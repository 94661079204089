import React from 'react';
import { connect } from "react-redux";
import { Grid, CircularProgress } from "@material-ui/core";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide } from '@material-ui/core';
import { hideAddOrgProductChampionsDialog } from "redux/product/orgProduct/action.js";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import CommonCss from '../../commonCss';
import SqureButton from 'Components/Common/SqureButton';
import Select from 'react-select';
import {
    searchUsers,
} from "redux/usersSettings/action";
import { showSnackBar } from "redux/snackbar/action";
import * as Validator from "../../util/Validation";
import { fetchRecommendedProducts } from "redux/product/action";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    dialogOrgProductChampionsState: state.orgProduct.dialogOrgProductChampionsState,
    dialogOrgProductChampions: state.orgProduct.dialogOrgProductChampions,
    orgAddVendorPocFetchProgress: state.orgProduct.orgAddVendorPocFetchProgress,
    usersSettings: state.usersSettings.users,
    orgFetchSecurityCert: state.orgProduct.orgFetchSecurityCert,
    complienceCert: state.master.complienceCert,
    products: state.products.recommendedProducts,
    fetchRecommendedProductsProgress: state.products.fetchRecommendedProductsProgress,
});

const connectionActions = {
    hideDialog: hideAddOrgProductChampionsDialog,
    fetchUsers: searchUsers,
    showSnackBar: showSnackBar,
    fetchRecommendedProducts: fetchRecommendedProducts
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    formControl: {
        ...CommonCss.formControl,
    },
    formGroup: {
        marginTop: 5,
        marginBottom: 16
    },
    creatable: {
        padding: 0
    },
    dialogContent: {
        minHeight: 170,
        overflowY: 'hidden',
        "& [class*=select__menu]": {
            maxHeight: 100
        }
    },
    searchAndSelect: {
        padding: 0,
        "& .select__control, & .select__control:hover": {
            border: 'none',
            height: 'inherit',
            boxShadow: 'none',
            minHeight: 44
        },
        "& .select__multi-value, & .select__multi-value__label": {
            background: '#4A87F8',
            color: '#fff',
            borderRadius: 20,
            padding: theme.spacing(.3, .6),
            fontSize: 13
        },
        "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
            background: '#4A87F8',
            color: '#fff',
            borderRadius: 20
        },
    },
    showNoteSection: {
        marginTop: 20,
        marginBottom: 20,
        "& [class*='editableField']": {
            padding: 5,
            margin: '0px !important',
            minHeight: "100px !important",
            maxHeight: 'auto !important',
        },
        "& [data-slate-node='element']": {
            margin: 0,
            marginLeft: 5,
            marginTop: 5,
        }
    },
});

class DialogOrgProductChampions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            link: '',
            name: '',
            email: '',
            title: '',
            dept: '',
            user: [],
            query: '',
            description: '',
            securityCert: {},
            selectedProduct: [],
            error: ""
        }
    }

    componentDidMount() {
        this.fetchUsers();
        this.fetchProduct();
    }

    componentDidUpdate(preProps) {
        if (this.props.dialogOrgProductChampionsState && !preProps.dialogOrgProductChampionsState) {

            this.setState(
                {
                    link: this.props.dialogOrgProductChampions?.link || '',
                    name: this.props.dialogOrgProductChampions?.name || '',
                    email: this.props.dialogOrgProductChampions?.email || '',
                    title: this.props.dialogOrgProductChampions?.title || '',
                    description: this.props.dialogOrgProductChampions?.description || '',
                    dept: this.props.dialogOrgProductChampions?.dept || '',
                    user: this.props.dialogOrgProductChampions?.user || [],
                    selectedProduct: this.props.dialogOrgProductChampions?.selectedProduct || [],
                    query: this.props.dialogOrgProductChampions?.query || '',
                    securityCert: this.props.dialogOrgProductChampions?.securityCert || ''
                });
        }
    }

    fetchProduct = (value = '') => {
        this.props.fetchRecommendedProducts(0, { feature_ids: [], num_users: '', page: 0, pageSize: 10, query: value, type: 2 });
    }

    fetchUsers = (query = '') => {
        this.props.fetchUsers(0, 20, query);
    }

    submitResponse = (e) => {
        if (this.props.dialogOrgProductChampions?.isLinkRequired && !this.state.link) {
            this.props.showSnackBar("Please enter link", "error", 3000);
            return;
        }

        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        if (this.props.dialogOrgProductChampions?.isLinkRequired && this.state.link && !re.test(this.state.link)) {
            this.props.showSnackBar("Please enter valid URL", "error", 3000);
            return;
        }

        if (this.props.dialogOrgProductChampions?.isUserRequired && this.state.user && this.state.user?.length === 0) {
            this.props.showSnackBar("Please choose value", "error", 3000);
            return;
        }
        if (this.props.dialogOrgProductChampions?.isNameRequired && !this.state.name) {
            this.props.showSnackBar("Please enter name", "error", 3000);
            return;
        }
        if (this.props.dialogOrgProductChampions?.isDeptRequired && !this.state.dept) {
            this.props.showSnackBar("Please enter department", "error", 3000);
            return;
        }
        if (this.props.dialogOrgProductChampions?.isEmailRequired && !this.state.email) {
            this.props.showSnackBar("Please enter email", "error", 3000);
            return;
        }

        if (this.props.dialogOrgProductChampions?.isEmailRequired && Validator.validateEmail(this.state.email) === false) {
            this.props.showSnackBar("Please enter valid email", "error", 3000);
            return;
        }
        if (this.props.dialogOrgProductChampions?.isSecurityCertRequired && !this.state.securityCert) {
            this.props.showSnackBar("Please choose security certificate", "error", 3000);
            return;
        }
        if (this.props.dialogOrgProductChampions?.isTitleRequired && !this.state.title) {
            this.props.showSnackBar("Please enter title", "error", 3000);
            return;
        }
        if (this.props.dialogOrgProductChampions?.isDescriptionRequired && !this.state.description) {
            this.props.showSnackBar("Please enter description", "error", 3000);
            return;
        }

        if (this.props.dialogOrgProductChampions?.isProductRequired && this.state.selectedProduct?.length === 0) {
            this.props.showSnackBar("Please choose product", "error", 3000);
            return;
        }

        console.log(this.state, 'Stateeeeeeeeeeeeeeeeeeeee')
        this.props.saveData({ ...this.props.dialogOrgProductChampions, ...this.state });
    }

    render() {
        const classes = this.props.classes;

        let userList = this.props.usersSettings?.map(m => {
            return {
                value: m.ID,
                label: m.Name,
                isIntegration: m.FromIntegration
            }
        });

        let productList = this.props.products.map(m => {
            return {
                value: m.ID,
                label: m.Name
            }
        });

        let orgSecurityCert = this.props.complienceCert?.map(m => {
            return {
                value: m.ID,
                label: m.Name,
                data: m
            }
        })

        orgSecurityCert.push({
            value: 0,
            label: "Other"
        });

        return <Dialog
            onClose={this.props.hideAddOrgProductChampionsDialog}
            aria-labelledby="new-champions-dialog"
            open={this.props.dialogOrgProductChampionsState}
            TransitionComponent={Transition}
            fullWidth={true}
            scroll={"body"}
            className={classnames(classes.dialog)}
            id="productChampDialog"
        >
            <DialogTitle id="form-dialog-title">{this.props.dialogOrgProductChampions?.head}</DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.formContainer}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            {this.props.dialogOrgProductChampions?.isSecurityCert && <div className={classnames(classes.formGroup, classes.searchAndSelect)}>
                                <Select
                                    isClearable
                                    isSearchable={true}
                                    options={orgSecurityCert}
                                    className={classnames(classes.creatable, classes.formControl)}
                                    classNamePrefix="select"
                                    // menuPortalTarget={document.getElementById("productChampDialog")}
                                    placeholder={this.props.dialogOrgProductChampions?.securityCertPlacehoder}
                                    onInputChange={(e) => {
                                    }}
                                    onChange={(e) => {
                                        this.setState({ securityCert: e })
                                    }}
                                />
                            </div>}
                            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}

                            {this.props.dialogOrgProductChampions?.isLink && <div className={classes.formGroup}>
                                <input className={classes.formControl} value={this.state.link} placeholder={this.props.dialogOrgProductChampions?.linkPlaceholder} onChange={(e) => {
                                    let error = "";
                                    if (e.target?.value && !Validator.validateURL(e.target?.value)) {
                                        error = "Please enter valid url."
                                    }
                                    this.setState({
                                        link: e.target.value,
                                        error
                                    })
                                }} />
                            </div>}
                            {this.props.dialogOrgProductChampions?.isName && <div className={classes.formGroup}>
                                <input className={classes.formControl} value={this.state.name} placeholder={this.props.dialogOrgProductChampions?.namePlaceholder} onChange={(e) => this.setState({
                                    name: e.target.value
                                })} />
                            </div>}
                            {/* <div className={classes.formGroup}>
                                        <input className={classes.formControl} placeholder="Product champions title" name="businessTitle"/>
                                    </div> */}
                            {this.props.dialogOrgProductChampions?.isDept && <div className={classes.formGroup}>
                                <input className={classes.formControl} value={this.state.dept} placeholder={this.props.dialogOrgProductChampions?.deptPlaceholder} onChange={(e) => this.setState({
                                    dept: e.target.value
                                })} />
                            </div>}

                            {this.props.dialogOrgProductChampions?.isEmail && <div className={classes.formGroup}>
                                <input className={classes.formControl} value={this.state.email} placeholder={this.props.dialogOrgProductChampions?.emailPlaceholder} onChange={(e) => this.setState({
                                    email: e.target.value
                                })} />
                            </div>}

                            {this.props.dialogOrgProductChampions?.isTitle && <div className={classes.formGroup}>
                                <input className={classes.formControl} value={this.state.title} placeholder={this.props.dialogOrgProductChampions?.titlePlaceholder} onChange={(e) => this.setState({
                                    title: e.target.value
                                })} />
                            </div>}

                            {this.props.dialogOrgProductChampions?.isDescription && <div className={classes.formGroup}>
                                <input className={classes.formControl} value={this.state.description} placeholder={this.props.dialogOrgProductChampions?.descriptionPlaceholder} onChange={(e) => this.setState({
                                    description: e.target.value
                                })} />
                            </div>}

                            {this.props.dialogOrgProductChampions?.isMessage && <div className={classes.formGroup}>
                                {false && <textarea
                                    className={classes.formControl}
                                    rows={5}
                                    value={this.state.description}
                                    placeholder={this.props.dialogOrgProductChampions?.messagePlaceholder}
                                    onChange={(e) => {
                                        this.setState({
                                            description: e.target.value
                                        })
                                    }
                                    } />}

                                <div className={classnames(classes.showNoteSection)}>
                                    <SlateInputField
                                        style={{ width: '100%' }}
                                        as={SlateInputField}
                                        onChangeEvent={(value) => {
                                            this.setState({ description: value });
                                        }}
                                        placeholder={this.props.dialogOrgProductChampions?.messagePlaceholder}
                                        initValue={this.state.description || ''}
                                        formControlStyle={{}}
                                        textContainerStyle={{}}
                                        external={true}
                                        folderPath={`/org_product/details/request_access`}
                                    />
                                </div>
                            </div>}

                            {this.props.dialogOrgProductChampions?.isUserList && <div className={classnames(classes.formGroup, classes.searchAndSelect)}>
                                <Select
                                    isClearable
                                    isSearchable={true}
                                    isMulti
                                    options={userList}
                                    className={classnames(classes.creatable, classes.formControl)}
                                    classNamePrefix="select"
                                    placeholder={this.props.dialogOrgProductChampions?.userPlacehoder}
                                    onInputChange={(e) => {
                                        this.fetchUsers(e);
                                    }}
                                    onChange={(e) => {
                                        this.setState({ user: e })
                                    }}
                                />
                            </div>}

                            {this.props.dialogOrgProductChampions?.isProductList && <div className={classnames(classes.formGroup, classes.searchAndSelect)}>
                                <Select
                                    isClearable
                                    className={classnames(classes.formControl, classes.creatable)}
                                    isSearchable={true}
                                    noOptionsMessage={() => this.props.fetchRecommendedProductsProgress ? 'Loading...' : "No options"}
                                    options={productList.filter((p) => {
                                        var isExisting = false;
                                        try {
                                            this.props.dialogOrgProductChampions.existingProductList.forEach((ep) => {
                                                if (isExisting) {
                                                    return;
                                                }
                                                if (ep.ID === p.value) {
                                                    isExisting = true;
                                                }
                                            });
                                        } catch (e) {

                                        }
                                        return !isExisting;
                                    })}
                                    classNamePrefix="select"
                                    placeholder="Choose product"
                                    onInputChange={(e) => {
                                        this.fetchProduct(e)
                                    }}
                                    onChange={(e) => {
                                        this.setState({ selectedProduct: e })
                                    }}
                                />
                            </div>}
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <div style={{ padding: '10px 24px' }}>
                    {!this.props.orgAddVendorPocFetchProgress && <>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actionBtn} variant={"contained"}
                            disabled={this.state.error.length > 0}
                            onClick={this.submitResponse}>Save</SqureButton>
                    </>}
                    {this.props.orgAddVendorPocFetchProgress && <>
                        <CircularProgress />
                    </>
                    }
                </div>
            </DialogActions>
        </Dialog>
    }
}


export default connector(compose(withRouter, withStyles(styles))(DialogOrgProductChampions));