import {START_APP_PRODUCT_CATEGORY_FETCH,END_APP_PRODUCT_CATEGORY_FETCH} from "./action";

const initState   =     {
    fetchAppProductCategoryProgress:false,
    fetchAppProductCategoryError:null,
    appProductCategories:null,
}

export  function appProductCategoryReducer(state=initState,action){
    switch(action.type){

        case START_APP_PRODUCT_CATEGORY_FETCH:
            return {
                ...state,
                fetchAppProductCategoryProgress:true,
                appProductCategories:null,
                fetchAppProductCategoryError: null
            };
        case END_APP_PRODUCT_CATEGORY_FETCH: {
            let success     =   action.payload.success;
            let error     =   action.payload.error;
            let categories     =   state.categories;
            if(success !== null){
                let Data    =   success.body;
                categories     =  Data;          
            }
            return {
                ...state,
                fetchAppProductCategoryProgress:false,
                appProductCategories:categories,
                fetchAppProductCategoryError: error
            }
        }
        
        default:
            return state;
    }
}