import React, { useState, useEffect } from "react";
import _ from "underscore";
import { generate } from "shortid";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Checkbox } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
// import Chip from "@material-ui/core/Chip";
import { Button } from "@material-ui/core";
// import Creatable from "react-select/creatable";

// icons
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";

// components
import RatingPoper from "Components/Application/Components/Evaluate/Components/Evaluation/InProgress/RatingPoper.jsx";

// redux
import { showSnackBar } from "redux/snackbar/action";
import { addRequirementResponse } from "redux/evaluation/criteria/action";

// Component styles
const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: '16px',
    width: "100%",
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
  },
  title: {
    color: "#AFAFAF",
    fontSize: "12px",
    marginBottom: "16px",
    display: "block",
  },
  textArea: {
    background: "#F7F7F7 0% 0% no-repeat padding-box",
    border: "1px solid #EBEBEB",
    borderRadius: "4px",
    padding: "8px",
    width: "100%",
  },
  dropDown: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    placeContent: "start",
    alignItems: "center",
    // maxWidth: '250px',
    "& label": {
      // fontSize: '12px',
      color: "#707070",
      marginRight: 30,
    },
    "& [class*='MuiSelect-root-']": {
      padding: "0.5em 0.75em",
      fontSize: "12px",
      color: "#707070",
      paddingRight: "32px",
    },
  },
  chips: {
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  answerChips: {
    margin: "16px 0",
    "& [class*='MuiChip-root-']": {
      marginRight: "5px",
      marginBottom: "5px",
      "&.otherChip": {
        backgroundColor: "#49B8FA",
        color: "#ffff",
        "& [class*='MuiChip-deleteIconSmall-']": {
          color: "#fff",
        },
      },
    },
  },
  addOtherEditIcon: {
    position: "absolute",
    right: "6px",
    top: "10px",
    cursor: "pointer",
    "& svg": {
      width: "16px",
    },
  },
  addOtherSaveIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
    "& svg": {
      width: "20px",
    },
    backgroundColor: "transparent",
    padding: 0,
    border: "none",
  },
  addOtherCloseIcon: {
    position: "absolute",
    right: "6px",
    top: "18px",
    cursor: "pointer",
    "& svg": {
      width: "20px",
    },
  },
}));

// redux props & actions
const connectedProps = (state) => ({
  evaludationCriteria: state.evaludationCriteria,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  addRequirementResponse: addRequirementResponse,
};

var connector = connect(connectedProps, connectionActions);

function AnswerReplySeller(props) {
  const classes = useStyles();
  const [ratingPoperEl, setRatingPoperEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filename, setFilename] = useState("");
  const { requirement, onChange, userOptions } = props;
  const [editOther, setEditOther] = useState(false);
  const [otherOption, setOtherOption] = useState(false);
  const [otherOptionText, setOtherOptionText] = useState("");
  const type = requirement?.AnswerType;
  let answers = requirement?.Options;

  useEffect(() => {
    if (type === 2 || type === 3) {
      var selectedValue = userOptions
        .filter((o) => o.Answer === "")
        .map((i) => {
          return i.OptionID;
        });
      const OtherOption = userOptions.filter((o) => o.Answer !== "");
      if (OtherOption.length) {
        setOtherOption(true);
        setOtherOptionText(OtherOption[0].Answer);
      } else {
        setOtherOption(false);
        setOtherOptionText("");
      }
      selectedValue = _.uniq(selectedValue);
      if (type === 3) {
        setSelectedOption(selectedValue);
      } else {
        setSelectedOptions(selectedValue);
      }
    } else if (type === 4) {
      if (userOptions.length > 0) {
        setSelectedOption(String(userOptions[0].Answer));
      }
    } else if (type === 5) {
      if (userOptions.length > 0) {
        setSelectedOption(String(userOptions[0].Answer));
      }
    } else if (type === 6) {
      if (userOptions.length > 0) {
        let fileUrl = userOptions[0].Answer || "";
        if (validURL(fileUrl)) {
          setSelectedOption(String(fileUrl));
          let filename = new URL(fileUrl).pathname.split("/").pop();
          setFilename(filename);
        }
      }
    }
    if (
      props.evaludationCriteria.addResponseProgress === false &&
      props.evaludationCriteria.successResponseAdd
    ) {
      props.showSnackBar("Response updated", "success", 1500);
    }
  }, [props, type, userOptions]);

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };


  // console.log('-----Data')
  // console.log(selectedOptions, 'selectedOptions')
  // console.log(answers, 'answers')

  return (
    <div className={classes.root}>
      {/* Multi and sigle select */}
      {(type === 2 || type === 3) && (
        <>
          <ul className={"answers"}>
            {type === 2 &&
              answers.map((item, i) => (
                <li key={i}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      boxSizing: "border-box",
                      placeContent: "center flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      id={item.ID}
                      checked={selectedOptions.indexOf(item.RefOptionId) !== -1}
                      name={item.ID}
                      color="primary"
                    />
                    <label htmlFor={item.ID}>{item.Title}</label>
                  </div>
                </li>
              ))}
            {type === 3 &&
              answers.map((item, i) => (
                <li key={i}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      boxSizing: "border-box",
                      placeContent: "center flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Radio
                      disabled={true}
                      value={String(item.RefOptionId)}
                      checked={selectedOption.includes(item.RefOptionId)}
                      inputProps={{
                        name: "RadioData",
                        "data-sequence": item.Sequence,
                      }}
                    />
                    <label htmlFor={item.RefOptionId}>{item.Title}</label>
                  </div>
                </li>
              ))}
            {requirement && requirement.IsOtherOption && otherOption && (
              <li key={generate()}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    boxSizing: "border-box",
                    placeContent: "center flex-start",
                    alignItems: "center",
                  }}
                >
                  {type === 3 && (
                    <Radio
                      disabled={true}
                      value="Other"
                      checked={otherOption}
                      inputProps={{
                        name: "RadioData",
                        "data-sequence": "data",
                      }}
                      onChange={(e) => {
                        setOtherOption(true);
                        setEditOther(true);
                        onChange([], otherOptionText || 'Other');
                      }}
                    />
                  )}
                  {otherOption && (
                    <>
                      <label htmlFor="otherOption">
                        <b>Other:</b>
                      </label>
                      <OtherTextField
                        disabled={true}
                        classes={classes}
                        value={otherOptionText}
                        editOther={editOther}
                        onEditOther={(value) => setEditOther(value)}
                        onClick={(value) => {
                          setOtherOption(value ? true : false);
                          setOtherOptionText(value);
                          let options = _.uniq(
                            type === 3 ? selectedOptions : selectedOptions
                          );
                          onChange(options, value || "remove");
                          setEditOther(false);
                        }}
                      />
                    </>
                  )}
                </div>
              </li>
            )}
          </ul>
        </>
      )}

      {type === 4 &&
        <>
          {/* <span className={classes.title}>Select one option from below</span> */}
          <RadioGroup disabled={true} name={`option_${requirement.ID}`} value={String(selectedOption)} onChange={(e) => {
            setSelectedOption(e.target.value)
            onChange(e.target.value)
          }}>
            <ul className={"answers"}>
              {answers.map((item, k) => {
                return <>
                  <li key={k}><FormControlLabel value={item.Description} control={<Radio disabled={true} />} label={item.Title} /></li>
                </>
              })}
              {/* <li  key={1}><FormControlLabel disabled={true} value='Yes' control={<Radio disabled={true}/>} label='Yes' /></li>
                <li  key={2}><FormControlLabel disabled={true} value='No' control={<Radio disabled={true}/>} label='No' /></li> */}
            </ul>
          </RadioGroup>
        </>
      }


      {/* Score */}
      {type === 5 && (
        <div
          style={{
            display: "flex",
            padding: "4px 21px",
            background: "#f9f9f9",
            boxSizing: "border-box",
            alignItems: "center",
            borderRadius: "8px",
            placeContent: "center space-between",
            flexDirection: "row",
            maxWidth: "200px",
          }}
        >
          <label>Response</label>&nbsp;&nbsp;
          <Button
            disabled={true}
            variant="outlined"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setRatingPoperEl(event.currentTarget);
            }}
          >
            {selectedOption ? selectedOption + "" : "+ Score"}
          </Button>
          <RatingPoper
            value={selectedOption ? selectedOption : 0}
            anchorEl={ratingPoperEl}
            onClose={() => {
              setRatingPoperEl(null);
            }}
            onSave={(value) => {
              onChange(null, `${value}`);
              setRatingPoperEl(null);
            }}
          />
        </div>
      )}

      {/* File Upload */}
      {type === 6 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "16px",
          }}
        >
          {selectedOption && (
            <a
              href={selectedOption}
              target="_blank"
              style={{
                marginBottom: "25px",
                display: "inline-block",
                textDecoration: "underline",
                color: "#4A87F8",
              }}
              rel="noreferrer"
            >
              {filename}
            </a>
          )}
          {/* <input
            disabled={true}
            id="uploadRequirementResponse"
            onChange={fileSelectHandler}
            type="file"
            accept="image/*"
          /> */}
        </div>
      )}
    </div>
  );
}

export default connector(AnswerReplySeller);

const OtherTextField = ({
  classes,
  value,
  onClick,
  editOther,
  onEditOther,
}) => {
  const [optionValue, setOptionValue] = useState(value);
  useEffect(() => {
    setOptionValue(value);
  }, [value]);
  return (
    <div
      style={{
        flex: 1,
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      {editOther && (
        <TextField
          style={{
            width: "80%",
            marginLeft: 10,
            color: "#04363D",
            fontSize: 13,
            margin:10
          }}
          value={optionValue}
          onChange={(e) => setOptionValue(e.target.value)}
        />
      )}
      {!editOther && (
        <p
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "80%",
            marginLeft: 10,
          }}
        >
          {optionValue}
        </p>
      )}
      {false && !editOther && (
        <span
          onClick={() => {
            onEditOther(true);
          }}
          className={classes.addOtherEditIcon}
        >
          <EditIcon />
        </span>
      )}
      {editOther && (
        <button
          disabled={/^ *$/.test(optionValue)}
          className={classes.addOtherSaveIcon}
          onClick={() => onClick(optionValue)}
        >
          <DoneIcon />
        </button>
      )}
    </div>
  );
};
