import React from 'react'
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Grid } from '@material-ui/core';
import CommonCss from 'commonCss';
import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';
import {InputAdornment } from '@material-ui/core';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
//UI
import CloseIcon from '@material-ui/icons/Close';
import { Search as SearchIcon } from "@material-ui/icons";
// assets
import DefaultImage from "assets/images/evaluation/default.svg";

// components
import AppBarV2 from "../Application/AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import SqureButton from 'Components/Common/SqureButton';
import ClusterProductsListDialog from "Components/Dialogs/ClusterProductsListDialog";
import MessageDialog from "Components/Common/MessageDialog";
import CreateClusterGroupProductDialog from "Components/Dialogs/CreateClusterGroupProductDialog"

// redux
import { showCreateClusterGroupDialog, fetchClusterGroupProducts, fetchClusterGroups, fetchDeleteClusterGroupProduct, showClusterProductsDialog, fetchDeleteProductCluster } from "redux/product/cluster/action";
import { showMessageDialog, hideMessageDialog } from "redux/applications/action";
import { deleteProduct } from "redux/product/orgProduct/action"
import { showSnackBar } from "redux/snackbar/action";
import { clearStoreState } from "redux/applications/action";

const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
  clusterGroupProducts: state.clusterProductReducer.fetchClusterGroupProductsSuccess,
  fetchClusterGroupProductsInprogress: state.clusterProductReducer.fetchClusterGroupProductsInprogress,
  fetchClusterGroupProductsError: state.clusterProductReducer.fetchClusterGroupProductsError,
  deleteClusterGroupProductsInprogress: state.clusterProductReducer.deleteClusterGroupProductsInprogress,
  deleteClusterGroupProductsSuccess: state.clusterProductReducer.deleteClusterGroupProductsSuccess,
  deleteProductProgress: state.orgProduct.deleteProductProgress,
  _createClusterGroupDialog: state.clusterProductReducer._createClusterGroupDialog,
  _clusterProductsDialog: state.clusterProductReducer._clusterProductsDialog,
  deleteClusterGroupInprogress: state.clusterProductReducer.deleteClusterGroupInprogress,
  deleteClusterGroupSuccess: state.clusterProductReducer.deleteClusterGroupSuccess,
  deleteClusterGroupError: state.clusterProductReducer.deleteClusterGroupError,
})

const connectionActions = {
  clearStoreState: clearStoreState,
  fetchClusterGroupProducts: fetchClusterGroupProducts,
  fetchClusterGroups: fetchClusterGroups,
  fetchDeleteClusterGroupProduct: fetchDeleteClusterGroupProduct,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  deleteProduct: deleteProduct,
  showCreateClusterGroupDialog: showCreateClusterGroupDialog,
  showClusterProductsDialog: showClusterProductsDialog,
  showSnackBar: showSnackBar,
  fetchDeleteProductCluster:fetchDeleteProductCluster
}

var connector = connect(connectedProps, connectionActions);


const styles = (theme) => createStyles({
      rootContainer: CommonCss.rootContainer,
      pageHeader: {
        marginTop: '2.5em',
        marginBlock: '2em',
        display: 'flex',
        placeContent: ' space-between',
        placeItems: 'center'
      },
      productCard: {
        position: 'relative'
      },
      closeIcon: {
        position: 'absolute',
        zIndex: 22,
        right: 12,
        top: 10,
        color: '#dcd7d7',
        cursor: 'pointer'
      },
      searchIconPop: {
        cursor: 'pointer',
        fontSize: theme.spacing(3),
        marginRight: '10px',
        "&:hover": {
          color: "#fff !important",
          fill: "#fff !important",
          background: "#707070",
          borderColor: "#707070",
          padding: '3px',
          borderRadius: theme.spacing(20),
          fontSize: theme.spacing(3)
        }
      },
      searchSection: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        marginleft: '6%',
        '& [class*="MuiOutlinedInput-root"]': {
          maxHeight: 40,
          minHeight: 40,
          maxWidth: 343,
        },
        "& [class*='MuiInputBase-input']": {
          fontSize: 13,
          width: 300,
        },
        "& [class*='MuiSvgIcon-root']": {
          fill: "#707070",
        },
        "& [class*=MuiGrid-container]": {
          alignItems: "center",
          marginBottom: 20,
        },
        "&>div ": {
          marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
          marginBottom: '16px',
        },
      },
    });


class ClusterProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteProductId: null,
      deleteId: null,
      showActionMenu: null,
      query: '',
      allProducts: [],
    }
  }

  componentDidMount() {
    this.fetchProducts()
  }

  fetchProducts = () => {
    this.props.fetchClusterGroupProducts(this.props.match?.params?.id)
  }

  componentDidUpdate(prevProps) {   
    if (!this.props.deleteClusterGroupProductsInprogress && this.props.deleteClusterGroupProductsSuccess && this.state.deleteProductId) {
      this.setState({
        deleteProductId: null
      }, () => {
        this.props.showSnackBar('Product removed successfully', "success", 3000);
        this.props.clearStoreState({
          'clusterProductReducer.deleteClusterGroupProductsSuccess': null,
        })
        this.fetchProducts()
      })
    }
    if (!this.props.fetchClusterGroupProductsInprogress && prevProps.fetchClusterGroupProductsInprogress) {
      if(this.props.clusterGroupProducts?.products){
        this.setState({ allProducts: this.props.clusterGroupProducts?.products || [] });
      }else{
        this.setState({ allProducts: [] });
      }
    }

    if (!this.props.fetchClusterGroupProductsError && prevProps.fetchClusterGroupProductsError) {
      if(this.props.clusterGroupProducts?.products){
        this.setState({ allProducts: this.props.clusterGroupProducts?.products || [] });
      }
    }

    if (!this.props.deleteProductProgress && prevProps.deleteProductProgress) {
      this.props.showSnackBar('Product deleted successfully', "success", 3000);
      this.fetchProducts()
    }

    if (!this.props.deleteClusterGroupInprogress && prevProps.deleteClusterGroupInprogress) {
      this.props.showSnackBar('Group deleted successfully', "success", 3000);
      let redirectPath = `/app/products`;
      this.props.history.push(redirectPath);
    }

  }

  producDetail = (e, product) => {
    this.props.history.push(`/app/products/org-product-details/${product.productID}`)
  }

  reviewProduct = (id) => {
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  }

  closeIconGroupCard = (e, product) => {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteProductId: product?.productID }, () => {
      me.props.showMessageDialog();
    })
  }

  deleteGroup = (e, grpId) => {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteId: grpId }, () => {
      me.props.showMessageDialog();
    })
  }

  // callBack = (action) => {
  //   this.props.hideMessageDialog();
  //   this.props.fetchDeleteClusterGroupProduct(this.props.match?.params?.id, this.state.deleteProductId)
  // };

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.fetchDeleteProductCluster(this.state.deleteId)
    }
    if (this.state.deleteProductId) {
      this.props.hideMessageDialog();
      this.props.fetchDeleteClusterGroupProduct(this.props.match?.params?.id, this.state.deleteProductId)
    }
  };

  handleActionClick = (event) => {
    this.setState({ showActionMenu: event.currentTarget })
  };

  handleActionClose = () => {
    this.setState({ showActionMenu: null })
  };
  onActionChange = (target) => {
    if (target === 10) {
      this.props.showClusterProductsDialog(false, this.props.clusterGroupProducts.products)
    } else if (target === 20) {
      this.props.showCreateClusterGroupDialog(false, this.props.clusterGroupProducts)
    }
    this.setState({ showActionMenu: null })
  }

  appBarTitleAction = () => {
    this.props.showCreateClusterGroupDialog(false, this.props.clusterGroupProducts)
  }

  filterData = (e) => {
    if (e.target.value && e.target.value.length) {
      let products = [...(this.props.clusterGroupProducts?.products || [])];
      let filterProducts = products.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
      this.setState({ allProducts: filterProducts, query: e.target.value});
    } else {
      this.setState({  query: '',allProducts: this.props.clusterGroupProducts?.products || [] });
    }
  }

  render() {
    const classes = this.props.classes;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to remove product from group? This process cannot be undone",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let messageDataDeleteGroup = {
      head: "Are you sure?",
      subHead: "Do you really want to remove group? This process cannot be undone",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const content = {
      countLabel: "product",
      pluralCountLabel: "products",
    };

    let totalProducts = this.state.allProducts.length;

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;
    return <>
      <div className={classnames(classes.root)} >

        <AppBarV2 title={this.props.clusterGroupProducts?.name} withBack={true}
          edit={adminFlag} editAction={this.appBarTitleAction} />

        <div className={classes.rootContainer}>
          <div className={classes.pageHeader}>
            <p className={classes.productCount}>
              {totalProducts} {" "}
              {totalProducts > 1 ? content.pluralCountLabel : content.countLabel}{" "}
              found
            </p>
            <div className={classes.searchSection}>
              <OutlinedInput
                id={"searchProducts"}
                placeholder={'Search Products'}
                onChange={this.filterData}
                value={this.state.query}
                startAdornment={
                  <InputAdornment>
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment>
                    {this.state.query.length > 0 && this.state.query !== '' && this.state.query !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                      <Clear className={classes.searchIconPop} onClick={()=>{
                        this.setState({  query: '' , allProducts: this.props.clusterGroupProducts?.products || [] });
                      }} />
                    </TooltipMsg>}
                  </InputAdornment>
                }
                roundedCorner={true}
              />
            </div>
            <div>
              <SqureButton 
                variant={"outlined"}
                onClick={(e) => {
                  this.deleteGroup(e, this.props.clusterGroupProducts.id)
                }}> Delete Group</SqureButton>

              {adminFlag && <SqureButton 
                style={{marginLeft:20}}
                variant={"contained"}
                onClick={() => {
                  this.props.showClusterProductsDialog(false, this.props.clusterGroupProducts.products)
                }}>+ Add Vendor</SqureButton>}
            </div>

          </div>

          <div className={classes.clusterProductsContainer}>
            <Grid container spacing={3}>
              {this.state.allProducts.map((product, i) => {
                // let product = item.orgProduct;
                return (<Grid item lg={2} md={3} key={i}>
                  <div onClick={(e) => this.producDetail(e, product)} className={classes.productCard}>
                    {adminFlag &&
                      <div className={classes.closeIcon} onClick={(event) => {
                        this.closeIconGroupCard(event, product)
                      }}>
                        <CloseIcon />
                      </div>
                    }
                    <LayeredCard
                      title={product.name}
                      subTitle={product.companyName}
                      description={product.description}
                      image={product.productLogo == null || product.productLogo.length === 0 ? DefaultImage : product.productLogo}
                      onClick={() => { }}
                      toogleCheck={(e) => {
                        this.reviewProduct(product.productID)
                      }}
                      numLayer={0}
                      showCheckbox={true}
                      id={product.productID}
                      uniqueId={product.productID + '_n_' + i}
                      IsExisting={true}
                      isOrgProduct
                    />
                  </div>
                </Grid>
                )
              }
              )}
            </Grid>
          </div>
        </div>
      </div>
      <MessageDialog messageData={this.state.deleteId === null ? messageData : messageDataDeleteGroup } callBack={this.callBack} />
      {
        this.props._createClusterGroupDialog === true &&
        <CreateClusterGroupProductDialog />
      }
      {
        this.props._clusterProductsDialog === true &&
        <ClusterProductsListDialog />
      }
    </>
  }
}

export default connector(compose(withRouter, withStyles(styles))(ClusterProducts));
