import React from "react";
import { connect } from "react-redux";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import DefaultImage from "assets/images/evaluation/default.svg";
import { Grid, CircularProgress, InputAdornment, Tabs, Tab, Button } from "@material-ui/core";
import AppBarV2 from "Components/Application/AppBarV2";
import {
  fetchOrgCategories,
  showAddOrgCategoryDialog,
  hideAddOrgCategoryDialog,
} from "redux/org/categories/action";
import InfiniteScroll from "react-infinite-scroll-component";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import { Search as SearchIcon, Cancel as CancelIcon } from "@material-ui/icons";
import CommonCss from 'commonCss';
import AddOrgCategoryDialog from "./Dialogs/AddOrgCategoryDialog";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
  fetchCategoriesProgress: state.orgCategory.fetchCategoriesProgress,
  categories: state.orgCategory.categories,
  totalCategories: state.orgCategory.totalCategories,
  fetchCategoryError: state.orgCategory.fetchCategoryError,
  orgName: state.authUser.user?.Organization?.Name,
  showAddCategoryDialog: state.orgCategory.showAddCategoryDialog,
  addCategoryProgress: state.orgCategory.addCategoryProgress,
  addCategoryError: state.orgCategory.addCategoryError,
});

const connectionActions = {
  fetchOrgCategories: fetchOrgCategories,
  showAddOrgCategoryDialog: showAddOrgCategoryDialog,
  hideAddOrgCategoryDialog: hideAddOrgCategoryDialog,
  showSnackBar:showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    productCount: {
      color: "#282D30",
      fontSize: 14,
      flex: 1,
    },
    productSection: {
      marginTop: theme.spacing(6.2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    searchCard: {
      width: "100%",
      background: "#fff",
      margin: "0 auto",
      padding: theme.spacing(2),
      borderBottom: "solid 1px #ccc",
      cursor: "pointer",
      "&:hover": {
        background: "#eaeaea",
      },
    },
    searchSubCatTitle: {
      width: "100%",
      background: "#f0f0f0",
      margin: "0 auto",
      padding: theme.spacing(1),
      borderBottom: "solid 1px #ccc",
      // fontSize:"10px",
      fontWeight: "bold",
    },
    disablesSearchCard: {
      width: "100%",
      background: "#f0f0f0",
      margin: "0 auto",
      padding: theme.spacing(2),
      borderBottom: "solid 1px #ccc",
      cursor: "not-allowed",
      "&:hover": {
        background: "#f0f0f0",
      },
    },
    searchSection: {
      flex: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        // maxWidth: 343,
      },
      "& [class*='MuiInputBase-input']": {
        fontSize: 13,
        // width: 300,
      },
      "& [class*='MuiSvgIcon-root']": {
        fill: "#707070",
      },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
      "&>div ": {
        marginBottom: 0,
      },
    },
    searchResult: {
      position: "absolute",
      top: "45px",
      left: 0,
      right: 0,
      zIndex: 100,
      background: "#fff",
      maxHeight: "400px",
      overflow: "auto",
      boxShadow: " 4px 0px 4px #2b2d3703",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    categoriesList: {
      marginTop: theme.spacing(3),
      maxHeight: "calc(100vh - 210px)",
      minHeight: 200,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    filterSection: {
      flex: 1,
      textAlign: 'right'
    },
    closeIcon: {
      cursor: "pointer",
    },
    btnAddProduct: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      marginRight: 10,
      "&:hover": {
        background: "#3C3C3C",
        color: '#fff',
      },
    },
    tabSection: {
      marginTop: theme.spacing(3),
      borderBottom: '1px solid #88888833',
      '& [class*=MuiTabs-root]': {
        minWidth: '200px !important'
      },
      '& [class*=MuiTab-root]': {
        minWidth: '200px !important'
      },
    },
  });

class AppCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 100,
      isSearching: false,
      searchPage: 0,
      searchPageSize: 60,
      items: [],
      isCartShow: false,
      tabValue: 1,
      isLoading:true
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if(this.props.fetchAppProductCategoryProgress === false && prevProps.fetchAppProductCategoryProgress === true){
      this.setState({
        isLoading : false
      })
    }

    if (this.props.addCategoryProgress === false && prevProps.addCategoryProgress === true) {
      if (this.props.addCategoryError === null) {
        this.fetchData();
        this.props.showSnackBar("Category successfully added", "success", 3000);
        this.props.hideAddOrgCategoryDialog();
      } else {
        if (this.props.addCategoryError.message !== undefined) {
          this.props.showSnackBar("Failed to add category", "error", 3000);
        } else {
          this.props.showSnackBar("Something went wrong", "error", 3000);
        }
      }
    }
  }

  fetchData() {
    this.props.fetchOrgCategories(this.state.page, this.state.pageSize, this.state.query);
  }

  goBack = () => {
    this.props.history.push("/app/products");
  };

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.main}>
        <AppBarV2
          title={this.props.orgName ? this.props.orgName + "'s Categories" : "Categories"}
          withBack={true}
          dispatchEvent={true}
          targetEvent={this.goBack}
        />
        <div className={classes.rootContainer}>
          <div className={classes.productSection}>
            <p className={classes.productCount}>
              {
                <>
                  {this.props.totalCategories.toLocaleString("en-US")}
                  {this.props.totalCategories > 1
                    ? " Categories"
                    : " Category"}{" "}
                  found
                </>
              }
            </p>
            <div className={classes.searchSection}>
              <OutlinedInput
                id={"searchCategories "}
                placeholder="Search Categories"
                value={this.state.query}
                onChange={(event) => {
                  var me = this;
                  this.setState({ query: event.target.value, page: 0 }, () => {
                    me.fetchData();
                  });
                }}
                fullWidth={true}
                style={{ width: "100%" }}
                startAdornment={
                  <InputAdornment>
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                }
                endAdornment={
                  this.state.query.length !== 0 ? (
                    <InputAdornment>
                      <CancelIcon
                        className={classes.closeIcon}
                        onClick={() => {
                          this.setState({
                            query: "",
                          });
                        }}
                      />
                    </InputAdornment>
                  ) : null
                }
                roundedCorner={true}
              />
            </div>
            <div className={classes.filterSection}>
              <Button className={classes.btnAddProduct} onClick={() => {
                this.props.showAddOrgCategoryDialog();
              }}>
                + Add New
              </Button>
            </div>
          </div>

          <div className={classes.tabSection}>
            <Tabs
              value={this.state.tabValue}
              onChange={(event, newValue) => {
                if (newValue === 0) {
                  let redirectPath = `/app/products/all-categories`;
                  this.props.history.push(redirectPath);
                }
                this.setState({ tabValue: newValue });
              }}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabWidth}
            >
              <Tab
                className={classes.orgProductTab}
                label="Expent Categories"
                key="expent-categories"
              />
              <Tab
                className={classes.orgProductTab}
                label={this.props.orgName ? this.props.orgName + "'s Categories" : "Categories"}
                key="inventory"
              />
            </Tabs>
          </div>

          {this.state.tabValue === 1 && <>
            <div className={classes.categoriesList} id="allCategories">
              <InfiniteScroll
                scrollableTarget="allCategories"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  marginRight: 20,
                }} //To put endMessage and loader to the top.
                dataLength={this.props.categories?.length} //This is important field to render the next data
                next={() => {
                  var me = this;
                  this.setState({ page: this.state.page + 1 }, () => {
                    me.fetchData();
                  });
                }}
                hasMore={
                  this.props.categories.length === (this.state.page + 1) * this.state.pageSize
                }
                endMessage={
                  <p
                    style={{
                      textAlign: "center",
                      minHeight: 100,
                      paddingTop: 20,
                    }}
                  >
                    {this.props.categories.length === 0 &&
                      this.props.fetchCategoriesProgress === false && (
                        <span>No More Data</span>
                      )}
                  </p>
                }
              >
                {this.props.fetchCategoriesProgress === false && <Grid container spacing={3}>
                  {this.props.categories.map((category, k) => {
                    return (
                      <Grid item lg={2} md={3} xs={12} sm={6} key={"cat_" + k}>
                        <LayeredCard
                          title={category.Name}
                          numTitleLines={3}
                          subTitle=""
                          image={
                            category.Logo == null || category.Logo.length === 0
                              ? DefaultImage
                              : category.Logo
                          }
                          onClick={() => { }}
                          showSubtitle={false}
                          showCheckbox={true}
                          source="CATEGORY_DETAILS"
                          toogleCheck={(e) => {
                            this.props.history.push(`${this.props.match.path}/${category.ID}/details`);
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>}

                {this.props.fetchCategoriesProgress === true && <div style={{ textAlign: 'center', marginTop: 20 }}>
                  <CircularProgress />
                </div>}

              </InfiniteScroll>
              {/* {this.props.fetchCategoriesProgress === true && (
                <div className={classes.backdrop}>
                  {" "}
                  <CircularProgress />
                </div>
              )} */}
            </div>
          </>
          }
        </div>
        {this.props.showAddCategoryDialog && <AddOrgCategoryDialog />}
      </div>
    );
  }
}

AppCategories.defaultProps = {
  isSeller: false
}

export default connector(
  compose(withStyles(styles), withRouter)(AppCategories)
);
