import { Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import DefaultImage from "assets/images/evaluation/default.svg";
import AppBarV2 from "Components/Application/AppBarV2";
// import DefaultImage from "assert/";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { fetchOrgProducts } from "redux/product/orgProduct/action";
import AddUser from "../../../../assets/images/home/add_user.svg";
import AddVendor from "../../../../assets/images/home/add_vendor.svg";
import Products from "../../../../assets/images/home/products.svg";
// import CommonCss from "../../../../../commonCss";
import CommonCss from "../../../../commonCss";
import { authorizedUser } from "../../../../redux/authuser/action";
import { updateDrawerStyle } from "../../../../redux/drawer/action";
import CardAction from "../../../Common/CardAction";
import ClusterFolder from "../../../Common/ClusterFolder";
import Actions from "./Components/Actions";
// import Evaluation from "./Components/Evaluation";
import FeaturedProducts from "./Components/FeaturedProducts";
// import HomeReviews from "./Components/HomeReviews";
// components
import Metric from "./Components/Metric";
import { isEnabledReview, isEnabledEvaluate, isSyncActivityLog } from "util/Common";
import RequestEvaluations from "./Components/RequestEvaluations";
import WelcomeBack from "Components/Common/WelcomePages/WelcomeBack";

const connectedProps = (state) => ({
  products: state.orgProduct.orgProducts,
  totalOrgProducts: state.orgProduct.totalOrgProducts,
  orgName: state.authUser.user?.Organization?.Name,
  userRole: state.authUser.user?.Role,
  user: state.authUser.user,
  orgUser: state.orgUser,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchOrgProducts: fetchOrgProducts,
  authorizedUser: authorizedUser,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    actionContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(0, 10),
    },
    statsContainer: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(4, 10),
    },
    commingSoon: {
      marginBottom: theme.spacing(6),
      "& div[class*=ComingSoon-root-]": {
        minHeight: 480,
        padding: 20,
        borderRadius: "6px",
      },
    },
    title: {
      // fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
      fontWeight: 600,
    },
    cardAction: {
      cursor: "pointer",
    },
    topHead: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 40,
      marginBottom: 8,
      "& > .appBar": {
        flex: 1,
      },
      "& h6": {
        fontSize: "1.4em",
        fontWeight: 600,
        margin: "0px",
      },
    },
    viewAll: CommonCss.viewAll,
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
  });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 6,
      sm1: window.matchMedia("(min-width: 576px) and (max-width: 667.98px)").matches,
      sm2: window.matchMedia("(min-width: 668px) and (max-width: 767.98px)").matches,
      isShowWelcome: true
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.props.updateDrawerStyle(true, true);
    this.fetchData();

    // setTimeout(() => {
    //   this.setState({
    //     isShowWelcome: false
    //   })
    // }, 5000);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  fetchData() {
    this.props.fetchOrgProducts(this.state.page, this.state.pageSize, this.state.query, "only_products");
  }

  producDetail = (e, product) => {
    this.props.history.push(`/app/products/org-product-details/${product.ID}`);
  };
  reviewProduct = (id) => {
    // e.stopPropagation();
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  };

  updateDimensions = () => {
    let sm1 = window.matchMedia("(min-width: 576px) and (max-width: 667.98px)").matches;
    let sm2 = window.matchMedia("(min-width: 668px) and (max-width: 767.98px)").matches;
    this.setState({
      sm1: sm1,
      sm2: sm2,
    });
  };

  render() {
    const classes = this.props.classes;

    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "DisableCatalogFeature");

    const EnableReviewForAdminsCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableReviewForAdminsCheck");
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForAdminsCheck");
    const EnableOtherForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableOtherWorkflowsForAdminsCheck");

    const GoogleSyncActivityLog = isSyncActivityLog(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "Google_SYNC_ACTIVITY_LOG");
    const OCTASyncActivityLog = isSyncActivityLog(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "Okta_SYNC_ACTIVITY_LOG");
    let isHideMatrix = false;
    if (GoogleSyncActivityLog === false && OCTASyncActivityLog === false) {
      isHideMatrix = true;
    }

    const { sm1, sm2 } = this.state;
    const sm = sm1 ? 12 : sm2 ? 12 : 6;

    let isShowRequests = false;
    if (EnableReviewForAdminsCheck || EnableEvaluateForAdminsCheck || EnableOtherForAdminsCheck) {
      isShowRequests = true;
    }

    let directoryLink = "/app/products/all-products";

    if (!DisableVendor) {
      directoryLink = "/app/vendors/all-vendors";
    }

    // if (this.state.isShowWelcome) {
    //   return <div>
    //     <WelcomeBack/>
    //   </div>
    // }

    return (
      <div>
        <AppBarV2 title={"Home"} />

        <div className={classes.actionContainer}>

          {this.state.isShowWelcome && <div>
            <WelcomeBack />
          </div>}

          {this.props.userRole === "OrgAdmin" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={sm} md={4} lg={4} xl={4}>
                <div
                  onClick={() => {
                    this.props.history.push(directoryLink);
                  }}
                >
                  <CardAction cardActionClass={classes.cardAction} image={Products} primary={"Expent Vendor Directory"} secondary={"Tap to view all"} toolTip="0" />
                </div>
              </Grid>
              <Grid item xs={12} sm={sm} md={4} lg={4} xl={4}>
                <div onClick={() => this.props.history.push("/app/settings/integrations")}>
                  <CardAction cardActionClass={classes.cardAction} image={AddVendor} primary={"Integrations"} secondary={"Tap to view integrations"} toolTip="1" />
                </div>
              </Grid>
              <Grid item xs={12} sm={sm} md={4} lg={4} xl={4}>
                <div onClick={() => this.props.history.push("/app/settings/users")}>
                  <CardAction cardActionClass={classes.cardAction} image={AddUser} primary={"Add new user"} secondary={"Tap to add a new user"} />
                </div>
              </Grid>
            </Grid>
          )}

          {this.props.userRole === "OrgUser" && (
            <>
              <div className={classes.topHead}>
                <h6>{this.props.totalOrgProducts > 0 ? `${this.props.orgName ? this.props.orgName + "'s" : ""} Vendor Catalog` : ""}</h6>
                <Link to={directoryLink} className={classes.viewAll}>
                  View all ({this.props.totalOrgProducts}){" "}
                </Link>
              </div>

              <Grid container spacing={3}>
                {this.props.products.slice(0, 6).map((product, index) => {
                  if (product.orgProduct !== undefined && product.orgProduct["products"] !== undefined) {
                    return (
                      <Grid item lg={2} md={3} key={index} className={classes.productCard}>
                        <div className={classes.productCard}>
                          {/* { 
                        <div className={classes.closeIcon} onClick={(event) => {
                          this.closeIconGroupCard(event, product.orgProduct)
                        }}>
                          <CloseIcon/>
                        </div> 
                      } */}
                          <ClusterFolder group={product} />
                        </div>
                      </Grid>
                    );
                  }

                  return (
                    <Grid item lg={2} md={3} key={index}>
                      <div onClick={(e) => this.producDetail(e, product)}>
                        <LayeredCard
                          title={product.Name}
                          subTitle={product.CompanyName}
                          description={product.Description}
                          image={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                          toogleCheck={(e) => {
                            this.reviewProduct(product.ID);
                          }}
                          onClick={() => { }}
                          numLayer={0}
                          //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                          showCheckbox={true}
                          id={product.ID}
                          uniqueId={product.ID + "_d_" + index}
                          //  type={'product'}
                          IsExisting={true}
                          isOrgProduct={true}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FeaturedProducts title={"Featured Products"} />
              </Grid>
            </>
          )}
        </div>

        <div className={classes.statsContainer}>
          {this.props.userRole === "OrgAdmin" && isShowRequests && <RequestEvaluations />}
          <Grid container spacing={4}>
            {this.props.userRole === "OrgAdmin" && (
              <>
                {/* {EnableReviewForAdminsCheck && <Grid item xs={12} sm={12} md={6} lg={6}>
                <HomeReviews title={"Reviews"} />
              </Grid>}

              {EnableEvaluateForAdminsCheck && this.props.user?.Organization?.ID !== 67 && <Grid item xs={12} sm={12} md={6} lg={6}>
                <Evaluation title={"Evaluation"} />
              </Grid>} */}

                {!isHideMatrix && this.props.user?.Organization?.ID !== 67 && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Metric title="Metrics" tooltip="2" />
                  </Grid>
                )}
              </>
            )}
            {false && this.props.userRole === "OrgUser" && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Actions title={"My Tasks"} />
              </Grid>
            )}
          </Grid>
        </div>

        {false && this.props.userRole === "OrgAdmin" && (
          <div className={classes.statsContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Metric title="Metrics" tooltip="2" />
              </Grid>
              {false && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Actions title={"My Tasks"} />
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Home));
