import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import {Add as AddIcon} from "@material-ui/icons"

import {
    Select,
    MenuItem,
    FormControl,
    Grid,
    CircularProgress,
    Button
} from "@material-ui/core";
import classnames from "classnames";
import { CommonFn } from 'services/commonFn';
import ReviewsEmptyState from "assets/images/reviews_empty_state.svg";
// assets
import CommonCss from "../../../../../commonCss";

// icons
import FilterListIcon from '@material-ui/icons/FilterList';

// components
import Image from 'Components/Common/image';
import ProductsReviewFilterDialog from 'Components/Dialogs/ProductsReviewFilterDialog';

// Redux
import { fetchCompletedReviewProducts } from "redux/product/orgProduct/action";
import { showProductsReviewFilterDialog } from "redux/evaluate/action";

import {
    showMessageDialog,
    hideMessageDialog,
  } from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { deleteProductReview } from "redux/product/orgProduct/action";
import { fetchReviewWorkflows,deleteReview } from "redux/review/action";
import { showSnackBar } from "redux/snackbar/action";
import deleteIcon from "assets/images/delete.svg";

const connectedProps = (state) => ({
    fetchCompletedReviewProductsUnderProgress: state.orgProduct.fetchCompletedReviewProductsUnderProgress,
    completedReviewProducts: state.orgProduct.completedReviewProducts,
    filterData: state.evaluate.productsReviewFilterData, 
    deleteProductReviewProgress: state.orgProduct.deleteProductReviewProgress,
    deleteProductReviewError: state.orgProduct.deleteProductReviewError,
    userType: state.authUser.user?.Role,

    fetchReviewWorkflowsProgress: state.review.fetchReviewWorkflowsProgress,
    fetchReviewWorkflowsError: state.review.fetchReviewWorkflowsError,
    reviewWorkflows: state.review.reviewWorkflows,
    reviewWorkflowsTotal: state.review.reviewWorkflowsTotal,

    deleteReviewProgress: state.review.deleteReviewProgress,
    deleteReviewError: state.review.deleteReviewError,
});

const connectionActions = {
    fetchCompletedReviewProducts: fetchCompletedReviewProducts,
    showProductsReviewFilterDialog: showProductsReviewFilterDialog,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
    deleteProductReview: deleteProductReview,
    showSnackBar: showSnackBar,
    fetchReviewWorkflows: fetchReviewWorkflows,
    deleteReview: deleteReview
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
    createStyles({
        viewAll: CommonCss.viewAll,
        viewAllbutton:{
            ...CommonCss.viewAllbutton,
            // marginRight: 10,
            lineHeight: 0,
            cursor: 'pointer'
        },
        productHead: {
            ...CommonCss.productHead,
            fontSize: 19,
            margin: "0px !important",
            lineHeight: '38px',
            '& span':{
                fontSize: 13,
                fontWeight: 400
            }
        },
        evaluateCard: {
            width: 'calc(100% - 0px)',
            background: '#fff',
            padding: "16px 24px",
            margin: '20px 0px 0px 0px',
            boxShadow: '0px 3px 25px #55555500',
            borderRadius: 5,
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center'
        },
        evaluateCardTitle: {
            color: '#20253A',
            fontSize: 14,
            margin: 0,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        evaluateCardSubTitle: {
            fontSize: 12,
            color: '#A0A5BA',
            marginTop: "5px",
            marginBottom: 0
        },
        evaluateLogo: {
            flex: .8,
            display: 'flex',
            justifyContent: 'flex-end'
        },
        evaluateText: {
            flex: 1.5,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        loader: {
            marginTop: 20,
            marginLeft: 20,
            display: 'block',
            position: 'absolute',
            top: '38%',
            left: '46%'
        },
        noMoreData: {
            marginTop: 30,
            display: 'block'
        },

        productLogo: {
            float: 'left',
            width: '50px',
            height: '50px',
            borderRadius: '4px',
            marginRight: '10px',
            textAlign: 'center',
            lineHeight: '33px',
            fontWeight: 600,
            fontSize: 11,
            color: '#A0A5BA',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'scale-down'
            }
        },
        rightArrow: {
            width: 17
        },
        reviewCompleted: {
            color: '#2EB77D',
            fontSize: 12,
            marginTop: 3,
            marginBottom: 0
        },
        select: {
            margin: 0,
            padding: 0,
        },
        showMore:{
            display: 'inline-block',
            border: '1px solid #FFF',
            cursor: 'pointer',
            padding: '10px 14px',
            fontSize: '12px',
            background: '#4A87F8',
            borderRadius: '20px',
            color: 'white'
        },
        btnReview:{
            width: '38px',
            height: '32px',
            borderRadius: '5px',
            cursor: 'pointer',
            textAlign: 'center',
            fontSize: '13px',
            color: '#626675',
            paddingTop: 2,
            zIndex: 12,
            position: 'relative',
            border: '1px solid #EEEEEE',
            background: '#ffffff'
          },
          DashboardNoDataLayout:{
            width: 'calc(100% - 10px)',
            margin: '22px 0px 0px 0px',
            minHeight: 450,
            textAlign: 'center',
            background: '#ffffff',
            padding: '23px',
            borderRadius: '6px',
            '& button':{
                margin: '20px 0'
            }
          }
    });

class CompletedReviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteId: null,
            query: "",
            page: 0,
            pageSize: 5,
            isCartShow: false,
            selectedReview: 10
        };
    }

    componentDidMount() {
        // this.fetchReviewProducts();
        this.fetchData();
    }

    componentDidUpdate(prevProps){
        if(!this.props.deleteReviewProgress && prevProps.deleteReviewProgress) {
          if(!this.props.deleteReviewError) {
            // this.fetchReviewProducts();
            this.props.showSnackBar('Review deleted successfully', "success", 3000);
          } else {
            this.props.showSnackBar(this.props.deleteReviewError, "error", 3000);
          }
        }
    }

    fetchData = ()=>{    
        if(this.state.selectedReview === 10){
            let data = {};
            data.query = '';
            data.page = this.state.page
            data.pagesize = this.state.pageSize
            data.type = [2]
            data.status = [2,3]
            this.props.fetchReviewWorkflows(data)
        }else{  
            let data = {};
            data.query = '';
            data.page = this.state.page
            data.pagesize = this.state.pageSize
            data.type = [2]
            data.status = [4]
            this.props.fetchReviewWorkflows(data)        
        }
    }

    fetchReviewProducts = () => {
        // In-Progress
        if(this.state.selectedReview === 10){
            let data = {};
            data.page = this.state.page
            data.pagesize = this.state.pageSize
            data.inOrgAndEvaluationInProgress = true
            data.range = {
                greaterThan : -(this.props.filterData?.daterange || 30)
            }
            this.props.fetchCompletedReviewProducts(data)
        }else{
            // Completed
            let data = {};
            data.page = this.state.page
            data.pagesize = this.state.pageSize
            data.inOrgAndEvaluated = true
            data.range = {
                greaterThan : -(this.props.filterData?.daterange || 30)
            }
            this.props.fetchCompletedReviewProducts(data)
        }
    }

    routeChange = (item) => {
        console.log(item,'Itemmmmmm1')
        if (item.WorkflowID !== undefined && item.WorkflowID !== null && item.WorkflowID !== '') {
            this.props.history.push(`/app/evaluate/${item.ID}/workflow/interim`);
        } else {
            let redirectPath = `/app/evaluate/${item.ID}/setup`;
            this.props.history.push(redirectPath);
        }
    }


    deleteReview(e, id) {
        e.stopPropagation();
        var me = this;
        this.setState({deleteId: id}, () => {
            me.props.showMessageDialog();
        })
    }
    
    callBack = () => {
        if (this.state.deleteId) {
            this.props.hideMessageDialog();
            this.props.deleteReview(this.state.deleteId);
        }
    };

    render() {
        const classes = this.props.classes;

        const { reviewWorkflows,reviewWorkflowsTotal } = this.props;
        // console.log('test',this.props.filterData.daterange) bug
        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };

        let userRole = ["OrgAdmin","OrgManager"];
        const adminFlag = userRole.indexOf(this.props.userType) > -1;

        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                        <p className={classnames(classes.productHead)}>Reviews {false &&<span>in last {this.props.filterData?.daterange || 30} days</span>}</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <div style={{    display: 'flex', alignItems: 'center',float: 'right'}}>
                            <div style={{marginRight: 16}}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.select}
                                    margin="dense"
                                    onClick={e => e.stopPropagation()}
                                >
                                    <Select
                                        value={this.state.selectedReview}
                                        onChange={(event) => {
                                            var me = this;
                                            this.setState({ selectedReview: event.target.value, page: 0 }, () => {
                                                me.fetchData();
                                            });
                                        }}
                                        ref={ref => {
                                            if (!ref || !ref.select) {
                                            return;
                                            }
                                
                                            const orig = ref.select.onMenuMouseDown;
                                
                                            ref.select.onMenuMouseDown = function(e) {
                                            e.nativeEvent.stopImmediatePropagation();
                                            orig.call(this, e);
                                            };
                                        }}
                                    >
                                        <MenuItem value={10}>In-Progress</MenuItem>
                                        <MenuItem value={20}>Completed</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {false &&
                                <span className={classes.viewAllbutton} onClick={()=>{this.props.showProductsReviewFilterDialog(this.state.selectedReview)}} >
                                    <FilterListIcon />
                                </span>
                            }
                            
                        </div>
                    </Grid>
                </Grid>
                <div style={{position:'relative'}}>
                    { reviewWorkflows?.length > 0 &&
                        <>
                            {   reviewWorkflows.map((item, index) =>
                                <div className={classes.evaluateCard} key={index} onClick={() => { this.routeChange(item) }}>
                                    <div className={classes.productLogo} title={item.Name}>
                                        <Image src={item?.Products[0]?.ProductLogo} alt={item.Name} title={item.Name} />
                                    </div>
                                    <div className={classes.evaluateText}>
                                        <p className={classes.evaluateCardTitle}>{item.Name}</p>
                                        {/* <p className={classes.reviewCompleted}>Last Reviewed: {CommonFn.formateDate(item.EvaluationUpdatedAt)}</p> */}
                                        {this.state.selectedReview === 20 &&
                                            <p className={classes.reviewCompleted}>Last Reviewed: {CommonFn.formateDate(item.UpdatedAt)}</p>
                                        }
                                        {this.state.selectedReview === 10 &&
                                            <p className={classes.reviewCompleted}>Last Updated: {CommonFn.formateDate(item.UpdatedAt)}</p>
                                        }
                                    </div>
                                    <div className={classes.evaluateLogo}>
                                       {adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={(e) => this.deleteReview(e, item.ID)}><Image 
                                        src={deleteIcon}
                                        alt="forgotpassword_image" 
                                        className={classes.titleImage}
                                        /></button> }
                                    </div>
                                </div>
                            )}
                            {!(reviewWorkflows?.length === reviewWorkflowsTotal) &&
                                <div style={{padding: '16px 0px', textAlign: 'center'}}>
                                    <span 
                                    onClick={()=>{
                                         var me = this;
                                         this.setState({ page: this.state.page + 1 }, () => {
                                             me.fetchData();
                                         });
                                    }}
                                    className={classes.showMore}
                                    >Load more</span>
                                </div>
                            }
                        </>
                    }

                    {this.props.fetchReviewWorkflowsProgress &&
                        <span className={classes.loader}><CircularProgress /></span>
                    }
                    {!this.props.fetchReviewWorkflowsProgress && reviewWorkflows == null &&
                        <div className={classes.DashboardNoDataLayout}>
                            <Image src={ReviewsEmptyState}/> 
                            <h2>No Reviews found</h2>
                            <p>Get started on your first review by tapping New Review below</p>
                            <Button variant="outlined" color="secondary" className={classes.button} startIcon={<AddIcon />} 
                            onClick={() => {this.props.history.push('/app/products/my-product')}}
                            >New Review</Button>
                        </div>
                    }
                </div>
                <MessageDialog messageData={messageData} callBack={this.callBack} />
                <ProductsReviewFilterDialog />
            </>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CompletedReviews));
