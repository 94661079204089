import React, { useState } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions, Select,MenuItem,FormControl,Grid, InputAdornment } from '@material-ui/core';
import DatePicker from "react-datepicker";

// redux
import { hideProductsReviewFilterDialog } from "redux/evaluate/action";
import { fetchCompletedReviewProducts } from "redux/product/orgProduct/action";

//components
// import ToolTip from "Components/Common/ToolTip.jsx";
import Image from 'Components/Common/image.jsx';
import SqureButton from 'Components/Common/SqureButton';
import OutlinedInput from "Components/Common/Input/OutlinedInput";

// assets
import CalendarImage from "assets/images/calendar.svg"

// styles
const useStyles = makeStyles(theme => ({
    label:{
        color: '#6C6C6C',
        fontSize: '1em',
        marginBottom: '0.8em'
    },
    datePicker: {
        '& .react-datepicker-wrapper':{
            width: '100%'
        }
    },
    select:{
        width: '100%',
        marginBottom: 25
    }
}))

// props & state
const connectedProps = (state) => ({
    dialogState: state.evaluate.productsReviewFilterDialog, 
    productsReviewFilterData: state.evaluate.productsReviewFilterData
});

const connectionActions = {
    hideProductsReviewFilterDialog: hideProductsReviewFilterDialog,
    fetchCompletedReviewProducts: fetchCompletedReviewProducts
};

var connector = connect(connectedProps, connectionActions);

function ProductsReviewFilterDialog(props) {

    const [page, setPage] = useState(0);
    const [pagesize, setPagesize] = useState(5);

    const [startDate, setStartDate] = useState(new Date());

    const classes = useStyles();

    // const getdays =(date)=> {
    //     var a = moment(new Date());
    //     var b = moment(date);
    //     return a.diff(b, 'days')
    // }

    const updateFilter = () => {
        const date1 = new Date();
        const date2 = startDate;
        const diffTime = Math.abs(date1 - date2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        // In-Progress
        if(props.selectedReview == 10){
            let data = {};
            data.page = page
            data.pagesize = pagesize
            data.inOrgAndEvaluationInProgress = true
            data.range = {
                greaterThan : -(diffDays - 1)
            }
            props.fetchCompletedReviewProducts(data)
        }else{
            // Completed
            let data = {};
            data.page = page
            data.pagesize = pagesize
            data.inOrgAndEvaluated = true
            data.range = {
                greaterThan : -(diffDays - 1)
            }
            props.fetchCompletedReviewProducts(data)
        }
        let sort_data = {};
        sort_data.daterange = diffDays - 1;

        props.hideProductsReviewFilterDialog(sort_data)
    }

  return (
    <div style={{maxWidth:420}}>
      <Dialog open={props.dialogState} onClose={props.hideProductsReviewFilterDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reviews Filter</DialogTitle>
        <DialogContent style={{maxWidth: 420}}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.datePicker}>

                    <DatePicker
                        customInput={
                            <OutlinedInput
                                label="Start Date"
                                startAdornment={
                                    <InputAdornment>
                                        <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                    </InputAdornment>
                                }
                            />
                        }
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl variant="outlined" className={classes.select} margin="dense">
                        <label className={classes.label}>Criticality</label>
                        <Select value={10} disabled>
                            <MenuItem value={10}>High</MenuItem>
                            <MenuItem value={20}>Medium</MenuItem>
                            <MenuItem value={30}>Low</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl variant="outlined" className={classes.select} margin="dense">
                    <label className={classes.label}>Risk</label>
                        <Select value={10} disabled>
                            <MenuItem value={10}>Security</MenuItem>
                            <MenuItem value={20}>Privacy</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions style={{padding: '20px 25px'}}>
            <SqureButton variant={"outlined"} onClick={props.hideProductsReviewFilterDialog}>Cancel</SqureButton>
            <SqureButton variant={"contained"} onClick={()=>{updateFilter()}}>Submit</SqureButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connector(ProductsReviewFilterDialog);
