import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {  Typography, Slider, Grid, Button } from '@material-ui/core';
import DoneIcon from "@material-ui/icons/Done";

const connectedProps = (state) => ({
});

const connectionActions = {
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        background: '#f1f1f1',
        color: "#fff",
        padding: '1px',
        width: '100%'
    },
    rating: {
        marginTop: '10px',
        marginBottom: '5px',
        '& [class*="MuiSlider-markActive"]':{
            background : '#bfb4b4'
        }
    },
    action: {
        marginTop: '10px',
        textAlign: "right"
    },
    saveBtn: {
        color: "#1f73b7",
        border: '1px solid #1f73b7',
        padding:'2px'
    },
    saveBtn1:{
        color: "#fff",
        border: '1px solid #1f73b7',
        padding:'2px',
        background:"#1f73b7"
    },
    ratingValue: {
        color: "#1f73b7"
    },
    thumb: {
        color: "#1f73b7"
    },
    track: {
        backgroundColor: "#1f73b7"
    },
    rail: {
        backgroundColor: "#f1f1f1"
    },
    resListItem: {
        marginRight: '5px',
        marginLeft: '5px',
        borderRadius: '4px',
        border: '1px solid #707070',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: theme.spacing(1.8),
        color: '#707070',
        padding: '3px',
        width: '100%'
    },
    slider: {
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '2px 10px',
        borderRadius: '10px',
    },
    sliderCount: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
    },
    sliderCount1:{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
        cursor:'pointer' 
    },
    counter1:{
        textAlign:'left',
        paddingLeft:6
    },
    counter2:{
        textAlign:'right',
        paddingRight:6
    },
    counterItem:{
        color:'#1f73b7'
    }
});

class RatingPoper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            isChanges : false,
            isShow: true
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value,
                isShow:false
            })

            setTimeout(() => {
                this.setState({
                    isShow:true,
                    isChanges : false,
                })
            }, 1000);
        }
    }

    render() {
        const classes = this.props.classes;

        const { minValue,maxValue,step} = this.props;
        return <div className={classes.root}>
            <Grid container className={classes.rating}>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} className={classes.slider}>
                    {this.state.isShow && <Slider
                        defaultValue={this.props.value}
                        // value={this.props.value}
                        valueLabelDisplay="auto"
                        step={step}
                        marks={false} 
                        min={minValue}
                        max={maxValue}
                        onChange={(event, value) => {
                            this.setState({
                                value: value,
                                isChanges: true
                            }, () => {
                                // this.props.onSave(this.state.value)
                            })
                        }}
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail
                        }}
                    />}
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.sliderCount}>
                    <Typography className={classes.resListItem}>{this.state.value}</Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.sliderCount}>
                    <Button 
                    className={this.state.isChanges ? classes.saveBtn1 : classes.saveBtn} onClick={() => {
                        this.setState({
                            isChanges: true
                        },()=>{
                            this.props.onSave(this.state.value)
                        })
                    }}><DoneIcon /></Button>
                </Grid>

                <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className={classes.counter1}>
                    <Typography className={classes.counterItem}>1</Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className={classes.counter2}>
                    <Typography className={classes.counterItem}>{maxValue}</Typography>
                </Grid>
            </Grid>
        </div>
    }
}

RatingPoper.defaultProps = {
    minValue : 1,
    maxValue:5,
    step:0.01
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(RatingPoper));