import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from "react-router-dom";
import SettingsCard from "./SettingsCard";
import Integrations from "./Integrations/Integrations";
import IntegrationDetails from "./Integrations/IntegrationDetails";
import DragAndDropSection from "./WorkflowBuilder";
import ComponentList from "./WorkflowBuilder/ComponentList";
import OktaSSOIntegration from "./OktaSSOIntegration";
import AzureSSOIntegration from "./AzureSSOIntegration";
import SlackIntegration from "./SlackIntegration";
import UsersSettings from "./UserAndGroups/UsersSettings";
import Products from "./Products";
import Payments from "./Payments";
import CompanyProfile from "./CompanyProfile/index";
import ExploreProducts from "../../../Common/ExploreProducts";
import { updateDrawerStyle } from "redux/drawer/action";
import Templates from "./Templates";
import FormTemplates from "./Templates/Forms"
import Template from "./Templates/template";
import ChooseTemplate from "./Templates/Common";
import Configurations from "./Configurations/index";
import Quicksight from "./Quicksight/index";
import GeneralConfigurations from "./Configurations/general"
import OrgProductDetailsConfigurations from "./Configurations/CustomFields/index"
import VendorAndServiceCatalog from "./Configurations/VendorAndServiceCatalog"
import FormDetails from "./Templates/Forms/formDetails";
import MasterWorkflow from './WorkflowBuilder/MasterWorkflow';
import Alerts from "./Alerts";
import OAuth from './OAuth';
import DocumentTypes from "./Configurations/DocumentTypes"
import DocumentTypesConfig from "./Configurations/DocumentTypes/DocumentTypesConfig"
import DocumentTypesVisibility from "./Configurations/DocumentTypes/DocumentTypesVisibility"
import Automations from './Automations';
import Departments from './Departments';
import Reports from './Reports';

const connectedProps = (state) => ({
});

const connectionActions = {
    updateDrawerStyle: updateDrawerStyle
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
});

class SettingsIndex extends React.Component {

    componentDidMount() {
        this.props.updateDrawerStyle(true, true);
    }
    render() {
        return <Switch>
            <Route exact path={this.props.match.path} component={SettingsCard} />
            <Route exact path={`${this.props.match.path}/form-builder`} component={ComponentList} history={this.props.history} />
            <Route exact path={`${this.props.match.path}/form-builder/default/:id`} component={DragAndDropSection} history={this.props.history} />
            <Route exact path={`${this.props.match.path}/form-builder/master/:id`} component={MasterWorkflow} history={this.props.history} />
            <Route exact path={`${this.props.match.path}/integrations`} component={Integrations} />
            <Route exact path={`${this.props.match.path}/integrations/:id`} component={IntegrationDetails} />
            <Route exact path={`${this.props.match.path}/okta-sso-intergration`} component={OktaSSOIntegration} />
            <Route exact path={`${this.props.match.path}/azure-sso-intergration`} component={AzureSSOIntegration} />
            <Route exact path={`${this.props.match.path}/slack-notifications-intergration`} component={SlackIntegration} />
            <Route exact path={`${this.props.match.path}/users`} component={UsersSettings} />
            <Route exact path={`${this.props.match.path}/products`} component={Products} />
            <Route exact path={`${this.props.match.path}/company-profile`} component={CompanyProfile} />
            <Route exact path={`${this.props.match.path}/payment`} component={Payments} />
            <Route exact path={`${this.props.match.path}/configurations`} component={Configurations} />
            <Route exact path={`${this.props.match.path}/configurations/general`} component={GeneralConfigurations} />
            <Route exact path={`${this.props.match.path}/configurations/org-product-details`} component={OrgProductDetailsConfigurations} />
            <Route exact path={`${this.props.match.path}/configurations/org-vendor-details`} render={(props) => <VendorAndServiceCatalog {...props} type={'vendor'} />} />
            {/* <Route exact path={`${this.props.match.path}/configurations/org-service-details`} render={(props) => <VendorAndServiceCatalog {...props} type={'service'} />} /> */}
            <Route exact path={`${this.props.match.path}/configurations/org-vendor-service-details`} render={(props) => <VendorAndServiceCatalog {...props} type={'vendorservice'} />} />
            <Route exact path={`${this.props.match.path}/configurations/document-types`} component={DocumentTypes} />
            <Route exact path={`${this.props.match.path}/configurations/document-types/:id`} component={DocumentTypesConfig} />
            <Route exact path={`${this.props.match.path}/configurations/document-product-types/:id`} component={DocumentTypesVisibility} />
            <Redirect exact from={`${this.props.match.path}/products/current-products`} to={"/app/products/org-products"} />
            <Route exact path={`${this.props.match.path}/products/explore-products`} component={ExploreProducts} />
            <Route exact path={`${this.props.match.path}/templates`} component={Templates} />
            <Route exact path={`${this.props.match.path}/templates/evaluation`} component={Template} />
            <Route exact path={`${this.props.match.path}/templates/evaluation/:template/:id`} component={ChooseTemplate} />
            <Route exact path={`${this.props.match.path}/templates/forms`} component={FormTemplates} />
            <Route exact path={`${this.props.match.path}/templates/forms/:form/:id`} component={FormDetails} />
            <Route exact path={`${this.props.match.path}/quicksight`} component={Quicksight} />
            <Route path={`${this.props.match.path}/alerts`} component={Alerts} />
            <Route path={`${this.props.match.path}/oauth`} component={OAuth} />
            <Route path={`${this.props.match.path}/automations`} component={Automations} />
            <Route path={`${this.props.match.path}/departments`} component={Departments} />
            <Route path={`${this.props.match.path}/reports`} component={Reports} />
        </Switch>
    }
}

export default connector(compose(
    withStyles(styles)
)(SettingsIndex));