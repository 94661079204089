import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import {
  MenuItem,
  Popover,
  Button,
  Divider,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import { withRouter, Link } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from "@material-ui/lab/Alert";

//components
import AddProductButton from 'Components/Common/AddProductButton'
import AppBar from "../../AppBar";
import ProductUploadDialog from "./UserAndGroups/UserAndProductUploadDialog";
import AddProductManuallyDialog from "./AddProductManuallyDialog";
import CommonCss from "../../../../commonCss";
import Image from 'Components/Common/image.jsx'
import ComingSoon from "../../../Common/ComingSoon";
import CartDrawer from "Components/Application/CartDrawer";

// assets
import DefaultImage from "assets/images/evaluation/default.svg";
import EvaluationEmptyState from "assets/images/evaluation_empty_state.svg";
import FeedbackCS from "../../../../assets/images/home/feedback_comming_soon.svg";

// components
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";


// Redux
import { showUserUploadDialog } from "redux/usersSettings/action";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import {fetchOrgProducts} from "redux/product/orgProduct/action";
import ToolTip from "Components/Common/ToolTip.jsx";
import ComingSoonDialog from "Components/Common/ComingSoonDialog";
import {createEvaluationCart,removeProductFromCart,fetchEvaluationCart} from "redux/evaluation/cart/action";
import {showSnackBar} from "redux/snackbar/action";

const connectedProps = (state) => ({
  productFecthProgress:state.orgProduct.orgProductFetchProg,
  products:state.orgProduct.orgProducts,
  totalOrgProducts:state.orgProduct.totalOrgProducts,
  evaluationCart: state.evaluationCart.evaluation,
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
});

const connectionActions = {
  showUserUploadDialog: showUserUploadDialog,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  fetchOrgProducts: fetchOrgProducts,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    thead: {
      width: "20%",
    },
    action: {
      width: "15%",
    },
    userCount: {
      color: "#282D30",
      fontSize: theme.spacing(2.5),
    },
    userSection: {
      marginTop: theme.spacing(4.2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    menuItem: {
      color: "#74798C",
      fontSize: theme.spacing(2),
      padding: theme.spacing(2.2),
      minWidth: 140,
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },

    searchInput: {
      border: "1px solid #EEEEEE",
      borderRadius: 5,
      height: 32,
      minWidth: 300,
      paddingLeft: 28,
    },
    search: {
      position: "relative",
      "& [class*='MuiSvgIcon-root']": {
        position: "absolute",
        top: 4,
        width: 20,
        left: 5,
        color: "#999999",
      },
    },

    subTitle: {
      fontSize: 20,
      color: "#282D30"
    },

    primary: {
      ...CommonCss.primary,    
      fontWeight: 600,
      lineHeight: 1.334,
      fontSize: '1em'
    },
    secondary: CommonCss.textAlignCenter,
    content: {
      ...CommonCss.content,
      flex: 2,
      display: 'flex',
      alignItems: 'center'
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
    },
    productTitleSection: {
      ...CommonCss.productTitleSection,
    },
    cardAction: {
      ...CommonCss.cardAction,
      flex: 1,
      "> div": {
        flex: 'none'
      }
    },
    cardSection: {
      ...CommonCss.cardSection,
      minHeight: 174,
      "& [class*='MuiGrid-item']": {
        padding: 10,
        display: 'flex'
      },
      "& [class*=LayeredCard-root]": {
        marginTop: 0
      }
    },
    products: {
      display: "flex",
    },

    productMain: {
      flex: 1,
    },

    productMenu: {
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000B",
      padding: theme.spacing(4, 0, 4, 3.2),
      // width: 250,
      minHeight: "100vh",
      position: "fixed",
    },
    menuHead: {
      display: "flex",
      marginBottom: 18,
    },
    categoriesText: {
      color: "#000000",
      fontSize: 18,
      marginLeft: 16,
    },
    allProductsText: {
      color: "#4A87F8",
      background: "#F2F7FF",
      fontSize: 13,
      padding: 8,
      marginRight: theme.spacing(3.2),
      paddingLeft: theme.spacing(4),
    },
    categoriesSubText: {
      color: "#696B6E",
      paddingLeft: theme.spacing(2),
      margin: theme.spacing(1.2, 0),
      paddingRight: theme.spacing(2),
    },
    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 0),
      cursor: "pointer",
      display: "block",
    },
    categoriesList: {
      overflowY: "overlay",
      height: "calc(100vh - 180px)",

      "& [class*='MuiSvgIcon-root']": {
        fontSize: 30,
        fill: "#696B6E",
      },

      "& [class*='MuiPaper-root']": {
        boxShadow: "none",

        "&:before": {
          content: "inherit",
        },
      },

      "& [class*='MuiAccordion-root']": {
        margin: "0 !important",
      },

      "& [class*='MuiAccordionSummary-content']": {
        margin: 0,
      },

      "& [class*='MuiAccordionSummary-root']": {
        flexDirection: "row-reverse",
        padding: 0,
        minHeight: "inherit !important",
      },

      "& [class*='MuiAccordionSummary-expandIcon']": {
        flexDirection: "row-reverse",
        padding: 0,
        "&.Mui-expanded ": {
          transform: "rotate(90deg)",
        },
      },

      "& [class*='MuiAccordionDetails-root']": {
        flexDirection: "column",
        padding: theme.spacing(0, 0, 0, 4.6),
      },
    },
    subCategory: {
      overflowY: "overlay",
      height: 250,
      marginRight: theme.spacing(1),
    },
    skeleton: {
      height: '174px',
      width: '198px',
      // marginTop: 37,
      borderRadius: 10
    }, 
    skeletionSection: {
      width: '100%',
      display: 'flex'
    },
    noMoreText: {
      width: '100%',
      textAlign: 'center',
      paddingTop: 10
    },
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
    DashboardNoDataLayout:{
      margin: '22px 0px 0px 0px',
      minHeight: 450,
      textAlign: 'center',
      // background: '#ffffff',
      padding: '23px',
      borderRadius: '6px',
      '& button':{
          margin: '20px 0',
          color: '#fff',
          background: '#3C3C3C',
          '&:hover':{
            color: '#FFF',
            background: '#3C3C3C',
        }
      }
    },
    loader:{
      position: 'fixed',
      right: '45%',
      top: '40%'
    }
  });

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        error: '',
        page: 0,
        pageSize: 60,
        query: '',
        openBulkCommingSoonModal: false,
        isCartShow: false,
    }
  }


  componentDidMount() {
    this.fetchVendorData()
  }

  fetchVendorData =()=> {
    this.props.fetchOrgProducts(this.state.page,this.state.pageSize,this.state.query);
    var me = this;
    setTimeout(() => {
      me.props.fetchEvaluationCart(null);
    }, 1000);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  navigate = (menu) => {
    if (menu.link) {
      this.props.history.push(menu.link);
    } else if (menu.key === "addManually") {
      this.props.showAddProductManuallyDialog();
    } else if (menu.key === "bulkUpload") {
      // this.props.showUserUploadDialog();

      this.setState({
        openBulkCommingSoonModal:true
      })
    }
    this.handleClose();
  };

 
  handleChange = (panel) => {
    this.setState({ expanded: panel === this.state.expanded ? false : panel });
    console.log(this.state.expanded, "panel");
  };

  addToCart =(item)=> {
    item.ID = item.ID || parseInt(item.application_id)
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.ID);
      this.props.showSnackBar("Product added to evaluation","success",3000);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(item.ID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
      } else {
        this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
        this.props.showSnackBar("Product added to evaluation","success",3000);
      }
    }
  }


  cartOpen = () => {
    this.setState({ isCartShow: true });
  };

  cartClose = () => {
   this.setState({ isCartShow: false });
  };

  reviewProduct = (id) => {
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  }


  render() {
    const classes = this.props.classes;


    if(this.props.productFecthProgress){
      return(<span className={classes.loader}><CircularProgress /></span>)
    }

    if(!this.props.productFecthProgress && this.props.totalOrgProducts === 0){
      return(<>
      <div className={classes.DashboardNoDataLayout}>
          <Image src={EvaluationEmptyState}/> 
          <h2>No Products found</h2>
          <p>Get started on your first review or evaluation by tapping on Add Vendor below</p>
          <AddProductButton title={'Add Product'}/>
      </div>
      {
          this.props.addProductManuallyDialogOpen &&
          <AddProductManuallyDialog />
      }
      </>)
    }

    const isCart = this.props.evaluationCart !== null && this.props.evaluationCart !== '' && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length > 0;

    const newUserOptions = [
      { url: "", label: "Add Manually", key: "addManually" },
      { url: "", key: "bulkUpload", label: "Bulk Upload" },
    ];

    const open = Boolean(this.state.anchorEl);
    const id = open ? "newUserPopover" : undefined;

    return (
      <div className={classes.products}>
        {/* <div className={classes.productMenu}>
          <div className={classes.menuHead}>
            <Image src={Category} alt="Categories" />
            <p className={classes.categoriesText}>Categories</p>
          </div>
          <p className={classes.allProductsText}>All products</p>
          <div className={classes.categoriesList}>
            {categories.map((category, index) => (
              <Accordion
                key={index}
                expanded={this.state.expanded === `panel${index}`}
                onChange={() => this.handleChange(`panel${index}`)}
              >
                <AccordionSummary expandIcon={<ArrowRightIcon />}>
                  <p className={classes.categoriesSubText}>{category.label}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.subCategory}>
                    {category.list.map((subCategory, index) => (
                      <a herf="#" key={index} className={classes.innerText}>
                        {subCategory.label}
                      </a>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div> */}
        <div className={classes.productMain}>
          <AppBar title="Products" withBack={true} />
          <div className={classes.userSection}>
            {/* <p className={classes.subTitle}>List of products</p> */}
            <div className={classes.search}></div>
            <div>
              <Button className={classes.newUserBtn} onClick={this.handleClick}>
                + Add vendor
              </Button>
              <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={() => this.handleClose()}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {newUserOptions?.map((item, childIndex) => {
                  return (
                    <div key={childIndex}>
                      {" "}
                      <MenuItem
                        value={item.label}
                        className={classes.menuItem}
                        onClick={() => this.navigate(item)}
                      >
                        {item.label}
                      </MenuItem>{" "}
                      <Divider />
                    </div>
                  );
                })}
              </Popover>
            </div>
          </div>
          <div className={classes.alert}>
            {this.state.error !== "" && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
          </div>
          <div>
            <div className={classes.productTitleSection}>
              <p className={classes.productHead}>Current products <ToolTip toolTip="5"/></p>
              {this.props.products?.length > 6 && <Link
                to="/app/products/org-products"
                className={classes.viewAll}
              >
                View all ({this.props.totalOrgProducts})
              </Link>}
            </div>
            <div className={classes.cardSection}>
              <Grid container spacing={3}>
                {!this.props.productFecthProgress && this.props.products.map((product, index) => 
                  {
                    return ( index < 6 && <Grid item lg={2} md={3} key={index}>
                       <LayeredCard
                       title={product.Name}
                       subTitle={product.CompanyName}
                       description={product.Description}
                       image={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage:product.ProductLogo}
                       toogleCheck={() => {
                        this.reviewProduct(product.ID)
                       }}
                       onClick={()=>{}}
                       numLayer={0}
                      //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                       showCheckbox={true}
                       id={product.ID}
                       uniqueId={product.ID+'_i_'+index}
                      //  type={'product'}
                       IsExisting={product.IsExisting}
                    />
                    </Grid>
                    )}
                )}
                {!this.props.productFecthProgress &&  this.props.products?.length === 0 && <p className={classes.noMoreText}>No more data</p>}
                 {this.props.productFecthProgress && <div className={classes.skeletionSection}>
               <Grid item lg={2} md={3}> <Skeleton variant="rect" className={classes.skeleton} /></Grid>
               <Grid item lg={2} md={3}> <Skeleton variant="rect" className={classes.skeleton} /></Grid>
               <Grid item lg={2} md={3}> <Skeleton variant="rect" className={classes.skeleton} /></Grid>
               <Grid item lg={2} md={3}> <Skeleton variant="rect" className={classes.skeleton} /></Grid>
               <Grid item lg={2} md={3}> <Skeleton variant="rect" className={classes.skeleton} /></Grid>
               <Grid item lg={2} md={3}> <Skeleton variant="rect" className={classes.skeleton} /></Grid>
               </div> }
              </Grid>
            </div>
            <div className={classes.productTitleSection} style={{marginTop: 30}}>
              <p className={classes.productHead}>Explore products <ToolTip toolTip="6"/></p>
              {/* <Link
                to="/app/settings/products/explore-products"
                className={classes.viewAll}
              >
                View all (120)
              </Link> */}
            </div>
            <ComingSoon
            feature="Products"
                image={FeedbackCS}
                title={""}
                description={
                  "Coming Soon"
                }
                link="https://google.com"
              />
          </div>
        </div>
        <ProductUploadDialog isFromProduct={true} />
        {
            this.props.addProductManuallyDialogOpen &&
            <AddProductManuallyDialog />
        }
        <ComingSoonDialog
                    feature="Product Bulk Upload "
                    comingSoonOpen={this.state.openBulkCommingSoonModal}
                    closeDownload={()=>{
                      this.setState({openBulkCommingSoonModal:false})
                    }}
                    description={'Product Bulk Upload. Interested in learning more?'}
                />

          <div id="homeCart"
              onMouseEnter={() => this.cartOpen()}
              onMouseLeave={() => this.cartClose()}
              className={classnames(
                  classes.cartSection,
                  this.state.isCartShow ? "showCart" : ""
              )}
              >
              {isCart && (
                  <div>
                  <CartDrawer/>
                  </div>
              )}
          </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Products));
