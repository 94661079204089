import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography } from "@material-ui/core";
import _ from "underscore";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";

import { showUserCountDetailsDialog } from "redux/evaluation/feedback/action";
import { setSelectedRequirement, setSelectedProduct, fetchRequirementTree } from "redux/evaluation/criteria/action";
// import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from "@material-ui/core";
// import Tooltip from "@material-ui/core/Tooltip";
// import AnsweredIcon from 'assets/images/evaluation/reqAnswered.svg';

import Image from "Components/Common/image.jsx";
import { showNudgeNotificationDialog } from "redux/notification/action";
import { hideOverallReqDialog } from "redux/evaluation/feedback/action";

import UserDetailsDialog from "./UserDetailsDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.evaluationFeedback.showOverallRequirementDialog,
  isShowNextButton: state.evaluationFeedback.isShowNextButton,
  isFromNextButton: state.evaluationFeedback.isFromNextButton,
  evaluation: state.evaluationMetaData.evaluation,
  requirementTree: state.evaludationCriteria.requirementTree,
  requirementUserResponsesStatus: state.evaludationCriteria.requirementUserResponsesStatus,
  orgRequirementProgressStatus: state.evaludationCriteria.orgRequirementProgressStatus,
  user: state.authUser.user,
  criterias: state.evaludationCriteria.criterias,
});

const connectionActions = {
  hideDialog: hideOverallReqDialog,
  showUserCountDetailsDialog: showUserCountDetailsDialog,
  setSelectedRequirement: setSelectedRequirement,
  setSelectedProduct: setSelectedProduct,
  fetchRequirementTree: fetchRequirementTree,
  showNudgeNotificationDialog: showNudgeNotificationDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    actions: {
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    actionBtn: {
      minWidth: "200px",
    },
    actionBtnDisabled: {
      minWidth: "200px",
    },
    dialogContent: {
      margin: "auto",
      marginBottom: 60,
      "& [class*=MuiAvatarGroup-root]": {
        justifyContent: "center",
        cursor: "pointer",
      },
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    productTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    title: {
      textAlign: "center",
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
      margin: "20px 0px 20px",
    },
    subTitle: {
      textAlign: "center",
      fontWeight: 400,
      color: "#282D30",
      fontSize: 17,
      margin: "20px 0px 20px",
    },
    prdHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    productName: {
      fontWeight: 600,
      marginLeft: theme.spacing(1),
      fontSize: theme.spacing(2.2),
      color: "#282D30",
    },
    productImg: {
      width: theme.spacing(3),
      marginRight: theme.spacing(0.6),
    },
    legendBox: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
    reqName: {
      width: "400px",
      minWidth: "400px",
      paddingLeft: "30px !important",
    },
    criteriaName: {
      width: "400px",
      minWidth: "400px",
      fontWeight: 600,
    },
    prdName: {
      textAlign: "center",
    },
    currProductTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    finalizeForm: {
      "& [class*=MuiTable-root]": {},
      "& [class*=MuiTableCell]": {
        border: "1px solid #DEDEDE",
      },
      "& [class*=MuiTableCell-root]": {
        padding: theme.spacing(1, 1),
      },
    },
    mandatoryTable: {
      borderCollapse: "separate !important",
      width: "100%",
      borderSpacing: 0,
      borderRadius: "4px",
      marginTop: 5,
    },
    otherTable: {
      borderCollapse: "separate !important",
      width: "100%",
      borderSpacing: 0,
      borderRadius: "4px",
      border: "1px solid #DEDEDE",
    },
    pd_action: {
      letterSpacing: "0px",
      color: "#0093E0 !important",
      opacity: 1,
      cursor: "pointer",
      fontSize: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    mandatoryItems: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    userCountCls: {
      cursor: "pointer",
      color: "#398AF5",
    },
    otherProductTotal: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      color: "#282D30",
      opacity: "0.6",
      fontSize: theme.spacing(2),
    },
    UserInfo: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    UserInfoTick: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      flexDirection: "column",
    },
    tickWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: 5,
    },
    assignedTo: {
      // width: "30%",
    },
    pdrName: {
      width: "25%",
    },
    checkmarkWraper: {
      // width: "200px",
    },
    subTable: {
      padding: "0px !important",
      border: "none !important",
    },
    flexWrapIcon: {
      display: "flex",
      gap: "0.5rem",
      fontWeight: "600",
      marginBottom: "0.2rem",
    },
    flexWrap: {
      marginBottom: "0.7rem",
    },
    nudgeBtn: {
      border: "1px solid #bda8a8",
      color: "#1f73b7",
      padding: 0,
      marginRight: "15px",
    },
    required: {
      color: "red",
      display: "inline",
      fontSize: "16px",
    },
  });

class OverallRequiremntViewDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      feedback: "",
      feedback_error: "",
      products: [],
    };
    this.showUserDetails = this.showUserDetails.bind(this);
    this.avatarName = this.avatarName.bind(this);
  }

  componentDidUpdate(prevProps) { }

  showUserDetails(userDetails) {
    this.props.showUserCountDetailsDialog(userDetails);
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  render() {
    const { orgRequirementProgressStatus, criterias } = this.props;
    const classes = this.props.classes;
    var reqTreeData = orgRequirementProgressStatus === null ? [] : orgRequirementProgressStatus;
    var eva_products = this.props.evaluation !== undefined ? [...this.props.evaluation.Products] : [];
    var products = [];
    eva_products.forEach(function (item) {
      var productName = item?.Name;
      var productLogo = item?.ProductLogo;
      products.push({
        productName: productName,
        productLogo: productLogo,
        id: item.ID,
      });
    });

    var reqStatusMap = {};
    let requirementUsersMap = {};
    reqTreeData.forEach(function (data) {
      var CriteriaID = data.CriteriaID;
      var requirement = data.RequirementName;
      var requirementId = data.RequirementID;
      if (requirementUsersMap[requirementId] === undefined) {
        requirementUsersMap[requirementId] = requirement;
      }
      var productId = data.ProductID;
      var userId = data.User.ID;
      if (reqStatusMap[CriteriaID] === undefined) {
        reqStatusMap[CriteriaID] = {};
        reqStatusMap[CriteriaID][requirementId] = {};
        reqStatusMap[CriteriaID][requirementId][userId] = [];
        reqStatusMap[CriteriaID][requirementId][userId].push(productId);
        reqStatusMap[CriteriaID][requirementId]["users"] = [];
        reqStatusMap[CriteriaID][requirementId]["users"].push(data.User);
      } else if (reqStatusMap[CriteriaID][requirementId] === undefined) {
        reqStatusMap[CriteriaID][requirementId] = {};
        reqStatusMap[CriteriaID][requirementId][userId] = [];
        reqStatusMap[CriteriaID][requirementId][userId].push(productId);
        reqStatusMap[CriteriaID][requirementId]["users"] = [];
        reqStatusMap[CriteriaID][requirementId]["users"].push(data.User);
      } else if (reqStatusMap[CriteriaID][requirementId][userId] === undefined || reqStatusMap[CriteriaID][requirementId]["users"] === undefined) {
        reqStatusMap[CriteriaID][requirementId][userId] = [];
        reqStatusMap[CriteriaID][requirementId][userId].push(productId);
        if (reqStatusMap[CriteriaID][requirementId]["users"] === undefined) {
          reqStatusMap[CriteriaID][requirementId]["users"] = [];
        }
        reqStatusMap[CriteriaID][requirementId]["users"].push(data.User);
      } else {
        reqStatusMap[CriteriaID][requirementId][userId].push(productId);
        reqStatusMap[CriteriaID][requirementId]["users"].push(data.User);
      }
    });

    let userCellWidth = 100 / (products.length + 1) + '%';

    console.log(this.props.isFromNextButton, 'isFromNextButton')
    console.log(requirementUsersMap, 'requirementUsersMap')
    console.log(reqStatusMap, 'reqStatusMap')

    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="app-integrationDialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        scroll="body"
        id="evMetaDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideDialog()}>
            <CloseIcon />
          </div>

          <div className={classes.finalizeForm}>
            <Typography variant={"h4"} className={classes.title}>
              {this.props.isFromNextButton ? 'Sorry You Can not Move Forward' : 'Overall Response Status'}
            </Typography>
            {this.props.isFromNextButton && <Typography variant={"h5"} className={classes.subTitle}>
              There are other colloborators who are yet to finish their mandatory questions.
            </Typography>}
            <div className={classes.flexWrap}>
              <div className={classes.flexWrapIcon}>
                <CheckCircleRoundedIcon style={{ fontSize: "1.2rem", color: "#2eb77d" }} /> Responded
              </div>
              <div className={classes.flexWrapIcon}>
                <CancelRoundedIcon style={{ fontSize: "1.2rem", color: "#e11e5a" }} /> Not responded
              </div>
              <div className={classes.flexWrapIcon}>
                <CheckCircleRoundedIcon style={{ fontSize: "1.2rem", color: "#ffb800" }} /> Not Assigned but resoponed
              </div>
            </div>
            {false && (
              <div style={{ textAlign: "right" }}>
                <Typography variant={"h6"} className={classes.otherProductTotal}>
                  Total user response count
                </Typography>
              </div>
            )}
            <div style={{ overflowX: 'auto' }}>
              <Table className={classes.mandatoryTable} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.criteriaName}></TableCell>
                    <TableCell colSpan={products.length + 1} className={classes.subTable}>
                      <Table>
                        <TableRow>
                          <TableCell style={{ width: userCellWidth, minWidth: '250px' }}>Users</TableCell>
                          {products.map((prd) => {
                            return (
                              <>
                                <TableCell className={classes.prdName} style={{ width: userCellWidth, minWidth: '250px' }} align="center">
                                  {" "}
                                  <div className={classes.prdHead}>
                                    <Image alt="Mandatory" className={classes.productImg} src={prd.productLogo} /> &nbsp;&nbsp;{prd.productName}
                                  </div>
                                </TableCell>
                              </>
                            );
                          })}
                        </TableRow>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {criterias &&
                    criterias.map((criteria, i) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell colSpan={products.length + 2} className={classes.criteriaName}>
                              {criteria.name}
                            </TableCell>
                          </TableRow>
                          {criteria.requirements.map((req) => {
                            let reqArr = reqStatusMap[criteria.id];
                            let allRespondedUsers = [];
                            let productWiseRespondedUsers = {};
                            if (reqArr && reqArr[req.id]) {
                              if (reqArr[req.id]["users"]) {
                                allRespondedUsers = [...reqArr[req.id]["users"]];
                              }
                              productWiseRespondedUsers = reqArr[req.id];
                            }

                            let AllUsersWithDuplicate = [...allRespondedUsers, ...req.colloborator, ...criteria.colloborator];
                            let allAssignedUsers = [...req.colloborator, ...criteria.colloborator];
                            let allAssignedUsersIDs = allAssignedUsers.map((user) => user.ID);

                            //uniq users
                            let Users = _.uniq(AllUsersWithDuplicate, function (x) {
                              return x.ID;
                            });

                            return (
                              <TableRow>
                                <TableCell className={classes.reqName}>
                                  {req.name} {req.is_mandatory && <div className={classes.required}>*</div>}
                                </TableCell>
                                <TableCell colSpan={products.length + 1} className={classes.subTable}>
                                  <Table>

                                    {Users.length ? Users.map((user, index) => {
                                      return (
                                        <TableRow>
                                          <TableCell style={{ width: userCellWidth, minWidth: '250px' }}>
                                            <div className={classes.UserInfo}>
                                              <Tooltip
                                                placement="top"
                                                title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{user.Email}</span>}
                                                arrow
                                              >
                                                <div className={classes.itemsBorderBottom}>{user.Name}</div>
                                              </Tooltip>
                                              <Button
                                                onClick={(event) => {
                                                  this.props.showNudgeNotificationDialog(user, {
                                                    type: "notification.evaluation.criteria.approver.response.nudge",
                                                    criteria: criteria,
                                                  });
                                                }}
                                                className={classes.nudgeBtn}
                                                type="button"
                                              >
                                                Nudge
                                              </Button>
                                            </div>
                                          </TableCell>
                                          {products.map((prd) => {
                                            // if (!allAssignedUsersIDs.includes(user.ID) && allResponedUsersIDs.includes(user.ID)) {
                                            //   return (
                                            //     <TableCell className={classes.checkmarkWraper} style={{ width: userCellWidth, minWidth: '250px' }}>
                                            //       <div className={classes.tickWrap}>
                                            //         <CheckCircleRoundedIcon style={{ fontSize: "1.2rem", color: "#ffb800" }} />
                                            //       </div>
                                            //     </TableCell>
                                            //   );
                                            // }
                                            if (allAssignedUsersIDs.includes(user.ID) && productWiseRespondedUsers[user.ID] && productWiseRespondedUsers[user.ID].includes(prd.id)) {
                                              return (
                                                <TableCell className={classes.checkmarkWraper} style={{ width: userCellWidth, minWidth: '250px' }}>
                                                  <div className={classes.tickWrap}>
                                                  <CheckCircleRoundedIcon style={{ fontSize: "1.2rem", color: "#2eb77d" }} />
                                                  </div>
                                                </TableCell>
                                              );
                                            }else if (productWiseRespondedUsers[user.ID] && productWiseRespondedUsers[user.ID].includes(prd.id)) {
                                              return (
                                                <TableCell className={classes.checkmarkWraper} style={{ width: userCellWidth, minWidth: '250px' }}>
                                                  <div className={classes.tickWrap}>
                                                    <CheckCircleRoundedIcon style={{ fontSize: "1.2rem", color: "#ffb800" }} />
                                                  </div>
                                                </TableCell>
                                              );
                                            }else if (!productWiseRespondedUsers[user.ID]) {
                                              return (
                                                <TableCell className={classes.checkmarkWraper} style={{ width: userCellWidth, minWidth: '250px' }}>
                                                  <div className={classes.tickWrap}>
                                                    <CancelRoundedIcon style={{ fontSize: "1.2rem", color: "#e11e5a" }} />
                                                  </div>
                                                </TableCell>
                                              );
                                            }else{
                                              return (
                                                <TableCell className={classes.checkmarkWraper} style={{ width: userCellWidth, minWidth: '250px' }}>
                                                  <div className={classes.tickWrap}>
                                                    {productWiseRespondedUsers[user.ID].includes(prd.id) ? (
                                                      <CheckCircleRoundedIcon style={{ fontSize: "1.2rem", color: "#2eb77d" }} />
                                                    ) : (
                                                      <CancelRoundedIcon style={{ fontSize: "1.2rem", color: "#e11e5a" }} />
                                                    )}
                                                  </div>
                                                </TableCell>
                                              );
                                            }
                                           
                                          })}
                                        </TableRow>
                                      );
                                    }) : <TableRow>
                                      <TableCell style={{ width: userCellWidth, minWidth: '250px' }}></TableCell>
                                      {products.map((item, index) => (
                                        <TableCell className={classes.checkmarkWraper} align="center" key={index} style={{ width: userCellWidth, minWidth: '250px' }}>-</TableCell>
                                      ))}
                                    </TableRow>}
                                  </Table>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </div>
        </DialogContent>
        <UserDetailsDialog />
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(OverallRequiremntViewDialog));
