import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { TextField, InputAdornment, OutlinedInput } from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import { CommonFn } from "services/commonFn";
// import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import * as Environment from "util/Environment";
import DatePicker from "react-datepicker";
import CalendarImage from "assets/images/calendar.svg";
// import { currencyOptions, currencyCodeMap } from "util/Currency";
// import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { fetchRecommendedProducts } from "redux/product/action";
import { withAsyncPaginate } from "react-select-async-paginate";
import axios from "axios";
import Select from "react-select";

const SelectAsyncPaginate = withAsyncPaginate(Select);

// var currencyTableTypeStyles = {
//   control: (styles) => ({ ...styles, height: 41 }),
//   menuPortal: (base) => ({ ...base, zIndex: 10 }),
//   option: (provided, state) => ({
//     ...provided,
//   }),
//   singleValue: (provided, state) => ({
//     ...provided,
//   }),
// };

const connectedProps = (state) => ({
  products: state.products.recommendedProducts,
});

const connectionActions = {
  fetchRecommendedProducts: fetchRecommendedProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "& [class*='editableField']": {
        minHeight: "auto !important",
      },
    },
    nameCol: {
      width: "40%",
    },
    statusCol: {
      width: "10%",
    },
    dueDateCol: {
      width: "20%",
    },
    actionCol: {
      textAlign: "center",
      width: "20%",
    },
    filters: {
      display: "flex",
      alignItems: "center",
      marginBottom: 25,
    },
    responseFilter: {
      marginRight: 20,
    },
    filterTitle1: {
      marginBottom: 1,
      color: "#282D30",
      fontSize: 14,
    },
    formControl: {
      minWidth: 150,
      "& > div": {
        color: "hsl(0,0%,50%)",
        fontSize: 14,
        background: "#fff",
        border: "1px solid #bebebe",
        borderRadius: 4,
        padding: 5,
      },
    },
    topActions: {
      textAlign: "right",
      marginBottom: 10,
    },
    calendarPopout: {
      zIndex: "100 !important",
    },
    datePicker: {
      display: "flex",
      alignItems: "center",
      gap: 5,
      "& .react-datepicker-wrapper": {
        width: "100%",
        backgroundColor: "white",
        minWidth: "170px",
      },
      "& .react-datepicker__input-container > div": {
        display: "flex",
        marginBottom: 0,
        alignItems: "center",
        paddingLeft: "8px !important",
        "& [class*=MuiInputBase-root]": {
          maxHeight: 39,
          minHeight: 39,
          width: "inherit",
        },
        "& input": {
          fontSize: theme.spacing(1.8),
          padding: "12px 5px 12px 2px !important",
          textAlign: "center",
        },
        "& [class*=MuiFormLabel-root]": {
          marginBottom: 0,
          marginRight: 29,
        },
      },
      "[class*=MuiMenuItem-root]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
      },
    },
    flexWrap: {
      display: "flex",
      alignItems: "center",
      gap: 5,
    },
  });

class SearchFilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 5,
      datas: [],
      value: null,
      selectOperand: {
        value: "eq",
        label: "==",
      },
      operand: [
        {
          value: "eq",
          label: "==",
        },
        {
          value: "gt",
          label: ">",
        },
        {
          value: "lt",
          label: "<",
        },
        {
          value: "gte",
          label: ">=",
        },
        {
          value: "lte",
          label: "<=",
        },
      ],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if(this.props.appliedFilters?.length !== prevProps.appliedFilters?.length){
      if(this.props.appliedFilters?.length === 0){
        this.setState({
          value: [3, 4, 5, 7, 8, 10, 15].includes(this.props.type) ? null : "",
          selectOperand: {
            value: "eq",
            label: "==",
          },
        });
      }
    }
  }

  fetchProduct = (value = "") => {
    this.props.fetchRecommendedProducts(0, {
      feature_ids: [],
      num_users: "",
      page: 0,
      pageSize: 10,
      query: value,
      type: 2,
    });
  };

  getUserOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      page: 0,
      pageSize: 20,
      query: inputValue,
    };
    const url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    // console.log(response)

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      ...m,
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  getTeamOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    const url = `${api_server}/groups?page=0&pageSize=20&query=${inputValue}&is_suppressed=no&t=${timestamp}`;
    const response = await axios.get(url, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      ...m,
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  handleOnChangeOperand = (operand) => {
    this.setState({ selectOperand: operand }, () => {
      if (this.state.value) {
        this.props.applyFilters(this.state.value, operand);
      }
    });
  };
  handleOnChangeValue = (value) => {
    this.setState({ value }, () => {
      this.props.applyFilters(value, this.state.selectOperand);
    });
  };

  render() {
    const { classes, type, title, additionalData } = this.props;
    const { value } = this.state;

    let options = [];
    if ([3, 4, 5, 15].includes(type)) {
      options = additionalData;
    }

    let productList = [];
    if (type === 10) {
      productList = this.props.products.map((m) => {
        m.value = m.ID;
        m.label = m.Name;
        return m;
      });
    }

    return (
      <div className={classes.root} key={this.props.key}>
        {[1, 11, 12, 13, 14, 19].includes(type) && (
          <div className={classes.flexWrap}>
            {type === 19 && (
              <div style={{ minWidth: 90 }}>
                <Select
                  menuPortalTarget={document.body}
                  value={this.state.selectOperand}
                  options={this.state.operand}
                  classNamePrefix="select"
                  placeholder="Type"
                  onChange={(event) => {
                    this.handleOnChangeOperand(event);
                  }}
                  styles={{ control: (base) => ({ ...base, height: 41 }), menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            )}
            <InputField
              classes={classes}
              val={value}
              type={type === 13 || type === 19 ? "number" : "text"}
              placeholder={`Search ${title}`}
              onChange={(value) => {
                this.handleOnChangeValue(value);
              }}
            />
          </div>
        )}
        {type === 2 && (
          <div className={classes.datePicker}>
            <div style={{ minWidth: 90 }}>
              <Select
                menuPortalTarget={document.body}
                value={this.state.selectOperand}
                options={this.state.operand}
                classNamePrefix="select"
                placeholder="Type"
                onChange={(event) => {
                  this.handleOnChangeOperand(event);
                }}
                styles={{ control: (base) => ({ ...base, height: 41, minWidth: 90 }), menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </div>
            <DatePicker
              readOnly={false}
              selected={value ? new Date(value) : ""}
              onChange={(date) => this.handleOnChangeValue(date)}
              dateFormat="MMM do yyyy"
              showMonthDropdown
              showYearDropdown
              popperClassName={classes.calendarPopout}
              dropdownMode="select"
              customInput={
                <OutlinedInput
                  value={value}
                  readOnlyInput={true}
                  placeholder={`Select ${title}`}
                  labelStyle={"inline"}
                  startAdornment={
                    <InputAdornment>
                      <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                    </InputAdornment>
                  }
                />
              }
            />
          </div>
        )}
        {[3, 4, 5, 15].includes(type) && (
          <Select
            isClearable
            // isMulti={[5, 15].includes(type)}
            menuPortalTarget={document.body}
            options={options}
            value={value}
            classNamePrefix="select"
            placeholder={`Search ${title}`}
            onChange={(e) => {
              this.handleOnChangeValue(e);
            }}
            styles={{ control: (base) => ({ ...base, height: 41 }), menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}
        {type === 7 && (
          <SelectAsyncPaginate
            isClearable
            isSearchable={true}
            menuPosition="fixed"
            classNamePrefix="select"
            isLoading={false}
            placeholder={`Search ${title}`}
            value={value}
            additional={{
              page: 0,
            }}
            loadOptions={this.getUserOptions}
            onChange={(e) => {
              this.handleOnChangeValue(e);
            }}
            styles={{ control: (base) => ({ ...base, height: 41 }) }}
          />
        )}
        {type === 8 && (
          <SelectAsyncPaginate
            isClearable
            isSearchable={true}
            menuPosition="fixed"
            classNamePrefix="select"
            isLoading={false}
            placeholder={`Search ${title}`}
            value={value}
            additional={{
              page: 0,
            }}
            loadOptions={this.getTeamOptions}
            onChange={(e) => {
              this.handleOnChangeValue(e);
            }}
            styles={{ control: (base) => ({ ...base, height: 41 }) }}
          />
        )}
        {type === 10 && (
          <Select
            isClearable
            menuPortalTarget={document.body}
            isSearchable={true}
            options={productList}
            classNamePrefix="select"
            placeholder={`Search ${title}`}
            onInputChange={(e) => {
              this.fetchProduct(e);
            }}
            value={value}
            onChange={(e) => {
              this.handleOnChangeValue(e);
            }}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}
        {type === 20 && (
          <div className={classes.currencyTypeFields} style={{ display: "flex", alignItems: "center", width: "100%" }}>
            {/* <div style={{ width: 100, marginRight: 5, zIndex: 1 }}> */}
            <div className={classes.flexWrap}>
              <div style={{ minWidth: 90 }}>
                <Select
                  menuPortalTarget={document.body}
                  value={this.state.selectOperand}
                  options={this.state.operand}
                  classNamePrefix="select"
                  placeholder="Type"
                  onChange={(event) => {
                    this.handleOnChangeOperand(event);
                  }}
                  styles={{ control: (base) => ({ ...base, height: 41, minWidth: 90 }), menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
              <InputField
                classes={classes}
                val={value}
                type={"number"}
                placeholder={`Enter ${title}`}
                onChange={(value) => {
                  this.handleOnChangeValue(value);
                }}
              />
            </div>
            {/* <Select
                isMulti={false}
                labelId="singleSelect-select-label"
                id="singleSelect-select"
                value={value?.type}
                onChange={(e) => {
                  let newValue = {
                    type: e,
                    value: value?.value,
                  };
                  this.handleOnChangeValue(newValue);
                }}
                options={currencyOptions}
                placeholder={"Type"}
                styles={currencyTableTypeStyles}
              ></Select> */}
            {/* <CurrencyTextField
              variant="outlined"
              value={value?.value}
              currencySymbol={currencyCodeMap[value?.type?.value]}
              style={{ textAlign: "left", flex: 1, height: 41, minWidth: 100, backgroundColor: "white" }}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              required={false}
              onChange={(e, v) => {
                let newValue = {
                  type: value?.type,
                  value: v,
                };
                this.handleOnChangeValue(newValue);
              }}
            /> */}
          </div>
        )}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(SearchFilterComponent));

const InputField = ({ classes, onChange, val, type, placeholder, isFocused = false }) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(val);
  }, [val]);
  return (
    <TextField
      fullWidth
      autoFocus={isFocused}
      className={classes.formControl}
      value={value}
      type={type ? type : "text"}
      InputProps={{ disableUnderline: true }}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
