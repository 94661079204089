import {
    START_FETCH_PRODUCT_CONTRACT, END_FETCH_PRODUCT_CONTRACT,
    START_ADD_PRODUCT_CONTRACT, END_ADD_PRODUCT_CONTRACT,
    START_UPDATE_PRODUCT_CONTRACT, END_UPDATE_PRODUCT_CONTRACT,
    START_DELETE_PRODUCT_CONTRACT, END_DELETE_PRODUCT_CONTRACT,
    START_ADD_PRODUCT_CONTRACT_COLLABORATORS, END_ADD_PRODUCT_CONTRACT_COLLABORATORS,
    START_FETCH_DOCUMENT_TYPES, END_FETCH_DOCUMENT_TYPES
} from "./action";

const initState = {
    fetchProductContractProgress: false,
    fetchProductContractError: null,
    contracts: [],

    addProductContractProgress: false,
    addProductContractError: null,
    addProductContractSuccess: null,

    updateProductContractProgress: false,
    updateProductContractError: null,
    updateProductContractSuccess: null,

    deleteProductContractProgress: false,
    deleteProductContractError: null,
    deleteProductContractSuccess: null,

    addProductContractColloboratorsProgress: false,
    addProductContractColloboratorsError: null,
    addProductContractColloboratorsSuccess: null,

    fetchDocumentTypesProgress: false,
    fetchDocumentTypesError: null,
    documentTypes: [],
}

export function orgProductContractsReducer(state = initState, action) {
    switch (action.type) {
        case START_FETCH_PRODUCT_CONTRACT:
            return {
                ...state,
                fetchProductContractProgress: true,
                fetchProductContractError: null,
            }
        case END_FETCH_PRODUCT_CONTRACT:
            return {
                ...state,
                fetchProductContractProgress: false,
                fetchProductContractError: action.payload.error,
                contracts: action.payload.success,
            }

        case START_ADD_PRODUCT_CONTRACT:
            return {
                ...state,
                addProductContractProgress: true,
                addProductContractError: null,
                addProductContractSuccess: null,
            }
        case END_ADD_PRODUCT_CONTRACT:
            return {
                ...state,
                addProductContractProgress: false,
                addProductContractError: action.payload.error,
                addProductContractSuccess: action.payload.success,
            }

        case START_UPDATE_PRODUCT_CONTRACT:
            return {
                ...state,
                updateProductContractProgress: true,
                updateProductContractError: null,
                updateProductContractSuccess: null,
            }
        case END_UPDATE_PRODUCT_CONTRACT:
            return {
                ...state,
                updateProductContractProgress: false,
                updateProductContractError: action.payload.error,
                updateProductContractSuccess: action.payload.success,
            }

        case START_DELETE_PRODUCT_CONTRACT:
            return {
                ...state,
                deleteProductContractProgress: true,
                deleteProductContractError: null,
                deleteProductContractSuccess: null,
            }
        case END_DELETE_PRODUCT_CONTRACT:
            return {
                ...state,
                deleteProductContractProgress: false,
                deleteProductContractError: action.payload.error,
                deleteProductContractSuccess: action.payload.success,
            }

        case START_ADD_PRODUCT_CONTRACT_COLLABORATORS:
            return {
                ...state,
                addProductContractColloboratorsProgress: true,
                addProductContractColloboratorsError: null,
                addProductContractColloboratorsSuccess: null,
            }

        case END_ADD_PRODUCT_CONTRACT_COLLABORATORS:
            return {
                ...state,
                addProductContractColloboratorsProgress: false,
                addProductContractColloboratorsError: action.payload.error,
                addProductContractColloboratorsSuccess: action.payload.success,
            }

        case START_FETCH_DOCUMENT_TYPES:
            return {
                ...state,
                fetchDocumentTypesProgress: true,
                fetchDocumentTypesError: null,
                documentTypes: [],
            }

        case END_FETCH_DOCUMENT_TYPES:
            return {
                ...state,
                fetchDocumentTypesProgress: false,
                fetchDocumentTypesError: action.payload.error,
                documentTypes: action.payload.success,
            }

        default:
            return state;
    }
}




