import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { DialogContent,Paper,Radio, Dialog, Slide,IconButton, DialogTitle, Typography, Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Close as CloseIcon } from "@material-ui/icons";
import { addOrgSettings } from "redux/org/user/action";
import "react-datepicker/dist/react-datepicker.css";
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import * as Validator from "util/Validation";
import Image from "Components/Common/image.jsx";
import DoneIcon from "@material-ui/icons/Done";
import { showWorkflowViewsDialog, hideWorkflowViewsDialog } from "redux/workflow/action";
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    showWorkflowViewDialog: state.workflow.showWorkflowViewDialog,
    showWorkflowViewData: state.workflow.showWorkflowViewData,

    user: state.orgUser,
    fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
});

const connectionActions = {
    showWorkflowViewsDialog: showWorkflowViewsDialog,
    hideWorkflowViewsDialog: hideWorkflowViewsDialog,
    addOrgSettings: addOrgSettings,
    showSnackBar: showSnackBar,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  notificationRoot: {
    padding: 10,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnSection: {
    textAlign: "right",
    margin: "0 !important",
    padding: "0 20px",
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  formControl1: {
    padding: 10,
    display: "flex",
    border: "1px solid #b3b3b3",
    borderRadius: 4,
    width: '100%'
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px'
  },
  criteriaName: {
    width: '60%'
  },
  actions: {
    width: '20%',
    textAlign: 'center'
  },
  defaultView: {
    width: '20%',
  },
  customTypeFields: {
    // marginBottom: 15,
    // marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    },
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left'
    }
  },
  tickMark: {
    color: "#4b86f8",
    marginLeft: 10,
    border: "1px solid #4b86f8",
    // marginRight: 10,
    padding: 6,
    minWidth: "50px !important",
    "&:hover": {
      color: "#4b86f8",
    },
  },
  mainSection: {
    padding: 20,
  },
  deleteBtn: {
    cursor: 'pointer',
    marginLeft: 10
  },
  actionBtns: {
    display: 'flex',
    gap: 25,
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionBtns1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
  },
  errorMsg: {
    color: 'red'
  }
});

class ViewsDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          isEdit: false,
          viewsList: [],
          isLoading: true,
          error: '',
          success: '',
        };
    }

    
  componentDidMount() {
    this.initConfiguration();
    this.setState({
      error: "",
      success: "",
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
      this.initConfiguration();
    }
  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  initConfiguration = () => {
    const { user, tab } = this.props;
    let configKey = "ALL_PROJECTS_VIEWS";
    if(tab === 3){
      configKey = "ALL_PROJECTS_TRACKING_VIEWS"
    }
    const viewsList = this.getConfigValue(user?.fetchAllOrgSettingsSuccess, "SettingName", configKey) || [];
    this.setState({
      viewsList: Validator.isJson(viewsList) ? JSON.parse(viewsList) : [],
      isLoading: false,
    });
  }

  updateConfiguration = () => {
    this.setState({
      isLoading: true
    }, () => {
      let viewsList = this.state.viewsList;
      console.log(viewsList, 'ruquestTypes')
      let configKey = "ALL_PROJECTS_VIEWS";
      if(this.props.tab === 3){
        configKey = "ALL_PROJECTS_TRACKING_VIEWS"
      }
      var notificationConfig = [
        {
          name: configKey,
          value: JSON.stringify(viewsList),
        }
      ];
      this.props.addOrgSettings(notificationConfig);
    })
  }

  addNewView = () => {
    let viewsList = this.state.viewsList;
    viewsList.push({
      id: new Date().getTime(),
      name: '',
      filters: this.props.filters || {},
      isEdit: true,
      isDefault: false,
      isNew: true,
      viewsListError: null
    })
    this.setState({
      viewsList: viewsList
    })
  }

  removeView = (index) => {
    let viewsList = this.state.viewsList;
    let isNew = viewsList[index].isNew;
    viewsList.splice(index, 1);
    this.setState({
      viewsList: viewsList
    }, () => {
      if (!isNew) {
        this.updateConfiguration();
      }
    })
  }

  updateView = (index) => {
    let viewsList = this.state.viewsList;
    let data = viewsList[index];
    let isError = false;

    data.viewsListError = null;

    if (data.name === '') {
      isError = true;
      data.viewsListError = 'Please enter name';
    }

    if (isError) {
      this.setState({
        viewsList: viewsList
      })
      return;
    }

    data.isNew = false;
    data.isEdit = false;
    data.viewsListError = null;

    this.updateConfiguration();
  }

  updateValue = (index, field, value) => {
    let viewsList = this.state.viewsList;
    let data = viewsList[index];
    data.viewsListError = null;
    data[field] = value;
    this.setState({
      viewsList: viewsList
    }) 
  }

  setDefault = (index) => {
    let viewsList = this.state.viewsList;
    let newViewsList = viewsList.map((view,i) => ({
      ...view,
      isDefault: index === i ? true : false,
    }))
    this.setState({
      viewsList: newViewsList
    },()=>{
        this.updateConfiguration();
    }) 
  }

  render() {
    const classes = this.props.classes;
    const { isLoading, viewsList } = this.state;
    
    return  <Dialog 
              onClose={this.props.hideWorkflowViewsDialog} 
              open={this.props.showWorkflowViewDialog}
              TransitionComponent={Transition}
              disableBackdropClick={true}
              fullWidth={true}
              maxWidth={"md"}
              scroll={"body"}
            >
              <DialogTitle disableTypography>
                <Typography variant={"h6"}>Manage Views</Typography>

                <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.hideWorkflowViewsDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{root:classes.dialogContent}}>
                <div className={classes.notificationRoot}>
                  {/* <div className={classes.btnSection}>
                    <Button
                      className={classes.newUserBtn}
                      onClick={() => {
                        this.addNewView()
                      }}
                    >
                      + Save Current View
                    </Button>
                  </div> */}
                  <div className={classes.mainSection}>
                    {!isLoading && (viewsList === undefined || viewsList === null || viewsList === '' || viewsList.length <= 0) && <div style={{ textAlign: 'center' }}>
                      No views
                    </div>}

                    {isLoading && (
                      <div style={{ textAlign: "center", marginTop: 20 }}>
                        <CircularProgress />
                      </div>
                    )}

                    {!isLoading && viewsList !== undefined && viewsList !== null && viewsList !== '' && viewsList.length > 0 && <TableContainer component={Paper}>
                      <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.criteriaName}>Name</TableCell>
                            <TableCell className={classes.actions}>Action</TableCell>
                            <TableCell className={classes.defaultView}>Default View</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {viewsList.map((view, k) => {
                            return <TableRow key={k}>
                              <TableCell className={classes.criteriaName}>
                                {(view.isNew || view.isEdit) && <div className={classnames(classes.inputSection, "inputSection")}>
                                  <input
                                    defaultValue={view.name}
                                    className={classes.formControl1}
                                    placeholder="View Name"
                                    name="name"
                                    onChange={(e) => {
                                      this.updateValue(k, 'name', e.target.value)
                                    }}
                                  />
                                </div>}
                                {!view.isNew && !view.isEdit && <span>{view?.name} </span>}
                                {view.viewsListError !== null && <span className={classes.errorMsg}>{view.viewsListError}</span>}
                              </TableCell>
                              <TableCell className={classes.actions}>
                                {!view.isNew && !view.isEdit && <div className={classes.actionBtns}>
                                  <Image className={classes.deleteBtn} src={editIcon} onClick={() => {
                                    this.updateValue(k, 'isEdit', true)
                                  }} />
                                  <Image className={classes.deleteBtn} src={deleteIcon} onClick={() => {
                                    this.removeView(k)
                                  }} />
                                </div>}

                                {(view.isNew || view.isEdit) && <div className={view.viewsListError !== null ? classes.actionBtns : classes.actionBtns} >
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() => {
                                      this.updateView(k);
                                    }}
                                  >
                                    <DoneIcon />
                                  </Button>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() => {
                                      if (view.isNew) {
                                        this.removeView(k);
                                      } else {
                                        this.updateValue(k, 'isEdit', false)
                                      }
                                    }}
                                  >
                                    <CloseIcon />
                                  </Button>
                                </div>
                                }
                              </TableCell>
                              <TableCell className={classes.defaultView}>
                                {(!view.isNew && !view.isEdit) && <Radio value={view.id} checked={view.isDefault} onChange={(event) => {
                                  this.setDefault(k)
                                }} />}
                              </TableCell>
                            </TableRow>
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
  }
}


export default connector(compose(
  withRouter,
  withStyles(styles)
)(ViewsDialog));