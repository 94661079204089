import {EvaluationFactory} from "services/evaluation";
import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

import { Types } from './types';

export function MultiChoiceQuizOptions(options){
    return {
        type:Types.QUIZ_OPTIONS,
        payload:{
            options
        }
    };
  }

/**
 * 
 */
 function startAddRequirementOptions(){
    return {
        type:Types.START_ADD_REQIREMENT_OPTION,
        payload:{}
    };
  }
  
  function endAddRequirementOptions(success,error,requirement_id){
    return {
        type:Types.END_ADD_REQIREMENT_OPTION,
        payload:{
            success,error,requirement_id
        }
    };
  }
  
  export  function addRequirementOptions(evaluation_id,requirement_id,options){
    const api_server = Environment.api_host("EVALUATION");
    const url   = `${api_server}/${evaluation_id}/requirement/${requirement_id}/options`;
    return async (dispatch) => {
        dispatch(startAddRequirementOptions());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,options);
            dispatch(endAddRequirementOptions(response,error,requirement_id));
        } catch(e){
            dispatch(endAddRequirementOptions(null,{
                message:e.message
            },requirement_id));
            return;
        }
    };
  } 