import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

export const START_NOTIFICATION_FETCH     =   "START_NOTIFICATION_FETCH";
export const END_NOTIFICATION_FETCH       =   "END_NOTIFICATION_FETCH";

export const START_NOTIFICATION_MARKREAD     =   "START_NOTIFICATION_MARKREAD";
export const END_NOTIFICATION_MARKREAD       =   "END_NOTIFICATION_MARKREAD";

export const START_NOTIFICATION_DELETE     =   "START_NOTIFICATION_DELETE";
export const END_NOTIFICATION_DELETE       =   "END_NOTIFICATION_DELETE";

function startNotificationFetch(page){
  return {
      type:START_NOTIFICATION_FETCH,
      payload:page
  };
}

function endNotificationFetch(success,error){
  return {
      type:END_NOTIFICATION_FETCH,
      payload:{
          success,error
      }
  };
}

export  function fetchNotifications(page,pageSize,readStatus){
  const api_server = Environment.api_host("USER");
  const timestamp     =   (new Date()).getTime();

  const url   =   `${api_server}/notifications?t=${timestamp}&page=${page}&pageSize=${pageSize}&readStatus=${readStatus}`;
  return async (dispatch) => {
      dispatch(startNotificationFetch(page));
      try{
          let [response,error]    =   await HttpFactory.instance().getMethod(url);
          dispatch(endNotificationFetch(response,error));
      } catch(e){
          dispatch(endNotificationFetch(null,{
              message:e.message
          }));
          return;
      }
  };
} 

function startNotificationMarkAsRead(){
  return {
      type:START_NOTIFICATION_MARKREAD,
      payload:{}
  };
}

function endNotificationMarkAsRead(success,error){
  return {
      type:END_NOTIFICATION_MARKREAD,
      payload:{
          success,error
      }
  };
}

export  function markAsRead(notificationId){
  const api_server = Environment.api_host("USER");
  const timestamp     =   (new Date()).getTime();

  const url   =   `${api_server}/notifications/${notificationId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startNotificationMarkAsRead());
      try{
          let [response,error]    =   await HttpFactory.instance().putMethod(url,{});
          dispatch(endNotificationMarkAsRead(notificationId,error));
      } catch(e){
          dispatch(endNotificationMarkAsRead(null,{
              message:e.message
          }));
          return;
      }
  };
} 

function startNotificationDelete(){
  return {
      type:START_NOTIFICATION_DELETE,
      payload:{}
  };
}

function endNotificationDelete(success,error){
  return {
      type:END_NOTIFICATION_DELETE,
      payload:{
          success,error
      }
  };
}

export  function deleteNotification(notificationId){
  const api_server = Environment.api_host("USER");
  const timestamp     =   (new Date()).getTime();

  const url   =   `${api_server}/notifications/${notificationId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startNotificationDelete());
      try{
          let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
          dispatch(endNotificationDelete(notificationId,error));
      } catch(e){
          dispatch(endNotificationDelete(null,{
              message:e.message
          }));
          return;
      }
  };
} 