import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {  Dialog, DialogContent, Slide, Typography,Grid } from '@material-ui/core';

import {hideAskSellerDialog,addAskSellerRequest} from "../../../../../../../redux/evaluation/criteria/action";
import OutlinedInput from "../../../../../../Common/Input/OutlinedInput";
import Alert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import SqureButton from '../../../../../../Common/SqureButton';
import {showSnackBar} from "../../../../../../../redux/snackbar/action";
// styles
import CommonCss from "commonCss.js";

// dialogs
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen:state.evaludationCriteria.askSellerDialog.show,
    product:state.evaludationCriteria.askSellerDialog.product,
    requirement:state.evaludationCriteria.askSellerDialog.requirement,
    evaluationId:state.evaludationCriteria.askSellerDialog.evaluationId,
    saveProgress:state.evaludationCriteria.askSellerSubmitProgress,
});

const connectionActions = {
    hideDialog:hideAskSellerDialog,
    showSnackBar:showSnackBar,
    addAskSellerRequest:addAskSellerRequest
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 12) +" !important"
    },
    modalTitle:{
        fontSize:theme.spacing(2.5),
        marginBottom:theme.spacing(2),
        textAlign:"center"
    },
    helptext:{
        fontSize:theme.spacing(1.8)
    },
    alerts:{
        marginTop:theme.spacing(4)
    },
    form:{
        marginTop:theme.spacing(2),
        width:'60%',
    },
    input:{
        marginBottom:theme.spacing(3)
    },
    scheduleTitle:{
        display:"block",
        marginBottom:theme.spacing(3),
        fontWeight:600,
        fontSize:theme.spacing(2)
    },
    scheduleTitle1:{
        display:"block",
        marginBottom:theme.spacing(3),
        fontWeight:600,
        fontSize:theme.spacing(1.8)
    },
    actions:{
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
        textAlign:"center",
    },
    loader:{
        textAlign:"center",
        padding:theme.spacing(10,0)
    },
    dialogAction:{
        textAlign:"center"
    },
    addNewButton:{
        fontWeight:600,
        fontSize:theme.spacing(2)
    },
    actionBtn:{
        minWidth:110
    },
    nameYour: CommonCss.nameYour ,
    yourQuestion: CommonCss.yourQuestion
});

class AskSellerDialog extends React.Component {

    constructor(props){
        super(props);
        this.state  =   {
            error:"",
            success:"",
            'name':'',
            'name_error':'',
            'description':'',
            'description_error':'',
        }
        this.submitResponse     =   this.submitResponse.bind(this);
    }
    
    

    componentDidUpdate(prevProps){
        if(this.props.isOpen === true && prevProps.isOpen === false){
            this.setState({
                error:"",
                success:"",
                description:this.props.requirement?.description,
                'response':this.serialize(this.props.requirement.description),
                'response_error':'',
            })
        }
        if(this.props.saveProgress === false && prevProps.saveProgress === true){
                //Migrate to different page
                this.props.hideDialog();
                this.props.showSnackBar("Request Sent to Vendor","success",3000);
        }
    }
    IsJsonString =(str)=> {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    serialize = response => {
        if(this.IsJsonString(response)){
            response = JSON.parse(response);
            return response.map(n => String(n)).join('\n')
        }else{
            return response
        }
    }
    
    submitResponse(){
        this.setState({
            'error':"",
            "success":"",
            "response_error":""
        });
        var me      =   this;
        var response   =   me.state.description;
        if(response === null || String(response).trim().length === 0){
            me.setState({response_error:"Please provide your response."});
            return;
        }


        this.props.addAskSellerRequest(this.props.product.VendorUUID,this.props.requirement.id,response)
    }

    render() {
        const classes = this.props.classes;
        // var me  =   this;
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-integrationDialog" 
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={false}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll="body"
                    id="evMetaDialog"
                    >
                    <DialogContent classes={{root:classes.dialogContent}}>
                    <Typography variant={"h2"} className={classes.modalTitle}>Ask Vendor</Typography>
                       {/* {this.props.requirement !== null && } */}
                       <div className={classes.alerts}>
                            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                        </div>
                        <div>
                            <Grid container>
                                <Grid item xs={12} lg={12}>
                                    <div className={classes.nameYour}>
                                    <OutlinedInput
                                        label="Name Your Question"
                                        error={this.state.name_error.length > 0}
                                        helperText={this.state.name_error}
                                        value={this.state.name || this.props.requirement?.name}
                                        onChange={(event) => {
                                            this.setState({ name: event.target.value });
                                        }}
                                    /> 
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div className={classes.yourQuestion}>
                                        <SlateInputField  
                                        label="Explain Your Question"
                                        error={this.state.description_error.length > 0}
                                        helperText={this.state.description_error}
                                        folderPath={`/evaluation/${this.props?.editDialog?.evaluationId}/criteria/${this.props?.editDialog?.criteriaId}/requirement/${this.props?.editDialog?.requirement?.id}`}
                                        placeholder=""
                                        as={SlateInputField}
                                        onChangeEvent={(value) => {
                                            this.setState({ description: value });
                                        }}
                                        initValue={this.state.description || this.props.requirement?.description}
                                        textContainerStyle={{ color: "#707070", }}/>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className={classes.actions}>
                                <SqureButton  variant={"contained"} 
                                onClick={this.submitResponse}>Submit</SqureButton>&nbsp;&nbsp;&nbsp;
                                <SqureButton  variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AskSellerDialog));