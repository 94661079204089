import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { updateFromOrgEvaluationRequirementTemplate } from "redux/templates/evaluation/org/action";
// components
import AnswerOptions from "./AnswerOptions";

// redux
import { updateCriteriaRequirementHeight } from "redux/applications/action";
import { MultiChoiceQuizOptions, addRequirementOptions } from "redux/evaluation/multichoice/action";
import { editRequirement } from "redux/evaluation/criteria/action";
import { showSnackBar } from "redux/snackbar/action";

import { requirementTypesOptions } from 'util/RequirementTypeOptions';

// Component styles
const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      "& [class*='MuiPaper-root-']": {
        boxShadow: "unset",
      },
      "& [class*='MuiAccordionSummary-root-']": {
        padding: 0,
      },
      "& [class*='MuiAccordionDetails-root-']": {
        padding: 0,
      },
    },
    answerType: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "start",
      width: '100%',
      alignItems: "center",
      "& label": {
        color: "#707070",
        marginRight: 20,
        fontSize: "14px",
        fontWeight: 500,
      },
      "& [class*='MuiSelect-root-']": {
        padding: "0.5em 0.75em",
        fontSize: "14px",
        color: "#707070",
        paddingRight: "32px",
      },
    },
    characterLimit: {
      flex: 0.8,
      "& [class*='answer-input']": {
        padding: 1,
        borderColor: '#444444'
      },
    },
    disabled: {
      "& [class*='MuiAccordionSummary-root-']": {
        opacity: 1,
      },
      "& [class*='MuiPaper-root-']": {
        backgroundColor: "unset",
      },
      "& [class*='MuiIconButton-root-']": {
        display: "none",
      },
      "& [class*='MuiSelect-root-']": {
        fontWeight: 500,
      },
      "& label": {
        color: "#6D7278",
        // fontSize: 12,
      },
      "& fieldset": {
        border: "unset",
      },
      "& svg": {
        display: "none",
      },
    },
    label: {
      color: "#707070",
      fontSize: theme.spacing(2),
      fontWeight: 400,
      minWidth: '115px'
    },
    summary: {
      minHeight: "max-content !important",
      "& > div:nth-child(1)": {
        margin: 0,
        minHeight: "max-content !important",
      },
    },
  });

const connectedProps = (state) => ({
  criteriaMultiChoice: state.criteriaMultiChoice,
  orgTemplateEvaluation: state.orgTemplateEvaluation,
  updateRequirementTemplateProg: state.orgTemplateEvaluation.updateFromOrgEvaluationRequirementTemplateProg,
});

const connectionActions = {
  updateCriteriaRequirementHeight: updateCriteriaRequirementHeight,
  editRequirement: editRequirement,
  showSnackBar: showSnackBar,
  updateFromOrgEvaluationRequirementTemplate:
    updateFromOrgEvaluationRequirementTemplate,
  MultiChoiceQuizOptions: MultiChoiceQuizOptions,
  addRequirementOptions: addRequirementOptions,
};

var connector = connect(connectedProps, connectionActions);

class MultiChoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 10,
      answertype: 1,
      expanded: true,
      questionSaved: false,
      options: [],
      characterLimit: 0,
      is_other_option: false
    };
  }

  componentDidMount() {
    this.updateViewHeight();
    try {
      this.setState({
        answertype:
          this.props.requirement?.AnswerType ||
          this.props.requirement?.answer_type ||
          1,
        options:
          this.props.requirement?.Options ||
          this.props.requirement?.options ||
          [],
        is_other_option:
          this.props.requirement?.IsOtherOption ||
          this.props.requirement?.is_other_option ||
          false,
        characterLimit: this.props.requirement?.CharacterLimit ||
          this.props.requirement?.character_limit ||
          0,
      });
    } catch (e) { }
  }

  componentDidUpdate(preProps) {
    let scope = this;
    const { criteriaMultiChoice, orgTemplateEvaluation } = this.props;
    const { questionSaved } = this.state;
    this.updateViewHeight();
    if (
      questionSaved === true &&
      ((criteriaMultiChoice.addRequirementOptionInprogress === false &&
        criteriaMultiChoice.addRequirementOptionSuccess) || (orgTemplateEvaluation?.orgRequirementTemplateOptionInprogress === false && orgTemplateEvaluation?.orgRequirementTemplateOptionSuccess))) {
      this.setState({
        questionSaved: false,
      }, () => {
        scope.props.showSnackBar("Question updated", "success", 3000);
      });
    }

    if (
      questionSaved === true &&
      criteriaMultiChoice.addRequirementOptionInprogress === false &&
      criteriaMultiChoice.addRequirementOptionError
    ) {
      this.setState(
        {
          questionSaved: false,
        },
        () => {
          scope.props.showSnackBar(
            criteriaMultiChoice.addRequirementOptionError,
            "error",
            3000
          );
        }
      );
    }

    if (
      questionSaved === true &&
      orgTemplateEvaluation?.orgRequirementTemplateOptionInprogress === false &&
      orgTemplateEvaluation?.orgRequirementTemplateQuestionOptionError
    ) {
      this.setState(
        {
          questionSaved: false,
        },
        () => {
          scope.props.showSnackBar(
            orgTemplateEvaluation?.orgRequirementTemplateQuestionOptionError,
            "error",
            3000
          );
        }
      );
    }

    if (
      this.props.requirement?.answer_type !== preProps.requirement?.answer_type
    ) {
      this.setState({
        answertype: this.props.requirement?.answer_type,
      });
    }

    if (
      this.props.requirement?.AnswerType !== preProps.requirement?.AnswerType
    ) {
      this.setState({
        answertype: this.props.requirement?.AnswerType,
      });
    }
  }

  getRequirementQuestion = () => {
    let params = {};
    params.evaluation_id = this.props.evaluation_id;
    params.requirement_id = this.props.requirement.id;
    this.props.getRequirementQuestion(params);
  };

  updateViewHeight = () => {
    let reqEle = document.getElementById(
      `requirement_${this.props.criteria?.id}_${this.props.requirement?.id}`
    );
    if (reqEle) {
      this.props.updateCriteriaRequirementHeight(
        true,
        `requirement_${this.props.criteria.id}_${this.props.requirement.id}`,
        reqEle.offsetHeight
      );
    }
  };

  saveQuiz = () => {
    this.setState({
      questionSaved: true,
    });
  };

  getOptions = (type) => {
    let options = [];
    if ([2, 3].includes(type)) {
      options = [
        {
          sequence: 0,
          title: "",
        },
      ];
    } else if ([4].includes(type)) {
      options = [
        {
          sequence: 0,
          title: "Yes",
          description: 'Yes'
        },
        {
          sequence: 1,
          title: "No",
          description: 'No'
        },
      ];
    }
    return options;
  };

  toggleOtherOption = (is_other_option) => {
    var isTemplateSection = this.props.isTemplate;
    const isNew = this.props.isNew;
    if (isNew === false) {
      if (isTemplateSection) {
        this.props.updateFromOrgEvaluationRequirementTemplate(
          this.props.match.params.id,
          this.props.criteria.ID,
          this.props.requirement.ID,
          {
            is_other_option
          }
        );
      } else {
        this.props.editRequirement(
          this.props.match.params.evaluation_id,
          this.props.criteria.id,
          this.props.requirement.id,
          {
            is_other_option
          },
          this.props.match?.params?.component_id
        );
      }
    } else {
      this.setState({
        is_other_option
      });
    }
  }

  getOtherOption = () => {
    var isTemplateSection = this.props.isTemplate;
    const isNew = this.props.isNew;
    if (isNew === false) {
      if (isTemplateSection) {
        return this.props.requirement.IsOtherOption
      } else {
        return this.props.requirement.is_other_option
      }
    } else {
      return this.state.is_other_option
    }
  }

  render() {
    const classes = this.props.classes;
    const multiChoice = [];
    var isTemplateSection = this.props.isTemplate;
    const isNew = this.props.isNew;
    const { answertype } = this.state;
    return (
      <>
        <div className={classes.root}>
          <Accordion expanded={this.state.expanded}>
            <AccordionSummary
              className={classnames(
                classes.summary
              )}
              onClick={(e) => {
                e.stopPropagation();
              }}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className={classes.answerType}>
                <p className={classes.label}>Answer type&nbsp;</p>
                <FormControl variant="outlined">
                  <Select
                    disabled={!this.props.editable}
                    value={
                      this.props.requirement?.answer_type ||
                      this.props.requirement?.AnswerType ||
                      this.state.answertype
                    }
                    onChange={(event) => {
                      this.props.MultiChoiceQuizOptions([]);
                      const options = this.getOptions(parseInt(event.target.value));
                      // this.setState({
                      //   options:options
                      // })
                      // console.log(options,'options')
                      if (isNew === false) {
                        if (isTemplateSection) {
                          this.props.updateFromOrgEvaluationRequirementTemplate(
                            this.props.match.params.id,
                            this.props.criteria.ID,
                            this.props.requirement.ID,
                            {
                              answer_type: parseInt(event.target.value),
                              options,
                              is_other_option: false,
                              character_limit: 0
                            }
                          );
                        } else {
                          this.props.editRequirement(
                            this.props.match.params.evaluation_id,
                            this.props.criteria.id,
                            this.props.requirement.id,
                            {
                              answer_type: parseInt(event.target.value),
                              is_other_option: false,
                              options,
                              character_limit: 0
                            },
                            this.props.match?.params?.component_id
                          );
                          this.props.addRequirementOptions(
                            this.props.match.params.evaluation_id,
                            this.props.requirement.id,
                            {
                              answer_type: parseInt(event.target.value),
                              options,
                              is_other_option: false,
                              character_limit: 0
                            }
                          );
                        }
                      } else {
                        this.props.onChange(
                          event.target.value,
                          options,
                          false,
                          0
                        );
                      }
                    }}
                  >
                    {requirementTypesOptions.map((itm) => {
                      return <MenuItem value={itm.value}>{itm.label}</MenuItem>
                    })}
                    {/* <MenuItem value={1}>Rich Text</MenuItem>
                    <MenuItem value={7}>Simple Text</MenuItem>
                    <MenuItem value={2}>Multi-select</MenuItem>
                    <MenuItem value={3}>Single-select</MenuItem>
                    <MenuItem value={4}>Binary</MenuItem>
                    <MenuItem value={5}>Score</MenuItem>
                    <MenuItem value={6}>File upload</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </AccordionSummary>
            {[2, 3, 4, 7, 14, 15].includes(this.state.answertype) && (
              <AccordionDetails>
                <AnswerOptions
                  saveBtnId={
                    this.props?.saveBtnId || this.props.requirement.id
                  }
                  isCriteriaRequirement={
                    this.props?.isCriteriaRequirement || false
                  }
                  isNew={isNew} // if it is new section or new template
                  answertype={answertype}
                  multiChoice={multiChoice}
                  options={this.state.options}
                  characterLimit={this.state.characterLimit}
                  evaluation_id={
                    this.props.evaluation_id || this.props.match.params.id
                  }
                  requirement={this.props.requirement}
                  criteria={this.props.criteria}
                  updateCriteriaRequirementHeight={
                    this.props.updateCriteriaRequirementHeight
                  }
                  type={this.state.type}
                  isTemplateSection={isTemplateSection}
                  editable={this.props.editable}
                  saveQuiz={this.saveQuiz}
                  isOtherOption={this.getOtherOption()}
                  removeIsOtherOption={(e) => {
                    e.stopPropagation()
                    this.toggleOtherOption(false)
                  }}
                  addOther={e => {
                    e.stopPropagation()
                    this.toggleOtherOption(true)
                  }}
                  onChange={(options, characterLimit) => {
                    if (this.props.isNew) {
                      this.props.onChange(this.state.answertype, options, this.state.is_other_option, characterLimit);
                    } else {
                      this.setState({ options: options, characterLimit });
                      if (isTemplateSection) {
                        this.props.updateFromOrgEvaluationRequirementTemplate(
                          this.props.match.params.id,
                          this.props.criteria.ID,
                          this.props.requirement.ID,
                          {
                            answer_type: answertype,
                            options: answertype === 7 ? [] : options,
                            is_other_option: false,
                            character_limit: characterLimit
                          }
                        );
                      } else {
                        this.props.editRequirement(
                          this.props.match.params.evaluation_id,
                          this.props.criteria.id,
                          this.props.requirement.id,
                          {
                            answer_type: answertype,
                            is_other_option: false,
                            options: answertype === 7 ? [] : options,
                            character_limit: characterLimit
                          },
                          this.props.match?.params?.component_id
                        );
                        this.props.addRequirementOptions(
                          this.props.match.params.evaluation_id,
                          this.props.requirement.id,
                          {
                            answer_type: answertype,
                            options: answertype === 7 ? [] : options,
                            is_other_option: false,
                            character_limit: characterLimit
                          }
                        );
                      }
                    }
                  }}
                  onQuizChange={(options) => {
                    this.props.MultiChoiceQuizOptions(options);
                    if(answertype === 7 && this.props.onChangeCharLimit){
                      this.props.onChangeCharLimit(options)
                    }
                  }}
                  MultiChoiceQuizOptions={this.props.MultiChoiceQuizOptions}
                  place={this.props.place}
                  fromAddTemplate={this.props.fromAddTemplate !== undefined && this.props.fromAddTemplate !== null ? this.props.fromAddTemplate : false}
                />
              </AccordionDetails>
            )}
          </Accordion>
        </div>
      </>
    );
  }
}

MultiChoice.defaultProps = {
  isNew: false,
  place: "",
};

export default connector(compose(withRouter, withStyles(styles))(MultiChoice));
