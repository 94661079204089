import { Dialog, DialogContent, Button } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { attachFileToDocument } from "redux/product/orgProduct/action.js";

const connectedProps = (state) => ({
  isOpen: state.dialogs.isShowAddDocumentDetailDialog,
});

const connectionActions = {
  attachFileToDocument: attachFileToDocument,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 30,
    },
    insertLink: {
      padding: 0,
      minWidth: 41,
      margin: '0.25em',
      border: 'none'
    },
    radioBox: {
      width: 13,
      height: 13,
      maxWidth: 13,
      minWidth: 13
    },
    inputBox: {
      width: "100%",
      height: 40,
      borderRadius: 3,
      border: '1px solid #ddd',
      margin: '20px 0',
      padding: 9
    },
    btnSection: {
      float: 'right',
      marginBottom: 10
    },
    label: {
      position: 'relative',
      top: -1
    },
    alert: {
      marginBottom: theme.spacing(1)
    }
  });

class UpdateDocumentFileDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      selectedOption: "link",
      urlError: "",
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  onUploadContractFile = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      if (file) {
        var reader = new FileReader();
        reader.onloadend = () => this.setState({ imgSrc: reader.result });
        reader.readAsDataURL(file);
      }
      formData.append("folder", `/orgProduct/contractsAndAgreements/${this.props.match?.params?.id}`);
      const timestamp = new Date().getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      axios
        .post(url, formData, {
          headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.setState({url: response?.data?.filepath});
        })
        .catch((err) => {
          this.setState({ urlError: "Something went wrong" });
        });
    }
  };

  onSave() {
    const { url, selectedOption } = this.state;

    var urlRegex =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    if (selectedOption === "link" && url && url.length && !urlRegex.test(url)) {
      this.setState({ urlError: "Please enter valid link. i.e. https://www.abc.xyz" });
      return;
    }

    this.props.attachFileToDocument(this.props.match?.params?.id, this.props.document?.ID, {
      url: url,
    });
    this.props.hideDialog()
  }

  render() {
    const classes = this.props.classes;
    // console.log(this.props.addDetailsData, 'addDetailsData')
    const { url, urlError } = this.state;

    return (
      <Dialog onClose={this.props.hideDialog}
      aria-labelledby="add-upload-dialog"
      open={true}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"sm"}
      scroll={"body"}
      id="attachRequestDialog"
      PaperProps={{ style: { overflowY: "visible" } }}>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.alert}>
            {urlError.length > 0 && (
              <Alert variant="filled" severity="error">
                {urlError}
              </Alert>
            )}
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="subCategory"
                checked={"link" === this.state.selectedOption}
                onChange={() => {
                  this.setState({ selectedOption: "link", url: "" });
                }}
                className={classes.radioBox}
              />{" "}
              <span className={classes.label}>Link</span>
            </label>
            &nbsp;
            <label>
              <input
                type="radio"
                name="subCategory"
                checked={"file" === this.state.selectedOption}
                onChange={() => {
                  this.setState({ selectedOption: "file", url: "" });
                }}
                className={classes.radioBox}
              />
              <span className={classes.label}>File</span>
            </label>
          </div>
          <div>
            {this.state.selectedOption === "link" ? (
              <input
                value={url}
                onChange={(e) => {
                  this.setState({ url: e.target.value });
                }}
                placeholder={"Add link"}
                className={classes.inputBox}
              />
            ) : (
              <input
                id="uploadFileContractV3"
                accept=".png, .jpg, .jpeg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={this.onUploadContractFile}
                type="file"
                className={classes.inputBox}
              />
            )}
          </div>
          <div className={classes.btnSection}>
            <Button variant="outlined" color="secondary" className={classes.button} onClick={() => this.props.hideDialog()}>
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button variant="contained" color="secondary" disabled={url.length < 1} className={classes.button} onClick={() => this.onSave()}>
              Update
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

UpdateDocumentFileDialog.defaultProps = {};

export default connector(compose(withRouter, withStyles(styles))(UpdateDocumentFileDialog));
