import { Button, CardHeader, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { createStyles, withStyles } from '@material-ui/core/styles';
import { EditOutlined as EditIcon } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import deleteIcon from "assets/images/delete.svg";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
// assets
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import classnames from "classnames";
import CommonCss from "commonCss";
import Image from "Components/Common/image.jsx";
import AddContackLinkDialog from "Components/Dialogs/AddContactLinkDialog";
import moment from 'moment';
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { compose } from "recompose";
import { authorizedUser } from "redux/authuser/action";
import { addOrgGroup, fetchOrgGroup } from "redux/org/group/action";
import {
  addOrgContactDetailLink, addOrgProductGroup, deleteOrgContactDetailLink, deleteOrgProductGroup, fetchOrgContactDetails, fetchOrgProductDepartment, fetchOrgVendorPocDetails,
  hideAddOrgProductChampionsDialog, orgDeleteContactDetails, orgProductAddVendorPoc,
  orgProductDeleteVendorPoc, showAddOrgProductChampionsDialog
} from "redux/product/orgProduct/action.js";
import { showSnackBar } from "redux/snackbar/action";
import * as Validator from "util/Validation";
import CustomSection from "./CustomSection";
import CollaboratorPopper from './Common/CollaboratorPopper';

let userRole = ["OrgAdmin", "OrgManager"];
const connectedProps = (state) => ({
  orgVendorPocDetails: state.orgProduct.orgVendorPocDetails,
  orgVendorPocDetailsFetchProgress:
    state.orgProduct.orgVendorPocDetailsFetchProgress,
  orgAddVendorPocProgress: state.orgProduct.orgAddVendorPocProgress,
  orgDeleteVendorPocProgress: state.orgProduct.orgDeleteVendorPocProgress,
  orgContactDetails: state.orgProduct.orgContactDetails,
  orgContactDetailsFetchProgress:
    state.orgProduct.orgContactDetailsFetchProgress,
  orgAddContactDetailsProgress: state.orgProduct.orgAddContactDetailsProgress,
  orgDeleteContactDetailsProgress:
    state.orgProduct.orgDeleteContactDetailsProgress,
  userType: state.authUser.user?.Role,
  addGroupProg: state.orgGroup.addGroupProg,
  addedGroup: state.orgGroup.addedGroup,
  groups: state.orgGroup.groups,
  fetchGroupProg: state.orgGroup.fetchGroupProg,
  orgProductGroupAddProgress: state.orgProduct.orgProductGroupAddProgress,
  orgProductGroupDeleteProgress: state.orgProduct.orgProductGroupDeleteProgress,
  orgProductDepartment: state.orgProduct.orgProductDepartment,
  contactDetailLinks: state.orgProduct.contactDetailLinks
});

const connectionActions = {
  showAddOrgProductChampionsDialog: showAddOrgProductChampionsDialog,
  orgProductAddVendorPoc: orgProductAddVendorPoc,
  orgProductDeleteVendorPoc: orgProductDeleteVendorPoc,
  fetchOrgVendorPocDetails: fetchOrgVendorPocDetails,
  hideAddOrgProductChampionsDialog: hideAddOrgProductChampionsDialog,
  fetchOrgContactDetails: fetchOrgContactDetails,
  orgDeleteContactDetails: orgDeleteContactDetails,
  fetchOrgProductDepartment: fetchOrgProductDepartment,
  showSnackBar: showSnackBar,
  authorizedUser: authorizedUser,
  fetchOrgGroup: fetchOrgGroup,
  addOrgGroup: addOrgGroup,
  addOrgProductGroup: addOrgProductGroup,
  deleteOrgProductGroup: deleteOrgProductGroup,
  addOrgContactDetailLink: addOrgContactDetailLink,
  deleteOrgContactDetailLink: deleteOrgContactDetailLink,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    section: {
      border: '1px solid #d8d8d8',
      padding: 17,
      borderRadius: 5,
      background: '#FFF',
      marginBottom: 17,
      position: 'relative',
    },
    head: {
      color: '#6C6C6C',
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11
    },
    threeDot: {
      position: "absolute",
      zIndex: 11,
      right: 5,
      top: 5,
      padding: 5
    },
    contactCardRoot: {
      cursor: "pointer",
    },
    addContact: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0px -19px 0px 0px",
    },
    contactCardHeader: {
      flexDirection: "column",
      padding: "16px 4px !important",
      "& [class*='MuiCardHeader-avatar-']": {
        margin: "0px",
        marginBottom: 12,
      },
      "& [class*='MuiCardHeader-content']": {
        width: "100%",
      },
      "& [class*=MuiTypography-displayBlock]": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        margin: "0px -19px 0px 0px",
      },
    },
    addNewChamp: {
      display: "block",
      textAlign: "center",
      color: "#bdbdbd",
      "& span": {
        display: "block",
        cursor: "pointer",
        color: "#000000",
      },
    },
    tabWidth: {
      "& [class*=MuiTab-root]": {
        minWidth: "auto",
        padding: "6px 20px",
      },
    },
    ...CommonCss.ProductDetails,
    deleteIcon: {
      position: "absolute",
      right: 16,
      top: 13,
      cursor: "pointer",
    },
    cardSection: {
      position: "relative",
    },
    supportSection: {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
    },
    formControl: {
      ...CommonCss.formControl,
      width: 300,
      marginLeft: 6,
    },
    formControlNew: {
      ...CommonCss.formControl,
    },
    formGroup: {
      marginTop: 5,
      display: "flex",
      alignItems: "center",
    },
    contained: {
      color: "#fff",
      padding: "6px 26px",
      backgroundColor: "#4b86f8",
      marginLeft: 10,
      "&:hover": {
        backgroundColor: "#4b86f8",
      },
    },
    supportText: {
      display: "flex",
      alignItems: "center",
      marginLeft: 5,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    supportLabel: {
      fontWeight: 600,
      marginTop: 3,
      marginBottom: 3,
      width: 59,
    },
    supportValue: {
      marginTop: 3,
      marginBottom: 3,
    },
    loader: {
      width: 100,
      height: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginLeft: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    description: {
      ...CommonCss.ProductDetails.description,
      "& p": {
        margin: "0px",
      },
      "& [class*=editor-editableField]": {
        minHeight: "auto !important",
        margin: "0px !important",
      },
      "& [class*=editor-formControl]": {
        border: "none !important",
      },
    },
    itemSection: {
      // position: 'relative',
      marginBottom: "16px !important",
      "& .product_container": {
        marginTop: "25px !important",
      },
    },
    tagClose: {
      display: "flex",
      alignItems: "center",
      padding: "6px 13px",
    },
    addNew: {
      cursor: "pointer",
    },
    searchAndSelect: {
      marginBottom: "5px",
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
        minHeight: 44,
      },
      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    iconEle: {
      width: 18,
      marginLeft: 8,
      cursor: "pointer",
    },
    buttonGroup: {
      alignItems: "center",
      position: "relative",
      zIndex: 1000,
    },
    creatable: {
      padding: 0,
    },
    contractHead: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      overflow: 'hidden',
      marginBottom: 25
    },
    headTable: {
      ...CommonCss.ProductDetails.head,
      marginBottom: 0
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      marginRight: '30px',
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 35,
      padding: 0,
      marginLeft: 15,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    paper: {
      boxShadow: 'none',
      border: '1px solid #ddd'
    },
    positionRelative: {
      position: 'relative',
    },
    tableCell: {
      width: "40%",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100vw - 99vw)'
    },
    tabledeleteCell: {
      width: "10%"
    },
    resourceHead: {
      padding: 10
    },
    deleteImage: {
      zIndex: 20,
      padding: 4,
      cursor: 'pointer'
    },
    formControlDialog: {
      ...CommonCss.formControl,
      '& > div': {
        backgroundColor: 'tarsparent !important',
        border: 'none',
        paddingRight: 0,
      },
      padding: '.375rem',
    },
    errformControlDialog: {
      borderColor: 'red !important',
      ...CommonCss.formControl,
      '& > div': {
        backgroundColor: 'tarsparent !important',
        border: 'none',
        paddingRight: 0,
      },
      padding: '.375rem',
    },
    formGroupDialog: {
      marginTop: 5,
      marginBottom: 16,
      '& input': {
        color: '#6C6C6C !important'
      }
    },
    dialogContent: {
      minHeight: 170,
      overflowY: 'hidden',
      "& [class*=select__menu]": {
        maxHeight: 100
      }
    },
    resourceLinkText: {
      color: '#4b86f8 !important',
      cursor: 'pointer',
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    menuList: {
      padding: '0px',
      "& > hr": {
        "&:first-child": {
          display: 'none'
        },
      }
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "50px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff"
        },
      },
    },
  });

class ContactTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailEdit: false,
      email: "",
      phone: "",
      isPhoneEdit: false,
      showMoreOrgVendorPocDetails: false,
      showMoreOrgBusinessPocDetails: false,
      showMoreOrgChampionDetails: false,
      departmentQuery: "",
      viewMore: [],
      departmentText: [],
      isDepartment: false,
      isOpen: false,
      contactDetails: [],
      anchorEl: null,
    };
    this.btnRef = React.createRef();
  }

  componentDidMount() {
    this.fetchOrgVendorPocDetails();
    this.fetchOrgContactDetails();
    this.fetchOrgGroups();
    this.fetchOrgProductDepartment();
  }

  componentDidUpdate(prevProps) {
    if (
      (!this.props.orgAddVendorPocProgress &&
        prevProps.orgAddVendorPocProgress) ||
      (!this.props.orgDeleteVendorPocProgress &&
        prevProps.orgDeleteVendorPocProgress)
    ) {
      this.props.hideAddOrgProductChampionsDialog();
      this.fetchOrgVendorPocDetails();
    }

    if (
      (!this.props.orgAddContactDetailsProgress &&
        prevProps.orgAddContactDetailsProgress) ||
      (!this.props.orgDeleteContactDetailsProgress &&
        prevProps.orgDeleteContactDetailsProgress)
    ) {
      this.props.hideAddOrgProductChampionsDialog();
      this.fetchOrgContactDetails();
    }

    if (this.props.addGroupProg === false && prevProps.addGroupProg === true) {
      if (this.props.addedGroup) {

        let newDepartmentText = [...this.state.departmentText];

        newDepartmentText.push({
          value: this.props.addedGroup.ID,
          label: this.props.addedGroup.Name
        });
        this.setState({ departmentText: newDepartmentText });
        this.fetchOrgGroups();
      } else {
        this.props.showSnackBar("Faild to create a new department", "error", 3000);
      }
    }

    if ((!this.props.orgProductGroupAddProgress && prevProps.orgProductGroupAddProgress) || (!this.props.orgProductGroupDeleteProgress && prevProps.orgProductGroupDeleteProgress)) {
      this.fetchOrgProductDepartment();
      this.setState({ departmentText: [] });
    }
  }

  fetchOrgGroups = () => {
    this.props.fetchOrgGroup(0, 50, this.state.departmentQuery);
  };

  fetchOrgProductDepartment() {
    this.props.fetchOrgProductDepartment(this.props.match?.params?.id);
  }

  saveDepartment = () => {
    if (this.state.departmentText && this.state.departmentText.length) {
      this.setState({ isDepartment: false });
      this.addOrgProductGroup({
        productID: parseInt(this.props.match?.params?.id),
        groupIDs: this.state.departmentText.map((e) => e.value),
      });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  addOrgProductGroup = (params) => {
    this.props.addOrgProductGroup(params);
  };

  deleteOrgProductGroup = (id) => {
    this.props.deleteOrgProductGroup({
      productID: parseInt(this.props.match?.params?.id),
      groupIDs: [id],
    });
  };

  fetchOrgVendorPocDetails = () => {
    if (this.props.match?.params?.id) {
      this.props.fetchOrgVendorPocDetails(this.props.match?.params?.id);
    }
  };

  fetchOrgContactDetails = () => {
    if (this.props.match?.params?.id) {
      this.props.fetchOrgContactDetails(this.props.match?.params?.id, {
        type: ["BusinessPoc", "Champion"],
      });
    }
  };

  componentWillUnmount() { }

  scrollContent = (e) => {
    e.persist();
  };

  deleteCard = (item) => {
    this.props.orgProductDeleteVendorPoc(this.props.match?.params?.id, item.ID);
  };

  deleteBusinessCard = (item) => {
    var accUserIds = [];
    var orgUserIDs = []
    if (item.User.FromIntegration) {
      orgUserIDs = [item.User.ID]
    } else {
      accUserIds = [item.User.ID]
    }
    this.props.orgDeleteContactDetails(this.props.match?.params?.id, {
      orgProductID: parseInt(this.props.match?.params?.id),
      type: "BusinessPoc",
      "accUserIDs": accUserIds,
      "orgUserIDs": orgUserIDs,
    });
  };

  deleteChampionCard = (item) => {
    var accUserIds = [];
    var orgUserIDs = []
    if (item.User.FromIntegration) {
      orgUserIDs = [item.User.ID]
    } else {
      accUserIds = [item.User.ID]
    }
    this.props.orgDeleteContactDetails(this.props.match?.params?.id, {
      orgProductID: parseInt(this.props.match?.params?.id),
      type: "Champion",
      "accUserIDs": accUserIds,
      "orgUserIDs": orgUserIDs,
    });
  };

  saveEmail = () => {
    // if (!this.state.email) {
    //   this.props.showSnackBar("Please enter value", "error", 3000);
    //   return;
    // }

    if (this.state.email && Validator.validateEmail(this.state.email) === false) {
      this.props.showSnackBar("Please enter valid email", "error", 3000);
      return;
    }

    this.props.updateProducts({
      ...this.props.productDetails,
      supportEmail: this.state.email,
    });
    this.setState({ isEmailEdit: false });
  };

  savePhone = () => {
    // if (!this.state.phone) {
    //   this.props.showSnackBar("Please enter value", "error", 3000);
    //   return;
    // }
    this.props.updateProducts({
      ...this.props.productDetails,
      supportPhone: this.state.phone,
    });
    this.setState({ isPhoneEdit: false });
  };

  viewMore = (value, state) => {
    const { viewMore } = this.state;
    let values = [...viewMore];
    if (state) {
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      }
    } else {
      values.push(value);
    }
    this.setState({
      viewMore: values,
    });
  };

  handleSubmitContacts = (data) => {
    this.setState({
      isOpen: false
    })

    this.props.addOrgContactDetailLink(parseInt(this.props.match?.params?.id), data);
  }

  deleteContracts = (id) => {
    this.props.deleteOrgContactDetailLink(parseInt(this.props.match?.params?.id), id);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const classes = this.props.classes;
    const { userType } = this.props;
    const { viewMore } = this.state;
    const champion = {
      head: "Add Champion",
      isUserList: true,
      isUserRequired: true,
      userPlacehoder: "User*",
      type: "champion",
    };

    const vendorPOC = {
      head: "Add Vendor POC",
      isName: true,
      isNameRequired: true,
      namePlaceholder: "Name*",
      isDept: true,
      isDeptRequired: true,
      deptPlaceholder: "Department*",
      isEmail: true,
      isEmailRequired: true,
      emailPlaceholder: "Email*",
      isTitle: true,
      titlePlaceholder: "Title",
      type: "vendor",
    };
    const businessPOC = {
      head: "Add Business POC",
      isUserList: true,
      isUserRequired: true,
      userPlacehoder: "User*",
      type: "businessPoc",
    };

    const championDetails = [];
    const businessPocDetails = [];
    this.props.orgContactDetails.map((e) => {
      if (e.ContactType === "Champion") {
        if (e.User) {
          championDetails.push(e);
        }
      } else {
        businessPocDetails.push(e);
      }
    });

    let groupList = this.props.groups.map((m) => {
      return {
        value: m.ID,
        label: m.Name,
      };
    });

    return (
      <div>
        {this.props.sections && this.props.sections.length > 0 && this.props.sections.map(section => {
          const hasWriteAccess = (section.Permission === "write") || (section.Access === 2);
          if (section.IsDeletable && section.IsEditable) {
            return (
              <CustomSection
                key={section.SectionUID}
                section={section}
                isEditable={hasWriteAccess}
                productID={this.props.productDetails.productID}
              />
            )
          }
          if (section.SectionUID === "prod_departments") {
            return (
              <>
                {(this.props.hideFlagForUser?.prod_departments) && <div className={classes.section} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                  {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                  <p className={classes.head}> {section.SectionName} </p>
                  <div className={classes.description}>
                    <ul className={classes.UL}>
                      {this.props.orgProductDepartment &&
                        this.props.orgProductDepartment
                          .slice(
                            0,
                            viewMore.indexOf("Departments") > -1
                              ? this.props.orgProductDepartment?.length
                              : 5
                          )
                          .map((item, k) => {
                            return (
                              <li
                                key={k}
                                style={{ marginRight: "14px" }}
                              >
                                <span
                                  className={classnames(
                                    classes.tag,
                                    classes.tagClose,
                                    classes.inverse
                                  )}
                                >
                                  <span>{item?.Name} </span>
                                  {hasWriteAccess && (
                                    <CloseIcon className={classes.iconEle}
                                      onClick={() => this.deleteOrgProductGroup(item.ID)} />
                                  )}
                                </span>
                              </li>
                            );
                          })}
                      {this.props.orgProductDepartment &&
                        this.props.orgProductDepartment.length > 5 && (
                          <li key={"viewMore"} style={{ marginRight: "14px" }}>
                            <span
                              className={classnames(classes.tag, classes.view_more)}
                              onClick={() => {
                                this.viewMore(
                                  "Departments",
                                  viewMore.indexOf("Departments") > -1
                                );
                              }}
                            >
                              {viewMore.indexOf("Departments") > -1
                                ? "- View less"
                                : "+ View more"}
                            </span>
                          </li>
                        )}
                      {!this.state.isDepartment && hasWriteAccess && (
                        <li onClick={() => this.setState({ isDepartment: true })}>
                          <span
                            className={classnames(
                              classes.tag,
                              classes.tagClose,
                              classes.addNew
                            )}
                          >
                            <span> Add new </span>
                            <CheckIcon className={classes.iconEle} />
                          </span>
                        </li>
                      )}
                    </ul>
                    {this.state.isDepartment && (
                      <Grid container spacing={3} className={classes.buttonGroup}>
                        <Grid item sm={6}>
                          <div className={classes.searchAndSelect}>
                            <CreatableSelect
                              isClearable
                              className={classnames(
                                classes.formControlNew,
                                classes.creatable
                              )}
                              value={this.state.departmentText}
                              isSearchable={true}
                              isMulti
                              isLoading={this.props.addGroupProg || this.props.fetchGroupProg}
                              options={groupList}
                              classNamePrefix="select"
                              placeholder="Departments using the product"
                              onInputChange={(e) => {
                                this.setState(
                                  {
                                    departmentQuery: e,
                                  },
                                  () => {
                                    this.fetchOrgGroups();
                                  }
                                );
                              }}
                              onCreateOption={(e) => {
                                this.props.addOrgGroup(e, null);
                              }}
                              onChange={(e) => {
                                this.setState({ departmentText: e });
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <Button
                            className={classes.tickMark}
                            onClick={() => this.saveDepartment()}
                          >
                            <DoneIcon />
                          </Button>
                          <Button
                            className={classes.tickMark}
                            onClick={() => this.setState({ isDepartment: false })}
                          >
                            <CloseIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </div>}
              </>
            )
          } else if (section.SectionUID === "prod_product_champions") {
            return (
              <>
                {/* Product champions */}
                {this.props.hideFlagForUser?.prod_product_champions && <div className={classes.section} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                  <Grid container className={classes.root}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <p className={classes.head} id="201">
                        {" "}
                        Product Champions{" "}
                      </p>
                    </Grid>
                    {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container className={classes.root} spacing={2}>
                        {!this.props.orgContactDetailsFetchProgress &&
                          championDetails
                            ?.slice(
                              0,
                              !this.state.showMoreOrgChampionDetails
                                ? 5
                                : championDetails?.length
                            )
                            ?.map((item, index) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  key={index}
                                  className={classes.cardSection}
                                >
                                  {hasWriteAccess && (
                                    <div onClick={() => this.deleteChampionCard(item)}>
                                      <Image
                                        src={deleteIcon}
                                        alt="iamge"
                                        className={classes.deleteIcon}
                                      />
                                    </div>
                                  )}
                                  <Card variant="outlined">
                                    <CardHeader
                                      className={classes.contactCardHeader}
                                      style={{ textAlign: "center" }}
                                      avatar={
                                        <Avatar
                                          aria-label="recipe"
                                          className={classes.contactCardAvatar}
                                        >
                                          {item.User && item.User?.Name?.slice(0, 1)}
                                        </Avatar>
                                      }
                                      title={item.User?.Name}
                                      subheader={item.User?.Email}
                                    />
                                  </Card>
                                </Grid>
                              );
                            })}
                        {this.props.orgContactDetailsFetchProgress && (
                          <div className={classes.loader}>
                            <CircularProgress />
                          </div>
                        )}
                        {/* Add new card */}
                        {hasWriteAccess && (
                          <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Card
                              className={classes.contactCardRoot}
                              variant="outlined"
                              onClick={() => {
                                this.props.showAddOrgProductChampionsDialog(champion);
                              }}
                            >
                              <CardContent>
                                <span className={classes.addNewChamp}>
                                  <Image
                                    src={AddEvaluationImage}
                                    style={{ maxWidth: "58px", cursor: "pointer" }}
                                  />
                                  <span className={classes.addContact}>
                                    Add Champion
                                  </span>
                                </span>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                        {championDetails && championDetails?.length > 5 && (
                          <div
                            style={{
                              textAlign: "center",
                              padding: "35px 15px",
                              width: "100%",
                            }}
                          >
                            <span
                              className={classes.show_more}
                              onClick={() =>
                                this.setState({
                                  showMoreOrgChampionDetails:
                                    !this.state.showMoreOrgChampionDetails,
                                })
                              }
                            >
                              {this.state.showMoreOrgChampionDetails === false
                                ? "Show more"
                                : "Show less"}
                            </span>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    {userRole.indexOf(userType) === -1 && this.props.orgContactDetailsFetchProgress === false && championDetails.length === 0 && (
                      <span className={classes.noMoreData}>No data </span>
                    )}
                  </Grid>
                </div>}
              </>
            )
          } else if (section.SectionUID === "prod_business_poc") {
            return (
              <>
                {/* orgBussinessPocDetails */}
                {this.props.hideFlagForUser?.prod_business_poc && <div className={classes.section} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                  <Grid container className={classes.root}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      <p className={classes.head}>{section.SectionName}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container className={classes.root} spacing={2}>
                        {!this.props.orgContactDetailsFetchProgress &&
                          businessPocDetails
                            ?.slice(
                              0,
                              !this.state.showMoreOrgBusinessPocDetails
                                ? 5
                                : businessPocDetails?.length
                            )
                            ?.map((item, index) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  key={index}
                                  className={classes.cardSection}
                                >
                                  {hasWriteAccess && (
                                    <div onClick={() => this.deleteBusinessCard(item)}>
                                      <Image
                                        src={deleteIcon}
                                        alt="iamge"
                                        className={classes.deleteIcon}
                                      />
                                    </div>
                                  )}
                                  <Card variant="outlined">
                                    <CardHeader
                                      className={classes.contactCardHeader}
                                      style={{ textAlign: "center" }}
                                      avatar={
                                        <Avatar
                                          aria-label="recipe"
                                          className={classes.contactCardAvatar}
                                        >
                                          {item.User && item.User?.Name?.slice(0, 1)}
                                        </Avatar>
                                      }
                                      title={item.User?.Name}
                                      subheader={item.User?.Email}
                                    />
                                  </Card>
                                </Grid>
                              );
                            })}
                        {this.props.orgContactDetailsFetchProgress && (
                          <div className={classes.loader}>
                            <CircularProgress />
                          </div>
                        )}
                        {/* Add new card */}
                        {hasWriteAccess && (
                          <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Card
                              className={classes.contactCardRoot}
                              variant="outlined"
                              onClick={() => {
                                this.props.showAddOrgProductChampionsDialog(
                                  businessPOC
                                );
                              }}
                            >
                              <CardContent>
                                <span className={classes.addNewChamp}>
                                  <Image
                                    src={AddEvaluationImage}
                                    style={{ maxWidth: "58px", cursor: "pointer" }}
                                  />
                                  <span className={classes.addContact}>
                                    Add Contact
                                  </span>
                                </span>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}

                        {businessPocDetails && businessPocDetails?.length > 5 && (
                          <div
                            style={{
                              textAlign: "center",
                              padding: "35px 15px",
                              width: "100%",
                            }}
                          >
                            <span
                              className={classes.show_more}
                              onClick={() =>
                                this.setState({
                                  showMoreOrgBusinessPocDetails:
                                    !this.state.showMoreOrgBusinessPocDetails,
                                })
                              }
                            >
                              {this.state.showMoreOrgBusinessPocDetails === false
                                ? "Show more"
                                : "Show less"}
                            </span>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    {userRole.indexOf(userType) === -1 && this.props.orgContactDetailsFetchProgress === false && businessPocDetails.length === 0 && (
                      <span className={classes.noMoreData}>No data </span>
                    )}
                  </Grid>
                </div>}
              </>
            )
          } else if (section.SectionUID === "prod_vendor_poc") {
            return (
              <>
                {/* Product champions */}
                {this.props.hideFlagForUser?.prod_vendor_poc && <div className={classes.section} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                  <Grid container className={classes.root}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      <p className={classes.head}>{section.SectionName}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container className={classes.root} spacing={2}>
                        {!this.props.orgVendorPocDetailsFetchProgress &&
                          this.props.orgVendorPocDetails
                            ?.slice(
                              0,
                              !this.state.showMoreOrgVendorPocDetails
                                ? 5
                                : this.props.orgVendorPocDetails.length
                            )
                            .map((item, index) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={2}
                                key={index}
                                className={classes.cardSection}
                              >
                                {hasWriteAccess && <div onClick={() => this.deleteCard(item)}>
                                  <Image
                                    src={deleteIcon}
                                    alt="iamge"
                                    className={classes.deleteIcon}
                                  />
                                </div>}
                                <Card variant="outlined">
                                  <CardHeader
                                    className={classes.contactCardHeader}
                                    style={{ textAlign: "center" }}
                                    avatar={
                                      <Avatar
                                        aria-label="recipe"
                                        className={classes.contactCardAvatar}
                                      >
                                        {item.Name && item.Name?.slice(0, 1)}
                                      </Avatar>
                                    }
                                    title={item?.Name}
                                    subheader={item?.Dept}
                                  />
                                </Card>
                              </Grid>
                            ))}
                        {this.props.orgVendorPocDetailsFetchProgress && (
                          <div className={classes.loader}>
                            <CircularProgress />
                          </div>
                        )}
                        {/* Add new card */}
                        {hasWriteAccess && (
                          <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Card
                              className={classes.contactCardRoot}
                              variant="outlined"
                              onClick={() => {
                                this.props.showAddOrgProductChampionsDialog(vendorPOC);
                              }}
                            >
                              <CardContent>
                                <span className={classes.addNewChamp}>
                                  <Image
                                    src={AddEvaluationImage}
                                    style={{ maxWidth: "58px", cursor: "pointer" }}
                                  />
                                  <span className={classes.addContact}>
                                    Add Contact
                                  </span>
                                </span>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}

                        {!this.props.orgVendorPocDetailsFetchProgress &&
                          this.props.orgVendorPocDetails?.length > 5 && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: "35px 15px",
                                width: "100%",
                              }}
                            >
                              <span
                                className={classes.show_more}
                                onClick={() =>
                                  this.setState({
                                    showMoreOrgVendorPocDetails:
                                      !this.state.showMoreOrgVendorPocDetails,
                                  })
                                }
                              >
                                {this.state.showMoreOrgVendorPocDetails === false
                                  ? "Show more"
                                  : "Show less"}
                              </span>
                            </div>
                          )}
                      </Grid>
                    </Grid>
                    {userRole.indexOf(userType) === -1 && this.props.orgVendorPocDetailsFetchProgress === false && this.props.orgVendorPocDetails === 0 && (
                      <span className={classes.noMoreData}>No data </span>
                    )}
                  </Grid>
                </div>}
              </>
            )
          } else if (section.SectionUID === "prod_support") {
            return (
              <>
                {/* Product champions */}
                {this.props.hideFlagForUser?.prod_support && <div className={classes.section} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                  {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                  <p className={classes.head}>{section.SectionName}</p>
                  <div className={classes.supportSection}>
                    <p className={classes.supportLabel}>Email:</p>
                    <div>

                      {!this.state.isEmailEdit && (
                        <div className={classes.supportText}>
                          <p className={classes.supportValue}>
                            {this.props.productDetails?.supportEmail ? this.props.productDetails?.supportEmail : 'No data'}
                          </p>
                          {hasWriteAccess && (
                            <span
                              onClick={() =>
                                this.setState({
                                  isEmailEdit: true,
                                  email: this.props.productDetails?.supportEmail,
                                })
                              }
                            >
                              <EditIcon
                                fontSize={"small"}
                                className={classes.editIcon}
                              />
                            </span>
                          )}
                        </div>
                      )}
                      {this.state.isEmailEdit && (
                        <div className={classes.formGroup}>
                          <input
                            className={classes.formControl}
                            value={this.state.email}
                            placeholder="email"
                            onChange={(e) =>
                              this.setState({
                                email: e.target.value,
                              })
                            }
                          />{" "}
                          <Button
                            className={classes.tickMark}
                            onClick={() => this.saveEmail()}
                          >
                            <DoneIcon />
                          </Button>
                          <Button
                            className={classes.tickMark}
                            onClick={() => this.setState({ isEmailEdit: false })}
                          >
                            <CloseIcon />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.supportSection}>
                    <p className={classes.supportLabel}>Phone:</p>
                    <div>
                      {!this.state.isPhoneEdit && (
                        <div className={classes.supportText}>
                          <p className={classes.supportValue}>
                            {this.props.productDetails?.supportPhone ? this.props.productDetails?.supportPhone : 'No data'}
                          </p>
                          {hasWriteAccess && (
                            <span
                              onClick={() =>
                                this.setState({
                                  isPhoneEdit: true,
                                  phone: this.props.productDetails?.supportPhone,
                                })
                              }
                            >
                              <EditIcon
                                fontSize={"small"}
                                className={classes.editIcon}
                              />
                            </span>
                          )}
                        </div>
                      )}
                      {this.state.isPhoneEdit && (
                        <div className={classes.formGroup}>
                          <input
                            className={classes.formControl}
                            value={this.state.phone}
                            placeholder="phone"
                            onChange={(e) =>
                              this.setState({
                                phone: e.target.value,
                              })
                            }
                          />{" "}
                          <Button
                            className={classes.tickMark}
                            onClick={() => this.savePhone()}
                          >
                            <DoneIcon />
                          </Button>
                          <Button
                            className={classes.tickMark}
                            onClick={() => this.setState({ isPhoneEdit: false })}
                          >
                            <CloseIcon />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>}
              </>
            )
          } else if (section.SectionUID === "prod_other_resources") {
            return (
              <>
                {/* Other Resources */}
                {this.props.hideFlagForUser?.prod_other_resources && <div className={classes.section} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                  {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                  <div className={classes.contractHead}>
                    <p className={classes.headTable}>Other Resources</p>
                    {hasWriteAccess && <Button className={classes.newUserBtn} onClick={() => this.setState({ isOpen: true })}>+ Add Link</Button>}
                  </div>
                  <div className={classes.labelDpa}>
                    <Paper className={classnames(classes.card, classes.paper, 'details-border')}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classnames(classes.tableCell)}>Name</TableCell>
                            <TableCell className={classnames(classes.tableCell)}>Link</TableCell>
                            <TableCell width="15%">Created At</TableCell>
                            <TableCell width="5%"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {this.props.contactDetailLinks && this.props.contactDetailLinks.length <= 0 && <TableRow>
                            <TableCell colSpan={3} className={classnames(classes.tableCell)} style={{ textAlign: 'center' }}>
                              No Record
                            </TableCell>
                          </TableRow>
                          }
                          {this.props.contactDetailLinks && this.props.contactDetailLinks.length > 0 && this.props.contactDetailLinks.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell className={classnames(classes.tableCell)}>
                                  {item.Name}
                                </TableCell>
                                <TableCell className={classnames(classes.tableCell)}>
                                  <a
                                    href={item?.Link}
                                    className={classes.resourceLinkText}
                                    title={item?.Link}
                                    target="_blank" rel="noreferrer"
                                  >
                                    {item.Link}
                                  </a>
                                </TableCell>
                                <TableCell>{moment(item?.CreatedAt).format("DD-MMM-YYYY")}</TableCell>
                                <TableCell> {this.props.adminFlag && <Image src={deleteIcon} alt={item.ID} className={classes.deleteImage} onClick={() => this.deleteContracts(item.ID)} />}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  </div>
                </div>}
              </>
            )
          }
        })}
        <AddContackLinkDialog
          classes={classes}
          isOpen={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          onSubmit={data => this.handleSubmitContacts(data)}
        />
      </div>
    )
  }
}

export default connector(compose(withRouter, withStyles(styles))(ContactTab));
