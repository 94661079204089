import {
    Box,
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import Alert from '@material-ui/lab/Alert';
import SqureButton from 'Components/Common/SqureButton';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import ExistingAutomationSelection from '../Common/existingAutomationSelection';
import { addNewExistingAutomation, fetchAdminAutomations } from 'redux/automations/action';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { showSnackBar } from 'redux/snackbar/action';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    adminAutomationsProgress: state.automation.adminAutomationsProgress,
    adminAutomationsError: state.automation.adminAutomationsError,
    adminAutomationsSuccess: state.automation.adminAutomationsSuccess,

    addExistingTemplateAutomationsProgress: state.automation.addExistingTemplateAutomationsProgress,
    addExistingTemplateAutomationsError: state.automation.addExistingTemplateAutomationsError,
    addExistingTemplateAutomationsSuccess: state.automation.addExistingTemplateAutomationsSuccess,
});

const connectionActions = {
    fetchAdminAutomations: fetchAdminAutomations,
    addNewExistingAutomation: addNewExistingAutomation,
    showSnackBar: showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 20
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    body: {
        marginTop: 20,
        marginBottom: 10
    },
    actionBtns: {
        textAlign: 'right',
        marginTop: '16px'
    },
    required: {
        color: 'red'
    },
    alerts: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
});

class AddExistingAutomationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            currentStep: 1,
            selectedAutomation: null,
            automationName: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (!this.props.addExistingTemplateAutomationsProgress && prevProps.addExistingTemplateAutomationsProgress) {
            if (this.props.addExistingTemplateAutomationsError !== null) {
                this.props.showSnackBar(
                    "Failed to add automation.",
                    "error",
                    3000
                );
            } else {
                this.props.showSnackBar(
                    "Automation added successfully.",
                    "success",
                    3000
                );
                let redirectPath = `/app/settings/automations/builder/${this.props.addExistingTemplateAutomationsSuccess?.ID}`;
                this.props.history.push(redirectPath);
                console.log(this.props.addExistingTemplateAutomationsSuccess, 'this.props.addExistingTemplateAutomationsSuccess')
            }
        }
    }

    fetchData() {
        this.props.fetchAdminAutomations({ page: 0, pageSize: 1000 })
    }

    clearError() {
        this.setState({
            error: "",
            success: "",
        })
    }

    handleSubmit() {
        const { currentStep } = this.state;
        if (currentStep === 1) {
            this.setState({
                currentStep: 2
            })
        } else if (currentStep === 2) {
            this.setState({
                currentStep: 3
            })
        } else if (currentStep === 3) {
            this.props.addNewExistingAutomation({
                name: this.state.automationName,
                template_id: this.state.selectedAutomation?.id
            })
        }
    }


    render() {
        const classes = this.props.classes;
        const { error, success, currentStep } = this.state;
        const { adminAutomationsSuccess } = this.props;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={true}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={"lg"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.head}>
                    <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                    <Typography variant={"h4"} className={classes.title}>Add Automation</Typography>
                </div>
                <div className={classes.body}>
                    <div className={classes.alerts}>
                        {error.length > 0 && <Alert variant="filled" severity="error">{error}</Alert>}
                        {success.length > 0 && <Alert variant="filled" severity="success">{success}</Alert>}
                    </div>

                    {currentStep === 1 && <ExistingAutomationSelection
                        automations={adminAutomationsSuccess && adminAutomationsSuccess.data ? adminAutomationsSuccess.data : []}
                        selectAutomation={(data) => {
                            this.setState({
                                selectedAutomation: data
                            })
                        }}
                        selectedAutomation={this.state.selectedAutomation}
                    />}

                    {currentStep === 2 && <div>
                        <Typography variant="body1" style={{ marginBottom: '8px' }}>Automation Details for <b>{this.state.selectedAutomation?.name}</b> </Typography>
                        <Box style={{ border: '1px solid #87929d', padding: '2px', borderRadius: '8px', maxHeight: '60vh', overflow: 'auto' }}>
                            <SlateInputField
                                readOnly={true}
                                isToolBar={false}
                                placeholder=""
                                style={{
                                    margin: 0,
                                }}
                                as={SlateInputField}
                                initValue={this.state.selectedAutomation?.description || ''}
                                formControlStyle={{ border: 'none', overflow: 'hidden' }}
                                textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
                            />
                        </Box>

                    </div>}

                    {currentStep === 3 && <div>
                        <Typography variant="body1" style={{ marginBottom: '8px' }}>Automation Name</Typography>
                        <OutlinedInput
                            id={"formTitleInput"}
                            placeholder={"Enter your automation name"}
                            autoFocus={true}
                            value={this.state.automationName}
                            onChange={(event) => {
                                this.setState({
                                    automationName: event.target.value
                                }, () => {
                                    this.clearError();
                                })
                            }}
                        />
                    </div>}

                    <div className={classes.actionBtns}>
                        <SqureButton
                            cls={classes.actionBtn}
                            variant={"outlined"}
                            onClick={(e) => {
                                if (currentStep === 1) {
                                    this.props.hideDialog()

                                } else if (currentStep === 2) {
                                    this.setState({
                                        currentStep: 1
                                    })
                                } else if (currentStep === 3) {
                                    this.setState({
                                        currentStep: 2
                                    })
                                }
                            }}
                        >
                            {currentStep === 1 ? 'Cancel' : 'Back'}
                        </SqureButton> &nbsp;&nbsp;&nbsp;
                        <SqureButton
                            disabled={currentStep === 1 || currentStep === 2 ? !this.state.selectedAutomation : !this.state.selectedAutomation || this.state.automationName === ''}
                            cls={classes.actionBtn}
                            variant={"contained"}
                            onClick={(e) => {
                                this.handleSubmit()
                            }}
                        >
                            {currentStep === 1 || currentStep === 2 ? 'Next' : 'Import'}
                        </SqureButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}


export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddExistingAutomationDialog));