import React, { useState } from "react";
// import _ from "underscore";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Button, Grid, CircularProgress, FormControl, Select, MenuItem } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
// icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { Send as SendIcon } from "@material-ui/icons";
import moment from "moment";
// Redux
import { fetchThread, fetchThreadReplies, addThread, updateThread, deleteThread } from "redux/workflow/thread/action";
import { fetchUsers } from "redux/usersSettings/action";
import { sendAddMentionNotification } from "services/addMentionNotification";
import { showSnackBar } from "redux/snackbar/action";

// Components
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import ShowResponseReply from "./ShowResponseReply";
const connectedProps = (state) => ({
  authUser: state.authUser.user,
  usersSettings: state.usersSettings,
  fetchThreadProgress: state.evaluationThread.fetchThreadProgress,
  threads: state.evaluationThread.threads,
  fetchThreadRepliesProgress: state.evaluationThread.fetchThreadRepliesProgress,
  fetchThreadRepliesError: state.evaluationThread.fetchThreadRepliesError,
  deleteThreadProgress: state.evaluationThread.deleteThreadProgress,
  deleteThreadError: state.evaluationThread.deleteThreadError,
  addThreadProgress: state.evaluationThread.addThreadProgress,
  addThreadError: state.evaluationThread.addThreadError,
  addThreadSuccess: state.evaluationThread.addThreadSuccess,
  updateThreadProgress: state.evaluationThread.updateThreadProgress,
  updateThreadError: state.evaluationThread.updateThreadError,
  updateThreadSuccess: state.evaluationThread.updateThreadSuccess,
  evaluation: state.evaluationMetaData.evaluation
});

const connectionActions = {
  fetchThread: fetchThread,
  addThread: addThread,
  updateThread: updateThread,
  deleteThread: deleteThread,
  fetchThreadReplies: fetchThreadReplies,
  _fetchUsers: fetchUsers,
  showSnackBar: showSnackBar,
};
var connector = connect(connectedProps, connectionActions);

const useMessageStyles = createStyles((theme) => ({
  root: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    placeContent: "flex-start space-between",
    alignItems: "flex-start",
    paddingBottom: 50,
    background: 'white',
    borderRadius: 5,
    // borderBottom: '1px solid #EEEEEE',
    "& [class*='MuiInputLabel']": {
      marginBottom: '0px'
    },
    "& [data-slate-node='element']": {
      marginTop: 5,
      marginLeft: 8,
      marginBottom: 8,
      fontSize: 14
    }
  },
  userAvatar: {
    width: "20px",
    margin: "15px",
    marginTop: '12px'
  },
  tblSelecter: {
    '& [class*=MuiSelect-outlined-]': {
      fontSize: 13,
      padding: '8px 12px',
      paddingRight: '32px',
      background: '#ffffff'
    }
  },
  avatar: {
    width: "60px",
    height: "60px",
    margin: "0px auto",
    borderRadius: "50%",
  },
  container: {
    width: "100%",
    // width: "calc(100% - 60px)",
    marginTop: "15px",
    marginBottom: "15px",
  },
  userName: {
    fontWeight: 600,
    color: "#000000",
    margin: "0px",
    fontSize: "15px"
  },
  headerWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  messageBody: {
    fontSize: "12px",
    marginBottom: "8px",
  },
  footer: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    marginTop: theme.spacing(2),
  },
  actionCol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
    marginBottom: 10,
  },
  leftColSpan: {
    fontWeight: "500",
    letterSpacing: "0px",
    marginRight: 10,
    fontSize: "0.8rem",
    cursor: 'pointer',
    textDecoration: "underline",
    color: "#4A87F8",
  },
  btnWrap: {
    textAlign: 'right'
  },
  viewMore: {
    fontSize: '14px',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#217fec',
    marginTop: '10px',
    textDecoration: 'underline',
    marginLeft: '85px',
  },
  slateWrap: {
    display: "block",
    "& [class*='editableField']": {
      minHeight: '70px !important',
    },
  },
  slateWrap2: {
    marginLeft: '90px',
    "& [class*='editableField']": {
      minHeight: "70px !important",
      margin: "0px !important",
    },
  }
}));

class InternalChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      sortBy: "desc",
      editThread: 0,
      commentMessage: "",
      replyMessage: "",
      showAddReplyID: null,
      showThread: true,
      addingCollaborator: false,
      removingCollaborator: false,
      removableCollaboratorIds: [],
      removableCollaboratorNames: [],
    };
  }

  componentDidMount() {
    this.props._fetchUsers(0, 100, "");
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetchThreadProgress && prevProps.fetchThreadProgress) {
      this.setState({
        commentMessage: 0,
        replyMessage: 0,
        showAddReplyID: null,
        addMentionData: {
          evaluation_name: this.props.evaluation?.Name,
          subject: `You have been mentioned in ${this.props.evaluation?.Name}`,
        }
      })
    }
    if (!this.props.deleteThreadProgress && prevProps.deleteThreadProgress) {
      if (!this.props.deleteThreadError) {
        this.props.showSnackBar("Comment deleted successfully", "success", 3000);
        this.fetchData();
      } else {
        this.props.showSnackBar("Failed to delete comment", "error", 3000);
      }
    }


    if (!this.props.fetchThreadRepliesProgress && prevProps.fetchThreadRepliesProgress) {
      if (this.props.fetchThreadRepliesError) {
        this.props.showSnackBar("Failed to fetch replies.", "error", 3000);
      }
    }

    if (!this.props.addThreadProgress && prevProps.addThreadProgress) {
      if (!this.props.addThreadError) {
        this.props.showSnackBar("Comment added successfully", "success", 3000);
        this.fetchData();
        let comment = this.props.addThreadSuccess?.Message;
        let data = { ...this.state.addMentionData, comment: comment, time: moment().format("MMM Do YYYY hh:mm A") }
        sendAddMentionNotification(comment, 'Approval_Criteria', data);
      } else {
        this.props.showSnackBar("Failed to add comment.", "error", 3000);
      }
    }

    if (!this.props.updateThreadProgress && prevProps.updateThreadProgress) {
      if (!this.props.updateThreadError) {
        this.props.showSnackBar("Comment updated successfully", "success", 3000);
        this.fetchData();
        let comment = this.props.updateThreadSuccess?.Message;
        let data = { ...this.state.addMentionData, comment: comment, time: moment().format("MMM Do YYYY hh:mm A") }
        sendAddMentionNotification(comment, 'Approval_Criteria', data);
      } else {
        this.props.showSnackBar("Failed to update comment.", "error", 3000);
      }
    }
  }

  fetchData = () => {
    this.props.fetchThread(this.props.evaluationId, this.props.componentId, this.state.sortBy, "Requirement_" + this.props.requirementId);
  };

  addResponse = () => {
    var bodyText = document.querySelectorAll("#userCommentAdd .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "");
    if (String(this.state.commentMessage).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Type your comment...') !== -1) {
      this.props.showSnackBar("Please type your comment", "error", 3000);
      return;
    }
    this.props.addThread(this.props.evaluationId, this.props.componentId, {
      message: this.state.commentMessage,
      thread_type: "Requirement_" + this.props.requirementId
    });
  };

  render() {
    const { classes, fetchThreadProgress, threads } = this.props;

    let responseThread = threads || [];
    let usersList = this.props.usersSettings?.users || [];
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.headerWrap}>
            <h5 className={classes.userName}>Comments</h5>
            <div className={classes.tblSelecter}>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select value={this.state.sortBy} onChange={(e) => {
                  this.setState({ sortBy: e.target.value }, () => {
                    this.fetchData();
                  })
                }}>
                  <MenuItem value={'desc'} style={{ fontSize: 13 }}>Most recent</MenuItem>
                  <MenuItem value={'asc'} style={{ fontSize: 13 }}>Least recent</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className={classes.slateWrap} id="userCommentAdd" style={{ marginBottom: 30 }}>
            <SlateInputField
              enableMention={true}
              style={{ width: "100%", margin: "12px 0px" }}
              as={SlateInputField}
              isToolBar={true}
              onChangeEvent={(value) => {
                this.setState({ commentMessage: value });
              }}
              placeholder="Type your comment..."
              initValue={this.state.commentMessage}
              formControlStyle={{}}
              textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
              id='main_thread'
            />
            <div className={classes.btnWrap}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                onClick={() => {
                  this.addResponse();
                }}
              >
                Add comment
              </Button>
            </div>
          </div>

          {fetchThreadProgress && (
            <Grid container justify='center' style={{ marginTop: '30px' }}>
              <CircularProgress />
            </Grid>
          )}

          {!fetchThreadProgress && responseThread && responseThread.map((parentResponse, k) => {
            return <div className={classes.messageBody}>
              {(parentResponse.AdditionalData === null || parentResponse.AdditionalData === '') && <ReplySection
                thread={parentResponse}
                parentThreadId={null}
                {...this.props}
                users={usersList}
              />}

              {parentResponse.AdditionalData && <div>
                <ShowResponseReply
                  response={parentResponse?.Meta?.response}
                  requirement={this.props.requirement}
                  product={this.props.requirement}
                  enableScoring={this.props.enableScoring}
                />
              </div>}

              <div className={classes.footer2}>
                <div className={classes.actionCol}>
                  {!this.state.showAddReplyID && (
                    <span
                      className={classnames(classes.leftColSpan)}
                      onClick={() => {
                        this.setState({ showAddReplyID: parentResponse.ID })
                      }}
                    >
                      Reply
                    </span>
                  )}
                </div>
              </div>
              {parentResponse?.ChildThreads &&
                parentResponse.ChildThreads.map((response, i) => {
                  return (
                    <div style={{ marginLeft: 55 }}>
                      <ReplySection
                        key={i}
                        parentThreadId={parentResponse.ID}
                        thread={response}
                        {...this.props}
                        users={usersList}
                      />
                      <div className={classes.footer2}>
                        <div className={classes.actionCol}>
                          {!this.state.showAddReplyID && (
                            <span
                              className={classnames(classes.leftColSpan)}
                              onClick={() => {
                                this.setState({ showAddReplyID: parentResponse.ID })
                              }}
                            >
                              Reply
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}

              {parentResponse.ChildThreads?.length === 3 && parentResponse.ChildThreadsCount > 3 && <div className={classes.viewMore} onClick={() => this.props.fetchThreadReplies(this.props.evaluationId, this.props.componentId, parentResponse.ID)}>View {parentResponse.ChildThreadsCount - 3} more {(parentResponse.ChildThreadsCount - 3) > 1 ? "replies" : "reply"}</div>}

              {this.state.showAddReplyID === parentResponse.ID && <div className={classes.slateWrap2} id="userReplyAdd">
                <SlateInputField
                  enableMention={true}
                  style={{ width: "100%", margin: "12px 0px" }}
                  as={SlateInputField}
                  isToolBar={true}
                  onChangeEvent={(value) => {
                    this.setState({ replyMessage: value })
                  }}
                  placeholder={"Type your reply..."}
                  initValue={this.state.replyMessage}
                  formControlStyle={{}}
                  textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
                  id={'thread_reply_' + parentResponse.ID}
                />
                <div className={classes.btnWrap}>
                  <Button
                    style={{ marginRight: 20 }}
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                    onClick={() => {
                      var bodyText = document.querySelectorAll("#userReplyAdd .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "");
                      if (String(this.state.replyMessage).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Type your reply...') !== -1) {
                        this.props.showSnackBar("Please type your reply", "error", 3000);
                        return;
                      }
                      this.props.addThread(this.props.evaluationId, this.props.componentId, { message: this.state.replyMessage, parent_thread_id: parentResponse.ID });
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ showAddReplyID: false })
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>}
            </div>
          }
          )}

        </div>
      </div>
    );
  }
}

// Component styles
const useStyles = makeStyles((theme) => ({
  responseWrap: {
    // minWidth: 300,
    // maxWidth: '60%',
    width: '100%',
  },
  threadNote: {
    "& [class*='editableField']": {
      minHeight: "auto !important",
      margin: "0px !important",
    },
    display: "block",
    background: "#f1f1f1",
    // border: "1px solid #217fec",
    padding: "10px",
    paddingTop: "8px",
    paddingBottom: "0px",
    borderRadius: "16px",
    fontSize: "13px",
  },
  replyActionContainer: {
    marginTop: "14px",
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    gap: 10,
    // placeContent: "space-between",
    alignItems: "flex-start",
  },
  userAvatar: {},
  replyAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg": {
      width: "16px",
      height: "16px",
      color: "#217FEC",
      marginRight: "15px",
      cursor: "pointer",
    },
  },
  btnWrap: {
    textAlign: 'right'
  },
  slateWrap: {
    marginLeft: '90px',
    marginTop: '10px',
    "& [class*='editableField']": {
      minHeight: "70px !important",
      margin: "0px !important",
    },
  }
}));

function ReplySection(props) {
  const { thread } = props;

  const [edit, setEdit] = useState(0);
  const [reply, setSetReply] = useState("");

  const classes = useStyles();

  const avatarName = (name) => {
    if (name) {
      return name.substring(0, 1);
    }
  };


  return (
    <>
      <div className={classes.replyActionContainer}>
        <span className={classes.userAvatar}>
          <Avatar style={{ width: "25px", height: "25px", fontSize: "15px" }}>{avatarName(thread?.User?.Name || "User")}</Avatar>
        </span>
        <div className={classes.responseWrap}>
          <span className={classes.threadNote}>
            <div style={{ display: "flex", placeContent: "space-between" }}>
              <span style={{ fontWeight: 600, marginLeft: 7 }}>{thread?.User?.Name || "..."}</span>
              <span className={classes.replyAction}>
                {edit > 0 && (
                  <>
                    <SaveIcon
                      onClick={() => {
                        var bodyText = document.querySelectorAll("#userCommentEdit .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "");
                        if (String(reply).trim().length === 0 || String(bodyText).trim().length === 0) {
                          props.showSnackBar("Please enter your response.", "error", 3000);
                          return;
                        }
                        let body = { message: reply };
                        if (props.parentThreadId !== null) {
                          body['parent_thread_id'] = props.parentThreadId;
                        }
                        props.updateThread(thread?.EvaluationID, thread?.ComponentId, thread?.ID, body);
                      }}
                    />
                    <CancelIcon
                      onClick={() => {
                        setEdit(0);
                      }}
                    />
                  </>
                )}
                <span style={{ fontSize: "12px", marginRight: 12 }}>{timeSince(thread?.CreatedAt)} ago</span>
                {props.authUser.ID === thread?.UserID && edit === 0 && (
                  <>
                    <EditIcon
                      onClick={() => {
                        setEdit(thread?.ID);
                      }}
                    />
                    <DeleteIcon
                      style={{ marginRight: 0 }}
                      onClick={() => {
                        props.deleteThread(thread?.EvaluationID, thread?.ComponentId, thread?.ID);
                      }}
                    />
                  </>
                )}
              </span>
            </div>
            <SlateInputField
              placeholder=""
              isToolBar={false}
              enableMention={true}
              readOnly={true}
              as={SlateInputField}
              formControlStyle={{ border: "none", background: "transparent" }}
              initValue={thread?.Message}
              textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
              paperStyle={{ fontWeight: 400, background: "transparent" }}
              style={{ width: "100%", marginBottom: 0, background: "transparent" }}
              id={'child_thread_' + thread?.ID}
            />
          </span>

        </div>

      </div>
      {edit === thread?.ID && (
        <div id="userCommentEdit" className={classes.slateWrap}>
          <SlateInputField
            style={{ width: "100%", marginBottom: 0 }}
            enableMention={true}
            placeholder=""
            as={SlateInputField}
            initValue={thread?.Message}
            formControlStyle={{}}
            textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
            paperStyle={{ fontWeight: 400 }}
            onChangeEvent={(value) => {
              setSetReply(value);
            }}
            id={'child_thread_user_comment_' + thread?.ID}
          />
        </div>
      )}
    </>
  );
}

function timeSince(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    if (interval === 1) {
      return Math.floor(interval) + " year";
    } else {
      return Math.floor(interval) + " years";
    }
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " month";
    } else {
      return Math.floor(interval) + " months";
    }
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " day";
    } else {
      return Math.floor(interval) + " days";
    }
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " hour";
    } else {
      return Math.floor(interval) + " hours";
    }
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " minute";
    } else {
      return Math.floor(interval) + " minutes";
    }
  }
  return Math.floor(seconds) + " seconds";
}

export default connector(compose(withRouter, withStyles(useMessageStyles))(InternalChat));
