import isHotkey from 'is-hotkey';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createEditor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/styles/makeStyles';
import classnames from "classnames";
import { withLinks } from './SlateImage';

import { HOTKEYS, SlateElement, toggleMark } from './SlateEditorUtil';
import { SlateToolbar } from './SlateToolbar';
import ToolTip from "Components/Common/ToolTip.jsx";
import withTables from "../SlateEditorNew/plugins/withTable";

export const SlateInputFieldV2 = ({
  initValue,
  required,
  fullWidth,
  placeholder,
  textContainerStyle,
  ...props
}) => {
  let editorInitialValue = [{
    type: 'paragraph',
    children: [{ text: '' }],
  }];
  const classes = useStyles();
  const [slateValue, setSlateValue] = useState(editorInitialValue);

  const editor = useMemo(
    () => withImages(withTables(withVariables(withMentions(withLinks(withHistory(withReact(createEditor()))))))),
    []
  )

  useEffect(() => {
    if (initValue && typeof initValue === 'string') {
      try {
        editorInitialValue = JSON.parse(initValue);
      } catch (e) {
        editorInitialValue = [
          {
            type: 'paragraph',
            children: [{ text: initValue }],
          }
        ];
        // console.log(e)
      }
      // console.log(JSON.stringify(editorInitialValue),"11111")
      setSlateValue(editorInitialValue);
    }
    if (initValue === 0) {
      editorInitialValue = [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        }
      ];
      // console.log(editorInitialValue,"11111")
      Transforms.select(editor, [0])
      setSlateValue(editorInitialValue);
    }


  }, [initValue]);

  const renderElement = useCallback((props) => <SlateElement {...props} />, []);
  // const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);

  const findUrlsInText = (text) => {

    const urlRegex =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

    // var urlRegex =
    // /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

    const matches = text.match(urlRegex);

    return matches ? matches.map((m) => [m.trim(), text.indexOf(m.trim())]) : [];
  };

  const myDecorator = ([node, path]) => {
    const nodeText = node.text;

    if (!nodeText) return [];

    const urls = findUrlsInText(nodeText);

    return urls.map(([url, index]) => {
      return {
        anchor: {
          path,
          offset: index,
        },
        focus: {
          path,
          offset: index + url.length,
        },
        decoration: "link",
      };
    });
  };


  const renderLeaf = ({ attributes, children, leaf }) => {
    if (leaf.decoration === "link") {
      children = (
        <a
          style={{ cursor: "pointer", color: 'blue' }}
          href={leaf.text}
          target={"_blank"}
          rel={"noreferrer"}
          onClick={() => {
            let url = '';
            if (leaf.text.indexOf('http') === -1) {
              url = 'http://' + leaf.text;
            } else {
              url = leaf.text;
            }
            window.open(url, "_blank", "noopener,noreferrer");
          }}
        >
          {children}
        </a>
      );
    }
    return <span {...attributes}>{children}</span>;
  };

  return (
    <div style={props.style} className={classnames(classes.input, { [classes.nobg]: props.roundedCorner, [classes.inline]: props.labelStyle === "inline" })}>
      {props.label !== null && <InputLabel
        className={classes.label}
        error={props.error}
        htmlFor={props.id}>{props.label}{required && <span style={{ 'color': 'red' }}>&nbsp;*</span>} {props.toolTip && <ToolTip toolTip={props.toolTip} />}</InputLabel>}
      <FormControl
        className={classnames(classes.formControl, 'editor-formControl')}
        style={props.formControlStyle}
        required={required}
        fullWidth={fullWidth}
      >
        <Paper className={classes.paper} style={props.paperStyle}>
          <Slate
            editor={editor}
            value={slateValue}
            onChange={(value) => {
              setSlateValue(value);
              props.onChangeEvent(JSON.stringify(value))
            }}
          >
            <Editable
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              readOnly={props.readOnly}
              decorate={myDecorator}
              renderElement={renderElement}
              className={classnames(classes.editableField, 'editor-editableField')}
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
              style={{ minHeight: 300, margin: 10, ...textContainerStyle }}
            />
            {props.isToolBar && <SlateToolbar {...props} />}
          </Slate>
        </Paper>
      </FormControl>
      {props.helperText?.trim().length > 0 && <FormHelperText
        className={classes.helperText}
        error={props.error}
      >{props.helperText}</FormHelperText>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: '100%',
    borderRadius: 4,
    border: "none",
  },
  editableField: {
    overflow: 'overlay',
  },
  "input": {
    textAlign: "left",
    marginBottom: theme.spacing(3.5),
    background: "#FFF"
  },

  "label": {
    marginBottom: theme.spacing(1.6),
    fontSize: theme.spacing(2),
    color: "#6C6C6C"
  },
  outlinedInput: {
    fontSize: theme.spacing(2.2),
    color: "#5F5F5F",
    padding: theme.spacing(1.5),
  },
  helperText: {
    fontSize: theme.spacing(1.5),
  },
  nobg: {
    background: "none"
  },
  inputRoot: {
    background: "#fff",
    minHeight: "50px",
    width: "100%",
  },
  paper: {
    boxShadow: 'none'
  }
}));

SlateInputFieldV2.defaultProps = {
  isToolBar: true,
  readOnly: false
};


const withMentions = editor => {
  const { isInline, isVoid } = editor
  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }
  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }
  return editor
}

const withVariables = editor => {
  const { isInline, isVoid } = editor
  editor.isInline = element => {
    return element.type === 'variable' ? true : isInline(element)
  }
  editor.isVoid = element => {
    return element.type === 'variable' ? true : isVoid(element)
  }
  return editor
}

const withImages = editor => {
  const { isInline, isVoid } = editor

  editor.isInline = element => {
    return element.type === 'image' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  return editor
}