import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import AnsweredIcon from 'assets/images/evaluation/QusAnswered.svg';
import DraftIcon from 'assets/images/evaluation/reqDraft.svg';

import Image from 'Components/Common/image.jsx';

import { hideSellerFinalizeReqDialog } from "redux/seller/action";
import { setSelectedRequirement } from "redux/evaluation/criteria/action"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.seller.showRequirementFinalizeDialog,
    showRequirementType: state.seller.showRequirementType,
    isShowViewDetails: state.seller.isShowViewDetails,
    evaluation: state.seller.evaluation
});

const connectionActions = {
    hideDialog: hideSellerFinalizeReqDialog,
    setSelectedRequirement:setSelectedRequirement
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions: {
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        minWidth: '200px'
    },
    actionBtnDisabled: {
        minWidth: '200px',
    },
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    productTitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    title: {
        textAlign: "center",
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px 0px'
    },
    prdHead: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    productName: {
        fontWeight: 600,
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(2.2),
        color: '#282D30',
    },
    productImg: {
        width: theme.spacing(3),
        marginRight: theme.spacing(0.6)
    },
    legendBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    green: {
        background: '#2EB77D',
        color: '#fff',
        padding: theme.spacing(0.50, 1),
        marginLeft: theme.spacing(3),
        border: '1px solid'
    },
    orange: {
        background: 'orange',
        color: '#fff',
        padding: theme.spacing(0.50, 1),
        marginLeft: theme.spacing(3),
        border: '1px solid'
    },
    red: {
        background: 'red',
        color: '#fff',
        padding: theme.spacing(0.50, 1),
        marginLeft: theme.spacing(3),
        border: '1px solid'
    },
    greenLegend: {
        background: '#2EB77D',
        padding: '0px 10px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    orangeLegend: {
        background: 'orange',
        padding: '0px 10px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    redLegend: {
        background: 'red',
        padding: '0px 10px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    legends: {
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    reqName: {
        width: '200px'
    },
    prdName: {
        width: '20%',
        textAlign: 'center'
    },
    currProductTitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    otherProductTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    otherProductTotal: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        opacity: '0.6',
        fontSize: theme.spacing(2)
    },
    finalizeForm: {
        '& [class*=MuiTable-root]': {

        },
        '& [class*=MuiTableCell]': {
            border: '1px solid #DEDEDE',
        },
        '& [class*=MuiTableCell-root]': {
            padding: theme.spacing(1, 1)
        },
    },
    mandatoryTable: {
        borderCollapse: 'separate !important',
        width: '100%',
        borderSpacing: 0,
        // border: '1px solid #EB3D1A',
        borderRadius: '4px',
    },
    otherTable: {
        borderCollapse: 'separate !important',
        width: '100%',
        borderSpacing: 0,
        borderRadius: '4px',
        border: '1px solid #DEDEDE',
    },
    pd_action: {
        letterSpacing: '0px',
        color: '#0093E0 !important',
        opacity: 1,
        cursor: 'pointer',
        fontSize: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    mandatoryItems: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

class FinalizeRequiremntViewDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            feedback: "",
            feedback_error: "",
            products: []
        }
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const classes = this.props.classes;
        const { evaluation } = this.props;
        var totalReq = 0;
        var totalDone = 0;
        var totalDraft = 0;

        // var criterias = evaluation.criterias !== undefined ? evaluation.criterias : [];
        var criterias = (evaluation !== undefined && evaluation !== null) ? evaluation?.criterias : [];
        var progress = (evaluation !== undefined && evaluation !== null) ? evaluation.progress : {};

        totalReq = progress?.Total;
        totalDone = progress?.Completed;
        totalDraft = progress?.Draft;

        var requirements = [];
        criterias.forEach(function (criteria) {
            criteria.requirements.forEach(function (req) {
                requirements.push(req);
            })
        });

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>

                <div className={classes.finalizeForm}>
                    <Typography variant={"h4"} className={classes.title}>User Response Status</Typography>
                    {<div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <Typography variant={"h6"} className={classes.otherProductTitle}>
                                    Requirements
                                </Typography>
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                <Typography variant={"h6"} className={classes.otherProductTotal}>
                                    {this.props.showRequirementType === 'DONE' ? totalDone + '/' + totalReq + ' Completed' : totalDraft + '/' + totalReq + ' Draft'}
                                </Typography>
                            </div>
                        </div>

                        <Table className={classes.mandatoryTable} aria-label="simple table">
                            {evaluation !== null && evaluation !== undefined && evaluation.productLogo !== undefined && <TableHead>
                                <TableRow>
                                    <TableCell className={classes.reqName}></TableCell>
                                    <TableCell className={classes.prdName}>
                                        <div className={classes.prdHead}>
                                            <Image alt="Mandatory" className={classes.productImg} src={evaluation?.productLogo} /> &nbsp;&nbsp;{evaluation?.productName}</div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>}
                            <TableBody>
                                {requirements.map((req) => {
                                    if (req.response_status !== this.props.showRequirementType) {
                                        return '';
                                    }
                                    return <TableRow>
                                        <TableCell className={classes.reqName}>{req.name}</TableCell>
                                        <TableCell className={classes.prdName}><Image alt="Answered" src={this.props.showRequirementType === 'DONE' ? AnsweredIcon : DraftIcon} />
                                                    {this.props.isShowViewDetails && <span className={classes.pd_action} onClick={() => {
                                                            this.props.hideDialog();
                                                            this.props.setSelectedRequirement(req.id);
                                                        }}>
                                                            View Details
                                                        </span>}
                                        </TableCell>
                                    </TableRow>
                                })}
                                {this.props.showRequirementType === 'DONE' && totalDone === 0 && <TableRow>
                                        <TableCell colSpan={2} align="center" className={classes.reqName}>No Completed Questions</TableCell>
                                    </TableRow>}

                                    {this.props.showRequirementType === 'DRAFT' && totalDone === 0 && <TableRow>
                                        <TableCell colSpan={2} align="center" className={classes.reqName}>No Draft Questions</TableCell>
                                    </TableRow>}

                            </TableBody>
                        </Table></div>}
                </div>
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(FinalizeRequiremntViewDialog));