import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { updateEvaluationMetaData, fetchEvaluationMetaData, emptyProductsList, fetchProductsList, fetchEvaluationInfo } from "../../../../../../../redux/evaluation/metadata/action";
import {
  fetchUserRequirementResponse,
  fetchScores,
  fetchRequirementTree,
  fetchLastEvaluationUserResponse,
  fetchRequirementResponse,
  fetchRequirementUserResponse,
  fetchCriteria,
  setSelectedRequirement,
  setSelectedProduct,
  fetchEvaluationProductsScore,
  fetchEvaluationOverallProductsScore,
  fetchRequirementUserResponseStatus,
  fetchRequirementOrgProgressStatus,
  updatecriteriaReqShowMap,
  setForAdminFlag,
  fetchPreviousInteraction,
  clearRequirementResponse,
} from "../../../../../../../redux/evaluation/criteria/action";
import { fetchProductRequirementDetails } from "redux/product/action";
import { toggleEvaluateView, toggleEvaluateNeverReviewed } from "redux/applications/action";
import { hideFeedbackTakeDialog } from "redux/evaluation/feedback/action";
import { showSnackBar } from "redux/snackbar/action";

import { fetchEvaluationMeta } from "redux/seller/action";

import { showWorkflowEvaluationGuideFormDialog, hideWorkflowEvaluationGuideFormDialog, vendorSendAllQuestions } from "redux/workflow/action";

import { withRouter } from 'react-router-dom';
import * as URL from "util/URL";
import { Grid, CircularProgress } from '@material-ui/core';
import EvaluationBar from "../Common/EvaluationBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classnames from "classnames";
import moment from 'moment';

import CurrentProduct from './Sections/CurrentProduct';
import Requirements from './Sections/Requirements';
import Response from './Sections/Response';
import BottomActions from './Sections/BottomActions';
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";

import FinalizeRequiremntViewDialog from "./dialogs/FinalizeRequiremntViewDialog";
import OverallRequiremntViewDialog from "./dialogs/OverallRequiremntViewDialog";
import AddUpdateTaskDialog from 'Components/Common/Dialogs/AddUpdateTaskDialog';

import FeedbackDialog from "./dialogs/FeedbackDialog";
import EvaluationGuideForm from "../WorkFlow/Components/EvaluationGuideForm";

import { fetchEvaluationSelectedFeatures } from "redux/evaluation/metadata/action";
import AskSellerDetailsUpdateDialog from './dialogs/AskSellerDetailsUpdateDialog';
import SendLoader from './dialogs/SendLoader';

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  updateProgress: state.evaluationMetaData.updateProgress,
  updateError: state.evaluationMetaData.errorUpdate,
  addResponseProgress: state.evaludationCriteria.addResponseProgress,
  user: state.authUser.user,
  productRequirement: state.products.productRequirement,
  userReponses: state.evaludationCriteria.userReponses,
  requirementTree: state.evaludationCriteria.requirementTree,
  lastEvaUserReponses: state.evaludationCriteria.lastEvaUserReponses,
  fetchLastEvaluationUserResponseProgress: state.evaludationCriteria.fetchLastEvaluationUserResponseProgress,
  requirementResponses: state.evaludationCriteria.requirementResponses,
  requirementUserResponses: state.evaludationCriteria.requirementUserResponses,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
  selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
  selectedProductId: state.evaludationCriteria.selectedProductId,
  addFeedbackProgress: state.evaluationFeedback.addFeedbackProgress,
  addFeedbackError: state.evaluationFeedback.addFeedbackError,
  saveProgress: state.evaludationCriteria.askSellerSubmitProgress,
  fetchCriteriaProgress: state.evaludationCriteria.fetchProgress,
  isCheckTreeConditions: state.evaludationCriteria.isCheckTreeConditions,
  criteriaReqShowMap: state.evaludationCriteria.criteriaReqShowMap,
  showRequirementIds: state.evaludationCriteria.showRequirementIds,
  criterias: state.evaludationCriteria.criterias,
  colloborators: state.evaludationCriteria.colloborators,
  fetchColloboratorProgress: state.evaludationCriteria.fetchColloboratorProgress,
  userRole: state.authUser.user?.Role,
  fetchReqResponseUserResProgress: state.evaludationCriteria.fetchReqResponseUserResProgress,
  fetchReqTreeProgress: state.evaludationCriteria.fetchReqTreeUnderProgress,
  isRequirementForAdmin: state.evaludationCriteria.isRequirementForAdmin,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  isShowEvaluationGuideFormDialog: state.workflow.isShowEvaluationGuideFormDialog,
  evaluationSelectedFeaturesError: state.evaluationMetaData.evaluationSelectedFeaturesError,
  evaluationSelectedFeatures: state.evaluationMetaData.evaluationSelectedFeatures,
  evaluationSelectedFeaturesProgress: state.evaluationMetaData.evaluationSelectedFeaturesProgress,
  showFinalizeRequirementDialog: state.evaluationFeedback.showFinalizeRequirementDialog,
  showOverallRequirementDialog: state.evaluationFeedback.showOverallRequirementDialog,
  showFeedbackTakeDialog: state.evaluationFeedback.showFeedbackTakeDialog,
  fetchEvaluationInfoProgress: state.evaluationMetaData.fetchEvaluationInfoProgress,
  evaluationInforError: state.evaluationMetaData.evaluationInforError,
  evaluationInfoData: state.evaluationMetaData.evaluationInfoData,
  isOpenTaskDialog: state.productTasks.showAddTaskDialog,
  sellerEvaluationMetaFetchProgress: state.seller.metaFetchProgress,

  sendVendorQuestionsProgress: state.workflow.sendVendorQuestionsProgress,
  sendVendorQuestionsError: state.workflow.sendVendorQuestionsError,
  sendVendorQuestionsSuccess: state.workflow.sendVendorQuestionsSuccess

});

const connectionActions = {
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  fetchUserRequirementResponse: fetchUserRequirementResponse,
  fetchScores: fetchScores,
  fetchRequirementTree: fetchRequirementTree,
  fetchLastEvaluationUserResponse: fetchLastEvaluationUserResponse,
  fetchRequirementResponse: fetchRequirementResponse,
  fetchRequirementUserResponse: fetchRequirementUserResponse,
  fetchCriteria: fetchCriteria,
  setSelectedProduct: setSelectedProduct,
  setSelectedRequirement: setSelectedRequirement,
  fetchProductRequirementDetails: fetchProductRequirementDetails,
  fetchProductsList: fetchProductsList,
  emptyProductsList: emptyProductsList,
  fetchEvaluationProductsScore: fetchEvaluationProductsScore,
  hideFeedbackTakeDialog: hideFeedbackTakeDialog,
  updateEvaluationMetaData: updateEvaluationMetaData,
  toggleEvaluateView: toggleEvaluateView,
  toggleEvaluateNeverReviewed: toggleEvaluateNeverReviewed,
  fetchEvaluationMeta: fetchEvaluationMeta,
  showSnackBar: showSnackBar,
  fetchRequirementUserResponseStatus: fetchRequirementUserResponseStatus,
  fetchEvaluationOverallProductsScore: fetchEvaluationOverallProductsScore,
  fetchRequirementOrgProgressStatus: fetchRequirementOrgProgressStatus,
  updatecriteriaReqShowMap: updatecriteriaReqShowMap,
  setForAdminFlag: setForAdminFlag,
  showWorkflowEvaluationGuideFormDialog: showWorkflowEvaluationGuideFormDialog,
  hideWorkflowEvaluationGuideFormDialog: hideWorkflowEvaluationGuideFormDialog,
  fetchPreviousInteraction: fetchPreviousInteraction,
  fetchEvaluationSelectedFeatures: fetchEvaluationSelectedFeatures,
  fetchEvaluationInfo: fetchEvaluationInfo,
  clearRequirementResponse: clearRequirementResponse,
  vendorSendAllQuestions: vendorSendAllQuestions
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  contentContainer: {
    // marginBottom: '100px'
  },
  content: {
    padding: theme.spacing(4, 10),
    background: "#F7F7F7",
    minHeight: "90vh"
  },
  reqContainer: {
    background: '#fff',
    maxHeight: "83vh",
    minHeight: "83vh",
    padding: theme.spacing(4, 4),
    overflowY: 'auto'
  },
  mainContainer: {
    padding: theme.spacing(4, 4),
    overflow: 'hidden scroll',
    height: "75vh"
  },
  currentProContainer: {
    background: '#fff',
    maxHeight: "83vh",
    minHeight: "83vh",
    padding: theme.spacing(4, 4),
    overflowY: 'auto'
  }
});

class InProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: [],
      selectedAnswers: [1, 2],
      selectedTab: 0,
      isHideTopBar: false,
      isHideAskSeller: false,
      isHideCommunityResponse: false,
      isHideYourResponse: false,
      scoring_mechanism: {
        id: 'Numerical'
      },
      featureRecommendation: false,
      configurationCheckDone: false,
      fetchFeatureRecommendationDone: false,
      callOnce: true,
      showLoader: true,
      componentId: this.props.match.params.component_id !== undefined ? this.props.match.params.component_id : 0,
      enableScoring: false,
      commentRequired: true,
      isShowAskSellerUpdateDialog: false,
      isShowMyReqSwitch: true,
      autoPopulateVendor: false,
      vendorEmailField: '',
      vendorNameField: '',
      isSendingReqToVendors: false,
      isCriteriaLoadDone: false,
      askSellerValue: null,
      vendorDeadline: ''
    }
    this.checkUserAssignment = this.checkUserAssignment.bind(this)
  }

  componentDidMount() {
    this.props.clearRequirementResponse()
    this.props.setForAdminFlag(false);
    this.props.setSelectedRequirement(null);
    this.props.setSelectedProduct(null);
    this.props.fetchEvaluationMetaData(this.props.match.params.evaluation_id);
    this.fetchEvaluationProgressForProducts();
    // if (this.props.isWorkFlow) {
    //   this.checkWorkflowConfiguration();
    // }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchProgress === false && prevProps.fetchProgress === true) {
      if (this.props.isWorkFlow) {
        this.setState({
          fetchFeatureRecommendationDone: true
        }, () => {
          this.checkWorkflowConfiguration();

          // this.checkFeatureRecommendationConfig()

        })
        // this.props.fetchEvaluationSelectedFeatures(this.props.match.params.evaluation_id);
      } else {
        this.setState({
          showLoader: false
        }, () => {
          this.initFunctions();
        })
      }
    }

    if (this.props.fetchLastEvaluationUserResponseProgress === false && prevProps.fetchLastEvaluationUserResponseProgress === true) {
      if (this.props.lastEvaUserReponses !== null && this.props.lastEvaUserReponses.RequirementID !== undefined) {
        let requirementId = this.props.lastEvaUserReponses.RequirementID;
        let queryReqId = URL.getQueryVariable("requirementId");
        if (queryReqId) {
          requirementId = queryReqId;
        }
        this.props.setSelectedRequirement(requirementId);
        this.props.setSelectedProduct(this.props.lastEvaUserReponses.ProductID);
        this.props.fetchPreviousInteraction(parseInt(this.props.match.params.evaluation_id), this.props.lastEvaUserReponses.ProductID)
        // this.props.fetchRequirementTree(this.props.match.params.evaluation_id, this.props.lastEvaUserReponses.ProductID, this.props.isRequirementForAdmin);
        this.props.fetchRequirementResponse(this.props.match.params.evaluation_id, requirementId);
        this.props.fetchRequirementUserResponse(this.props.match.params.evaluation_id, this.props.lastEvaUserReponses.ProductID, requirementId);
      } else {
        if (this.props.selectedProductId !== null) {
          // this.props.fetchRequirementTree(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.isRequirementForAdmin);
        } else {
          var products = this.props.evaluation !== undefined ? this.props.evaluation?.Products || [] : [];
          if (products.length) {
            this.props.setSelectedProduct(products[0].ID);
            this.props.fetchPreviousInteraction(parseInt(this.props.match.params.evaluation_id), products[0].ID)
            // this.props.fetchRequirementTree(this.props.match.params.evaluation_id, products[0].ID, this.props.isRequirementForAdmin);
          }
        }
      }
      this.props.fetchCriteria(this.props.match.params.evaluation_id, true, this.state.componentId)
    }

    if (this.props.fetchCriteriaProgress === false && prevProps.fetchCriteriaProgress === true) {
      this.props.fetchEvaluationInfo(this.props.match.params.evaluation_id);
      this.checkRequirementRules()
      const isAdmin = this.props.userRole === "OrgAdmin" || this.props.userRole === "OrgManager" || this.props.user.ID === this.props.evaluation.UserID;
      if (isAdmin) {
        // console.log(this.props.criterias, 'criterias5555')
        let userAssigned = this.checkUserAssignment();
        // console.log(userAssigned, 'userAssigned')
        if (userAssigned) {
          this.props.setForAdminFlag(false);
          this.props.fetchRequirementTree(this.props.match.params.evaluation_id, this.props.selectedProductId, false);
        } else {
          this.props.fetchRequirementTree(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.isRequirementForAdmin);
        }
      } else {
        this.props.fetchRequirementTree(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.isRequirementForAdmin);
      }
      this.props.fetchRequirementUserResponseStatus(this.props.match.params.evaluation_id);

      this.setState({
        isCriteriaLoadDone: true
      }, () => {
        this.checkOnLoadConditions()
      })
    }

    if (this.props.evaluation?.Products?.length !== prevProps.evaluation?.Products?.length) {
      this.props.emptyProductsList();
      let products = this.props.evaluation?.Products;
      if (products) {
        for (let i = 0; i < products?.length; i++) {
          this.props.fetchProductsList(products[i].ID);
        }
      }
    }

    if (this.props.addResponseProgress === false && prevProps.addResponseProgress === true) {
      this.fetchEvaluationProgressForProducts();
    }

    if (this.props.updateProgress === false && prevProps.updateProgress === true) {
      if (this.props.updateError == null) {
        this.setReview()
        //Migrate to different page
        if (this.props.evaluation.Status === 4) {
          this.props.history.push("/app/evaluate/" + this.props.evaluation.ID);
        }
        this.props.showSnackBar("Updated Successfully.", "success", 3000)
      } else {
        this.props.showSnackBar("Failed to update evaluation status.", "error", 3000)
      }
    }

    if (this.props.addFeedbackProgress === false && prevProps.addFeedbackProgress === true) {
      if (this.props.addFeedbackError === null) {
        if (this.props.user.ID === this.props.evaluation.UserID) {
          this.props.updateEvaluationMetaData(this.props.match.params.evaluation_id, {
            Status: 4
          });
        } else if (this.props.evaluation?.ID) {
          this.props.hideFeedbackTakeDialog();
          this.props.history.push("/app/evaluate/" + this.props.evaluation.ID);
        }
      }
    }

    if (this.props.selectedProductId !== prevProps.selectedProductId) {
      var product = (this.props.evaluation !== undefined && this.props.evaluation !== null) ? this.props.evaluation.Products.filter(o => o.ID === this.props.selectedProductId)[0] : {};
      if (product !== undefined && product !== null) {
        this.props.fetchEvaluationMeta(product?.VendorUUID, this.props.user.Organization.ID);
      } else {
        this.props.fetchEvaluationMeta('', this.props.user.Organization.ID);
      }
    }

    if (this.props.fetchEvaluationProductsScoreProgress === false && prevProps.fetchEvaluationProductsScoreProgress === false) {

    }

    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.props.isWorkFlow) {
        this.checkWorkflowConfiguration();
      }
    }

    if (this.props.evaluationSelectedFeaturesProgress === false && prevProps.evaluationSelectedFeaturesProgress === true) {
      this.setState({
        fetchFeatureRecommendationDone: true
      }, () => {
        this.checkFeatureRecommendationConfig()
      })
    }

    if (this.props.isShowEvaluationGuideFormDialog === false && prevProps.isShowEvaluationGuideFormDialog === true) {
      this.setState({
        showLoader: false
      }, () => {
        this.initFunctions();
      })
    }

    if (this.props.sellerEvaluationMetaFetchProgress === false && prevProps.sellerEvaluationMetaFetchProgress === true) {
      this.props.fetchRequirementTree(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.isRequirementForAdmin);
    }

    if (this.props.sendVendorQuestionsProgress === false && prevProps.sendVendorQuestionsProgress) {
      if (this.props.sendVendorQuestionsError === null) {
        window.location.reload()
      }
    }

  }


  checkRequirementRules() {
    if (this.props.criterias && this.state.configurationCheckDone) {
      if (!this.props.isHideAskSeller) {
        var isRuleUsed = false;
        if (this.props.criterias !== null && this.props.criterias !== undefined) {
          this.props.criterias.forEach(function (item) {
            if (item?.rules.length > 0) {
              isRuleUsed = true;
            }
          })
        }
        this.setState({
          isShowMyReqSwitch: isRuleUsed ? false : true
        })
      }
    }
  }

  checkUserAssignment() {
    var isAssigned = false;
    let me = this;
    if (this.props.criterias !== undefined && this.props.criterias !== null) {
      this.props.criterias.forEach(function (criteria) {
        if (isAssigned) {
          return;
        }
        if (criteria.colloborator !== null && criteria.colloborator.length > 0) {
          criteria.colloborator.forEach(function (col) {
            if (col.ID === me.props.user.ID) {
              isAssigned = true
            }
          })
        }

        if (criteria.requirements !== undefined && criteria.requirements !== null && criteria.requirements.length > 0) {
          criteria.requirements.forEach(function (requirement) {
            if (isAssigned) {
              return;
            }
            if (requirement.colloborator !== null && requirement.colloborator.length > 0) {
              requirement.colloborator.forEach(function (col) {
                if (col.ID === me.props.user.ID) {
                  isAssigned = true
                }
              })
            }
          })
        }
      })
    }

    return isAssigned;
  }

  initFunctions() {
    this.checkForEvaluationCreator();
    this.props.fetchUserRequirementResponse(this.props.match.params.evaluation_id);
    this.props.fetchScores(this.props.match.params.evaluation_id, [this.props.user.ID]);
    this.props.fetchLastEvaluationUserResponse(this.props.match.params.evaluation_id, 0);
    let products = this.props.evaluation?.Products;
    if (products !== undefined) {
      this.props.fetchProductRequirementDetails(
        products.map((i) => {
          return i.ID
        }),
        parseInt(this.props.match.params.evaluation_id),
        window.location.pathname
      )
    }
    this.checkOrgUserConditions();
  }

  checkWorkflowConfiguration() {
    const { workflowComponentsForms, workflowComponentDetail } = this.props;
    // console.log(this.props.workflowComponentsForms, 'workflowComponentsForms');
    // console.log(this.props.workflowComponentDetail, 'workflowComponentDetail');
    if (workflowComponentsForms === null || workflowComponentDetail === null) {
      return;
    }

    var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      this.setState({
        showLoader: false,
        isHideAskSeller: true,
      }, () => {
        this.initFunctions();
        this.checkRequirementRules()
      })
    } else {
      var askSellerFormId = workflowComponentsForms.filter(o => o.Name === 'ask_seller').map(o => o.ID)[0];
      var askSellerValue = configurations.filter(o => o.FormID === askSellerFormId).map(o => o.Value)[0];
      askSellerValue = askSellerValue === 'true' ? true : false;
      if (askSellerValue === false) {
        this.setState({
          isHideAskSeller: true,
        })
      }
      this.setState({
        askSellerValue: askSellerValue
      })
      //  else if (askSellerValue === true && evaluation && !evaluation?.AskSellerPopup) {
      //   this.setState({
      //     isShowAskSellerUpdateDialog: true
      //   })
      // }

      var communityResponseId = workflowComponentsForms.filter(o => o.Name === 'community_response').map(o => o.ID)[0];
      var communityResponseValue = configurations.filter(o => o.FormID === communityResponseId).map(o => o.Value)[0];
      communityResponseValue = communityResponseValue === 'true' ? true : false;
      // console.log(communityResponseId, 'communityResponseId')
      // console.log(communityResponseValue, 'communityResponseValue')

      if (communityResponseValue === false) {
        this.setState({
          isHideCommunityResponse: true
        })
      }

      var featureRecommendationFormId = workflowComponentsForms.filter(o => o.Name === 'feature_recommendation').map(o => o.ID)[0];
      var frValue = configurations.filter(o => o.FormID === featureRecommendationFormId).map(o => o.Value)[0];
      frValue = frValue === 'true' ? true : false;
      this.setState({
        featureRecommendation: frValue,
      })

      // var scoreMachFormId = workflowComponentsForms.filter(o => o.Name === "scoring_mechanism").map(o => o.ID)[0];
      // var score_mechanism_val = configurations.filter(o => o.FormID === scoreMachFormId).map(o => o.Value)[0];
      // if (score_mechanism_val !== undefined && score_mechanism_val !== null && score_mechanism_val !== '') {
      //   if (Validator.isJson(score_mechanism_val)) {
      //     this.setState({
      //       scoring_mechanism: JSON.parse(score_mechanism_val)
      //     })
      //   }
      // }

      var enableScoringFormId = workflowComponentsForms.filter(o => o.Name === "enable_scoring").map(o => o.ID)[0];
      var enableScoringValue = configurations.filter(o => o.FormID === enableScoringFormId).map(o => o.Value)[0];
      if (enableScoringValue !== undefined && enableScoringValue !== null && enableScoringValue !== '') {
        enableScoringValue = enableScoringValue === 'true' ? true : false;
        this.setState({
          enableScoring: enableScoringValue
        })
      }

      var mandatoryFormId = workflowComponentsForms.filter(o => o.Name === "mandatory_comment").map(o => o.ID)[0];
      var mandatoryValue = configurations.filter(o => o.FormID === mandatoryFormId).map(o => o.Value)[0];
      if (mandatoryValue !== undefined && mandatoryValue !== null && mandatoryValue !== '') {
        mandatoryValue = mandatoryValue === 'true' ? true : false;
        this.setState({
          commentRequired: mandatoryValue
        })
      }

      var yourResponseId = workflowComponentsForms.filter(o => o.Name === "hide_your_response").map(o => o.ID)[0];
      var yourResponseValue = configurations.filter(o => o.FormID === yourResponseId).map(o => o.Value)[0];
      yourResponseValue = yourResponseValue === 'true' ? true : false;
      this.setState({
        isHideYourResponse: yourResponseValue
      })

      var vendorNameId = workflowComponentsForms.filter(o => o.Name === "vendor_name").map(o => o.ID)[0];
      var vendorNameValue = configurations.filter(o => o.FormID === vendorNameId).map(o => o.Value)[0];
      vendorNameValue = vendorNameValue && vendorNameValue !== '' ? JSON.parse(vendorNameValue) : '';

      var vendorEmailId = workflowComponentsForms.filter(o => o.Name === "vendor_email").map(o => o.ID)[0];
      var vendorEmailValue = configurations.filter(o => o.FormID === vendorEmailId).map(o => o.Value)[0];
      vendorEmailValue = vendorEmailValue && vendorEmailValue !== '' ? JSON.parse(vendorEmailValue) : '';

      var autoPopulateVendorId = workflowComponentsForms.filter(o => o.Name === "auto_ask_vendor").map(o => o.ID)[0];
      var autoPopulateVendorValue = configurations.filter(o => o.FormID === autoPopulateVendorId).map(o => o.Value)[0];
      autoPopulateVendorValue = autoPopulateVendorValue === 'true' ? true : false;

      var vendorDeadlineId = workflowComponentsForms.filter(o => o.Name === "ask_seller_Deadline").map(o => o.ID)[0];
      var vendorDeadlineValue = configurations.filter(o => o.FormID === vendorDeadlineId).map(o => o.Value)[0];
      vendorDeadlineValue = vendorDeadlineValue && vendorDeadlineValue !== '' ? JSON.parse(vendorDeadlineValue) : '';

      // console.log(vendorDeadlineValue, 'vendorDeadlineValue');
      // console.log(vendorNameValue, 'vendorNameValue');
      // console.log(vendorEmailValue, 'vendorEmailValue');
      // console.log(autoPopulateVendorValue, 'autoPopulateVendorValue');


      this.setState({
        vendorNameField: vendorNameValue,
        vendorEmailField: vendorEmailValue,
        vendorDeadline: vendorDeadlineValue,
        autoPopulateVendor: autoPopulateVendorValue,
        configurationCheckDone: true,
        callOnce: true
      }, () => {
        this.checkOnLoadConditions()
        this.checkFeatureRecommendationConfig()
        this.checkRequirementRules()
      })
    }
  }

  checkOnLoadConditions() {
    const { isCriteriaLoadDone, configurationCheckDone, autoPopulateVendor, askSellerValue, vendorNameField, vendorEmailField, vendorDeadline } = this.state;
    this.setState({
      showLoader: true
    })
    if (isCriteriaLoadDone && configurationCheckDone) {
      const { evaluation } = this.props;
      // console.group('---------Check-------')
      // console.log(vendorEmailField, 'vendorEmailField')
      // console.log(vendorNameField, 'vendorNameField')
      // console.log(autoPopulateVendor, 'autoPopulateVendor')
      // console.log(askSellerValue, 'askSellerValue')
      // console.log(evaluation, 'evaluation')
      // console.groupEnd()
      if (evaluation && evaluation.Products) {
        let vendors = evaluation.Products.filter(o => o.Type === 'CUSTOM_VENDOR');
        let vendorsV2 = evaluation.Products.filter(o => o.Type === 'CUSTOM_VENDOR_V2' && o.VendorEmail && o.VendorEmail !== '');
        if (autoPopulateVendor === true && vendorNameField !== '' && vendorEmailField !== '' && vendors.length > 0 && evaluation && !evaluation?.AskSellerPopup) {
          this.setState({
            showLoader: true,
            isSendingReqToVendors: true
          }, () => {
            this.props.vendorSendAllQuestions(this.props.match.params.evaluation_id, {
              email_item: vendorEmailField?.value,
              name_item: vendorNameField?.value,
              dead_line: vendorDeadline && vendorDeadline !== '' ? vendorDeadline : ''
            })
          })
        } else if (autoPopulateVendor === true && vendorsV2.length > 0 && evaluation && !evaluation?.AskSellerPopup) {
          this.setState({
            showLoader: true,
            isSendingReqToVendors: true
          }, () => {
            this.props.vendorSendAllQuestions(this.props.match.params.evaluation_id, {
              email_item: vendorsV2[0].VendorEmail,
              name_item: vendorsV2[0].VendorName,
              dead_line: vendorDeadline && vendorDeadline !== '' ? vendorDeadline : ''
            })
          })
        } else if (askSellerValue === true && evaluation && !evaluation?.AskSellerPopup) {
          this.setState({
            isShowAskSellerUpdateDialog: true
          })
        } else {
          this.setState({
            showLoader: false
          })
        }
      }
    }

  }

  checkFeatureRecommendationConfig() {
    if (this.state.configurationCheckDone === true && this.state.fetchFeatureRecommendationDone === true && this.state.callOnce) {
      if (this.state.featureRecommendation && this.props.evaluation.SubcategoryID === null) {
        this.props.showWorkflowEvaluationGuideFormDialog();
        this.setState({
          callOnce: false
        })
      } else {
        this.props.hideWorkflowEvaluationGuideFormDialog();
        this.setState({
          callOnce: false,
          // showLoader: false
        }, () => {
          this.initFunctions();
        })
      }
    }
  }

  checkForEvaluationCreator() {
    if (this.props.user.ID === this.props.evaluation?.UserID) {
      this.props.setForAdminFlag(true);
    }
  }

  fetchEvaluationProgressForProducts() {
    this.props.fetchEvaluationProductsScore(this.props.match.params.evaluation_id, {
      "with_stats": true,
      "user_id": this.props.user.ID
    })
    this.props.fetchRequirementUserResponseStatus(this.props.match.params.evaluation_id);
    this.props.fetchRequirementOrgProgressStatus(this.props.match.params.evaluation_id);
  }

  setReview = () => {
    try {
      if (this.props.evaluation?.Status === 4 && this.props.evaluation?.Type === 2 && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), 'days') > 30) {
        document.body.classList.add('evaluate-view');
        this.props.toggleEvaluateView(true, this.props.evaluation?.Products[0]?.ID);
      } else if ((((this.props.evaluation?.Status <= 3 || this.props.evaluation?.Status === 4) && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), 'days') <= 30) && this.props.evaluation?.Type === 2)) {
        this.props.toggleEvaluateNeverReviewed(true, this.props.evaluation?.Products[0]?.ID);
      }
    } catch (e) { }
  }

  checkOrgUserConditions() {
    const { evaluation } = this.props;
    // console.log(evaluation, 'Evaluation000001');
    if (evaluation !== undefined && evaluation !== null && this.props.userRole !== "OrgAdmin" && this.props.userRole !== "OrgManager" && this.props.user.ID !== evaluation?.UserID) {
      this.setState({
        isHideTopBar: true
      })
    }
  }

  render() {
    const classes = this.props.classes;

    if (this.props.evaluation === null) {
      return <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    }

    if (this.state.isSendingReqToVendors) {
      return <SendLoader />
    }

    if (this.props.isOpenTaskDialog) {
      // console.log(this.props.isOpenTaskDialog,'Opennnnnnnnnnnnnnnnnnnnnnnnnnnn')
    }

    // console.log(this.props.evaluation,'Evaaaaaaaaaaa')
    var maxScore = this.props.evaluationInfoData !== undefined && this.props.evaluationInfoData !== null && this.props.evaluationInfoData?.MaxScore && this.props.evaluationInfoData?.MaxScore !== null && this.props.evaluationInfoData?.MaxScore !== '' ? this.props.evaluationInfoData.MaxScore : 5;

    return <div>
      <EvaluationBar
        isShowCollabrations={true}
        isShowEvalDetails={false}
        isWorkFlow={this.props.isWorkFlow}
        isShowViewBtns={true}
      />
      {this.state.showLoader === false && <Grid container className={classnames(classes.contentContainer)}>
        <Grid item xs={3} className={classnames(classes.reqContainer)}>
          <Requirements
            isShowMyReqSwitch={this.state.isShowMyReqSwitch}
          />
        </Grid>
        <Grid item xs={6} className={classnames(classes.mainContainer)}>
          <Response
            scoring_mechanism={this.state.scoring_mechanism}
            isHideAskSeller={this.state.isHideAskSeller}
            isHideCommunityResponse={this.state.isHideCommunityResponse}
            isHideYourResponse={this.state.isHideYourResponse}
            enableScoring={this.state.enableScoring}
            commentRequired={this.state.commentRequired}
            maxScore={maxScore}
          />
        </Grid>
        <Grid item xs={3} className={classnames(classes.currentProContainer)}>
          <CurrentProduct
            isWorkFlow={this.props.isWorkFlow}
            workflowComponentId={this.props.workflowComponentId}
            isHideAskSeller={this.state.isHideAskSeller}
            isHideCommunityResponse={this.state.isHideCommunityResponse}
            isHideYourResponse={this.state.isHideYourResponse}
            enableScoring={this.state.enableScoring}
            commentRequired={this.state.commentRequired}
          />
        </Grid>
      </Grid>}

      <BottomActions
        isWorkFlow={this.props.isWorkFlow}
        isCreatorOrAdmin={this.props.isCreatorOrAdmin}
        workflowComponentId={this.props.workflowComponentId}
        user={this.props.user}
        adminForward={this.props.adminForward}
      />

      {this.props.showFinalizeRequirementDialog && <FinalizeRequiremntViewDialog
        isWorkFlow={this.props.isWorkFlow}
        workflowComponentId={this.props.workflowComponentId}
        isRequirementForAdmin={this.props.isRequirementForAdmin}
      />}

      {this.props.showOverallRequirementDialog && <OverallRequiremntViewDialog />}

      <FeedbackDialog />

      {this.props.collaboratorDialogOpen &&
        <SearchUserPoper {...this.props.collaboratorDialogData} />}

      {this.props.isShowEvaluationGuideFormDialog && <>
        <EvaluationGuideForm />
      </>}

      {this.props.isOpenTaskDialog && <AddUpdateTaskDialog />}

      {this.state.isShowAskSellerUpdateDialog && <AskSellerDetailsUpdateDialog
        vendorNameField={this.state.vendorNameField}
        vendorEmailField={this.state.vendorEmailField}
        vendorDeadline={this.state.vendorDeadline}
        isOpen={this.state.isShowAskSellerUpdateDialog}
        hideDialog={() => {
          this.setState({
            isShowAskSellerUpdateDialog: false
          })
        }}
      />}

    </div>
  }
}

InProgress.defaultProps = {
  isWorkFlow: false,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(InProgress));