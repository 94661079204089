import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Button, Typography } from "@material-ui/core";
import ReactSelect from "react-select";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import { addReqToSellerLibrary } from "redux/seller/evaluate/action";

const connectedProps = (state) => ({
  sellerProducts: state.sellerProduct.sellerProducts,
  loading: state.sellerEvaluate.addReqToSellerLibraryProgress,
});

const connectionActions = {
  addReqToSellerLibrary: addReqToSellerLibrary,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    requirementContainer: {
      padding: "30px 16px 10px 25px",
      width: "100%",
    },
    bottomContainer: {
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      borderRadius: 8,
      backgroundColor: "#FAFAFA",
      justifyContent: "right",
    },
    actionItem: {
      textAlign: "center",
      cursor: "pointer",
      marginRight: 4,
    },
    Btn: {
      marginLeft: 10,
    },
    filterTitle: {
      color: "#6C6C6C",
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    reqTypes: {
      marginBottom: 20,
    },
  });

class ReqQuestionUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      description_error: "",
      isLoading: true,
      productOptions: [
        {
          label: "Global",
          value: "global",
        },
      ],
      product: null,
    };
  }

  componentDidMount() {
    console.log(this.props.requirement);
    const { requirement, product } = this.props;
    let newOptions = [...this.state.productOptions];

    if (this.props.sellerProducts && this.props.sellerProducts.Data && this.props.sellerProducts.Data.length > 0) {
      this.props.sellerProducts?.Data.forEach((o) => {
        newOptions.push({
          label: o.Name,
          value: o.ID,
        });
      });
    }
    let selectedProduct = null;
    if (product && product.product_id) {
      selectedProduct = { label: product.product_name, value: product.product_id };
    } else if (product.is_global === 1) {
      selectedProduct = {
        label: "Global",
        value: "global",
      };
    }

    this.setState({
      description: requirement.description || "",
      isLoading: false,
      id: requirement.id,
      productOptions: newOptions,
      product: selectedProduct,
    });
  }

  componentDidUpdate(prevProps) {}

  clearError = () => {
    this.setState({
      description_error: "",
      answer_error: "",
    });
  };

  updateRequirement() {
    const { product, description } = this.state;

    let params = {
      entity_type: "question",
      question_id: this.props.requirement?.id,
      answer_id: null,
      question: {
        is_global: product?.value === "global" ? 1 : 0,
        product_id: product?.value === "global" ? null : product?.value,
        question_title: this.props.requirement?.name,
        question_text: description,
      },
    };

    console.log(params, "params");

    this.props.addReqToSellerLibrary({
      source: { id: null, name: "library" },
      action: "update",
      data: [params],
    });
  }

  render() {
    const classes = this.props.classes;
    const { product, productOptions, description, description_error, isLoading } = this.state;
    console.log(this.state, "Stateeeeeeeeeeeeeeeeeeee");

    if (isLoading) {
      return null;
    }

    return (
      <>
        <div className={classes.requirementContainer}>
          <div className={classes.reqTypes}>
            <Typography variant={"h4"} className={classes.filterTitle}>
              Select Product
            </Typography>
            <ReactSelect
              isClearable
              isSearchable={true}
              options={productOptions}
              classNamePrefix="select"
              placeholder="Search Product"
              onChange={(e) => {
                this.setState({
                  product: e,
                });
              }}
              value={product}
            />
          </div>

          <div className={classes.nameYour}>
            <OutlinedInput
              label="Question"
              size="small"
              error={description_error.length > 0}
              helperText={description_error}
              value={description}
              onChange={(event) => {
                this.setState({
                  description: event.target.value,
                });
              }}
            />
          </div>
        </div>
        <Grid container className={classes.bottomContainer}>
          <div className={classes.actionItem}>
            <Button
              className={classes.Btn}
              variant="outlined"
              color="secondary"
              onClick={() => {
                this.props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={this.props.loading}
              className={classes.Btn}
              variant="contained"
              color="secondary"
              onClick={() => {
                this.updateRequirement();
              }}
            >
              Update
            </Button>
          </div>
        </Grid>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ReqQuestionUpdate));
