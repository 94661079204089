import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import * as Validator from "util/Validation";
import { Dialog, DialogContent, Slide, DialogTitle, Typography, IconButton, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import ReactSelect from 'react-select'
import { withRouter } from 'react-router-dom';
import { hideApprovalCriteriaRuleDialog } from "redux/common/action";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import Alert from '@material-ui/lab/Alert';
import deleteIcon from "assets/images/delete.svg";
import Image from 'Components/Common/image.jsx';
import { saveEvaluationRule } from "redux/evaluation/rule/action";
import { saveTemplateRule } from "redux/templates/evaluation/org/action";

// dialogs
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  // isOpen:state.common.isApprovalCriteriaRuleDialogOpen,
  // ruleDialog: state.common.approvalRuleDialog,
  ruleSaveProgress: state.evaluationRule.ruleSaveProgress,
  errorRuleSave: state.evaluationRule.errorRuleSave,
  templateRuleSaveProgress: state.orgTemplateEvaluation.ruleSaveProgress,
  templateErrorRuleSave: state.orgTemplateEvaluation.errorRuleSave,
});

const connectionActions = {
  hideDialog: hideApprovalCriteriaRuleDialog,
  saveEvaluationRule: saveEvaluationRule,
  saveTemplateRule: saveTemplateRule
}

var connector = connect(connectedProps, connectionActions);

const responseOption = [
  { label: 'Yes', value: 10 },
  { label: 'Partial', value: 5 },
  { label: 'No', value: 1 },
]

const styles = (theme) => createStyles({
  dialogContent: {
    padding: theme.spacing(2, 2) + " !important",
    //minHeight:"300px"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ruleTable: {
    width: "100%",
    border: '1px solid #ddd',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10
  },
  ruleTableDisabled: {
    width: "100%",
    border: '1px solid #ddd',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
    pointerEvents: 'none',
  },
  deleteIcon: {
    color: "red"
  },
  op2: {
    width: "10%"
  },
  op1: {
    width: "10%"
  },
  leftOp: {
    width: "35%"
  },
  rightOp: {
    width: "35%",
    "& [class*=select__menu]": {
      maxHeight: 100,
    },
  },
  action: {
    width: "10%",
    paddingLeft: 10
  },
  alerts: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  conditions: {
    fontSize: 16,
    fontWeight: 500
  },
  ruleTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 0
  },
  addAnotherRule: {
    textAlign: 'center',
    color: '#4175DF',
    cursor: 'pointer',
    paddingBottom: 50
  },
  ruleSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ruleDelete: {
    marginBottom: 16,
    cursor: 'pointer'
  },
  saveBtn: {
    margin: "10px 15px"
  },
  disableRules: {
    pointerEvents: 'none',
  }
});

class ApprovalCriteriaRuleDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      actionOption: [{ value: 1, label: 'Go to Approval Step' }, { value: 2, label: `Don't move forward` }],
      operand: [
        {
          value: 1,
          label: "=="
        },
        {
          value: 2,
          label: "in"
        }
      ],
      rules: [
        {
          ruleElement: [
            {
              "op2": 3,
              "leftOp": null,
              "op1": 1,
              "rightOP": []
            }
          ],
          actionElement: {
            type: '',
            data: ''
          }
        }
      ]
    }
  }

  componentDidMount() {
    if (this.props.isOpen) {
      var getRules = [];
      if (this.props.ruleDialog && this.props.ruleDialog?.rules?.length) {
        // console.log(this.props.ruleDialog?.rules, 'Rulessssssssssssssss')
        this.props.ruleDialog.rules.forEach((value) => {
          var elements = [];

          value.conditions.forEach((element) => {
            if (element.opId === 3 || element.opId === 4) {
              elements[elements.length - 1].op2 = element.opId;
            } else {
              elements.push({ leftOp: this.getLeftOp(element.leftExp), op1: element.opId, op2: '', rightOP: this.getRightValue(this.getLeftOp(element.leftExp), element.rightExp, element.opId) })
            }
          });

          getRules.push({
            ruleElement: elements, actionElement: {
              type: this.getActionOptions(value.action.type) || '',
              data: this.getActionType(value.action.data, value.action.type) || ''
            }
          })
        });
        // console.log(getRules,'getRules')

        this.setState({
          error: "",
          success: "",
          rules: getRules
        });
      } else {
        this.reset();
      }
    }
  }

  getActionOptions = (option) => {
    if (Validator.isJson(option)) {
      return this.state.actionOption.find(element => option && element.value === JSON.parse(option));
    } else {
      return '';
    }
  }

  reset = () => {
    this.setState({
      error: "",
      success: "",
      answerType: null,
      rules: [
        {
          ruleElement: [
            {
              "op2": 3,
              "leftOp": null,
              "op1": 1,
              "rightOP": []
            }
          ],
          actionElement: {
            type: '',
            data: ''
          }
        }
      ]
    });
  }

  getLeftOp = (leftOp) => {
    if (this.props.ruleDialog?.requirements?.length > 0) {
      let leftOpValue = '';
      this.props.ruleDialog?.requirements?.map((item) => {
        if (("" + item.id) === leftOp) {
          leftOpValue = {
            answer_type: 1,
            value: "" + item.id,
            label: item.name,
            answerOptions: [{
              ID: 1,
              Title: 'Preliminary Approval'
            }, {
              ID: 2,
              Title: 'Approved'
            }, {
              ID: 3,
              Title: 'Approved with exceptions'
            }, {
              ID: 4,
              Title: 'Rejected'
            }]
          }
        }
      });
      return leftOpValue
    }
    return '';
  }

  getActionType = (actionData, actionType) => {
    if (actionType === 1) {
      return this.getSelectedValue(this.props.ruleDialog?.criterias, actionData);
    } else if (actionType === 2) {
      return this.getSelectedValue(this.props.ruleDialog?.requirements, actionData);
    } else if (actionType === 3) {
      const selectedOption = responseOption.filter(option => option.value === parseInt(actionData))
      if (selectedOption[0]) {
        return selectedOption[0]
      }
    }
    return ''
  }

  getSelectedValue = (arrayOption, actionData) => {
    let selectedObj = '';
    if (arrayOption?.length && Validator.isJson(actionData)) {
      arrayOption.map(element => {
        if (actionData && (element.id === JSON.parse(actionData))) {
          selectedObj = {
            value: element.id,
            label: element.name
          }
        }
      });
      return selectedObj;
    }
    return selectedObj;
  }

  getRightValue = (arrayOption, option, OpID) => {
    let selectedObj = [];
    if (arrayOption?.answerOptions?.length) {
      arrayOption.answerOptions.map(element => {
        if (OpID === 2) {
          if (option && Validator.isJson(option) && JSON.parse(option).indexOf(element.ID) > -1) {
            selectedObj.push({
              value: element.ID,
              label: element.Title
            })
          }
        } else if (OpID === 1) {
          var optionVal = option;
          try {
            if (option) {
              optionVal = JSON.parse(option);
            }
          } catch {

          }
          if (arrayOption.answer_type === 4) {
            if (optionVal === element.Description) {
              selectedObj = {
                value: element.Description,
                label: element.Title
              };
            }
          } else {
            if (optionVal === element.ID) {
              selectedObj = {
                value: element.ID,
                label: element.Title
              };
            }
          }
        }
      });
      return selectedObj?.length === 0 ? '' : selectedObj;
    }
    return '';
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && prevProps.isOpen === false) {
      var getRules = [];

      if (this.props.ruleDialog && this.props.ruleDialog?.rules?.length) {
        // console.log(this.props.ruleDialog?.rules, 'Rulessssssssssssssss')
        this.props.ruleDialog.rules.map((value) => {
          var elements = [];

          value.Elements.map((element) => {
            if (element.OpID === 3 || element.OpID === 4) {
              elements[elements.length - 1].op2 = element.OpID;
            } else {
              elements.push({ leftOp: this.getLeftOp(element.LeftExpression), op1: element.OpID, op2: '', rightOP: this.getRightValue(this.getLeftOp(element.LeftExpression), element.RightExpression, element.OpID) })
            }
          });

          getRules.push({
            ruleElement: elements, actionElement: {
              type: this.getActionOptions(value.ActionType) || '',
              data: this.getActionType(value.ActionData, value.ActionType) || ''
            }
          })
        });
        this.setState({
          error: "",
          success: "",
          rules: getRules
        });
      } else {
        this.reset();
      }

    }

    if ((this.props.ruleSaveProgress === false && prevProps.ruleSaveProgress === true) || (this.props.templateRuleSaveProgress === false && prevProps.templateRuleSaveProgress === true)) {
      if (this.props.errorRuleSave === null && this.props.templateErrorRuleSave === null) {
        this.setState({
          "success": "Rule updated successfully.",
        });
        setTimeout(() => {
          this.props.onClose()
        }, 2000);
      } else if (this.props.errorRuleSave !== null || this.props.templateErrorRuleSave !== null) {
        this.setState({
          "error": this.props.errorRuleSave
        });
      }
    }
  }

  addNewRuleElement = (ruleIndex) => {
    var rules = this.state.rules;
    var ruleElement = rules[ruleIndex].ruleElement;
    ruleElement.push({
      "op2": 3,
      "leftOp": null,
      "op1": 1,
      "rightOP": null
    })
    rules[ruleIndex].ruleElement = ruleElement;
    this.setState({
      rules: rules
    })
  }

  deleteRuleElement = (ruleIndex, elementIndex) => {
    var rules = this.state.rules;
    rules[ruleIndex].ruleElement.splice(elementIndex, 1);
    this.setState({
      rules: rules
    })
  }

  saveRule = () => {
    var seq = 1;
    let rowNumber = 0;
    this.setState({
      "error": "",
      "success": ""
    })
    var errorMessage = "";

    var rules = [];
    this.state.rules.forEach((rule) => {
      rowNumber++;
      var ruleElement = [];

      // console.log(rule,'Ruleeeee')
      if (!rule.actionElement.type) {
        errorMessage = `Please select action type`
        return;
      }

      if (!rule.actionElement.data && rule.actionElement.type === 1) {
        errorMessage = `Please select section or question`
        return;
      }

      rule.ruleElement.forEach((ruleElem, k) => {
        if (errorMessage.length > 0) {
          return;
        }
        if (!ruleElem.leftOp) {
          errorMessage = `Error Row ${rowNumber} please select question.`
          return;
        }

        if (!ruleElem.rightOP || ruleElem.rightOP?.length === 0) {
          errorMessage = `Error Row ${rowNumber} please select option.`
          return;
        }
        // console.log(ruleElem, 'ruleElem')
        ruleElement.push({
          leftExp: ruleElem.leftOp.value,
          opId: ruleElem.op1,
          rightExp: ruleElem.op1 === 1 ? String(ruleElem.rightOP.value) : JSON.stringify(ruleElem.rightOP.map((i) => {
            return i.value
          })),
          seq: seq,
          from_template: true
        })
        seq++;

        if (k !== 0) {
          ruleElement.push({
            leftExp: "E=" + (seq - 2),
            opId: ruleElem.op2,
            rightExp: "E=" + (seq - 1),
            seq: seq,
            from_template: true,
          })
          seq++;
        }
      })
      rules.push({ conditions: ruleElement, action: { type: rule.actionElement.type.value, from_template: true, data: rule.actionElement.data !== '' ? (rule.actionElement.data.value).toString() : '' } })
    })
    if (!errorMessage) {

      if (this.props.ruleDialog?.isApproval) {
        this.props.onSave(rules)
      }
    } else {
      this.setState({ error: errorMessage })
    }
  }

  addAnotherRole = () => {
    const rules = this.state.rules;
    var rule = {
      ruleElement: [
        {
          "op2": 0,
          "leftOp": null,
          "op1": 1,
          "rightOP": []
        }
      ],
      actionElement: {
        type: '',
        data: ''
      }
    };
    rules.push(rule);
    this.setState({
      rules: rules
    })
  }

  deleteRule = (ruleIndex) => {
    const rules = this.state.rules;
    rules.splice(ruleIndex, 1);
    this.setState({
      rules: rules
    })
  }

  render() {
    const classes = this.props.classes;
    var criteriaOptions = [];
    const { approvalStages, ruleDialog } = this.props;
    const selectedCriteria = ruleDialog.criteria;

    // console.log(selectedCriteria,'selectedCriteria')
    // console.log(approvalStages,'approvalStages')
    let answerOps = [];
    approvalStages.forEach(function (as) {
      if (as.isDefault === true || as.isChecked === true) {
        answerOps.push({
          ID: as.id,
          Title: as.name
        })
      }
    })

    if (this.props.ruleDialog?.criterias?.length > 0) {
      this.props.ruleDialog?.criterias?.forEach((item, k) => {
        if (item.id !== selectedCriteria.id) {
          return;
        }
        criteriaOptions.push({
          answer_type: 1,
          value: "" + item.id || k,
          label: item?.name,
          answerOptions: answerOps
        })
      })
    }

    let goToCriteriasOption = []
    this.props.ruleDialog?.criterias?.forEach((item) => {
      if (item.id === selectedCriteria.id) {
        return;
      }
      goToCriteriasOption.push({
        value: item?.id,
        label: item?.name,
      })
    })

    return <Dialog
      onClose={this.props.onClose}
      aria-labelledby="app-evRuleDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={false}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evApprovalCriteriaRuleDialog"
    >
      <DialogTitle disableTypography>
        {this.props.dialogContent == null && <>
          <Typography variant={"h6"}> {this.props.ruleDialog?.criteria?.name} Rule</Typography>
        </>}
        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {this.props.rulefetchProgress && <DialogContent dividers={true} classes={{ root: classes.dialogContent }}>
        <CircularProgress />
      </DialogContent>}
      {!this.props.rulefetchProgress && <>
        <DialogContent dividers={true} classes={{ root: classes.dialogContent }}>
          {
            this.state.rules.map((element, ruleIndex) => {

              return (
                <table className={this.props.ruleDialog.canUpdateRule ? classes.ruleTable : classes.ruleTableDisabled} key={ruleIndex} >
                  <tr>
                    <td colSpan="5">
                      <div className={classes.ruleSection}>
                        <p className={classes.ruleTitle}>Rule {ruleIndex + 1}</p>
                        {this.props.ruleDialog.canUpdateRule && <div className={classes.ruleDelete} onClick={() => this.deleteRule(ruleIndex)}><Image alt="Delete" src={deleteIcon} /></div>}
                      </div>
                    </td>
                  </tr>

                  {element.ruleElement?.map((rule, k) => <tr key={k}>
                    <td className={classes.op2}>
                      {k !== 0 && <div>
                        <OutlinedSelectInput
                          value={rule.op2}
                          options={[
                            {
                              value: 3,
                              label: "And"
                            },
                            {
                              value: 4,
                              label: "Or"
                            }

                          ]}
                          noMargin={true}
                          compact={true}
                          onChange={(e) => {
                            var rules = this.state.rules;
                            var ruleElement = rules[ruleIndex].ruleElement[k];
                            ruleElement.op2 = e.target.value;
                            rules[ruleIndex].ruleElement[k] = ruleElement;
                            this.setState({ rules: rules });
                          }}
                        />
                      </div>}
                      {k === 0 && <div className={classes.conditions}>Condition</div>}
                    </td>
                    <td className={classes.leftOp}>
                      <ReactSelect
                        isClearable
                        menuPosition='fixed'
                        isSearchable={true}
                        options={criteriaOptions}
                        classNamePrefix="select"
                        placeholder="Search Criteria"
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var ruleElement = rules[ruleIndex].ruleElement[k];
                          ruleElement.leftOp = e;
                          ruleElement.rightOP = null
                          ruleElement.op1 = 1
                          // if(e.answer_type === 3 || e.answer_type === 4){
                          //   ruleElement.op1 = 1
                          // } else {
                          //   ruleElement.op1 = 2
                          // }
                          rules[ruleIndex].ruleElement[k] = ruleElement;
                          this.setState({ rules: rules });
                        }}
                        menuPortalTarget={document.getElementById("evApprovalCriteriaRuleDialog")}
                        value={rule.leftOp}
                      />
                    </td>
                    <td className={classes.op1}>
                      <OutlinedSelectInput
                        value={rule.op1}
                        options={this.state.operand}
                        noMargin={true}
                        compact={true}
                        onChange={(event) => {
                          // console.log(event, 'Event')
                          var rules = this.state.rules;
                          var ruleElement = rules[ruleIndex].ruleElement[k];
                          ruleElement.op1 = event.target.value;
                          if (event.target.value === 2) {
                            ruleElement.rightOP = [];
                          } else {
                            ruleElement.rightOP = null;
                          }
                          // if(e.answer_type === 3 || e.answer_type === 4){
                          //   ruleElement.op1 = 1
                          // } else {
                          //   ruleElement.op1 = 2
                          // }
                          rules[ruleIndex].ruleElement[k] = ruleElement;
                          this.setState({ rules: rules });
                          return;
                        }}
                      />
                    </td>
                    <td className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        isMulti={rule.op1 === 2}
                        menuPosition='fixed'
                        options={rule?.leftOp?.answerOptions?.map((item, i) => {
                          return {
                            value: item.ID,
                            label: item.Title,
                          }
                        })}
                        classNamePrefix="select"
                        placeholder="Search Option"
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var ruleElement = rules[ruleIndex].ruleElement[k];
                          ruleElement.rightOP = e;
                          rules[ruleIndex].ruleElement[k] = ruleElement;
                          this.setState({ rules: rules });
                        }}
                        menuPortalTarget={document.getElementById("evApprovalCriteriaRuleDialog")}
                        value={rule.rightOP}
                      />
                    </td>
                    <td className={classes.action}>
                      {this.props.ruleDialog.canUpdateRule && <span hidden={element.ruleElement.length === 1}> <IconButton size="small" onClick={() => { this.deleteRuleElement(ruleIndex, k) }}>
                        <Image alt="Delete" src={deleteIcon} />
                      </IconButton> </span>}
                      {this.props.ruleDialog.canUpdateRule && <span hidden={k !== element.ruleElement.length - 1}><IconButton size="small" onClick={() => this.addNewRuleElement(ruleIndex, k)}>
                        <AddCircleIcon />
                      </IconButton>  </span>}
                    </td>
                  </tr>
                  )}
                  <tr>
                    <td><p className={classes.conditions}>Action</p></td>
                    <td className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={this.state.actionOption}
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var actionElement = rules[ruleIndex].actionElement;
                          actionElement['data'] = '';
                          if (e?.value) {
                            actionElement['type'] = e;
                          } else {
                            actionElement['type'] = '';
                          }
                          rules[ruleIndex].actionElement = actionElement;
                          this.setState({ rules: rules });
                        }}
                        classNamePrefix="select"
                        placeholder="Choose Action"
                        value={element?.actionElement?.type}
                      />
                    </td>
                    {element?.actionElement?.type?.value === 1 && <td colSpan="2" className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={goToCriteriasOption}
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var actionElement = rules[ruleIndex].actionElement;
                          if (e?.value) {
                            actionElement['data'] = e;
                          } else {
                            actionElement['data'] = '';
                          }
                          rules[ruleIndex].actionElement = actionElement;
                          this.setState({ rules: rules });
                        }}
                        classNamePrefix="select"
                        placeholder="Search Criteria"
                        value={element?.actionElement?.data}
                      />
                    </td>}
                    {false && element?.actionElement?.type?.value === 2 && <td colSpan="2" className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={this.props.ruleDialog?.criterias?.map((item) => {
                          return {
                            value: item?.id,
                            label: item?.name,
                          }
                        })}
                        onChange={(e) => {
                          // console.log(e)
                          var rules = this.state.rules;
                          var actionElement = rules[ruleIndex].actionElement;
                          if (e?.value) {
                            actionElement['data'] = e;
                          } else {
                            actionElement['data'] = '';
                          }
                          rules[ruleIndex].actionElement = actionElement;
                          this.setState({ rules: rules });
                        }}
                        classNamePrefix="select"
                        placeholder="Search Requirement"
                        value={element?.actionElement?.data}
                      />
                    </td>}
                  </tr>
                </table>
              )
            })
          }
          {this.props.ruleDialog.canUpdateRule && <div className={classes.addAnotherRule} onClick={() => this.addAnotherRole()}>Add another rule</div>}
          <div className={classes.alerts}>
            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
            {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
          </div>
        </DialogContent>
        <DialogActions>
          {!this.props.ruleSaveProgress && <>
            {this.props.ruleDialog.canUpdateRule && <Button variant="contained" color="secondary" className={classes.saveBtn} onClick={this.saveRule}>
              Save
            </Button>}
            <Button variant="outlined" color="secondary" className={classes.saveBtn} onClick={this.props.onClose}>
              Cancel
            </Button>
          </>}
          {this.props.ruleSaveProgress && <CircularProgress />}
        </DialogActions>
      </>}
    </Dialog>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ApprovalCriteriaRuleDialog));