import React from "react";

export const VendorOption = (props) => {
  const product = props;
  return <div >
    <span style={{ 'color': '#000', fontSize: '16px' }}>{product.label}</span>&nbsp;
    {product.isProduct !== undefined && product.isProduct && <span style={{ 'color': '#6C6C6C', fontSize: '12px' }}>By {product.company_name}</span>}
  </div>
}

export const VendorOptionLabel = (props) => {
  const product = props;
  // console.log(product)
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', cursor: props.is_disabled ? 'not-allowed' : 'pointer' }}>
      <div >
        <span style={{ 'color': '#000', fontSize: '16px' }}>{product.label}</span>&nbsp;
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {product.is_existing && (
          <div
            style={{
              color: '#2EB77D',
              padding: '3px 10px',
              fontSize: 11,
              background: '#E6FFF3',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Existing
          </div>
        )}
      </div>
    </div>
  )

}

export const IsExistingVendor = (props) => {
  const product = props;

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', cursor: props.is_disabled ? 'not-allowed' : 'pointer' }}>
      <div >
        <span style={{ 'color': '#000', fontSize: '16px' }}>{product.label}</span>&nbsp;
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {(props.is_existing) && (
          <div
            style={{
              color: '#2EB77D',
              padding: '3px 10px',
              fontSize: 11,
              background: '#E6FFF3',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Existing
          </div>
        )}
      </div>
    </div>
  )

}


export const IsExistingService = (props) => {
  const product = props;

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', cursor: props.is_disabled ? 'not-allowed' : 'pointer' }}>
      <div >
        <span style={{ 'color': '#000', fontSize: '13px' }}>{product.label}</span>&nbsp;
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {(props.is_org) && (
          <div
            style={{
              color: '#2EB77D',
              padding: '3px 10px',
              fontSize: 11,
              background: '#E6FFF3',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Existing
          </div>
        )}
      </div>
    </div>
  )

}

export const ProductSearchFunction = (option, rawInput) => {
  return option.data.__isNew__ || option;
}