import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import ForgotPasswordContainer from "./Common/ForgotPasswordContainer";
import ArrowBack from "../assets/images/back_arrow.svg";
import { withRouter } from 'react-router-dom';
import ForgotpassImage from "../assets/images/forgotconfirm.svg";
import { Typography, Button } from '@material-ui/core';
// import OutlinedInput from "./Common/Input/OutlinedInput";

import Image from 'Components/Common/image.jsx'

const connectedProps = (state) => ({

});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        margin: "0 auto",
    },
    backImage: {
        cursor: "pointer",
        width:theme.spacing(5)
    },
    content: {
        // width: "50%",
        margin: "0 auto",
        textAlign: "center",
        paddingTop: theme.spacing(1),
        '@media (min-width: 992px)': { 
            width: "50%"
        }
    },
    heading: {
        marginTop: theme.spacing(1),
        fontWeight:700,
        color:"#333333",
        fontSize:'1.4em',
        marginBottom: theme.spacing(2),
    },
    sendBtn: {
        padding: theme.spacing(0.9,10),
        fontSize:theme.spacing(2.2),
        fontWeight:500,
        // fontSize: '1.4em',
        '@media (max-width: 991.98px)': { 
            padding: '.4em 1em'
        }
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    captionText :{
        color:"#333333",
        fontSize:"1rem"
    },
    action: {
        marginTop: theme.spacing(4),
    },
    successImage:{
        width: "40%"
    }

});

class ResetPasswordConfirm extends React.Component {

    render() {
        const classes = this.props.classes;
        return <ForgotPasswordContainer>
            <div className={classes.root}>
                <div className={classes.backBtn}>
                    <Image src={ArrowBack} className={classes.backImage} onClick={() => {
                        this.props.history.goBack();
                    }} alt="back" />
                </div>
                <div className={classes.content}>
                    <Image src={ForgotpassImage} alt="forgotpassword_image" className={classes.successImage}/>
                    <Typography variant={"h6"} className={classes.heading}>Awesome!</Typography>

                    <div className={classes.caption}>
                        <Typography className={classes.captionText}>You're all set!</Typography>
                    </div>
                    <div className={classes.action}>
                        <Button variant="contained" color="secondary" className={classes.sendBtn} onClick={() => {
                            this.props.history.push("/");
                        }}>Go to Login</Button>
                    </div>
                </div>
            </div>
        </ForgotPasswordContainer>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ResetPasswordConfirm));