import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
import AddIcon from "@material-ui/icons/Add";

import StepMenu from './StepMenu';
const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  }
});

class AddNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  onConnect = (params) => {
    console.log('handle onConnect', params)


  };

  hideShowMenu(state) {
    this.setState({
      isShowMenu: state
    })
  }

  handleActionTypeClick(type) {
    this.props.data.addNewAction(type)
    this.hideShowMenu(false)
  }

  render() {
    const { isShowMenu } = this.state;
    // console.log(this.props, 'Propssssssssssss')
    return (
      <>
        <div class="recipe-action-steps">
          <div class="">
            <div class="add-step ">
              {!isShowMenu && <button type="button" aria-label="Add new step" class="add-step__trigger" onClick={() => {
                this.hideShowMenu(true)
              }}>
                <AddIcon />
              </button>}

              {isShowMenu && <StepMenu
                onClickHandle={(type) => {
                  this.handleActionTypeClick(type)
                }}
                hideStepMenu={() => {
                  this.hideShowMenu(false)
                }}
              />}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(AddNode));

