import moment from "moment";

const CommonFn = {
  getNameFromRole(role) {
    switch (role) {
      case "OrgAdmin":
        return "Admin";
      case "OrgManager":
        return "Manager";
      case "OrgUser":
        return "User";
      case "OrgExternal":
        return "External";
      default:
    }
  },
  getIdFromRoleName(roleName) {
    switch (roleName) {
      case "Admin":
        return "OrgAdmin";
      case "Org Manager":
        return "OrgManager";
      case "Manager":
        return "OrgManager";
      case "User":
        return "OrgUser";
      case "External":
        return "OrgExternal";
      default:
    }
  },
  getStorage(key) {
    return window.localStorage.getItem(key);
  },
  roleOptions() {
    return [
      {
        id: "OrgAdmin",
        label: "Admin",
      },
      {
        id: "OrgUser",
        label: "User",
      },
    ];
  },
  formateDate(createdAt, isYear = false) {
    let rt = moment(createdAt).format('Do MMMM')
    if (isYear) {
      rt = moment(createdAt).format('Do MMMM YYYY')
    }
    return rt
  },
  formateDateTime(dateTimeStr) {
    return moment(dateTimeStr).format("LLL");
  },
  formatTime(createdAt) {
    let rt = moment(createdAt).format('h:mm:ss a')
    return rt;
  },
  formatResponseDate(createdAt) {
    let rt = moment(createdAt).format('YYYY-MM-DD')
    return rt
  },
  getPreviousDate(duration) {
    var d = new Date();
    return d.setDate(d.getDate() - duration);
  },
  getProductListSize() {
    let width = window.innerWidth;
    if (width > 2000 && width < 2500) {
      return 30;
    } else if (width > 2501 && width < 3000) {
      return 36;
    } else if (width > 3000 && width < 4000) {
      return 42;
    } else if (width > 4000) {
      return 84;
    } else {
      return 24;
    }
  },
  findAnswer(userReponses, rule, requirements) {
    let pushToRequirementArray = true;
    for (let userReponse in userReponses) {
      let requirementsObj = userReponses[userReponse];
      if (requirementsObj) {
        for (let requirement in requirementsObj) {
          const response = { [requirement]: [] };
          requirementsObj[requirement].UserOptions.map((option) => {
            const reqObj = requirements.filter(req => req.id === parseInt(requirement));
            if (reqObj && reqObj[0]?.answer_type === 4) {
              response[requirement].push(option.Answer);
            } else {
              response[requirement].push(option.OptionID);
            }
            return option;
          });
          try {
            if (eval(rule.JsExpression)) {
              pushToRequirementArray = false;
              break;
            }
          } catch (e) { }
        }
      }
    }
    return pushToRequirementArray;
  },
  getDateDiff(date) {
    let diff = 0;
    if (date) {
      var m1 = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
      var m2 = moment(date, 'YYYY-MM-DD HH:mm:ss');
      diff = moment.preciseDiff(m1, m2); // '1 month 2 days 3 hours 4 minutes 5 seconds'
    }
    return diff;
  },
};



export { CommonFn };
