import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AuthContainer from "./Common/AuthContainer";
import Logo from "../assets/images/colour_logo.svg";
import { Typography, Button, CircularProgress, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { authorizedUser, logout, userSSOLogin, showAskLoginPlatformDialog, setCurrentOrg } from "../redux/authuser/action";
import Alert from '@material-ui/lab/Alert';
import * as URL from "../util/URL";
import Image from 'Components/Common/image.jsx'
import AskLoginPlatformDialog from './Dialogs/AskLoginPlatformDialog';
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import queryString from "query-string";
import { getNewPlatform } from 'util/Environment';

const connectedProps = (state) => ({
    authProgress: state.authUser.authProgress,
    authError: state.authUser.authError,
    user: state.authUser.user,
    organization: state.authUser.user?.Organization,
    orgs: state.authUser.user?.Orgs,
    setCurrentOrgData: state.authUser.setCurrentOrgData,
    setCurrentOrgProgress: state.authUser.setCurrentOrgProgress,
    setCurrentOrgError: state.authUser.setCurrentOrgError,
    userSSOLoginProgress: state.authUser.userSSOLoginProgress,
    userSSOLoginData: state.authUser.userSSOLoginData,
});

const connectionActions = {
    authorizedUser: authorizedUser,
    logout: logout,
    userSSOLogin: userSSOLogin,
    showAskLoginPlatformDialog: showAskLoginPlatformDialog,
    setCurrentOrg: setCurrentOrg,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "loginform": {
        textAlign: "center",
        paddingTop: "40%",
        width: "65%",
        margin: "0 auto",
    },
    "caption": {
        marginTop: theme.spacing(2)
    },
    "heading": {
        marginBottom: theme.spacing(1.5)
    },
    subheading: {
        fontSize: 16
    },
    button: {
        marginTop: theme.spacing(1),
    }
});

class Splash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "error": "",
            isAskForOrganization: false,
            isOrgCheckDone: false,
            selectedOrganization: null,
            isAskForPlatform: false,
            selectedPlatform: 'Buyer',
            queryParams: queryString.parse(this.props.location.search),
            redirectTo: null,
            noOrgs: false,
        };
        this.goToPlatform = this.goToPlatform.bind(this);
    }

    componentDidMount() {
        var token = URL.getQueryVariable("token");
        var type = URL.getQueryVariable("type");

        if (this.state.queryParams && this.state.queryParams.redirectTo !== undefined && this.state.queryParams.redirectTo !== null) {
            this.setState({
                redirectTo: this.state.queryParams.redirectTo
            })
        }

        if (token !== null && type !== null) {
            this.props.userSSOLogin({
                "token": token
            })
        } else {
            this.props.authorizedUser();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.setCurrentOrgProgress === false && prevProps.setCurrentOrgProgress === true) {
            if (this.props.setCurrentOrgData !== null) {
                this.props.authorizedUser();
            }else{
                this.setState({ error: "Something went wrong. please try again later." });
            }
        }
        if (this.props.authProgress === false && prevProps.authProgress === true) {
            if (this.props.authError !== null) {
                var error = this.props.authError;
                console.log(error.message);
                if (error.message.indexOf("Token") !== -1) {
                    this.props.logout();
                    this.props.history.push("/")
                } else {
                    this.setState({ error: error.message });
                }
            } else {
                if (this.props.user !== null) {
                    if(this.props.orgs?.length === 0) {
                        this.setState({
                            noOrgs:true,
                        })
                    }else if(this.props.orgs?.length === 1 && !this.state.isOrgCheckDone) {
                        this.setState({
                            isOrgCheckDone: true,
                        },()=>{
                            this.props.setCurrentOrg(this.props.orgs[0]?.ID)
                        })
                    }else if(this.props.orgs?.length > 0 && !this.state.isOrgCheckDone) {
                        this.setState({
                            isAskForOrganization: true,
                            isOrgCheckDone: true,
                            selectedOrganization: this.props.orgs[0]?.ID,
                            isAskForPlatform: false
                        })
                    } else if (this.props.organization?.IsBuyer && this.props.organization?.IsSeller) {
                        this.setState({
                            isAskForPlatform: true,
                            isAskForOrganization: false,
                        })
                    } else if (this.props.organization?.IsSeller) {
                        let link = `${getNewPlatform()}/sellerapp/home`
                        window.open(link, "_self");
                    } else {
                        let link = `${getNewPlatform()}/home`
                        window.open(link, "_self");
                    }
                } else {
                    this.props.logout();
                    this.props.history.push("/")
                }
            }
        }

        if (this.props.userSSOLoginProgress === false && prevProps.userSSOLoginProgress === true) {
            if (this.props.userSSOLoginData !== null) {
                const { userSSOLoginData } = this.props;
                window.localStorage.setItem("authToken", userSSOLoginData.authToken);
                window.localStorage.setItem("authType", userSSOLoginData.authType);
                setTimeout(() => {
                    this.props.authorizedUser();
                }, 1000);
            }
        }
    }

    goToPlatform() {
        if(this.state.isAskForOrganization) { 
            this.props.setCurrentOrg(this.state.selectedOrganization)
        } else {
            if (this.state.selectedPlatform === 'Vendor') {
                let link = `${getNewPlatform()}/sellerapp/home`
                window.open(link, "_self");
            } else {
                let link = `${getNewPlatform()}/home`
                window.open(link, "_self");
            }
        }
    }

    render() {
        var classes = this.props.classes;

        return <AuthContainer>
            <div className={classes.loginform}>
                <Image src={Logo} alt="logo" />
                <div className={classes.caption}>
                    <Typography variant={"h5"} className={classes.heading}>Expent</Typography>
                    {(this.props.authProgress || this.props.setCurrentOrgProgress) && <CircularProgress />}
                </div>

                {this.state.isAskForOrganization && <div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{marginBottom: '10px'}}>Select Organization</FormLabel>
                        <RadioGroup aria-label="org" name="org1" value={this.state.selectedOrganization} onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({
                                selectedOrganization: Number(e.target.value)
                            })
                        }}>
                            {this.props?.orgs?.map(org=>{
                                return (
                                    <FormControlLabel key={org.ID} value={org.ID} control={<Radio />} label={org.Name} />
                                )
                            })}
                            
                        </RadioGroup>
                    </FormControl>
                    <Button variant="contained" color="secondary" fullWidth className={classes.button} onClick={this.goToPlatform}>Next</Button>
                </div>}

                {this.state.isAskForPlatform && <div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{marginBottom: '10px'}}>Select Platform</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={this.state.selectedPlatform} onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({
                                selectedPlatform: e.target.value
                            })
                        }}>
                            <FormControlLabel value="Buyer" control={<Radio />} label="Buyer" />
                            <FormControlLabel value="Vendor" control={<Radio />} label="Vendor" />
                        </RadioGroup>
                    </FormControl>
                    <Button variant="contained" color="secondary" fullWidth className={classes.button} onClick={this.goToPlatform}>Next</Button>
                </div>}
                
                {this.state.noOrgs && <div className={classes.alert}>
                    <Alert variant="filled" severity="info">You are not associated with any organization. please contact admin</Alert>
                    <br />
                    <Button variant="contained" color="secondary" fullWidth className={classes.button} onClick={this.props.logout}>Logout</Button>
                </div>}
                {this.state.error.length > 0 && <div className={classes.alert}>
                    <Alert variant="filled" severity="error">{this.state.error}</Alert>
                    <br />
                    <Button variant="contained" color="secondary" fullWidth className={classes.button} onClick={this.props.logout}>Logout</Button>
                </div>}
            </div>

            <AskLoginPlatformDialog />
        </AuthContainer>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Splash));