import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as icons from "@material-ui/icons";

const IconPicker = ({ selectedIconName, onIconSelect }) => {
  const [search, setSearch] = useState("");
  const [selectedIcon, setSelectedIcon] = useState(icons[selectedIconName]);

  const allIcons = Object.entries(icons)
    .map(([name, Icon]) => ({ name, Icon }))
    .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

  const handleIconSelect = (event, icon) => {
    onIconSelect(icon ? icon.name : null);
    setSelectedIcon(icon);
  };

  return (
    <Autocomplete
      size="small"
      options={allIcons?.slice(0, 20)}
      getOptionLabel={(option) => option?.name}
      renderOption={(option) => (
        <>
          <option.Icon />
          <span style={{ marginLeft: 8 }}>{option.name}</span>
        </>
      )}
      onChange={handleIconSelect}
      inputValue={search}
      onInputChange={(event, value) => setSearch(value)}
      renderInput={(params) => <TextField {...params} label="Select Icons" variant="outlined" />}
      fullWidth
      value={selectedIcon}
    />
  );
};

export default IconPicker;
