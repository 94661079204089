import {
    START_MASTER_EVALUATION_TEMPLATE_FETCH, END_MASTER_EVALUATION_TEMPLATE_FETCH,
    START_MASTER_EVALUATION_TEMPLATE_GET, END_MASTER_EVALUATION_TEMPLATE_GET,
    SET_TEMPLATE_ACTIVE_TAB
} from "./action";

const initState = {
    templates: [],
    templatesWithCriterias: [],
    fetchProg: false,
    fetchTemplateProg: false,
    template: null,
    templateActiveTab: 1
}

export function masterTemplateEvaluationReducer(state = initState, action) {
    switch (action.type) {
        case START_MASTER_EVALUATION_TEMPLATE_FETCH:
            var templates = state.templates;
            if (action.payload == 0) {
                templates = []
            }
            return {
                ...state,
                fetchProg: true,
                templates: templates,
                templatesWithCriterias: templates
            };
        case END_MASTER_EVALUATION_TEMPLATE_FETCH:
            var success = action.payload.success;
            var templates = state.templates;
            if (success !== null) {
                var Page = success.Page;
                var Data = success.Data;
                if (Page == 0) {
                    templates = Data;
                } else {
                    templates = templates.concat(Data)
                }
            }
            return {
                ...state,
                fetchProg: false,
                templates: templates,
                templatesWithCriterias: templates
            }
        case START_MASTER_EVALUATION_TEMPLATE_GET:
            return {
                ...state,
                fetchTemplateProg: true,
                template: null
            }
        case END_MASTER_EVALUATION_TEMPLATE_GET:
            var template = action.payload.success
            return {
                ...state,
                fetchTemplateProg: false,
                template: template,
            }

        case SET_TEMPLATE_ACTIVE_TAB:
            return {
                ...state,
                templateActiveTab: action.payload.id
            }
        default:
            return state;
    }
}