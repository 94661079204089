import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {  Popover, Typography,Slider ,Grid,Button } from '@material-ui/core';

const connectedProps = (state) => ({
    expandedCriteriaId:state.evaludationCriteria.expandedCriteriaId
});

const connectionActions = {
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root:{
        background:"#03333A",
        color:"#fff",
        padding:theme.spacing(2),
        width:'300px'
    },
    rating:{
        marginTop:'30px'
    },
    action:{
        marginTop:'10px',
        textAlign:"right"
    },
    saveBtn:{
        color:"#F7A738"
    },
    ratingValue:{
        color:"#F7A738"
    },
    thumb:{
        color:"#F7A738"
    },
    track:{
        backgroundColor:"#F7A738"
    },
    rail:{
        backgroundColor:"#f0f0f0"
    }
});

class RatingPoper extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            value:0
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.anchorEl !== null && prevProps.anchorEl == null){
            this.setState({
                value:this.props.value
            })
        }
    }
    componentDidMount(){
    }

    render() {
        const classes   =   this.props.classes;

        const maxScore = this.props.maxScore !== undefined && this.props.maxScore !== null ? this.props.maxScore : 5;

        return <Popover 
                    key={this.props.key || ''}
                    classes={{
                        paper:classes.root
                    }}
                    open={Boolean(this.props.anchorEl)}
                    anchorEl={this.props.anchorEl}
                    onClose={()=>{
                        this.props.onClose(null)
                    }}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    >
                        <Typography>Rate Your Response</Typography>
                        <Grid container className={classes.rating}>
                            <Grid item lg={2} style={{flex:1}}>
                                <Typography className={classes.ratingValue}>{this.state.value}</Typography>
                            </Grid>
                            <Grid item lg={10} style={{flex:10}}>
                                <Slider
                                    defaultValue={this.props.value}
                                    // value={this.state.value}
                                    valueLabelDisplay="auto"
                                    step={0.5}
                                    marks
                                    min={0}
                                    max={maxScore}
                                    onChange={(event,value)=>{
                                        this.setState({
                                            value:value
                                        })
                                    }}
                                    classes={{
                                        thumb:classes.thumb,
                                        track:classes.track,
                                        rail:classes.rail
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.action}>
                            <Button style={this.state.value > 0 ? {'background-color':'#F7A738','color':'white'} :{}} className={classes.saveBtn} onClick={()=>{
                                this.props.onSave(this.state.value)
                            }}>Save</Button>
                        </div>
            </Popover>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(RatingPoper));