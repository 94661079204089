export function getConnectionMap(connections) {
    let map = {}
    connections.forEach(function (itm) {
        if (map[itm.SourceComponentID] === undefined) {
            map[itm.SourceComponentID] = [];
            map[itm.SourceComponentID].push(itm.TargetComponentID)
        } else {
            map[itm.SourceComponentID].push(itm.TargetComponentID)
        }
    })
    return map;
}

export function getComponentMap(components) {
    let map = {}
    components.forEach(function (itm) {
        map[itm.ID] = itm
    })
    return map;
}

export function getControlEndId(sourceMap, componentMap, sourceId) {
    let targetId = null;
    if (sourceMap[sourceId]) {
        let targets = sourceMap[sourceId];
        targets.forEach(function (itm) {
            let data = componentMap[itm];
            if (data.Type === 'Control' && data.RefID === 'if_end') {
                targetId = itm
            }
        })
    }
    return targetId
}

export function getForControlEndId(sourceMap, componentMap, sourceId) {
    let targetId = null;
    if (sourceMap[sourceId]) {
        let targets = sourceMap[sourceId];
        targets.forEach(function (itm) {
            let data = componentMap[itm];
            if (data.Type === 'Control' && data.RefID === 'for_end') {
                targetId = itm
            }
        })
    }
    return targetId
}

export function getAllComponents(currentId, connectionMap, componentMap, result) {
    //   console.group(currentId)
    //   console.log('currentId=========>',currentId)
    //   console.log('connectionMap=========>',connectionMap)
    //   console.log(connectionMap)
    if (connectionMap[currentId] === undefined || connectionMap[currentId].length <= 0) {
        return result;
    }
    let targets = connectionMap[currentId];
    //   console.log('targets=========>',targets)

    targets.forEach(function (tarId) {
        let data = componentMap[tarId];
        //   console.log('data=========>',data)
        if (data.Type !== 'Control') {
            if (data) {
                result.push(data)
            }
            getAllComponents(tarId, connectionMap, componentMap, result)
            //   console.groupEnd()
        } else if (data.Type === 'Control') {
            let data = componentMap[tarId];
            if (data) {
                result.push(data)
            }
            let endControlId = getControlEndId(connectionMap, componentMap, tarId)
            let endData = componentMap[endControlId];
            if (endData) {
                result.push(endData)
            }
            getAllComponents(endControlId, connectionMap, componentMap, result)
            //   console.groupEnd()
        }
    })
    return result;
}

export function getAutomationComponents(startId, connections, components) {
    let connectionMap = getConnectionMap(connections)
    let componentMap = getComponentMap(components)
    // console.log(connectionMap, 'connectionMap')
    // console.log(componentMap, 'componentMap')
    let result = [];
    let startData = componentMap[startId];
    result.push(startData)
    let finalData = getAllComponents(startId, connectionMap, componentMap, result)
    // console.log(finalData, 'finalData')
    return finalData;
}


export function getItems(currentId, endId, connectionMap, componentMap, result) {
    if (currentId === endId || connectionMap[currentId] === undefined) {
        return result
    }
    let targets = connectionMap[currentId];
    targets.forEach(function (tarId) {
        let data = componentMap[tarId]
        if (tarId === endId) {
            // Do Nothing
        } else if (data.Type === 'Control' && (data.RefID === 'if' || data.RefID === 'ifelse' || data.RefID === 'else')) {
            result.push(data)
            let endId = getControlEndId(connectionMap, componentMap, data.ID)
            // console.log(data.ID, 'Child Start')
            // console.log(endId, 'Child Endddddd')
            result = getAllComponents(endId, connectionMap, componentMap, result)
            // getItems(data.ID, endId, connectionMap, componentMap, result)
        } else if (data.Type === 'Control' && data.RefID === 'for') {
            result.push(data)
            let endId = getForControlEndId(connectionMap, componentMap, data.ID)
            // console.log(data.ID, 'Child Start')
            // console.log(endId, 'Child Endddddd')
            result = getAllComponents(endId, connectionMap, componentMap, result)
            // getItems(data.ID, endId, connectionMap, componentMap, result)
        } else {
            result.push(data)
            getItems(tarId, endId, connectionMap, componentMap, result)
        }
    })
    return result;
}


export function getControlItems(startControlId, connections, components, type) {
    let connectionMap = getConnectionMap(connections)
    let componentMap = getComponentMap(components)
    // console.log(connectionMap, 'connectionMap')
    // console.log(componentMap, 'componentMap')
    if (type === 'IF') {
        let endControlId = getControlEndId(connectionMap, componentMap, startControlId)
        // console.log(endControlId, 'endControlId')
        let finalData = getItems(startControlId, endControlId, connectionMap, componentMap, [])
        // console.log(finalData, 'finalData')
        return finalData;
    } else if (type === 'LOOP') {
        let endControlId = getForControlEndId(connectionMap, componentMap, startControlId)
        // console.log(endControlId, 'endControlId')
        let finalData = getItems(startControlId, endControlId, connectionMap, componentMap, [])
        // console.log(finalData, 'finalData')
        return finalData;
    }
}