import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "../../../../../AppBarV2";
import CommonCss from "commonCss";
import moment from "moment";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, LinearProgress, IconButton, Paper, Button } from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import { showAddOauthDialog, hideAddOauthDialog, deleteOauth, fetchOauths } from "redux/oauth/action";
import AddOauthDialog from "../../dialogs/AddOauthDialog";
import { showSnackBar } from "redux/snackbar/action";

import { showMessageDialog, hideMessageDialog } from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

const connectedProps = (state) => ({
  isOpenAddDialog: state.oauth.dialogAddOauthOpen,
  addNewOauthProgress: state.oauth.addNewOauthProgress,
  addNewOauthError: state.oauth.addNewOauthError,
  addNewOauthSuccess: state.oauth.addNewOauthSuccess,
  deleteOauthProgress: state.oauth.deleteOauthProgress,
  deleteOauthError: state.oauth.deleteOauthError,
  deleteOauthSuccess: state.oauth.deleteOauthSuccess,
  fetchOauthsProgress: state.oauth.fetchOauthsProgress,
  fetchOauthsError: state.oauth.fetchOauthsError,
  oauthDatas: state.oauth.oauthDatas,
});

const connectionActions = {
  showAddOauthDialog: showAddOauthDialog,
  hideAddOauthDialog: hideAddOauthDialog,
  showSnackBar: showSnackBar,
  deleteOauth: deleteOauth,
  fetchOauths: fetchOauths,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    topActions: {
      textAlign: "right",
      marginBottom: 20,
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    nameCol: {
      width: "30%",
    },
    actionCol: {
      paddingLeft: "30px",
      width: "10%",
    },
  });

class Home extends React.Component {
  state = {
    deleteId: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.props.fetchOauths();
  };

  componentDidUpdate(prevProps) {
    const { addNewOauthProgress, addNewOauthError, addNewOauthSuccess, deleteOauthProgress, deleteOauthError, fetchOauthsProgress, fetchOauthsError } = this.props;
    if (!addNewOauthProgress && prevProps.addNewOauthProgress) {
      if (addNewOauthError === null) {
        this.props.showSnackBar("Application added successfully.", "success", 3000);
        this.props.hideAddOauthDialog();
        this.handleEdit(addNewOauthSuccess?.ClientID);
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }

    if (!deleteOauthProgress && prevProps.deleteOauthProgress) {
      if (deleteOauthError === null) {
        this.props.showSnackBar("Application deleted successfully.", "success", 3000);
        this.fetchData();
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }

    if (!fetchOauthsProgress && prevProps.fetchOauthsProgress) {
      if (fetchOauthsError) {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }
  }

  handleEdit = (id) => {
    let redirectPath = `/app/settings/oauth/${id}`;
    this.props.history.push(redirectPath);
  };

  handleDelete = (id) => {
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    });
  };

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteOauth(this.state.deleteId);
    }
  };

  render() {
    const classes = this.props.classes;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div>
        <AppBarV2 title="API" withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.topActions}>
            <Button
              className={classes.newUserBtn}
              onClick={() => {
                this.props.showAddOauthDialog();
              }}
            >
              + New Application
            </Button>
          </div>
          <div className={classes.body}>
            <Paper style={{ width: "100%", overflow: "hidden" }}>
              <TableContainer style={{ maxHeight: 650 }}>
                <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.nameCol}>Application Name</TableCell>
                      <TableCell>Client ID</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell className={classes.actionCol}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(this.props.fetchOauthsProgress || this.props.deleteOauthProgress) && (
                      <TableRow>
                        <TableCell colSpan={6} style={{ textAlign: "center", padding: 0 }} className={classes.nameCol}>
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {this.props.oauthDatas.map((row, i) => {
                      return (
                        <TableRow key={"row_" + row.ID}>
                          <TableCell>{row.Name}</TableCell>
                          <TableCell>{row.ClientID}</TableCell>
                          <TableCell>{row.IntegrationUserEmail}</TableCell>
                          <TableCell>{row.Status === 1 ? "Active" : "In Active"}</TableCell>
                          <TableCell>{moment(row?.CreatedAt).format("MMM Do YYYY hh:mm A")}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => this.handleEdit(row?.ClientID)}>
                              <Image src={editIcon} />
                            </IconButton>

                            {row.Status === 2 && (
                              <IconButton onClick={() => this.handleDelete(row?.ClientID)}>
                                <Image src={deleteIcon} />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {!this.props.fetchOauthsProgress && this.props.oauthDatas.length <= 0 && (
                      <TableRow>
                        <TableCell colSpan={6} style={{ textAlign: "center" }} className={classes.nameCol}>
                          No Applications
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
        {this.props.isOpenAddDialog && <AddOauthDialog />}
        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Home));
