import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import classnames from "classnames";
import ShareIcon from '@material-ui/icons/Share';
import {
  Typography, CircularProgress, Tooltip, Button
} from '@material-ui/core'

import { withRouter } from 'react-router-dom';
import { searchUsers } from "redux/usersSettings/action";
import { fetchOrgGroup } from "redux/org/group/action";
import { fetchWorkFlowCustomForm } from "redux/evaluation/workflow/action";
import CustomReply from 'Components/Common/FormReply/CustomReply';
import BottomActions from "./BottomActions";

import deleteIcon from "assets/images/delete.svg";
import Image from "Components/Common/image.jsx";
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import addUsers from "assets/images/add_profile.svg";

import { fetchOrgFormUsingId } from "redux/forms/org/action";
import { fetchMasterFormUsingId } from "redux/forms/master/action";
import { fetchSimilarExistingProducts } from "redux/product/orgProduct/action";
import Alert from '@material-ui/lab/Alert';

import EvaluationBar from "../../../Common/EvaluationBar";
import * as Validator from "util/Validation";
import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";
import { getNextComponent } from "util/Common";

import { showSearchTeamsPoperDialog, hideSearchTeamsPoperDialog, showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import SearchTeamsPoper from "Components/Common/TeamsAndUser/SearchTeamsPoper";
import SearchUserPoper from "Components/Common/TeamsAndUser/SearchUserPoper";
import { fetchFormDecisionItems } from "redux/workflow/summary/action";
import { showSnackBar } from "redux/snackbar/action";
import {
  addWorkflowComponentUserResponse, addCustomFormResponse,
  fetchWorkflowFormResponse, addComponentColloborator, fetchComponentCollaborators,
  deleteComponentColloborator
} from "redux/workflow/action";


const connectedProps = (state) => ({
  OrgID: state.authUser.user.OrgID,
  user: state.authUser.user,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  componentUserResponseError: state.workflow.componentUserResponseError,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  fetchCustomFormProgress: state.evaluationWorkflow.fetchCustomFormProgress,
  workflowCustomForm: state.evaluationWorkflow.workflowCustomForm,
  addCustomComponentUserResponseProgress: state.workflow.addCustomComponentUserResponseProgress,
  componentUserResponse: state.workflow.componentUserResponse,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  orgFormData: state.orgForms.orgFormData,
  fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
  fetchMasterFormsByIdProgress: state.masterForms.fetchMasterFormsByIdProgress,
  masterFormData: state.masterForms.masterFormData,
  fetchWokflowFormResponseProgress: state.workflow.fetchWokflowFormResponseProgress,
  workflowFormResponse: state.workflow.workflowFormResponse,
  similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
  similarExistingProducts: state.orgProduct.similarExistingProducts,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  userTimeline: state.workflow.userTimeline,
  fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,
  isOpenTeamDialog: state.dialogs.isShowSearchTeamPoperDialog,
  isOpenUserDialog: state.dialogs.isShowSearchUserPoperDialog,
  fetchDecisionItemsProgress: state.workflowSummary.fetchDecisionItemsProgress,
  fetchDecisionItemsError: state.workflowSummary.fetchDecisionItemsError,
  decisionItems: state.workflowSummary.decisionItems,

  fetchComponentColloboratorProgress: state.workflow.fetchComponentColloboratorProgress,
  fetchComponentColloboratorError: state.workflow.fetchComponentColloboratorError,
  componentColloborators: state.workflow.componentColloborators,

  addComponentColloboratorProgress: state.workflow.addComponentColloboratorProgress,
  addComponentColloboratorError: state.workflow.addComponentColloboratorError,
  addComponentColloboratorSuccess: state.workflow.addComponentColloboratorSuccess,

  deleteComponentColloboratorProgress: state.workflow.deleteComponentColloboratorProgress,
  deleteComponentColloboratorError: state.workflow.deleteComponentColloboratorError,
  deleteComponentColloboratorSuccess: state.workflow.deleteComponentColloboratorSuccess

});

const connectionActions = {
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchWorkFlowCustomForm: fetchWorkFlowCustomForm,
  addCustomFormResponse: addCustomFormResponse,
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  fetchOrgFormUsingId: fetchOrgFormUsingId,
  fetchMasterFormUsingId: fetchMasterFormUsingId,
  fetchWorkflowFormResponse: fetchWorkflowFormResponse,
  fetchSimilarExistingProducts: fetchSimilarExistingProducts,
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  showSearchTeamsPoperDialog: showSearchTeamsPoperDialog,
  hideSearchTeamsPoperDialog: hideSearchTeamsPoperDialog,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
  fetchFormDecisionItems: fetchFormDecisionItems,
  showSnackBar: showSnackBar,
  addComponentColloborator: addComponentColloborator,
  fetchComponentCollaborators: fetchComponentCollaborators,
  deleteComponentColloborator: deleteComponentColloborator
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  topRoot: {
    height: '88.5vh',
    overflow: 'scroll',
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
  },
  root: {
    // background: '#fff',
    padding: 20,
    width: '80%',
    margin: '0px auto',
    paddingBottom: 250
  },
  actions: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  actionBtn: {
    minWidth: '200px'
  },
  actionBtnDisabled: {
    minWidth: '200px',
  },
  dialogContent: {
    margin: 'auto',
    marginBottom: 20,
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  stepHead: {
    color: '#282D30',
    fontSize: 18,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  stepHead2: {
    color: '#282D30',
    fontSize: 14,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  sectionName: {
    color: '#282D30',
    fontSize: 18,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  stepSubHead: {
    color: '#4B4B4B',
    fontSize: 12,
    margin: 0,
    paddingBottom: 20
  },
  customForm: {
    marginTop: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
    padding: 20,
  },
  footer: {
    textAlign: 'right'
  },
  section: {
    margin: 0,
    // border: '1px solid #f1f1f1',
    // padding: 20
  },
  sectionItem: {
    padding: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
  },
  sectionBox: {
    padding: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
    borderTop: '4px solid #7fc781'
  },
  sectionTitle: {
    color: '#282D30',
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
  alert: {
    padding: '10px'
  },
  topHeadColor: {
    background: '#1f73b7',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    height: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%'
  },
  disableForm: {
    pointerEvents: 'none'
  },
  titleFlex: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  titlePart: {
    flex: 1
  },
  progressPart: {
    textAlign: 'right',
    flex: .5
  },
  assignedTo: {
    marginTop: 10
  },
  addUser: {
    cursor: 'pointer'
  },
  copyText: {
    fontWeight: 400,
    fontSize: theme.spacing(2),
    position: 'absolute',
    right: 20,
    border: '1px solid #4b86ff',
  },
  copiedText: {
    textAlign: 'left',
    fontSize: '14px',
    color: '#2EB77D',
    position: 'absolute',
    right: 20,
  },
});

class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customFormTitle: '',
      customFormDescription: '',
      customFormData: [],
      isShowLoader: true,
      formConfigDetails: {},
      formError: '',
      showSectionMap: {},
      showSectionResponseMatch: {},
      itemIdsForSection: {},
      saveType: 'NEXT',
      isFormEditable: true,
      componentStatus: null,
      nextComponent: null,
      nextClickLoaderSave: false,
      nextClickLoader: false,
      currentSelected: null,
      IntegrationFormData: null,
      webHookItemIdMap: {},
      totalQuestions: 0,
      totalAnswered: 0,
      decisionItemMap: {},
      isCheckDecisionItems: false,
      showDependentItems: {},
      showSectionCheckTypeMap: {},
      formColloborator: [],
      isForFormColloborator: true,
      form_type: '',
      form_id: '',
      isCopyForPublic: false,
    }
    this.avatarName = this.avatarName.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.setSelectedTeams = this.setSelectedTeams.bind(this);
    this.setSelectedUsers = this.setSelectedUsers.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this)
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    // const isCheckDecisionItems = window.localStorage.getItem("isCheckDecisionItems");
    // console.log(isCheckDecisionItems,'isCheckDecisionItems')
    // if(isCheckDecisionItems !== undefined && isCheckDecisionItems !== null && isCheckDecisionItems !== ''){
    //     this.setState({
    //       isCheckDecisionItems : isCheckDecisionItems === 'false' ? false : true
    //     })
    // }
    this.props.fetchEvaluationMetaData(this.props.evaluation_id);
    this.props.fetchComponentCollaborators(this.props.evaluation_id, this.props.workflowComponentId)



    this.props.fetchUsers(0, 30, "");
    this.props.fetchOrgGroup(0, 30, "");

    if (this.props.evaluation !== null) {
      this.props.fetchSimilarExistingProducts(this.props.evaluation.Products.map((p) => {
        return p.ID
      }))
    }

    this.checkForStatus();
  }

  componentDidUpdate(prevProps) {

    if (!this.props.fetchProgress && prevProps.fetchProgress) {
      this.checkWorkflowConfiguration();
    }

    if (this.props.addCustomComponentUserResponseProgress === false && prevProps.addCustomComponentUserResponseProgress === true) {
      if (this.props.componentUserResponseError === null) {
        if (this.props.componentUserResponse.refreshNeeded) {
          if (this.state.saveType !== 'NEXT') {
            this.setState({
              nextClickLoader: false,
              nextClickLoaderSave: false
            }, () => {
              this.props.showSnackBar("Response updated successfully.", "success", 3000)
              window.location.reload()
            })
          } else {
            this.updateWorkflowComponentUserRes()
          }
        } else {
          if (this.state.saveType !== 'NEXT') {
            this.setState({
              nextClickLoader: false,
              nextClickLoaderSave: false
            })
            this.props.showSnackBar("Response updated successfully.", "success", 3000)
          } else {
            var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);
            if (nextComponent !== undefined && nextComponent !== null && nextComponent !== '') {
              let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
              this.props.history.push(redirectPath);
            } else {
              this.updateWorkflowComponentUserRes()
            }
          }
        }
      } else {
        alert('Something Went Wrong');
      }
    }

    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.props.isWorkFlow) {
        this.checkWorkflowConfiguration();
      }
    }

    if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
      this.checkConditions('ORG');
    }

    if (this.props.fetchMasterFormsByIdProgress === false && prevProps.fetchMasterFormsByIdProgress === true) {
      this.checkConditions('MASTER');
    }

    if (this.props.fetchWokflowFormResponseProgress === false && prevProps.fetchWokflowFormResponseProgress === true) {
      this.checkResponses();
    }

    if (this.props.fetchWokflowUserTimelineProgress === false && prevProps.fetchWokflowUserTimelineProgress === true) {
      this.checkForStatus();
    }

    if (this.props.fetchDecisionItemsProgress === false && prevProps.fetchDecisionItemsProgress === true) {
      if (this.props.fetchDecisionItemsError === null) {
        // console.log(this.props.decisionItems, 'decisionItems')
        if (this.props.decisionItems !== null && this.props.decisionItems.length > 0) {
          let decisionItemMap = {};
          this.props.decisionItems.forEach(function (di) {
            if (decisionItemMap[di.SectionID] === undefined) {
              decisionItemMap[di.SectionID] = [];
              decisionItemMap[di.SectionID].push(di.ID)
            } else {
              decisionItemMap[di.SectionID].push(di.ID)
            }
          })
          this.setState({
            decisionItemMap: decisionItemMap
          })
        }
      }
    }

    if (!this.props.fetchComponentColloboratorProgress && prevProps.fetchComponentColloboratorProgress) {
      if (this.props.fetchComponentColloboratorError === null) {
        console.log(this.props.componentColloborators, 'componentColloborators-success111')
        this.setState({
          formColloborator: this.props.componentColloborators.map(o => {
            return {
              EntityID: o.EntityID,
              ...o.User
            }
          })
        })
      } else {
        console.log(this.props.fetchComponentColloboratorError, 'error')
      }
    }

    if (!this.props.addComponentColloboratorProgress && prevProps.addComponentColloboratorProgress) {
      if (this.props.addComponentColloboratorError === null) {
        console.log(this.props.addComponentColloboratorSuccess, 'addComponentColloboratorSuccess')
        this.props.showSnackBar("Collaborator added successfully.", "success", 3000)
      } else {
        console.log(this.props.addComponentColloboratorError, 'error')
        this.props.showSnackBar("Failed to add Collaborator.", "error", 3000)
      }
    }

    if (!this.props.deleteComponentColloboratorProgress && prevProps.deleteComponentColloboratorProgress) {
      if (this.props.deleteComponentColloboratorError === null) {
        console.log(this.props.deleteComponentColloboratorSuccess, 'deleteComponentColloboratorSuccess')
        this.props.showSnackBar("Collaborator delete successfully.", "success", 3000)
        this.setState({
          formColloborator: []
        })
      } else {
        console.log(this.props.deleteComponentColloboratorError, 'error')
        this.props.showSnackBar("Failed to delete Collaborator.", "error", 3000)
      }
    }
  }

  updateWorkflowComponentUserRes() {
    var responseDatas = [];
    this.props.workflowComponentsForms.forEach((data) => {
      if (data.IsConfiguration === false) {
        var obj = {
          "form_id": data.ID
        }
        var value = '';
        if (data.Name === 'evaluation.name') {
          value = this.state.name;
        }
        obj['value'] = value;
        responseDatas.push(obj);
      }
    });

    this.props.addWorkflowComponentUserResponse(this.props.evaluation_id, this.props.workflowComponentId, {
      "data": responseDatas,
      "forward": this.state.saveType === 'NEXT' ? true : false
    });
  }

  checkForStatus() {
    const { userTimeline } = this.props;
    this.setState({
      isFormEditable: true,
    })
    if (userTimeline !== null && userTimeline !== undefined) {
      const workflowComId = parseInt(this.props.workflowComponentId);
      var componentStatus = userTimeline.filter(o => o.ComponentId === workflowComId)[0]
      let componentIndex = userTimeline.findIndex(o => o.ComponentId === workflowComId);

      let nextComponent;
      if (userTimeline[componentIndex++] !== undefined) {
        nextComponent = userTimeline[componentIndex++]
      }


      if (componentStatus !== undefined && componentStatus !== null && componentStatus.Status === 2) {
        this.setState({
          isFormEditable: false,
          componentStatus: componentStatus,
          nextComponent: nextComponent
        })
      }
    } else {
      this.setState({
        isFormEditable: true,
      })
    }
  }

  checkResponses() {
    const { workflowFormResponse } = this.props;
    const { customFormData, formConfigDetails } = this.state;
    var me = this;
    if (customFormData === null || customFormData.length <= 0 || workflowFormResponse === null || workflowFormResponse.length <= 0) {
      return;
    }

    var responseMap = {};
    workflowFormResponse.forEach(function (item) {
      responseMap[item.ItemId] = item;
    });
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    var customFormDataNew = this.state.customFormData;
    customFormDataNew[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        if (responseMap[item.ID] === undefined) {
          return;
        }
        var value = '';
        try {
          value = JSON.parse(responseMap[item.ID].Data)
        } catch (error) {
        }
        item['value'] = value;
        if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
          me.updateShowSection(item, value);
        }
        if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
          me.updateShowSectionForNumbers(item, value)
        }
      })
    });
    this.setState({
      customFormData: customFormDataNew,
    }, () => {
      setTimeout(() => {
        this.updateTotalAnswered();
      }, 1000);
    })
  }

  checkWorkflowConfiguration() {
    const { workflowComponentsForms, workflowComponentDetail, evaluation } = this.props;
    if (workflowComponentsForms === null || workflowComponentDetail === null || evaluation === null || evaluation === undefined || evaluation === '') {
      return;
    }

    var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      this.setState({
        isHideAskSeller: true
      })
    } else {
      console.log(workflowComponentsForms, 'workflowComponentsForms')
      console.log(workflowComponentDetail, 'workflowComponentDetail')
      console.log(configurations, 'configurations')
      var customFormId = workflowComponentsForms.filter(o => o.Name === 'workflow_form_template').map(o => o.ID)[0];
      var customFormValue = configurations.filter(o => o.FormID === customFormId).map(o => o.Value)[0];

      var isPulicId = workflowComponentsForms.filter(o => o.Name === 'is_plublic').map(o => o.ID)[0];
      var isPulicValue = configurations.filter(o => o.FormID === isPulicId).map(o => o.Value)[0];
      isPulicValue = isPulicValue === 'true' ? true : false;
      this.setState({
        isCopyForPublic: isPulicValue
      })
      // var integrationFormId = workflowComponentsForms.filter(o => o.Name === 'integration_form_data').map(o => o.ID)[0];
      // var integrationFormValues = configurations.filter(o => o.FormID === integrationFormId).map(o => o.Value)[0];
      try {
        let configData = JSON.parse(customFormValue);
        this.setState({
          formConfigDetails: configData,
          form_type: configData?.type,
          form_id: configData?.id,
          // IntegrationFormData:JSON.parse(integrationFormValues)
        }, () => {
          customFormValue = configData;
          if (customFormValue?.type === "MASTER") {
            this.props.fetchMasterFormUsingId(customFormValue?.id, this.props.evaluation?.ID);
          } else {
            this.props.fetchOrgFormUsingId(customFormValue?.id, this.props.evaluation?.ID);
            this.props.fetchFormDecisionItems(this.props.evaluation_id, customFormValue?.id)
          }
          this.props.fetchWorkflowFormResponse(this.props.evaluation_id, customFormValue?.id, customFormValue?.type, this.props.workflowComponentId)
          // this.createIntegrationFormMap()
        })
      } catch (e) { }

    }
  }

  createIntegrationFormMap() {
    const { IntegrationFormData } = this.state;
    let webHookItemIdMap = {};
    if (IntegrationFormData !== undefined && IntegrationFormData !== null && IntegrationFormData !== '' && IntegrationFormData.items !== undefined && IntegrationFormData.items.length > 0) {
      IntegrationFormData.items.forEach(function (itm) {
        webHookItemIdMap[itm.ID] = itm.additional_data;
      })
    }
    this.setState({
      webHookItemIdMap: webHookItemIdMap
    })
  }

  checkConditions(type) {
    const orgFormData = type === 'ORG' ? this.props.orgFormData : this.props.masterFormData;
    // const { orgFormData } = this.props;
    const { formConfigDetails } = this.state;

    let showSectionMap = {};
    let showSectionCheckTypeMap = {
      item: [],
      section: []
    };
    let showSectionResponseMatch = {};
    let itemIdsForSection = {};
    var formType = formConfigDetails?.type;
    let showDependentItems = {}
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    if (orgFormData) {
      orgFormData[sectionName].forEach(function (section, k) {
        if (section[sectionItemName].length > 0) {
          section[sectionItemName].forEach(function (item) {
            try {
              if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                var options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                showSectionResponseMatch[item.ID] = {};
                itemIdsForSection[section.ID] = item.ID;
                options.forEach(function (op) {
                  if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
                    if (showDependentItems[item.ID] === undefined) {
                      showDependentItems[item.ID] = []
                    }

                    if (op.condition.type === 'item') {
                      showSectionMap[op.condition.value] = false;
                      showSectionCheckTypeMap.item.push(op.condition.value);
                      showSectionResponseMatch[item.ID][op.value] = op.condition;
                      if (showDependentItems[item.ID][op.value] === undefined) {
                        showDependentItems[item.ID][op.value] = [];
                        showDependentItems[item.ID][op.value].push(op.condition.value);
                      } else {
                        showDependentItems[item.ID][op.value].push(op.condition.value);
                      }
                    } else {
                      showSectionMap[op.condition.value] = false;
                      showSectionResponseMatch[item.ID][op.value] = op.condition;
                      if (showDependentItems[item.ID][op.value] === undefined) {
                        showDependentItems[item.ID][op.value] = [];
                        showDependentItems[item.ID][op.value].push(op.condition.value);
                      } else {
                        showDependentItems[item.ID][op.value].push(op.condition.value);
                      }
                    }
                  }
                })
              }
              if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                var num_options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                showSectionResponseMatch[item.ID] = {};
                itemIdsForSection[section.ID] = item.ID;
                num_options.forEach(function (op) {
                  if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
                    showSectionCheckTypeMap.item.push(op.condition.value);
                    showSectionMap[op.condition.value] = false;
                    showSectionResponseMatch[item.ID][op.value] = op.condition;
                  }
                })
              }
            } catch (error) {
            }
            // var item = section[sectionItemName][0];

          })
        }
      })
    }

    let totalQuestions = 0;
    if (orgFormData) {
      orgFormData[sectionName].forEach(function (section, k) {
        if (section[sectionItemName] !== null && section[sectionItemName].length > 0) {
          section[sectionItemName].forEach(function (itm) {
            if (showSectionMap[itm.ID] !== undefined && showSectionMap[itm.ID] === false) {
              return;
            } else if (showSectionMap[itm.SectionID] !== undefined && showSectionMap[itm.SectionID] === false) {
              return;
            }
            if (itm.Type !== 17 && itm.Type !== 21 && itm.Type !== 22) {
              totalQuestions++;
            }
          })
        }
      })
    }

    this.setState({
      customFormTitle: orgFormData?.FormTitle,
      customFormDescription: orgFormData?.FormDescription,
      customFormData: orgFormData,
      showSectionMap: showSectionMap,
      showSectionResponseMatch: showSectionResponseMatch,
      itemIdsForSection: itemIdsForSection,
      isShowLoader: false,
      totalQuestions: totalQuestions,
      showDependentItems: showDependentItems,
      showSectionCheckTypeMap: showSectionCheckTypeMap
    }, () => {
      this.checkResponses();
    })
  }

  updateShowSection(item, response) {
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    const { showDependentItems } = this.state;
    if (item.Type === 19 && response.indexOf('Other-') !== -1) {
      try {
        var options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
        if (options !== undefined && options.length > 0) {
          let otherOption = options.filter(o => o.isOtherOption !== undefined && o.isOtherOption === true)[0];
          if (otherOption !== undefined && otherOption !== null && otherOption !== -1 && otherOption !== '') {
            response = String(otherOption?.value)
          }
        }
      } catch (error) {
      }
    }


    const { itemIdsForSection } = this.state;
    var me = this;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      var datas = showSectionResponseMatch[item.ID];

      let selectedId = null;
      let updateSecId = '';
      Object.keys(datas).forEach(function (okey) {
        var data = datas[okey];
        if (item.Type === 4) {
          okey = parseInt(okey)
        }
        if (okey === response) {
          showSectionMap[data.value] = true;
          updateSecId = data.value;
          selectedId = data.value;
        } else {
          showSectionMap[data.value] = false;
          var clearResponseArr = [];
          var itemId = itemIdsForSection[data.value];

          // console.log(itemIdsForSection, 'itemIdsForSection')
          // console.log(showSectionResponseMatch, 'showSectionResponseMatch')
          // console.log(showDependentItems, 'showDependentItems')


          // console.log(itemId, 'itemId')

          clearResponseArr.push(data.value);
          if (showSectionResponseMatch[itemId] !== undefined) {
            var hideSectionOptions = showSectionResponseMatch[itemId];
            Object.keys(hideSectionOptions).forEach(function (jkey) {
              var opData = hideSectionOptions[jkey];
              if (selectedId === opData.value) {
                return;
              }
              showSectionMap[opData.value] = false;
              clearResponseArr.push(opData.value);
            });
          }
          me.clearResponseForSections(clearResponseArr);

          if (showDependentItems[item.ID] !== undefined && showDependentItems[item.ID][okey] !== undefined) {
            me.clearResponseForSectionItems(showDependentItems[item.ID][okey])
            showDependentItems[item.ID][okey].forEach(function (depItm) {
              if (showSectionResponseMatch[depItm] !== undefined) {
                var hideSectionOptions = showSectionResponseMatch[depItm];
                Object.keys(hideSectionOptions).forEach(function (jkey) {
                  var opData = hideSectionOptions[jkey];
                  if (selectedId === opData.value) {
                    return;
                  }
                  showSectionMap[opData.value] = false;
                  clearResponseArr.push(opData.value);
                });
              }
            })
          }
        }
      })

      if (updateSecId !== '') {
        showSectionMap[updateSecId] = true;
      }
    }
    this.setState({
      showSectionMap: showSectionMap
    })

  }

  updateShowSectionForNumbers(item, response) {
    if (item.Type === 16) {
      if (response !== undefined && response !== null && response !== '') {
        let newValue;
        try {
          newValue = JSON.parse(response);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '' && newValue.value !== undefined && newValue.value !== null && newValue.value !== '') {
          response = newValue.value;
        } else if (response.type !== undefined && response.type !== null && response.type !== '') {
          response = response.value;
        }
      }
    }
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    const { showDependentItems } = this.state;
    const { itemIdsForSection } = this.state;
    var me = this;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      try {
        var num_options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
        var datas = showSectionResponseMatch[item.ID];
        var checkMap = {};
        num_options.forEach(function (itm) {
          checkMap[itm.value] = {
            ...itm,
            value: parseFloat(itm.label)
          }
        })
        let resValue = parseFloat(response)
        let selectedId = null;
        Object.keys(datas).forEach(function (okey) {
          var data = datas[okey];
          if (checkMap[okey] !== undefined && checkMap[okey].operation === 1 && checkMap[okey].value === resValue) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 2 && resValue > checkMap[okey].value) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 3 && resValue >= checkMap[okey].value) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 4 && resValue < checkMap[okey].value) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 5 && resValue <= checkMap[okey].value) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else {
            showSectionMap[data.value] = false;
            var clearResponseArr = [];
            var itemId = itemIdsForSection[data.value];
            clearResponseArr.push(data.value);
            if (showSectionResponseMatch[itemId] !== undefined) {
              var hideSectionOptions = showSectionResponseMatch[itemId];
              Object.keys(hideSectionOptions).forEach(function (jkey) {
                var opData = hideSectionOptions[jkey];
                if (selectedId === opData.value) {
                  return;
                }
                showSectionMap[opData.value] = false;
                clearResponseArr.push(opData.value);
              });
            }
            me.clearResponseForSections(clearResponseArr);

            if (showDependentItems[item.ID] !== undefined && showDependentItems[item.ID][okey] !== undefined) {
              me.clearResponseForSectionItems(showDependentItems[item.ID][okey])
              showDependentItems[item.ID][okey].forEach(function (depItm) {
                if (showSectionResponseMatch[depItm] !== undefined) {
                  var hideSectionOptions = showSectionResponseMatch[depItm];
                  Object.keys(hideSectionOptions).forEach(function (jkey) {
                    var opData = hideSectionOptions[jkey];
                    if (selectedId === opData.value) {
                      return;
                    }
                    showSectionMap[opData.value] = false;
                    clearResponseArr.push(opData.value);
                  });
                }
              })
            }
          }
        })
        if (selectedId !== null) {
          showSectionMap[selectedId] = true;
        }
      } catch { }

    }

    this.setState({
      showSectionMap: showSectionMap
    })
  }

  clearResponseForSections(sectionArr) {
    const { formConfigDetails } = this.state;
    let newData = this.state.customFormData;
    var formTypeN = formConfigDetails?.type;

    var sectionName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    newData[sectionName].forEach((section, sk) => {
      if (sectionArr.indexOf(section.ID) !== -1) {
        var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
        sectionItems.forEach(function (si) {
          si.response = '';
          si.value = '';
        })
      }
    });
    this.setState({
      customFormData: newData
    })
  }

  clearResponseForSectionItems(itemArr) {
    const { formConfigDetails } = this.state;
    let newData = this.state.customFormData;
    var formTypeN = formConfigDetails?.type;

    var sectionName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    newData[sectionName].forEach((section, sk) => {
      var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
      sectionItems.forEach(function (si) {
        if (itemArr.indexOf(si.ID) !== -1) {
          si.response = '';
          si.value = '';
        }
      })
    });
    this.setState({
      customFormData: newData
    })
  }

  setSelectedTeams(team, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;
    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [team];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === team.ID)
        if (index === -1) {
          datas.push(team);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      }, () => {
        this.updateTotalAnswered()
      })
    }
  }

  setSelectedUsers(user, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;

    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [user];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === user.ID)
        if (index === -1) {
          datas.push(user);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      }, () => {
        this.updateTotalAnswered()
      })
    }
  }

  cleanErrors() {
    let { customFormData, formConfigDetails } = this.state;
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    this.state.customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        item['error'] = ''
      })
    })
    this.setState({
      customFormData: customFormData
    })
  }

  updateTotalAnswered() {
    let { customFormData, formConfigDetails, showSectionMap, showSectionCheckTypeMap } = this.state;
    var formType = formConfigDetails?.type;
    let totalAnswered = 0;
    let totalQuestions = 0;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        if (item.Type !== 17 && item.Type !== 21 && item.Type !== 22) {
          if (item.value !== undefined && item.value !== null && item.value !== '') {
            if (item.Type === 5 || item.Type === 7 || item.Type === 8 || item.Type === 10 || item.Type === 20) {
              if (item.value.length > 0) {
                totalAnswered++;
              }
            } else if (item.Type === 16) {
              if (item.value.value !== undefined && item.value.value !== null && item.value.value !== '') {
                totalAnswered++;
              }
            } else if (item.Type === 11) {
              let itemId = '#section_item' + item.ID + ' .editor-editableField';
              var bodyText = document.querySelectorAll(itemId)[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
              if (String(item.value).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Type your message here') !== -1) {
                return;
              } else {
                totalAnswered++;
              }
            } else {
              totalAnswered++;
            }
          }
        }

        if (showSectionMap[item.ID] !== undefined && showSectionMap[item.ID] === false && showSectionCheckTypeMap.item.indexOf(item.ID) !== -1) {
          return;
        } else if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
          return;
        }
        totalQuestions++;
      })
    })
    this.setState({
      totalAnswered: totalAnswered,
      totalQuestions: totalQuestions
    })
  }

  submitResponse(type) {
    let { customFormData, formConfigDetails, showSectionMap, showSectionCheckTypeMap } = this.state;

    // if (isFormEditable === false) {
    //   var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);
    //   let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
    //   this.props.history.push(redirectPath);
    //   return;
    // }

    this.setState({
      formError: '',
      saveType: type
    })

    const formId = customFormData?.ID;

    var resData = [];
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    var isRequiredFieldEmpty = false;
    var isValidationError = false;
    var validationMsg = '';

    var requireFieldNames = [];
    let firstErrorId = null;
    this.state.customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        item['error'] = '';

        if (showSectionMap[item.ID] !== undefined && showSectionMap[item.ID] === false && showSectionCheckTypeMap.item.indexOf(item.ID) !== -1) {
          return '';
        } else if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
          return '';
        }

        if (item.IsRequired && (item.value === undefined || item.value === "" || item.value === null)) {
          isRequiredFieldEmpty = true;
          requireFieldNames.push(item.ItemName);
          item.error = 'Please enter/select option.';
          if (firstErrorId === null) {
            firstErrorId = item.ID;
          }
        }

        if (item.IsRequired && item.Type === 12 && item.value !== '' && item.value !== null) {
          if (Validator.validateEmail(item.value) === false) {
            isValidationError = true;
            validationMsg = 'Please enter valid email.';
            item.error = 'Please enter valid email.';
            if (firstErrorId === null) {
              firstErrorId = item.ID;
            }
          }
        }

        if (item.IsRequired && item.Type === 14 && item.value !== '' && item.value !== null) {
          if (Validator.validateURL(item.value) === false) {
            isValidationError = true;
            validationMsg = 'Please enter valid URL.';
            item.error = 'Please enter valid URL.';
            if (firstErrorId === null) {
              firstErrorId = item.ID;
            }
          }
        }

        if ((item.value === undefined || item.value === "" || item.value === null)) {
          return;
        }

        let finalValue;
        if (item.Type === 4 || item.Type === 18) {
          finalValue = JSON.stringify(String(item.value))
        } else if (item.Type === 5) {
          // let options = item.value.map(o=>String(o))
          finalValue = JSON.stringify(item.value)
        } else {
          finalValue = JSON.stringify(item.value)
        }

        var obj = {
          itemId: item.ID,
          value: finalValue
        }
        resData.push(obj);
      })
    });

    if (isRequiredFieldEmpty) {
      var nMsg = '';
      if (requireFieldNames.length === 1) {
        nMsg += ' is required field.';
      } else {
        nMsg += ' are required fields.';
      }
      var str = requireFieldNames.join(', ');
      str = str + nMsg;
      this.setState({
        formError: str
      })
      if (firstErrorId !== null) {
        let itemId = 'section_item' + firstErrorId;
        document.getElementById(itemId).scrollIntoView({
          // behavior: 'auto',
          block: 'center',
          inline: 'center',
          behavior: 'smooth'
        });
      }
    } else if (isValidationError) {
      this.setState({
        formError: validationMsg
      })
      if (firstErrorId !== null) {
        let itemId = 'section_item' + firstErrorId;
        document.getElementById(itemId).scrollIntoView({
          // behavior: 'auto',
          block: 'center',
          inline: 'center',
          behavior: 'smooth'
        });
      }
    } else {
      this.setState({
        nextClickLoader: type === 'NEXT' ? true : false,
        nextClickLoaderSave: type === 'SAVE' ? true : false
      })

      console.log(resData, 'resData')
      this.props.addCustomFormResponse(this.props.evaluation_id, formId, {
        form_type: formType,
        data: resData,
        componentId: parseInt(this.props.workflowComponentId)
      })
    }
  }

  deleteFormColloborator() {
    const { formColloborator } = this.state;
    console.log(formColloborator, 'formColloborator')
    this.props.deleteComponentColloborator(this.props.evaluation_id, this.props.workflowComponentId, {
      type: 'User',
      entity_id: formColloborator[0].ID
    })
    this.props.hideSearchUsersPoperDialog();
  }

  addFormColloborator() {
    const { formColloborator } = this.state;
    this.props.addComponentColloborator(this.props.evaluation_id, this.props.workflowComponentId, {
      type: 'User',
      entity_id: formColloborator[0].ID
    })
    this.props.hideSearchUsersPoperDialog();
  }

  copyLink() {
    navigator.clipboard.writeText(`${window.location.origin}/evaluation/${this.props.match.params.evaluation_id}/component/${this.props.workflowComponentId}/${this.state.form_type}/${this.state.form_id}/o_id/${this.props.OrgID}`)
    this.props.showSnackBar('link copied to clipboard', 'success', 3000)
  }

  render() {
    const classes = this.props.classes;
    let { customFormTitle, customFormDescription, customFormData,
      formConfigDetails, isShowLoader, showSectionMap,
      showSectionCheckTypeMap, isFormEditable, totalQuestions,
      totalAnswered, formColloborator, isCopyForPublic } = this.state;

    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    if (isShowLoader) {
      return <div className={classes.loader}><CircularProgress /></div>
    }

    let zIndexForItems = 0;

    // console.log(customFormData,'customFormData')

    return <>
      {this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation.Type !== 3 && <EvaluationBar
        isShowCollabrations={false}
        isShowEvalDetails={false}
        isWorkFlow={this.props.isWorkFlow}
        isShowViewBtns={false}
      />}
      <div className={classnames(classes.topRoot)}>
        <div className={classes.root}>
          <div classes={classes.dialogContent} >
            <div className={classes.customForm}>
              <div className={classes.titleFlex}>
                <div className={classes.titlePart}>
                  <Typography variant={"h4"} className={classes.stepHead}>{customFormTitle}</Typography>
                </div>
                <div className={classes.progressPart}>
                  <Typography variant={"h4"} className={classes.stepHead2}>{totalAnswered} / {totalQuestions} Answered</Typography>
                  {isCopyForPublic && <Button
                    color="primary"
                    className={classes.copyText}
                    startIcon={<ShareIcon />}
                    onClick={() => {
                      this.copyLink()
                    }}
                  >
                    Share
                  </Button>}
                </div>
              </div>
              <span className={classes.stepSubHead}>{customFormDescription}</span>
              <div className={classes.assignedTo}>
                <label className={classes.label}>Assigned To</label>
                {formColloborator !== undefined && formColloborator !== null && formColloborator !== '' && <div>
                  <AvatarGroup max={3}>
                    {formColloborator.map((user, k) => {
                      return <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>
                        {user.Name + "\n" + user.Email}
                      </span>} arrow>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar style={{ zIndex: 0, backgroundColor: user.type === "GROUP" ? "#62a00b" : "#bdbdbd" }} key={k}> {this.avatarName(user.Name)}</Avatar>
                          &nbsp;&nbsp;<Image
                            className={classnames(classes.addUser, 'evaluate-add-user')}
                            alt="add user"
                            src={deleteIcon}
                            onClick={(event) => {
                              this.deleteFormColloborator()
                            }} />
                        </span>
                      </Tooltip>
                    })}
                  </AvatarGroup>
                </div>}
                {(formColloborator.length <= 0 || formColloborator === '') && <div>
                  <Image className={classnames(classes.addUser, 'evaluate-add-user')} alt="add user" src={addUsers} onClick={(event) => {
                    this.setState({
                      currentSelected: formColloborator,
                      isForFormColloborator: true
                    }, () => {
                      this.props.showSearchUsersPoperDialog();
                    })
                  }} />
                </div>}
              </div>
              <div className={classes.topHeadColor}></div>
            </div>
            {false && <div className={classes.alert}>
              {this.state.formError.length > 0 && <Alert variant="filled" severity="error">{this.state.formError}</Alert>}
            </div>}



            <div className={isFormEditable === false ? '' : ''}>
              {customFormData !== null && customFormData[sectionName].map((section, sk) => {
                var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
                sectionItems = sectionItems.sort(function (a, b) {
                  if (a.Seq > b.Seq) {
                    return 1;
                  } else if (a.Seq < b.Seq) {
                    return -1
                  } else {
                    return 0;
                  }
                })

                let isHideSection = false;
                sectionItems.forEach((item, key) => {
                  if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                    isHideSection = true;
                  }
                })

                if (sectionItems.length <= 0) {
                  return null
                }

                return <>
                  {!isHideSection && section?.SectionName !== '' && <div className={classes.sectionBox}>
                    <Typography variant={"h4"} className={classes.sectionName}>{section?.SectionName}</Typography>
                    <span className={classes.stepSubHead}>{section?.Description}</span>
                  </div>}
                  <div className={classes.section} key={sk}>
                    {sectionItems.map((item, key) => {
                      if (showSectionMap[item.ID] !== undefined && (showSectionMap[item.ID] === false && showSectionCheckTypeMap?.item.indexOf(item.ID) !== -1)) {
                        return '';
                      } else if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                        return '';
                      }

                      if (item.Type === 1) {
                      }
                      var options = [];
                      try {
                        options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                      } catch { }

                      if (item.Type === 17 && (this.props.similarExistingProducts === undefined || this.props.similarExistingProducts === null || Object.keys(this.props.similarExistingProducts).length <= 0)) {
                        return ''
                      }

                      zIndexForItems++;
                      let newZIndex = ((totalQuestions + 10) - zIndexForItems)


                      return <><div className={classes.sectionItem} key={item.SectionID + '_section' + key} id={'section_item' + item.ID}>
                        <CustomReply
                          additional_data={options}
                          type={item.Type}
                          title={item.ItemName}
                          description={item.Description}
                          isRequired={item?.IsRequired}
                          key={item.ID + '_sectionItem'}
                          index={item.ID}
                          value={(item?.value !== undefined && item?.value !== null) ? item.value : ''}
                          selectedOption={[]}
                          evaluationId={this.props.match.params.evaluation_id}
                          orgId={this.props.OrgID}
                          workflowId={this.props.workflow_id}
                          isFormEditable={true}
                          itemId={item.ID}
                          sectionId={item.SectionID}
                          item={item}
                          error={item.error || ''}
                          isOtherOption={item?.IsOtherOption}
                          otherOptionData={item?.OtherOptionData}
                          webHookItemIdMap={null}
                          zIndexForItem={newZIndex}
                          decisionItemMap={this.state.isCheckDecisionItems ? this.state.decisionItemMap : []}
                          onChange={(response, isOther = false) => {
                            item['value'] = response;
                            if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                              this.updateShowSection(item, response);
                            }
                            if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                              this.updateShowSectionForNumbers(item, response);
                            }
                            this.cleanErrors();
                            this.updateTotalAnswered();
                          }}
                          showUserAddDialog={(item) => {
                            this.setState({
                              currentSelected: item,
                              isForFormColloborator: false
                            }, () => {

                            })
                            this.props.showSearchUsersPoperDialog()
                          }}
                          showTeamAddDialog={(data) => {
                            this.setState({
                              currentSelected: item
                            }, () => {
                              this.props.showSearchTeamsPoperDialog()
                            })
                          }}
                        // similarExistingProducts={this.props.similarExistingProducts}
                        // similarExistingProductProgress={this.props.similarExistingProductProgress}
                        />
                      </div>
                      </>
                    })}

                  </div>
                </>
              })}
            </div>
            <BottomActions
              user={this.props.user}
              isCreatorOrAdmin={this.props.isCreatorOrAdmin}
              adminForward={this.props.adminForward}
              submitResponse={this.submitResponse}
              nextClickLoaderSave={this.state.nextClickLoaderSave}
              nextClickLoader={this.state.nextClickLoader}
              isFormEditable={isFormEditable}
            />
          </div>
        </div>

        {this.props.isOpenTeamDialog && <SearchTeamsPoper
          selectedTeams={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
          onAdd={(team) => {
            this.setSelectedTeams({
              id: team.ID,
              ID: team.ID,
              value: team.ID,
              label: team.Name,
              Name: team.Name,
              Email: team.Email,
            }, true)
          }}
          onRemove={(team) => {
            this.setSelectedTeams({
              id: team.ID,
              ID: team.ID,
              value: team.ID,
              label: team.Name,
              Name: team.Name,
              Email: team.Email,
            }, true)
          }}
          onClose={() => {
            this.props.hideSearchTeamsPoperDialog();
          }}
        />}

        {this.props.isOpenUserDialog && <SearchUserPoper
          selectedUsers={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
          onAdd={(user) => {
            if (this.state.isForFormColloborator) {
              this.setState({
                formColloborator: [{
                  id: user.ID,
                  ID: user.ID,
                  value: user.ID,
                  label: user.Name,
                  Name: user.Name,
                  Email: user.Email,
                }]
              }, () => {
                this.addFormColloborator()
              })
            } else {
              this.setSelectedUsers({
                id: user.ID,
                ID: user.ID,
                value: user.ID,
                label: user.Name,
                Name: user.Name,
                Email: user.Email,
              }, true)
            }
          }}
          onRemove={(user) => {
            if (this.state.isForFormColloborator) {
              this.deleteFormColloborator()
            } else {
              this.setSelectedUsers({
                id: user.ID,
                ID: user.ID,
                value: user.ID,
                label: user.Name,
                Name: user.Name,
                Email: user.Email,
              }, true)
            }
          }}
          onClose={() => {
            this.props.hideSearchUsersPoperDialog();
          }}
        />}

      </div>
    </>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomForm));