/* eslint-disable no-useless-escape */
import { Backdrop, CircularProgress, CssBaseline } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from "classnames";
import React from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "recompose";
// import { getConfigValue } from "util/Common";
import { fetchAllOrgSettings } from "redux/org/user/action";
import { authorizedUser, logout } from "../../redux/authuser/action";
import { updateDrawerStyle } from "../../redux/drawer/action";
import Home from "./Components/Home/index";
import Evaluate from "./Components/Evaluate/index";
import { isEnabledProjectTracking } from "util/Common";
import Products from "./Components/Products";
import CommonCss from "commonCss";
// import Templates from "./Components/Templates";
import Drawer from "./Drawer";
import DrawerV2 from "./DrawerV2";
import Snackbar from "Components/Common/Snackbar";
import Library from './Components/Library';
import ImporatData from "./Components/ImportData"
import CompanyProfile from './Components/CompanyProfile';
import Settings from './Components/Settings';
import { getNewPlatform } from 'util/Environment';

const connectedProps = (state) => ({
    authProgress: state.authUser.authProgress,
    authError: state.authUser.authError,
    user: state.authUser.user,
    orgUser: state.orgUser,
    organization: state.authUser.user?.Organization,
    fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
});

const connectionActions = {
    authorizedUser: authorizedUser,
    logout: logout,
    fetchAllOrgSettings: fetchAllOrgSettings,
    updateDrawerStyle: updateDrawerStyle
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        paddingTop: '5.25rem !important',
        flexGrow: 1,
        padding: theme.spacing(4, 10),
        background: "#F7F7F7",
        minHeight: "100vh",
        '@media (max-width: 991.98px)': {
            padding: '2em 2em 2em 8em'
        }
    },
    content2: {
        paddingTop: '5.25rem !important',
        flexGrow: 1,
        background: "#F7F7F7",
        minHeight: "100vh"
    },
    fullSceen: CommonCss.backdrop,
});

class SellerApplication extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.props.authorizedUser();
        this.props.fetchAllOrgSettings();
        this.props.updateDrawerStyle(true, true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.authProgress === false && prevProps.authProgress === true) {
            if (this.props.authError !== null) {
                var error = this.props.authError;
                if (error.message.indexOf("Token") !== -1) {
                    //LOGOUT
                    this.props.logout();
                    this.props.history.push("/")
                } else {
                    this.props.logout();
                    this.props.history.push("/")
                }
            } else {
                if (this.props.user == null) {
                    this.props.logout();
                    this.props.history.push("/")
                } else {
                    const IsInitialFlow = this.props.user.IsInitialFlow;
                    if (!IsInitialFlow) {
                        this.props.history.push("/sellerapp")
                    }
                }
            }
        }
        if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
            const EnableVendorCatalogV2 = isEnabledProjectTracking(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableVendorCatalogV2");
            if (EnableVendorCatalogV2 && (window?.location?.pathname === "/sellerapp" || window?.location?.pathname === "/sellerapp/home")) {
                let link = `${getNewPlatform()}/sellerapp/home`
                window.open(link, "_self");
            } else {
                this.setState({ loading: false })
            }
        }
    }

    
    render() {

        const classes = this.props.classes;
        if(this.state.loading){
            return <Backdrop className={classes.fullSceen} open={true} transitionDuration={100}>
                <CircularProgress />
            </Backdrop>
        }
        var isShowPadding = false;

        if (this.props.user == null) {
            return null;
        }

        const hidePadding = [
            '\/sellerapp\/evaluate\/.+\/[0-9]+\/[0-9]+',
            '\/sellerapp\/library'
        ]
        const preg_match = (regex, str) => {
            return (new RegExp(regex).test(str))
        }
        for (let regex of hidePadding) {
            if (preg_match(regex, this.props.location.pathname)) {
                isShowPadding = false;
            }
        }

        return <div className={classes.root}>
            <CssBaseline />
            {/* <Drawer /> */}
            <DrawerV2 {...this.props} />
            <div className={classnames('applicationContent', isShowPadding ? [classes.content] : [classes.content2]
            )}>
                <Switch>
                    <Route exact path={`${this.props.match.path}/home`} component={Home} />
                    {/* <Route path={`${this.props.match.path}/products`} component={Products} /> */}
                    <Route path={`${this.props.match.path}/library`} component={Library} />
                    <Route path={`${this.props.match.path}/import`} component={ImporatData} />
                    <Route path={`${this.props.match.path}/assessment`} component={Evaluate} />
                    <Route path={`${this.props.match.path}/profile`} component={CompanyProfile} />
                    <Route path={`${this.props.match.path}/settings`} component={Settings} />
                    <Redirect exact path={this.props.match.path} to={`${this.props.match.path}/home`} />
                </Switch>
            </div>
            <Snackbar />
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(SellerApplication));