import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    logoimage: {
      textAlign: "center",
    },
    menu: {
      width: "90%",
      margin: "0 auto",
      marginTop: theme.spacing(5),
    },
    sub_menu: {
      marginLeft: 20
    },
    menu_item: {
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(1.2),
      "&:hover": {
        background: "#a3dcfc33",
        borderRadius: theme.spacing(1.2),
        // "& $menu_item_text": {
        //     color: "#49B8FA",
        // },
        // "& $circle": {
        //     background: "#49B8FA",
        // }
      },
    },
    menu_item_text: {
      color: "#91919C",
    },
    menu_item_icon: {
      minWidth: theme.spacing(6),
    },
    active_menu_item: {
      background: "#a3dcfc33",
      borderRadius: theme.spacing(1.2),
    },
    active_circle: {
      background: "#49B8FA",
    },
    active_menu_item_text: {
      color: "#4A87F8",
    },
    compressed: {
      padding: theme.spacing(2, 4),
    },
    compressedIcon: {
      textAlign: "center",
      margin: "0 auto",
      minWidth: "auto",
      "& img": {
        margin: "0 auto",
      },
      "& svg": {
        margin: "0 auto",
      },
    },
    logoImage: {
      width: 44,
    },
    logoimagesrc: {
      width: "30px !important",
    },
    appDrawer: {
      position: 'relative',
      '&:hover': {
        '& .toggleArrow': {
          opacity: 1,
          transition: 'opacity .5s',
          '-webkit-transition': 'opacity .5s'
        }
      },
      '@media (max-width: 991.98px)': {
        position: 'absolute'
      }
    },
    btnToggle: {
      opacity: 0,
      // transition: 'opacity 5s',
      // '-webkit-transition': 'opacity 5s',
      position: 'fixed',
      width: '30px',
      height: '30px',
      background: '#ffffff',
      // display: 'inline-block',
      border: '2px solid #202639',
      zIndex: 99999,
      borderRadius: '50%',
      flexDirection: 'row',
      boxSizing: 'border-box',
      display: 'flex',
      placeContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&.max': {
        left: '228px',
      },
      '&.min': {
        left: '102px',
        '& svg': {
          marginLeft: '3px'
        }
      },
      '& svg': {
        width: '17px',
        height: '17px',
        marginLeft: '7px'
      },
      '@media (max-width: 991.98px)': {
        '&.min': {
          left: '68px',
          opacity: 1
        },
        '&.max': {
          opacity: 1
        }
      }
    },
    helpButton: {
      backgroundColor: "#1f73b7 !important",
      color: "#FFFFFF !important",
      fill: "#FFFFFF !important",
      padding: "0.52857rem 1.57143rem",
      borderRadius: "999rem",
      letterSpacing: "0.6",
      fontSize: "1.07143rem",
      textDecoration: "none",
      position: "fixed",
      bottom: "10px",
      left: "5px",
      '& span': {
        textDecoration: "none",
      },
    },
    activeExpandIcon: {
      color: '#1f73b7'
    },
    inActiveExpandIcon: {
      color: '#91919c'
    }
  });

class DrawerListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidMount() {
    const { item, active } = this.props;
    if (item.childerens !== undefined && item.childerens !== null && item.childerens.length > 0 && active) {
      this.setState({
        open: true
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props;
    if (active !== prevProps.active) {
      if (active) {
        this.setState({
          open: true
        })
      } else {
        this.setState({
          open: false
        })
      }
    }
  }

  render() {
    const classes = this.props.classes;
    const { item, active, key, expanded, hideMenus } = this.props;
    const { open } = this.state;

    var location = this.props.location.pathname;

    let isShowIcon = true;
    if (hideMenus.indexOf('Evaluate') !== -1 && hideMenus.indexOf('Review') !== -1 && hideMenus.indexOf('Survey') !== -1 && hideMenus.indexOf('Other') !== -1) {
      isShowIcon = false;
    }
    // console.log(item, 'Itmmmmmmmmmmmmsss')
    return (
      <>
        <ListItem
          button
          key={key}
          className={classnames(classes.menu_item, {
            [classes.active_menu_item]: active, [classes.compressed]: !expanded,
          })}
          onClick={() => {
            if (item.childerens !== undefined && item.childerens !== null && item.childerens.length > 0) {
              if (isShowIcon) {
                this.setState({
                  open: this.state.open ? false : true
                }, () => {
                  this.props.onClick(item.secondaryLink);
                })
              } else {
                this.props.onClick(item.secondaryLink);
              } 
            } else {
              this.props.onClick(item.link);
            }
          }}
        >
          {<ListItemIcon
            className={classnames({
              [classes.compressedIcon]: !expanded,
            })}
          >

            {!this.props.expanded && <Tooltip title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{item.label}</span>} placement="right">
              {active ? item.active_icon : item.inactive_icon}
            </Tooltip>}

            {this.props.expanded &&
              <>
                {active ? item.active_icon : item.inactive_icon}
              </>
            }
          </ListItemIcon>}

          {this.props.expanded && (
            <ListItemText
              primary={item.label}
              classes={{
                primary: classnames(classes.menu_item_text, { [classes.active_menu_item_text]: active, }),
              }}
            />
          )}

          {isShowIcon && item.childerens !== undefined && item.childerens !== null && item.childerens.length > 0 && <>
            {open ? <ExpandLess className={active ? classes.activeExpandIcon : classes.inActiveExpandIcon} /> : <ExpandMore className={active ? classes.activeExpandIcon : classes.inActiveExpandIcon} />}
          </>}

        </ListItem>
        {isShowIcon && item.childerens !== undefined && item.childerens !== null && item.childerens.length > 0 &&
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List className={classes.sub_menu}>
              {item.childerens.map((citm) => {
                var activeSubItem = null;
                var isCheckForTypes = false;
                let activeSubMenu = false;
                if (hideMenus.indexOf(citm.label) !== -1) {
                  return null;
                }
                if (location.indexOf('/workflow') !== -1 && location.indexOf('/component') !== -1 && this.props.evaluation !== undefined && this.props.evaluation !== null) {
                  if (this.props.evaluation?.Type === 1) {
                    activeSubItem = "/app/evaluate";
                  } else if (this.props.evaluation?.Type === 2) {
                    activeSubItem = "/app/review";
                  } else if (this.props.evaluation?.Type === 3) {
                    activeSubItem = "/app/survey";
                  } else if (this.props.evaluation?.Type === 4) {
                    activeSubItem = "/app/renewal_workflows";
                  } else if (this.props.evaluation?.Type === 10) {
                    activeSubItem = "/app/other_workflows";
                  }
                  isCheckForTypes = true;
                }

                if (isCheckForTypes === true && citm.link === activeSubItem) {
                  activeSubMenu = true;
                }

                if (isCheckForTypes === false && location.indexOf(citm.link) !== -1) {
                  activeSubMenu = true;
                }

                return <ListItem
                  button
                  key={key + citm.label}
                  className={classnames(classes.menu_item, {
                    [classes.active_menu_item]: activeSubMenu, [classes.compressed]: !expanded,
                  })}
                  onClick={() => {
                    this.props.onClick(citm.link);
                  }}
                >
                  <ListItemIcon
                    className={classnames({
                      [classes.compressedIcon]: !expanded,
                    })}
                  >
                    {!this.props.expanded && <Tooltip title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{citm.label}</span>} placement="right">
                      {activeSubMenu ? citm.active_icon : citm.inactive_icon}
                    </Tooltip>}

                    {this.props.expanded &&
                      <>
                        {activeSubMenu ? citm.active_icon : citm.inactive_icon}
                      </>
                    }
                  </ListItemIcon>

                  {this.props.expanded && (
                    <ListItemText
                      primary={citm.label}
                      classes={{
                        primary: classnames(classes.menu_item_text, { [classes.active_menu_item_text]: activeSubMenu, }),
                      }}
                    />
                  )}
                </ListItem>
              })}
            </List>
          </Collapse>
        }</>
      // </Tooltip>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(DrawerListItem));
