import React from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scaleShowValues: true,
  cornerRadius: 20,
  plugins: {
    datalabels: {
      display: false,
      render: () => { }
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
        barPercentage: 0.45,
        ticks: {
          font: {
            size: 12,
            weight: 'bold',
          },
          maxRotation: 45,
          minRotation: 45,
        },
        grid: {
          display: false,
        }
      },
    y: {
        ticks: {
          padding: 5,
          font: {
            size: 12,
            weight: 'bold',
          },
        },
        min: 0,
        max: 3,
        precision: 0.3,
        grid: {
          display: true,
        }
      },
  },
};

const getBackgroundColor = (score) => {
  let color = '#cb3837'
  if (score >= 2) color = '#63ac67'
  else if (score >= 1) color = '#fec93e'
  return color
}

const ScoreAnalysisChart = ({dataArr}) => {
  const getChartData = () => {
    var data = [];
    var labels = [];
    var backgroundColor = [];
    dataArr?.forEach(item=>{
      if(item.isChecked){
        labels.push(item.name);
        data.push(item.overall_score?.toFixed(2));
        backgroundColor.push(getBackgroundColor(item.overall_score));
      }  
    })

    const chartdata = {
      labels: labels,
      datasets: [
        {
          label: "",
          fill: true,
          maxBarThickness: 50,
          barWidth: 1,
          backgroundColor,
          data,
        },
      ],
    };

    return chartdata;
  };

  return <Bar height={100} data={getChartData()} options={options} />;
};

export default React.memo(ScoreAnalysisChart);
