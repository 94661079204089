import { CircularProgress } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { fetchEvaluationMetaData } from "../../../../../../../redux/evaluation/metadata/action";
const connectedProps = (state) => ({
    evaluation: state.evaluationMetaData.evaluation,
    fetchProgress: state.evaluationMetaData.fetchProgress
});

const connectionActions = {
    fetchEvaluationMetaData: fetchEvaluationMetaData,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        padding: theme.spacing(50, 0),
        textAlign: "center"
    }
});

class Splash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.fetchEvaluationMetaData(this.props.match.params.evaluation_id)
    }

    componentDidUpdate(prevProps) {
        if (this.props.fetchProgress === false && prevProps.fetchProgress) {
            if (this.props.evaluation !== null && (this.props.match.params.workflow_id === undefined || this.props.match.params.workflow_id === null)) {
                if (this.props.evaluation.WorkflowID !== null && this.props.evaluation.WorkflowID !== '') {
                    let redirectPath = `/app/evaluate/${this.props.evaluation?.ID}/workflow/interim`;
                    this.props.history.push(redirectPath);
                } else {
                    switch (this.props.evaluation.EvaluationUserStatus) {
                        case 1:
                            this.props.history.push(`/app/evaluate`)
                            break
                        case 2:
                            this.props.history.replace(`/app/evaluate/${this.props.evaluation.ID}/setup`)
                            break
                        case 3:
                            this.props.history.replace(`/app/evaluate/${this.props.evaluation.ID}/respond`)
                            break
                        case 4:
                        case 5:
                            this.props.history.replace(`/app/evaluate/${this.props.evaluation.ID}/summary`)
                            break
                        default:
                            this.props.history.push("/app/evaluate")
                            break
                    }
                }
            } else {
                this.props.history.push("/app/evaluate")
            }
        }
    }
    render() {
        const classes = this.props.classes;

        return <div className={classes.root}>
            <CircularProgress />
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Splash));