import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";

export const START_VENDOR_CATALOG_FETCH = "START_VENDOR_CATALOG_FETCH";
export const END_VENDOR_CATALOG_FETCH = "END_VENDOR_CATALOG_FETCH";

export const START_VISUALIZATION_VENDOR_CATALOG_FETCH = "START_VISUALIZATION_VENDOR_CATALOG_FETCH";
export const END_VISUALIZATION_VENDOR_CATALOG_FETCH = "END_VISUALIZATION_VENDOR_CATALOG_FETCH";

export const START_VENDOR_CATALOG_TAB_DATA_FETCH = "START_VENDOR_CATALOG_TAB_DATA_FETCH";
export const END_VENDOR_CATALOG_TAB_DATA_FETCH = "END_VENDOR_CATALOG_TAB_DATA_FETCH";

export const SHOW_ADD_VENDOR_DIALOG = "SHOW_ADD_VENDOR_DIALOG";
export const HIDE_ADD_VENDOR_DIALOG = "HIDE_ADD_VENDOR_DIALOG";

export const START_ORG_VENDOR_ADD = "START_ORG_VENDOR_ADD";
export const END_ORG_VENDOR_ADD = "END_ORG_VENDOR_ADD";

export const START_DELETE_ORG_VENDOR = "START_DELETE_ORG_VENDOR";
export const END_DELETE_ORG_VENDOR = "END_DELETE_ORG_VENDOR";

export const START_UPDATE_ORG_VENDOR_DETAILS = "START_UPDATE_ORG_VENDOR_DETAILS";
export const END_UPDATE_ORG_VENDOR_DETAILS = "END_UPDATE_ORG_VENDOR_DETAILS";

export const START_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA = "START_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA";
export const END_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA = "END_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA";

//catalog builder
export const START_FETCH_ORG_CATALOG_TABS = "START_FETCH_ORG_CATALOG_TABS";
export const END_FETCH_ORG_CATALOG_TABS = "END_FETCH_ORG_CATALOG_TABS";

export const START_FETCH_ORG_CATALOG_BUILDER_TABS = "START_FETCH_ORG_CATALOG_BUILDER_TABS";
export const END_FETCH_ORG_CATALOG_BUILDER_TABS = "END_FETCH_ORG_CATALOG_BUILDER_TABS";

export const START_FETCH_EXT_ORG_CATALOG_BUILDER_TABS = "START_FETCH_EXT_ORG_CATALOG_BUILDER_TABS";
export const END_FETCH_EXT_ORG_CATALOG_BUILDER_TABS = "END_FETCH_EXT_ORG_CATALOG_BUILDER_TABS";

export const START_ADD_ORG_VENDOR_BUILDER_TAB = "START_ADD_ORG_VENDOR_BUILDER_TAB";
export const END_ADD_ORG_VENDOR_BUILDER_TAB = "END_ADD_ORG_VENDOR_BUILDER_TAB";
export const START_UPDATE_ORG_VENDOR_BUILDER_TAB = "START_UPDATE_ORG_VENDOR_BUILDER_TAB";
export const END_UPDATE_ORG_VENDOR_BUILDER_TAB = "END_UPDATE_ORG_VENDOR_BUILDER_TAB";
export const START_DELETE_ORG_VENDOR_BUILDER_TAB = "START_DELETE_ORG_VENDOR_BUILDER_TAB";
export const END_DELETE_ORG_VENDOR_BUILDER_TAB = "END_DELETE_ORG_VENDOR_BUILDER_TAB";

export const START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION = "START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION";
export const END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION = "END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION";
export const START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION = "START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION";
export const END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION = "END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION";
export const START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION = "START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION";
export const END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION = "END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION";

export const START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM = "START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM";
export const END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM = "END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM";
export const START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM = "START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM";
export const END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM = "END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM";
export const START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM = "START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM";
export const END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM = "END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM";

export const START_ORG_CATALOG_STATE_UPDATE = "START_ORG_CATALOG_STATE_UPDATE";
export const END_ORG_CATALOG_STATE_UPDATE = "END_ORG_CATALOG_STATE_UPDATE";

export const START_FETCH_SECTION_EXT_USERS = "START_FETCH_SECTION_EXT_USERS";
export const END_FETCH_SECTION_EXT_USERS = "END_FETCH_SECTION_EXT_USERS";

export const START_FETCH_TEAMS_DATA = "START_FETCH_TEAMS_DATA";
export const END_FETCH_TEAMS_DATA = "END_FETCH_TEAMS_DATA";

export const START_FETCH_CONTRACT_DATA = "START_FETCH_CONTRACT_DATA";
export const END_FETCH_CONTRACT_DATA = "END_FETCH_CONTRACT_DATA";

export const START_FETCH_VISUALIZATION_CONTRACT_DATA = "START_FETCH_VISUALIZATION_CONTRACT_DATA";
export const END_FETCH_VISUALIZATION_CONTRACT_DATA = "END_FETCH_VISUALIZATION_CONTRACT_DATA";

function startFetchContractData() {
  return {
    type: START_FETCH_CONTRACT_DATA,
    payload: null,
  };
}

function endFetchContractData(success, error) {
  return {
    type: END_FETCH_CONTRACT_DATA,
    payload: {
      success,
      error,
    },
  };
}

export function fetchContractData(data) {
  const api_server = Environment.api_host("PRODUCT");
  const timestamp = new Date().getTime();
  const url = `${api_server}/contracts?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchContractData());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url,data);
      dispatch(endFetchContractData(response, error));
    } catch (e) {
      dispatch(
        endFetchContractData(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchVisualizationContractData(id) {
  return {
    type: START_FETCH_VISUALIZATION_CONTRACT_DATA,
    payload: {id},
  };
}

function endFetchVisualizationContractData(success, error,id) {
  return {
    type: END_FETCH_VISUALIZATION_CONTRACT_DATA,
    payload: {
      success,
      error,
      id
    },
  };
}

export function fetchVisualizationContractData(data,id) {
  const api_server = Environment.api_host("PRODUCT");
  const timestamp = new Date().getTime();
  const url = `${api_server}/contracts?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchVisualizationContractData(id));
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url,data);
      dispatch(endFetchVisualizationContractData(response, error,id));
    } catch (e) {
      dispatch(
        endFetchVisualizationContractData(null, {
          message: e.message,
        },id)
      );
      return;
    }
  };
}

function startFetchTeamsData() {
  return {
    type: START_FETCH_TEAMS_DATA,
    payload: null,
  };
}

function endFetchTeamsData(success, error) {
  return {
    type: END_FETCH_TEAMS_DATA,
    payload: {
      success,
      error,
    },
  };
}

export function fetchTeamsData(teams) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/builer/team_info?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchTeamsData());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url,teams);
      dispatch(endFetchTeamsData(response, error));
    } catch (e) {
      dispatch(
        endFetchTeamsData(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}


function startCatalogStateUpdate() {
  return {
    type: START_ORG_CATALOG_STATE_UPDATE,
    payload: {},
  };
}

function endCatalogStateUpdate(success, error) {
  return {
    type: END_ORG_CATALOG_STATE_UPDATE,
    payload: {
      success,
      error,
    },
  };
}

export function updateOrgCatalogState(type,id,slug,data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  let url = `${api_server}/entity/${type}/${id}/${slug}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCatalogStateUpdate());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url,data, true);
      dispatch(endCatalogStateUpdate(response, error));
    } catch (e) {
      dispatch(
        endCatalogStateUpdate(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}


function startDeleteOrgVendor() {
  return {
    type: START_DELETE_ORG_VENDOR,
    payload: null,
  };
}

function endDeleteOrgVendor(success, error) {
  return {
    type: END_DELETE_ORG_VENDOR,
    payload: {
      success,
      error,
    },
  };
}

export function deleteOrgVendor(id) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendor/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startDeleteOrgVendor());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteOrgVendor(response, error));
    } catch (e) {
      dispatch(
        endDeleteOrgVendor(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export function showAddVendorDialog() {
  return {
    type: SHOW_ADD_VENDOR_DIALOG,
    payload: {},
  };
}

export function hideAddVendorDialog() {
  return {
    type: HIDE_ADD_VENDOR_DIALOG,
    payload: {},
  };
}

function startVendorCatalogFetch(searchQuery) {
  return {
    type: START_VENDOR_CATALOG_FETCH,
    payload: {
      searchQuery,
    },
  };
}

function endVendorCatalogFetch(searchQuery, success, error) {
  return {
    type: END_VENDOR_CATALOG_FETCH,
    payload: {
      searchQuery,
      success,
      error,
    },
  };
}

export function fetchVendorCatalog(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendor/search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startVendorCatalogFetch(data));
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endVendorCatalogFetch(data, response, error));
    } catch (e) {
      dispatch(
        endVendorCatalogFetch(data, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startVisualizationVendorCatalogFetch(id) {
  return {
    type: START_VISUALIZATION_VENDOR_CATALOG_FETCH,
    payload: {id},
  };
}

function endVisualizationVendorCatalogFetch(success, error,id) {
  return {
    type: END_VISUALIZATION_VENDOR_CATALOG_FETCH,
    payload: {
      success,
      error,
      id,
    },
  };
}

export function fetchVisualizationVendorCatalog(data,id) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendor/search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startVisualizationVendorCatalogFetch(id));
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endVisualizationVendorCatalogFetch(response, error,id));
    } catch (e) {
      dispatch(
        endVisualizationVendorCatalogFetch(null, {
          message: e.message,
        },id)
      );
      return;
    }
  };
}

function startVendorCatalogTabDataFetch() {
  return {
    type: START_VENDOR_CATALOG_TAB_DATA_FETCH,
    payload: {},
  };
}

function endVendorCatalogTabDataFetch(success, error) {
  return {
    type: END_VENDOR_CATALOG_TAB_DATA_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchVendorCatalogTabData(vendorId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendor/org/${vendorId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startVendorCatalogTabDataFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, true);
      dispatch(endVendorCatalogTabDataFetch(response, error));
    } catch (e) {
      dispatch(
        endVendorCatalogTabDataFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateOrgVendorDetails() {
  return {
    type: START_UPDATE_ORG_VENDOR_DETAILS,
    payload: null,
  };
}

function endUpdateOrgVendorDetails(success, error) {
  return {
    type: END_UPDATE_ORG_VENDOR_DETAILS,
    payload: {
      success,
      error,
    },
  };
}

export function updateOrgVendorDetails(id, params) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  let url = `${api_server}/entity/vendor/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateOrgVendorDetails(id));
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, params);
      dispatch(endUpdateOrgVendorDetails(response, error));
    } catch (e) {
      dispatch(
        endUpdateOrgVendorDetails(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateOrgVendorTabSectionItemData() {
  return {
    type: START_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA,
    payload: null,
  };
}

function endUpdateOrgVendorTabSectionItemData(item_slug, success, error) {
  return {
    type: END_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA,
    payload: {
      success,
      error,
      item_slug,
    },
  };
}

export function updateOrgVendorTabSectionItemData(id, slug, data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  let url = `${api_server}/entity/vendor/${id}/${slug}?t=${timestamp}`;
  // let url = `${api_server}/tools/edit-item/vendor/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateOrgVendorTabSectionItemData(id));
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endUpdateOrgVendorTabSectionItemData(slug, response, error));
    } catch (e) {
      dispatch(
        endUpdateOrgVendorTabSectionItemData(slug, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddOrgVendor() {
  return {
    type: START_ORG_VENDOR_ADD,
    payload: null,
  };
}

function endAddOrgVendor(success, error) {
  return {
    type: END_ORG_VENDOR_ADD,
    payload: {
      success,
      error,
    },
  };
}

export function addOrgVendor(data) {
  return async (dispatch) => {
    dispatch(startAddOrgVendor());
    try {
      const api_server = Environment.api_host("CATALOG");
      const timestamp = new Date().getTime();
      const url = `${api_server}/entity/vendor?t=${timestamp}`;
      let [response, error] = await HttpFactory.instance().postMethod(url, data);
      dispatch(endAddOrgVendor(response, error));
    } catch (e) {
      dispatch(
        endAddOrgVendor(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchOrgCatalogAllTabs() {
  return {
    type: START_FETCH_ORG_CATALOG_TABS,
    payload: null,
  };
}

function endFetchOrgCatalogAllTabs(success, error) {
  return {
    type: END_FETCH_ORG_CATALOG_TABS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchOrgCatalogAllTabs(type) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/builder/${type}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchOrgCatalogAllTabs());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchOrgCatalogAllTabs(response, error));
    } catch (e) {
      dispatch(
        endFetchOrgCatalogAllTabs(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchOrgCatalogDetailTabs() {
  return {
    type: START_FETCH_ORG_CATALOG_BUILDER_TABS,
    payload: null,
  };
}

function endFetchOrgCatalogDetailTabs(success, error) {
  return {
    type: END_FETCH_ORG_CATALOG_BUILDER_TABS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchOrgCatalogDetailsTabs(type) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/all/${type}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchOrgCatalogDetailTabs());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchOrgCatalogDetailTabs(response, error));
    } catch (e) {
      dispatch(
        endFetchOrgCatalogDetailTabs(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchOrgExtUserCatalogDetailTabs() {
  return {
    type: START_FETCH_EXT_ORG_CATALOG_BUILDER_TABS,
    payload: null,
  };
}

function endFetchOrgExtUserCatalogDetailTabs(success, error) {
  return {
    type: END_FETCH_EXT_ORG_CATALOG_BUILDER_TABS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchOrgExtUserCatalogDetailsTabs(entityId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/ext/${entityId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchOrgExtUserCatalogDetailTabs());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url);
      dispatch(endFetchOrgExtUserCatalogDetailTabs(response, error));
    } catch (e) {
      dispatch(
        endFetchOrgExtUserCatalogDetailTabs(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchSectionExtUsers() {
  return {
    type: START_FETCH_SECTION_EXT_USERS,
    payload: null,
  };
}

function endFetchSectionExtUsers(success, error) {
  return {
    type: END_FETCH_SECTION_EXT_USERS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchSectionExtUsers(entityId,sectionId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/permission/ext/entity/${entityId}/section/${sectionId}/user?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchSectionExtUsers());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchSectionExtUsers(response, error));
    } catch (e) {
      dispatch(
        endFetchSectionExtUsers(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddOrgVendorDetailTab() {
  return {
    type: START_ADD_ORG_VENDOR_BUILDER_TAB,
  };
}

function endAddOrgVendorDetailTab(success, error) {
  return {
    type: END_ADD_ORG_VENDOR_BUILDER_TAB,
    payload: {
      success,
      error,
    },
  };
}

export function addOrgVendorDetailsTab(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAddOrgVendorDetailTab());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data);
      dispatch(endAddOrgVendorDetailTab(response, error));
    } catch (e) {
      dispatch(
        endAddOrgVendorDetailTab(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateOrgVendorDetailTab() {
  return {
    type: START_UPDATE_ORG_VENDOR_BUILDER_TAB,
    payload: null,
  };
}

function endUpdateOrgVendorDetailTab(tabId, success, error) {
  return {
    type: END_UPDATE_ORG_VENDOR_BUILDER_TAB,
    payload: {
      tabId,
      success,
      error,
    },
  };
}

export function updateOrgVendorDetailsTab(id, data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateOrgVendorDetailTab());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endUpdateOrgVendorDetailTab(id, response, error));
    } catch (e) {
      dispatch(
        endUpdateOrgVendorDetailTab(id, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export function updateOrgVendorDetailsTabSequence(tabId, data, type) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${tabId}?t=${timestamp}`;
  return async (dispatch) => {
    try {
      await HttpFactory.instance().putMethod(url, data);
      dispatch(fetchOrgCatalogAllTabs(type));
    } catch (e) {
      return;
    }
  };
}

function startDeleteOrgVendorDetailTab() {
  return {
    type: START_DELETE_ORG_VENDOR_BUILDER_TAB,
    payload: null,
  };
}

function endDeleteOrgVendorDetailTab(tabId, success, error) {
  return {
    type: END_DELETE_ORG_VENDOR_BUILDER_TAB,
    payload: {
      tabId,
      success,
      error,
    },
  };
}

export function deleteOrgVendorDetailsTab(id) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startDeleteOrgVendorDetailTab());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteOrgVendorDetailTab(id, response, error));
    } catch (e) {
      dispatch(
        endDeleteOrgVendorDetailTab(id, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddOrgVendorDetailTabSection() {
  return {
    type: START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION,
    payload: null,
  };
}

function endAddOrgVendorDetailTabSection(tabId, success, error) {
  return {
    type: END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION,
    payload: {
      tabId,
      success,
      error,
    },
  };
}

export function addOrgVendorDetailsTabSection(tabId, data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${tabId}/section?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAddOrgVendorDetailTabSection());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endAddOrgVendorDetailTabSection(tabId, response, error));
    } catch (e) {
      dispatch(
        endAddOrgVendorDetailTabSection(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateOrgVendorDetailTabSection() {
  return {
    type: START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION,
    payload: null,
  };
}

function endUpdateOrgVendorDetailTabSection(tabId, sectionId, success, error) {
  return {
    type: END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION,
    payload: {
      tabId,
      sectionId,
      success,
      error,
    },
  };
}

export function updateOrgVendorDetailsTabSection(tabId, sectionId, data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${tabId}/section/${sectionId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateOrgVendorDetailTabSection());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endUpdateOrgVendorDetailTabSection(tabId, sectionId, response, error));
    } catch (e) {
      dispatch(
        endUpdateOrgVendorDetailTabSection(tabId, sectionId, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export function updateOrgVendorDetailsTabSectionSequence(tabId, sectionId, data, type) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${tabId}/section/${sectionId}?t=${timestamp}`;
  return async (dispatch) => {
    try {
      await HttpFactory.instance().putMethod(url, data);
      dispatch(fetchOrgCatalogAllTabs(type));
    } catch (e) {
      return;
    }
  };
}

function startDeleteOrgVendorDetailTabSection() {
  return {
    type: START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION,
    payload: null,
  };
}

function endDeleteOrgVendorDetailTabSection(tabId, sectionId, success, error) {
  return {
    type: END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION,
    payload: {
      tabId,
      sectionId,
      success,
      error,
    },
  };
}

export function deleteOrgVendorDetailsTabSection(tabId, sectionId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${tabId}/section/${sectionId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startDeleteOrgVendorDetailTabSection());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteOrgVendorDetailTabSection(tabId, sectionId, response, error));
    } catch (e) {
      dispatch(
        endDeleteOrgVendorDetailTabSection(tabId, sectionId, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddOrgVendorDetailTabSectionItem() {
  return {
    type: START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
    payload: null,
  };
}

function endAddOrgVendorDetailTabSectionItem(tabId, sectionId, success, error) {
  return {
    type: END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
    payload: {
      tabId,
      sectionId,
      success,
      error,
    },
  };
}

export function addOrgVendorDetailsTabSectionItem(tabId, sectionId, data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${tabId}/section/${sectionId}/item?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAddOrgVendorDetailTabSectionItem());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endAddOrgVendorDetailTabSectionItem(tabId, sectionId, response, error));
    } catch (e) {
      dispatch(
        endAddOrgVendorDetailTabSectionItem(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateOrgVendorDetailTabSectionItem() {
  return {
    type: START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
    payload: null,
  };
}

function endUpdateOrgVendorDetailTabSectionItem(tabId, sectionId, itemId, success, error) {
  return {
    type: END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
    payload: {
      tabId,
      sectionId,
      itemId,
      success,
      error,
    },
  };
}

export function updateOrgVendorDetailsTabSectionItem(tabId, sectionId, itemId, data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${tabId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateOrgVendorDetailTabSectionItem());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endUpdateOrgVendorDetailTabSectionItem(tabId, sectionId, itemId, response, error));
    } catch (e) {
      dispatch(
        endUpdateOrgVendorDetailTabSectionItem(tabId, sectionId, itemId, sectionId, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startDeleteOrgVendorDetailTabSectionItem() {
  return {
    type: START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
    payload: null,
  };
}

function endDeleteOrgVendorDetailTabSectionItem(tabId, sectionId, itemId, success, error) {
  return {
    type: END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
    payload: {
      tabId,
      sectionId,
      itemId,
      success,
      error,
    },
  };
}

export function deleteOrgVendorDetailsTabSectionItem(tabId, sectionId, itemId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/tab/${tabId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;

  return async (dispatch) => {
    dispatch(startDeleteOrgVendorDetailTabSectionItem());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteOrgVendorDetailTabSectionItem(tabId, sectionId, itemId, response, error));
    } catch (e) {
      dispatch(
        endDeleteOrgVendorDetailTabSectionItem(tabId, sectionId, itemId, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
