import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import AppBarV2 from "../../../../AppBarV2";
import LayeredCard from "../Common/LayeredCard";
import { Grid, CircularProgress, Button } from "@material-ui/core";
import {Add as AddIcon} from "@material-ui/icons"

import AddEvaluationImage from "../../../../../../assets/images/evaluation/add-evaluation.svg";
import classnames from "classnames";
import { Link } from "react-router-dom";
import CommonCss from "../../../../../../commonCss";
import { CommonFn } from 'services/commonFn';

//components
import NewEvaluationDialog from "../Common/NewEvaluationDialog";

//assets
import EvaluationEmptyState from "assets/images/evaluation_empty_state.svg";
import deleteIcon from "assets/images/delete.svg";

// Redux
import { showAddNewEvaluationDialog, fetchEvaluateStatusCount, fetchCompletedEvaluation, fetchInProgressEvaluation } from "redux/evaluate/action";
import { fetchAddOrgProductCountStats } from "redux/product/orgProduct/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "redux/evaluation/cart/action";
import { updateDrawerStyle } from "redux/drawer/action";
import Image from 'Components/Common/image';
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { deleteProductReview } from "redux/product/orgProduct/action";
import { showSnackBar } from "redux/snackbar/action";


const connectedProps = (state) => ({
  products: state.products.products,
  categoryFetchProgress: state.productCategory.fetchCategoryProgress,
  categories: state.productCategory.categories,
  totalCategories: state.productCategory.totalCategories,
  fetchProductsProgress: state.products.fetchProductsProgress,
  evaluationCart: state.evaluationCart.evaluation,
  statusCount: state.evaluate.statusCount,
  existingProductCount: state.metric.vendors,
  orgProductCountStats: state.orgProduct.orgProductCountStats,
  inProgressEvaluationProgress: state.evaluate.inProgressEvaluationProgress,
  inProgress: state.evaluate.inProgress,
  inProgressTotal: state.evaluate.inProgressTotal,
  completedEvaluationProgress:state.evaluate.completedEvaluationProgress,
  completed: state.evaluate.completed,
  completedTotal: state.evaluate.completedTotal,
  deleteProductReviewProgress: state.orgProduct.deleteProductReviewProgress,
  deleteProductReviewError: state.orgProduct.deleteProductReviewError,
  userType: state.authUser.user?.Role,
});

const connectionActions = {
  showAddNewEvaluationDialog: showAddNewEvaluationDialog,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchEvaluateStatusCount: fetchEvaluateStatusCount,
  fetchAddOrgProductCountStats: fetchAddOrgProductCountStats,
  fetchCompletedEvaluation: fetchCompletedEvaluation,
  fetchInProgressEvaluation: fetchInProgressEvaluation,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  deleteProductReview: deleteProductReview,
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topContainer: {
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
    },
    title: {
      fontSize: theme.spacing(2.4),
     
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    btnReview:{
      width: '38px',
      height: '32px',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: '13px',
      color: '#626675',
      paddingTop: 2,
      zIndex: 12,
      position: 'relative',
      border: '1px solid #EEEEEE',
      background: '#ffffff'
    },
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      alignItems: 'center'
    },
    content: {
      padding: theme.spacing(4, 10),
      background: "#F7F7F7",
      // minHeight: "100vh"
  },
  loader:{
    textAlign:'center',
    marginTop:50
  }
  });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      query: "",
      page: 0,
      pageSize: 5,
      inPorgressStatus: [2, 3],
      completedStatus: [4],
      items: [],
      isCartShow: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps){
    if(!this.props.deleteProductReviewProgress && prevProps.deleteProductReviewProgress) {
      if(!this.props.deleteProductReviewError) {
        this.fetchData();
      } else {
        this.props.showSnackBar(this.props.deleteProductReviewError, "error", 3000);
      }
    }
  }

  fetchData() {
   this.props.fetchCompletedEvaluation({ page: this.state.page, pageSize: this.state.pageSize, status: this.state.completedStatus, type: [1] });
   this.props.fetchInProgressEvaluation({ page: this.state.page, pageSize: this.state.pageSize, status: this.state.inPorgressStatus, type: [1] });
  }


  routeChange = (item,id) => {
    let redirectPath = '';
    if(item.WorkflowID === null || item.WorkflowID === ''){
      redirectPath = `/app/evaluate/${id}/`;
      this.props.history.push(redirectPath);
    }else{
      redirectPath = `/app/evaluate/${id}/workflow/interim`;
      this.props.history.push(redirectPath);
    }
  }

  deleteReview(e, id) {
    e.stopPropagation();
    var me = this;
    this.setState({deleteId: id}, () => {
        me.props.showMessageDialog();
    })
  }

  callBack = () => {
      if (this.state.deleteId) {
          this.props.hideMessageDialog();
          this.props.deleteProductReview(this.state.deleteId);
      }
  };


  render() {
    const classes = this.props.classes;


    if(this.props.inProgressEvaluationProgress){
      return <div className={classes.loader}><CircularProgress /></div>
    }

    if(!this.props.inProgressEvaluationProgress && this.props.inProgress?.length === 0 
      && !this.props.completedEvaluationProgress && this.props.completed?.length === 0){
      return(<>
      <AppBarV2 title="Evaluate" />
      <div className={classes.DashboardNoDataLayout}>
          <Image src={EvaluationEmptyState}/> 
          <h2>No Evaluations found</h2>
          <p>Get started on your first evaluation by tapping New Evaluation below</p>
          <Button variant="outlined" color="secondary" className={classes.button} startIcon={<AddIcon />} 
          onClick={() => {this.props.showAddNewEvaluationDialog()}}
          >New Evaluation</Button>
      </div>
      <NewEvaluationDialog /> 
      </>)
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    return (
      <div>
        <AppBarV2 title="Evaluate" />
          <Grid container className={classnames(classes.content)}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="topContainer" className={classes.topContainer}>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.newEvaluation}>
                  <LayeredCard
                    title="+ New Evaluation"
                    color="CYAN"
                    numLayer={1}
                    // subTitle="0 Evaluations"
                    image={AddEvaluationImage}
                    subTitle={'Tap to start a new evaluation'}
                    onClick={() => {this.props.showAddNewEvaluationDialog()}}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.bottomContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                  <div className={classes.bottomHead}>
                    <p className={classnames(classes.productHead)}>In Progress</p>
                      {this.props.inProgressTotal > 5 &&  
                        <Link
                          to="/app/evaluate/in-progress"
                          className={classes.viewAll}
                        >
                          View all ({this.props.inProgressTotal.toLocaleString("en-US")})
                        </Link>
                      }
                  </div>
                  <div>
                   {this.props.inProgress && this.props.inProgress.map((item, index) => 
                      <div className={classes.evaluateCard} key={index}   onClick={() => this.routeChange(item,item.ID)}>
                        <div className={classes.evaluateText}>
                          <p className={classes.evaluateCardTitle}>{item.Name}</p>
                          <p className={classes.evaluateCardSubTitle}>Updated At: {CommonFn.formateDate(item.UpdatedAt)}</p>
                        </div>
                        <div className={classes.evaluateLogo}>
                          <div className={classes.productLogoContainer}>
                          {
                            item.Products?.slice(0,item.Products.length < 5 ? item.Products.length : 3).map((item, i) => {
                              return(<div className={classes.productLogo} title={item.Name}><Image src={item.ProductLogo} alt={item.Name} title={item.Name} /></div>)
                            })
                          }
                          {
                            item.Products.length > 4 &&
                            <div className={classes.productLogo} title={item.Products.length - 3 + 'more product'}> + {item.Products.length - 3}</div>
                          }
                        </div>
                        { adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={(e) => this.deleteReview(e, item.ID)}><Image 
                            src={deleteIcon}
                            alt="forgotpassword_image" 
                            className={classes.titleImage}
                        /></button> }
                      </div>
                    </div> )}
                    { this.props.inProgressEvaluationProgress &&
                        <span className={classes.loader}><CircularProgress /></span>
                      }
                      { !this.props.inProgressEvaluationProgress && this.props.inProgress?.length === 0 &&
                        <span className={classes.noMoreData}>No more data </span>
                      }
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                  <div className={classes.bottomHead}>
                    <p className={classnames(classes.productHead)}>Completed</p>
                    {this.props.completedTotal > 5 &&
                      <Link
                        to="/app/evaluate/completed"
                        className={classes.viewAll}
                      >
                        View all ({this.props.completedTotal.toLocaleString("en-US")})
                      </Link>
                    }
                  </div>
                  <div>
                   {this.props.completed && this.props.completed.map((item, index) => <div className={classes.evaluateCard} key={index}   onClick={() => this.routeChange(item,item.ID)}>
                      <div className={classes.evaluateText}>
                        <p className={classes.evaluateCardTitle}>{item.Name}</p>
                        <p className={classes.evaluateCardSubTitle}>
                        Updated At: {CommonFn.formateDate(item.UpdatedAt)}</p>
                      </div>
                      <div className={classes.evaluateLogo}>
                          <div className={classes.productLogoContainer}>
                          {
                            item.Products?.slice(0,item.Products.length < 5 ? item.Products.length : 3).map((item, i) => {
                              return(<div className={classes.productLogo} title={item.Name}><Image src={item.ProductLogo} alt={item.Name} title={item.Name} /></div>)
                            })
                          }
                          {
                            item.Products.length > 4 &&
                            <div className={classes.productLogo} title={item.Products.length - 3 + 'more product'}> + {item.Products.length - 3}</div>
                          }
                        </div>
                        { adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={(e) => this.deleteReview(e, item.ID)}><Image 
                            src={deleteIcon}
                            alt="forgotpassword_image" 
                            className={classes.titleImage}
                        /></button> }
                      </div>
                    </div> )}
                    { this.props.completedEvaluationProgress &&
                        <span className={classes.loader}><CircularProgress /></span>
                      }
                      { !this.props.completedEvaluationProgress && this.props.completed?.length === 0 &&
                        <span className={classes.noMoreData}>No more data </span>
                      }
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <MessageDialog messageData={messageData} callBack={this.callBack} />
        <NewEvaluationDialog />        
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(Home));
