import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";

export const START_FETCH_DATASETS = "START_FETCH_DATASETS";
export const END_FETCH_DATASETS = "END_FETCH_DATASETS";

export const START_ADD_DASHBOARD = "START_ADD_DASHBOARD";
export const END_ADD_DASHBOARD = "END_ADD_DASHBOARD";
export const START_FETCH_DASHBOARD = "START_FETCH_DASHBOARD";
export const END_FETCH_DASHBOARD = "END_FETCH_DASHBOARD";
export const START_GET_DASHBOARD_DETAILS = "START_GET_DASHBOARD_DETAILS";
export const END_GET_DASHBOARD_DETAILS = "END_GET_DASHBOARD_DETAILS";
export const START_UPDATE_DASHBOARD = "START_UPDATE_DASHBOARD";
export const END_UPDATE_DASHBOARD = "END_UPDATE_DASHBOARD";
export const START_DELETE_DASHBOARD = "START_DELETE_DASHBOARD";
export const END_DELETE_DASHBOARD = "END_DELETE_DASHBOARD";

export const START_ADD_VISUALIZATION = "START_ADD_VISUALIZATION";
export const END_ADD_VISUALIZATION = "END_ADD_VISUALIZATION";
export const START_FETCH_VISUALIZATION = "START_FETCH_VISUALIZATION";
export const END_FETCH_VISUALIZATION = "END_FETCH_VISUALIZATION";
export const START_GET_VISUALIZATION_DETAILS = "START_GET_VISUALIZATION_DETAILS";
export const END_GET_VISUALIZATION_DETAILS = "END_GET_VISUALIZATION_DETAILS";
export const START_GET_VISUALIZATION_CHART_DATA = "START_GET_VISUALIZATION_CHART_DATA";
export const END_GET_VISUALIZATION_CHART_DATA = "END_GET_VISUALIZATION_CHART_DATA";
export const START_GET_VISUALIZATION_TABLE_DATA = "START_GET_VISUALIZATION_TABLE_DATA";
export const END_GET_VISUALIZATION_TABLE_DATA = "END_GET_VISUALIZATION_TABLE_DATA";
export const START_UPDATE_VISUALIZATION = "START_UPDATE_VISUALIZATION";
export const END_UPDATE_VISUALIZATION = "END_UPDATE_VISUALIZATION";
export const START_DELETE_VISUALIZATION = "START_DELETE_VISUALIZATION";
export const END_DELETE_VISUALIZATION = "END_DELETE_VISUALIZATION";

function startGetVisualizationTableData() {
  return {
    type: START_GET_VISUALIZATION_TABLE_DATA,
    payload: null,
  };
}

function endGetVisualizationTableData(success, error, visualizationId) {
  return {
    type: END_GET_VISUALIZATION_TABLE_DATA,
    payload: {
      success,
      error,
      visualizationId,
    },
  };
}

export function getVisualizationTableData(id) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${id}/tabledata?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startGetVisualizationTableData());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false);
      dispatch(endGetVisualizationTableData(response, error, id));
    } catch (e) {
      dispatch(
        endGetVisualizationTableData(null, null, id, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchDatasets() {
  return {
    type: START_FETCH_DATASETS,
    payload: null,
  };
}

function endFetchDatasets(success, error) {
  return {
    type: END_FETCH_DATASETS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchDatasets() {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/datasets?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchDatasets());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false);
      dispatch(endFetchDatasets(response, error));
    } catch (e) {
      dispatch(
        endFetchDatasets(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchDashboard() {
  return {
    type: START_FETCH_DASHBOARD,
    payload: null,
  };
}

function endFetchDashboard(success, error) {
  return {
    type: END_FETCH_DASHBOARD,
    payload: {
      success,
      error,
    },
  };
}

export function fetchDashboard(queryParams) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/dashboard?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchDashboard());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, queryParams, false);
      dispatch(endFetchDashboard(response, error));
    } catch (e) {
      dispatch(
        endFetchDashboard(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startGetDashboardDetails() {
  return {
    type: START_GET_DASHBOARD_DETAILS,
    payload: null,
  };
}

function endGetDashboardDetails(success, error) {
  return {
    type: END_GET_DASHBOARD_DETAILS,
    payload: {
      success,
      error,
    },
  };
}

export function getDashboardDetails(id) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/dashboard/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startGetDashboardDetails());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false);
      dispatch(endGetDashboardDetails(response, error));
    } catch (e) {
      dispatch(
        endGetDashboardDetails(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddDashboard() {
  return {
    type: START_ADD_DASHBOARD,
    payload: null,
  };
}

function endAddDashboard(success, error) {
  return {
    type: END_ADD_DASHBOARD,
    payload: {
      success,
      error,
    },
  };
}

export function addDashboard(data) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/dashboard?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAddDashboard());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endAddDashboard(response, error));
    } catch (e) {
      dispatch(
        endAddDashboard(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateDashboard() {
  return {
    type: START_UPDATE_DASHBOARD,
    payload: null,
  };
}

function endUpdateDashboard(success, error) {
  return {
    type: END_UPDATE_DASHBOARD,
    payload: {
      success,
      error,
    },
  };
}

export function updateDashboard(id, data) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/dashboard/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateDashboard());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endUpdateDashboard(response, error));
    } catch (e) {
      dispatch(
        endUpdateDashboard(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startDeleteDashboard() {
  return {
    type: START_DELETE_DASHBOARD,
    payload: null,
  };
}

function endDeleteDashboard(success, error) {
  return {
    type: END_DELETE_DASHBOARD,
    payload: {
      success,
      error,
    },
  };
}

export function deleteDashboard(id) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/dashboard/${id}?t=${timestamp}`;

  return async (dispatch) => {
    dispatch(startDeleteDashboard());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteDashboard(response, error));
    } catch (e) {
      dispatch(
        endDeleteDashboard(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchVisualization() {
  return {
    type: START_FETCH_VISUALIZATION,
    payload: null,
  };
}

function endFetchVisualization(success, error) {
  return {
    type: END_FETCH_VISUALIZATION,
    payload: {
      success,
      error,
    },
  };
}

export function fetchVisualization(params) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/list?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchVisualization());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, params, false);
      dispatch(endFetchVisualization(response, error));
    } catch (e) {
      dispatch(
        endFetchVisualization(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startGetVisualizationChartData() {
  return {
    type: START_GET_VISUALIZATION_CHART_DATA,
    payload: null,
  };
}

function endGetVisualizationChartData(success, error, visualizationId) {
  return {
    type: END_GET_VISUALIZATION_CHART_DATA,
    payload: {
      success,
      error,
      visualizationId,
    },
  };
}

export function getVisualizationChartData(id) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startGetVisualizationChartData());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false);
      if(response && response.configuration){
        try{
          let config = JSON.parse(response.configuration);
          if(config?.chartType === "TABLE"){
            dispatch(getVisualizationTableData(id));
          } else {
            dispatch(getVisualizationDetails(id));
          }
        } catch(e){
          dispatch(getVisualizationDetails(id));
        }
      }
      dispatch(endGetVisualizationChartData(response, error, id));
    } catch (e) {
      dispatch(
        endGetVisualizationChartData(null, null, id, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startGetVisualizationDetails() {
  return {
    type: START_GET_VISUALIZATION_DETAILS,
    payload: null,
  };
}

function endGetVisualizationDetails(success, error, visualizationId) {
  return {
    type: END_GET_VISUALIZATION_DETAILS,
    payload: {
      success,
      error,
      visualizationId,
    },
  };
}

export function getVisualizationDetails(id) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${id}/data?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startGetVisualizationDetails());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false);
      dispatch(endGetVisualizationDetails(response, error, id));
    } catch (e) {
      dispatch(
        endGetVisualizationDetails(null, null, id, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddVisualization() {
  return {
    type: START_ADD_VISUALIZATION,
    payload: null,
  };
}

function endAddVisualization(success, error) {
  return {
    type: END_ADD_VISUALIZATION,
    payload: {
      success,
      error,
    },
  };
}

export function addVisualization(data) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/add?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAddVisualization());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endAddVisualization(response, error));
    } catch (e) {
      dispatch(
        endAddVisualization(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateVisualization() {
  return {
    type: START_UPDATE_VISUALIZATION,
    payload: null,
  };
}

function endUpdateVisualization(success, error) {
  return {
    type: END_UPDATE_VISUALIZATION,
    payload: {
      success,
      error,
    },
  };
}

export function updateVisualization(id, data) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateVisualization());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endUpdateVisualization(response, error));
    } catch (e) {
      dispatch(
        endUpdateVisualization(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startDeleteVisualization() {
  return {
    type: START_DELETE_VISUALIZATION,
    payload: null,
  };
}

function endDeleteVisualization(success, error) {
  return {
    type: END_DELETE_VISUALIZATION,
    payload: {
      success,
      error,
    },
  };
}

export function deleteVisualization(id) {
  const api_server = Environment.api_host("VISUALIZATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${id}?t=${timestamp}`;

  return async (dispatch) => {
    dispatch(startDeleteVisualization());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteVisualization(response, error));
    } catch (e) {
      dispatch(
        endDeleteVisualization(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
