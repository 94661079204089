import React,{useState,useEffect} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

// const useStyles = makeStyles({

// });

export default function DataTable({tableColumns, data, config = {}}) {
  // const classes = useStyles();
  const [headerColumnMap, setHeaderColumnMap] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() =>{
    let columnObject = {}
    tableColumns?.forEach((column,i)=>{
      columnObject[column.uid] = column;
    });
    setHeaderColumnMap(columnObject)
  },[])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const allData = config?.pagination ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;

  const stylesData = (data)=> {
    if(!data) return {}
    return {
        width: data.width || 'unset',
        // color: data.color || '#000000de',
        // fontWeight: data.fontWeight || 500,
        // fontSize: data.fontSize || 16,
        border: config.columnBorder ? "1px solid rgba(224, 224, 224, 1)" : "",
        backgroundColor: config.headerBackgroundColor || '#fafafa',
        color: config.headerColor || '#000000de',
        // border: `${data.borderWidth}px solid ${data.borderColor}` || '1px solid #e0e0e0',
    }
  }

  return (
    <Paper style={{overflowX: config?.responsive ? "auto" : "unset"}}>
      <TableContainer>
        <Table aria-label="simple table" size={config?.dense ? "small": "medium"}>
          <TableHead>
            <TableRow>
              {tableColumns.map((header,i) => {
                let styles = stylesData(header);
                return <TableCell style={styles} align={header.align || 'left'} key={i}>{header.title || header.label}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.map((row,i) =>(
              <TableRow key={"row"+i}>
                {row.map((columnData,seq) => {
                  if(headerColumnMap[columnData.uid]){
                    return <TableCell key={"col"+seq} style={{border: config.columnBorder ? "1px solid rgba(224, 224, 224, 1)" : ""}} align={headerColumnMap[columnData.uid]?.align || 'left'}>{columnData.value === "NaN" ? "-" : columnData.value}</TableCell>
                  } else {
                    return null;
                  }
                })}
              </TableRow>
            ))}
            
          </TableBody>
        </Table>
      </TableContainer>
      {config?.pagination && <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
    </Paper>
  );
}
