import {
    START_FETCH_REQUEST, END_FETCH_REQUEST,
    START_DELETE_REQUEST, END_DELETE_REQUEST,
    START_FETCH_PRODUCT_INPROGRESS_REQUEST, END_FETCH_PRODUCT_INPROGRESS_REQUEST,
    START_FETCH_INPROGRESS_REQUEST, END_FETCH_INPROGRESS_REQUEST,
    START_DELETE_INPROGRESS_REQUEST, END_DELETE_INPROGRESS_REQUEST,
    START_FETCH_PRODUCT_COMPLETED_REQUEST, END_FETCH_PRODUCT_COMPLETED_REQUEST,
    START_FETCH_COMPLETED_REQUEST, END_FETCH_COMPLETED_REQUEST,
    START_DELETE_COMPLETED_REQUEST, END_DELETE_COMPLETED_REQUEST,
} from "./action";

const initState = {
    fetchRequestProgress: false,
    fetchRequestError: null,
    requestData: {}, 
    deleteRequestProgress: true,
    deleteRequestError: null,

    fetchInprogressRequestProgress: false,
    fetchInprogressRequestError: null,
    requestInprogressData: {}, 

    fetchInprogressProductRequestProgress: false,
    fetchInprogressProductRequestError: null,
    requestInprogressProductData: {}, 

    deleteInprogressRequestProgress: true,
    deleteInprogressRequestError: null,

    fetchCompletedRequestProgress: false,
    fetchCompletedRequestError: null,
    requestCompletedData: {}, 

    fetchCompletedProductRequestProgress: false,
    fetchCompletedProductRequestError: null,
    requestCompletedProductData: {},

    deleteCompletedRequestProgress: true,
    deleteCompletedRequestError: null,
}

export function evaluationRequestReducer(state = initState, action) {
    switch (action.type) {
        case START_FETCH_REQUEST:
            return {
                ...state,
                fetchRequestProgress: true,
                fetchRequestError: null,
            };
        case END_FETCH_REQUEST:
            var currentData = {...state.requestData}
            if(action.payload?.queryParam?.page > 0){
                currentData?.Data?.push(...action.payload?.success?.Data);
            }else{
                currentData = action.payload.success;
            }
            return {
                ...state,
                fetchRequestProgress: false,
                fetchRequestError: action.payload.error,
                requestData: currentData
            };
        case START_DELETE_REQUEST:
            return {
                ...state,
                deleteRequestProgress: true,
                deleteRequestError: null,
            };
        case END_DELETE_REQUEST:
            return {
                ...state,
                deleteRequestProgress: false,
                deleteRequestError: action.payload.error,
            };
        
        case START_FETCH_INPROGRESS_REQUEST:
            return {
                ...state,
                fetchInprogressRequestProgress: true,
                fetchInprogressRequestError: null,
            };
        case END_FETCH_INPROGRESS_REQUEST:
            return {
                ...state,
                fetchInprogressRequestProgress: false,
                fetchInprogressRequestError: action.payload.error,
                requestInprogressData: action.payload.success,
            };
        case START_FETCH_PRODUCT_INPROGRESS_REQUEST:
            return {
                ...state,
                fetchInprogressProductRequestProgress: true,
                fetchInprogressProductRequestError: null,
            };
        case END_FETCH_PRODUCT_INPROGRESS_REQUEST:
            currentData = {...state.requestInprogressProductData}
            if(action.payload?.queryParam?.page > 0){
                currentData?.Data?.push(...action.payload?.success?.Data);
            }else{
                currentData = action.payload.success;
            }
            return {
                ...state,
                fetchInprogressProductRequestProgress: false,
                fetchInprogressProductRequestError: action.payload.error,
                requestInprogressProductData: currentData,
            };
        case START_DELETE_INPROGRESS_REQUEST:
            return {
                ...state,
                deleteInprogressRequestProgress: true,
                deleteInprogressRequestError: null,
            };
        case END_DELETE_INPROGRESS_REQUEST:
            return {
                ...state,
                deleteInprogressRequestProgress: false,
                deleteInprogressRequestError: action.payload.error,
            };

        case START_FETCH_COMPLETED_REQUEST:
            return {
                ...state,
                fetchCompletedRequestProgress: true,
                fetchCompletedRequestError: null,
            };
        case END_FETCH_COMPLETED_REQUEST:
            return {
                ...state,
                fetchCompletedRequestProgress: false,
                fetchCompletedRequestError: action.payload.error,
                requestCompletedData: action.payload.success,
            };
        case START_FETCH_PRODUCT_COMPLETED_REQUEST:
            return {
                ...state,
                fetchCompletedProductRequestProgress: true,
                fetchCompletedProductRequestError: null,
            };
        case END_FETCH_PRODUCT_COMPLETED_REQUEST:
            currentData = {...state.requestCompletedProductData}
            if(action.payload?.queryParam?.page > 0){
                currentData?.Data?.push(...action.payload?.success?.Data);
            }else{
                currentData = action.payload.success;
            }
            return {
                ...state,
                fetchCompletedProductRequestProgress: false,
                fetchCompletedProductRequestError: action.payload.error,
                requestCompletedProductData: currentData,
            };
        case START_DELETE_COMPLETED_REQUEST:
            return {
                ...state,
                deleteCompletedRequestProgress: true,
                deleteCompletedRequestError: null,
            };
        case END_DELETE_COMPLETED_REQUEST:
            return {
                ...state,
                deleteCompletedRequestProgress: false,
                deleteCompletedRequestError: action.payload.error,
            };
        
        default:
            return state;
    }
}