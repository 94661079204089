import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { withStyles, createStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CommonCss from "commonCss";

const connectedProps = (state) => ({
  expentDirectoryProducts: state.expentDirectory.products
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  "@global": {
    "[class*='MuiAutocomplete-popper'] *": {
      fontSize: 13,
    },
  },
  appyBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    minHeight: 35,
    width: "calc(100% - 46px)",
    margin: 23,
    color: "#fff",
    fontSize: 14,
    padding: theme.spacing(0.5, 8),

    "&:hover": {
      background: "#3C3C3C",
    },
  },
  drawerSection: {
    "& [class*='MuiAccordionSummary-root']": {
      padding: theme.spacing(0, 3.6),
      minHeight: 48,
      width: 500,
      "& [class*=MuiSvgIcon-root]": {
        width: 16,
      },
    },

    "& [class*='MuiAccordion-root']": {
      boxShadow: "none",
      borderBottom: "1px solid #EFEFEF",
      borderRadius: 0,
      margin: 0,
      "&:before": {
        background: "none",
      },
    },
    "& [class*='MuiAccordionSummary-content']": {
      margin: 0,
    },
    "& [class*='MuiAccordionDetails-root']": {
      padding: theme.spacing(0, 2),
    },

    "& [class*='MuiAccordionSummary-expandIcon']": {
      flexDirection: "row-reverse",
      padding: 0,
      "&.Mui-expanded ": {
        transform: "rotate(90deg)",
      },
    },

    "& [class*='MuiOutlinedInput-root']": {
      padding: "2px 60px 2px 2px !important",
      marginBottom: "10px !important",
      fontSize: 13,
    },
    "& [class*='MuiAutocomplete-root']": {
      width: "100%",
    },
    "& [class*='MuiAutocomplete-tag']": {
      background: "#4A87F8",
      color: "#fff",
      height: 28,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  head: {
    color: "#282D30",
    fontSize: 18,
  },
  priceText: {
    color: "#1C1C1C",
    padding: theme.spacing(1, 3.9),
    fontSize: 13,
  },
  sliderRange: {
    width: "calc(100% - 70px)",
    margin: "auto",
    display: "block",
    marginBottom: 14,

    "& [class*='MuiSlider-thumb']": {
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px #1000001A",
      border: "1px solid #E4E4E4",
      width: 22,
      height: 22,
      marginTop: -10,
    },
    "& [class*='MuiSlider-track']": {
      height: 3,
      backgroundColor: "#007BFF",
    },
    "& [class*='MuiSlider-root']": {
      color: "#abd0f5",
      height: "3px",
    },
  },
  sliderValue: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 3.6),
    color: "#545454",
    fontSize: 13,
    "& p": {
      margin: 0,
    },
  },
  sliderText: {
    color: "#969090",
    border: "1px solid #969090",
    width: 50,
    display: "flex",
    height: 40,
    padding: 8,
    alignItems: "center",
    borderRadius: 4,
    marginTop: 10,
  },
  headSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: 23,
  },
  checkboxHeadSection: {
    padding: 23,
    paddingBottom: 53,
    paddingTop: 53,
  },
  close: {
    fill: "#656262",
    cursor: "pointer",
  },
  label: {
    ...CommonCss.label,
    color: "#545454",
    fontSize: 14,
    marginBottom: 0,
  },
  filterText: {
    color: "#1C1C1C",
    fontSize: 13,
    margin: 0,
  },
  iconEle: {
    width: 18,
    color: "#fff",
    marginRight: 10,
    "&:hover": {
      color: "#e8e2e2",
    },
  },
  checkboxSection: {
    width: "100%",
    maxHeight: 300,
    overflowY: "auto",
    marginBottom: 10,
  },
  checkedIcon: CommonCss.checkedIcon,
  icon: CommonCss.icon,
  searchSection: {
    width: "96%",
    padding: 10,
    marginBottom: 10,
    marginLeft: 10,
    height: 40,
    borderRadius: 4,
    border: "1px solid #b7b5b5",
  },
  checkboxWithFilterSection: {
    width: "100%",
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    right: 4,
    top: 7,
    color: "#ada8a8",
    cursor: "pointer",
    "&:hover": {
      color: "#ada8a8",
    },
  },
  checkBoxFilter: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formGroup: {
    marginLeft: 10,
    '& [class*=MuiTypography-root]': {
      fontSize: '14px'
    }
  },
  popover: {
    "& [class*=MuiPaper-root]": {
      marginTop: 50,
      marginLeft: 50
    }
  },

});

class AllProductFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: [20, 37],
      expanded: false,
      searchFeatures: "",
      appliedfilters: {},
      filters: [],
    };
  }

  componentDidMount() {
    const { appliedfilters, expentDirectoryProducts } = this.props
    const filters = this.state.filters
    const aggregations = expentDirectoryProducts?.aggregations || {}
    if (aggregations) {
      if (aggregations?.features) {
        const list = Object.keys(aggregations.features).map(key => ({ value: key, label: aggregations.features[key] }))
        filters.push({
          label: 'Features',
          key: 'feature_ids',
          isMultiAutoCompleteSelect: true,
          list
        })
      }
      if (aggregations?.platforms) {
        const aggPlateForms = aggregations?.platforms
        const platforms = {
          label: 'Platforms',
          isMultiCheckboxSelect: true,
          key: 'platform_ids',
          list: []
        }
        const deployment = {
          label: 'Deployment',
          key: 'platform_ids',
          isMultiCheckboxSelect: true,
          list: []
        }
        Object.keys(aggPlateForms).forEach(key => {
          if (!['Online', 'Business Hours', 'Cloud'].includes(aggPlateForms[key])) {
            platforms.list.push({ value: key, label: aggPlateForms[key] })
          }
          if (['Cloud'].includes(aggPlateForms[key])) {
            deployment.list.push({ value: key, label: aggPlateForms[key] })
          }
          return true
        })
        filters.push(platforms)
        filters.push(deployment)
      }
    }
    this.setState({ appliedfilters, filters })
  }

  accordionChange = (panel) => {
    this.setState({
      expanded: panel === this.state.expanded ? false : panel
    });
  };


  handleOnClickApply = () => {
    this.props.onApplyFilter(this.state.appliedfilters)
  }

  handleFilter = (key, value) => {
    const appliedfilters = this.state.appliedfilters
    if (appliedfilters?.popularFilters) {
      delete appliedfilters?.popularFilters
    }
    appliedfilters[key] = value
    this.setState({ appliedfilters })
  }

  render() {
    var { classes } = this.props;
    const { filters, appliedfilters } = this.state
    return (
      <div className={classes.drawerSection}>
        <div className={classes.headSection}>
          <div className={classes.head}>Filters</div>
        </div>
        {filters && filters.map((filter, index) => (
          <Accordion
            key={index}
            expanded={this.state.expanded === `panel${index}`}
            onChange={() => this.accordionChange(`panel${index}`)}
          >
            <AccordionSummary expandIcon={<ArrowForwardIosIcon />}>
              <p className={classes.filterText}>{filter.label}</p>
            </AccordionSummary>
            <AccordionDetails>
              {filter.isCheckBoxFilter && (
                <div className={classes.checkBoxFilter}>
                  {filter.key === "popularFilters" && filter.list.map((checkbox, index) => (
                    <FormGroup className={classes.formGroup} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={appliedfilters[filter.key] === checkbox.key}
                            checkedIcon={
                              <span
                                className={classnames(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            onChange={(e) => {
                              e.preventDefault()
                              if(e.target.checked) {
                                this.setState({ appliedfilters: { [filter.key]: checkbox.key } })
                              } else {
                                this.setState({ appliedfilters: { }})
                              }
                            }}
                            icon={<span className={classes.icon} />}
                            color="primary"
                            value={checkbox.key}
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label={checkbox.label}
                      />
                    </FormGroup>
                  ))}
                </div>
              )}
              {filter.isMultiAutoCompleteSelect === true && filter.key === 'feature_ids' && (
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={filter.list}
                  value={filter.list.filter(obj => appliedfilters[filter.key] && appliedfilters[filter.key].includes(obj.value)) || []}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={filter.placeholder || `Search ${filter.label}`}
                    />
                  )}
                  ChipProps={{
                    deleteIcon: (
                      <CloseIcon className={classes.iconEle} />
                    ),
                  }}
                  onChange={(e, value) => this.handleFilter(filter.key, value.map(o => o.value))}
                />
              )}
              {filter.isMultiCheckboxSelect && (
                <div className={classes.checkBoxFilter}>
                  {filter.key === "platform_ids" && filter.list.map((checkbox, index) => (
                    <FormGroup className={classes.formGroup} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={appliedfilters[filter.key] ? appliedfilters[filter.key].includes(checkbox.value) : false}
                            checkedIcon={
                              <span
                                className={classnames(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            onChange={(e) => {
                              let values = appliedfilters[filter.key]
                              if (values) {
                                if(values.includes(checkbox.value)) {
                                  values = values.filter(val => val !== checkbox.value )
                                } else {
                                  values.push(checkbox.value)
                                }
                              } else {
                                values = [checkbox.value]
                              }
                              this.handleFilter(filter.key, values)
                            }}
                            icon={<span className={classes.icon} />}
                            color="primary"
                            value={checkbox.value}
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label={checkbox.label}
                      />
                    </FormGroup>
                  ))}
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))}

        <div className={classes.textCenter}>
          <Button className={classes.appyBtn} onClick={() => this.handleOnClickApply()}>Apply</Button>
        </div>

      </div>
    );
  }
}

AllProductFilter.defaultProps = {
  appliedfilters: {}
}

export default React.memo(connector(compose(
  withRouter,
  withStyles(styles)
)(AllProductFilter)));
