import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import Splash from './Splash/index';
import Setup from "./Setup/index";
import Summary from "./Summary/index";
import InProgress from "./InProgress/index";
import InProgressV2 from "./InProgressV2/index";
import ProgressSummary from "./ProgressSummary/index";
import AppBarV2 from "../../../../AppBarV2";
import { updateDrawerStyle } from "../../../../../../redux/drawer/action";
import { fetchProductPrice } from '../../../../../../redux/product/orgProduct/action';

import { CircularProgress } from '@material-ui/core';
import CriteriaAddDialog from "../../Components/Common/CriteriaAddDialog";
import CriteriaUpdateDialog from "./Approval/CriteriaUpdateDialog";
import SaveTemplateDialog from "./Common/SaveTemplateDialog";
import VendorEmailDialog from "./Common/VendorEmailDialog";
import AskSellerDialog from "./Common/AskSellerDialog";
import PreviousInteractionDialog from "./Common/PreviousInteractionDialog"
import RequirementAddDialog from "../../Components/Common/RequirementAddDialog";
import EvaluationLinkDialog from "./Common/EvaluationLinkDialog/index";
import EvaluationRuleDialog from "./Common/EvaluationRuleDialog";
import ReadMoreDialog from "./Common/ReadMoreDialog";
import { withRouter } from 'react-router-dom';
import Approval from './Approval';
import GetApproval from "./Approval/GetApproval";
import PricingDialog from "Components/Dialogs/PricingDialog";
import SendNotificationDialog from "./Approval/SendNotificationDialog";
import FinishDialog from "./Approval/FinishDialog";
import EvaluationReport from "./Report";
import WorkFlowInit from "./WorkFlow";
import WorkFlowComponent from "./WorkFlow/WorkflowComponent";
import ChildWorkFlowComponent from "./WorkFlow/ChildWorkFlowComponent";
import WorkFlowApprovalComponent from "./Approval/GetApproval";
import { updateEvaluationTitle } from "redux/evaluation/metadata/action";
import WorkflowProgress from "./Common/WorkflowProgress";
import ChildWorkFlowInit from "./WorkFlow/ChildWorkflow";
// import WorkflowLoaderDialog from './WorkFlow/Common/WorkflowLoaderDialog';
import SummaryComponent from "./ProgressSummary";
import NudgeNotification from "../../../Notification/NudgeNotification";
import WorkflowSummary from "./WorkFlow/Components/Summary/finishSummary"
import Configuration from './WorkFlow/Components/Configuration';

const connectedProps = (state) => ({
    isRuleModalOpen: state.evaluationRule.showDialog,
    isOpen: state.evaluationCart.showProductUpdateDialog,
    targetContainer: state.evaluationCart.targetContainer,
    evaluation: state.evaluationMetaData.evaluation,
    evaluateView: state.applications.evaluateView,
    user: state.authUser.user,
    evaluateViewId: state.applications.evaluateViewId,
    evaluateNeverReview: state.applications.evaluateNeverReview,
    evaluateNeverReviewId: state.applications.evaluateNeverReviewId,
    sendNotificationDialog: state.evaluationMetaData.isOpenSendNotificationDialog,
    productPriceProg: state.orgProduct.productPriceProg,
    productPriceError: state.orgProduct.productPriceError,
    productPrice: state.orgProduct.productPrice,
    isShowWorkflowLoader: state.workflow.isShowWorkflowLoader,
    workflowUserActivity: state.evaluationWorkflow.workflowUserActivity,
    showPreviousInteractionDialog: state.evaludationCriteria.showPreviousInteractionDialog,
    updateProgress: state.evaluationMetaData.updateProgress,
    evalTitleUpdateProgress: state.evaluationMetaData.evalTitleUpdateProgress,
    evalTitleUpdateError: state.evaluationMetaData.evalTitleUpdateError,
    showNudgeDialog: state.notifications.showNudgeDialog,
    evaluationInfoData: state.evaluationMetaData.evaluationInfoData,
});

const connectionActions = {
    updateDrawerStyle: updateDrawerStyle,
    fetchProductPrice: fetchProductPrice,
    updateEvaluationTitle: updateEvaluationTitle,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    title: {
        fontWeight: 500,
        color: "#34495E",
        opactiy: 0.6,
        fontSize: theme.spacing(1.8),
        cursor: "pointer",
        textDecoration: 'underline'
    },
    subTitle: {
        fontWeight: 500,
        color: "#34495E",
        opactiy: 0.6,
        fontSize: theme.spacing(1.5),
        cursor: "pointer",
        textDecoration: "underline"
    },
    active: {
        color: "#34495E",
        opactiy: 1,
    },
    "@global": {
        '.applicationContent': {
            "& .appBar [class*=MuiGrid-container]": {
                alignItems: 'flex-start',
                "& [class*=titleContainer]": {
                    marginTop: 10
                }
            }
        },
        "[class*=MuiPopover-paper] [class*=MuiButtonBase-root]": {
            color: '#707070',
            fontSize: 13
        }
    },
    titleSection: {
        display: 'inline-block'
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },

});

class Evaluation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPricingDialogOpen: false,
            pricingUrlFetch: true,
            twoStepBackState: false,
            isShowBack: true
        }
    }

    componentDidMount() {
        if (this.props?.location && this.props?.location?.search) {
            const search = new URLSearchParams(this.props.location.search)
            if (search.get("for_product") !== null) {
                this.props.fetchProductPrice(search.get("for_product"))
            }
            this.updateBackFlag();
            if (search.get("hide_B") !== null) {
                this.setState({
                    isShowBack: false
                })
            }
        }
        setTimeout(() => {
            this.props.updateDrawerStyle(false, true)
        }, 100)
    }

    componentDidUpdate(prevProps) {
        if (!this.props.productPriceProg && prevProps.productPriceProg) {
            if (this.state.pricingUrlFetch && this.props.productPriceError === null) {
                this.setState({
                    isPricingDialogOpen: true,
                    pricingUrlFetch: false
                })
            }
        }

        if (this.props?.location && this.props.location.search !== prevProps.location.search) {
            this.updateBackFlag()
        }
    }

    updateBackFlag() {
        // console.log(this.props.location, 'Location666666666')
        if (this.props?.location && this.props?.location?.search) {
            console.log(this.props.location, 'Location666666666')
            const search = new URLSearchParams(this.props.location.search)
            if (search.get("dispatchTo") !== null) {
                this.setState({
                    twoStepBackState: true
                })
            } else {
                this.setState({
                    twoStepBackState: false
                })
            }
        } else {
            this.setState({
                twoStepBackState: false
            })
        }
    }

    render() {
        const classes = this.props.classes;
        // let formatedStatus = this.props.evaluation?.RequestStatus || 'Draft';
        // formatedStatus = formatedStatus === 'PendingApproval'? "Pending Approval" : formatedStatus;
        if (this.props.user == null) {
            return <div className={classes.loader}> <CircularProgress /></div>
        }
        return <div>
            {this.props.isShowWorkflowLoader === false &&
                <AppBarV2
                    withBack={this.state.isShowBack}
                    dispathFrmApproval={this.state.twoStepBackState}
                    title={this.props.evaluation == null ? "Evaluation" : this.props.evaluation?.Name}
                    // title={this.props.evaluation == null ? "Evaluation" : 'Request #' +this.props.evaluation?.ID +' ('+this.props.evaluation?.RequestStatus+')'}
                    isEditableTitle={this.props.location.pathname.indexOf('/workflow') !== -1 ? true : false}
                    submitTitle={(value) => {
                        var maxScore = 5;
                        if (this.props.evaluationInfoData !== undefined && this.props.evaluationInfoData !== null && this.props.evaluationInfoData?.MaxScore && this.props.evaluationInfoData?.MaxScore !== null && this.props.evaluationInfoData?.MaxScore !== '') {
                            maxScore = this.props.evaluationInfoData.MaxScore;
                        } else if (this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation?.MaxScore && this.props.evaluation?.MaxScore !== null && this.props.evaluation?.MaxScore !== '') {
                            maxScore = this.props.evaluation.MaxScore;
                        }

                        this.props.updateEvaluationTitle(this.props.evaluation?.ID, {
                            name: value,
                            maxScore: maxScore
                        })
                    }}
                    isSubmittingTitle={this.props.evalTitleUpdateProgress}
                >
                </AppBarV2>
            }
            <Switch>
                <Route exact path={this.props.match.path} component={Splash} />
                <Route exact path={`${this.props.match.path}/setup`} component={Setup} />
                <Route exact path={`${this.props.match.path}/respond`} component={InProgressV2} />
                <Route exact path={`${this.props.match.path}/respond/detailed`} component={ProgressSummary} />
                <Route exact path={`${this.props.match.path}/respond/comparison`} component={InProgress} />
                <Route exact path={`${this.props.match.path}/summary`} component={Summary} />
                <Route exact path={`${this.props.match.path}/approval`} component={Approval} />
                <Route exact path={`${this.props.match.path}/approval/:product_id`} component={GetApproval} />
                <Route exact path={`${this.props.match.path}/report`} component={EvaluationReport} />
                <Route exact path={`${this.props.match.path}/workflow`} component={WorkflowProgress} />
                <Route exact path={`${this.props.match.path}/workflow/interim`} component={WorkFlowInit} />
                <Route exact path={`${this.props.match.path}/workflow/:next_evaluation_id/interim`} component={ChildWorkFlowInit} />
                <Route exact path={`${this.props.match.path}/workflow/:id/configure`} component={Configuration} />
                <Route exact path={`${this.props.match.path}/workflow/:workflow_id/component/:component_id`} component={WorkFlowComponent} />
                <Route exact path={`${this.props.match.path}/workflow/:workflow_id/component/:component_id/nextEvaluation/:next_evaluation_id`} component={ChildWorkFlowComponent} />
                <Route exact path={`${this.props.match.path}/workflow/:workflow_id/component/:component_id/approval/:product_id`} component={WorkFlowApprovalComponent} />
                <Route exact path={`${this.props.match.path}/workflow/:workflow_id/component/:component_id/respond/detailed`} component={SummaryComponent} />
                <Route exact path={`${this.props.match.path}/report/workflow/:workflow_id/component/:component_id`} component={EvaluationReport} />
                <Route exact path={`${this.props.match.path}/workflow/:workflow_id/component/:component_id/workflow_summary`} component={WorkflowSummary} />
            </Switch>
            <CriteriaAddDialog />
            <CriteriaUpdateDialog />
            <RequirementAddDialog />
            <ReadMoreDialog />
            <SaveTemplateDialog />
            {
                !this.props.targetContainer && this.props.isOpen &&
                <VendorEmailDialog />
            }
            <AskSellerDialog />
            {this.props.showPreviousInteractionDialog && <PreviousInteractionDialog />}

            {
                this.props.sendNotificationDialog &&
                <SendNotificationDialog />
            }
            <EvaluationLinkDialog />
            <FinishDialog />
            <EvaluationRuleDialog />
            <PricingDialog
                isOpen={this.state.isPricingDialogOpen}
                handleOnClose={() => this.setState({ isPricingDialogOpen: false })}
                pricingData={this.props.productPrice}
            />
            {this.props.showNudgeDialog && <NudgeNotification />}
            {/* <WorkflowLoaderDialog/> */}
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Evaluation));