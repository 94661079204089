import {
  START_ADD_NEW_ROW, END_ADD_NEW_ROW,
  START_UPDATE_ROW, END_UPDATE_ROW,
  START_FETCH_ROWS, END_FETCH_ROWS,
  START_DELETE_ROW, END_DELETE_ROW,
} from "./action";


// Initial State
const initState = {
  addNewRowProgress: false,
  addNewRowError: null,
  newTaskData: null,

  updateRowProgress: false,
  updateRowError: null,
  updatedTaskData: null,

  fetchDataProgress: false,
  fetchDataError: null,
  tableDatas: {},

  deleteRowProgress: false,
  deleteRowError: null,
}


export function cataclogTableReducers(state = initState, action) {
  switch (action.type) {
    case START_ADD_NEW_ROW:
      return {
        ...state,
        addNewRowProgress: true,
        addNewRowError: null,
        newTaskData: null
      }

    case END_ADD_NEW_ROW:
      return {
        ...state,
        addNewRowProgress: false,
        addNewRowError: action.payload.error,
        newTaskData: action.payload.success
      }

    case START_UPDATE_ROW:
      return {
        ...state,
        updateRowProgress: true,
        updateRowError: null,
        updatedTaskData: null,
      }

    case END_UPDATE_ROW:
      return {
        ...state,
        updateRowProgress: false,
        updateRowError: action.payload.error,
        updatedTaskData: action.payload.success,
      }

    case START_FETCH_ROWS:
      return {
        ...state,
        fetchDataProgress: true,
        fetchDataError: null,
      }

    case END_FETCH_ROWS:
      let successFetchData = action.payload.success;
      const infoData = action.payload.data;
      let tableDataItems = state.tableDatas;
      tableDataItems[infoData.item_slug] = successFetchData;
      return {
        ...state,
        fetchDataError: action.payload.error,
        tableDatas: tableDataItems,
        fetchDataProgress: false,
      }

    case START_DELETE_ROW:
      return {
        ...state,
        deleteRowProgress: true,
        deleteRowError: null,
      }

    case END_DELETE_ROW:
      return {
        ...state,
        deleteRowProgress: false,
        deleteRowError: action.payload.error,
      }

    default:
      return state;
  }

}