import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as Validator from "util/Validation";
import {hideFeedbackTakeDialog,addEvaluationFeedback,fetchEvaluationUserFeedback} from "../../../../../../../redux/evaluation/feedback/action"
import {showSnackBar} from "../../../../../../../redux/snackbar/action";
import { withRouter } from 'react-router-dom';
import SqureButton from '../../../../../../Common/SqureButton';
import Nestable from 'react-nestable';
import DragIcon from "../../../../../../../assets/images/drag_icon.svg"
import CloseIcon from '@material-ui/icons/Close';
import Feedback from "assets/images/feedback.svg"

import Image from 'Components/Common/image.jsx';
import ToolTip from "Components/Common/ToolTip.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen:state.evaluationFeedback.showFeedbackTakeDialog,
    addFeedbackProgress:state.evaluationFeedback.addFeedbackProgress,
    addFeedbackError:state.evaluationFeedback.addFeedbackError,
    evaluation:state.evaluationMetaData.evaluation,
    user:state.authUser.user,
    userFeedback:state.evaluationFeedback.userFeedback,
    userFeedbackProgress:state.evaluationFeedback.userFeedbackProgress
});

const connectionActions = {
    hideDialog:hideFeedbackTakeDialog,
    showSnackBar:showSnackBar,
    addEvaluationFeedback:addEvaluationFeedback,
    fetchEvaluationUserFeedback:fetchEvaluationUserFeedback
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    title:{
        textAlign:"center",
        fontWeight:500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px 0px'
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 13
    },
    actions:{   
        textAlign:"center"
    },
    actionBtn:{
        minWidth:'200px'
    },
    dragItem:{
        borderRadius:4,
        padding:theme.spacing(1.5),
        color: '#6D7278',
        border: '1px solid #EBEBEB',
        display: 'flex',
        justifyContent: 'space-between'
    },
    nestedComponent:{
        padding:theme.spacing(3,0),
    },
    dialogContent: {
        maxWidth: 425,
        margin: 'auto',
        marginBottom: 60
    },
    feedbackSection: {
        marginBottom: 40,
        "& > div": {
            color: '#475867',
            marginTop: 15,
            fontSize: 12,
            "& > label": {
                fontSize: 14,
            }
        },
        "& [class*=MuiInputBase-root]": {
            padding: 0
        },
        "& textarea": {
            height: 60,
            fontSize: 14
        }
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    feedbackText: {
        color: '#475867',
        fontSize: 14,
        marginTop: 0
    },
    feedbackImg: {
        textAlign: 'center',
        marginTop: 10
    },
});

class FeedbackDialog extends React.Component {

    constructor(props){
        super(props);
        this.state  =   {
            error:"",
            success:"",
            feedback:"",
            feedback_error:"",
            products:[]
        }
        this.submitFeedback     =   this.submitFeedback.bind(this);

    }

    componentDidUpdate(prevProps){
        if(this.props.isOpen == true && prevProps.isOpen == false){
            this.setState({
                products:this.props.evaluation.Products.map((p)=>{
                    p.id = p.ID
                    return p;
                })
            })
            this.props.fetchEvaluationUserFeedback(this.props.evaluation.ID)
        }

        if(this.props.userFeedbackProgress == false && prevProps.userFeedbackProgress == true){
            if(this.props.userFeedback !== null){
                var products    = this.props.evaluation.Products.map((p)=>{
                    p.id = p.ID
                    return p;
                });
                var productSeq   = [];
                if(this.props.userFeedback.ProductSeq !== null){
                    if(Validator.isJson(this.props.userFeedback.ProductSeq)){
                        productSeq = JSON.parse(this.props.userFeedback.ProductSeq)
                    }
                }
                products.sort((a,b)=>{
                    var aIndex  = productSeq.indexOf(a.id);
                    var bIndex  = productSeq.indexOf(b.id);
                    if(aIndex > bIndex){
                        return 1
                    } else if(aIndex < bIndex){
                        return -1
                    } else {
                        return 0;
                    }
                });
                this.setState({
                    feedback:this.props.userFeedback.Feedback,
                    products:products
                })
            }
        }

        if(this.props.addFeedbackProgress == false && prevProps.addFeedbackProgress == true){
            if(this.props.addFeedbackError == null){
                //Migrate to different page
                this.props.hideDialog();
                this.props.showSnackBar("Feedback Added","success",3000);
            } else {
                if(this.props.addFeedbackError.message !== undefined){
                    this.setState({
                        "error":"Failed to add Feedback Details."
                    })
                } else {
                    this.setState({
                        "error":"Something went wrong."
                    })
                }
            }
        }
    }
    
    submitFeedback(){
        this.setState({
            success:"",
            error:"",
            feedback_error:""
        });
        var feedback = this.state.feedback;
        if(feedback.trim().length == 0){
            this.setState({
                feedback_error:"Please enter your feedback"
            });
            return;
        }

        this.props.addEvaluationFeedback(this.props.evaluation.ID,{
            feedback:this.state.feedback,
            productIds:this.state.products.map((p)=>{
                return p.ID
            }),
        })
    }

    render() {
        const classes = this.props.classes;
        var me  =   this;
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-integrationDialog" 
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll="body"
                    id="evMetaDialog"
                    >
                        <DialogContent classes={{root:classes.dialogContent}} >
                        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon/></div>
                            <div className={classes.feedbackImg} > <img src={Feedback} alt="Product ranking"/></div>
                            <div>
                            <Typography variant={"h4"} className={classes.title}>Product ranking and Feedback</Typography>
                            <p className={classes.subTitle}>Rank products based on your evaluation and provide feedback below to add more context.</p>
                            </div>
                            <div className={classes.alerts}>
                                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                                {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                            </div>
                            <div className={classes.form}>
                                <div className={classes.nestedComponent}>
                                    <p className={classes.feedbackText}>Product ranking <ToolTip toolTip="37"/></p>
                                    <Nestable
                                        items={this.state.products}
                                        renderItem={({item, index})=>{
                                            return <div className={classes.dragItem}> { index + 1 || ''}{ index > -1 ? '.' : ''} {item.Name} <Image alt="drag" src={DragIcon}/></div>
                                        }}
                                        maxDepth={1}
                                        onChange={(data)=>{
                                            this.setState({
                                                products:data
                                            })
                                        }}
                                    />
                                </div>
                                <div className={classes.feedbackSection}>
                                    {/* <OutlinedInput
                                        label="Feedback"
                                        error={this.state.feedback_error.length > 0}
                                        helperText={this.state.feedback_error}
                                        value={this.state.feedback}
                                        multiline={true}
                                        row={10}
                                        toolTip="38"
                                        onChange={(event) => {
                                            this.setState({ feedback: event.target.value });
                                        }}
                                    />  */}
                                    <SlateInputField 
                                        label="Feedback"
                                        error={this.state.feedback_error.length > 0}
                                        helperText={this.state.feedback_error}
                                        folderPath={`/evaluation/${this.props?.evaluation?.ID}/feedback`}
                                        as={SlateInputField}
                                        toolTip="38"
                                        onChangeEvent={(value) => {
                                            this.setState({ feedback: value });
                                        }}
                                        initValue={this.state.feedback}
                                        textContainerStyle={{ color: "#707070", }}/>
                                </div>
                            </div>
                            <div className={classes.actions}>
                                <SqureButton  cls={classes.actionBtn} variant={"contained"} onClick={this.submitFeedback}>Submit Feedback</SqureButton>
                            </div>
                        </DialogContent>

                </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(FeedbackDialog));