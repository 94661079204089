import TextFormatIcon from '@material-ui/icons/TextFormat';
import Filter1Icon from '@material-ui/icons/Filter1';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ShareIcon from '@material-ui/icons/Share';
import ShortTextIcon from '@material-ui/icons/ShortText';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
export function getChildItems(nodes, links) {
  let map = nodes ? new Map(nodes.map(o => [o.ID, { ...o, children: [] }])) : null;
  let resultMap = {}
  if (links && map) {
    links.forEach(function (data) {
      let source = data.SourceComponentID;
      let target = data.TargetComponentID;
      let sourceData = map.get(source)
      if (sourceData.Type === 'Control' && (sourceData.RefID === 'else' || sourceData.RefID === 'if' || sourceData.RefID === 'ifelse')) {
        if (resultMap[source] === undefined) {
          resultMap[source] = []
          resultMap[source].push(target)
        } else {
          resultMap[source].push(target)
        }
      }
      map.get(source).children.push(map.get(target))
    })
  }

  const nodeTree = map && nodes && nodes.length > 0 ? map.get(nodes[0].ID) : [];
  const result = getAllComponents(nodeTree, [], [], false)
  // console.log(result, 'FinalComponentssssssssssssssss')
  return {
    componentChildMap: resultMap,
    nodeTree: nodeTree,
    componentInSequence: result.componentSequence,
    childItems: result.childItems
  }
}

function getAllComponents(component, datas, childItems, isParentStart) {
  // console.log(component,'Componentttttttt')
  if (component.Type !== 'Control') {
    datas.push(component.ID);
  }

  if ((component.Type === 'Control' && component.RefID === 'if')) {
    datas.push(component.ID);
    isParentStart = true;
  }

  if (isParentStart && component.Type !== 'Control' && component.RefID !== 'if' && component.RefID !== 'if_end') {
    childItems.push(component.ID)
  }

  if (component.Type === 'Control' && component.RefID === '"if_end"') {
    datas.push(component.ID);
    isParentStart = false
  }

  if (component.children && component.children.length > 0) {
    getAllComponents(component.children[0], datas, childItems, isParentStart);
  }
  return {
    componentSequence: datas,
    childItems: childItems
  };
}


function getSourceMap(connections) {
  let map = {}
  if (connections) {
    connections.forEach(function (con) {
      if (map[con.SourceComponentID] === undefined) {
        map[con.SourceComponentID] = []
        map[con.SourceComponentID].push(con.TargetComponentID)
      } else {
        map[con.SourceComponentID].push(con.TargetComponentID)
      }
    })
  }
  return map
}

function getControlEndId(sourceMap, componentMap, sourceId) {
  let targetId = null;
  if (sourceMap[sourceId]) {
    let targets = sourceMap[sourceId];
    targets.forEach(function (itm) {
      let data = componentMap[itm];
      if (data.Type === 'Control' && data.RefID === 'if_end') {
        targetId = itm
      }
    })
  }
  return targetId
}

function getComponentMap(components) {
  let map = {}
  components.forEach(function (com) {
    map[com.ID] = com
  })
  return map;
}

function getComponents(current, end, result, sourceMap, componentMap, alreadyAdded, originalStart) {
  if (sourceMap[current] === undefined || sourceMap[current].length <= 0) {
    return result;
  }
  let targetPoints = sourceMap[current];
  // console.log(current, '-------------------Currentttttttttttt')
  // console.log(targetPoints, 'targetPoints')
  targetPoints.forEach(function (tar) {
    if (alreadyAdded.indexOf(tar) === -1 && end !== tar) {
      let data = componentMap[tar]
      alreadyAdded.push(tar)
      // console.log(tar, 'tar')
      // console.log(data, 'data')
      result.push(data)
      getComponents(tar, end, result, sourceMap, componentMap, alreadyAdded, originalStart)
    }
  })
  return result
}



export function getComponentChild(nodes, links, componentId) {
  // console.log('----------------------------')
  // console.log(nodes, 'Nodesssssssssss')
  // console.log(links, 'linksssssssssssss')
  if (nodes && links) {
    const connectionSourceMap = getSourceMap(links)
    const componentMap = getComponentMap(nodes)
    const endControlId = getControlEndId(connectionSourceMap, componentMap, componentId)
    console.log(connectionSourceMap, 'connectionSourceMap')
    console.log(componentMap, 'componentMap')
    console.log(componentId, 'startControlId')
    console.log(endControlId, 'endControlId')
    let finalDatas = getComponents(componentId, endControlId, [], connectionSourceMap, componentMap, [], componentId)
    return finalDatas;
  } else {
    return []
  }

}

export function getComponentType(slug) {
  let type = ''
  if (slug === 'ACTION') type = { type: 'Action', refID: '', refType: '' }
  if (slug === 'IF') type = { type: 'Control', refID: 'if', refType: 'Master' }
  if (slug === 'IF_ELSE') type = { type: 'Control', refID: 'ifelse', refType: 'Master' }
  if (slug === 'LOOP') type = { type: 'Control', refID: 'for', refType: 'Master' }
  if (slug === 'FUNCTION_CALL') type = { type: 'Control', refID: '', refType: '' }
  return type;
}

export function getTargetComponentId(sourceId, connections) {
  let targetId = null;
  if (connections) {
    try {
      targetId = connections.filter((co) => co.SourceComponentID === sourceId)[0].ID
    } catch (e) { }
  }

  if (targetId === undefined || targetId === null) {
    targetId = sourceId
  }

  return targetId
}


export function getImageUsingType(slug, classIcon) {
  let res = ''
  if (slug === 'TEXT') res = <TextFormatIcon className={classIcon} />
  if (slug === 'TEXTAREA') res = <TextFormatIcon className={classIcon} />
  if (slug === "DATETIME") res = <CalendarTodayIcon className={classIcon} />
  if (slug === "DATE") res = <CalendarTodayIcon className={classIcon} />
  if (slug === 'NUMBER') res = <Filter1Icon className={classIcon} />
  if (slug === 'OBJECT') res = <FlipToBackIcon className={classIcon} />
  if (slug === 'SINGLE_SELECT') res = <ShortTextIcon className={classIcon} />
  if (slug === "MULTI_SELECT") res = <ShareIcon className={classIcon} />
  if (slug === "BOOLEAN") res = <ConfirmationNumberIcon className={classIcon} />
  return res;
}