import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_EVALUATION_DOWNLOAD_REPORT         =   "START_EVALUATION_DOWNLOAD_REPORT";
export const END_EVALUATION_DOWNLOAD_REPORT         =   "END_EVALUATION_DOWNLOAD_REPORT";

function startEvaluationDownloadReport(){
    return {
        type:START_EVALUATION_DOWNLOAD_REPORT,
        payload:{}
    };
}

function endEvaluationDownloadReport(success,error){
    return {
        type:END_EVALUATION_DOWNLOAD_REPORT,
        payload:{
            success,error
        }
    };
}

export function downloadEvaluationReport(evaluationID,data){
    const api_server = Environment.api_host("REPORT");
    const timestamp     =   (new Date()).getTime();

    const url   =   `${api_server}/evaluation/${evaluationID}/generate-report?t=${timestamp}`;
    // const url   =   `${api_server}/${evaluationId}/approvals?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEvaluationDownloadReport());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endEvaluationDownloadReport(response,error));
        } catch(e){
            dispatch(endEvaluationDownloadReport(null,{
                message:e.message
            }));
            return;
        }
    };
}