import {
    START_ADD_THREAD, END_ADD_THREAD,
    START_FETCH_THREAD, END_FETCH_THREAD,
    START_FETCH_THREAD_REPLIES, END_FETCH_THREAD_REPLIES,
    START_UPDATE_THREAD, END_UPDATE_THREAD,
    START_DELETE_THREAD, END_DELETE_THREAD,
} from "./action";

const initState = {
    threadReplies: [],
    fetchThreadRepliesProgress: false,
    fetchThreadRepliesError: null,

    threads: [],
    fetchThreadProgress: false,
    fetchThreadError: null,

    deleteThreadProgress: false,
    deleteThreadSuccess: null,
    deleteThreadError: null,

    updateThreadProgress: false,
    updateThreadSuccess: null,
    updateThreadError: null,

    addThreadProgress: false,
    addThreadSuccess: null,
    addThreadError: null,
}

export function evaluationThreadReducer(state = initState, action) {
    switch (action.type) {

        case START_ADD_THREAD:
            return {
                ...state,
                addThreadProgress: true,
                addThreadSuccess: null,
                addThreadError: null,
            };
        case END_ADD_THREAD:
            return {
                ...state,
                addThreadProgress: false,
                addThreadSuccess: action.payload.success,
                addThreadError: action.payload.error
            };
        case START_UPDATE_THREAD:
            return {
                ...state,
                updateThreadProgress: true,
                updateThreadSuccess: null,
                updateThreadError: null,
            };
        case END_UPDATE_THREAD:
            return {
                ...state,
                updateThreadProgress: false,
                updateThreadSuccess: action.payload.success,
                updateThreadError: action.payload.error
            };
        case START_DELETE_THREAD:
            return {
                ...state,
                deleteThreadProgress: true,
                deleteThreadSuccess: null,
                deleteThreadError: null,
            };
        case END_DELETE_THREAD:
            return {
                ...state,
                deleteThreadProgress: false,
                deleteThreadSuccess: action.payload.success,
                deleteThreadError: action.payload.error
            };

        case START_FETCH_THREAD_REPLIES:
            return {
                ...state,
                threadReplies: [],
                fetchThreadRepliesProgress: true,
                fetchThreadRepliesError: null
            };
        case END_FETCH_THREAD_REPLIES:
            var { success, error, threadId } = action.payload;
            let newThreads = [...state.threads]
            if (!error) {
                let indexItm = newThreads.findIndex(o => o.ID === threadId);
                newThreads[indexItm] = { ...newThreads[indexItm], ChildThreads: success };
            }
            return {
                ...state,
                threads: newThreads,
                fetchThreadRepliesProgress: false,
                fetchThreadRepliesError: error,
                threadRepliess: success
            };

        case START_FETCH_THREAD:
            return {
                ...state,
                threads: [],
                fetchThreadProgress: true,
                fetchThreadError: null
            };
        case END_FETCH_THREAD:
            var threads = action.payload.success

            return {
                ...state,
                fetchThreadProgress: false,
                fetchThreadError: action.payload.error,
                threads: threads
            };

        default:
            return state;
    }
}