import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import FlashAutoIcon from "@material-ui/icons/FlashAuto";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Button, IconButton, Typography, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { showSnackBar } from "redux/snackbar/action";
import { sellerGenieQuestionSearch } from "redux/seller/library/action";
import Box from "@material-ui/core/Box";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import { addSellerRequirementResponse } from "redux/seller/action";
import SqureButton from "Components/Common/SqureButton";
import { ArrowForwardIos as ArrowForwardIcon, ArrowBackIos as ArrowBackIcon, Send as SendIcon } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { addReqToSellerLibrary } from "redux/seller/evaluate/action";
import { addSellerAssessmentQuesionResponse } from "redux/seller/assessment/action";
import CustomTypeHandler from "../Common/CustomTypeHandler";

const connectedProps = (state) => ({
  thread: state.seller.thread,
  response: state.seller.response,
  addSellerAssessmentQuesionResponseProgress: state.sellerAssessment.addSellerAssessmentQuesionResponseProgress,
  addSellerAssessmentQuesionResponseError: state.sellerAssessment.addSellerAssessmentQuesionResponseError,
  genieQuestionSearchProgress: state.sellerLibrary.genieQuestionSearchProgress,
  genieQuestionSearchError: state.sellerLibrary.genieQuestionSearchError,
  genieQuestionSearchData: state.sellerLibrary.genieQuestionSearchData,
  sellerAssessmentDetails: state.sellerAssessment.sellerAssessmentDetails,
  addReqToSellerLibraryProgress: state.sellerEvaluate.addReqToSellerLibraryProgress,
  addReqToSellerLibraryError: state.sellerEvaluate.addReqToSellerLibraryError,
});

const connectionActions = {
  addSellerRequirementResponse: addSellerRequirementResponse,
  showSnackBar: showSnackBar,
  addSellerAssessmentQuesionResponse: addSellerAssessmentQuesionResponse,
  addReqToSellerLibrary: addReqToSellerLibrary,
  sellerGenieQuestionSearch: sellerGenieQuestionSearch,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: "20px",
    },
    form: {
      alignItems: "center",
      paddingTop: theme.spacing(1),
      flexDirection: "column",
      "& [class*='editableField']": {
        minHeight: "auto !important",
      },
    },
    thread: {
      // minHeight: "10vh"
    },
    response: {
      // background:"#f7f7f7",
      // borderRadius:theme.spacing(1),
      // padding:theme.spacing(1),
      // marginBottom:theme.spacing(1),
    },
    username: {
      fontWeight: "600",
      display: "flex",
      placeContent: "center space-between",
      alignItems: "center",
      fontSize: 14,
    },
    username1: {
      fontWeight: "600",
      display: "flex",
      placeContent: "center space-between",
      alignItems: "center",
      fontSize: 16,
      marginBottom: 10,
    },
    responseTime: {
      fontSize: 14,
      marginTop: 1,
    },
    sender: {
      width: "100%",
      marginBottom: 20,
      "& .messageHeader": {
        display: "flex",
        fontSize: 14,
        // textTransform: 'capitalize',
        "& span": {
          marginLeft: "10px",
        },
      },
      "& .messageContainer": {
        background: "#F3F4F5 0% 0% no-repeat padding-box",
        border: "1px solid #D5D8DC",
        maxWidth: "60%",
        textAlign: "left",
        fontSize: "14px",
        letterSpacing: "0px",
        color: "#1C2833",
        borderRadius: "18px !important",
        "border-top-left-radius": "0 !important",
        padding: "0px 16px",
        minWidth: 320,
      },
    },
    refreshIcon: {
      cursor: "pointer",
      position: "absolute",
      right: 5,
      top: 10,
      color: "#999999",
      transitionDuration: "5s",
      transitionProperty: "transform",
      zIndex: 1000,
    },
    underprogress: {
      transform: "rotate(360deg)",
      "-webkit-transform": "rotate(360deg) infinite linear",
    },
    receiver: {
      width: "100%",
      marginBottom: 20,
      height: "100%",
      // flexDirection: 'row',
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      placeContent: "flex-end space-between",
      alignItems: "flex-end",
      "& .messageHeader": {
        display: "flex",
        // textTransform: 'capitalize',
        "& span": {
          marginLeft: "10px",
        },
      },
      "& .messageContainer": {
        background: "#4b86f7 0% 0% no-repeat padding-box",
        // border: '1px solid #1C2833',
        maxWidth: "60%",
        textAlign: "left",
        fontSize: "14px",
        letterSpacing: "0px",
        color: "#ffffff",
        // float: 'right',
        borderRadius: "18px !important",
        "border-top-right-radius": "0 !important",
        padding: "0px 16px",
        minWidth: 320,
        "&  *": {
          color: "#ffffff !important",
        },
      },
    },
    chatStart: {
      // marginTop: '70px'
    },
    mainLoader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 100,
    },
    appBar: {
      background: "#fff",
      padding: theme.spacing(1, 2),
      boxShadow: "none",
      borderBottom: "2px solid #f1f1f1",
      marginBottom: 20,
    },
    draftBtn: {
      marginRight: 10,
      color: "#707070",
      borderColor: "#707070",
    },
    informationBox: {
      backgroundColor: "hsl(196deg 72% 93%)",
      border: "2px solid hsl(187deg 55% 82%)",
      padding: theme.spacing(1, 1),
      borderRadius: theme.spacing(1.25),
      marginBottom: theme.spacing(2),
      "& label": {
        display: "none",
      },
    },
    answerBox: {
      backgroundColor: "#fff",
      marginTop: theme.spacing(2),
      borderBottom: "1px solid #f1f1f1",
    },
    tabPanelRes: {
      backgroundColor: "#fff",
      padding: theme.spacing(2),
      marginBottom: 20,
      position: "relative",
      // minHeight: theme.spacing(34),
      "& [class*=MuiBox-root]": {
        padding: "1.5em 1.5em 1.5em 1.5em",
      },
      // "& [class*=makeStyles-label]": {
      //   display: "none",
      // },
    },
    addText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "80px",
      fontSize: 13,
      color: "#707070",
      cursor: "pointer",
      transition: "all linear .5s",
    },
    addTextimage: {
      marginRight: 10,
      width: 21,
      position: "relative",
      top: 2,
    },
    noteSection: {
      width: "calc(100% - 7px)",
      "& [class*='editableField']": {
        maxHeight: "105px !important;",
        minHeight: "105px !important",
      },
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    respoRoot: {
      // background: '#fff',
      paddingBottom: theme.spacing(2),
      position: "relative",
      // padding: theme.spacing(2),
    },
    collUsers: {
      position: "relative",
    },
    showNoteSection: {
      width: "calc(100% - 7px)",
      marginTop: 20,
      "& [class*='editableField']": {
        margin: "0px !important",
        minHeight: "auto !important",
        maxHeight: "auto !important",
      },
    },
    rNote: {
      height: "100%",
      minHeight: "25px",
      fontSize: 13,
      color: "#707070",
      width: "100%",
      // margin: '0px auto',
      overflow: "overlay",
      // textAlign:"center",
      // padding:theme.spacing(4)
    },
    requirementResponseDiv: {
      // marginBottom: 25,
      // border: '1px solid #f1f1f1',
      // padding: 10
    },
    actionBtns: {
      textAlign: "right",
    },
    resActionBtns: {
      marginTop: 20,
    },
    checkBox: {
      // color : 'red'
    },
    chatWithBuyerDiv: {
      textAlign: "center",
    },
    buyerBtn: {
      color: "#4b86ff",
      backgroundColor: "#fff",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#4b86ff",
      },
    },
    currProductTitle: {
      marginBottom: theme.spacing(2),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    alertBox: {
      marginBottom: 10,
    },
    nextPrevbtns: {
      marginTop: 5,
      textAlign: "center",
    },
    nextBtn: {
      marginLeft: 10,
    },
    arrowIcons: {
      "&:hover": {
        background: "#f1f1f1",
        cursor: "pointer",
        padding: "2px",
        borderRadius: "4px",
      },
    },
    infoWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    genieIconWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  });

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      selectedRequirement: "",
      selectedTab: 0,
      note: "",
      response: null,
      responseError: "",
      checkOnce: true,
      hideChatScreen: true,
      markAsDone: false,
      markAsDoneResponse: false,
      isCheckForNextInNewTree: false,
      isAddedinLibrary: false,
      quesLibraryState: false,
    };
  }

  componentDidMount() {
    this.setState({
      markAsDone: this.props.isCompletedQuestionaire,
    });
  }

  componentDidUpdate(preProps) {
    if (!this.props.addSellerAssessmentQuesionResponseProgress && preProps.addSellerAssessmentQuesionResponseProgress) {
      if (!this.props.addSellerAssessmentQuesionResponseError) {
        this.props.setSelectedQuestion(this.state.nextQuestion);
        this.setState({ isAddedinLibrary: false });
        this.props.showSnackBar("Success", "success", 3000);
      } else {
        console.warn("Something went wrong");
      }
    }

    if (!this.props.addReqToSellerLibraryProgress && preProps.addReqToSellerLibraryProgress) {
      if (this.props.addReqToSellerLibraryError === null) {
        this.props.showSnackBar("Seller library updated successfully", "success", 3000);
      } else {
        console.warn("Something went wrong");
      }
    }
  }

  getNextAndPreviousIds = () => {
    var nextReqId = null;
    var previousReqId = null;
    const sellerAssessmentDetails = this.props.sellerAssessmentDetails?.data || [];

    var currentReq = this.props.requirement;

    var indexOfCurrent = sellerAssessmentDetails.findIndex((item) => item.ID === currentReq.ID);
    var indexOfNext = null;
    var indexOfPrevious = null;

    if (indexOfCurrent !== sellerAssessmentDetails.length - 1) {
      indexOfNext = indexOfCurrent + 1;
      nextReqId = sellerAssessmentDetails[indexOfNext];
    }

    if (indexOfCurrent !== 0) {
      indexOfPrevious = indexOfCurrent - 1;
      previousReqId = sellerAssessmentDetails[indexOfPrevious];
    }

    return {
      nextReqId: nextReqId,
      previousReqId: previousReqId,
      indexOfCurrentReq: indexOfCurrent,
    };
  };

  updateQuestionResponse = (nextQuestion) => {
    const { note, requirement, answer_type, options, selectedOptions } = this.props;
    let { answer } = this.props;
    this.setState({ nextQuestion: nextQuestion });
    if (parseInt(answer_type) === 8) {
    } else if (parseInt(answer_type) === 11) {
      answer = answer.toISOString();
    }

    let params = {
      question_id: requirement?.ID,
      answer_id: requirement?.answers?.id,
      entity_type: "answer",
      answer: {
        created_from: "",
        note: note || "",
        answer_type: parseInt(answer_type),
        options: options,
        answer: answer,
        options_answer: selectedOptions,
      },
    };

    this.props.addSellerAssessmentQuesionResponse({
      action: "update",
      source: { id: this.props.match.params.uuid, name: "assessment" },
      data: [params],
    });

    if (this.state.isAddedinLibrary) {
      this.props.addReqToSellerLibrary({
        action: "add",
        source: { name: "imported", id: this.props.match.params.uuid },
        data: [{ question_id: requirement.ID }],
      });
    }
  };

  getConfidence = (score) => {
    // if (score <= 40) {
    //   return <div style={{ color: "red", display: "inline" }}>Low</div>;
    // } else if (score <= 70) {
    //   return <div style={{ color: "#ecb22e", display: "inline" }}>Medium</div>;
    // } else {
    //   return <div style={{ color: "#2eb77d", display: "inline" }}>High</div>;
    // }
    try {
      return Math.floor(score * 100) + "%";
    } catch (e) {
      return "0%";
    }
  };

  render() {
    const classes = this.props.classes;

    if (this.props.addSellerAssessmentQuesionResponseProgress) {
      return (
        <div className={classes.mainLoader}>
          <CircularProgress />
        </div>
      );
    }

    const { questionID, requirement, answer, answer_type, selectedOptions, options, note, completedQuestionsId, genieSummaryExist, genieQuestionResponseMap } = this.props;

    var nextPrevData = this.getNextAndPreviousIds();
    var nextReqId = nextPrevData.nextReqId;
    var previousReqId = nextPrevData.previousReqId;
    var indexOfCurrentReq = nextPrevData.indexOfCurrentReq;
    let qRes = genieQuestionResponseMap[questionID] || {};
    let GNRes = requirement?.search_response?.match_objects?.map((O) => ({ ...O, type: "needs_review" }));
    let GARes = requirement?.search_response?.match_objects?.map((O) => ({ ...O, type: "auto_populate" }));
    let hasRespondedNeedsReview = requirement?.answers.created_from === "needs_review" && GNRes?.length > 0;
    let hasAutoPopulated = requirement?.answers.created_from === "genie" && GARes?.length > 0;
    let hasNotRespondedNeedsReview = qRes.length > 0 && qRes[0]?.type === "needs_review" && genieSummaryExist && !completedQuestionsId?.includes(questionID);

    return (
      <div className={classes.root}>
        <div>
          <Box component="span" display="block" className={classes.informationBox}>
            <Typography className={classes.infoText}>
              <SlateInputFieldV2
                readOnly={true}
                isToolBar={false}
                placeholder=""
                style={{
                  margin: 0,
                }}
                as={SlateInputField}
                initValue={requirement?.question?.description}
                formControlStyle={{ border: "none", borderColor: "hsl(196deg 72% 93%)", overflow: "hidden" }}
                textContainerStyle={{ minHeight: "100px !important", margin: "0px", background: "hsl(196deg 72% 93%)", color: "#707070", fontSize: 16 }}
              />
            </Typography>
          </Box>
        </div>

        <div className={classes.respoRoot}>
          <div className={classnames(classes.tabPanelRes)}>
            {requirement !== undefined && requirement !== null && (
              <div className={classes.requirementResponseDiv}>
                <div className={classes.genieIconWrap}>
                  {hasRespondedNeedsReview || hasNotRespondedNeedsReview || hasAutoPopulated ? (
                    <>
                      {hasAutoPopulated && (
                        <div className={classes.infoWrap}>
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<FlashAutoIcon />}
                            onClick={() => {
                              this.props.openChooseResponseDialog(GARes, indexOfCurrentReq);
                            }}
                          >
                            Genie Auto Populated
                          </Button>
                        </div>
                      )}
                      {hasRespondedNeedsReview && (
                        <div className={classes.infoWrap}>
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<FlashAutoIcon />}
                            onClick={() => {
                              this.props.openChooseResponseDialog(GNRes, indexOfCurrentReq);
                            }}
                          >
                            Genie Powered Response Selected
                          </Button>
                        </div>
                      )}
                      {hasNotRespondedNeedsReview && (
                        <div className={classes.infoWrap}>
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<FlashAutoIcon />}
                            onClick={() => {
                              this.props.openChooseResponseDialog(qRes, indexOfCurrentReq);
                            }}
                          >
                            Genie Powered Response Available
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div></div>
                  )}
                  {this.props.genieQuestionSearchProgress ? (
                    <CircularProgress style={{ width: 25, height: 25 }} />
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => {
                        let is_global;
                        if (this.props.sellerAssessmentDetails?.data?.length > 0) {
                          let data = this.props.sellerAssessmentDetails?.data[0] || {};
                          if (data?.assessment?.name) {
                            is_global = data?.assessment?.is_global;
                          }
                        }
                        this.props.sellerGenieQuestionSearch(
                          {
                            search_type: "embeddings",
                            confidence: "high",
                            question_ids: [questionID],
                            filters: { is_global: is_global, different_answer_type: true },
                          },
                          indexOfCurrentReq
                        );
                      }}
                    >
                      <Tooltip
                        className={"tooltip"}
                        placement="top"
                        title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Use Genie</span>}
                        arrow
                      >
                        <FlashAutoIcon style={{ color: "#1f73b7" }} />
                      </Tooltip>
                    </IconButton>
                  )}
                </div>

                <CustomTypeHandler
                  requirement={{ answer, answer_type, selectedOptions, options, note }}
                  isActive={true}
                  id={questionID}
                  answer_type={answer_type}
                  selectedOptions={selectedOptions}
                  isUpdateOnChange={true}
                  onChangeType={() => {}}
                  onChangeData={(item) => {
                    this.props.updateResponse(item);
                  }}
                />
                <Grid container className={classes.resActionBtns}>
                  <Grid item xs={5} lg={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={false}
                          className={classes.checkBox}
                          checked={this.state.isAddedinLibrary}
                          color="default"
                          onChange={(event) => {
                            this.setState({
                              isAddedinLibrary: this.state.isAddedinLibrary ? false : true,
                            });
                          }}
                          name="add_to_library"
                        />
                      }
                      label="Add to Library"
                    />
                  </Grid>

                  <Grid item xs={2} lg={2}>
                    <div className={classes.nextPrevbtns}>
                      <Tooltip
                        className={"tooltip"}
                        placement="top"
                        title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Previous Question</span>}
                        arrow
                      >
                        <ArrowBackIcon
                          className={classes.arrowIcons}
                          onClick={() => {
                            this.props.setSelectedQuestion(previousReqId);
                          }}
                        />
                      </Tooltip>

                      <Tooltip
                        className={"tooltip"}
                        placement="top"
                        title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Next Question</span>}
                        arrow
                      >
                        <ArrowForwardIcon
                          className={classes.arrowIcons}
                          onClick={(e) => {
                            this.props.setSelectedQuestion(nextReqId);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item xs={5} lg={5}>
                    <div className={classes.actionBtns}>
                      <SqureButton
                        disabled={this.props.addSellerAssessmentQuesionResponseProgress}
                        variant={"contained"}
                        endIcon={<SendIcon />}
                        onClick={() => {
                          this.updateQuestionResponse(nextReqId);
                        }}
                      >
                        Submit
                      </SqureButton>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
          {/* <div className={classnames(classes.tabPanelRes)}>
                    <CachedIcon className={classnames(classes.refreshIcon, this.props.threadFetchProgress === true ? classes.underprogress : null)} onClick={() => {
                        this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId)
                    }} />
                    <Typography variant={"h6"} className={classes.currProductTitle}>
                        Chat with {this.props.evaluation.orgName}
                    </Typography>
                    <div className={classnames(classes.thread)}>
                        {this.props.thread.map((response, k) => {
                            let $previousValue = this.props.thread[k - 1];
                            if (response.IsDraft || k === 0) {
                                return '';
                            }
                            return <div className={classnames(
                                classes.response,
                                response.Type === 1 ? classes.sender : classes.receiver,
                                $previousValue?.Type === 1 && response?.Type === 2 ? classes.chatStart : ''
                            )} key={response.id}>
                                <div className={'messageHeader'}>
                                    {
                                        response.Type === 1 ?
                                            <Typography className={classes.username}>{response.UserName}</Typography>
                                            : <Typography className={classes.username}>You</Typography>
                                    }
                                    &nbsp;<span className={classes.responseTime}>{CommonFn.formateDateTime(response.UpdatedAt)}</span>
                                </div>
                                <div className={'messageContainer'}>
                                    <SlateReadonlyField initValue={response.Response} />
                                </div>
                            </div>
                        })
                        }
                    </div>
                    <div className={classes.form}>
                        {this.state.hideChatScreen && <div className={classes.chatWithBuyerDiv}>
                            <Button className={classes.buyerBtn} variant="contained" color="primary" endIcon={<ChatIcon />} onClick={() => {
                                this.setState({
                                    hideChatScreen: false
                                })
                            }}>
                                Chat with Buyer
                            </Button>
                        </div>}
                        {!this.state.hideChatScreen && <div className={classes.chatWithBuyerScreen} id="chatWithBuyerScreenMsg">
                            <SlateInputField
                                style={{ width: '100%' }}
                                as={SlateInputField}
                                onChangeEvent={(value) => {
                                    this.setState({ message: value });
                                }}
                                placeholder=""
                                initValue={this.state.message}
                                formControlStyle={{ overflow: 'overlay' }}
                                textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
                                external={true}
                                folderPath={this.props.match.params.uuid + "/" + this.props.selectedRequirementId}
                            />

                            <div style={{ textAlign: 'right' }}>
                                <SqureButton style={{ marginRight: 10 }} variant={"outlined"} onClick={() => {
                                    this.setState({
                                        hideChatScreen: true,
                                        message: ''
                                    })
                                }}>Cancel</SqureButton>
                                <SqureButton style={{ "marginLeft": "10px", background: '#398af5' }} variant={"contained"} endIcon={<SendIcon />} onClick={this.submit}>Send</SqureButton>
                            </div>
                        </div>}

                        <FinalizeRequiremntViewDialog />

                        <BottomActions
                            saveAsDraft={this.saveAsDraft}
                            submit={this.submit}
                            submitQuesnnaire={this.submitQuesnnaire}
                            isDisable={this.props.isCompletedQuestionaire}
                            isBackBtnRequired={this.props.isInstructionAvailable}
                            goToInstruction={() => {
                                this.props.showInstruction()
                            }}
                        />

                        <MessageDialog messageData={messageData} callBack={this.callBack} />

                        <ConfirmCallbackDialog
                            messageData={messageDataQuestionaire}
                            callBack={this.callBackQuesnnaire}
                            closeCallBack={this.onCloseDialog}
                            closeDialog={this.onCloseDialog}
                        />
                    </div>
                </div> */}
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Chat));
