import {
  START_FETCH_SELLER_ASSESSMENTS,
  END_FETCH_SELLER_ASSESSMENTS,
  START_DELETE_SELLER_ASSESSMENT,
  END_DELETE_SELLER_ASSESSMENT,
  START_FETCH_SELLER_ASSESSMENT_DETAILS,
  END_FETCH_SELLER_ASSESSMENT_DETAILS,
  SHOW_IMPORT_ASSESSMENT_DIALOG,
  HIDE_IMPORT_ASSESSMENT_DIALOG,
  SET_IMPORT_ASSESSMENT_DATA,
  START_ADD_ASSESSMENT_QUESTION_RESPONSE,
  END_ADD_ASSESSMENT_QUESTION_RESPONSE,
  START_IMPORT_SELLER_ASSESSMENT,
  END_IMPORT_SELLER_ASSESSMENT,
  START_EXPORT_SELLER_ASSESSMENT,
  END_EXPORT_SELLER_ASSESSMENT,
  START_FETCH_SEARCH_SETTNGS,
  END_FETCH_SEARCH_SETTNGS,
  START_UPDATE_SEARCH_SETTNGS,
  END_UPDATE_SEARCH_SETTNGS,
} from "./action";

const initState = {
  sellerAssessmentDetailsProg: false,
  sellerAssessmentDetailsError: null,
  sellerAssessmentDetails: [],

  sellerAssessmentsProg: false,
  sellerAssessmentsError: null,
  sellerAssessments: [],

  importAssessmentDialogOpen: false,
  importAssessmentData: null,

  importSellerAssessmentProgress: false,
  importSellerAssessmentError: null,
  sellerAssessmentImportSuccess: {},

  exportSellerAssessmentProgress: false,
  exportSellerAssessmentError: null,
  sellerAssessmentExportSuccess: {},

  deleteSellerAssessmentProg: false,
  deleteSellerAssessmentError: null,

  addSellerAssessmentQuesionResponseProgress: false,
  addSellerAssessmentQuesionResponseError: null,
  addSellerAssessmentQuesionResponseSuccess: null,

  searchSettingsProg: false,
  searchSettingsError: null,
  searchSettings: {},

  updateSearchSettingsProg: true,
  updateSearchSettingsError: null,
};

export function sellerAssessmentReducer(state = initState, action) {
  switch (action.type) {
    case SHOW_IMPORT_ASSESSMENT_DIALOG:
      return {
        ...state,
        importAssessmentDialogOpen: true,
        importAssessmentData: null,
      };

    case HIDE_IMPORT_ASSESSMENT_DIALOG:
      return {
        ...state,
        importAssessmentDialogOpen: false,
        importAssessmentData: null,
      };
    case SET_IMPORT_ASSESSMENT_DATA:
      return {
        ...state,
        importAssessmentData: action.payload.data,
      };
    case START_IMPORT_SELLER_ASSESSMENT:
      return {
        ...state,
        importSellerAssessmentProgress: true,
        importSellerAssessmentError: null,
        sellerAssessmentImportSuccess: {},
      };
    case END_IMPORT_SELLER_ASSESSMENT:
      return {
        ...state,
        importSellerAssessmentProgress: false,
        importSellerAssessmentError: action.payload.error,
        sellerAssessmentImportSuccess: action.payload.success,
      };
    case START_EXPORT_SELLER_ASSESSMENT:
      return {
        ...state,
        exportSellerAssessmentProgress: true,
        exportSellerAssessmentError: null,
        sellerAssessmentExportSuccess: {},
      };
    case END_EXPORT_SELLER_ASSESSMENT:
      return {
        ...state,
        exportSellerAssessmentProgress: false,
        exportSellerAssessmentError: action.payload.error,
        sellerAssessmentExportSuccess: action.payload.success,
      };
    case START_FETCH_SELLER_ASSESSMENT_DETAILS:
      return {
        ...state,
        sellerAssessmentDetailsProg: true,
        sellerAssessmentDetailsError: null,
      };
    case END_FETCH_SELLER_ASSESSMENT_DETAILS: {
      const { success, error } = action.payload;
      let sellerAssessmentDetails = success;
      return {
        ...state,
        sellerAssessmentDetailsProg: false,
        sellerAssessmentDetailsError: error,
        sellerAssessmentDetails,
      };
    }
    case START_FETCH_SELLER_ASSESSMENTS:
      return {
        ...state,
        sellerAssessmentsProg: true,
        sellerAssessmentsError: null,
      };
    case END_FETCH_SELLER_ASSESSMENTS: {
      const { success, error } = action.payload;
      let sellerAssessments = success || [];

      return {
        ...state,
        sellerAssessmentsProg: false,
        sellerAssessmentsError: error,
        sellerAssessments,
      };
    }
    case START_DELETE_SELLER_ASSESSMENT:
      return {
        ...state,
        deleteSellerAssessmentProg: true,
        deleteSellerAssessmentError: null,
      };
    case END_DELETE_SELLER_ASSESSMENT: {
      const { error } = action.payload;

      return {
        ...state,
        deleteSellerAssessmentProg: false,
        deleteSellerAssessmentError: error,
      };
    }
    case START_ADD_ASSESSMENT_QUESTION_RESPONSE:
      return {
        ...state,
        addSellerAssessmentQuesionResponseProgress: true,
        addSellerAssessmentQuesionResponseSuccess: null,
        addSellerAssessmentQuesionResponseError: null,
      };

    case END_ADD_ASSESSMENT_QUESTION_RESPONSE:
      let newSellerAssessmentDetails = { ...state.sellerAssessmentDetails };
      if (action.payload.success?.Data) {
        let datas = action.payload.success?.Data || [];
        datas?.forEach((data) => {
          let index = newSellerAssessmentDetails?.data?.findIndex((item) => item.ID === data.ID);
          newSellerAssessmentDetails.data[index] = data;
        });
      }
      return {
        ...state,
        sellerAssessmentDetails: newSellerAssessmentDetails,
        addSellerAssessmentQuesionResponseProgress: false,
        addSellerAssessmentQuesionResponseSuccess: action.payload.success,
        addSellerAssessmentQuesionResponseError: action.payload.error,
      };
    case START_FETCH_SEARCH_SETTNGS:
      return {
        ...state,
        searchSettingsProg: true,
        searchSettingsError: null,
        searchSettings: {},
      };
    case END_FETCH_SEARCH_SETTNGS: {
      const { success, error } = action.payload;
      let searchSettings = success || {};

      return {
        ...state,
        searchSettingsProg: false,
        searchSettingsError: error,
        searchSettings,
      };
    }
    case START_UPDATE_SEARCH_SETTNGS:
      return {
        ...state,
        updateSearchSettingsProg: true,
        updateSearchSettingsError: null,
      };
    case END_UPDATE_SEARCH_SETTNGS: {
      const { error } = action.payload;

      return {
        ...state,
        updateSearchSettingsProg: false,
        updateSearchSettingsError: error,
      };
    }
    default:
      return state;
  }
}
