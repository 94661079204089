import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

export const START_FETCH_PRODUCT_CONTRACT = "START_FETCH_PRODUCT_CONTRACT";
export const END_FETCH_PRODUCT_CONTRACT = "END_FETCH_PRODUCT_CONTRACT";

export const START_ADD_PRODUCT_CONTRACT = "START_ADD_PRODUCT_CONTRACT";
export const END_ADD_PRODUCT_CONTRACT = "END_ADD_PRODUCT_CONTRACT";

export const START_UPDATE_PRODUCT_CONTRACT = "START_UPDATE_PRODUCT_CONTRACT";
export const END_UPDATE_PRODUCT_CONTRACT = "END_UPDATE_PRODUCT_CONTRACT";

export const START_DELETE_PRODUCT_CONTRACT = "START_DELETE_PRODUCT_CONTRACT";
export const END_DELETE_PRODUCT_CONTRACT = "END_DELETE_PRODUCT_CONTRACT";

export const START_ADD_PRODUCT_CONTRACT_COLLABORATORS = "START_ADD_PRODUCT_CONTRACT_COLLABORATORS";
export const END_ADD_PRODUCT_CONTRACT_COLLABORATORS = "END_ADD_PRODUCT_CONTRACT_COLLABORATORS";

export const START_FETCH_DOCUMENT_TYPES = "START_FETCH_DOCUMENT_TYPES";
export const END_FETCH_DOCUMENT_TYPES = "END_FETCH_DOCUMENT_TYPES";

function startFetchProductContract(){
    return {
        type:START_FETCH_PRODUCT_CONTRACT,
        payload:{}
    };
}

function endFetchProductContract(success,error){
    return {
        type:END_FETCH_PRODUCT_CONTRACT,
        payload:{
            success,error
        }
    };
}

export function fetchProductContract(evaluationID){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductContract());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProductContract(response,error));
        } catch(e){
            dispatch(endFetchProductContract(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startAddProductContract(){
    return {
        type:START_ADD_PRODUCT_CONTRACT,
        payload:{}
    };
}

function endAddProductContract(success,error){
    return {
        type:END_ADD_PRODUCT_CONTRACT,
        payload:{
            success,error
        }
    };
}

export function addProductContract(evaluationID,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddProductContract());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddProductContract(response,error));
        } catch(e){
            dispatch(endAddProductContract(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startAddProductContractColloborators(){
    return {
        type:START_ADD_PRODUCT_CONTRACT_COLLABORATORS,
        payload:{}
    };
}

function endAddProductContractColloborators(success,error){
    return {
        type:END_ADD_PRODUCT_CONTRACT_COLLABORATORS,
        payload:{
            success,error
        }
    };
}

export function addProductContractColloborators(evaluationID,stepId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline/${stepId}/collaborators?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddProductContractColloborators());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddProductContractColloborators(response,error));
        } catch(e){
            dispatch(endAddProductContractColloborators(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startUpdateProductContract(){
    return {
        type:START_UPDATE_PRODUCT_CONTRACT,
        payload:{}
    };
}

function endUpdateProductContract(success,error){
    return {
        type:END_UPDATE_PRODUCT_CONTRACT,
        payload:{
            success,error
        }
    };
}

export function updateProductContract(evaluationID,stepId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline/${stepId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateProductContract());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateProductContract(response,error));
        } catch(e){
            dispatch(endUpdateProductContract(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startDeleteProductContract(){
    return {
        type:START_DELETE_PRODUCT_CONTRACT,
        payload:{}
    };
}

function endDeleteProductContract(success,error){
    return {
        type:END_DELETE_PRODUCT_CONTRACT,
        payload:{
            success,error
        }
    };
}

export function deleteProductContract(evaluationID,stepId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline/${stepId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteProductContract());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url,data);
            dispatch(endDeleteProductContract(response,error));
        } catch(e){
            dispatch(endDeleteProductContract(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startFetchDocumentTypes(){
    return {
        type:START_FETCH_DOCUMENT_TYPES,
        payload:{}
    };
}

function endFetchDocumentTypes(success,error){
    return {
        type:END_FETCH_DOCUMENT_TYPES,
        payload:{
            success,error
        }
    };
}

export function fetchDocumentTypes(evaluationID){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchDocumentTypes());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchDocumentTypes(response,error));
        } catch(e){
            dispatch(endFetchDocumentTypes(null,{
                message:e.message
            }));
            return;
        }
    };
}