import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography, InputAdornment, Button, List, ListItem, ListItemText, ListItemSecondaryAction, FormControl, Select, MenuItem } from "@material-ui/core";
import ReactSelect from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import Image from 'Components/Common/image.jsx';
import BottomActions from "./BottomActions";
import { updateAutomationAction, showSelectOptionFieldsDialog, updateActionConfigOnChange } from 'redux/automations/action';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import Variables from "./Variables";
import OptionalFieldsDialog from "../../../Dialogs/OptionalFieldsDialog";
import AddFieldDialog from "../../../Dialogs/AddFieldDialog";
import deleteIcon from "assets/images/delete.svg";

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/prism';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import TextInput from "./commonTypes/TextInput";

const connectedProps = (state) => ({
    isOpenOptionalFieldsDialog: state.automation.dialogSelectAutomationOptionFieldsOpen,
    updateActionConfigProgress: state.automation.updateActionConfigProgress,
    updateActionConfigError: state.automation.updateActionConfigError,
    updateActionConfigSuccess: state.automation.updateActionConfigSuccess,

    fetchComponentVariablesProgress: state.automation.fetchComponentVariablesProgress,
});

const connectionActions = {
    updateAutomationAction: updateAutomationAction,
    showSelectOptionFieldsDialog: showSelectOptionFieldsDialog,
    updateActionConfigOnChange: updateActionConfigOnChange
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    rootConfig: {
        position: 'relative',
        height: '85vh',
        padding: 20,
        overflow: 'auto'
    },
    actionBtns: {
        position: "absolute",
        bottom: 0,
        right: 10,
        left: "auto",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: '10px'
    },
    closeIcon: {
        cursor: 'pointer',
        maxWidth: 18,
    },
    closeIconSection: {
        position: 'absolute',
        top: '0px',
        right: 0,
        backgroundColor: '#fff',
        zIndex: 10,
        borderRadius: '0px !important',
        '&:hover': {
            backgroundColor: '#fff',
        }
    },
    title: {
        marginBottom: 20,
        fontWeight: 500
    },
    inputSection: {
        // display: "flex",
        // justifyContent: "space-between",
    },
    inputBoxes: {
        marginTop: 21,
        width: '100%',
        '& input': {
            width: '100%'
        },
        '& [class*="react-datepicker-wrapper"]': {
            width: '100%!important'
        }
    },
    inputValueBox: {
        width: '100%',
        '& input': {
            width: '100%'
        },
        '& [class*="react-datepicker-wrapper"]': {
            width: '100%!important'
        }
    },
    inputBoxesSchema: {
        width: '100%',
        '& input': {
            width: '100%'
        },
        '& [class*="react-datepicker-wrapper"]': {
            width: '100%!important'
        },
        '& [class*="Outlined-input"]': {
            marginBottom: 0
        }
    },
    datePicker: {
        '& [class*="react-datepicker-wrapper"]': {
            width: '100%!important'
        }
    },
    input: {
        minHeight: 38,
        padding: "2px 8px",
        border: "1px solid #cccccc",
        borderRadius: 3,
        fontSize: 15,
        color: "rgb(51, 51, 51)",
        width: '100%'
    },
    optionalFields: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    opCount: {
        border: '1px solid #6dc8ce',
        fontSize: 12,
        color: '#108291',
        padding: '4px 12px',
        cursor: 'pointer',
        fontWeight: 600,
        borderRadius: 4,
        '&:hover': {
            border: '1px solid #108291',
            color: '#6dc8ce',
        }
    },
    calendarPopout: {
        zIndex: '555555 !important'
    },
    fieldEmptyState: {
        alignItems: "center",
        background: "#ffffff",
        border: "1px dashed  #bac4cc",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        padding: "25px"
    },
    field_empty_state__caption: {
        "fontWeight": "600",
        "marginBottom": "20px",
        "textAlign": "center"
    },
    fieldEmptyStateaction: {
        marginBottom: 10
    },
    ioListItem: {
        border: "1px solid #cccccc",
        marginBottom: 10
    },
    ioListItemGutters: {
        paddingLeft: 0
    },
    ioOutputListItemGutters: {
        paddingLeft: 16
    },
    deleteIcon: {
        cursor: 'pointer'
    },
    deleteInputIcon: {
        cursor: 'pointer',
        position: 'absolute'
    },
    schemaBody: {
        border: "1px solid #cccc",
        padding: 10,
    },
    formControl: {
        width: '100%'
    },
    inTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    inTitleText: {
        cursor: 'pointer',
        marginLeft: 10
    },
    inTitleTextActive: {
        marginLeft: 10,
        cursor: 'pointer',
        color: '#1f73b7'
    }
});

class ActionItemConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            slug: '',
            inputField: [],
            isEditName: false,
            showHideMap: {},
            isShowVariables: false,
            focusInputData: null,
            optionalItems: [],
            optionalSelectedFields: [],
            isOpenAddFieldDialog: false,
            selectedSchemaField: null,
            defaultCode: `// @param input fields supplied in the recipe step, as an object \n// @return object matching the output schema\n// Eg: Code for returning time zone for an IP address`,
            focusInputIOData: null
        }
        this.handleAddVariable = this.handleAddVariable.bind(this)
        this.showAddFieldDialog = this.showAddFieldDialog.bind(this)
    }

    componentDidMount() {
        this.initializeConfigurations()
    }

    componentDidUpdate(prevProps) {
        if (!this.props.updateActionConfigProgress && prevProps.updateActionConfigProgress) {
            if (this.props.updateActionConfigError === null) {
                this.initializeConfigurationsOnChange()
            }
        }
    }

    getSelectedOption(options, selectedValue) {
        let response = ''
        try {
            response = options.filter((op) => op.value === selectedValue)[0]
        } catch (e) {

        }
        return response;
    }

    initializeConfigurations() {
        const { inputJson, componentDetails } = this.props;
        let me = this;
        let inputData = inputJson && inputJson.length > 0 ? inputJson : [];
        let optionalItems = []
        let configItems = []
        let optionalSelectedFields = []
        if (inputData.length > 0) {
            let responseJson = {}
            try {
                responseJson = JSON.parse(componentDetails.InputData)
            } catch (e) { }
            inputData.forEach(function (itm) {
                itm.value = '';
                itm.valueType = 'DEFAULT';
                let optionalAdded = false;

                if (responseJson[itm.name + '_ValueType'] && responseJson[itm.name + '_ValueType'] !== '') {
                    itm.valueType = responseJson[itm.name + '_ValueType'];
                }

                if (responseJson[itm.name] && responseJson[itm.name] !== '') {
                    optionalAdded = true;
                    if (itm.type === "SINGLE_SELECT" && itm.valueType === 'DEFAULT') {
                        itm.value = me.getSelectedOption(itm.options, responseJson[itm.name])
                    } else {
                        itm.value = responseJson[itm.name]
                    }
                } else if (itm.type === "IO_SCHEMA") {
                    itm.value = []
                } else {
                    itm.value = ''
                }



                if (optionalAdded) {
                    configItems.push(itm)
                    if (itm.optional) {
                        optionalItems.push(itm)
                        let id = itm.name;
                        optionalSelectedFields.push(id)
                    }
                } else if (itm.optional) {
                    optionalItems.push(itm)
                } else {
                    configItems.push(itm)
                }
            })
            this.setState({
                inputField: configItems,
                optionalItems: optionalItems,
                optionalSelectedFields: optionalSelectedFields
            })
        }
    }

    initializeConfigurationsOnChange() {
        const { updateActionConfigSuccess } = this.props;
        let me = this;
        let inputJson = updateActionConfigSuccess?.InputRef?.input_json
        let inputData = inputJson && inputJson.length > 0 ? inputJson : [];
        let optionalItems = []
        let configItems = []
        let optionalSelectedFields = []
        if (inputData.length > 0) {
            let responseJson = {}
            try {
                responseJson = JSON.parse(updateActionConfigSuccess.InputData)
            } catch (e) { }
            inputData.forEach(function (itm) {
                itm.value = '';
                itm.valueType = 'DEFAULT';
                let optionalAdded = false;
                if (responseJson[itm.name + '_ValueType'] && responseJson[itm.name + '_ValueType'] !== '') {
                    itm.valueType = responseJson[itm.name + '_ValueType'];
                }
                if (responseJson[itm.name] && responseJson[itm.name] !== '') {
                    optionalAdded = true;
                    if (itm.type === "SINGLE_SELECT" && itm.valueType === 'DEFAULT') {
                        itm.value = me.getSelectedOption(itm.options, responseJson[itm.name])
                    } else {
                        itm.value = responseJson[itm.name]
                    }
                } else if (itm.type === "IO_SCHEMA") {
                    itm.value = []
                } else {
                    itm.value = ''
                }
                if (optionalAdded) {
                    configItems.push(itm)
                    if (itm.optional) {
                        optionalItems.push(itm)
                        let id = itm.name;
                        optionalSelectedFields.push(id)
                    }
                } else if (itm.optional) {
                    optionalItems.push(itm)
                } else {
                    configItems.push(itm)
                }
            })
            this.setState({
                inputField: configItems,
                optionalItems: optionalItems,
                optionalSelectedFields: optionalSelectedFields
            })
        }
    }

    handleChange = (value, index, type) => {
        var inputData = [...this.state.inputField];
        inputData.forEach((item, mapIndex) => {
            if (index === mapIndex) {
                item.value = value;
            }
        });
        this.setState({
            inputField: inputData,
            error: ''
        })
    }

    handleChangeSingleSelect = (value, index, type) => {
        var inputData = [...this.state.inputField];
        inputData.forEach((item, mapIndex) => {
            if (index === mapIndex) {
                item.value = value;
            }
        });
        this.setState({
            inputField: inputData,
            error: ''
        }, () => {
            let params = this.populateInput()
            this.props.updateActionConfigOnChange(this.props.id, this.props.actionData.object.ID, {
                inputData: JSON.stringify(params),
            })
        })
    }

    handleChangeValueType = (value, index) => {
        var inputData = [...this.state.inputField];
        inputData.forEach((item, mapIndex) => {
            if (index === mapIndex) {
                item.valueType = value;
                item.value = '';
            }
        });
        this.setState({
            inputField: inputData,
            error: ''
        })
    }

    populateInput() {
        let params = {}

        this.state.inputField.forEach(function (ifield) {
            if (ifield.valueType === 'DEFAULT') {
                if (ifield.type === "SINGLE_SELECT" && ifield.value && ifield.value !== '') {
                    params[ifield.name] = ifield.value.value
                } else {
                    params[ifield.name] = ifield.value
                }
            } else {
                params[ifield.name] = ifield.value
            }
            params[ifield.name + '_ValueType'] = ifield.valueType
        })

        return params;
    }

    validateInputExpression(expression) {
        var input = this.populateInput()
        try {
            return eval(expression)
        } catch (e) {

        }
        return false;
    }

    handleFormSubmit = () => {
        this.setState({
            error: '',
            success: '',
        })
        let params = this.populateInput()

        this.props.updateAutomationAction(this.props.id, this.props.actionData.object.ID, {
            inputData: JSON.stringify(params),
        })
    }

    handleFormSubmit_bkp = () => {
        this.setState({
            error: '',
            success: '',
        })
        if (this.props.actionData?.type === "TRIGGER") {
            this.props.updateTrigger(this.props.actionData, this.state.inputField);
        } else {
            // this.props.updateAutomationAction(this.props.actionData, this.state.inputField, {
            //     name: this.state.name
            // });
            let params = JSON.stringify(this.state.inputField);
            this.props.updateAutomationAction(this.props.id, this.props.actionData.object.ID, {
                inputData: params,
                name: this.state.name
            })
        }
    }

    setSelected(index) {
        this.setState({
            focusInputData: index,
            isShowVariables: true
        })
    }

    setSelectedIOField(index, itemIndex) {
        this.setState({
            focusInputData: index,
            focusInputIOData: itemIndex,
            isShowVariables: true
        })
    }

    removeSelected() {
        this.setState({
            focusInputData: null,
            isShowVariables: false,
            selectedSchemaField: null,
            focusInputIOData: null
        })
    }

    handleAddVariable(component, variable) {
        const { focusInputData, focusInputIOData } = this.state;
        var inputData = [...this.state.inputField];
        let fieldD = inputData[focusInputData];
        if (focusInputIOData !== null) {
            let fields = fieldD.value;
            let field = fields[focusInputIOData]
            let oldValue = field.value;
            let newValue = '{{ .component_' + component.componentID + '.' + variable?.name + ' }}'
            field.value = oldValue + ' ' + newValue
        } else {
            let oldValue = fieldD.value;
            let newValue = '{{ .component_' + component.componentID + '.' + variable?.name + ' }}'
            fieldD.value = oldValue + ' ' + newValue
        }
        this.setState({
            inputField: inputData,
        }, () => {
            this.removeSelected()
        })
    }

    handleAddOptionalFields(fields, opIds) {
        let { inputField } = this.state;
        let newDatas = [...inputField];
        let alreadyAdded = inputField.filter(o => o.optional === true).map(j => j.name);
        fields.forEach(function (fl) {
            if (alreadyAdded.indexOf(fl.name) === -1) {
                newDatas.push(fl)
            }
        })
        this.setState({
            inputField: newDatas,
            optionalSelectedFields: opIds
        })
    }

    showAddFieldDialog(item, index) {
        this.setState({
            selectedSchemaField: {
                index: index,
                item: item
            },
            isOpenAddFieldDialog: true
        })
    }

    addField(data) {
        const { selectedSchemaField } = this.state;
        var inputData = [...this.state.inputField];
        let fieldD = inputData[selectedSchemaField.index];
        fieldD.value.push(data)
        this.setState({
            inputField: inputData,
        }, () => {
            this.removeSelected()
        })
    }

    deleteIOField(index, itemIndex) {
        var inputData = [...this.state.inputField];
        let fieldD = inputData[index];
        let fields = fieldD.value;
        fields.splice(itemIndex, 1);
        this.setState({
            inputField: inputData,
        })
    }

    handleChangeIoSchema = (value, index, itmIndex) => {
        var inputData = [...this.state.inputField];
        let fieldD = inputData[index];
        let fields = fieldD.value;
        let field = fields[itmIndex]
        field.value = value;
        this.setState({
            inputField: inputData,
            error: ''
        })
    }

    getVariableMap = () => {
        const { componentVariables } = this.props;
        let data = {
            map: {},
            variables: []
        }
        if (componentVariables) {
            componentVariables.forEach(function (component) {
                component.variables.forEach(function (variable) {
                    let newValue = '.component_' + component.componentID + '.' + variable?.name;
                    data.map[newValue] = variable?.label;
                    data.variables.push(newValue)
                })
            })
        }
        return data;
    }

    render() {
        const { classes, componentVariables } = this.props;
        const { name, inputField, isEditName, isShowVariables, optionalItems, optionalSelectedFields } = this.state;
        let variableDatas = this.getVariableMap();
        return (
            <div className={classes.rootConfig}>
                {/* {!isEditName && <Typography className={classes.title}>{name}
                    {this.props.actionData?.type !== "TRIGGER" && <IconButton aria-label="delete" className={classes.editIcon} onClick={() => {
                        this.setState({
                            isEditName: true
                        })
                    }}>
                        <EditIcon style={{ color: '#282D30' }} />
                    </IconButton>}
                </Typography>} */}


                {isEditName && <>
                    <div className={classes.inputBoxes}>
                        <input
                            className={classes.input}
                            disabled={false}
                            placeholder={'Please enter action name'}
                            onChange={(e) => {
                                this.setState({
                                    name: e.target?.value
                                })
                            }}
                            value={name}
                        />
                    </div>
                </>
                }
                {inputField.length > 0 &&
                    inputField.map((item, index) => {
                        if (item.ngIf && item.ngIf !== '') {
                            if (!this.validateInputExpression(item.ngIf)) {
                                return null
                            }
                        }

                        return <div key={index} className={classes.inputSection}>
                            {item.type === 'TEXT' &&
                                <div className={classes.inputBoxes}>
                                    <Typography>{item.label}</Typography>
                                    {/* <input
                                        className={classes.input}
                                        disabled={false}
                                        placeholder={item.hint}
                                        onChange={(e) => {
                                            this.handleChange(e.target?.value || '', index)
                                        }}
                                        onFocus={() => {
                                            this.setSelected(index)
                                        }}
                                        value={item.value}
                                    /> */}
                                    <TextInput
                                        value={item.value}
                                        handleChange={(value) => {
                                            this.handleChange(value || '', index)
                                        }}
                                        handleFocus={() => {
                                            this.setSelected(index)
                                        }}
                                        variableMap={variableDatas.map}
                                        availableVariables={variableDatas.variables}
                                    />
                                </div>
                            }
                            {['NUMBER', 'SINGLE_SELECT', 'MULTI_SELECT', 'TEXTAREA', 'DATE'].includes(item.type) && <div className={classes.inputBoxes}>
                                <div className={classes.inTitle}>
                                    <Typography>{item.label}</Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography onClick={() => {
                                            this.handleChangeValueType('DEFAULT', index)
                                        }} className={item.valueType === 'DEFAULT' ? classes.inTitleTextActive : classes.inTitleText}>Default</Typography>
                                        <Typography onClick={() => {
                                            this.handleChangeValueType('FORM_FIELD', index)
                                        }} className={item.valueType === 'FORM_FIELD' ? classes.inTitleTextActive : classes.inTitleText}>Form Field</Typography>
                                    </div>
                                </div>
                                {item.valueType === 'FORM_FIELD' &&
                                    // <input
                                    //     className={classes.input}
                                    //     disabled={false}
                                    //     placeholder={item.hint}
                                    //     onChange={(e) => {
                                    //         this.handleChange(e.target?.value || '', index)
                                    //     }}
                                    //     onFocus={() => {
                                    //         this.setSelected(index)
                                    //     }}
                                    //     value={item.value}
                                    // />
                                    <TextInput
                                        value={item.value}
                                        handleChange={(value) => {
                                            this.handleChange(value || '', index)
                                        }}
                                        handleFocus={() => {
                                            this.setSelected(index)
                                        }}
                                        variableMap={variableDatas.map}
                                        availableVariables={variableDatas.variables}
                                    />
                                }
                            </div>
                            }

                            {
                                item.type === 'NUMBER' && item.valueType === 'DEFAULT' &&
                                <div className={classes.inputValueBox}>
                                    <input
                                        className={classes.input}
                                        type={'number'}
                                        disabled={false}
                                        placeholder={item.hint}
                                        onChange={(e) => {
                                            this.handleChange(e.target?.value || '', index)
                                        }}
                                        value={item.value}
                                    />
                                </div>
                            }
                            {
                                item.type === 'SINGLE_SELECT' && item.valueType === 'DEFAULT' &&
                                <div className={classes.inputValueBox}>
                                    <ReactSelect
                                        isClearable
                                        disabled={false}
                                        isSearchable={true}
                                        menuPosition='fixed'
                                        options={item.options}
                                        classNamePrefix="select"
                                        placeholder={item.hint}
                                        onChange={(e) => {
                                            this.handleChangeSingleSelect(e, index)
                                        }}
                                        value={item.value}
                                    />
                                </div>
                            }
                            {
                                item.type === 'MULTI_SELECT' && item.valueType === 'DEFAULT' &&
                                <div className={classes.inputValueBox}>
                                    <ReactSelect
                                        isClearable
                                        isMulti
                                        disabled={false}
                                        isSearchable={true}
                                        menuPosition='fixed'
                                        options={item.options}
                                        classNamePrefix="select"
                                        placeholder={item.hint}
                                        onChange={(e) => {
                                            this.handleChange(e, index)
                                        }}
                                        value={item.value}
                                    />
                                </div>
                            }
                            {
                                item.type === 'TEXTAREA' && item.valueType === 'DEFAULT' &&
                                <div className={classes.inputValueBox}>
                                    {/* <textarea
                                        className={classes.input}
                                        rows="5"
                                        disabled={false}
                                        placeholder={item.hint}
                                        onChange={(e) => {
                                            this.handleChange(e.target?.value || '', index)
                                        }}
                                        value={item.value}
                                    /> */}

                                    <SlateInputField
                                        required={false}
                                        folderPath={`/evaluation/${this.props?.evaluationId}/email`}
                                        as={SlateInputField}
                                        onChangeEvent={(value) => {
                                            this.handleChange(value || '', index)
                                        }}
                                        initValue={item.value}
                                        textContainerStyle={{ color: "#707070", }}
                                        isShowAddVariableOption={false}
                                        isShowAddVisualizationOption={true}
                                        isShowTableOption={true}
                                        isShowImageOption={true}
                                        isShowAlignmentOption={true}
                                        isShowHeadingOption={true}
                                        isToolBar={!this.props.isForSummary}
                                        isShowAddAutomationVariableOption={true}
                                        automationVariables={componentVariables}
                                    />

                                </div>
                            }

                            {
                                item.type === 'IO_SCHEMA' &&
                                <div className={classes.inputBoxes}>
                                    <Typography>{item.label}</Typography>
                                    {item.value && item.value.length <= 0 && <div className={classes.fieldEmptyState}>
                                        <div className={classes.field_empty_state__caption}>
                                            {item.name === 'input' ? "Tell us what fields should be in the list, and assign values to them." : "Describe the fields that will be returned as the output."}
                                        </div>
                                        <div className={classes.fieldEmptyStateaction}>
                                            <Button type="button" variant="outlined" onClick={() => {
                                                this.showAddFieldDialog(item, index)
                                            }}>+ Add Field</Button>
                                        </div>
                                    </div>}
                                    {item.value && item.value.length > 0 && <div className={classes.schemaBody}>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button type="button" variant="outlined" onClick={() => {
                                                this.showAddFieldDialog(item, index)
                                            }}>+ Add Field</Button>
                                        </div>
                                        {item.name === 'input' && <List classes={{
                                            root: classes.listRoot
                                        }}>
                                            {item.value.map((itm, k) => {
                                                return <ListItem classes={{
                                                    container: classes.ioInputListItem,
                                                    gutters: classes.ioListItemGutters
                                                }}>
                                                    <ListItemText>
                                                        {itm.type === 'TEXT' &&
                                                            <div className={classes.inputBoxesSchema}>
                                                                <Typography>{itm.label}</Typography>
                                                                {/* <input
                                                                    className={classes.input}
                                                                    disabled={false}
                                                                    placeholder={itm.hint}
                                                                    onChange={(e) => {
                                                                        this.handleChangeIoSchema(e.target?.value || '', index, k)
                                                                    }}
                                                                    onFocus={() => {
                                                                        this.setSelectedIOField(index, k)
                                                                    }}
                                                                    value={itm.value}
                                                                /> */}

                                                                <TextInput
                                                                    value={itm.value}
                                                                    handleChange={(value) => {
                                                                        this.handleChangeIoSchema(value || '', index, k)
                                                                    }}
                                                                    handleFocus={() => {
                                                                        this.setSelectedIOField(index, k)

                                                                    }}
                                                                    variableMap={variableDatas.map}
                                                                    availableVariables={variableDatas.variables}
                                                                />

                                                            </div>
                                                        }
                                                        {itm.type === 'NUMBER' &&
                                                            <div className={classes.inputBoxesSchema}>
                                                                <Typography>{itm.label}</Typography>
                                                                <input
                                                                    className={classes.input}
                                                                    type={'number'}
                                                                    disabled={false}
                                                                    placeholder={itm.hint}
                                                                    onChange={(e) => {
                                                                        this.handleChangeIoSchema(e.target?.value || '', index, k)
                                                                    }}
                                                                    value={itm.value}
                                                                />
                                                            </div>
                                                        }
                                                        {itm.type === 'DATE' &&
                                                            <div className={classes.inputBoxesSchema}>
                                                                <Typography>{itm.label}</Typography>
                                                                <DatePicker
                                                                    className={classes.datePicker}
                                                                    selected={itm.value}
                                                                    popperClassName={classes.calendarPopout}
                                                                    disabled={false}
                                                                    onChange={date => {
                                                                        this.handleChangeIoSchema(date, index, k)
                                                                    }}
                                                                    dateFormat="MMM do yyyy"
                                                                    portalId="evMetaDialog"
                                                                    minDate={null}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={
                                                                        <OutlinedInput
                                                                            readOnlyInput={true}
                                                                            startAdornment={
                                                                                <InputAdornment>
                                                                                    <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                        {itm.type === 'BOOLEAN' &&
                                                            <div className={classes.inputBoxesSchema}>
                                                                <Typography>{itm.label}</Typography>
                                                                <FormControl variant="outlined" className={classes.formControl} size="small">
                                                                    <Select value={itm.value} onChange={(e) => {
                                                                        this.handleChangeIoSchema(e.target.value, index, k)
                                                                    }}>
                                                                        {itm.options.map((o) => {
                                                                            return <MenuItem value={o.value} style={{ fontSize: 13 }}>{o.label}</MenuItem>
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        }
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <Image alt="Delete" src={deleteIcon} className={classes.deleteInputIcon} onClick={() => {
                                                            this.deleteIOField(index, k)
                                                        }} />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            })}
                                        </List>}

                                        {item.name === 'output' && <List>
                                            {item.value.map((itm, k) => {
                                                return <ListItem classes={{
                                                    container: classes.ioListItem,
                                                    gutters: classes.ioOutputListItemGutters
                                                }}>
                                                    <ListItemText
                                                        primary={itm?.label}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Image alt="Delete" src={deleteIcon} className={classes.deleteIcon} onClick={() => {
                                                            this.deleteIOField(index, k)
                                                        }} />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            })}
                                        </List>}
                                    </div>}
                                </div>
                            }

                            {(item.type === 'CODE') &&
                                <div className={classes.inputBoxes}>
                                    <Typography>{item.label}</Typography>
                                    <Editor
                                        value={item.value === '' ? this.state.defaultCode : item.value}
                                        onValueChange={code => {
                                            this.handleChange(code || '', index)
                                        }}
                                        highlight={code => highlight(code, languages.js)}
                                        padding={10}
                                        style={{
                                            fontFamily: '"Fira code", "Fira Mono", monospace',
                                            fontSize: 12,
                                            border: '1px solid #ccc',
                                            minHeight: 200
                                        }}
                                    />
                                </div>
                            }
                            {item.type === 'JSON' &&
                                <div className={classes.inputBoxes}>
                                    <Typography>{item.label}</Typography>
                                    <textarea
                                        className={classes.input}
                                        rows="5"
                                        disabled={false}
                                        placeholder={item.hint}
                                        onChange={(e) => {
                                            this.handleChange(e.target?.value || '', index)
                                        }}
                                        value={item.value}
                                    />
                                </div>
                            }
                            {item.type === 'DATE' && item.valueType === 'DEFAULT' &&
                                <div className={classes.inputValueBox}>
                                    <DatePicker
                                        className={classes.datePicker}
                                        selected={item.value}
                                        popperClassName={classes.calendarPopout}
                                        disabled={false}
                                        onChange={date => {
                                            this.handleChange(date, index)
                                        }}
                                        dateFormat="MMM do yyyy"
                                        portalId="evMetaDialog"
                                        minDate={null}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        customInput={
                                            <OutlinedInput
                                                readOnlyInput={true}
                                                startAdornment={
                                                    <InputAdornment>
                                                        <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    />
                                </div>
                            }
                            {item.type === 'ARRAY' &&
                                <div className={classes.inputBoxes}>
                                    <Typography>{item.label}</Typography>
                                    <input
                                        className={classes.input}
                                        disabled={false}
                                        placeholder={item.hint}
                                        onChange={(e) => {
                                            this.handleChange(e.target?.value || '', index)
                                        }}
                                        value={item.value}
                                    />
                                </div>
                            }
                        </div>
                    })
                }

                {
                    !this.props.isForSummary && optionalItems && optionalItems.length > 0 && optionalItems.length - optionalSelectedFields.length > 0 && <div className={classes.optionalFields}>
                        <span className={classes.opCount} onClick={() => {
                            this.props.showSelectOptionFieldsDialog()
                        }}>
                            + {optionalItems.length - optionalSelectedFields.length}  optional fields available
                        </span>
                    </div>
                }

                {
                    !this.props.isForSummary && <BottomActions
                        submit={() => {
                            this.handleFormSubmit()
                        }}
                    />
                }

                {
                    isShowVariables && <Variables
                        componentVariables={componentVariables}
                        handleSelectVariable={(component, variable) => {
                            this.handleAddVariable(component, variable)
                        }}
                    />
                }

                {
                    this.props.isOpenOptionalFieldsDialog && <OptionalFieldsDialog
                        optionalItems={optionalItems}
                        alreadySelected={optionalSelectedFields}
                        onSelectionSubmit={(fields, opIds) => {
                            this.handleAddOptionalFields(fields, opIds)
                        }}
                    />
                }

                {
                    this.state.isOpenAddFieldDialog && <AddFieldDialog
                        isOpen={true}
                        item={this.state.selectedSchemaField?.item}
                        hideDialog={() => {
                            this.setState({
                                isOpenAddFieldDialog: false
                            })
                        }}
                        onSubmit={(data) => {
                            this.setState({
                                isOpenAddFieldDialog: false
                            }, () => {
                                this.addField(data)
                            })
                        }}
                    />
                }
            </div >
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ActionItemConfig));
