import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, CircularProgress } from "@material-ui/core";

import { showSnackBar } from "redux/snackbar/action";

import OutlinedInput from "../../../../../../Components/Common/Input/OutlinedInput";
import CustomFormBuilder from "./CustomFormBuilder";
import { generate } from 'shortid';
import SqureButton from 'Components/Common/SqureButton';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import {
  addWorkFlowCustomFormData,
  updateWorkFlowCustomFormData,
  deleteWorkFlowCustomFormData,
  fetchWorkFlowCustomForm,
  fetchWorkFlowCustomFormUsingId,
  updateWorkFlowCustomFormSectionSequence,
  addWorkFlowCustomFormSection, updateWorkFlowCustomFormSection, deleteWorkFlowCustomFormSection, fetchWorkFlowCustomFormSections,
  addWorkFlowCustomFormSectionItem, updateWorkFlowCustomFormSectionItem, deleteWorkFlowCustomFormSectionItem, fetchWorkFlowCustomFormSectionItems,
  showWrapperComponentDialog, hideWrapperComponentDialog
} from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
  masterTemplates: state.masterTemplateEvaluation.templates,
  fetchProg: state.masterTemplateEvaluation.fetchProg,
  orgTemplates: state.orgTemplateEvaluation.templates,
  addWorkflowCustomFormProgress: state.evaluationWorkflow.addWorkflowCustomFormProgress,
  updateWorkflowCustomFormProgress: state.evaluationWorkflow.updateWorkflowCustomFormProgress,
  fetchCustomFormProgress: state.evaluationWorkflow.fetchCustomFormProgress,
  workflowCustomForm: state.evaluationWorkflow.workflowCustomForm,
  fetchCustomFormSectionsProgress: state.evaluationWorkflow.fetchCustomFormSectionsProgress,
  customFormSections: state.evaluationWorkflow.customFormSections,
  addCustomFormSectionProgress: state.evaluationWorkflow.addCustomFormSectionProgress,
  updateCustomFormSectionProgress: state.evaluationWorkflow.updateCustomFormSectionProgress,
  deletCustomFormSectionProgress: state.evaluationWorkflow.deletCustomFormSectionProgress,
  addCustomFormSectionItemProgress: state.evaluationWorkflow.addCustomFormSectionItemProgress,
  updateCustomFormSectionItemProgress: state.evaluationWorkflow.updateCustomFormSectionItemProgress,
  deleteCustomFormSectionItemProgress: state.evaluationWorkflow.deleteCustomFormSectionItemProgress,
  fetchCustomFormSectionItemsProgress: state.evaluationWorkflow.fetchCustomFormSectionItemsProgress,
  customFormSectionItems: state.evaluationWorkflow.customFormSectionItems,
  newwlyAddedCustomSectionData: state.evaluationWorkflow.newwlyAddedCustomSectionData,
  startCustomFormSectionSeqProgress: state.evaluationWorkflow.startCustomFormSectionSeqProgress,
  isWrapperrComponentDialogOpen: state.evaluationWorkflow.isWrapperrComponentDialogOpen
});

const connectionActions = {
  addWorkFlowCustomFormData: addWorkFlowCustomFormData,
  updateWorkFlowCustomFormData: updateWorkFlowCustomFormData,
  deleteWorkFlowCustomFormData: deleteWorkFlowCustomFormData,
  fetchWorkFlowCustomForm: fetchWorkFlowCustomForm,
  fetchWorkFlowCustomFormUsingId: fetchWorkFlowCustomFormUsingId,
  addWorkFlowCustomFormSection: addWorkFlowCustomFormSection,
  updateWorkFlowCustomFormSection: updateWorkFlowCustomFormSection,
  deleteWorkFlowCustomFormSection: deleteWorkFlowCustomFormSection,
  fetchWorkFlowCustomFormSections: fetchWorkFlowCustomFormSections,
  addWorkFlowCustomFormSectionItem: addWorkFlowCustomFormSectionItem,
  updateWorkFlowCustomFormSectionItem: updateWorkFlowCustomFormSectionItem,
  deleteWorkFlowCustomFormSectionItem: deleteWorkFlowCustomFormSectionItem,
  fetchWorkFlowCustomFormSectionItems: fetchWorkFlowCustomFormSectionItems,
  updateWorkFlowCustomFormSectionSequence: updateWorkFlowCustomFormSectionSequence,
  showWrapperComponentDialog: showWrapperComponentDialog,
  hideWrapperComponentDialog:hideWrapperComponentDialog,
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  answerType: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    marginTop: 30,
    placeContent: "start",
    alignItems: "center",
    maxWidth: "250px",
    "& label": {
      color: "#707070",
      marginRight: 20,
      fontSize: "14px",
      fontWeight: 500,
    },
    "& [class*='MuiSelect-root-']": {
      padding: "0.5em 0.75em",
      fontSize: "14px",
      color: "#707070",
      paddingRight: "32px",
    },
  },
  input: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: '100%'
  },
  indexNumber: {
    width: 10,
    marginTop: 5,
  },
  inputSection: {
    display: "flex",
    marginTop: 21,
    justifyContent: "space-between",
  },
  requiredField: {
    color: "red",
    marginLeft: 5,
  },
  inputController: {
    marginLeft: 5,
    flex: 1,
  },
  image: {
    marginTop: 5,
    minWidth: 10,
    cursor: "pointer",
  },
  saveBtnSection: {
    position: "absolute",
    bottom: 0,
    right: 12,
    left: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: '#f1f1f1',
    padding: '10px'
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  newUserBtnDisabled: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "no-drop",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  addMore: {
    fontSize: "12px",
    color: "#4175DF",
    cursor: "pointer",
    display: "inline-block",
    marginTop: 10,
  },
  label: {
    fontSize: 15,
    fontWeight: 500,
  },
  radioBox: {
    '& [class*=MuiFormControlLabel-label]': {
      fontSize: 15
    }
  },
  alerts: {
    marginTop: 10
  },
  tabs: {
    listStyle: "none",
    display: "flex",
    marginBottom: 10,
    padding: 0,
    marginTop: 0

  },
  tab: {
    borderBottom: "solid 1px #e0e0e0",
  },
  tabBtn: {
    padding: '1em',
    borderRadius: 0
  },
  activeBtn: {
    color: "#4A87F8",
    borderBottom: "solid 2px #4A87F8",
  },
  customSection: {
    marginTop: theme.spacing(6),
    height:'70vh',
    overflow : 'auto'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
});

class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNewSection: null,
      newTabSection: null,
      tabs: [],
      activeSection: null,
      customSections: [],
      customFormTitle: '',
      customFormDescription: '',
      isShowCustomForm: false,
      isShowNewSectionbtn: false,
    }

    this.saveCustomFormData = this.saveCustomFormData.bind(this);
    this.onSectionDragEnd = this.onSectionDragEnd.bind(this)
  }

  componentDidMount() { 
    this.props.fetchWorkFlowCustomForm(this.props.workflowId, this.props.componentId);
  }

  componentDidUpdate(prevProps) {
    const {
      addWorkflowCustomFormProgress,
      updateWorkflowCustomFormProgress,
      fetchCustomFormProgress,
      fetchCustomFormSectionsProgress,
      addCustomFormSectionProgress,
      updateCustomFormSectionProgress,
      deletCustomFormSectionProgress,
      deleteCustomFormSectionItemProgress,
      startCustomFormSectionSeqProgress
    } = this.props;

    if (!addWorkflowCustomFormProgress && prevProps.addWorkflowCustomFormProgress === true) {
      this.setState({
        isShowNewSectionbtn: true
      })
      // this.props.closeConfiguration();
      this.props.showSnackBar("Custom form details added successfully", "success", 3000);
    }

    if (!updateWorkflowCustomFormProgress && prevProps.updateWorkflowCustomFormProgress === true) {
      this.props.closeConfiguration();
      this.props.showSnackBar("Custom form details updates successfully", "success", 3000);
    }

    if (!fetchCustomFormProgress && prevProps.fetchCustomFormProgress === true) {
      if (this.props.workflowCustomForm !== null) {
        this.setState({
          customFormTitle: this.props.workflowCustomForm?.FormTitle,
          customFormDescription: this.props.workflowCustomForm?.FormDescription,
          isShowNewSectionbtn: true
        }, () => {
          this.props.fetchWorkFlowCustomFormSections(this.props.workflowId, this.props.workflowCustomForm?.ComponentID, this.props.workflowCustomForm?.ID);
        })
      } else {
        this.setState({
          isShowCustomForm: true
        })
      }
    }

    if (!fetchCustomFormSectionsProgress && prevProps.fetchCustomFormSectionsProgress === true) {
      if (this.props.customFormSections !== null) {
        this.setState({
          customSections: this.props.customFormSections
        })
      }
      this.setState({
        isShowCustomForm: true
      })
    }

    if (!addCustomFormSectionProgress && prevProps.addCustomFormSectionProgress === true) {
      const data = this.state.newTabSection;
      const items = data?.items || [];

      items.forEach(item => {
        this.props.addWorkFlowCustomFormSectionItem(
          this.props.workflowId,
          this.props.workflowCustomForm?.ComponentID,
          this.props.workflowCustomForm?.ID,
          this.props.newwlyAddedCustomSectionData.ID,
          {
            name: item.name,
            description: item.description,
            visibility: 1,
            type: item.type,
            additional_data: JSON.stringify(item.additional_data),
            is_multiselect: item.is_multiselect,
          }
        )
      });

      this.setState({
        newTabSection: null
      }, () => {
        this.props.closeConfiguration();
        this.props.showSnackBar("New Section added successfully", "success", 3000);
      });

    }

    if (!updateCustomFormSectionProgress && prevProps.updateCustomFormSectionProgress === true) {
      const data = this.state.newTabSection;
      const items = data?.items || [];
      items.map(item => {
        const newData = {
          name: item.name,
          description: item.description,
          visibility: item.visibility,
          type: item.type,
          additional_data: JSON.stringify(item.additional_data),
          is_multiselect: item.is_multiselect,
        }
        if (item?.isNew) {
          this.props.addWorkFlowCustomFormSectionItem(
            this.props.workflowId,
            this.props.workflowCustomForm?.ComponentID,
            this.props.workflowCustomForm?.ID,
            data.ID,
            newData)
        } else {
          this.props.updateWorkFlowCustomFormSectionItem(
            this.props.workflowId,
            this.props.workflowCustomForm?.ComponentID,
            this.props.workflowCustomForm?.ID,
            data.ID,
            item.itemId,
            newData
          )
        }
        return item
      })
      this.setState({
        newTabSection: null,
      }, () => {
        this.props.closeConfiguration();
        this.props.showSnackBar("Section updated successfully", "success", 3000);
      })
    }

    if (!deletCustomFormSectionProgress && prevProps.deletCustomFormSectionProgress === true) {
      this.props.closeConfiguration();
      this.props.showSnackBar("Section deleted successfully", "success", 3000);
    }

    if (!deleteCustomFormSectionItemProgress && prevProps.deleteCustomFormSectionItemProgress === true) {
      this.props.closeConfiguration();
      this.props.showSnackBar("Section item deleted successfully", "success", 3000);
    }

    if (!startCustomFormSectionSeqProgress && prevProps.startCustomFormSectionSeqProgress === true) {
      // this.props.closeConfiguration();
      this.setState({
        isShowCustomForm: false
      }, () => {
        this.props.showSnackBar("Section sequence updated successfully", "success", 3000);
      })
    }

  }

  handleCreateNewSection = (data) => {
    const { newTabSection } = this.state
    if (newTabSection === null) {
      this.setState({
        newTabSection: { ...data }
      }, () => {
        this.props.addWorkFlowCustomFormSection(
          this.props.workflowId,
          this.props.workflowCustomForm?.ComponentID,
          this.props.workflowCustomForm.ID,
          {
            name: data.name,
            description: "",
            visibility: 1
          }
        )
      })
    }
  }

  handleUpdateSection = (data, isCustom, sectionData) => {
    const { newTabSection } = this.state
    if (newTabSection === null) {
      this.setState({
        newTabSection: isCustom ? { ...data, ...sectionData } : null
      }, () => {
        this.props.updateWorkFlowCustomFormSection(
          this.props.workflowId,
          this.props.workflowCustomForm?.ComponentID,
          this.props.workflowCustomForm.ID,
          sectionData.ID,
          {
            name: data.name,
            description: "",
            visibility: data.visibility
          }
        )
      })
    }
  }

  handleDeleteSection = (sectionData) => {
    this.setState({ activeSection: null }, () => {
      this.props.deleteWorkFlowCustomFormSection(
        this.props.workflowId,
        this.props.workflowCustomForm?.ComponentID,
        this.props.workflowCustomForm.ID,
        sectionData.ID)
    })
  }

  handleDeleteItem = (sectionData, itemData) => {
    this.setState({ activeSection: null }, () => {
      this.props.deleteWorkFlowCustomFormSectionItem(
        this.props.workflowId,
        this.props.workflowCustomForm?.ComponentID,
        this.props.workflowCustomForm.ID,
        sectionData.ID,
        itemData.itemId)
    })
  }

  onSectionDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const itemIndex = parseInt(draggableId)
      const draggedSection = this.state.customSections[itemIndex];
      const destinationIndex = parseInt(destination.index);

      this.setState({
        isShowCustomForm: false
      })

      this.props.updateWorkFlowCustomFormSectionSequence(
        this.props.workflowId,
        this.props.workflowCustomForm?.ComponentID,
        this.props.workflowCustomForm.ID,
        draggedSection.ID,
        {
          seq: destinationIndex
        }
      )

    }
  }

  saveCustomFormData() {
    this.setState({
      error: '',
      success: '',
    })

    let errorMessage = '';
    if (this.state.customFormTitle.length <= 0 || this.state.customFormTitle === '') {
      errorMessage = `Please enter form title`;
      this.setState({
        error: errorMessage
      })
      return;
    }

    if (this.state.customFormDescription.length <= 0 || this.state.customFormDescription === '') {
      errorMessage = `Please enter form description`;
      this.setState({
        error: errorMessage
      })
      return;
    }

    var componentId = parseInt(this.props.componentDetails.id);

    if (this.props.workflowCustomForm === null) {
      this.props.addWorkFlowCustomFormData(this.props.workflowId, componentId, {
        form_title: this.state.customFormTitle,
        form_description: this.state.customFormDescription,
      })
    } else {
      this.props.updateWorkFlowCustomFormData(this.props.workflowId, componentId, this.props.workflowCustomForm.ID, {
        form_title: this.state.customFormTitle,
        form_description: this.state.customFormDescription,
      })
    }
  }


  render() {
    const classes = this.props.classes;

    const { activeSection, activeNewSection } = this.state;
    let tabView = this.state.customSections.sort(function (a, b) {
      if (a.Seq >= b.Seq) {
        return 1
      } else if (a.Seq <= b.Seq) {
        return -1
      } else {
        return 0
      }
    });

    return (
      <div>

        {!this.state.isShowCustomForm && <div className={classes.loader}><CircularProgress /></div>
        }

        {this.state.isShowCustomForm && <div className={classes.customSection}>
          <Grid container>
            <Grid lg={12} md={12} sm={12} xs={12} >
              <OutlinedInput
                id={"formTitleInput"}
                label="Form Title"
                placeholder={"Enter your Title here"}
                value={this.state.customFormTitle}
                onChange={(event) => {
                  this.setState({
                    customFormTitle: event.target.value
                  })
                }}
              />
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} >
              <OutlinedInput
                id={"formDescInput"}
                label="Form Description"
                placeholder={"Enter your description here"}
                value={this.state.customFormDescription}
                onChange={(event) => {
                  this.setState({
                    customFormDescription: event.target.value
                  })
                }}
              />
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12}>

              {tabView && (
                <>
                  <DragDropContext onDragEnd={this.onSectionDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {tabView?.map((obj, index) => (
                            <Draggable key={String(index)} draggableId={String(index)} index={index} sectionData={obj}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <CustomFormBuilder
                                    config={obj}
                                    id={`${obj.SectionName.toLowerCase().split(" ").join("-")}`}
                                    key={generate()}
                                    isNew={false}
                                    isLoader={false}
                                    isCustom={obj.IsDeletable}
                                    onDelete={() => this.handleDeleteSection(obj)}
                                    deleteItem={itemData => this.handleDeleteItem(obj, itemData)}
                                    onCancel={() => this.setState({ activeSection: null })}
                                    isActive={activeSection !== null && activeSection === index}
                                    activeSection={() => this.setState({ activeSection: index })}
                                    onUpdate={data => this.handleUpdateSection(data, obj.IsDeletable, obj)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                          }
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}

              {(activeNewSection === null && this.state.isShowNewSectionbtn === true) && <Grid container justify="center">
                <SqureButton
                  variant={"contained"}
                  onClick={() => {
                    this.setState({ activeNewSection: true })
                    setTimeout(() => {
                      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                    }, 100)
                  }}
                >
                  + New Section
                </SqureButton>
              </Grid>}

              {activeNewSection !== null && <CustomFormBuilder
                isNew
                isLoader={false}
                isCustom={true}
                isActive={true}
                key={generate()}
                config={{ Visibility: 3 }}
                onSave={data => this.handleCreateNewSection(data)}
                onCancel={() => this.setState({ activeNewSection: null })}
              />}
            </Grid>
          </Grid>

        </div>
        }

        <div className={classes.saveBtnSection}>
          <button
            type="button"
            className={classes.newUserBtn}
            onClick={() => this.saveCustomFormData()}> Save</button>
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomForm));
