import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import WrapperComponentDialog from "./WrapperComponentDialog";

import { showWrapperComponentDialog } from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
  isWrapperrComponentDialogOpen: state.evaluationWorkflow.isWrapperrComponentDialogOpen
});

const connectionActions = {
  showWrapperComponentDialog:showWrapperComponentDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
});

class WrapperForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const classes = this.props.classes;

    return (
      <div>
        <button type="button"
          className={classes.newUserBtn}
          onClick={() => this.props.showWrapperComponentDialog()}>
          Define workflow </button>

          {this.props.isWrapperrComponentDialogOpen && <WrapperComponentDialog
              wrapperData={this.props.wrapperData}
              wrapperDetails={this.props.wrapperDetails}
              wrapperId={this.props.wrapperId}
              componentDetails={this.props.componentDetails}
        />}

      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(WrapperForm));
