import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import classnames from "classnames";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  criteriaPriority: {
    minHeight: '25px !important',
    padding: 3,
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    color: '#5F5F5F',
    fontSize: '1.1em',
    marginLeft: 4,
    width: 40,
    textAlign: 'center'
  },
  actionTxt: {
    color: '#4175DF',
    width: 'max-content',
    fontSize: theme.spacing(1.75),
    cursor:'pointer'
  },
  OutlinedInput: {
    minHeight: '25px !important',
    padding: 6,
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    color: '#5F5F5F',
    fontSize: '1.1em',
    width: 50,
    textAlign: 'center'
  },
  actionIcon: {
    width: 30,
    height: 30,
    borderRadius: 5,
    textAlign: 'center',
    marginLeft: 5,
    border: '1px solid #4b86f8'
  },
  micon: {
    fontSize: 16,
    marginTop: 6,
    color: '#4b86f8',
  },
});

class PriorityInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isEdit: false,
      user: '',
      priority: '',
      priority_error: ''
    }
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
      priority: this.props.value,
      user: this.props.user
    })
  }

  componentDidUpdate(prevProps) {
    if(this.props.value !== prevProps.value){
      this.setState({
        value: this.props.value,
        priority: this.props.value,
        user: this.props.user
      })
    }
  }


  updatePriority(criteriaId) {
    this.setState({
      priority_error: ''
    })
    if (this.state.priority <= 0 || this.state.priority > 100) {
      this.setState({
        priority_error: 'Enter between 0-100'
      })
    } else {
      this.setState({
        isEdit:false
      },()=>{
        this.props.onSubmit(this.state.priority)
      })
    }
  }

  render() {
    let value = this.state.value;
    const classes = this.props.classes;
    const { isEdit } = this.state;

    var priorityText = "Add Weightage";
    // if (value !== null && value !== '' && value === 0) {
    //   priorityText = 'Weightage: 50%';
    // }
    if (value !== null && value !== '' && value > 0) {
      priorityText = 'Weightage: ' + value + '%';
    }

    return (
      <div>
        {this.state.priority_error !== null && this.state.priority_error.length > 0 && <>
          <span style={{ color: 'red', fontSize: 12 }}>{this.state.priority_error}</span>
        </>}
        {!isEdit
          ? (
            <Typography
              className={classes.actionTxt}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  isEdit: true
                })
              }}>{priorityText}</Typography>
          ) : (
            <Grid container style={{ alignItems: 'end' }}>
              <input
                value={this.state.priority}
                className={classes.OutlinedInput}
                onChange={(event) => {
                  this.setState({ priority: event.target.value });
                }}
              ></input>
              <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                <div
                  className={classes.actionIcon}
                  onClick={(e) => {
                    e.stopPropagation()
                    this.setState({
                      priority_error: '',
                      priority: this.props.value,
                      isEdit: false
                    })
                  }}
                >
                  <ClearIcon className={classes.micon} />
                </div>
                {this.state.priority && (
                  <div
                    className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                    onClick={(e) => {
                      e.stopPropagation()
                      this.updatePriority()
                    }}
                  >
                    <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                  </div>
                )}
              </Grid>
            </Grid>
          )
        }
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(PriorityInput));
