import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";

const useStyles = makeStyles((theme) => ({
  card: (props) => ({
    // maxWidth: "400px",
    border: props.border,
    borderLeft: props.borderLeft,
    boxShadow: "none",
  }),
  icon: (props) => ({
    fontSize: "50px",
    color: props.color,
    marginRight: 15,
  }),
  cardContent: {
    marginBottom: "0px",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "18px",
  },
}));

const DataCard = (props) => {
  const { title, color, value } = props;

  const dynamicStyle = { color: color, border: `1px solid ${color}`, borderLeft: `8px solid ${color}` };
  const classes = useStyles(dynamicStyle);

  return (
    <Card
      className={classes.card}
      // style={{ maxWidth: 400 }}
    >
      <CardContent className={classes.cardContent}>
        <Grid container alignItems="center">
          <Grid item>
            <DashboardIcon className={classes.icon} />
          </Grid>
          <Grid item>
            <Typography className={classes.title}>{title}</Typography>
            <Typography variant="h5">{value}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DataCard;
