import { createStyles, withStyles } from '@material-ui/styles';
import CommonCss from "commonCss";
import { connect } from "react-redux";
import CheckIcon from "@material-ui/icons/Add";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, List, ListItem, ListItemIcon, Popover, Typography} from "@material-ui/core";
import React, { Component } from 'react';
import { showSearchExternalUsersPoperDialog } from "redux/dialogs/action";

const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
});


const connectionActions = {
  showSearchExternalUsersPoperDialog: showSearchExternalUsersPoperDialog,
};

var connector = connect(connectedProps, connectionActions);


const styles = (theme) => createStyles({
  ...CommonCss.ProductDetails,
  root: {
    position: 'relative',
    width: '100%'
  },
  threeDot: {
    position: "absolute",
    zIndex: 0,
    right: -10,
    top: -10,
    padding: 5
  },
  listIcon: {
    minWidth: theme.spacing(5),
  },
  menuList: {
    padding: '0px',
    "& > hr": {
      "&:first-child": {
        display: 'none'
      },
    }
  },
  popover: {
    "& [class*='paper']": {
      boxShadow: "0px 8px 12px #0000001D",
      border: "1px solid #EEEEEE",
      borderRadius: "5px",
      overflow: "inherit",
    },
  },
  
})

class CollaboratorPopper extends Component {
  constructor(props) {
    super()
    this.state = {
      anchorEl: null,
    };
    this.btnRef = React.createRef();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    
    const open = Boolean(this.state.anchorEl);
    
    return (
      <>
        <Popover
          className={classes.popover}
          id={'add-vendor'}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          >

          <List className={classes.menuList}>
            <ListItem
                button
                onClick={() => {
                  this.props.showSearchExternalUsersPoperDialog({
                    from: 'ORG_PRODUCT_DETAILS',
                    selectedUsers: this.props.selectedUsers || [],
                    sectionId: this.props.sectionId,
                    tabId: this.props.tabId
                  });
                  this.handleClose();
                }}
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <CheckIcon className={classes.proNotBlocked} />
                </ListItemIcon>
                <Typography variant="inherit" >
                  Add External User
                </Typography>
              </ListItem>


          </List>
        </Popover>
        <div className={classes.root} >
          {["OrgAdmin","OrgManager"].includes(this.props.userType) &&<IconButton ref={this.btnRef} className={classes.threeDot} onClick={this.handleClick}><MoreVertIcon /></IconButton>}       
        </div>
      </>
    )
  }
}

export default connector(withStyles(styles)(CollaboratorPopper))
