import { Button, CircularProgress, FormControl, Grid, MenuItem, Paper, Select, Tab, Tabs } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from "@material-ui/icons";
//components
import CommingSoonImg from "assets/images/home/feedback_comming_soon.svg";
import MetricsEmptyState from "assets/images/metrics_empty_state.svg";
import classnames from "classnames";
import CommonCss from "commonCss";
import AddProductManuallyDialog from "Components/Application/Components/Settings/AddProductManuallyDialog";
//assets
import Image from 'Components/Common/image';
import SearchInput from "Components/Common/Input/SearchInput";
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from "react-redux";
import { compose } from "recompose";
import { updateDrawerStyle } from "redux/drawer/action";
//redux
import { fetchMetricCategoryVendors, fetchVendors } from "redux/metric/action";
import { productSearch } from "redux/product/action";
import { fetchAllCategories } from "redux/product/category/action";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import AppBarV2 from "../../AppBarV2";
// import VendorEmailDialog from '../Evaluate/Components/Evaluation/Common/VendorEmailDialog';
import CommingSoon from "../Home/Components/CommingSoon";
import VendorCard from "./VendorCard";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action"
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
    fetchVendorProgress: state.metric.fetchVendorProgress,
    user: state.authUser.user,
    vendors: state.metric.vendors,
    vendorsTotal: state.metric.vendorsTotal,
    addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
    // fetch product categories
    fetchAllCategoryProgress: state.productCategory.fetchAllCategoryProgress,
    allCategories: state.productCategory.allCategories,
    fetchCategoryProgress: state.productCategory.fetchCategoryProgress,
    fetchAllCategoryError: state.productCategory.fetchAllCategoryError,
    // fetch category wise products
    fetchCategoryProductsProgress: state.metric.fetchMetricCategoryVendorProgress,
    categoryProducts: state.metric.metricCategoryVendors,
    categoryProductsTotal: state.metric.metricCategoryVendorsTotal,

    fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
    fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
    appCatalogProducts: state.appCatalog.products,

    updateVendorRefactorError: state.metric.updateVendorRefactorError,
    updateVendorRefactorProgress: state.metric.updateVendorRefactorProgress,
});

const connectionActions = {
    showAddProductManuallyDialog: showAddProductManuallyDialog,
    fetchAllCategories: fetchAllCategories,
    fetchVendors: fetchVendors,
    fetchMetricCategoryVendors: fetchMetricCategoryVendors,
    updateDrawerStyle: updateDrawerStyle,
    productSearch: productSearch,
    showSnackBar: showSnackBar,
    fetchAppCatlogProducts: fetchAppCatlogProducts,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    searchIcon: {
        marginRight: theme.spacing(2)
    },
    vendors: {
        marginTop: theme.spacing(5)
    },
    searchSection: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        '& [class*="MuiOutlinedInput-root"]': {
            maxHeight: 40,
            minHeight: 40,
            maxWidth: 343,
        },
        "& [class*='MuiInputBase-input']": {
            fontSize: 13,
            width: 300,
        },
        // "& [class*='MuiSvgIcon-root']": {
        //   fill: "#707070",
        // },
        "& [class*=MuiGrid-container]": {
            alignItems: "center",
            marginBottom: 20,
        },
        "&>div ": {
            marginBottom: 0,
        },
    },
    nodata: {
        height: '70vh',
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center',
        alignItems: 'center',
        '& p': {
            fontSize: '22px',
            textAlign: 'center'
        }
    },
    signon: {
        color: '#1f73b7 !important',
        textDecoration: 'underline'
    },
    DashboardNoDataLayout: {
        margin: '22px 0px 0px 0px',
        minHeight: 450,
        textAlign: 'center',
        // background: '#ffffff',
        padding: '23px',
        borderRadius: '6px',
        '& button': {
            margin: '20px 0',
            color: '#fff',
            background: '#3C3C3C',
            '&:hover': {
                color: '#fff',
                background: '#3C3C3C',
            }
        }
    },
    addProductLink: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    headSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 30
    },
    headSectionTab: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing(3)} 0`,
    },
    loader: {
        position: 'fixed',
        right: '45%',
        top: '40%'
    },
    "@global": {
        ".applicationContent": {
            padding: "0 !important",
        },
        '@media (max-width: 991.98px)': {
            "& [class*='Metrics-metricContainer-']": {

            }
        }
    },
    categorySidebar: {
        width: '270px',
        padding: '2em 0em 2em 1.4em',
        position: 'fixed',
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px #0000000b',
        minHeight: '100vh',
        // '@media (max-width: 991.98px)':{
        //     position: 'fixed',
        //     zIndex: 999,
        //     height: '100vh',
        //     left: 84,
        //     top: 0
        // }
    },
    metricContainer: {
        flex: 1,
        // padding: '3em 5em',
    },
    pleft: {
        marginLeft: '270px'
    },
    allCategory: {
        height: 'calc(100vh - 50px)', // calc(100vh - 120px)
        overflowY: 'overlay'
    },
    activeCategory: {
        color: "#4A87F8 !important",
        background: "#F2F7FF",
        fontSize: 13,
        marginRight: '1.6em !important',
    },
    innerText: {
        color: "#696B6E",
        padding: theme.spacing(1, 1.5, 1, 1),
        cursor: "pointer",
        display: "block",
        margin: 0,
    },
    rootPaper: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
        borderBottom: "solid 1px #e0e0e0",
    },
    searchIconPop: {
        cursor: 'pointer',
        fontSize: theme.spacing(3),
        marginRight: '10px',
        "&:hover": {
            color: "#fff !important",
            background: "#707070",
            borderColor: "#707070",
            padding: '3px',
            borderRadius: theme.spacing(20),
            fontSize: theme.spacing(4)
        }
    },
    rootContainer: CommonCss.rootContainer

});

const CustomTabs = withStyles({
    root: {
        backgroundColor: 'tarsparent !imporant',
    },
    indicator: {
        backgroundColor: '#4A87F8',
    },
})(Tabs);

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        '&:hover': {
            color: '#4A87F8',
            opacity: 1,
        },
        '&:focus': {
            color: '#4A87F8',
        },
    },
    selected: {},
}))((props) => <Tab {...props} />);


class Metrics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMetric: 10,
            activeCategoryId: 0,
            tabCount: 0,
            sortby: 'most',
            isShowLoader:true,
            searchObj: {
                field_setname: 'all',
                text: '',
                filter_fields: {},
                analytics_only: true,
                qtype_page: null,
                page_size: 20,
                sort_fields: []
            },
            page: 0,
        }
    }

    componentDidMount() {
        this.props.fetchAllCategories(0, 500, '', {
            source: "metric"
        })
        this.fetchData()
    }

    componentDidUpdate(prevProps){
        if(this.props.fetchAllCategoryProgress === false && prevProps.fetchAllCategoryProgress === true){
            setTimeout(() => {
                this.setState({
                    isShowLoader:false
                })
            }, 2000);
        }
        if(!this.props.updateVendorRefactorProgress && prevProps.updateVendorRefactorProgress){
            if(this.props.updateVendorRefactorError) {
                this.props.showSnackBar("Something went wrong", "error", 3000);
            } else {
                this.fetchData()
                this.props.showSnackBar("Success", "success", 3000);
            }
        }
    }

    fetchData() {
        const { searchObj, sortby, page } = this.state
        if (sortby === 'most') {
            searchObj.sort_fields = ["-usage"];
        } else {
            searchObj.sort_fields = ["usage"];
        }
        searchObj.qtype_page = ["existing", page];
        this.props.fetchAppCatlogProducts(searchObj);
    }

    render() {
        const { classes, appCatalogProducts, fetchAppCatalogProductsProgress } = this.props;
        
        // console.log(appCatalogProducts,'appCatalogProducts')
        let data = appCatalogProducts?.existing?.items || []
        let total = appCatalogProducts?.existing?.total || 0

        if (this.state.isShowLoader) {
            return (<span className={classes.loader}><CircularProgress /></span>)
        }

        if (this.props.allCategories === null || (this.props.allCategories?.Data && this.props.allCategories?.Data.length === 0) || (data.length <= 0 && this.state.searchObj?.text === '' && this.props.fetchAppCatalogProductsProgress === false && !this.props.fetchAppCatalogProductsError)) {
            return (
                <div className={classes.DashboardNoDataLayout}>
                    <Image src={MetricsEmptyState} />
                    <h2>No Metrics found</h2>
                    <p>Integrate with your Single Sign-On solution to understand usage of your existing vendors</p>
                    <Button variant="outlined" color="secondary" className={classes.button} startIcon={<AddIcon />}
                        onClick={() => { this.props.history.push('/app/settings/integrations') }}
                    >Integrate</Button>
                </div>
            )
        }

        // console.log(this.props.allCategories, 'allCategories')
        // console.log(total, 'total_products')
        // console.log(data, 'data')

        return <div style={{ display: 'flex' }}>
            {/* Categories list */}
            {this.state.tabCount === 0 && (
                <div className={classes.categorySidebar}>
                    <div className={classes.allCategory}>
                        {/* All categories */}
                        <p className={classnames(classes.innerText, this.state.searchObj.filter_fields?.category_ids === undefined ? classes.activeCategory : '')} onClick={() => {
                            let me = this;
                            this.setState({ page: 0, searchObj: { ...this.state.searchObj, filter_fields: {} } }, () => me.fetchData())
                        }}><b>All</b></p>
                        {/* Sub categories */}
                        {this.props.allCategories && this.props.allCategories?.Data?.map((category, index) => (
                            <p key={index} className={classnames(classes.innerText, this.state.searchObj.filter_fields?.category_ids && this.state.searchObj.filter_fields?.category_ids.includes(category.ID) ? classes.activeCategory : '')} onClick={() => {
                                let me = this;
                                this.setState({ page: 0, searchObj: { ...this.state.searchObj, filter_fields: { category_ids: [category.ID] } } }, () => me.fetchData())
                            }}>
                                {category.Name}
                            </p>
                        ))}
                    </div>
                </div>
            )}

            <div className={classnames(classes.metricContainer, this.state.tabCount === 0 ? classes.pleft : {})}>
                {!(this.state.query === '' && this.props.vendorsTotal === 0) &&
                    <AppBarV2 title="Analytics" dropdown={true} />
                }
                <div className={classes.rootContainer}>
                    {false && <div className={classes.headSectionTab}>
                        <Paper className={classes.rootPaper}>
                            <CustomTabs
                                value={this.state.tabCount}
                                onChange={(event, value) => {
                                    event.preventDefault()
                                    this.setState({
                                        tabCount: value
                                    })
                                }}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <CustomTab label="Usage Metrics" />
                                {/* <CustomTab label="Search Metrics" /> */}
                            </CustomTabs>
                        </Paper>
                    </div>}
                    {this.state.tabCount === 0 && (
                        <div className={classes.vendors}>
                            <div container className={classnames(classes.headSection)}>
                                <p className={classes.productCount}>
                                    {data.length === 0 && <span></span>}
                                    {total > 0 && <span>
                                        {total > 1 ? total+" Products" : total+" Product"}
                                    </span>}
                                </p>
                                <div className={classes.searchSection}>
                                    <SearchInput
                                        roundedCorner
                                        placeholder="Search Vendor"
                                        showTypes={false}
                                        isFilter={false}
                                        page="metrics"
                                        searchIcon={true}
                                        searchObj={this.state.searchObj}
                                        onChange={(searchObj) => {
                                            this.setState({ searchObj,page: 0 }, () => {
                                                this.fetchData();
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <FormControl variant="outlined" className={classes.formControl} size="small">
                                        <Select value={this.state.sortby} onChange={(e) => {
                                            this.setState({
                                                searchObj: { ...this.state.searchObj },
                                                sortby: e.target.value,
                                                page: 0
                                            }, () => {
                                                this.fetchData()
                                            })
                                        }}>
                                            <MenuItem value="most">Most used</MenuItem>
                                            <MenuItem value="least">Least used</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {(!fetchAppCatalogProductsProgress && data.length <= 0) && <div className={classes.DashboardNoDataLayout}>
                                <p>No Metrics found</p>
                            </div>}

                            {<InfiniteScroll style={{ overflowX: "hidden" }}
                                dataLength={data.length}
                                next={() => {
                                    var me = this;
                                    this.setState({
                                        searchObj: { ...this.state.searchObj },
                                        page: this.state.page + 1,
                                    }, () => {
                                        me.fetchData()
                                    })
                                }}
                                hasMore={total > (this.state.page + 1) * this.state.searchObj.page_size}
                                loader={<h4>Loading...</h4>}
                                endMessage={<p style={{ textAlign: 'center' }}></p>
                                }
                            >
                                <Grid container spacing={2}>
                                    {data.map((vendor, i) => {
                                        if (vendor.name === null && vendor.company_name === null) {
                                            return null
                                        }
                                        return <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                                            <VendorCard
                                                vendor={{
                                                    existing: vendor.states?.existing,
                                                    application_id: vendor.id,
                                                    logo: vendor.logo,
                                                    monthlyActive: vendor?.usage?.monthlyactive,
                                                    name: vendor.name || vendor.company_name,
                                                    source: vendor?.usage?.source,
                                                    totalUser: vendor?.usage?.weeklyactive,
                                                    weeklyActive: vendor?.usage?.weeklyactive,
                                                    weeklyActiveAvg: vendor?.usage?.weeklyactive,
                                                }}
                                            />
                                        </Grid>
                                    })}
                                </Grid>
                            </InfiniteScroll>}


                            {fetchAppCatalogProductsProgress && (
                                <Grid container justify='center'>
                                    <CircularProgress />
                                </Grid>
                            )}
                            {/* {!fetchAppCatalogProductsProgress && (
                                <span style={{ display: 'block', height: 100, textAlign: 'center', paddingTop: 20 }}><span>Not there? Help us serve you better by adding</span> <span className={classes.addProductLink} onClick={() => this.props.showAddProductManuallyDialog()}>{this.state.query}</span>  </span>
                            )} */}
                        </div>
                    )}
                    {this.state.tabCount === 1 && (
                        <div className={classes.vendors}>
                            {this.props.user && this.props.user?.OrgID === 54 ? (
                                <embed
                                    src="https://search.expent.ai/_dashboards/app/dashboards#/view/299e2ad0-2129-11ec-882e-733743b92de9?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fd%2Cto%3Anow%2Fd))&show-time-filter=true&hide-filter-bar=true"
                                    width="100%"
                                    style={{ height: '100vh' }}
                                />
                            ) : (
                                <CommingSoon
                                    title="Coming Soon"
                                    layout={"row"}
                                    feature="App Home"
                                    image={CommingSoonImg}
                                    description=""
                                />
                            )}
                        </div>
                    )}
                    {
                        this.props.addProductManuallyDialogOpen &&
                        <AddProductManuallyDialog />
                    }
                </div>
            </div>
        </div >
    }
}

export default connector(compose(
    withStyles(styles)
)(Metrics));
