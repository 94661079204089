import { createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import ReactSelect from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CommonCss from "commonCss";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);
const styles = (theme) =>
  createStyles({
    formGroup: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    formControl: {
      ...CommonCss.formControl,
      minHeight: 34,
      padding: "0px 10px",
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    supportLabel: {
      marginTop: 3,
      marginBottom: 3,
      display: "inline-block",
      marginRight: 30,
    },
  });

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    if (this.props.value && typeof this.props.value != "object") {
      let valueObj = {
        value: this.props.value,
      };
      if (typeof this.state.value === "string") {
        valueObj.label = this.props.value?.charAt(0).toUpperCase() + this.props.value?.slice(1);
      } else {
        valueObj.label = this.props.value;
      }
      this.setState({ value: valueObj });
    }
  }

  componentDidUpdate(prevProps) {}

  render() {
    const classes = this.props.classes;
    const formatedOptions = this.props.options?.map((option) => {
      if (option && typeof option === "string") {
        return {
          label: option.charAt(0).toUpperCase() + option.slice(1),
          value: option,
        };
      } else {
        return {
          label: option,
          value: option,
        };
      }
    });

    return (
      <div className={classes.formGroup}>
        <span className={classes.supportLabel}>{this.props.label}:</span>
        <div style={{ width: "100%", maxWidth: 200 }}>
          <ReactSelect
            isClearable={this.props.isClearable || false}
            options={formatedOptions}
            classNamePrefix="select"
            placeholder={"Select"}
            onChange={(e) => {
              if (e) {
                this.setState({ value: e });
                this.props.updateValue(e.value);
              } else {
                this.setState({ value: null });
                this.props.updateValue(null);
              }
            }}
            value={this.state.value}
          />
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Select));
