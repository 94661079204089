import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CircularProgress, Typography } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const connectedProps = (state) => ({
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  schemaRoot: {
    marginTop: 20,
    maxHeight: 500,
    overflow: 'auto'
  },
  tagName: {
    cursor: 'pointer',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    padding: 5
  },
  tagValue: {
    cursor: 'pointer',
    color: '#df8888'
  },
  childTagName: {
    cursor: 'pointer',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    marginLeft: 15,
    padding: 5
  },
  loader: {
    textAlign: 'center',
    marginTop: 10
  },
  subChild: {
    paddingLeft: '35px'
  },
});

class SchemaNodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  // handleClick(tag) {
  //   let additionalData = {}
  //   this.props.handleClick(tag, additionalData)
  // }

  openCloseTag(index) {
    let tags = this.state.tags;
    let tag = tags[index];
    tag.isOpen = tag.isOpen ? false : true;
    this.setState({ tags })
  }

  render() {
    const { classes, tag, key } = this.props;
    let nodeTagName = ' {{ ' + tag.name + ' }}';
    return (
      <div className={classes?.schemaNodesRoot} key={key}>
        {<p className={classes?.tagName} onClick={() => {
          // console.log(tag, 'SelectedTag')
          this.props.handleClick(tag, {
            name: ''
          })
        }}>{tag?.name} <span className={classes?.tagValue}> {nodeTagName}</span></p>}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SchemaNodes));
