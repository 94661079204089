import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";
import { addProductToIsExisting, endAddProductState, stateAddProductState } from '../../product/action';

export const RESET_ORG_PRODUCT_DATA = "RESET_ORG_PRODUCT_DATA";

export const START_ORG_PRODUCTS_COUNT = "START_ORG_PRODUCTS_COUNT";
export const END_ORG_PRODUCTS_COUNT = "END_ORG_PRODUCTS_COUNT";

export const START_FETCH_ORG_PRODUCT = "START_FETCH_ORG_PRODUCT";
export const END_FETCH_ORG_PRODUCT = "END_FETCH_ORG_PRODUCT";

export const START_FETCH_SIMILAR_EXISTING_PRODUCT = "START_FETCH_SIMILAR_EXISTING_PRODUCT";
export const END_FETCH_SIMILAR_EXISTING_PRODUCT = "END_FETCH_SIMILAR_EXISTING_PRODUCT";

export const START_ADD_ORG_PRODUCT = "START_ADD_ORG_PRODUCT";
export const END_ADD_ORG_PRODUCT = "END_ADD_ORG_PRODUCT";

export const START_ADD_ORG_PRODUCT_CATEGORIES = "START_ADD_ORG_PRODUCT_CATEGORIES";
export const END_ADD_ORG_PRODUCT_CATEGORIES = "END_ADD_ORG_PRODUCT_CATEGORIES";

export const START_ADD_ORG_PRODUCT_CHAMPIONS = "START_ADD_ORG_PRODUCT_CHAMPIONS";
export const END_ADD_ORG_PRODUCT_CHAMPIONS = "END_ADD_ORG_PRODUCT_CHAMPIONS";

export const START_ADD_ORG_PRODUCT_ALTERNATIVES = "START_ADD_ORG_PRODUCT_ALTERNATIVES";
export const END_ADD_ORG_PRODUCT_ALTERNATIVES = "END_ADD_ORG_PRODUCT_ALTERNATIVES";

export const START_FETCH_COMPLEMENTARY_PRODUCTS = "START_FETCH_COMPLEMENTARY_PRODUCTS";
export const END_FETCH_COMPLEMENTARY_PRODUCTS = "END_FETCH_COMPLEMENTARY_PRODUCTS";

export const START_FETCH_COMPANY_PRODUCTS = "START_FETCH_COMPANY_PRODUCTS";
export const END_FETCH_COMPANY_PRODUCTS = "END_FETCH_COMPANY_PRODUCTS";

export const START_FETCH_COMPLETED_REVIEWS_PRODUCTS = "START_FETCH_COMPLETED_REVIEWS_PRODUCTS";
export const END_FETCH_COMPLETED_REVIEWS_PRODUCTS = "END_FETCH_COMPLETED_REVIEWS_PRODUCTS";

export const START_FETCH_UPCOMING_REVIEWS_PRODUCTS = "START_FETCH_UPCOMING_REVIEWS_PRODUCTS";
export const END_FETCH_UPCOMING_REVIEWS_PRODUCTS = "END_FETCH_UPCOMING_REVIEWS_PRODUCTS";
export const START_ADD_PRODUCT_REVIEW = 'START_ADD_PRODUCT_REVIEW';
export const END_ADD_PRODUCT_REVIEW = 'END_ADD_PRODUCT_REVIEW';
export const START_PRODUCT_REVIEWS = 'START_PRODUCT_REVIEWS';
export const END_PRODUCT_REVIEWS = 'END_PRODUCT_REVIEWS';
export const START_DELETE_PRODUCT_REVIEW = 'START_DELETE_PRODUCT_REVIEW';
export const END_DELETE_PRODUCT_REVIEW = 'END_DELETE_PRODUCT_REVIEW';

// Org product champions dialogs
export const SHOW_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG = "SHOW_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG";
export const HIDE_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG = "HIDE_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG";

export const START_FETCH_ORG_PRODUCT_DETAILS = 'START_FETCH_ORG_PRODUCT_DETAILS';
export const END_FETCH_ORG_PRODUCT_DETAILS = 'END_FETCH_ORG_PRODUCT_DETAILS';

export const START_FETCH_ORG_CONTACT_DETAILS = 'START_FETCH_ORG_CONTACT_DETAILS';
export const END_FETCH_ORG_CONTACT_DETAILS = 'END_FETCH_ORG_CONTACT_DETAILS';

export const START_FETCH_ORG_VENDOR_POC_DETAILS = 'START_FETCH_ORG_VENDOR_POC_DETAILS';
export const END_FETCH_ORG_VENDOR_POC_DETAILS = 'END_FETCH_ORG_VENDOR_POC_DETAILS';

export const START_UPDATE_ORG_PRODUCT_DETAILS = 'START_UPDATE_ORG_PRODUCT_DETAILS';
export const END_UPDATE_ORG_PRODUCT_DETAILS = 'END_UPDATE_ORG_PRODUCT_DETAILS';

export const START_ORG_PRODUCT_GROUP_ADD = "START_ORG_PRODUCT_GROUP_ADD";
export const END_ORG_PRODUCT_GROUP_ADD = "END_ORG_PRODUCT_GROUP_ADD";

export const START_ORG_ADD_VENDOR_POC = "START_ORG_ADD_VENDOR_POC";
export const END_ORG_ADD_VENDOR_POC = "END_ORG_ADD_VENDOR_POC";

export const START_FETCH_ORG_PRODUCT_DEPARTMENT = "START_FETCH_ORG_PRODUCT_DEPARTMENT";
export const END_FETCH_ORG_PRODUCT_DEPARTMENT = "END_FETCH_ORG_PRODUCT_DEPARTMENT";

export const START_ORG_PRODUCT_GROUP_DELETE = 'START_ORG_PRODUCT_GROUP_DELETE';
export const END_ORG_PRODUCT_GROUP_DELETE = 'END_ORG_PRODUCT_GROUP_DELETE';

export const START_ORG_DELETE_VENDOR_POC = 'START_ORG_DELETE_VENDOR_POC';
export const END_ORG_DELETE_VENDOR_POC = 'END_ORG_DELETE_VENDOR_POC';

export const START_ADD_ORG_CONTACT_DETAILS = 'START_ADD_ORG_CONTACT_DETAILS';
export const END_ADD_ORG_CONTACT_DETAILS = 'END_ADD_ORG_CONTACT_DETAILS';

export const START_DELETE_ORG_CONTACT_DETAILS = 'START_DELETE_ORG_CONTACT_DETAILS';
export const END_DELETE_ORG_CONTACT_DETAILS = 'END_DELETE_ORG_CONTACT_DETAILS';

export const START_FETCH_ORG_FINANCE_DETAILS = "START_FETCH_ORG_FINANCE_DETAILS";
export const END_FETCH_ORG_FINANCE_DETAILS = "END_FETCH_ORG_FINANCE_DETAILS";

export const START_UPDATE_ORG_FINANCE_DETAILS = "START_UPDATE_ORG_FINANCE_DETAILS";
export const END_UPDATE_ORG_FINANCE_DETAILS = "END_UPDATE_ORG_FINANCE_DETAILS";

export const START_FETCH_ORG_DOCUMENTS = 'START_FETCH_ORG_DOCUMENTS';
export const END_FETCH_ORG_DOCUMENTS = 'END_FETCH_ORG_DOCUMENTS';
export const START_ADD_ORG_DOCUMENT = 'START_ADD_ORG_DOCUMENT';
export const END_ADD_ORG_DOCUMENT = 'END_ADD_ORG_DOCUMENT';
export const START_DELETE_ORG_DOCUMENT = 'START_DELETE_ORG_DOCUMENT';
export const END_DELETE_ORG_DOCUMENT = 'END_DELETE_ORG_DOCUMENT';


export const START_ADD_ORG_SECURITY_CERT = 'START_ADD_ORG_SECURITY_CERT';
export const END_ADD_ORG_SECURITY_CERT = 'END_ADD_ORG_SECURITY_CERT';
export const START_UPDATE_ORG_SECURITY_CERT = 'START_UPDATE_ORG_SECURITY_CERT';
export const END_UPDATE_ORG_SECURITY_CERT = 'END_UPDATE_ORG_SECURITY_CERT';
export const START_FETCH_ORG_SECURITY_CERT = 'START_FETCH_ORG_SECURITY_CERT';
export const END_FETCH_ORG_SECURITY_CERT = 'END_FETCH_ORG_SECURITY_CERT';
export const START_DELETE_ORG_SECURITY_CERT = 'START_DELETE_ORG_SECURITY_CERT';
export const END_DELETE_ORG_SECURITY_CERT = 'END_DELETE_ORG_SECURITY_CERT';

export const START_ADD_ORG_SECURITY_CERT_DOC = 'START_ADD_ORG_SECURITY_CERT_DOC';
export const END_ADD_ORG_SECURITY_CERT_DOC = 'END_ADD_ORG_SECURITY_CERT_DOC';

export const START_FETCH_ORG_SECURITY_CERT_DOC = 'START_FETCH_ORG_SECURITY_CERT_DOC';
export const END_FETCH_ORG_SECURITY_CERT_DOC = 'END_FETCH_ORG_SECURITY_CERT_DOC';

export const START_ADD_ORG_PII = 'START_ADD_ORG_PII';
export const END_ADD_ORG_PII = 'END_ADD_ORG_PII';
export const START_DELETE_ORG_PII = 'START_DELETE_ORG_PII';
export const END_DELETE_ORG_PII = 'END_DELETE_ORG_PII';
export const START_FETCH_ORG_PII = 'START_FETCH_ORG_PII';
export const END_FETCH_ORG_PII = 'END_FETCH_ORG_PII';

export const START_FETCH_ORG_RISK_POSTURE = 'START_FETCH_ORG_RISK_POSTURE';
export const END_FETCH_ORG_RISK_POSTURE = 'END_FETCH_ORG_RISK_POSTURE';


export const START_ORG_PRODUCT_COMPLETED_REVIEWS = 'START_ORG_PRODUCT_COMPLETED_REVIEWS';
export const END_ORG_PRODUCT_COMPLETED_REVIEWS = 'END_ORG_PRODUCT_COMPLETED_REVIEWS';

export const START_ORG_PRODUCT_INPROGRESS_REVIEWS = 'START_ORG_PRODUCT_INPROGRESS_REVIEWS';
export const END_ORG_PRODUCT_INPROGRESS_REVIEWS = 'END_ORG_PRODUCT_INPROGRESS_REVIEWS';

export const START_FETCH_ORG_FEATURES = 'START_FETCH_ORG_FEATURES';
export const END_FETCH_ORG_FEATURES = 'END_FETCH_ORG_FEATURES';

export const START_FETCH_CONTRACT_FOLDERS = 'START_FETCH_CONTRACT_FOLDERS';
export const END_FETCH_CONTRACT_FOLDERS = 'END_FETCH_CONTRACT_FOLDERS';

export const START_UPDATE_CONTRACT_FOLDERS = 'START_UPDATE_CONTRACT_FOLDERS';
export const END_UPDATE_CONTRACT_FOLDERS = 'END_UPDATE_CONTRACT_FOLDERS';

export const START_ADD_ORG_FEATURES = 'START_ADD_ORG_FEATURES';
export const END_ADD_ORG_FEATURES = 'END_ADD_ORG_FEATURES';

export const START_DELETE_ORG_FEATURES = 'START_DELETE_ORG_FEATURES';
export const END_DELETE_ORG_FEATURES = 'END_DELETE_ORG_FEATURES';

export const START_FETCH_ORG_SUBCATEGORIES = 'START_FETCH_ORG_SUBCATEGORIES';
export const END_FETCH_ORG_SUBCATEGORIES = 'END_FETCH_ORG_SUBCATEGORIES';

export const START_ADD_ORG_SUBCATEGORIES = 'START_ADD_ORG_SUBCATEGORIES';
export const END_ADD_ORG_SUBCATEGORIES = 'END_ADD_ORG_SUBCATEGORIES';

export const START_ADD_ORG_CATEGORIES = 'START_ADD_ORG_CATEGORIES';
export const END_ADD_ORG_CATEGORIES = 'END_ADD_ORG_CATEGORIES';

export const START_ADD_NEW_ORG_CATEGORIES = 'START_ADD_NEW_ORG_CATEGORIES';
export const END_ADD_NEW_ORG_CATEGORIES = 'END_ADD_NEW_ORG_CATEGORIES';

export const START_DELETE_ORG_SUBCATEGORIES = 'START_DELETE_ORG_SUBCATEGORIES';
export const END_DELETE_ORG_SUBCATEGORIES = 'END_DELETE_ORG_SUBCATEGORIES';
export const START_DELETE_ORG_CATEGORIES = 'START_DELETE_ORG_CATEGORIES';
export const END_DELETE_ORG_CATEGORIES = 'END_DELETE_ORG_CATEGORIES';

export const START_FETCH_ORG_PLATFORM = 'START_FETCH_ORG_PLATFORM';
export const END_FETCH_ORG_PLATFORM = 'END_FETCH_ORG_PLATFORM';

export const START_ADD_ORG_PLATFORM = 'START_ADD_ORG_PLATFORM';
export const END_ADD_ORG_PLATFORM = 'END_ADD_ORG_PLATFORM';

export const START_DELETE_ORG_PLATFORM = 'START_DELETE_ORG_PLATFORM';
export const END_DELETE_ORG_PLATFORM = 'END_DELETE_ORG_PLATFORM';

export const START_FETCH_ORG_RELATED_PRODUCTS = 'START_FETCH_ORG_RELATED_PRODUCTS';
export const END_FETCH_ORG_RELATED_PRODUCTS = 'END_FETCH_ORG_RELATED_PRODUCTS';

export const START_ADD_ORG_RELATED_PRODUCTS = 'START_ADD_ORG_RELATED_PRODUCTS';
export const END_ADD_ORG_RELATED_PRODUCTS = 'END_ADD_ORG_RELATED_PRODUCTS';

export const START_DELETE_ORG_RELATED_PRODUCTS = 'START_DELETE_ORG_RELATED_PRODUCTS';
export const END_DELETE_ORG_RELATED_PRODUCTS = 'END_DELETE_ORG_RELATED_PRODUCTS';

export const START_DELETE_PRODUCT = 'START_DELETE_PRODUCT';
export const END_DELETE_PRODUCT = 'END_DELETE_PRODUCT';

export const START_FETCH_PARSE_CONTRACT_DETAILS = 'START_FETCH_PARSE_CONTRACT_DETAILS';
export const END_FETCH_PARSE_CONTRACT_DETAILS = 'END_FETCH_PARSE_CONTRACT_DETAILS';

export const SHOW_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG = "SHOW_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG"
export const HIDE_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG = "HIDE_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG"

export const START_ADD_CONTRACT_DETAILS = 'START_ADD_CONTRACT_DETAILS';
export const END_ADD_CONTRACT_DETAILS = 'END_ADD_CONTRACT_DETAILS';

export const START_FETCH_EXISTING_SIMILAR_PRODUCTS = 'START_FETCH_EXISTING_SIMILAR_PRODUCTS';

export const END_FETCH_EXISTING_SIMILAR_PRODUCTS = 'END_FETCH_EXISTING_SIMILAR_PRODUCTS';

export const START_FETCH_ALTERNATIVE_PRODUCTS = "START_FETCH_ALTERNATIVE_PRODUCTS"
export const END_FETCH_ALTERNATIVE_PRODUCTS = "END_FETCH_ALTERNATIVE_PRODUCTS"

export const START_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING = "START_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING"
export const END_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING = "END_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING"

export const RESET_SIMILAR_EXISTING_PRODUCTS = "RESET_SIMILAR_EXISTING_PRODUCTS"

export const START_SEARCH_ORG_PRODUCTS = "START_SEARCH_ORG_PRODUCTS"
export const END_SEARCH_ORG_PRODUCTS = "END_SEARCH_ORG_PRODUCTS"

export const START_FETCH_ORG_PRODUCT_DETAILS_TABS = "START_FETCH_ORG_PRODUCT_DETAILS_TABS"
export const END_FETCH_ORG_PRODUCT_DETAILS_TABS = "END_FETCH_ORG_PRODUCT_DETAILS_TABS"

export const START_FETCH_ORG_PRODUCT_TABS = "START_FETCH_ORG_PRODUCT_TABS"
export const END_FETCH_ORG_PRODUCT_TABS = "END_FETCH_ORG_PRODUCT_TABS"

export const START_ADD_ORG_PRODUCT_DETAILS_TAB = "START_ADD_ORG_PRODUCT_DETAILS_TAB"
export const END_ADD_ORG_PRODUCT_DETAILS_TAB = "END_ADD_ORG_PRODUCT_DETAILS_TAB"
export const START_UPDATE_ORG_PRODUCT_DETAILS_TAB = "START_UPDATE_ORG_PRODUCT_DETAILS_TAB"
export const END_UPDATE_ORG_PRODUCT_DETAILS_TAB = "END_UPDATE_ORG_PRODUCT_DETAILS_TAB"
export const START_DELETE_ORG_PRODUCT_DETAILS_TAB = "START_DELETE_ORG_PRODUCT_DETAILS_TAB"
export const END_DELETE_ORG_PRODUCT_DETAILS_TAB = "END_DELETE_ORG_PRODUCT_DETAILS_TAB"

export const START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION = "START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION"
export const END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION = "END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION"
export const START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION = "START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION"
export const END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION = "END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION"
export const START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION = "START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION"
export const END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION = "END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION"

export const START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM = "START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM"
export const END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM = "END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM"
export const START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM = "START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM"
export const END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM = "END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM"

export const START_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA = "START_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA"
export const END_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA = "END_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA"
export const START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA = "START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA"
export const END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA = "END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA"
export const START_ADD_ORG_CONTACT_DETAILS_LINK = "START_ADD_ORG_CONTACT_DETAILS_LINK"
export const END_ADD_ORG_CONTACT_DETAILS_LINK = "END_ADD_ORG_CONTACT_DETAILS_LINK"

export const START_DELETE_ORG_CONTACT_DETAIL_LINK = "START_DELETE_ORG_CONTACT_DETAIL_LINK"
export const END_DELETE_ORG_CONTACT_DETAIL_LINK = "END_DELETE_ORG_CONTACT_DETAIL_LINK"

export const START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM = "START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM"
export const END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM = "END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM"

export const START_UPDATE_CONTRACT_DETAILS = "START_UPDATE_CONTRACT_DETAILS";
export const END_UPDATE_CONTRACT_DETAILS = "END_UPDATE_CONTRACT_DETAILS";
export const ACTIVE_CONTRACT_DETAILS = "ACTIVE_CONTRACT_DETAILS";

export const START_FETCH_PRODUCT_PRICE = "START_FETCH_PRODUCT_PRICE";
export const END_FETCH_PRODUCT_PRICE = "END_FETCH_PRODUCT_PRICE"

export const START_FETCH_FEATURED_PRODUCT = "START_FETCH_FEATURED_PRODUCT";
export const END_FETCH_FEATURED_PRODUCT = "END_FETCH_FEATURED_PRODUCT";

export const SHOW_ADD_PRODUCT_TEAMS_DIALOG = "SHOW_ADD_PRODUCT_TEAMS_DIALOG";
export const HIDE_ADD_PRODUCT_TEAMS_DIALOG = "HIDE_ADD_PRODUCT_TEAMS_DIALOG";

export const START_FETCH_PRODUCT_TAB_TEAMS = "START_FETCH_PRODUCT_TAB_TEAMS";
export const END_FETCH_PRODUCT_TAB_TEAMS = "END_FETCH_PRODUCT_TAB_TEAMS";

export const START_FETCH_PRODUCT_SECTION_TEAMS = "START_FETCH_PRODUCT_SECTION_TEAMS";
export const END_FETCH_PRODUCT_SECTION_TEAMS = "END_FETCH_PRODUCT_SECTION_TEAMS";

export const START_ADD_PRODUCT_TAB_TEAMS = "START_ADD_PRODUCT_TAB_TEAMS";
export const END_ADD_PRODUCT_TAB_TEAMS = "END_ADD_PRODUCT_TAB_TEAMS";

export const START_ADD_PRODUCT_SECTION_TEAMS = "START_ADD_PRODUCT_SECTION_TEAMS";
export const END_ADD_PRODUCT_SECTION_TEAMS = "END_ADD_PRODUCT_SECTION_TEAMS";

export const START_DELETE_PRODUCT_TAB_TEAMS = "START_DELETE_PRODUCT_TAB_TEAMS";
export const END_DELETE_PRODUCT_TAB_TEAMS = "END_DELETE_PRODUCT_TAB_TEAMS";

export const START_DELETE_PRODUCT_SECTION_TEAMS = "START_DELETE_PRODUCT_SECTION_TEAMS";
export const END_DELETE_PRODUCT_SECTION_TEAMS = "END_DELETE_PRODUCT_SECTION_TEAMS";

export const START_ALL_PRODUCT_SEARCH = "START_ALL_PRODUCT_SEARCH";
export const END_ALL_PRODUCT_SEARCH = "END_ALL_PRODUCT_SEARCH";
export const START_FETCH_DWNLD_PRODUCT_CSV_FILE = "START_FETCH_DWNLD_PRODUCT_CSV_FILE"
export const END_FETCH_DWNLD_PRODUCT_CSV_FILE = "END_FETCH_DWNLD_PRODUCT_CSV_FILE"
export const START_IMPORT_PRODUCT_DATA_FILE = "START_IMPORT_PRODUCT_DATA_FILE"
export const END_IMPORT_PRODUCT_DATA_FILE = "END_IMPORT_PRODUCT_DATA_FILE"

export const START_FETCH_ORG_PRODUCT_TAB_ITEMS = "START_FETCH_ORG_PRODUCT_TAB_ITEMS";
export const END_FETCH_ORG_PRODUCT_TAB_ITEMS = "END_FETCH_ORG_PRODUCT_TAB_ITEMS";

export const START_ADD_NEW_CONTRACT_DETAILS = "START_ADD_NEW_CONTRACT_DETAILS";
export const END_ADD_NEW_CONTRACT_DETAILS = "END_ADD_NEW_CONTRACT_DETAILS";

export const START_FETCH_OVERALL_ANALYSYS_DATA = "START_FETCH_OVERALL_ANALYSYS_DATA";
export const END_FETCH_OVERALL_ANALYSYS_DATA = "END_FETCH_OVERALL_ANALYSYS_DATA";

export const START_FETCH_TEAM_WISE_ANALYSYS_DATA = "START_FETCH_TEAM_WISE_ANALYSYS_DATA";
export const END_FETCH_TEAM_WISE_ANALYSYS_DATA = "END_FETCH_TEAM_WISE_ANALYSYS_DATA";

export const START_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA = "START_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA";
export const END_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA = "END_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA";

export const START_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA = "START_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA";
export const END_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA = "END_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA";

export const START_FETCH_ANALYSYS_CRITERIAS = "START_FETCH_ANALYSYS_CRITERIAS";
export const END_FETCH_ANALYSYS_CRITERIAS = "END_FETCH_ANALYSYS_CRITERIAS";

export const START_FETCH_ANALYSYS_REQUIREMENTS = "START_FETCH_ANALYSYS_REQUIREMENTS";
export const END_FETCH_ANALYSYS_REQUIREMENTS = "END_FETCH_ANALYSYS_REQUIREMENTS";

export const RESET_ANALYSIS_DATA = "RESET_ANALYSIS_DATA";

export const START_UPDATE_ORG_DOCUMENT = "START_UPDATE_ORG_DOCUMENT";
export const END_UPDATE_ORG_DOCUMENT = "END_UPDATE_ORG_DOCUMENT";

export const START_GET_ORG_DOCUMENT_DETAILS = "START_GET_ORG_DOCUMENT_DETAILS";
export const END_GET_ORG_DOCUMENT_DETAILS = "END_GET_ORG_DOCUMENT_DETAILS";

export const START_UPDATE_DOCUMENT_FILE = "START_UPDATE_DOCUMENT_FILE";
export const END_UPDATE_DOCUMENT_FILE = "END_UPDATE_DOCUMENT_FILE";

export const SHOW_ADD_ORG_PRODUCT_WORKFLOW_DIALOG = "SHOW_ADD_ORG_PRODUCT_WORKFLOW_DIALOG";
export const HIDE_ADD_ORG_PRODUCT_WORKFLOW_DIALOG = "HIDE_ADD_ORG_PRODUCT_WORKFLOW_DIALOG";

export function resetAnalysisData() {
    return {
        type: RESET_ANALYSIS_DATA,
        payload: null
    }
}


function startFetchAnalysisCriterias() {
    return {
        type: START_FETCH_ANALYSYS_CRITERIAS,
        payload: {
        }
    }
}

function endFetchAnalysisCriterias(success, error) {
    return {
        type: END_FETCH_ANALYSYS_CRITERIAS,
        payload: {
            success,
            error
        }
    }
}

export function fetchAnalysisCriterias(productId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/${productId}/criteria?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAnalysisCriterias());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAnalysisCriterias(response, error));
        } catch (e) {
            dispatch(endFetchAnalysisCriterias(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAnalysisRequirements() {
    return {
        type: START_FETCH_ANALYSYS_REQUIREMENTS,
        payload: {
        }
    }
}

function endFetchAnalysisRequirements(success, error) {
    return {
        type: END_FETCH_ANALYSYS_REQUIREMENTS,
        payload: {
            success,
            error
        }
    }
}

export function fetchAnalysisRequirements(productId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/${productId}/requirement?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAnalysisRequirements());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAnalysisRequirements(response, error));
        } catch (e) {
            dispatch(endFetchAnalysisRequirements(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchOverallAnalysisData() {
    return {
        type: START_FETCH_OVERALL_ANALYSYS_DATA,
        payload: {
        }
    }
}

function endFetchOverallAnalysisData(success, error) {
    return {
        type: END_FETCH_OVERALL_ANALYSYS_DATA,
        payload: {
            success,
            error
        }
    }
}

export function fetchOverallAnalysisData(productId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/${productId}/overall_scores?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOverallAnalysisData());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOverallAnalysisData(response, error));
        } catch (e) {
            dispatch(endFetchOverallAnalysisData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchRequirementWiseAnalysisData() {
    return {
        type: START_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA,
        payload: {
        }
    }
}

function endFetchRequirementWiseAnalysisData(success, error) {
    return {
        type: END_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA,
        payload: {
            success,
            error
        }
    }
}

export function fetchRequirementWiseAnalysisData(productId, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/${productId}/requirement/fetch_overall_scores?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchRequirementWiseAnalysisData());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endFetchRequirementWiseAnalysisData(response, error));
        } catch (e) {
            dispatch(endFetchRequirementWiseAnalysisData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchCriteriaRequirementWiseAnalysisData() {
    return {
        type: START_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA,
        payload: {
        }
    }
}

function endFetchCriteriaRequirementWiseAnalysisData(success, error) {
    return {
        type: END_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA,
        payload: {
            success,
            error
        }
    }
}

export function fetchCriteriaRequirementWiseAnalysisData(productId, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/${productId}/criteria/requirement/fetch_overall_scores?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchCriteriaRequirementWiseAnalysisData());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endFetchCriteriaRequirementWiseAnalysisData(response, error));
        } catch (e) {
            dispatch(endFetchCriteriaRequirementWiseAnalysisData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchTeamWiseAnalysisData() {
    return {
        type: START_FETCH_TEAM_WISE_ANALYSYS_DATA,
        payload: {
        }
    }
}

function endFetchTeamWiseAnalysisData(success, error) {
    return {
        type: END_FETCH_TEAM_WISE_ANALYSYS_DATA,
        payload: {
            success,
            error
        }
    }
}

export function fetchTeamWiseAnalysisData(productId, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/${productId}/criteria/fetch_overall_scores?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchTeamWiseAnalysisData());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endFetchTeamWiseAnalysisData(response, error));
        } catch (e) {
            dispatch(endFetchTeamWiseAnalysisData(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAllProductSearch(searchQuery) {
    return {
        type: START_ALL_PRODUCT_SEARCH,
        payload: {
            searchQuery
        }
    }
}

function endAllProductSearch(searchQuery, success, error) {
    return {
        type: END_ALL_PRODUCT_SEARCH,
        payload: {
            searchQuery,
            success,
            error
        }
    }
}

export function allproductSearch(searchQuery) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/v2/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAllProductSearch(searchQuery));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, searchQuery, false);
            dispatch(endAllProductSearch(searchQuery, response, error));
        } catch (e) {
            dispatch(endAllProductSearch(searchQuery, null, {
                message: e.message
            }));
            return;
        }
    };
}

// reset data
function startResetData() {
    return {
        type: RESET_ORG_PRODUCT_DATA,
        payload: null
    }
}
export function resetAddOrgProductData() {
    return async (dispatch) => {
        dispatch(startResetData())
    }
}

/**
 * Product stats
 */
function startOrgProductCountStats() {
    return {
        type: START_ORG_PRODUCTS_COUNT,
        payload: {}
    };
}

function endOrgProductCountStats(success, error) {
    return {
        type: END_ORG_PRODUCTS_COUNT,
        payload: {
            success, error
        }
    };
}

export function fetchAddOrgProductCountStats() {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/stats?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startOrgProductCountStats());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endOrgProductCountStats(response, error));
        } catch (e) {
            dispatch(endOrgProductCountStats(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgProduct(page) {
    return {
        type: START_FETCH_ORG_PRODUCT,
        payload: page
    };
}

function endFetchOrgProduct(success, error) {
    return {
        type: END_FETCH_ORG_PRODUCT,
        payload: {
            success, error
        }
    };
}

export function fetchOrgProducts(page, pageSize, query, group_status = "both", additionalParam = null) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    // throw new Error("I want to catch This");
    const url = `${api_server}/product/org/all/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOrgProduct(page));
        try {

            var bodyData = {
                page: page,
                pageSize: pageSize,
                query: query,
                group_status: group_status,
            }
            if (additionalParam !== null) {
                Object.keys(additionalParam).forEach(function (key) {
                    bodyData[key] = additionalParam[key];
                });
            }

            let [response, error] = await HttpFactory.instance().postMethod(url, bodyData);
            dispatch(endFetchOrgProduct(response, error));


        } catch (e) {
            dispatch(endFetchOrgProduct(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchFeaturedProduct() {
    return {
        type: START_FETCH_FEATURED_PRODUCT,
        payload: {}
    };
}

function endFetchFeaturedProduct(success, error) {
    return {
        type: END_FETCH_FEATURED_PRODUCT,
        payload: {
            success, error
        }
    };
}

export function fetchFeaturedProducts(subcategoryId) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const timestamp = (new Date()).getTime();
    // throw new Error("I want to catch This");
    let url = `${api_server}/featured-products?t=${timestamp}`;
    if (subcategoryId !== undefined) {
        url = `${api_server}/featured-products?subcategory=${subcategoryId}&t=${timestamp}`;
    }
    return async (dispatch) => {
        dispatch(startFetchFeaturedProduct());
        try {

            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchFeaturedProduct(response, error));


        } catch (e) {
            dispatch(endFetchFeaturedProduct(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgProduct() {
    return {
        type: START_ADD_ORG_PRODUCT,
        payload: {}
    };
}

function endAddOrgProduct(success, error) {
    return {
        type: END_ADD_ORG_PRODUCT,
        payload: {
            success, error
        }
    };
}

export function fetchAddOrgProduct(query, isExisting) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgProduct());
        if (isExisting) dispatch(stateAddProductState());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, query, false);
            dispatch(endAddOrgProduct(response, error));
            if (isExisting) {
                dispatch(addProductToIsExisting(query, error))
                if (query?.productId) dispatch(endAddProductState(query.productId, { ID: query.productId, State: 'existing' }))
            }
            if (response && response.orgProduct) {
                // Add pproduct category
                if (query.cat && query.cat.length > 0) {
                    const cats = query.cat.map(x => x.ID)
                    let product_id = response.orgProduct.id
                    dispatch(fetchAddOrgProductCats({ categoryIDs: cats }, product_id))
                }
                // Add pproduct champions
                if (query.champ && query.champ.length > 0) {
                    const champs = query.champ.map(x => x.ID)
                    let product_id = response.orgProduct.id
                    dispatch(fetchAddOrgProductChampions({ champions: champs }, product_id))
                }
            }

        } catch (e) {
            dispatch(endAddOrgProduct(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startfetchdownloadProductCSVFileLink() {
    return {
        type: START_FETCH_DWNLD_PRODUCT_CSV_FILE,
        payload: {}
    };
}

function endfetchdownloadProductCSVFileLink(success, error) {
    return {
        type: END_FETCH_DWNLD_PRODUCT_CSV_FILE,
        payload: {
            success, error
        }
    };
}

export function fetchdownloadProductCSVFileLink(itemIds = []) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/download_template?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startfetchdownloadProductCSVFileLink());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                with_application_catalog: true,
                itemIDs: itemIds
            });
            dispatch(endfetchdownloadProductCSVFileLink(response, error));
        } catch (e) {
            dispatch(endfetchdownloadProductCSVFileLink(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startimportProductDataFile() {
    return {
        type: START_IMPORT_PRODUCT_DATA_FILE,
        payload: {}
    };
}

function endimportProductDataFile(success, error) {
    return {
        type: END_IMPORT_PRODUCT_DATA_FILE,
        payload: {
            success, error
        }
    };
}

export function importProductDataFile(file_path) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/import_data?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startimportProductDataFile());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                file_path
            });
            dispatch(endimportProductDataFile(response, error));
        } catch (e) {
            dispatch(endimportProductDataFile(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddOrgProductCats() {
    return {
        type: START_ADD_ORG_PRODUCT_CATEGORIES,
        payload: {}
    };
}

function endAddOrgProductCats(success, error) {
    return {
        type: END_ADD_ORG_PRODUCT_CATEGORIES,
        payload: {
            success, error
        }
    };
}

export function fetchAddOrgProductCats(cats, product_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${product_id}/categories?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgProductCats());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, cats);
            dispatch(endAddOrgProductCats(response, error));
        } catch (e) {
            dispatch(endAddOrgProductCats(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startAddOrgProductChamp() {
    return {
        type: START_ADD_ORG_PRODUCT_CHAMPIONS,
        payload: {}
    };
}

function endAddOrgProductChamp(success, error) {
    return {
        type: END_ADD_ORG_PRODUCT_CHAMPIONS,
        payload: {
            success, error
        }
    };
}

export function fetchAddOrgProductChampions(champs, product_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${product_id}/champions?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgProductChamp());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, champs);
            dispatch(endAddOrgProductChamp(response, error));
        } catch (e) {
            dispatch(endAddOrgProductChamp(null, {
                message: e.message
            }));
            return;
        }
    };
}

/**
 *  Org product alternatives products
 */
function startOrgProductAlternatives() {
    return {
        type: START_ADD_ORG_PRODUCT_ALTERNATIVES,
        payload: {}
    };
}

function endOrgProductAlternatives(success, error) {
    return {
        type: END_ADD_ORG_PRODUCT_ALTERNATIVES,
        payload: {
            success, error
        }
    };
}

export function fetchOrgProductAlternatives(product_id) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const url = `${api_server}/similar-products/${product_id}`;
    return async (dispatch) => {
        dispatch(startOrgProductAlternatives());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endOrgProductAlternatives(response, error));
        } catch (e) {
            dispatch(endOrgProductAlternatives(null, {
                message: e.message
            }));
            return;
        }
    };
}



/**
 *  Org product complementary products
 */
function startFetchComplementaryProducts() {
    return {
        type: START_FETCH_COMPLEMENTARY_PRODUCTS,
        payload: {}
    };
}

function endFetchComplementaryProducts(success, error) {
    return {
        type: END_FETCH_COMPLEMENTARY_PRODUCTS,
        payload: {
            success, error
        }
    };
}

export function fetchComplementaryProducts(product_id) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const url = `${api_server}/complementary-products/${product_id}`;
    return async (dispatch) => {
        dispatch(startFetchComplementaryProducts());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchComplementaryProducts(response, error));
        } catch (e) {
            dispatch(endFetchComplementaryProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}


/**
 *  Company products
 */
function startCompanyProductdetails() {
    return {
        type: START_FETCH_COMPANY_PRODUCTS,
        payload: {}
    };
}

function endCompanyProductdetails(success, error) {
    return {
        type: END_FETCH_COMPANY_PRODUCTS,
        payload: {
            success, error
        }
    };
}

export function fetchCompanyProductDetails(product_id) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const url = `${api_server}/company-products-details/${product_id}`;
    return async (dispatch) => {
        dispatch(startCompanyProductdetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endCompanyProductdetails(response, error));
        } catch (e) {
            dispatch(endCompanyProductdetails(null, {
                message: e.message
            }));
            return;
        }
    };
}

/**
 *  completed review products
 */
function startCompletedReviewProducts(params) {
    return {
        type: START_FETCH_COMPLETED_REVIEWS_PRODUCTS,
        payload: {
            params
        }
    };
}

function endCompletedReviewProducts(params, success, error) {
    return {
        type: END_FETCH_COMPLETED_REVIEWS_PRODUCTS,
        payload: {
            params, success, error
        }
    };
}

export function fetchCompletedReviewProducts(params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/evaluation/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startCompletedReviewProducts(params));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
            dispatch(endCompletedReviewProducts(params, response, error));
        } catch (e) {
            dispatch(endCompletedReviewProducts(null, null, {
                message: e.message
            }));
            return;
        }
    };
}



/**
 *  completed review products
 */
function startUpcomingReviewProducts(params) {
    return {
        type: START_FETCH_UPCOMING_REVIEWS_PRODUCTS,
        payload: {
            params
        }
    };
}

function endUpcomingReviewProducts(params, success, error) {
    return {
        type: END_FETCH_UPCOMING_REVIEWS_PRODUCTS,
        payload: {
            params, success, error
        }
    };
}

export function fetchUpcomingReviewProducts(params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/evaluation/products?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startUpcomingReviewProducts(params));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
            dispatch(endUpcomingReviewProducts(params, response, error));
        } catch (e) {
            dispatch(endUpcomingReviewProducts(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddProductToReview(id) {
    return {
        type: START_ADD_PRODUCT_REVIEW,
        payload: {
            id
        }
    };
}

function endAddProductToReview(success, error) {
    return {
        type: END_ADD_PRODUCT_REVIEW,
        payload: {
            success, error
        }
    };
}

function startProductReviews() {
    return {
        type: START_PRODUCT_REVIEWS,
        payload: null
    };
}

function endProductReviews(success, error) {
    return {
        type: END_PRODUCT_REVIEWS,
        payload: {
            success, error
        }
    };
}

function startDeleteProductReview() {
    return {
        type: START_DELETE_PRODUCT_REVIEW,
        payload: null
    };
}

function endDeleteProductReview(success, error, id) {
    return {
        type: END_DELETE_PRODUCT_REVIEW,
        payload: {
            success, error, id
        }
    };
}

export function addProductToReview(id, params = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review/${id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddProductToReview(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
            dispatch(endAddProductToReview(response, error));
        } catch (e) {
            dispatch(endAddProductToReview(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function fetchProductReviews(params) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startProductReviews());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
            dispatch(endProductReviews(response, error));
        } catch (e) {
            dispatch(endProductReviews(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


export function deleteProductReview(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review/${id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteProductReview());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteProductReview(response, error, id));
        } catch (e) {
            dispatch(endDeleteProductReview(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


/**
 * Org product champions dialogs
 */
export function showAddOrgProductChampionsDialog(payload) {
    return {
        type: SHOW_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG,
        payload: payload
    };
}

export function hideAddOrgProductChampionsDialog() {
    return {
        type: HIDE_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG,
        payload: {}
    };
}

export function showAddProductWorkflowDialog(payload) {
    return {
        type: SHOW_ADD_ORG_PRODUCT_WORKFLOW_DIALOG,
        payload: payload
    };
}

export function hideAddProductWorkflowDialog() {
    return {
        type: HIDE_ADD_ORG_PRODUCT_WORKFLOW_DIALOG,
        payload: {}
    };
}

function startFetchOrgProductDetails() {
    return {
        type: START_FETCH_ORG_PRODUCT_DETAILS,
        payload: null
    };
}

function endFetchOrgProductDetails(success, error) {
    return {
        type: END_FETCH_ORG_PRODUCT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchOrgProductDetails(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgProductDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url, null, true);
            dispatch(endFetchOrgProductDetails(response, error));
            return response;
        } catch (e) {
            dispatch(endFetchOrgProductDetails(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOrgProductDetails() {
    return {
        type: START_UPDATE_ORG_PRODUCT_DETAILS,
        payload: null
    };
}

function endUpdateOrgProductDetails(success, error) {
    return {
        type: END_UPDATE_ORG_PRODUCT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function updateOrgProductDetails(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startUpdateOrgProductDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, params);
            dispatch(endUpdateOrgProductDetails(response, error));
        } catch (e) {
            dispatch(endUpdateOrgProductDetails(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgContactDetails() {
    return {
        type: START_FETCH_ORG_CONTACT_DETAILS,
        payload: null
    };
}

function endFetchOrgContactDetails(success, error) {
    return {
        type: END_FETCH_ORG_CONTACT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchOrgContactDetails(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/all_contact_details?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgContactDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endFetchOrgContactDetails(response, error));
        } catch (e) {
            dispatch(endFetchOrgContactDetails(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgContactDetails() {
    return {
        type: START_ADD_ORG_CONTACT_DETAILS,
        payload: null
    };
}

function endAddOrgContactDetails(success, error) {
    return {
        type: END_ADD_ORG_CONTACT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function addOrgContactDetails(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/contact_details?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgContactDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgContactDetails(response, error));
        } catch (e) {
            dispatch(endAddOrgContactDetails(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgDeleteContactDetails() {
    return {
        type: START_DELETE_ORG_CONTACT_DETAILS,
        payload: null
    };
}

function endOrgDeleteContactDetails(success, error) {
    return {
        type: END_DELETE_ORG_CONTACT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function orgDeleteContactDetails(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/contact_details?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startOrgDeleteContactDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, true, params);
            dispatch(endOrgDeleteContactDetails(response, error));
        } catch (e) {
            dispatch(endOrgDeleteContactDetails(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgVendorPocDetails() {
    return {
        type: START_FETCH_ORG_VENDOR_POC_DETAILS,
        payload: null
    };
}

function endFetchOrgVendorPocDetails(success, error) {
    return {
        type: END_FETCH_ORG_VENDOR_POC_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchOrgVendorPocDetails(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/vendor_poc?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgVendorPocDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgVendorPocDetails(response, error));
        } catch (e) {
            dispatch(endFetchOrgVendorPocDetails(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgProductGroup() {
    return {
        type: START_ORG_PRODUCT_GROUP_ADD,
        payload: null
    };
}

function endAddOrgProductGroup(success, error) {
    return {
        type: END_ORG_PRODUCT_GROUP_ADD,
        payload: {
            success, error
        }
    };
}

export function addOrgProductGroup(params) {
    return async (dispatch) => {
        dispatch(startAddOrgProductGroup());
        try {
            const api_server = Environment.api_host("PRODUCT");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/org/products/group?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgProductGroup(response, error));
        } catch (e) {
            dispatch(endAddOrgProductGroup(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgProductGroup() {
    return {
        type: START_ORG_PRODUCT_GROUP_DELETE,
        payload: null
    };
}

function endDeleteProductGroup(success, error) {
    return {
        type: END_ORG_PRODUCT_GROUP_DELETE,
        payload: {
            success, error
        }
    };
}

export function deleteOrgProductGroup(params) {
    return async (dispatch) => {
        dispatch(startDeleteOrgProductGroup());
        try {
            const api_server = Environment.api_host("PRODUCT");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/org/products/${params.productID}/group?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().deleteMethod(url, true, params);
            dispatch(endDeleteProductGroup(response, error));
        } catch (e) {
            dispatch(endDeleteProductGroup(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgAddVendorPoc() {
    return {
        type: START_ORG_ADD_VENDOR_POC,
        payload: null
    };
}

function endOrgAddVendorPoc(success, error) {
    return {
        type: END_ORG_ADD_VENDOR_POC,
        payload: {
            success, error
        }
    };
}

export function orgProductAddVendorPoc(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/vendor_poc?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startOrgAddVendorPoc(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endOrgAddVendorPoc(response, error));
        } catch (e) {
            dispatch(endOrgAddVendorPoc(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgDeleteVendorPoc() {
    return {
        type: START_ORG_DELETE_VENDOR_POC,
        payload: null
    };
}

function endOrgDeleteVendorPoc(success, error) {
    return {
        type: END_ORG_DELETE_VENDOR_POC,
        payload: {
            success, error
        }
    };
}

export function orgProductDeleteVendorPoc(id, deleteVendorId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/vendor_poc/${deleteVendorId}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startOrgDeleteVendorPoc(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, {});
            dispatch(endOrgDeleteVendorPoc(response, error));
        } catch (e) {
            dispatch(endOrgDeleteVendorPoc(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgProductDepartment() {
    return {
        type: START_FETCH_ORG_PRODUCT_DEPARTMENT,
        payload: null
    };
}

function endFetchOrgProductDepartment(success, error) {
    return {
        type: END_FETCH_ORG_PRODUCT_DEPARTMENT,
        payload: {
            success, error
        }
    };
}

export function fetchOrgProductDepartment(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/group?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgProductDepartment(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgProductDepartment(response, error));
        } catch (e) {
            dispatch(endFetchOrgProductDepartment(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchContractFolders() {
    return {
        type: START_FETCH_CONTRACT_FOLDERS,
        payload: null
    };
}

function endFetchContractFolders(success, error) {
    return {
        type: END_FETCH_CONTRACT_FOLDERS,
        payload: {
            success, error
        }
    };
}

export function fetchContractFolders(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/product/${id}/item/contract_details/folder?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchContractFolders(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchContractFolders(response, error));
        } catch (e) {
            dispatch(endFetchContractFolders(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgFinanceDetails() {
    return {
        type: START_FETCH_ORG_FINANCE_DETAILS,
        payload: null
    };
}

function endFetchOrgFinanceDetails(success, error) {
    return {
        type: END_FETCH_ORG_FINANCE_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchOrgFinanceDetails(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/financial/documents?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgFinanceDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgFinanceDetails(response, error));
        } catch (e) {
            dispatch(endFetchOrgFinanceDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOrgFinanceDetails() {
    return {
        type: START_UPDATE_ORG_FINANCE_DETAILS,
        payload: null
    };
}

function endUpdateOrgFinanceDetails(success, error) {
    return {
        type: END_UPDATE_ORG_FINANCE_DETAILS,
        payload: {
            success, error
        }
    };
}

export function updateOrgFinanceDetails(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/financial_details?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startUpdateOrgFinanceDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endUpdateOrgFinanceDetails(response, error));
        } catch (e) {
            dispatch(endUpdateOrgFinanceDetails(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateContractFolders() {
    return {
        type: START_UPDATE_CONTRACT_FOLDERS,
        payload: null
    };
}

function endUpdateContractFolders(success, error) {
    return {
        type: END_UPDATE_CONTRACT_FOLDERS,
        payload: {
            success, error
        }
    };
}

export function updateContractFolders(id, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/product/${id}/item/contract_details/folder?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startUpdateContractFolders(id));
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateContractFolders(response, error));
        } catch (e) {
            dispatch(endUpdateContractFolders(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgDocuments() {
    return {
        type: START_FETCH_ORG_DOCUMENTS,
        payload: null
    };
}

function endFetchOrgDocuments(success, error) {
    return {
        type: END_FETCH_ORG_DOCUMENTS,
        payload: {
            success, error
        }
    };
}

export function fetchOrgDocuments(id, isActive = null) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/documents?t=${timestamp}`
    if (isActive !== null) {
        url = url + '&isActive=' + isActive
    }
    return async (dispatch) => {
        dispatch(startFetchOrgDocuments(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgDocuments(response, error));
        } catch (e) {
            dispatch(endFetchOrgDocuments(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgDocument() {
    return {
        type: START_ADD_ORG_DOCUMENT,
        payload: null
    };
}

function endAddOrgDocument(success, error) {
    return {
        type: END_ADD_ORG_DOCUMENT,
        payload: {
            success, error
        }
    };
}

export function addOrgDocument(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/documents?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgDocument(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgDocument(response, error));
        } catch (e) {
            dispatch(endAddOrgDocument(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgDocument() {
    return {
        type: START_DELETE_ORG_DOCUMENT,
        payload: null
    };
}

function endDeleteOrgDocument(success, error) {
    return {
        type: END_DELETE_ORG_DOCUMENT,
        payload: {
            success, error
        }
    };
}

export function deleteOrgDocument(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/document?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteOrgDocument(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, true, params);
            dispatch(endDeleteOrgDocument(response, error));
        } catch (e) {
            dispatch(endDeleteOrgDocument(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgSecurityCert() {
    return {
        type: START_ADD_ORG_SECURITY_CERT,
        payload: null
    };
}

function endAddOrgSecurityCert(success, error) {
    return {
        type: END_ADD_ORG_SECURITY_CERT,
        payload: {
            success, error
        }
    };
}

export function addOrgSecurityCert(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/security_cert?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgSecurityCert(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgSecurityCert(response, error));
        } catch (e) {
            dispatch(endAddOrgSecurityCert(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOrgSecurityCert() {
    return {
        type: START_UPDATE_ORG_SECURITY_CERT,
        payload: null
    };
}

function endUpdateOrgSecurityCert(success, error) {
    return {
        type: END_UPDATE_ORG_SECURITY_CERT,
        payload: {
            success, error
        }
    };
}

export function updateOrgSecurityCert(id, certId, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/security_cert/${certId}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startUpdateOrgSecurityCert(id));
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, params);
            dispatch(endUpdateOrgSecurityCert(response, error));
        } catch (e) {
            dispatch(endUpdateOrgSecurityCert(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgSecurityCertDoc() {
    return {
        type: START_ADD_ORG_SECURITY_CERT_DOC,
        payload: null
    };
}

function endAddOrgSecurityCertDoc(success, error) {
    return {
        type: END_ADD_ORG_SECURITY_CERT_DOC,
        payload: {
            success, error
        }
    };
}

export function addOrgSecurityCertDoc(id, certId, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/security_cert/${certId}/docuement?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgSecurityCertDoc(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgSecurityCertDoc(response, error));
        } catch (e) {
            dispatch(endAddOrgSecurityCertDoc(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function deleteOrgSecurityCertDoc(id, certId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/security_cert/0/docuement/${certId}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgSecurityCertDoc(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endAddOrgSecurityCertDoc(response, error));
        } catch (e) {
            dispatch(endAddOrgSecurityCertDoc(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgSecurityCertDoc() {
    return {
        type: START_FETCH_ORG_SECURITY_CERT_DOC,
        payload: null
    };
}

function endFetchOrgSecurityCertDoc(success, error) {
    return {
        type: END_FETCH_ORG_SECURITY_CERT_DOC,
        payload: {
            success, error
        }
    };
}

export function fetchOrgSecurityCertDoc(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/security_cert_docuements?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgSecurityCertDoc(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgSecurityCertDoc(response, error));
        } catch (e) {
            dispatch(endFetchOrgSecurityCertDoc(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgSecurityCert() {
    return {
        type: START_FETCH_ORG_SECURITY_CERT,
        payload: null
    };
}

function endFetchOrgSecurityCert(success, error) {
    return {
        type: END_FETCH_ORG_SECURITY_CERT,
        payload: {
            success, error
        }
    };
}

export function fetchOrgSecurityCert(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/security_cert?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgSecurityCert(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgSecurityCert(response, error));
        } catch (e) {
            dispatch(endFetchOrgSecurityCert(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgSecurityCert() {
    return {
        type: START_DELETE_ORG_SECURITY_CERT,
        payload: null
    };
}

function endDeleteOrgSecurityCert(success, error) {
    return {
        type: END_DELETE_ORG_SECURITY_CERT,
        payload: {
            success, error
        }
    };
}

export function deleteOrgSecurityCert(id, security_cert_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/security_cert/${security_cert_id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteOrgSecurityCert(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgSecurityCert(response, error));
        } catch (e) {
            dispatch(endDeleteOrgSecurityCert(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgPii() {
    return {
        type: START_ADD_ORG_PII,
        payload: null
    };
}

function endAddOrgPii(success, error) {
    return {
        type: END_ADD_ORG_PII,
        payload: {
            success, error
        }
    };
}

export function addOrgPii(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/pii?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgPii(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgPii(response, error));
        } catch (e) {
            dispatch(endAddOrgPii(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgPii() {
    return {
        type: START_DELETE_ORG_PII,
        payload: null
    };
}

function endDeleteOrgPii(success, error) {
    return {
        type: END_DELETE_ORG_PII,
        payload: {
            success, error
        }
    };
}

export function deleteOrgPii(id, pii_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/pii/${pii_id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteOrgPii(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgPii(response, error));
        } catch (e) {
            dispatch(endDeleteOrgPii(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgPii() {
    return {
        type: START_FETCH_ORG_PII,
        payload: null
    };
}

function endFetchOrgPii(success, error) {
    return {
        type: END_FETCH_ORG_PII,
        payload: {
            success, error
        }
    };
}

export function fetchOrgPii(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/pii?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgPii(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgPii(response, error));
        } catch (e) {
            dispatch(endFetchOrgPii(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgRiskPosture() {
    return {
        type: START_FETCH_ORG_RISK_POSTURE,
        payload: null
    };
}

function endFetchOrgRiskPosture(success, error) {
    return {
        type: END_FETCH_ORG_RISK_POSTURE,
        payload: {
            success, error
        }
    };
}

export function fetchOrgRiskPosture(id, page, pageSize) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/risk_posture?t=${timestamp}&page=${page || 0}&pageSize=${pageSize || 100}`
    return async (dispatch) => {
        dispatch(startFetchOrgRiskPosture(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgRiskPosture(response, error));
        } catch (e) {
            dispatch(endFetchOrgRiskPosture(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


/**
 * Org Product Details page Completed & InPrgoress reviews
 */

function startOrgProductCompletedReviews(params) {
    return {
        type: START_ORG_PRODUCT_COMPLETED_REVIEWS,
        payload: {
            params
        }
    };
}

function endOrgProductCompletedReviews(params, success, error) {
    return {
        type: END_ORG_PRODUCT_COMPLETED_REVIEWS,
        payload: {
            params, success, error
        }
    };
}

export function fetchOrgProductCompletedReviews(params) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startOrgProductCompletedReviews(params));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
            dispatch(endOrgProductCompletedReviews(params, response, error));
        } catch (e) {
            dispatch(endOrgProductCompletedReviews(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgProductInprogressReviews(params) {
    return {
        type: START_ORG_PRODUCT_INPROGRESS_REVIEWS,
        payload: {
            params
        }
    };
}

function endOrgProductInprogressReviews(params, success, error) {
    return {
        type: END_ORG_PRODUCT_INPROGRESS_REVIEWS,
        payload: {
            params, success, error
        }
    };
}

export function fetchOrgProductInprogressReviews(params) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startOrgProductInprogressReviews(params));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
            dispatch(endOrgProductInprogressReviews(params, response, error));
        } catch (e) {
            dispatch(endOrgProductInprogressReviews(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchOrgFeatures() {
    return {
        type: START_FETCH_ORG_FEATURES,
        payload: null
    };
}

function endFetchOrgFeatures(success, error) {
    return {
        type: END_FETCH_ORG_FEATURES,
        payload: {
            success, error
        }
    };
}

export function fetchOrgFeatures(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/features?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgFeatures(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgFeatures(response, error));
        } catch (e) {
            dispatch(endFetchOrgFeatures(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgFeatures() {
    return {
        type: START_ADD_ORG_FEATURES,
        payload: null
    };
}

function endAddOrgFeatures(success, error) {
    return {
        type: END_ADD_ORG_FEATURES,
        payload: {
            success, error
        }
    };
}

export function addOrgFeatures(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/features?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgFeatures(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgFeatures(response, error));
        } catch (e) {
            dispatch(endAddOrgFeatures(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgFeatures() {
    return {
        type: START_DELETE_ORG_FEATURES,
        payload: null
    };
}

function endDeleteOrgFeatures(success, error) {
    return {
        type: END_DELETE_ORG_FEATURES,
        payload: {
            success, error
        }
    };
}

export function deleteOrgFeatures(id, feature_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/features/${feature_id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteOrgFeatures(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgFeatures(response, error));
        } catch (e) {
            dispatch(endDeleteOrgFeatures(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgSubCategories() {
    return {
        type: START_FETCH_ORG_SUBCATEGORIES,
        payload: null
    };
}

function endFetchOrgSubCategories(success, error) {
    return {
        type: END_FETCH_ORG_SUBCATEGORIES,
        payload: {
            success, error
        }
    };
}

export function fetchOrgSubCategories(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/subcategories?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgSubCategories(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgSubCategories(response, error));
        } catch (e) {
            dispatch(endFetchOrgSubCategories(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgSubCategories() {
    return {
        type: START_ADD_ORG_SUBCATEGORIES,
        payload: null
    };
}

function endAddOrgSubCategories(success, error) {
    return {
        type: END_ADD_ORG_SUBCATEGORIES,
        payload: {
            success, error
        }
    };
}

export function addOrgSubCategories(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/subcategories?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgSubCategories(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgSubCategories(response, error));
        } catch (e) {
            dispatch(endAddOrgSubCategories(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgSubCategories() {
    return {
        type: START_DELETE_ORG_SUBCATEGORIES,
        payload: null
    };
}

function endDeleteOrgSubCategories(success, error) {
    return {
        type: END_DELETE_ORG_SUBCATEGORIES,
        payload: {
            success, error
        }
    };
}

export function deleteOrgSubCategories(id, subcategory_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/subcategories/${subcategory_id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteOrgSubCategories(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgSubCategories(response, error));
        } catch (e) {
            dispatch(endDeleteOrgSubCategories(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchOrgPlatform() {
    return {
        type: START_FETCH_ORG_PLATFORM,
        payload: null
    };
}

function endFetchOrgPlatform(success, error) {
    return {
        type: END_FETCH_ORG_PLATFORM,
        payload: {
            success, error
        }
    };
}

export function fetchOrgPlatform(id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/platforms?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgPlatform(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgPlatform(response, error));
        } catch (e) {
            dispatch(endFetchOrgPlatform(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgPlatform() {
    return {
        type: START_ADD_ORG_PLATFORM,
        payload: null
    };
}

function endAddOrgPlatform(success, error) {
    return {
        type: END_ADD_ORG_PLATFORM,
        payload: {
            success, error
        }
    };
}

export function addOrgPlatform(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/platforms?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgPlatform(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgPlatform(response, error));
        } catch (e) {
            dispatch(endAddOrgPlatform(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgPlatform() {
    return {
        type: START_DELETE_ORG_PLATFORM,
        payload: null
    };
}

function endDeleteOrgPlatform(platform_id, success, error) {
    return {
        type: END_DELETE_ORG_PLATFORM,
        payload: {
            platform_id, success, error
        }
    };
}

export function deleteOrgPlatform(id, platformId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/platforms/${platformId}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteOrgPlatform(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgPlatform(platformId, response, error));
        } catch (e) {
            dispatch(endDeleteOrgPlatform(platformId, null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgRelatedProducts(type, page = 0) {
    return {
        type: START_FETCH_ORG_RELATED_PRODUCTS,
        payload: { type, page }
    };
}

function endFetchOrgRelatedProducts(type, success, error) {
    return {
        type: END_FETCH_ORG_RELATED_PRODUCTS,
        payload: {
            type,
            success, error
        }
    };
}

export function fetchOrgRelatedProducts(type, id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/fetch_related_products?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startFetchOrgRelatedProducts(type, params.page));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
            dispatch(endFetchOrgRelatedProducts(type, response, error));
        } catch (e) {
            dispatch(endFetchOrgRelatedProducts(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchExistingSimilarProducts() {
    return {
        type: START_FETCH_EXISTING_SIMILAR_PRODUCTS,
        payload: {}
    };
}

function endFetchExistingSimilarProducts(success, error) {
    return {
        type: END_FETCH_EXISTING_SIMILAR_PRODUCTS,
        payload: {
            success, error
        }
    };
}


export function fetchOrgExistingSimilarProducts(productId) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/recommendation/existing-similar-products/${productId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchExistingSimilarProducts());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchExistingSimilarProducts(response, error));
        } catch (e) {
            dispatch(endFetchExistingSimilarProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgRelatedProducts(type, id) {
    return {
        type: START_ADD_ORG_RELATED_PRODUCTS,
        payload: { type, id }
    };
}

function endAddOrgRelatedProducts(type, success, error) {
    return {
        type: END_ADD_ORG_RELATED_PRODUCTS,
        payload: {
            type, success, error
        }
    };
}

export function addOrgRelatedProducts(type, id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/related_products?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgRelatedProducts(type, id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgRelatedProducts(type, response, error));
        } catch (e) {
            dispatch(endAddOrgRelatedProducts(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgRelatedProducts(type, id) {
    return {
        type: START_DELETE_ORG_RELATED_PRODUCTS,
        payload: { type, id }
    };
}

function endDeleteOrgRelatedProducts(type, success, error) {
    return {
        type: END_DELETE_ORG_RELATED_PRODUCTS,
        payload: {
            type, success, error
        }
    };
}

export function deleteOrgRelatedProducts(type, id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/related_products?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteOrgRelatedProducts(type, id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, true, params);
            dispatch(endDeleteOrgRelatedProducts(type, response, error));
        } catch (e) {
            dispatch(endDeleteOrgRelatedProducts(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchSimilarExistingProducts() {
    return {
        type: START_FETCH_SIMILAR_EXISTING_PRODUCT,
        payload: {}
    };
}

function endFetchSimilarExistingProducts(success, error) {
    return {
        type: END_FETCH_SIMILAR_EXISTING_PRODUCT,
        payload: {
            success, error
        }
    };
}

export function fetchSimilarExistingProducts(products) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/similar_existing_products/${products.join(",")}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchSimilarExistingProducts());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchSimilarExistingProducts(response, error));


        } catch (e) {
            dispatch(endFetchSimilarExistingProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteProduct() {
    return {
        type: START_DELETE_PRODUCT,
        payload: {}
    };
}

function endDeleteProduct(success, error) {
    return {
        type: END_DELETE_PRODUCT,
        payload: {
            success, error
        }
    };
}

export function deleteProduct(productID) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${productID}/archive?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteProduct());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteProduct(response, error));
        } catch (e) {
            dispatch(endDeleteProduct(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function showAddOrgProductContractDetailsDialog(payload) {
    return {
        type: SHOW_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG,
        payload: payload
    };
}

export function hideAddOrgProductContractDetailsDialog() {
    return {
        type: HIDE_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG,
        payload: {}
    };
}

export function startFetchParseContractDetails() {
    return {
        type: START_FETCH_PARSE_CONTRACT_DETAILS
    };
}

export function endFetchParseContractDetails(success, error) {
    return {
        type: END_FETCH_PARSE_CONTRACT_DETAILS,
        payload: {
            success,
            error
        }
    };
}

export function fetchParseContractDetails(data) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/contract_details/parse?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchParseContractDetails());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endFetchParseContractDetails(response, error));
        } catch (e) {
            dispatch(endFetchParseContractDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function startAddContractDetails() {
    return {
        type: START_ADD_CONTRACT_DETAILS
    };
}

export function endAddContractDetails(success, error) {
    return {
        type: END_ADD_CONTRACT_DETAILS,
        payload: {
            success,
            error
        }
    };
}

export function addContractDetails(id, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${id}/financial/document?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddContractDetails());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddContractDetails(response, error));
        } catch (e) {
            dispatch(endAddContractDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function activeContractDetailsDocument(document) {
    return {
        type: ACTIVE_CONTRACT_DETAILS,
        payload: document
    };
}

export function startUpdateContractDetails() {
    return {
        type: START_UPDATE_CONTRACT_DETAILS
    };
}

export function endUpdateContractDetails(success, error) {
    return {
        type: END_UPDATE_CONTRACT_DETAILS,
        payload: {
            success,
            error
        }
    };
}

export function updateFinancialDetailsData(id, docId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${id}/${docId}/document/details?t=${timestamp}`;
    // const url = `${api_server}/org/products/${id}/financial/document/${docId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateContractDetails());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateContractDetails(response, error));
        } catch (e) {
            dispatch(endUpdateContractDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}





function startFetchAlternativeProducts() {
    return {
        type: START_FETCH_ALTERNATIVE_PRODUCTS,
        payload: {}
    };
}

function endFetchAlternativeProducts(success, error) {
    return {
        type: END_FETCH_ALTERNATIVE_PRODUCTS,
        payload: {
            success, error
        }
    };
}

export function fetchAlternativeProducts(productID) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/alternative-products/${productID}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAlternativeProducts());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAlternativeProducts(response, error));
        } catch (e) {
            dispatch(endFetchAlternativeProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgCategories() {
    return {
        type: START_ADD_ORG_CATEGORIES,
        payload: null
    };
}

function endAddOrgCategories(success, error) {
    return {
        type: END_ADD_ORG_CATEGORIES,
        payload: {
            success, error
        }
    };
}

export function addOrgCategories(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/categories?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgCategories());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgCategories(response, error));
        } catch (e) {
            dispatch(endAddOrgSubCategories(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddNewOrgCategories() {
    return {
        type: START_ADD_NEW_ORG_CATEGORIES,
        payload: null
    };
}

function endAddNewOrgCategories(success, error) {
    return {
        type: END_ADD_NEW_ORG_CATEGORIES,
        payload: {
            success, error
        }
    };
}

export function addNewOrgCategories(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/product/${id}/add_category?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddNewOrgCategories());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddNewOrgCategories(response, error));
        } catch (e) {
            dispatch(endAddNewOrgCategories(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgCategories() {
    return {
        type: START_DELETE_ORG_CATEGORIES,
        payload: null
    };
}

function endDeleteOrgCategories(category_id, success, error) {
    return {
        type: END_DELETE_ORG_CATEGORIES,
        payload: {
            success,
            error,
            category_id
        }
    };
}

export function deleteOrgCategories(id, category_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/categories/${category_id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteOrgCategories(id));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgCategories(category_id, response, error));
        } catch (e) {
            dispatch(endDeleteOrgCategories(category_id, null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startSearchOrgProducts() {
    return {
        type: START_SEARCH_ORG_PRODUCTS,
        payload: null
    };
}

function endSearchOrgProducts(success, error) {
    return {
        type: END_SEARCH_ORG_PRODUCTS,
        payload: {
            success,
            error,
        }
    };
}

export function searchOrgProducts(params) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/products?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startSearchOrgProducts());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endSearchOrgProducts(response, error));
        } catch (e) {
            dispatch(endSearchOrgProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchOrgProductsSimilarExisting() {
    return {
        type: START_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING,
        payload: null
    };
}

function endFetchOrgProductsSimilarExisting(productId, success, error) {
    return {
        type: END_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING,
        payload: {
            productId,
            success,
            error,
        }
    };
}

export function resetSimilarExistingProducts() {
    return {
        type: RESET_SIMILAR_EXISTING_PRODUCTS,
        payload: null
    };
}

export function fetchOrgProductsSimilarExisting(productId) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/recommendation/existing-similar-products/${productId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOrgProductsSimilarExisting());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgProductsSimilarExisting(productId, response, error));
        } catch (e) {
            dispatch(endFetchOrgProductsSimilarExisting(productId, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgProductDetailTabs() {
    return {
        type: START_FETCH_ORG_PRODUCT_DETAILS_TABS,
        payload: null
    };
}

function endFetchOrgProductDetailTabs(success, error) {
    return {
        type: END_FETCH_ORG_PRODUCT_DETAILS_TABS,
        payload: {
            success,
            error,
        }
    };
}

export function fetchOrgProductDetailsTabs() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOrgProductDetailTabs());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgProductDetailTabs(response, error));
        } catch (e) {
            dispatch(endFetchOrgProductDetailTabs(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgProductTabs() {
    return {
        type: START_FETCH_ORG_PRODUCT_TABS,
        payload: null
    };
}

function endFetchOrgProductTabs(success, error) {
    return {
        type: END_FETCH_ORG_PRODUCT_TABS,
        payload: {
            success,
            error,
        }
    };
}

export function fetchOrgProductTabs(productId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/tabs?productID=${productId}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOrgProductTabs());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgProductTabs(response, error));
        } catch (e) {
            dispatch(endFetchOrgProductTabs(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgProductDetailTab() {
    return {
        type: START_ADD_ORG_PRODUCT_DETAILS_TAB,
    }
}

function startAddOrgContactDetailLink() {
    return {
        type: START_ADD_ORG_CONTACT_DETAILS_LINK,
        payload: null
    };
}

function endAddOrgProductDetailTab(success, error) {
    return {
        type: END_ADD_ORG_PRODUCT_DETAILS_TAB,
        payload: {
            success,
            error,
        }
    }
}

function endAddOrgContactDetailLink(success, error) {
    return {
        type: END_ADD_ORG_CONTACT_DETAILS_LINK,
        payload: {
            success, error
        }
    };
}

export function addOrgContactDetailLink(id, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/contact_details_link?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAddOrgContactDetailLink(id));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddOrgContactDetailLink(response, error));
        } catch (e) {
            dispatch(endAddOrgContactDetailLink(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function addOrgProductDetailsTab(tabUid, data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgProductDetailTab());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgProductDetailTab(response, error));
        } catch (e) {
            dispatch(endAddOrgProductDetailTab(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOrgProductDetailTab() {
    return {
        type: START_UPDATE_ORG_PRODUCT_DETAILS_TAB,
        payload: null
    };
}

function endUpdateOrgProductDetailTab(tabUid, success, error) {
    return {
        type: END_UPDATE_ORG_PRODUCT_DETAILS_TAB,
        payload: {
            tabUid,
            success,
            error,
        }
    };
}

export function updateOrgProductDetailsTab(tabUid, data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgProductDetailTab());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgProductDetailTab(tabUid, response, error));
        } catch (e) {
            dispatch(endUpdateOrgProductDetailTab(tabUid, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function updateOrgProductDetailsTabSequence(tabUid, data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}/seq?t=${timestamp}`;
    return async (dispatch) => {
        try {
            await HttpFactory.instance().putMethod(url, data);
            dispatch(fetchOrgProductDetailsTabs());
        } catch (e) {
            return;
        }
    };
}

function startDeleteOrgProductDetailTab() {
    return {
        type: START_DELETE_ORG_PRODUCT_DETAILS_TAB,
        payload: null
    }
}

function startOrgDeleteContactDetailLink() {
    return {
        type: START_DELETE_ORG_CONTACT_DETAIL_LINK,
        payload: null
    };
}

function endDeleteOrgProductDetailTab(tabUid, success, error) {
    return {
        type: END_DELETE_ORG_PRODUCT_DETAILS_TAB,
        payload: {
            tabUid,
            success,
            error,
        }
    }
}

function endOrgDeleteContactDetailLink(success, error, link_id) {
    return {
        type: END_DELETE_ORG_CONTACT_DETAIL_LINK,
        payload: {
            success, error, link_id
        }
    };
}

export function deleteOrgProductDetailsTab(tabUid) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgProductDetailTab());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgProductDetailTab(tabUid, response, error));
        } catch (e) {
            dispatch(endDeleteOrgProductDetailTab(tabUid, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgProductDetailTabSection() {
    return {
        type: START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION,
        payload: null
    };
}

function endAddOrgProductDetailTabSection(tabUid, success, error) {
    return {
        type: END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION,
        payload: {
            tabUid,
            success,
            error,
        }
    };
}

export function addOrgProductDetailsTabSection(tabUid, sectionUid, data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}/sections/${sectionUid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgProductDetailTabSection());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgProductDetailTabSection(tabUid, response, error));
        } catch (e) {
            dispatch(endAddOrgProductDetailTabSection(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgProductDetailTabSection() {
    return {
        type: START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION,
        payload: null
    };
}

function endUpdateOrgProductDetailTabSection(tabUid, sectionUid, success, error) {
    return {
        type: END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION,
        payload: {
            tabUid,
            sectionUid,
            success,
            error,
        }
    };
}

export function updateOrgProductDetailsTabSection(tabUid, sectionUid, data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}/sections/${sectionUid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgProductDetailTabSection());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgProductDetailTabSection(tabUid, sectionUid, response, error));
        } catch (e) {
            dispatch(endUpdateOrgProductDetailTabSection(tabUid, sectionUid, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function updateOrgProductDetailsTabSectionSequence(tabUid, sectionUid, data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}/sections/${sectionUid}/seq?t=${timestamp}`;
    return async (dispatch) => {
        try {
            await HttpFactory.instance().putMethod(url, data);
            dispatch(fetchOrgProductDetailsTabs());
        } catch (e) {
            return;
        }
    };
}

function startDeleteOrgProductDetailTabSection() {
    return {
        type: START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION,
        payload: null
    };
}

function endDeleteOrgProductDetailTabSection(tabUid, sectionUid, success, error) {
    return {
        type: END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION,
        payload: {
            tabUid,
            sectionUid,
            success,
            error,
        }
    };
}

export function deleteOrgProductDetailsTabSection(tabUid, sectionUid) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}/sections/${sectionUid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgProductDetailTabSection());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgProductDetailTabSection(tabUid, sectionUid, response, error));
        } catch (e) {
            dispatch(endDeleteOrgProductDetailTabSection(tabUid, sectionUid, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgProductDetailTabSectionItem() {
    return {
        type: START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
        payload: null
    };
}

function endAddOrgProductDetailTabSectionItem(tabUid, sectionUid, success, error) {
    return {
        type: END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
        payload: {
            tabUid,
            sectionUid,
            success,
            error,
        }
    };
}

export function addOrgProductDetailsTabSectionItem(tabUid, sectionUid, itemUid, data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}/sections/${sectionUid}/items/${itemUid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgProductDetailTabSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgProductDetailTabSectionItem(tabUid, sectionUid, response, error));
        } catch (e) {
            dispatch(endAddOrgProductDetailTabSectionItem(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgProductDetailTabSectionItem() {
    return {
        type: START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
        payload: null
    };
}

function endUpdateOrgProductDetailTabSectionItem(tabUid, sectionUid, itemUid, success, error) {
    return {
        type: END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
        payload: {
            tabUid,
            sectionUid,
            itemUid,
            success,
            error,
        }
    };
}

export function updateOrgProductDetailsTabSectionItem(tabUid, sectionUid, itemUid, data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}/sections/${sectionUid}/items/${itemUid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgProductDetailTabSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgProductDetailTabSectionItem(tabUid, sectionUid, itemUid, response, error));
        } catch (e) {
            dispatch(endUpdateOrgProductDetailTabSectionItem(tabUid, sectionUid, itemUid, sectionUid, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgProdutDetailsTabSectionItemData(itemUID) {
    return {
        type: START_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA,
        payload: {
            itemUID
        },
    };
}

function endFetchOrgProdutDetailsTabSectionItemData(itemsUIDs, success, userType, tabUid, error) {
    return {
        type: END_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA,
        payload: {
            itemsUIDs,
            success,
            error,
            userType,
            tabUid
        }
    };
}

export function fetchTabsSectionsData(product_id, tabUid, itemsUIDs) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${product_id}/tab/${tabUid}?t=${timestamp}`;
    return async (dispatch, getState) => {
        dispatch(startFetchOrgProdutDetailsTabSectionItemData());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            let obj = getState();
            obj = obj?.authUser?.user?.Role;
            dispatch(endFetchOrgProdutDetailsTabSectionItemData(itemsUIDs, response, obj, tabUid, error));
        } catch (e) {
            dispatch(endFetchOrgProdutDetailsTabSectionItemData(itemsUIDs, null, null, tabUid, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOrgProdutDetailsTabSectionItemData(itemUID) {
    return {
        type: START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA,
        payload: {
            itemUID
        }
    };
}

function endUpdateOrgProdutDetailsTabSectionItemData(itemUID, success, error) {
    return {
        type: END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA,
        payload: {
            itemUID,
            success,
            error,
        }
    };
}

export function updateOrgProdutDetailsTabSectionItemData(productID, itemUID, data) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/org/products/${productID}/item/${itemUID}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgProdutDetailsTabSectionItemData(itemUID));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endUpdateOrgProdutDetailsTabSectionItemData(itemUID, response, error));
        } catch (e) {
            dispatch(endUpdateOrgProdutDetailsTabSectionItemData(itemUID, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function deleteOrgContactDetailLink(product_id, link_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${product_id}/contact_details_link/${link_id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startOrgDeleteContactDetailLink());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endOrgDeleteContactDetailLink(response, error, link_id));
        } catch (e) {
            dispatch(endOrgDeleteContactDetailLink(null, null, link_id));
            return;
        }
    };
}


function startDeleteOrgProductDetailTabSectionItem() {
    return {
        type: START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
        payload: null
    };
}

function endDeleteOrgProductDetailTabSectionItem(tabUid, sectionUid, itemUid, success, error) {
    return {
        type: END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
        payload: {
            tabUid,
            sectionUid,
            itemUid,
            success,
            error,
        }
    };
}

export function deleteOrgProductDetailsTabSectionItem(tabUid, sectionUid, itemUid) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/tabs/${tabUid}/sections/${sectionUid}/items/${itemUid}?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startDeleteOrgProductDetailTabSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgProductDetailTabSectionItem(tabUid, sectionUid, itemUid, response, error));
        } catch (e) {
            dispatch(endDeleteOrgProductDetailTabSectionItem(tabUid, sectionUid, itemUid, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startfetchProductPrice() {
    return {
        type: START_FETCH_PRODUCT_PRICE,
        payload: null
    };
}

function endfetchProductPrice(success, error) {
    return {
        type: END_FETCH_PRODUCT_PRICE,
        payload: {
            success,
            error,
        }
    };
}

export function fetchProductPrice(productId) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/v2/tables/get_price/${productId}?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startfetchProductPrice());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endfetchProductPrice(response, error));
        } catch (e) {
            dispatch(endfetchProductPrice(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showAddProductTeamsDialog(id, type, data) {
    return {
        type: SHOW_ADD_PRODUCT_TEAMS_DIALOG,
        payload: { id, type, data }
    };
}

export function hideAddProductTeamsDialog() {
    return {
        type: HIDE_ADD_PRODUCT_TEAMS_DIALOG,
        payload: {}
    };
}

function startFetchProductTabTeams() {
    return {
        type: START_FETCH_PRODUCT_TAB_TEAMS,
        payload: null
    };
}

function endFetchProductTabTeams(success, error) {
    return {
        type: END_FETCH_PRODUCT_TAB_TEAMS,
        payload: {
            success,
            error,
        }
    };
}

export function FetchProductTabTeams(tabId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/tab/${tabId}/teams?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductTabTeams());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProductTabTeams(response, error));
        } catch (e) {
            dispatch(endFetchProductTabTeams(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddProductTabTeams() {
    return {
        type: START_ADD_PRODUCT_TAB_TEAMS,
        payload: null
    };
}

function endAddProductTabTeams(success, error) {
    return {
        type: END_ADD_PRODUCT_TAB_TEAMS,
        payload: {
            success,
            error,
        }
    };
}

export function addProductTabTeams(data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/tab/teams?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddProductTabTeams());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddProductTabTeams(response, error));
        } catch (e) {
            dispatch(endAddProductTabTeams(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteProductTabTeams() {
    return {
        type: START_DELETE_PRODUCT_TAB_TEAMS,
        payload: null
    };
}

function endDeleteProductTabTeams(success, error) {
    return {
        type: END_DELETE_PRODUCT_TAB_TEAMS,
        payload: {
            success,
            error,
        }
    };
}

export function deleteProductTabTeams(tabid, teamid) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/tab/${tabid}/team/${teamid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteProductTabTeams());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteProductTabTeams(response, error));
        } catch (e) {
            dispatch(endDeleteProductTabTeams(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchProductSectionTeams() {
    return {
        type: START_FETCH_PRODUCT_SECTION_TEAMS,
        payload: null
    };
}

function endFetchProductSectionTeams(success, error) {
    return {
        type: END_FETCH_PRODUCT_SECTION_TEAMS,
        payload: {
            success,
            error,
        }
    };
}

export function FetchProductSectionTeams(sectionId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/section/${sectionId}/teams?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startFetchProductSectionTeams());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProductSectionTeams(response, error));
        } catch (e) {
            dispatch(endFetchProductSectionTeams(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddProductSectionTeams() {
    return {
        type: START_ADD_PRODUCT_SECTION_TEAMS,
        payload: null
    };
}

function endAddProductSectionTeams(success, error) {
    return {
        type: END_ADD_PRODUCT_SECTION_TEAMS,
        payload: {
            success,
            error,
        }
    };
}

export function addProductSectionTeams(data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/section/teams?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddProductSectionTeams());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddProductSectionTeams(response, error));
        } catch (e) {
            dispatch(endAddProductSectionTeams(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteProductSectionTeams() {
    return {
        type: START_DELETE_PRODUCT_SECTION_TEAMS,
        payload: null
    };
}

function endDeleteProductSectionTeams(teamid, success, error) {
    return {
        type: END_DELETE_PRODUCT_SECTION_TEAMS,
        payload: {
            teamid,
            success,
            error,
        }
    };
}

export function deleteProductSectionTeams(sectionId, teamid) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/section/${sectionId}/team/${teamid}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteProductSectionTeams());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteProductSectionTeams(teamid, response, error));
        } catch (e) {
            dispatch(endDeleteProductSectionTeams(teamid, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchProductTabItems() {
    return {
        type: START_FETCH_ORG_PRODUCT_TAB_ITEMS,
        payload: {}
    };
}

function endFetchProductTabItems(success, error) {
    return {
        type: END_FETCH_ORG_PRODUCT_TAB_ITEMS,
        payload: {
            success, error
        }
    };
}

export function fetchProductTabItems() {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/product/tab/items?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductTabItems());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProductTabItems(response, error));
        } catch (e) {
            dispatch(endFetchProductTabItems(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function startAddNewContractDetails() {
    return {
        type: START_ADD_NEW_CONTRACT_DETAILS
    };
}

export function endAddNewContractDetails(success, error) {
    return {
        type: END_ADD_NEW_CONTRACT_DETAILS,
        payload: {
            success,
            error
        }
    };
}

export function addNewContractDetails(id, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${id}/financial/document?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewContractDetails());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewContractDetails(response, error));
        } catch (e) {
            dispatch(endAddNewContractDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgDocument() {
    return {
        type: START_UPDATE_ORG_DOCUMENT,
        payload: null
    };
}

function endUpdateOrgDocument(success, error) {
    return {
        type: END_UPDATE_ORG_DOCUMENT,
        payload: {
            success, error
        }
    };
}

export function updateOrgDocument(id, docId, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/document/${docId}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startUpdateOrgDocument(id));
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, params);
            dispatch(endUpdateOrgDocument(response, error));
        } catch (e) {
            dispatch(endUpdateOrgDocument(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startGetOrgDocumentDetails() {
    return {
        type: START_GET_ORG_DOCUMENT_DETAILS,
        payload: null
    };
}

function endGetOrgDocumentDetails(success, error) {
    return {
        type: END_GET_ORG_DOCUMENT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function getOrgDocumentDetails(id, docId, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/${docId}/document/details?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startGetOrgDocumentDetails(id));
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url, params);
            dispatch(endGetOrgDocumentDetails(response, error));
        } catch (e) {
            dispatch(endGetOrgDocumentDetails(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAttachFileToDocument() {
    return {
        type: START_UPDATE_DOCUMENT_FILE,
        payload: null
    };
}

function endAttachFileToDocument(success, error) {
    return {
        type: END_UPDATE_DOCUMENT_FILE,
        payload: {
            success, error
        }
    };
}

export function attachFileToDocument(id, docId, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/org/products/${id}/document/${docId}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startAttachFileToDocument(id));
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, params);
            dispatch(endAttachFileToDocument(response, error));
        } catch (e) {
            dispatch(endAttachFileToDocument(null, null, {
                message: e.message
            }));
            return;
        }
    };
}
