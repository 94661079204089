import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import deleteIcon from "assets/images/delete.svg";
// import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {
  FormControl, MenuItem, TableRow, TableCell, InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import moment from "moment";
import CalendarImage from "assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import editIcon from "assets/images/edit_icon.svg";
// import addUsers from "assets/images/add_profile.svg";
import DoneIcon from "@material-ui/icons/Done";
import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import { Select as MaterialSelect } from "@material-ui/core";
import DocumentFileDetails from "./DocumentFileDetails";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import {
  useTree,
  CellTree,
} from '@table-library/react-table-library/tree';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { currencyOptions, currencyCodeMap } from "util/Currency";
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import FileAncher from 'Components/Common/FileAncher';
const SelectAsyncPaginate = withAsyncPaginate(Select);

const connectedProps = (state) => ({
  fetchContractFoldersProgress: state.vendors.fetchContractFoldersProgress,
  contractFoldersData: state.vendors.contractFoldersData,
  fetchContractFoldersError: state.vendors.fetchContractFoldersError,
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({

});

var currencyStyles = {
  control: (styles) => ({ ...styles, height: 36, maxHeight: 36 }),
  menuPortal: (base) => ({ ...base, zIndex: 999 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
};

const THEME = {
  Table: `
    width: 100%;
    display: table;
    position: unset;
  `,
  Header: `
    display: table-header-group;
  `,
  Body: `
    display: table-row-group;
  `,
  Row: `
    display: table-row;
  `,
  BaseRow: `
    font-size: 14px;
  `,
  HeaderRow: `
    display: table-row;
    font-size: 14px;
    background-color: #f1f1f1;
  `,
  HeaderCell: `
    font-weight: 500;
    display: table-cell;
  `,
  BaseCell: `
    display: table-cell;
    padding: 16px 8px;
    &:nth-of-type(1) {
      padding: 16px 16px;
    }
  `
};

let FOUND = false;

const FolderBasedTable = (props) => {
  const [activeRowId, setActiveRowId] = useState(null);
  const [folderCollection, setFolderCollection] = useState([]);
  const materialTheme = getTheme(DEFAULT_OPTIONS);
  const theme = useTheme([materialTheme, THEME]);


  useEffect(() => {
    let folders = []
    try {
      folders = JSON.parse(JSON.stringify(props.contractFoldersData?.meta || []));
    } catch (e) {
    }
    const documents = [...props.orgDocuments || []];
    documents.forEach((document, index) => {
      FOUND = false;
      initializeFolders(folders, document, index);
      if (!FOUND) {
        folders = [...folders, {
          documentIndex: index,
        }]
      }
    })
    setFolderCollection(folders)
  }, [props.orgDocuments, props.contractFoldersData?.meta?.length]);

  const initializeFolders = (folders, document, index) => {
    folders.forEach((item, i) => {
      let existingIndex = item.nodes?.findIndex(O => O.id !== document.ID);
      if (existingIndex && existingIndex > -1) {
        FOUND = true;
      } else if (document.folder_id === item.id) {
        item.nodes = [
          ...item?.nodes,
          {
            documentIndex: index,
          }
        ]
        FOUND = true;
      }
      if (!FOUND && item.nodes && item.nodes.length > 0) {
        initializeFolders(item.nodes, document, index);
      }
    });
  };

  const onTreeChange = (action, state) => {
    console.log(action, state);
  }

  const data = { nodes: folderCollection };

  const tree = useTree(
    data,
    {
      onChange: onTreeChange,
    },
    {
      treeIcon: {
        margin: '4px',
        size: '20px',
        iconDefault: <InsertDriveFileOutlinedIcon fontSize="small" />,
        iconRight: <FolderIcon fontSize="small" />,
        iconDown: <FolderOpenIcon fontSize="small" />,
      },
    },
  );

  const deleteFolder = (id) => {
    let folders = props.contractFoldersData?.meta || []
    updateFoldersData(folders, id);
    props.deleteFolder({ meta: folders })
  }

  const updateFoldersData = (folders, id) => {
    folders.forEach((item, i) => {
      if (id === item.id) {
        folders.splice(i, 1);
      } else {
        updateFoldersData(item.nodes || [], id);
      }
    });
  }

  return (
    <Table data={data} tree={tree} theme={theme} layout={{ custom: true }}>
      {(tableList) => (
        <>
          <Header>
            <HeaderRow>
              <HeaderCell style={{ minWidth: "200px" }} resize>Resource</HeaderCell>
              <HeaderCell style={{ minWidth: "180px" }}>Type</HeaderCell>
              <HeaderCell style={{ minWidth: "180px" }}>Owner</HeaderCell>
              {!activeRowId && <>
                {/* <HeaderCell style={{minWidth: "260px"}}>Contract Value</HeaderCell> */}
                <HeaderCell style={{ minWidth: "200px" }}>Start Date</HeaderCell>
                <HeaderCell style={{ minWidth: "200px" }}>End Date</HeaderCell>
                <HeaderCell style={{ minWidth: "200px" }}>Renewal Date</HeaderCell>
                <HeaderCell style={{ minWidth: "100px" }}>Status</HeaderCell>
              </>}
              <HeaderCell style={{ minWidth: "130px" }}>Created At</HeaderCell>
              {props.hasUpdateAccess && <HeaderCell></HeaderCell>}
            </HeaderRow>
          </Header>

          <Body>
            {tableList.map((row, index) => {
              let item = row;
              if (item.documentIndex !== undefined && item.documentIndex !== null) {
                item = { ...item, ...props.orgDocuments[item.documentIndex] }
              }
              // console.log(item, 'Itmmmmmmmmmmmmmmmmmmmmmmmmmmmmm')
              let fileName=item.file?.name
              let fileURL=item.file?.url

              return (<>
                <Row key={index} item={item} style={{ cursor: "pointer", background: activeRowId === item.id ? "#dbd8d8" : "" }}>
                  {(item.documentIndex !== undefined && item.documentIndex !== null) ? (<>
                    <CellTree item={item}>
                      <div>
                        <FileAncher
                          fileName={fileName}
                          fileURL={fileURL}
                        />
                      </div>
                    </CellTree>
                    <Cell>
                      <div>
                        {item.Type !== '' && item.Type !== null ? item.Type?.label : ''} {item.type?.type ? `(${item.type?.type})` : ''}
                      </div>

                      {/* <div>
                          <FormControl className={props.classes?.formSelect} style={{ zIndex: 11 }}>
                            <Select
                              isClearable
                              isMulti={false}
                              labelId="singleSelect-select-label"
                              id="singleSelect-select"
                              isDisabled={false}
                              value={item.Type}
                              onChange={(e) => {
                                props.updateLocalValue(e, 'Type', item.documentIndex)
                              }}
                              fullWidth
                              options={props.typeOptions}
                              menuPortalTarget={document.body}
                              // menuPosition={'fixed'}
                              menuPlacement={'auto'}
                              placeholder={"Select Option"}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                            />
                          </FormControl>
                        </div> */}
                    </Cell>
                    <Cell>
                      {!item.isEdit && <span>
                        {item.User !== null && item.User !== '' ? item.User?.label : ""}
                      </span>}

                      {item.isEdit && <div>
                        <FormControl className={props.classes?.formSelect} style={{ zIndex: 11 }}>
                          {/* {false && <Select
                              isClearable
                              isMulti={false}
                              labelId="singleSelect-select-label"
                              id="singleSelect-select"
                              isDisabled={false}
                              value={item.User}
                              onChange={(e) => {
                                props.updateLocalValue(e, 'User', item.documentIndex)
                              }}
                              fullWidth
                              options={props.documentUserOptions}
                              menuPortalTarget={document.body}
                              // menuPosition={'fixed'}
                              menuPlacement={'auto'}
                              placeholder={"Select Option"}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                            />} */}

                          <SelectAsyncPaginate
                            isClearable={true}
                            isMulti={false}
                            isSearchable={true}
                            value={item.User}
                            menuPosition='fixed'
                            classNamePrefix="select"
                            isLoading={props.userSearchProgress}
                            placeholder="Search User"
                            additional={{
                              page: 0,
                            }}
                            loadOptions={props.getOwnerOptions}
                            onChange={(e) => {
                              props.updateLocalValue(e, 'User', item.documentIndex)
                            }}
                          />
                        </FormControl>
                      </div>}
                    </Cell>
                    {!activeRowId && <>
                      {/* <Cell>
                          {item.isEdit && (
                            <div className={props.classes?.currencyTypeFields} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                              <div style={{ width: 100, marginRight: 5, zIndex: 11 }}>
                                <Select
                                  isMulti={false}
                                  labelId="singleSelect-select-label"
                                  id="singleSelect-select"
                                  value={item.TotalCost?.type || {
                                    label: "USD",
                                    value: "USD",
                                  }}
                                  onChange={(e) => {
                                    let newValue = {
                                      type: e,
                                      value: item.TotalCost?.value,
                                    };
                                    props.updateLocalValue(newValue, "TotalCost", item.documentIndex);
                                  }}
                                  options={currencyOptions}
                                  placeholder={"Select a Option"}
                                  styles={currencyStyles}
                                ></Select>
                              </div>

                              <CurrencyTextField
                                variant="outlined"
                                value={item.TotalCost?.value}
                                currencySymbol={currencyCodeMap[item.TotalCost?.type?.value]}
                                style={{ textAlign: "left", flex: 1, height: 37, maxWidth: 150 }}
                                outputFormat="string"
                                decimalCharacter="."
                                digitGroupSeparator=","
                                required={false}
                                onChange={(e, v) => {
                                  let newValue = {
                                    type: item.TotalCost?.type,
                                    value: v,
                                  };
                                  props.updateLocalValue(newValue, "TotalCost", item.documentIndex);
                                }}
                              />
                            </div>
                          )}
                          {!item.isEdit && (
                            <div>
                              {item.TotalCost && item.TotalCost.value && item.TotalCost.value !== "" && <p className={props.classes?.data}>{currencyCodeMap[item.TotalCost.type.value] + "" + item.TotalCost?.value}</p>}
                              {item.TotalCost && item.TotalCost.value === "" && <p className={props.classes?.data}>-</p>}
                            </div>
                          )}
                        </Cell> */}
                      <Cell>
                        {item.isEdit && (
                          <div className={props.classes?.datePicker}>
                            <DatePicker
                              readOnly={false}
                              selected={item.StartDate !== null ? new Date(item.StartDate) : ""}
                              onChange={(date) => props.updateLocalValue(date, "StartDate", item.documentIndex)}
                              dateFormat="MMM do yyyy"
                              customInput={
                                <OutlinedInput
                                  value={item.StartDate}
                                  readOnlyInput={true}
                                  placeholder="Select Date"
                                  size="large"
                                  style={{ height: '37px', minHeight: '37px' }}
                                  labelStyle={"inline"}
                                  startAdornment={
                                    <InputAdornment>
                                      <Image src={CalendarImage} className={props.classes?.inputCalendar} alt="input calendar" />
                                    </InputAdornment>
                                  }
                                />
                              }
                            />
                          </div>
                        )}
                        {!item.isEdit && (
                          <div>
                            <p className={props.classes?.data}>{item.StartDate ? moment(item.StartDate).format("DD MMM YYYY") : ""}</p>
                          </div>
                        )}
                      </Cell>
                      <Cell>
                        {item.isEdit && (
                          <div className={props.classes?.datePicker}>
                            <DatePicker
                              readOnly={false}
                              selected={item.EndDate !== null ? new Date(item.EndDate) : ""}
                              onChange={(date) => props.updateLocalValue(date, "EndDate", item.documentIndex)}
                              dateFormat="MMM do yyyy"
                              customInput={
                                <OutlinedInput
                                  value={item.EndDate}
                                  readOnlyInput={true}
                                  placeholder="Select Date"
                                  labelStyle={"inline"}
                                  style={{ height: '37px', minHeight: '37px' }}
                                  startAdornment={
                                    <InputAdornment>
                                      <Image src={CalendarImage} className={props.classes?.inputCalendar} alt="input calendar" />
                                    </InputAdornment>
                                  }
                                />
                              }
                            />
                          </div>
                        )}
                        {!item.isEdit && (
                          <div>
                            <p className={props.classes?.data}>{item.EndDate ? moment(item.EndDate).format("DD MMM YYYY") : ""}</p>
                          </div>
                        )}
                      </Cell>
                      <Cell>
                        {item.isEdit && (
                          <div className={props.classes?.datePicker}>
                            <DatePicker
                              readOnly={false}
                              selected={item.RenewalDate !== null ? new Date(item.RenewalDate) : ""}
                              onChange={(date) => props.updateLocalValue(date, "RenewalDate", item.documentIndex)}
                              dateFormat="MMM do yyyy"
                              customInput={
                                <OutlinedInput
                                  value={item.RenewalDate}
                                  readOnlyInput={true}
                                  placeholder="Select Date"
                                  labelStyle={"inline"}
                                  style={{ height: '37px', minHeight: '37px' }}
                                  startAdornment={
                                    <InputAdornment>
                                      <Image src={CalendarImage} className={props.classes?.inputCalendar} alt="input calendar" />
                                    </InputAdornment>
                                  }
                                />
                              }
                            />
                          </div>
                        )}
                        {!item.isEdit && (
                          <div>
                            <p className={props.classes?.data}>{item.RenewalDate ? moment(item.RenewalDate).format("DD MMM YYYY") : ""}</p>
                          </div>
                        )}
                      </Cell>
                      <Cell>
                        {!item.isEdit && <div>
                          {item.Status}
                        </div>}
                        {item.isEdit && <div>
                          <FormControl variant="outlined" className={props.classes?.formControlSel} size="small">
                            <MaterialSelect value={item.Status} onChange={(e) => {
                              props.updateLocalValue(e.target.value, 'Status', item.documentIndex)
                            }}>
                              {props.documentStatusOptions.map((op) => {
                                return <MenuItem value={op.value} style={{ fontSize: 13 }}>{op.label}</MenuItem>
                              })}
                            </MaterialSelect>
                          </FormControl>
                        </div>}
                      </Cell>
                    </>}

                    <Cell>
                      {moment(item?.CreatedAt).format("DD-MMM-YYYY")}
                    </Cell>
                    <Cell>
                      {props.hasUpdateAccess && <div style={{ display: 'flex' }}>
                        {!item.isEdit && <IconButton onClick={() => props.handleEdit(item.documentIndex)}>
                          <Image src={editIcon} />
                        </IconButton>}

                        {item.isEdit && <IconButton onClick={() => props.handleUpdate(item.documentIndex)}>
                          <DoneIcon />
                        </IconButton>}

                        {!item.isEdit && <IconButton onClick={(e) => {
                          e.stopPropagation();
                          props.deleteContracts(item.id);
                        }}>
                          <Image src={deleteIcon} />
                        </IconButton>}

                        <IconButton onClick={(e) => {
                          e.stopPropagation();
                          props.addFileToFolder(item);
                        }}>
                          <CreateNewFolderOutlinedIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                          setActiveRowId(activeRowId === item.id ? null : item.id)
                        }}>
                          {activeRowId === item.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </div>}
                    </Cell>
                  </>) : (
                    <>
                      <CellTree item={item}><div style={{ marging: 0, padding: "0px 10px" }}>{item.label}</div></CellTree>
                      <Cell></Cell>
                      {!activeRowId && <>
                        <Cell></Cell>
                        <Cell></Cell>
                        <Cell></Cell>
                        <Cell></Cell>
                      </>}
                      <Cell></Cell>
                      <Cell>
                        {moment(item?.CreatedAt).format("DD-MMM-YYYY")}
                      </Cell>
                      <Cell>
                        {props.hasUpdateAccess && <div style={{ display: 'flex' }}>
                          <IconButton onClick={(e) => {
                            e.stopPropagation();
                            props.updateFolder(item);
                          }}>
                            <Image src={editIcon} />
                          </IconButton>
                          <IconButton onClick={(e) => {
                            e.stopPropagation();
                            deleteFolder(item.id);
                          }}>
                            <Image src={deleteIcon} />
                          </IconButton>
                        </div>}
                      </Cell>
                    </>
                  )}

                </Row>
                {activeRowId === item.id && <TableRow>
                  <TableCell padding="checkbox" colSpan="7">
                    <div>
                      <DocumentFileDetails
                        document={item}
                        hasUpdateAccess={props.hasUpdateAccess}
                        onUpdateData={(params) => {
                          props.updateDocTypeFile(item.id, params)
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>}
              </>)
            })}
            {tableList.length <= 0 && <TableRow><TableCell colSpan={7} style={{ textAlign: 'center' }}> No Document</TableCell></TableRow>}
          </Body>
        </>
      )}
    </Table>

  )
}

export default connector(
  compose(withRouter, withStyles(styles))(FolderBasedTable)
);
