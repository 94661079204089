import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
// import SearchUserPoper from "./SearchUserPoper";
import addUsers from "../../../assets/images/add_profile.svg";
import Image from 'Components/Common/image.jsx'
import { showSnackBar } from "redux/snackbar/action";
import classnames from "classnames";
import Tooltip from '@material-ui/core/Tooltip';

// redux
import { showEvaluationCollaboratorDialog, hideEvaluationCollaboratorDialog } from "redux/evaluate/action";

const connectedProps = (state) => ({
    open: state.evaluate.evaluationCollaboratorDialogOpen
});

const connectionActions = {
    showSnackBar: showSnackBar,
    showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
    hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        display: "flex",
        "& [class*=MuiAvatar-root]": {
            width: 30,
            height: 30,
            fontSize: 14
        },
        "& [class*=MuiAvatarGroup-avatar]": {
            margin: 0
        }
    },
    'addUserIcon': {
        marginRight: theme.spacing(2)
    },
    addUser: {
        width: 23,
        cursor: "pointer",
        marginTop: 1,
        marginLeft: 1
    },
});

class UserGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popoverAnchor: null,
        }
        this.handleClose = this.handleClose.bind(this);
        this.avatarName = this.avatarName.bind(this);
        this.updatePriority = this.updatePriority.bind(this);
    }

    componentDidMount() {
    }

    avatarName(name) {
        // console.log(name);
        return name.substring(0, 1);
    }

    handleClose(user, criteriaId, flag) {
        if (user !== null && this.props.onAdd !== undefined && this.props.onAdd !== null) {
            this.props.onAdd(user, flag);
            //this.props.showSnackBar("User added","success",3000);
        } else {
            this.setState({ popoverAnchor: null });
        }
    }

    updatePriority(user) {
        this.props.updatePriority(user)
    }

    render() {
        const classes = this.props.classes;
        var editable = true;
        if (this.props.editable !== undefined) {
            editable = this.props.editable
        }
        return <div className={classes.root}>
            {this.props.users !== undefined && this.props.users !== null && <AvatarGroup max={3}>
                {
                    this.props.users.map((user, k) => {
                        let tooltipMsg = '';
                        if (this.props.position === 'CriteriaHeadSetup') {
                            let value = user?.TemplateCriteriaPriority !== undefined && user?.TemplateCriteriaPriority !== null && user?.TemplateCriteriaPriority !== '' && user?.TemplateCriteriaPriority > 0 ? user?.TemplateCriteriaPriority : 50;
                            tooltipMsg = user.Name + "\n" + user.Email + "\n Weightage: " + value + '%';
                        } else {
                            tooltipMsg = user.Name + "\n" + user.Email
                        }
                        return <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{tooltipMsg}</span>} arrow>
                            <Avatar key={k}> {this.avatarName(user.Name)}</Avatar></Tooltip>
                    })
                }

            </AvatarGroup>}
            {editable && <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Add your collaborators here</span>} arrow>
                <Image className={classnames(classes.addUser, 'evaluate-add-user')}
                    alt="add user"
                    src={addUsers}
                    onClick={(event) => {
                        this.props.showEvaluationCollaboratorDialog({
                            isfromSettingsTemplate: this.props.isfromSettingsTemplate !== undefined ? this.props.isfromSettingsTemplate : false,
                            isNew: this.props.isNew !== undefined ? this.props.isNew : false,
                            // criterias: this.props.criterias !== undefined ? this.props.criterias : [],
                            evaluationId: this.props.evaluationId,
                            criteriaId: this.props.criteriaId,
                            updateNewCollaborator: this.props.updateNewCollaborator,
                            removeCollaborator: this.props.removeCollaborator,
                            existingUsers: this.props.users || [],
                            selectedUser: this.props.users || [],
                            productId: this.props.productId || '',
                            onClose: this.handleClose,
                            onSubmit: this.handleClose,
                            updatePriority: this.updatePriority,
                            position: this.props.position || '',
                            requirement_info: this.props.requirement_info || {},
                            showNotificationIcon: this.props.showNotificationIcon || false,
                            notificationData: this.props.notificationData || '',
                            isShowAddWeightage: this.props.isShowAddWeightage || false
                        })
                    }}
                />
            </Tooltip>
            }
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(UserGroup));