import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import ReactSelect from "react-select";
import classNames from "classnames";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import {
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  IconButton,
  ListItemIcon,
  Tooltip,
  Switch,
  Popover,
  Divider,
  ListItemSecondaryAction,
  List,
  ListItem,
  TextField,
  InputAdornment,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
  FormControl,
  MenuItem, Grid,
  Checkbox,
  FormControlLabel,
  InputLabel
} from "@material-ui/core";

import { Select as MaterialSelect } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SaveIcon from '@material-ui/icons/Save';
import DateFilter from "Components/Common/Filters/DateFilter";
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Image from 'Components/Common/image.jsx';
// import * as Validator from "util/Validation";
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";
import { geFlowType, getUpdatedWorkflowColumns, getUpdatedAppCatalogColumns, getFinalTrackingColumns, updateTrackingColumns } from "util/Columns";
import SqureButton from "Components/Common/SqureButton";
import SearchIcon from "@material-ui/icons/Search";
import FormColumnsDialog from "./Common/formColumnsDialog";
// import editIcon from "assets/images/edit_icon.svg";
import { updateEvaluationTitle } from "redux/evaluation/metadata/action";
import addUsers from "assets/images/add_profile.svg";
import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
const SelectAsyncPaginate = withAsyncPaginate(Select);

const connectedProps = (state) => ({
  user: state.authUser.user,
  userType: state.authUser.user?.Role,
  isOpenAddFormColumnsDialog: state.workflow.isOpenAddFormColumnsDialog,
  orgForms: state.orgForms.forms,
  orgUser: state.orgUser,
});

const connectionActions = {
  updateEvaluationTitle: updateEvaluationTitle,
};

var connector = connect(connectedProps, connectionActions);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const RequestStatusMap = {
  Approved: 'Approved',
  PendingApproval: 'Pending Approval',
  Draft: 'Draft',
  Rejected: 'Rejected',
  CLOSED: 'Closed',
  FINISHED: 'Finished',
}

const ProjectStatusArray = [
  { label: "Draft", value: "Draft" },
  { label: "Pending Approval", value: "PendingApproval" },
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
  { label: "Closed", value: "CLOSED" },
  { label: "Finished", value: "FINISHED" },
]

const WorkflowStatusArray = [
  { label: "In Progress", value: "in_progress" },
  { label: "Completed", value: "completed" },
  { label: "Closed", value: "closed" },
]

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    workflowPaper: {
      padding: 20,
    },
    paper: {
      width: "100%",
      overflow: "hidden",
    },
    tableContainer: {
      // maxHeight: '68vh'
    },
    visuallyHidden: {
      marginLeft: 5,
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: "1 1 100%",
    },
    formSelect: {
      width: 150,
    },
    columnDrawer: {
      width: 400,
      right: "0",
      padding: "15px",
      zIndex: "9",
    },
    bottomActions: {
      textAlign: "right",
    },
    autoSelectInput: {
      width: "100%",
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
      // width: '30%'
    },
    searchBox: {
      // flex: 0.3,
      maxWidth: '500px',
      minWidth: '250px',
    },
    table: {},
    topHeader: {
      display: "flex",
    },
    tabsSection: {
      flex: 6,
    },
    showBtnSection: {
      flex: 1,
      textAlign: "right",
      paddingTop: 3,
      paddingRight: 10,
    },
    answerBox: {
      backgroundColor: "#fff",
      borderBottom: "1px solid #f1f1f1",
      boxShadow: "none",
    },
    secondaryBar: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      gap: 20,
      padding: theme.spacing(3, 3),
    },
    filterOptions: {
      display: "flex",
      alignItems: "center",
      gap: 20,
      padding: theme.spacing(3, 3),
      paddingTop: 0
    },
    formControl: {
      width: 150,
    },
    addFormColumn: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    disabledMenu: {
      cursor: 'not-allowed',
    },
    disabled: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    exportBtn: {
      marginLeft: 'auto',
      marginRight: '30px',
    },
    exportBtn1: {
      marginLeft: 'auto',
      display: 'flex',
      marginTop: 25,
      justifyContent: 'center',
      alignItems: 'center'
    },
    filterBtn: {
      height: 39,
      fontSize: 16,
      borderColor: '#cccccc',
      marginTop: -10,
    },
    editIconBtn: {
      width: 20
    },
    bodyConfig: {
      marginTop: 20,
      marginBottom: 20
    },
    selectComp: {
      width: 300,
    },
    filterTitle: {
      color: "#282D30",
      fontWeight: 400,
      fontSize: theme.spacing(2.2),
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: 10
    },
    stateLabel: {
      cursor: "pointer",
      backgroundColor: "#fff",
      border: "1px #dadce0 solid",
      borderRadius: 8,
      height: 32,
      padding: "0 10px",
      margin: "0px 3px",
      display: "flex",
      alignItems: "center",
    },
    selected: {
      backgroundColor: "#a3dcfc33",
    },
    stateLabelText: {
      color: "#5f6368",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "1px",
    },
    labelInit: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 16,
      height: 16,
      marginRight: 8,
      color: "#fff",
      borderRadius: 8,
      fontSize: 10,
      background: "#4a87f8",
    },
    isFeatured: {
      background: "#ff9800",
      color: "#fff",
    },
    clearFilterBtn: {
      marginLeft: 10,
      padding: '4px 20px'
    },
    statusfilterBtn: {
      background: '#fff',
      border: '1px solid #cccccc',
      borderRadius: 4,
      minWidth: 150,
      color: '#222',
      boxShadow: 'none',
      padding: '4px 20px',
      fontSize: 16,
      '&:hover': {
        background: '#fff',
        color: '#222'
      }
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    textWrap: {
      "& [class*='editor-editableField']": {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        minHeight: 'unset !important',
        "& p": {
          marginTop: 0
        }
      },
    },
    statusFilter: {
      marginRight: 20,
    }
  });

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

class ListingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "name",
      selected: [],
      dense: false,
      page: 0,
      rowsPerPage: 20,
      query: "",
      rows: [],
      columns: [],
      invertDirection: {
        asc: "desc",
        desc: "asc",
      },
      isShowSubMenu: false,
      subMenuEl: null,
      colVisibilityData: null,
      activeTab: 1,
      statusFilter: [],
      projectStatusFilter: [],
      workflowNameFilter: "0",
      requestTypeFilter: "0",
      projectOwnerIDs: [],
      requestorIds: [],
      anchorEl: null,
      currentColumn: null,
      isShowProjectConfigPopover: false,
      projectTrackingEl: null,
      trackFormConfig: '',
      availableFilters: [
        { label: "Workflow Status", slug: "workflow_status", value: 0, classname: 'isFeatured', isShow: true },
        { label: "Project Status", slug: "project_status", value: 1, classname: 'isFeatured', isShow: true },
        { label: "Workflow Name", slug: "workflow_name", value: 2, classname: 'isFeatured', isShow: true },
        { label: "Project Type", slug: "request_type", value: 6, classname: 'isFeatured', isShow: true },
        { label: "Requestor", slug: "requestor", value: 5, classname: 'isFeatured', isShow: true },
        { label: "Procurement Owner", slug: "procurement_owner", value: 3, classname: 'isFeatured', isShow: true },
        { label: "Date", slug: "date", value: 4, classname: 'isFeatured', isShow: true },
      ],
      showFilters: [],
      workflowStatusfilterPopover: null,
      workflowProjectStatusfilterPopover: null,
      procurementOwnerText: '',
      isEditProcurementOwner: false,
      columnNameMap: {}
    };
    this.btnRef = React.createRef();

    this.handleCloseSubMenu = this.handleCloseSubMenu.bind(this);
    this.showColumnVisibityPoper = this.showColumnVisibityPoper.bind(this);
    this.updateColVisibilityStatus = this.updateColVisibilityStatus.bind(this);
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
    this.handleChangeTabs = this.handleChangeTabs.bind(this);
  }

  componentDidMount() {
    if (this.props.from === "WORKFLOW_MENU") {
      let columns = []
      if (this.props.activeTab === 3) {
        columns = getFinalTrackingColumns(this.props.formItemsIdMap);
        this.initWorkflowTrackingTableData();
      } else {
        columns = getUpdatedWorkflowColumns();
        this.initWorkflowTableData();
      }
      this.setColumns(columns)
    } else if (this.props.from === "APP_CATALOG") {
      let columns = getUpdatedAppCatalogColumns();
      this.setColumns(columns);
      this.initAppCatalogTableData();
    }
    this.initLocalVariables();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUpdatedNewData !== prevProps.isUpdatedNewData) {
      if (this.props.from === "WORKFLOW_MENU") {
        if (this.props.activeTab !== 3) {
          this.initWorkflowTableData();
        } else if (this.props.activeTab === 3) {
          this.initWorkflowTrackingTableData();
        }
      } else if (this.props.from === "APP_CATALOG") {
        this.initAppCatalogTableData();
      }
    }

    if (this.props.selectedView !== prevProps.selectedView) {
      this.initLocalVariablesByViews();
    }

    if (this.props.totalRows !== prevProps.totalRows && this.props.activeTab !== 3) {
      if (this.props.from === "WORKFLOW_MENU") {
        this.initWorkflowTableData();
        // selectedColumns
      } else if (this.props.from === "APP_CATALOG") {
        this.initAppCatalogTableData();
      }
    }

    if (this.props.trackDataTotalRows !== prevProps.trackDataTotalRows && this.props.activeTab === 3) {
      if (this.props.from === "WORKFLOW_MENU") {
        this.initWorkflowTrackingTableData();
      }
    }

    if (this.props.updateTrackingForm !== prevProps.updateTrackingForm) {
      this.setState({
        trackFormConfig: this.props.projectTrackingForm
      })
    }

    if (this.props.trackDataRows !== prevProps.trackDataRows && this.props.activeTab === 3) {
      if (this.props.from === "WORKFLOW_MENU") {
        this.initWorkflowTrackingTableData();
      }
    }

    if (this.props.isChangesInFilters !== prevProps.isChangesInFilters) {
      this.initLocalVariables()
    }

    if (this.props.projectColumnWithNames !== prevProps.projectColumnWithNames) {
      this.updateWorkflowColumnNames()
    }

  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  updateWorkflowColumnNames() {
    const { projectColumnWithNames } = this.props;
    if (projectColumnWithNames) {
      let prjColumns = JSON.parse(projectColumnWithNames);
      // console.log(prjColumns, 'prjColumns')
      let map = {}
      prjColumns.forEach(function (col) {
        map[col.id] = col.label
      })
      let columns = this.state.columns;
      columns.forEach(function (cl) {
        if (cl.id === "projectOwnerID") {
          cl.label = map[cl.id]
        }
      })
      this.setState({
        columns: columns,
        columnNameMap: map
      })
    }
  }

  initLocalVariablesByViews() {
    if (this.props.selectedView) {
      let state = {
        page: this.props.page,
        rowsPerPage: this.props.pageSize,
        query: this.props.searchQuery,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        dates: this.props.dates,
        duration: this.props.duration,
        columns: this.props.columns,
        statusFilter: this.props.statusFilter || [],
        projectStatusFilter: this.props.projectStatusFilter || [],
        workflowNameFilter: this.props.workflowNameFilter || "0",
        requestTypeFilter: this.props.requestTypeFilter || "0",
        trackFormConfig: this.props.projectTrackingForm,
        showFilters: this.props.showFilters || [],
        projectOwnerIds: this.props.projectOwnerIds || [],
        requestorIds: this.props.requestorIds || [],
      };

      if (this.props.isGlobalSortRequire) {
        state["order"] = this.props.sortType;
        state["orderBy"] = this.props.sortField;
      }
      this.setState(state,()=>{
        this.updateNameMap(this.props.columns)
      });
    } else {
      this.setState({
        showFilters: [],
        statusFilter: ['completed', 'in_progress', 'closed'],
        projectStatusFilter: ['Draft', 'PendingApproval', 'Approved', 'Rejected', 'CLOSED', 'FINISHED'],
        workflowNameFilter: "0",
        requestTypeFilter: "0",
        projectOwnerIDs: [],
        requestorIds: [],
        query: '',
        page: 0,
      })
    }
  }

  initLocalVariables() {
    let state = {
      page: this.props.page,
      rowsPerPage: this.props.pageSize,
      query: this.props.searchQuery,
      activeTab: this.props.activeTab,
      statusFilter: this.props.statusFilter || [],
      projectStatusFilter: this.props.projectStatusFilter || [],
      workflowNameFilter: this.props.workflowNameFilter || "0",
      requestTypeFilter: this.props.requestTypeFilter || "0",
      trackFormConfig: this.props.projectTrackingForm,
      showFilters: this.props.showFilters || [],
      projectOwnerIds: this.props.projectOwnerIds || [],
      requestorIds: this.props.requestorIds || [],
    };

    if (this.props.isGlobalSortRequire) {
      state["order"] = this.props.sortType;
      state["orderBy"] = this.props.sortField;
    }
    this.setState(state);
  }

  initWorkflowTableData() {
    if (this.props.rows !== undefined && this.props.rows !== null) {
      let newRows = this.props.rows.map((o) => {
        // console.log(o, 'EvaluationData')
        let date = CommonFn.formateDate(o?.CreatedAt, true);
        let lastUpdateDate = CommonFn.formateDate(o?.UpdatedAt, true);

        let totalDays = o?.DaysInCurrentStep !== null && o?.DaysInCurrentStep !== "" && o?.DaysInCurrentStep > 0 ? Math.round(o.DaysInCurrentStep) : 0;

        return {
          name: o?.Name,
          owner: o?.ProjectOwnerID !== '' && o?.ProjectOwnerID !== null ? { label: o.ProjectOwnerName, value: o.ProjectOwnerID } : '',
          requestId: o?.ID,
          workflow_name: o?.WorkflowName,
          workflow_type: o?.RequestType !== '' ? o?.RequestType : geFlowType(o?.Type),
          workflow_type_id: o?.Type,
          requester_name: { label: o?.RequestorName, value: o.RequestorID },
          requestStatus: o?.RequestStatus,
          status: o?.Status === 4 ? "Completed" : "In Progress",
          statusValue: o?.Status,
          currentStepDays: totalDays,
          creation_date: date,
          last_update: lastUpdateDate,
          id: o?.ID,
          workflowId: o?.WorkflowName,
          userId: o?.User?.ID,
          currentStep: o?.CurrentComponnetName,
          ...o?.FormFields,
          isEditOwner: false,
          isEditRequester: false,
        };
      });
      // console.log(newRows, "NewRowsssssssssssssss");
      this.setState({
        rows: newRows,
      });
    }
  }

  initWorkflowTrackingTableData() {
    if (this.props.rows !== undefined && this.props.rows !== null) {
      let newRows = this.props.rows.map((o) => {

        let date = CommonFn.formateDate(o?.CreatedAt, true);
        let lastUpdateDate = CommonFn.formateDate(o?.UpdatedAt, true);

        let totalDays = o?.DaysInCurrentStep !== null && o?.DaysInCurrentStep !== "" && o?.DaysInCurrentStep > 0 ? Math.round(o.DaysInCurrentStep) : 0;

        return {
          name: o?.Name,
          owner: o?.ProjectOwnerID !== '' && o?.ProjectOwnerID !== null ? { label: o.ProjectOwnerName, value: o.ProjectOwnerID } : '',
          requestId: o?.ID,
          workflow_name: o?.WorkflowName,
          workflow_type: geFlowType(o?.Type),
          workflow_type_id: o?.Type,
          requester_name: { label: o?.RequestorName, value: o.RequestorID },
          requestStatus: o?.RequestStatus,
          status: o?.Status === 4 ? "Completed" : "In Progress",
          currentStepDays: totalDays,
          creation_date: date,
          last_update: lastUpdateDate,
          id: o?.ID,
          workflowId: o?.WorkflowName,
          userId: o?.User?.ID,
          currentStep: o?.CurrentComponnetName,
          ...o?.FormFields,
          isEditOwner: false,
          isEditRequester: false
        };
      });

      this.setState({
        rows: newRows,
      });
    }
  }

  setColumns(columns) {
    this.setState(
      {
        columns: columns,
        colVisibilityData: null,
      },
      () => {
        if (this.props.from === "WORKFLOW_MENU" && this.state.activeTab !== 3) {
          window.localStorage.setItem("workflowListingColumns", JSON.stringify(columns));
          this.updateWorkflowColumnNames()
        } else if (this.props.from === "WORKFLOW_MENU" && this.state.activeTab === 3) {
          window.localStorage.setItem("projectTrackingTableColumns", JSON.stringify(columns));
          // updateTrackingColumns(columns)
          // console.log('TrackingLocalColumns-CalllingggggggUpdate')
          this.updateWorkflowColumnNames()
        } else if (this.props.from === "APP_CATALOG") {
          window.localStorage.setItem("appCatalogColumns", JSON.stringify(columns));
        }
      }
    );
  }

  setOrder(order) {
    this.setState({
      order: order,
    });
  }

  setOrderBy(orderBy) {
    this.setState({
      orderBy: orderBy,
    });
  }

  setSelected(selected) {
    this.setState({
      selected: selected,
    });
  }

  setDense(dense) {
    this.setState({
      dense: dense,
    });
  }

  setPage(page) {
    this.setState(
      {
        page: page,
      },
      () => {
        this.props.onChangePage(page, this.state.rowsPerPage);
      }
    );
  }

  setRowsPerPage(rowsPerPage) {
    this.setState(
      {
        rowsPerPage: rowsPerPage,
      },
      () => {
        this.props.onChangePage(this.state.page, rowsPerPage);
      }
    );
  }

  updateSearchQuery(searchQuery) {
    this.setState(
      {
        page: 0,
        rowsPerPage: 20,
        query: searchQuery,
      },
      () => {
        this.props.onChangeSearch(0, 20, searchQuery);
      }
    );
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property ? this.state.invertDirection[this.state.order] : "asc";
    this.setState(
      {
        page: 0,
      },
      () => {
        this.setOrder(isAsc);
        this.setOrderBy(property);
        if (this.props.isGlobalSortRequire) {
          this.props.onChangeSort(isAsc, property);
        }
      }
    );
  };

  handleSort = (event, columnName) => {
    this.setState((state) => ({
      columnToSort: columnName,
      sortDirection: state.columnToSort === columnName ? this.state.invertDirection[state.sortDirection] : "asc",
    }));
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.rows.map((n) => n.name);
      this.setSelected(newSelecteds);
      return;
    }
    this.setSelected([]);
  };
  // App Catalog Functions
  initAppCatalogTableData() {
    if (this.props.rows !== undefined && this.props.rows !== null) {
      let newRows = this.props.rows.map((o) => {
        let startDate, endDate, renewalDate;
        if (o.start_date) {
          startDate = CommonFn.formateDate(o.start_date, true);
        }
        if (o.end_date) {
          endDate = CommonFn.formateDate(o.end_date, true);
        }
        if (o.renewal_date) {
          renewalDate = CommonFn.formateDate(o.renewal_date, true);
        }
        return {
          name: o?.name || "",
          owner: o?.projectOwnerID !== '' && o?.projectOwnerID !== null ? { label: o.ProjectOwnerName, value: o.projectOwnerID } : '',
          company_name: o?.company_name,
          departments: o.departments !== undefined && o.departments !== undefined && o.departments.length > 0 ? o.departments.join(", ") : "",
          description: o?.description,
          logo: o?.logo,
          type: o?.type,
          business_criticality: o?.business_criticality || "",
          cost_per_license: o?.cost_per_license || 0,
          start_date: startDate || "",
          end_date: endDate || "",
          number_of_license_procured: o?.number_of_license_procured || 0,
          renewal_date: renewalDate || "",
          total_cost: o?.total_cost || 0,
          id: o?.product_id,
          states: o?.states,
          isEditOwner: false,
          isEditRequester: false
        };
      });
      // console.log(newRows, "NewRowsssssssssssssssAppCatalog");
      this.setState({
        rows: newRows,
      });
    }
  }

  routeChange = (item) => {
    // console.log(this.props.projectTrackingForm, 'this.props.projectTrackingForm')
    // console.log(this.state.activeTab, 'activeTab')
    // console.log(this.props.from, 'this.props.from')
    // console.log(item, 'item')
    if (this.state.activeTab === 3 && this.props.projectTrackingForm !== null && this.props.projectTrackingForm !== '') {
      this.props.history.push(`/app/project/${item.id}/tracking/${this.props.projectTrackingForm.value}`);
    } else {
      if (this.props.from === "WORKFLOW_MENU" && (item.workflow_type_id !== 3)) {
        if (item.workflowId !== undefined && item.workflowId !== null && item.workflowId !== "") {
          this.props.history.push(`/app/evaluate/${item.id}/workflow/interim`);
        } else {
          let redirectPath = `/app/evaluate/${item.id}/setup`;
          this.props.history.push(redirectPath);
        }
      } else if (this.props.from === "WORKFLOW_MENU" && item.workflow_type_id === 3) {
        let redirectPath = "";
        if (this.props.user.ID === item.userId) {
          redirectPath = `/app/survey/${item.id}`;
          this.props.history.push(redirectPath);
        } else {
          let redirectPath = `/app/survey/${item.id}/take-survey`;
          this.props.history.push(redirectPath);
        }
      } else if (this.props.from === "APP_CATALOG") {
        let redirectPath = "";
        redirectPath = `/app/products/org-product-details/${item.id}`;
        this.props.history.push(redirectPath);
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
      () => {
        this.props.onChangePage(this.state.page, this.state.rowsPerPage);
      }
    );
  };

  handleChangeDense = (event) => {
    this.setDense(event.target.checked);
  };

  isSelected = (name) => {
    return this.state.selected.indexOf(name) !== -1;
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  handleCloseSubMenu() {
    this.setState({
      isShowSubMenu: false,
      subMenuEl: null,
      colVisibilityData: null,
      isShowProjectConfigPopover: false,
      projectTrackingEl: null,
    });
  }

  showColumnVisibityPoper(event) {
    this.setState({
      subMenuEl: event.currentTarget || event.current,
      isShowSubMenu: true,
      colVisibilityData: [...this.state.columns],
    });
  }

  showProjectTrackingConfigPoper(event) {
    this.setState({
      projectTrackingEl: event.currentTarget || event.current,
      isShowProjectConfigPopover: true,
    });
  }

  setVisibilityForColumns() {
    this.setColumns(this.state.colVisibilityData);
    let columns = []
    this.state.colVisibilityData.forEach(item => {
      if (item.isDynamic && item.isVisible) {
        columns.push(item.id)
      }
    })
    this.props.onSetFormColumnsIds(columns);
    this.handleCloseSubMenu();
  }

  updateColVisibilityStatus(oldStatus, index) {
    let colVisibilityData = [...this.state.colVisibilityData];
    let myCol = colVisibilityData[index];
    let isVisible = oldStatus === true ? false : true;
    myCol = {
      ...myCol,
      isVisible: isVisible,
    };
    colVisibilityData[index] = myCol;

    this.setState({
      colVisibilityData: colVisibilityData,
    });
  }

  handleChangeTabs(event, newValue) {
    const showFilters = [...this.state.showFilters];
    this.setState(
      {
        activeTab: newValue,
        showFilters: [],
        statusFilter: ['completed', 'in_progress', 'closed'],
        projectStatusFilter: ['Draft', 'PendingApproval', 'Approved', 'Rejected', 'CLOSED', 'FINISHED'],
        workflowNameFilter: "0",
        requestTypeFilter: "0",
        projectOwnerIDs: [],
        requestorIds: [],
        query: '',
        page: 0,
      },
      () => {
        this.props.onChangeTab(newValue, showFilters);
        let columns;
        if (newValue === 3) {
          columns = getFinalTrackingColumns(this.props.formItemsIdMap);
        } else {
          columns = getUpdatedWorkflowColumns();
        }
        this.setColumns(columns)
      }
    );
  }

  selectProjectOwers = (value) => {
    this.setState(
      {
        projectOwnerIDs: value,
        page: 0,
      },
      () => {
        this.props.onSelectProjectOwnersFilter(value);
      }
    );
  }

  selectRequester = (value) => {
    this.setState(
      {
        requestorIds: value,
        page: 0,
      },
      () => {
        this.props.onSelectRequestorFilter(value);
      }
    );
  }

  updateStatusFilter(key) {
    let newStatusFilter = [...this.state.statusFilter];
    let index = newStatusFilter.indexOf(key);
    if (index > -1) {
      newStatusFilter.splice(index, 1);
    } else {
      newStatusFilter.push(key)
    }
    this.setState(
      {
        statusFilter: newStatusFilter,
        page: 0,
      },
      () => {
        this.props.onChangeStatusFilter(newStatusFilter);
      }
    );
  }

  updateProjectStatusFilter(key) {
    let newProjectStatusFilter = [...this.state.projectStatusFilter];
    let index = newProjectStatusFilter.indexOf(key);
    if (index > -1) {
      newProjectStatusFilter.splice(index, 1);
    } else {
      newProjectStatusFilter.push(key)
    }
    this.setState(
      {
        projectStatusFilter: newProjectStatusFilter,
        page: 0,
      },
      () => {
        this.props.onChangeProjectStatusFilter(newProjectStatusFilter);
      }
    );
  }

  updateWorkflowNameFilter(newValue) {
    this.setState(
      {
        workflowNameFilter: newValue,
        page: 0,
      },
      () => {
        this.props.onChangeWorkflowNameFilter(newValue);
      }
    );
  }

  updateRequestTypeFilter(newValue) {
    this.setState(
      {
        requestTypeFilter: newValue,
        page: 0,
      },
      () => {
        this.props.onChangeRequestTypeFilter(newValue);
      }
    );
  }

  setFormColumns = (allSelectedFormColumnsObj) => {
    let columnData = [];
    let selectedColumns = [];

    Object.keys(allSelectedFormColumnsObj).forEach((key) => {
      allSelectedFormColumnsObj[key].forEach((item) => {
        selectedColumns.push(item);
      });
    });
    selectedColumns.forEach((column) => {
      columnData.push({
        id: column.ID,
        numeric: false,
        align: "left",
        isVisible: true,
        isSortRequire: false,
        disablePadding: false,
        label: column.ItemName,
        isDynamic: true,
        type: column.Type,
      });
    });

    if (this.props.activeTab && this.props.activeTab === 3) {
      window.localStorage.setItem("allTrackingSelectedFormColumnsObj", JSON.stringify(allSelectedFormColumnsObj));
      window.localStorage.setItem("projectTrackingFormColumns", JSON.stringify(columnData));
    } else {
      window.localStorage.setItem("allSelectedFormColumnsObj", JSON.stringify(allSelectedFormColumnsObj));
    }

    let baseColumns = this.state.columns.filter((item) => !item.isDynamic);
    if (this.props.activeTab && this.props.activeTab === 3) {
      updateTrackingColumns([...baseColumns, ...columnData])
      let map = this.props.formItemsIdMap;
      columnData.forEach(function (col) {
        if (map[col.id] === undefined) {
          map[col.id] = col;
        }
      })
      this.props.updateFormIdMap(map);
      setTimeout(() => {
        let columns = getFinalTrackingColumns(this.props.formItemsIdMap);
        this.setColumns(columns)
      }, 2000);
    } else {
      this.setColumns([...baseColumns, ...columnData]);
    }
    // this.setColumns([...baseColumns, ...columnData]);
    this.handleCloseSubMenu();
    this.props.onSetFormColumnsIds(selectedColumns.map((O) => O.ID));
  };

  onColumnsDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const newColVisibilityData = reorder(this.state.colVisibilityData, source.index, destination.index);

      this.setState({
        colVisibilityData: newColVisibilityData,
      });
    }
  }

  handleClick = (event, row) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget || event.current,
      currentColumn: row,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      currentColumn: null,
    });
  };

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      page: 0,
      pageSize: 20,
      query: inputValue
    };
    const url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    // console.log(response)

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  editOwner(index) {
    let { rows } = this.state;
    let row = rows[index];
    row.isEditOwner = true;
    this.setState({
      rows: rows
    })
  }

  editRequester(index) {
    let { rows } = this.state;
    let row = rows[index];
    row.isEditRequester = true;
    this.setState({
      rows: rows
    })
  }

  updateOwner(id, user, index) {
    let ownerId = '';
    if (user !== undefined && user !== null && user !== '') {
      ownerId = user.value
    }
    this.props.updateEvaluationTitle(id, {
      projectOwnerID: ownerId === '' ? 0 : ownerId
    })

    // console.log(user,'Userrrrrrrrrrrrrr')

    let { rows } = this.state;
    let row = rows[index];
    row.isEditOwner = false;
    row.owner = user;
    this.setState({
      rows: rows
    })
  }

  updateRequester(id, user, index) {
    let requesterId = '';
    if (user !== undefined && user !== null && user !== '') {
      requesterId = user.value
    }
    this.props.updateEvaluationTitle(id, {
      userId: requesterId === '' ? 0 : requesterId
    })

    // console.log(user,'Userrrrrrrrrrrrrr')

    let { rows } = this.state;
    let row = rows[index];
    row.isEditRequester = false;
    row.requester_name = user;
    this.setState({
      rows: rows
    })
  }

  updateTrackingConfiguration() {
    if (this.state.trackFormConfig === null || this.state.trackFormConfig === '') {
      this.props.showSnackBar('Please select form', 'error', 3000)
      return;
    }
    this.props.updateTrackingConfiguration(this.state.trackFormConfig)
    this.handleCloseSubMenu();
  }

  redirectToForm = () => {
    let trackFormID = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "PROJECT_TRACKING_FORM");
    // console.log(trackFormID)
    if (trackFormID && trackFormID.length > 0) {
      this.props.history.push(`/app/settings/templates/forms/custom/${trackFormID}`)
    } else {
      this.props.showSnackBar('Something went wrong. Please try again later', 'error', 3000)
    }
  }

  handleOnClickFilter(value, index) {
    let showFilters = this.state.showFilters;
    if (showFilters.indexOf(value) !== -1) {
      showFilters.splice(showFilters.indexOf(value), 1)
    } else {
      showFilters.push(value)
    }
    this.setState({
      showFilters: showFilters
    }, () => {
      this.props.updateShowFilters(showFilters);
    })
  }

  clearFilter() {
    this.setState(
      {
        showFilters: [],
        statusFilter: ['completed', 'in_progress', 'closed'],
        projectStatusFilter: ['Draft', 'PendingApproval', 'Approved', 'Rejected', 'CLOSED', 'FINISHED'],
        workflowNameFilter: "0",
        requestTypeFilter: "0",
        projectOwnerIDs: [],
        requestorIds: [],
        query: '',
        page: 0,
      }, () => {
        this.props.clearFilters()
      })
  }

  updateColumnName = (index) => {
    let { procurementOwnerText, colVisibilityData } = this.state;
    // console.log(procurementOwnerText, 'procurementOwnerText')
    let col = colVisibilityData[index];
    col.label = procurementOwnerText;
    this.setState({
      colVisibilityData: colVisibilityData,
      columns: colVisibilityData,
      procurementOwnerText: '',
      isEditProcurementOwner: false,
    }, () => {
      this.props.updateColumnNames(this.state.columns)
      if (this.props.from === "WORKFLOW_MENU" && this.state.activeTab !== 3) {
        window.localStorage.setItem("workflowListingColumns", JSON.stringify(this.state.columns));
      } else if (this.props.from === "WORKFLOW_MENU" && this.state.activeTab === 3) {
        window.localStorage.setItem("projectTrackingTableColumns", JSON.stringify(this.state.columns));
      } else if (this.props.from === "APP_CATALOG") {
        window.localStorage.setItem("appCatalogColumns", JSON.stringify(this.state.columns));
      }
      this.updateNameMap(this.state.columns)
    })
  }

  updateNameMap(prjColumns) {
    if (prjColumns) {
      let map = {}
      prjColumns.forEach(function (col) {
        map[col.id] = col.label
      })
      this.setState({
        columnNameMap: map
      })
    }
  }

  render() {
    const classes = this.props.classes;
    const { order, orderBy, page, rowsPerPage, rows, dense, columns, colVisibilityData, query, activeTab, trackFormConfig, showFilters, availableFilters } = this.state;
    const { totalRows, searchPlaceHolder, isShowSearch, isGlobalSortRequire } = this.props;
    const openAction = Boolean(this.state.anchorEl);
    // console.log(this.props.tableMaxHeight, "tableMaxHeight");
    // console.log(activeTab, "activeTab");
    let newRows = isGlobalSortRequire ? rows : stableSort(rows, getComparator(order, orderBy));
    let userRole = ["OrgAdmin", "OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    const ownerData = this.props.projectOwnerIds || [];
    const requesterData = this.props.requestorIds || [];

    return (
      <div>
        <Paper className={classes.paper}>
          <AppBar position="static" color="default" className={classes.answerBox}>
            <div className={classes.topHeader}>
              {this.props.isShowTabs && (
                <div className={classes.tabsSection}>
                  <Tabs value={activeTab} indicatorColor="primary" textColor="primary" onChange={this.handleChangeTabs} aria-label="classes. tabs example">
                    {this.props.tabs &&
                      this.props.tabs.map((tab) => {
                        return <Tab value={tab.value} label={tab.label} />;
                      })}
                  </Tabs>
                </div>
              )}
              {<div className={classes.showBtnSection}>
                {[0, 3].includes(this.props.activeTab) && (<>
                  {adminFlag && <Tooltip title="Create or manage views">
                    <IconButton
                      aria-label="filter list"
                      onClick={(event) => {
                        this.props.showWorkflowViewsDialog(columns);
                      }}
                    >
                      <LibraryBooksIcon />
                    </IconButton>
                  </Tooltip>}
                </>)}
                <Tooltip title="Show Hide Columns">
                  <IconButton
                    aria-label="filter list"
                    onClick={(event) => {
                      this.showColumnVisibityPoper(event);
                    }}
                  >
                    <SettingsOutlined />
                  </IconButton>
                </Tooltip>
                {this.state.activeTab === 3 && (this.props.projectTrackingForm === null || this.props.projectTrackingForm === '') && <Tooltip title="Configuration">
                  <IconButton
                    aria-label="filter list"
                    onClick={(event) => {
                      this.showProjectTrackingConfigPoper(event);
                    }}
                  >
                    <PermDataSettingIcon />
                  </IconButton>
                </Tooltip>}
              </div>}

              {/* {this.props.activeTab === 3 && <div className={classes.showBtnSection}>
                <Tooltip title="Configuration">
                  <IconButton
                    aria-label="filter list"
                    onClick={(event) => {
                      this.showProjectTrackingConfigPoper(event);
                    }}
                  >
                    <SettingsOutlined />
                  </IconButton>
                </Tooltip>
              </div>} */}

            </div>
          </AppBar>
          <div className={classes.secondaryBar}>
            {isShowSearch && (
              <div className={classes.searchBox}>
                <Typography variant={"h6"} className={classes.filterTitle}>
                  Project Name
                </Typography>
                <TextField
                  className={classes.autoSelectInput}
                  value={query}
                  variant="outlined"
                  placeholder={searchPlaceHolder}
                  type="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onInput={(e) => {
                    this.updateSearchQuery(e.target.value);
                  }}
                />
              </div>
            )}

            {showFilters.indexOf('workflow_status') !== -1 && <div className={classes.statusFilter}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Workflow Status
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.statusfilterBtn}
                  onClick={(event) => {
                    this.setState({
                      workflowStatusfilterPopover: event.currentTarget,
                    });
                  }}
                >
                  Status
                </Button>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  id="workflowStatusfilterPopover"
                  className={classes.popover}
                  anchorEl={this.state.workflowStatusfilterPopover}
                  keepMounted
                  open={Boolean(this.state.workflowStatusfilterPopover)}
                  onClose={() => {
                    this.setState({ workflowStatusfilterPopover: null });
                  }}
                >
                  <List>
                    {WorkflowStatusArray.map((obj, i) => (
                      <ListItem key={i} classes={{ button: classes.listItem }} button onClick={() => { }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              classes={{
                                checked: classes.checked,
                              }}
                              onChange={(e) => {
                                this.updateStatusFilter(obj.value)
                              }}
                              checked={this.state.statusFilter?.includes(obj.value)}
                            />
                          }
                          name={obj.value}
                          label={obj.label}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Popover>
              </FormControl>
            </div>}

            {showFilters.indexOf('project_status') !== -1 && <div className={classes.statusFilter}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Project Status
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.statusfilterBtn}
                  onClick={(event) => {
                    this.setState({
                      workflowProjectStatusfilterPopover: event.currentTarget,
                    });
                  }}
                >
                  Status
                </Button>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  id="workflowProjectStatusfilterPopover"
                  className={classes.popover}
                  anchorEl={this.state.workflowProjectStatusfilterPopover}
                  keepMounted
                  open={Boolean(this.state.workflowProjectStatusfilterPopover)}
                  onClose={() => {
                    this.setState({ workflowProjectStatusfilterPopover: null });
                  }}
                >
                  <List>
                    {ProjectStatusArray.map((obj, i) => (
                      <ListItem key={i} classes={{ button: classes.listItem }} button onClick={() => { }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              classes={{
                                checked: classes.checked,
                              }}
                              onChange={(e) => {
                                this.updateProjectStatusFilter(obj.value)
                              }}
                              checked={this.state.projectStatusFilter?.includes(obj.value)}
                            />
                          }
                          name={obj.value}
                          label={obj.label}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Popover>
              </FormControl>
            </div>}

            {showFilters.indexOf('workflow_name') !== -1 && <div className={classes.statusFilter}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Workflow Name
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <MaterialSelect
                  value={this.state.workflowNameFilter}
                  onChange={(e) => {
                    this.updateWorkflowNameFilter(e.target.value);
                  }}
                >
                  {this.props.workflowFilterOptions.map((op) => {
                    return <MenuItem key={'wrkFilter_' + op.value} value={op.value} style={{ fontSize: 13 }}>
                      {op.label}
                    </MenuItem>
                  })}
                </MaterialSelect>
              </FormControl>
            </div>}

            {showFilters.indexOf('request_type') !== -1 && <div className={classes.statusFilter}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Project Type
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <MaterialSelect
                  value={this.state.requestTypeFilter}
                  onChange={(e) => {
                    this.updateRequestTypeFilter(e.target.value);
                  }}
                >
                  {this.props.requestTypeOptions.map((op) => {
                    return <MenuItem key={'reqTypeFilter_' + op.value} value={op.value} style={{ fontSize: 13 }}>
                      {op.label}
                    </MenuItem>
                  })}
                </MaterialSelect>
              </FormControl>
            </div>}

            {showFilters.indexOf('procurement_owner') !== -1 && <div className={classes.selectComp}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                {this.state.columnNameMap && this.state.columnNameMap['projectOwnerID'] !== undefined ? this.state.columnNameMap['projectOwnerID'] : 'Procurement owner'}
              </Typography>
              <SelectAsyncPaginate
                isClearable={false}
                isMulti
                isSearchable={true}
                value={ownerData}
                menuPosition='fixed'
                classNamePrefix="select"
                isLoading={false}
                placeholder={this.state.columnNameMap && this.state.columnNameMap['projectOwnerID'] !== undefined ? 'Search ' + this.state.columnNameMap['projectOwnerID'] : 'Search procurement owner'}
                additional={{
                  page: 0,
                }}
                loadOptions={this.getOptions}
                onChange={(e) => {
                  this.selectProjectOwers(e);
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </div>}

            {showFilters.indexOf('requestor') !== -1 && activeTab !== 1 && <div className={classes.selectComp}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Requestor
              </Typography>
              <SelectAsyncPaginate
                isClearable={false}
                isMulti
                isSearchable={true}
                value={requesterData}
                menuPosition='fixed'
                classNamePrefix="select"
                isLoading={false}
                placeholder="Search requestor"
                additional={{
                  page: 0,
                }}
                loadOptions={this.getOptions}
                onChange={(e) => {
                  this.selectRequester(e);
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </div>}

            {showFilters.indexOf('date') !== -1 && !this.props.isLoading && <div style={{}}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Date
              </Typography>
              <DateFilter
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                duration={this.props.duration}
                btnClass={classes.filterBtn}
                onChangeDates={(dates) => {
                  let startDate, endDate;
                  if (dates.isCustomDates) {
                    startDate = dates.startDate;
                    endDate = dates.endDate;
                  } else {
                    startDate = CommonFn.getPreviousDate(dates.duration);
                    endDate = new Date();
                  }
                  this.setState({ page: 0 }, () => {
                    this.props.handleDateChange(dates, startDate, endDate, dates.duration)
                  })
                }}
              />
            </div>}

            <div className={classes.exportBtn1}>
              {[0, 3].includes(this.props.activeTab) && adminFlag && (
                <Button variant="outlined" color="secondary" style={{ marginRight: 20 }} startIcon={<SaveIcon />} onClick={() => { this.props.showAddUpdateDialog() }}>
                  Save View
                </Button>
              )}
              {[0, 3].includes(this.props.activeTab) && this.props.viewsList?.length > 0 && (
                <div className={classes.statusFilter}>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <InputLabel id="demo-simple-select-outlinedViewlabel">Views</InputLabel>
                    <MaterialSelect
                      labelId="demo-simple-select-outlinedViewlabel"
                      id="demo-simple-select-outlined"
                      label="Views"
                      value={String(this.props.selectedView)}
                      onChange={(e) => {
                        this.props.selectView(Number(e.target.value));
                      }}
                    >
                      {this.props.viewsList?.map((item, i) => (
                        <MenuItem value={String(item.id)} key={i} style={{ fontSize: 13 }}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </MaterialSelect>
                  </FormControl>
                </div>
              )}
              {this.props.showExport && rows.length > 0 && adminFlag && <>
                {this.props.loadingExportBtn ? (
                  <Button className={classes.exportBtn} style={{ width: 100 }} disabled variant="contained" color="secondary">
                    <CircularProgress style={{ width: 25, height: 25 }} />
                  </Button>) : (
                  <Button variant="contained" color="secondary" startIcon={<GetAppIcon />} onClick={() => { this.props.exportData(this.state.dates) }}>
                    Export
                  </Button>
                )}
              </>}
              &nbsp;&nbsp;
              {this.props.showStartNewProjectBtn && <Button variant="outlined" color="secondary" startIcon={<PlayCircleOutlineIcon />} onClick={() => { this.props.onClickStartNewProject() }}>
                Start New Project
              </Button>}
            </div>
          </div>
          <div className={classes.filterOptions}>
            <Grid container>
              {availableFilters.map((objState, index) => {
                if (!objState.isShow || (objState.slug === 'requestor' && activeTab === 1)) {
                  return null
                }
                return (
                  <Grid
                    item
                    key={index}
                    className={classNames(classes.stateLabel, showFilters.indexOf(objState.slug) !== -1 ? classes.selected : {})}
                    onClick={() => this.handleOnClickFilter(objState.slug, index)}
                  >
                    {objState.slug === 'procurement_owner' && <Typography className={classes.stateLabelText}>
                      {this.state.columnNameMap && this.state.columnNameMap['projectOwnerID'] !== undefined ? this.state.columnNameMap['projectOwnerID'] + ' +' : objState.label + ' +'}
                    </Typography>}

                    {objState.slug !== 'procurement_owner' && <Typography className={classes.stateLabelText}>
                      {objState.label} +
                    </Typography>}
                  </Grid>
                );
              })}
              <Grid item key={'clearFilter'}>
                <Button className={classes.clearFilterBtn} variant="outlined" color="secondary" onClick={() => { this.clearFilter() }}>
                  Clear Filter
                </Button>
              </Grid>
            </Grid>

          </div>
          <TableContainer className={classes.tableContainer} style={{ maxHeight: this.props.tableMaxHeight }}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  {columns && columns !== null && columns.map((headCell) => {
                    if (!headCell.isVisible) {
                      return null;
                    }
                    return (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        style={{ whiteSpace: "nowrap" }}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.isSortRequire && (
                          <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={this.createSortHandler(headCell.id)}>
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null} */}
                          </TableSortLabel>
                        )}

                        {!headCell.isSortRequire && <span>{headCell.label}</span>}
                      </TableCell>
                    );
                  })}
                  {this.props.showActions && this.state.activeTab !== 3 && adminFlag && (
                    <TableCell>Action</TableCell>
                  )}
                  {this.props.showActions && this.state.activeTab === 1 && this.props.userType === "OrgUser" && (
                    <TableCell>Action</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!this.props.isLoading &&
                  rows.length > 0 &&
                  newRows.map((row, index) => {
                    // console.log(row, 'RowDataaaaaaaaaaaa')
                    const isItemSelected = this.isSelected(row.name);
                    // const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name + index}
                        selected={isItemSelected}
                        style={{ cursor: "pointer" }}
                      >
                        {columns && columns !== null && columns.map((col) => {
                          if (col.isVisible) {
                            if (col.id === 'requestStatus') {
                              return <TableCell onClick={(event) => {
                                this.routeChange(row)
                              }} align={col.align}>{RequestStatusMap[row[col.id]]}</TableCell>;
                            } else if (col.type === 2 && row[col.id]) {
                              return <TableCell align={col.align}>{CommonFn.formateDate(row[col.id], true)}</TableCell>;
                            }
                            else if (col.id === 'projectOwnerID') {
                              return <TableCell align={col.align}>{row[col.id]}
                                {!row.isEditOwner && <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span>{row.owner?.label}</span>
                                  {adminFlag && (row.owner === '' || row.owner === null) && <Image
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.editOwner(index)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    alt="Edit"
                                    src={addUsers}
                                  />}

                                  {adminFlag && row.owner !== '' && row.owner !== null &&
                                    <IconButton
                                      style={{ marginLeft: 8, cursor: 'pointer' }}
                                      ref={this.btnRef} onClick={(e) => {
                                        e.preventDefault();
                                        this.editOwner(index)
                                      }}><EditIcon className={classes.editIconBtn} /></IconButton>}
                                </div>
                                }

                                {row.isEditOwner && <div>
                                  <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
                                    <SelectAsyncPaginate
                                      isClearable={true}
                                      isMulti={false}
                                      isSearchable={true}
                                      value={row.owner}
                                      menuPosition='fixed'
                                      classNamePrefix="select"
                                      isLoading={this.props.userSearchProgress}
                                      placeholder="Search User"
                                      additional={{
                                        page: 0,
                                      }}
                                      loadOptions={this.getOptions}
                                      onChange={(e) => {
                                        this.updateOwner(row.id, e, index)
                                      }}
                                    />
                                  </FormControl>
                                </div>}
                              </TableCell>;
                            }
                            else if (col.id === 'requester_name') {
                              return <TableCell align={col.align}>
                                {!row.isEditRequester && <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span>{row.requester_name?.label}</span>
                                  {adminFlag && (row.requester_name === '' || row.requester_name === null) && <Image
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.editRequester(index)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    alt="Edit"
                                    src={addUsers}
                                  />}

                                  {adminFlag && row.requester_name !== '' && row.requester_name !== null &&
                                    <IconButton
                                      style={{ marginLeft: 8, cursor: 'pointer' }}
                                      ref={this.btnRef} onClick={(e) => {
                                        e.preventDefault();
                                        this.editRequester(index)
                                      }}><EditIcon className={classes.editIconBtn} /></IconButton>}
                                </div>
                                }

                                {row.isEditRequester && <div>
                                  <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
                                    <SelectAsyncPaginate
                                      isClearable={true}
                                      isMulti={false}
                                      isSearchable={true}
                                      value={row.requester_name}
                                      menuPosition='fixed'
                                      classNamePrefix="select"
                                      isLoading={this.props.userSearchProgress}
                                      placeholder="Search User"
                                      additional={{
                                        page: 0,
                                      }}
                                      loadOptions={this.getOptions}
                                      onChange={(e) => {
                                        this.updateRequester(row.id, e, index)
                                      }}
                                    />
                                  </FormControl>
                                </div>}
                              </TableCell>;
                            }
                            return <TableCell onClick={(event) => {
                              this.routeChange(row)
                            }} align={col.align}>{row[col.id]}</TableCell>;
                          } else {
                            return null;
                          }
                        })}
                        {adminFlag && this.state.activeTab !== 3 &&
                          <TableCell>
                            <IconButton ref={this.btnRef}
                              className={classes.threeDot}
                              onClick={(e) => this.handleClick(e, row)}><MoreVertIcon />
                            </IconButton>
                          </TableCell>}

                        {this.props.userType === "OrgUser" && this.state.activeTab === 1 &&
                          <TableCell>
                            <IconButton ref={this.btnRef}
                              className={classes.threeDot}
                              onClick={(e) => this.handleClick(e, row)}><MoreVertIcon />
                            </IconButton>
                          </TableCell>}

                      </TableRow>
                    );
                  })}

                {this.props.isLoading && (
                  <TableRow>
                    <TableCell colSpan={columns ? columns.length : 0} style={{ textAlign: "center" }} className={classes.nameCol}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {!this.props.isLoading && rows.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={columns.length} style={{ textAlign: "center" }} className={classes.nameCol}>
                      No Items
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 50, 100]}
            component="div"
            count={totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
          {this.props.isOpenAddFormColumnsDialog && <FormColumnsDialog
            activeTab={this.state.activeTab}
            setFormColumns={this.setFormColumns}
          />}
        </Paper>

        {this.state.currentColumn && <Popover
          className={classes.popover}
          id={'add-vendor'}
          open={openAction}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}>

          <List
          // className={this.state.currentColumn?.requestStatus !== "Draft" ? classes.disabledMenu : ''}
          >
            {this.state.currentColumn?.statusValue === 5 && <ListItem
              button
              onClick={() => {
                this.props.reOpenProject(this.state.currentColumn);
                this.handleClose();
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <LockOpenIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Reopen
              </Typography>
            </ListItem>}
            {this.state.currentColumn?.statusValue === 4 && <ListItem
              button
              onClick={() => {
                this.props.undoProject(this.state.currentColumn);
                this.handleClose();
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <LockOpenIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Reopen
              </Typography>
            </ListItem>}
            <ListItem
              button
              onClick={() => {
                this.routeChange(this.state.currentColumn);
                this.handleClose();
              }}
              className={this.state.currentColumn?.requestStatus !== "Draft" ? classes.disabled : ''}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Edit
              </Typography>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.props.onDelete(this.state.currentColumn?.id);
                this.handleClose();
              }}
              className={this.state.currentColumn?.requestStatus !== "Draft" ? classes.disabled : ''}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {/* <Image src={deleteIcon} alt="delete Image" className={classes.deleteImage} /> */}
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Delete
              </Typography>
            </ListItem>
          </List>
        </Popover>}

        {this.state.isShowSubMenu && (
          <Popover
            open={this.state.isShowSubMenu}
            anchorEl={this.state.subMenuEl}
            onClose={this.handleCloseSubMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ marginTop: 10 }}>Select Visible Column</Typography>
                <div className={classes.buttonContainer}>
                  {this.props.showFormColumns && adminFlag && (
                    <SqureButton variant={"outlined"} onClick={this.props.onShowFormColumns}>
                      Add Form Fields
                    </SqureButton>
                  )}

                  {this.state.activeTab === 3 && (
                    <SqureButton variant={"outlined"} onClick={this.redirectToForm}>
                      Edit Form Fields
                    </SqureButton>
                  )}
                </div>
              </div>
              {colVisibilityData !== null && (
                <DragDropContext onDragEnd={this.onColumnsDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <List>
                          {colVisibilityData.map((col, k) => (
                            <Draggable key={String(k)} draggableId={String(k)} index={k}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <ListItem button style={{ cursor: 'grab' }}>
                                    <DragIndicatorOutlinedIcon id={k} style={{ color: '#D9D9D9', marginRight: 5 }} />
                                    {/* <Typography variant="inherit" style={{ paddingRight: 10 }}>{col.label}</Typography> */}
                                    {/* {!col.isDynamic && ( */}
                                    {col.id === "projectOwnerID" && <>
                                      {!this.state.isEditProcurementOwner && <><Typography
                                        variant="inherit"
                                        style={{ paddingRight: 10 }}
                                      >
                                        {col.label}
                                      </Typography>
                                        <EditIcon onClick={() => {
                                          this.setState({
                                            procurementOwnerText: col.label,
                                            isEditProcurementOwner: true
                                          })
                                        }} />
                                      </>}

                                      {this.state.isEditProcurementOwner && <>
                                        <input
                                          style={{ padding: 5, borderRadius: 4 }}
                                          value={this.state.procurementOwnerText} onChange={(e) => {
                                            this.setState({
                                              procurementOwnerText: e.target.value
                                            })
                                          }} />
                                        <DoneIcon onClick={() => {
                                          this.updateColumnName(k)
                                        }} />
                                      </>
                                      }

                                    </>}

                                    {col.id !== "projectOwnerID" && <Typography variant="inherit" style={{ paddingRight: 10 }}>{col.label}</Typography>}

                                    <ListItemSecondaryAction>
                                      <Switch
                                        checked={col.isVisible}
                                        onChange={(event) => {
                                          this.updateColVisibilityStatus(col.isVisible, k);
                                        }}
                                        color="primary"
                                        name="is_editable"
                                        inputProps={{ "aria-label": "is_editable checkbox" }}
                                      />
                                    </ListItemSecondaryAction>
                                    {/* )} */}
                                  </ListItem>
                                  <Divider />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </List>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

              <div className={classes.bottomActions}>
                {
                  <SqureButton
                    style={{ marginLeft: "10px" }}
                    variant={"outlined"}
                    onClick={() => {
                      this.handleCloseSubMenu();
                    }}
                  >
                    Cancel
                  </SqureButton>
                }

                {
                  <SqureButton
                    style={{ marginLeft: "10px" }}
                    variant={"contained"}
                    onClick={() => {
                      this.setVisibilityForColumns();
                    }}
                  >
                    Confirm
                  </SqureButton>
                }
              </div>
            </Paper>
          </Popover>
        )}

        {this.state.isShowProjectConfigPopover && (
          <Popover
            open={this.state.isShowProjectConfigPopover}
            anchorEl={this.state.projectTrackingEl}
            onClose={this.handleCloseSubMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography>Project Tracking Configuration</Typography>
              </div>

              <div className={classes.bodyConfig}>
                <Typography style={{ fontSize: 14 }}>Select Form</Typography>
                <ReactSelect
                  isClearable
                  disabled={this.props.workflowStatus === 2 ? true : false}
                  isSearchable={true}
                  menuPosition='fixed'
                  options={this.props.orgForms && this.props.orgForms.map((item) => ({ label: item.FormTitle, value: item.ID }))}
                  classNamePrefix="select"
                  placeholder="Search Form"
                  onChange={(e) => {
                    // console.log(e, 'Formmmmmmmmmm')
                    this.setState({
                      trackFormConfig: e
                    })
                  }}
                  value={trackFormConfig}
                />
              </div>

              <div className={classes.bottomActions}>
                {
                  <SqureButton
                    style={{ marginLeft: "10px" }}
                    variant={"outlined"}
                    onClick={() => {
                      this.handleCloseSubMenu();
                    }}
                  >
                    Cancel
                  </SqureButton>
                }


                {
                  <SqureButton
                    style={{ marginLeft: "10px" }}
                    variant={"contained"}
                    onClick={() => {
                      this.updateTrackingConfiguration();
                    }}
                  >
                    Update
                  </SqureButton>
                }
              </div>
            </Paper>
          </Popover>
        )}
      </div>
    );
  }
}

ListingTable.defaultProps = {
  isShowSearch: false,
  searchPlaceHolder: "Project Name",
  page: 0,
  rowsPerPage: 20,
  query: "",
  activeTab: 1,
  tableMaxHeight: "50vh",
  isGlobalSortRequire: false,
  selectedColumns: [],
};

export default connector(compose(withRouter, withStyles(styles))(ListingTable));