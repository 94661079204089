import { createStyles, withStyles } from "@material-ui/styles";
import CommonCss from "commonCss";
import { connect } from "react-redux";
import React, { Component } from "react";
import CustomItem from "./CustomItem";
import * as Validator from "util/Validation";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
// import DefaultItem from "./DefaultItem";

const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
});

const connectionActions = {
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    ...CommonCss.ProductDetails,
    root: {
      border: "1px solid #d8d8d8",
      padding: 17,
      borderRadius: 5,
      background: "#FFF",
      marginBottom: 17,
      position: "relative",
    },
    positionRelative: {
      position: "relative",
    },
    threeDot: {
      position: "absolute",
      zIndex: 11,
      right: 5,
      top: 5,
      padding: 5,
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    menuList: {
      padding: "0px",
      "& > hr": {
        "&:first-child": {
          display: "none",
        },
      },
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "50px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff",
        },
      },
    },
    head: {
      color: "#6C6C6C",
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11,
    },
  });

class CustomSection extends Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  scrollContent = (e) => {
    e.persist();
  };

  render() {
    const { section, catalogType, appId, hasUpdateAccess } = this.props;
    let items = [];
    if (section && section.items && section.items.length) {
      items = section.items;
    }

    return (
        <div onScroll={(e) => this.scrollContent(e)}>
          {items.map((item) => (
            <CustomItem
              type={item.type}
              index={item.id}
              key={item.id}
              title={item.name}
              additionalData={Validator.isJson(item.additional_data) ? JSON.parse(item.additional_data) : item.additional_data ? item.additional_data : {}}
              itemId={item.id}
              slug={item.slug}
              appId={appId}
              hasUpdateAccess={hasUpdateAccess}
              sectionName={section.name}
              sectionId={section.id}
              item={item}
              catalogType={catalogType}
              appDetails={this.props.appDetails}
            />
          ))}
        </div>
    );
  }
}

export default React.memo(connector(withStyles(styles)(CustomSection)));
