import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { getMessage } from "util/Notification";
import Logo from "assets/images/logo/logo.svg";

import {
  Typography, Avatar
} from "@material-ui/core";

const connectedProps = (state) => ({
  user: state.authUser.user,
  toastNotification: state.notifications.toastNotification,
  toastNotificationCount: state.notifications.toastNotificationCount,
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
});

class NotificationToast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
    this.avatarName = this.avatarName.bind(this);

  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    var me = this;
    if (this.props.toastNotificationCount !== prevProps.toastNotificationCount ) {
        me.checkNotificationMsg()
    }
  }

  checkNotificationMsg(){
    const {toastNotification,user} = this.props;
    var obj = {
      info: getMessage(toastNotification,user),
      id: toastNotification?.id,
      data: toastNotification?.data,
      readAt: toastNotification?.readAt,
      timestamp: toastNotification?.timestamp
    }
    this.notify(obj);
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  notify(item) {
    const classes = this.props;
    toast(
      <div className={classes.notificationItem} id={111}>
        <div className={classes.notificationCard} style={{display: 'flex'}}>
          <div className={classes.userImage} style={{marginRight: 20,"& [class*=MuiAvatar-root]": {width: 30,height: 30,fontSize: 14,marginRight: 20,background: '#1f73b7',},"& [class*=MuiAvatarGroup-avatar]": {margin: 0,},}}>
          {item.info !== undefined && item.info !== null && item.info.user !== undefined && item.info.user !== null && <Avatar key={1212} title={'Sunil'}>
              {" "}
              {this.avatarName(item.info.user.name)}
            </Avatar>}

            {(item.info === undefined || item.info === null || item.info.user === undefined || item.info.user === null) && <img src={Logo} alt="logo" className={classes.logoImage} style={
              {width: 25,height: 25,fontSize: 12,marginRight: 20,background: '#f7f7f7',borderRadius: '50%', padding: 5,}
            }/>}

          </div>
          <div className={classes.msgContainer} style={{flex: 7}}>
            <Typography className={classes.msgText} style={{
              width: 'auto',overflowWrap: 'break-word',wordWrap: 'break-word',hyphens: 'auto',fontSize:14,}}>
              {item.info.message}
            </Typography>
          </div>
        </div>
      </div>,
      {
        duration: 4000,
        position: 'top-right',
        style: {
          background : '#fff',
          color:'#222',
          border:'1px solid #1f73b7',
          width:400,
          minWidth:400
        },
        className: '',
        // icon: '👏',
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
  }


  render() {
    return (
      <div>
        <Toaster />
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(NotificationToast));
