import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "chartjs-adapter-moment";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
import PieChart from "../Charts/PieChart";
import DoughnutChart from "../Charts/DoughnutChart";
import { connect } from "react-redux";
import { compose } from "recompose";
import { getVisualizationChartData } from "redux/visualization/action";
import * as Validator from "util/Validation";
import { generateColor } from "util/ChartConfig";
import DataCard from "./DataCard";
import HeadingCard from "./HeadingCard";
import LinkCard from "./LinkCard";
import QuickAccessCard from "./QuickAccessCard";
import DataTable from "./DataTable";
import { isEnabledEvaluate } from "util/Common";
import { fetchVendors } from "redux/vendor/action";
import { showEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
import { fetchVendorCatalog } from "redux/vendor/vendorCatalog/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import FilterSearch from "./FilterSearch";
import Vendors from "../TabularViews/Vendors";
import Projects from "../TabularViews/Projects";
import Renewals from "../TabularViews/Renewals";

const styles = (theme) =>
  createStyles({
    loader: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    flexWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 25,
      flexWrap: "wrap",
    },
  });

const connectedProps = (state) => ({
  visualizationChartDataMap: state.visualization.visualizationChartDataMap,
  updateVisualizationProgress: state.visualization.updateVisualizationProgress,
  fetchVisualizationChartDataProgress: state.visualization.fetchVisualizationChartDataProgress,
  expentDirectoryProducts: state.expentDirectory.products,
  VendorCatalog: state.vendorCatalog.VendorCatalog,
  vendors: state.vendors.vendorsData,
  orgUser: state.orgUser,
});

const connectionActions = {
  getVisualizationChartData: getVisualizationChartData,
  fetchVendorCatalog: fetchVendorCatalog,
  fetchVendors: fetchVendors,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  showEvaluationProductSelectionDialog: showEvaluationProductSelectionDialog,
};

var connector = connect(connectedProps, connectionActions);
const AccessLinks = ["Start a new project", "Vendor catalog", "Expent vendor directory", "Activity"];

class ChartsRenderer extends Component {
  constructor(props) {
    super();
    this.state = {
      appliedFilters: [],
    };
  }

  componentDidMount() {
    this.initVisualizationChart(this.props.visualizationId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.visualizationId !== prevProps.visualizationId) {
      this.initVisualizationChart(this.props.visualizationId);
    }
  }

  initVisualizationChart = (id) => {
    if (id && !["heading", "catalog", "renewal", "projects"].includes(id)) {
      this.props.fetchVendorCatalog({ query: "", page: 0, page_size: 6, filters: { is_org: "1" } });
      this.props.fetchVendors({
        query: "",
        page_size: 6,
        page: 0,
      });
      this.props.fetchExpentDirectoryProducts({
        field_setname: "all",
        text: "",
        filter_fields: {},
        page: 0,
        page_size: 6,
        sort_fields: [],
      });
      this.props.getVisualizationChartData(id);
    }
  };
  render() {
    const { classes, visualizationChartDataMap, updateVisualizationProgress, fetchVisualizationChartDataProgress } = this.props;

    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "DisableCatalogFeature");
    // let existingProductCount = this.props.expentDirectoryProducts?.existing?.total || 0;
    // let otherVendorCount = this.props.vendors?.total || 0;
    // let otherProductCount = this.props.expentDirectoryProducts?.others?.total || 0;

    if (fetchVisualizationChartDataProgress || updateVisualizationProgress) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    if (!this.props.visualizationId) {
      return <div>Failed to load visualization</div>;
    } else if (this.props.visualizationId === "catalog") {
      return <Vendors Vid={this.props.card?.dataGrid?.i} defaultSelectedView={this.props.card?.selectedView} key={this.props.card?.dataGrid?.i} />;
    } else if (this.props.visualizationId === "renewal") {
      return <Renewals Vid={this.props.card?.dataGrid?.i} defaultSelectedView={this.props.card?.selectedView} key={this.props.card?.dataGrid?.i} />;
    } else if (this.props.visualizationId === "projects") {
      if (this.props.card?.projectType?.value === "all_projects" || this.props.card?.projectType?.value === "project_tracking") {
        return (
          <Projects
            key={this.props.card?.dataGrid?.i}
            Vid={this.props.card?.dataGrid?.i}
            activeTab={this.props.card?.projectType?.value === "all_projects" ? 0 : 3}
            defaultSelectedView={this.props.card?.selectedView}
          />
        );
      }
    } else if (this.props.visualizationId === "heading") {
      return <HeadingCard title={this.props.card?.actionCardTitle} />;
    }

    let id = this.props.visualizationId;
    let dataObject = visualizationChartDataMap[id];

    if (dataObject?.config) {
      let config = dataObject?.config?.configuration;
      let datasetConfig = [];
      let tableColumns = [];
      let filters = [];
      let chartType;
      let chartConfig = {};
      if (Validator.isJson(config)) {
        config = JSON.parse(config);
        chartType = config.chartType;
        chartConfig = config.configurations;
        datasetConfig = config.datasets;
        tableColumns = config.columns || [];
        filters = config.filters || [];
      }

      let labels = [];
      if (dataObject?.data) {
        dataObject.data?.forEach((dataset) => {
          dataset.forEach((item) => {
            if (item.axisType === "x") {
              labels.push(item.value);
            }
            if (item.axisType === "y") {
              let index = datasetConfig.findIndex((o) => o.uid === item.uid);
              if (index > -1) {
                datasetConfig[index].data.push(item.value);
              }
            }
          });
        });
      } else if (chartType !== "LINKCARD") {
        return <div></div>;
      }

      if (chartType === "PIE" || chartType === "DOUGHNUT") {
        datasetConfig = datasetConfig.map((item) => ({
          ...item,
          backgroundColor: generateColor(labels.length),
        }));
      }

      const chartdata = {
        labels: labels,
        datasets: datasetConfig,
      };

      // formatter.format(value)
      var formatter = Intl.NumberFormat("en", { notation: "compact" });

      if (chartConfig?.scales?.y?.ticks?.transformExpression?.length > 0) {
        chartConfig["scales"]["y"]["ticks"]["callback"] = (value) => {
          try {
            return eval(chartConfig?.scales?.y?.ticks?.transformExpression);
          } catch (e) {
            return value;
          }
        };
      }

      if (chartConfig?.scales?.x?.ticks?.transformExpression?.length > 0) {
        chartConfig["scales"]["x"]["ticks"]["callback"] = (value) => {
          try {
            return eval(chartConfig?.scales?.x?.ticks?.transformExpression);
          } catch (e) {
            return value;
          }
        };
      }

      if (chartConfig?.plugins?.datalabels?.transformExpression?.length > 0) {
        chartConfig["plugins"]["datalabels"]["formatter"] = (value, context) => {
          try {
            return eval(chartConfig?.plugins?.datalabels?.transformExpression);
          } catch (e) {
            return value;
          }
        };
      }

      let options = {
        ...chartConfig,
      };

      return (
        <>
          {!["DATACARD", "LINKCARD"].includes(chartType) && (
            <div className={classes.flexWrapper}>
              {filters?.map((filter) => {
                if (!filter.value) return null;
                return (
                  <FilterSearch
                    type={filter.type}
                    title={filter.title}
                    applyFilters={(value, operand) => {
                      let appliedFilters = this.state.appliedFilters || [];
                      let index = appliedFilters.findIndex((O) => O.uid === filter.uid);
                      if (index !== -1) {
                        if (value) {
                          appliedFilters[index] = { ...filter, value: value, operand: operand };
                        } else {
                          appliedFilters.splice(index, 1);
                        }
                      } else {
                        appliedFilters.push({ ...filter, value: value, operand: operand });
                      }
                      this.setState({ appliedFilters: appliedFilters });
                    }}
                  />
                );
              })}
            </div>
          )}
          <ChartType
            height={this.props.height}
            chartType={chartType}
            chartdata={chartdata}
            options={options}
            config={config}
            tableColumns={tableColumns}
            dataObject={dataObject}
            chartConfig={chartConfig}
            labels={labels}
            DisableVendor={DisableVendor}
            {...this.props}
          />
        </>
      );
    } else {
      return <div></div>;
    }
  }
}

export default connector(compose(withRouter, withStyles(styles))(ChartsRenderer));

const ChartType = ({ chartType, chartdata, options, config, tableColumns, dataObject, chartConfig, labels, height, DisableVendor, ...props }) => {
  let renderChart = null;
  if (chartType === "LINE") {
    renderChart = <LineChart chartdata={chartdata} options={options} />;
  } else if (chartType === "DATACARD") {
    renderChart = <DataCard title={config?.dataCardTitle} color={config?.dataCardColor} value={labels[0]} />;
  } else if (chartType === "LINKCARD") {
    if (AccessLinks.includes(config?.dataCardType)) {
      if (config?.dataCardType === "Start a new project") {
        renderChart = (
          <QuickAccessCard
            color={config?.dataCardColor}
            isButton={config?.dataCardIsButton}
            iconName={config?.dataCardIcon}
            subTitle={config?.dataCardSubTitle}
            title={config?.dataCardTitle?.length > 0 ? config?.dataCardTitle : "Start a new project"}
            onClickHandler={() => {
              props.showEvaluationProductSelectionDialog();
            }}
          />
        );
      } else if (config?.dataCardType === "Vendor catalog") {
        renderChart = (
          <QuickAccessCard
            color={config?.dataCardColor}
            subTitle={config?.dataCardSubTitle}
            isButton={config?.dataCardIsButton}
            iconName={config?.dataCardIcon}
            title={config?.dataCardTitle?.length > 0 ? config?.dataCardTitle : "Vendor Catalog"}
            onClickHandler={() => {
              if (DisableVendor) {
                props.history.push("/app/products/org-products");
              } else {
                props.history.push("/app/vendors/org-vendors");
              }
            }}
          />
        );
      } else if (config?.dataCardType === "Expent vendor directory") {
        renderChart = (
          <QuickAccessCard
            color={config?.dataCardColor}
            subTitle={config?.dataCardSubTitle}
            isButton={config?.dataCardIsButton}
            iconName={config?.dataCardIcon}
            title={config?.dataCardTitle?.length > 0 ? config?.dataCardTitle : "Expent Vendor Directory"}
            onClickHandler={() => {
              if (DisableVendor) {
                props.history.push("/app/products/all-products");
              } else {
                props.history.push("/app/vendors/all-vendors");
              }
            }}
          />
        );
      } else if (config?.dataCardType === "Activity") {
        renderChart = (
          <QuickAccessCard
            color={config?.dataCardColor}
            subTitle={config?.dataCardSubTitle}
            isButton={config?.dataCardIsButton}
            iconName={config?.dataCardIcon}
            title={config?.dataCardTitle?.length > 0 ? config?.dataCardTitle : "Activity"}
            onClickHandler={() => {
              props.history.push("/app/workflows_listing");
            }}
          />
        );
      }
    } else {
      renderChart = (
        <LinkCard
          isButton={config?.dataCardIsButton}
          title={config?.dataCardTitle}
          subTitle={config?.dataCardSubTitle}
          color={config?.dataCardColor}
          link={config?.dataCardLink}
          iconName={config?.dataCardIcon}
        />
      );
    }
  } else if (chartType === "BAR") {
    renderChart = <BarChart chartdata={chartdata} options={options} height={height} />;
  } else if (chartType === "PIE") {
    renderChart = <PieChart chartdata={chartdata} options={options} />;
  } else if (chartType === "DOUGHNUT") {
    renderChart = <DoughnutChart chartdata={chartdata} options={options} />;
  } else if (chartType === "TABLE") {
    renderChart = <DataTable tableColumns={tableColumns} data={dataObject?.data || []} config={chartConfig} />;
  }
  return <div style={{ flexGrow: 1 }}>{renderChart}</div>;
};
