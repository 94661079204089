import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { hideShowOverallUserProgressDialog } from "../../../../../../../../redux/evaluation/feedback/action"
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { Avatar } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaluationFeedback.showOverallUserWiseProgressDialog,
    evaluation: state.evaluationMetaData.evaluation,
    productsScore: state.evaludationCriteria.productsScore,
    selectedProductId: state.evaludationCriteria.selectedProductId,
    user: state.authUser.user,
});

const connectionActions = {
    hideDialog: hideShowOverallUserProgressDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions: {
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        minWidth: '200px'
    },
    actionBtnDisabled: {
        minWidth: '200px',
    },
    dialogContent: {
        margin: 'auto',
        marginBottom: 60,
        "& [class*=MuiAvatar-root]": {
            width: 30,
            height: 30,
            fontSize: 14
          },
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        textAlign: "center",
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px 0px'
    },
    overallDetails: {
        display: 'flex',
        margin: '10px 0'
      },
      avatarImg: {
        marginRight: 10
      },
      progTextComp: {
        margin: theme.spacing(1, 0),
        fontSize: theme.spacing(1.6)
      },
      totalAnswered: {
        flex: 1,
        textAlign: 'right',
        margin: theme.spacing(1, 0),
        fontSize: theme.spacing(1.6)
      },
      userProgress:{
        border: '1px solid #f1f1f1',
        padding: '5px',
        marginTop: 15,
        boxShadow: '0px 1px 20px -6px rgb(157 133 133 / 77%)',
        borderRadius: theme.spacing(1),
      }
});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#2EB77D',
    },
  }))(LinearProgress);

class OverallUserWiseProgressDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            feedback: "",
            feedback_error: "",
            products: []
        }
        this.avatarName = this.avatarName.bind(this);
    }

    avatarName(name) {
        return name.substring(0, 1);
    }

    componentDidUpdate(prevProps) {
    }


    render() {
        const classes = this.props.classes;

        var productScoreMap = {};
        var prScores = this.props.productsScore !== null ? this.props.productsScore : [];
        prScores.forEach(function (product) {
            productScoreMap[product.ID] = product.Stats;
        });

        var overallProgress = productScoreMap[this.props.selectedProductId]?.AllUserProgress;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>

                <div className={classes.form}>
                    <Typography variant={"h4"} className={classes.title}>Overall Progress</Typography>

                    {overallProgress !== undefined && overallProgress.length > 1 && overallProgress.map((productProgress, k) => {
                        return <>
                            <div className={classes.userProgress}>
                            <Typography>
                                <div className={classes.overallDetails}>
                                    <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{productProgress?.User?.Name + "\n" + productProgress?.User?.Email}</span>} arrow>
                                        <Avatar className={classes.avatarImg}> {this.avatarName(productProgress?.User?.Name)}</Avatar></Tooltip>
                                    <p className={classes.progTextComp}>{productProgress?.Completed === 0 ? 0 : Math.round(productProgress?.Completed * 100 / productProgress?.Total)}% Completed</p>
                                    <span className={classes.totalAnswered}>{productProgress?.Completed}/{productProgress?.Total}</span>
                                </div>
                            </Typography>
                            <BorderLinearProgress variant="determinate" value={productProgress?.Completed === 0 ? 0 : Math.round(productProgress?.Completed * 100 / productProgress?.Total)} />
                            </div>
                        </>
                    })}

                </div>
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(OverallUserWiseProgressDialog));