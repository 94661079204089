import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import Alert from '@material-ui/lab/Alert';

import { FormControl, Select, MenuItem } from '@material-ui/core';
import SqureButton from 'Components/Common/SqureButton';
import OutlinedInput from "Components/Common/Input/OutlinedInput";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
});

const connectionActions = {
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 20
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    body: {
        marginTop: 20,
        marginBottom: 10
    },
    formControl: {
        width: '100%',
        marginBottom: '1.75em',
        '& [class*=MuiSelect-outlined]': {
            padding: '1rem'
        }
    },
    actionBtns: {
        textAlign: 'right'
    },
    required: {
        color: 'red'
    },
    alerts: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
});

class AddFieldDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            name: '',
            type: 'TEXT',
            isOptional: 'NO',
            hint: '',
            typeOptions: [{
                label: 'String',
                value: 'TEXT',
            }, {
                label: 'Number',
                value: 'NUMBER',
            }, {
                label: 'Date',
                value: 'DATE',
            }, {
                label: 'Boolean',
                value: 'BOOLEAN',
            }],
            optionalOptions: [{
                label: 'Yes',
                value: 'YES',
            }, {
                label: 'No',
                value: 'NO',
            }],
            binaryOptions: [
                {
                    label: 'Yes',
                    value: 'yes'
                },
                {
                    label: 'No',
                    value: 'no'
                }
            ]
        }
    }

    componentDidMount() {
        this.initData()

    }

    componentDidUpdate(prevProps) {

    }

    initData() {
        this.setState({
            error: "",
            success: "",
            name: '',
            type: 'TEXT',
            isOptional: 'NO',
            hint: '',
        })
    }

    clearError() {
        this.setState({
            error: "",
            success: "",
        })
    }

    isValidVariableName(variableName) {
        // Regular expression that matches a valid JavaScript variable name
        var variableRegex = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/;

        // Test if the string matches the regular expression
        return variableRegex.test(variableName);
    }

    addField() {
        const { name, type, isOptional, hint } = this.state;
        const { item } = this.props;
        this.clearError()

        if (name === '' || name.length <= 0) {
            this.setState({
                error: 'Please enter field name'
            })
            return;
        }

        if (item?.name === 'output') {
            let isValid = this.isValidVariableName(name);
            if (!isValid) {
                this.setState({
                    error: 'Field name should start with a letter and should not contain spaces or any special characters except `_`'
                })
                return;
            }
        }


        let data = {
            label: name,
            type: type,
            isOptional: isOptional,
            hint: hint,
            value: '',
            options: type === 'BOOLEAN' ? this.state.binaryOptions : ''
        }
        this.props.onSubmit(data)
    }

    render() {
        const classes = this.props.classes;
        const { error, success, name, type, hint, isOptional, typeOptions, optionalOptions } = this.state;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.head}>
                    <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                    <Typography variant={"h4"} className={classes.title}>Add new Field</Typography>
                </div>
                <div className={classes.body}>
                    <div className={classes.alerts}>
                        {error.length > 0 && <Alert variant="filled" severity="error">{error}</Alert>}
                        {success.length > 0 && <Alert variant="filled" severity="success">{success}</Alert>}
                    </div>
                    <Typography>Name <span className={classes.required}>*</span></Typography>
                    <OutlinedInput
                        id={"formTitleInput"}
                        placeholder={"Enter your field name"}
                        autoFocus={true}
                        value={name}
                        onChange={(event) => {
                            this.setState({
                                name: event.target.value
                            }, () => {
                                this.clearError();
                            })
                        }}
                    />

                    <Typography>Type <span className={classes.required}>*</span></Typography>

                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <Select value={type} onChange={(e) => {
                            this.setState({ type: e.target.value }, () => {
                                this.clearError();
                            })
                        }}>
                            {typeOptions.map((o) => {
                                return <MenuItem value={o.value} style={{ fontSize: 13 }}>{o.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <Typography>Optional <span className={classes.required}>*</span></Typography>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <Select value={isOptional} onChange={(e) => {
                            this.setState({ isOptional: e.target.value }, () => {
                                this.clearError();
                            })
                        }}>
                            {optionalOptions.map((o) => {
                                return <MenuItem value={o.value} style={{ fontSize: 13 }}>{o.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <Typography>Hint</Typography>
                    <OutlinedInput
                        id={"formTitleInput"}
                        placeholder={"Enter hint"}
                        autoFocus={true}
                        value={hint}
                        onChange={(event) => {
                            this.setState({
                                hint: event.target.value
                            }, () => {
                                this.clearError();
                            })
                        }}
                    />

                    <div className={classes.actionBtns}>
                        <SqureButton
                            cls={classes.actionBtn}
                            variant={"outlined"}
                            onClick={(e) => {
                                this.props.hideDialog()
                            }}
                        >
                            Cancel
                        </SqureButton> &nbsp;&nbsp;&nbsp;
                        <SqureButton
                            cls={classes.actionBtn}
                            variant={"contained"}
                            onClick={(e) => {
                                this.addField()
                            }}
                        >
                            Add Field
                        </SqureButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}

AddFieldDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddFieldDialog));