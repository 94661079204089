import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import ListWithFilter from "./ListWithFilter";
import Slack from "../../assets/images/slack.png";
import Zendesk from "../../assets/images/zendesk.png";
import Asana from "../../assets/images/asana.png";

const styles = () => createStyles({});

class ExploreProducts extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const classes = this.props.classes;
    const filters = [
      { label: "Category", list: [{ label: "Data" }, { label: "Data" }] },
      {
        label: "Pricing Options",
        list: [
          { label: "Free Trial" },
          { label: "Subscription" },
          { label: "Free Plan" },
        ],
      },
      {
        label: "Number of users",
        list: [
          { label: "0-250" },
          { label: "250-1000" },
          { label: "Greater than 1000" },
        ],
      },
      {
        label: "Platform",
        list: [
          { label: "Web" },
          { label: "Mobile" },
          { label: "Mac" },
          { label: "Windows" },
        ],
      },
      {
        label: "Deployment",
        list: [{ label: "Cloud" }, { label: "Self-Hosted" }],
      },
    ];
    const cardContent = [
      {
        primary: "Zendesk",
        secondary: "Customer support",
        image: Slack,
        activeImage: true,
        link: "",
      },
      {
        primary: "Slack",
        secondary: "Customer support",
        image: Zendesk,
        activeImage: true,
        link: "",
      },
      {
        primary: "Office 365",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Asana",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Zendesk",
        secondary: "Customer support",
        image: Slack,
        activeImage: true,
        link: "",
      },
      {
        primary: "Slack",
        secondary: "Customer support",
        image: Zendesk,
        activeImage: true,
        link: "",
      },
      {
        primary: "Office 365",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Asana",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Zendesk",
        secondary: "Customer support",
        image: Slack,
        activeImage: true,
        link: "",
      },
      {
        primary: "Slack",
        secondary: "Customer support",
        image: Zendesk,
        activeImage: true,
        link: "",
      },
      {
        primary: "Office 365",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Asana",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Zendesk",
        secondary: "Customer support",
        image: Slack,
        activeImage: true,
        link: "",
      },
      {
        primary: "Slack",
        secondary: "Customer support",
        image: Zendesk,
        activeImage: true,
        link: "",
      },
      {
        primary: "Office 365",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Asana",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Zendesk",
        secondary: "Customer support",
        image: Slack,
        activeImage: true,
        link: "",
      },
      {
        primary: "Slack",
        secondary: "Customer support",
        image: Zendesk,
        activeImage: true,
        link: "",
      },
      {
        primary: "Office 365",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Asana",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Zendesk",
        secondary: "Customer support",
        image: Slack,
        activeImage: true,
        link: "",
      },
      {
        primary: "Slack",
        secondary: "Customer support",
        image: Zendesk,
        activeImage: true,
        link: "",
      },
      {
        primary: "Office 365",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Asana",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Zendesk",
        secondary: "Customer support",
        image: Slack,
        activeImage: true,
        link: "",
      },
      {
        primary: "Slack",
        secondary: "Customer support",
        image: Zendesk,
        activeImage: true,
        link: "",
      },
      {
        primary: "Office 365",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
      {
        primary: "Asana",
        secondary: "Customer support",
        image: Asana,
        activeImage: true,
        link: "",
      },
    ];

    const props = {
      ...this.props,
      parentClass: classes,
      title: "Explore products",
      countLabel: "product",
      pluralCountLabel: "products",
      searchPlaceholder: "Search product",
      filters: filters,
      cardContent: cardContent,
    };
    return (
      <div>
        <ListWithFilter {...props} />
      </div>
    );
  }
}

export default compose(withRouter, withStyles(styles))(ExploreProducts);
