import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { Divider, Grid, List, ListItem } from '@material-ui/core';

import Image from 'Components/Common/image.jsx';
import arrowRight from 'assets/images/evaluation/arrowRight.svg';
import DefaultImage from "assets/images/evaluation/default.svg"

import SellerDetailsUpdate from '../common/SellerDetailsUpdate';
import { sellerDetailsPopupStateUpdate, productRFPMapFetch } from "redux/seller/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    evaluation: state.evaluationMetaData.evaluation,
    updateSellerStateProgress: state.seller.updateSellerStateProgress,
    updateSellerStateError: state.seller.updateSellerStateError,
    updateSellerStateSuccess: state.seller.updateSellerStateSuccess,
    productRFPMapProgress: state.seller.productRFPMapProgress,
    productRFPMapError: state.seller.productRFPMapError,
    productRFPMapSuccess: state.seller.productRFPMapSuccess,
});

const connectionActions = {
    sellerDetailsPopupStateUpdate: sellerDetailsPopupStateUpdate,
    productRFPMapFetch: productRFPMapFetch
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        // margin: 'auto',
        // marginBottom: 60
        padding: '0px !important'
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    body: {
        marginTop: 20
    },
    otherProTitle: {
        margin: theme.spacing(2, 0, 1, 0),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    otherItmBox: {
        // border: "1px solid #f1f1f1",
        padding: 0,
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: 'pointer'
    },
    productNameContainer: {
        flex: 0.9,
    },
    productImage: {
        width: '100%',
        height: 'auto',
        marginRight: theme.spacing(2)
    },
    productImageContainer: {
        width: 67,
        height: 57,
        padding: 8,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 6px #0000001A',
        borderRadius: 10
    },
    productNameTitle: {
        fontSize: theme.spacing(2),
    },
    productCompBox: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(1, 2)
    },
    productName: {
        // fontWeight: 600,
        marginLeft: theme.spacing(1),
        wordBreak: 'break-all',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: '#398AF5',
        whiteSpace: 'nowrap'
    },
    productNameOri: {
        fontWeight: 600,
        marginLeft: theme.spacing(1),
        wordBreak: 'break-all',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    productCompletion: {
        fontWeight: 600,
        color: '#282D30',
        opacity: '0.6',
        wordBreak: 'break-all',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    nextProductBtn: {
        textAlign: 'right',
        flex: 1
    },
    cartItem: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        padding: 12,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between'
    },

    topBar: {
        padding: 15,
        background: '#fff',
        boxShadow: 'none',
        borderBottom: '2px solid #f1f1f1',
    },
    productContainer: {
        minHeight: '90vh',
        overflow: 'scroll',
        borderLeft: '2px solid #f7f7f7',
        padding: 20
    },
    sellerUpdateContainer: {
        padding: 20
    },
});

class AskSellerDetailsUpdateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            products: [],
            selectedProductIndex: 999999,
            selectedProduct: '',
            productMap: {},
            productIdIndexMap: {},
            isCheckCloseConditions: false
        }
    }

    componentDidMount() {
        console.log(this.props.evaluation, 'Evaluationnnnnnnnnnnnn')
        const { evaluation } = this.props;
        this.props.productRFPMapFetch(this.props.match.params.evaluation_id)

        if (evaluation && evaluation.Products) {
            let productMap = {}
            let productIdIndexMap = {}
            evaluation.Products.forEach(function (prd, k) {
                productMap[k] = prd;
                productIdIndexMap[prd.ID] = k;
            })

            this.setState({
                products: evaluation?.Products,
                selectedProductIndex: 0,
                selectedProduct: evaluation?.Products[0],
                productMap: productMap,
                productIdIndexMap: productIdIndexMap
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.productRFPMapProgress && prevProps.productRFPMapProgress) {
            if (this.props.productRFPMapError === null) {
                this.checkCloseConditions();
            }
        }

        if (!this.props.updateSellerStateProgress && prevProps.updateSellerStateProgress) {
            if (this.props.updateSellerStateError === null) {
                window.location.reload()
                // this.props.hideDialog()
            }
        }
    }

    closeDialog() {
        // this.props.hideDialog()
        this.props.sellerDetailsPopupStateUpdate(this.props.match.params.evaluation_id, {
            askSellerPopUp: true
        })
    }

    checkCloseConditions() {
        if (this.state.isCheckCloseConditions) {
            const { products, productIdIndexMap, productMap } = this.state;
            const { productRFPMapSuccess } = this.props;
            // if (products.length === 1 && productRFPMapSuccess[products[0].ID.toString()] !== undefined ) {
            //     this.closeDialog()
            // } else 
            if (products.length === Object.keys(productRFPMapSuccess).length) {
                this.closeDialog()
            }

            let notCompletedProductId = null;
            products.forEach(function (product) {
                let productId = (product.ID).toString()
                if (productRFPMapSuccess[productId] === undefined && notCompletedProductId === null) {
                    notCompletedProductId = productId;
                }
            })

            if (notCompletedProductId !== null) {
                let index = productIdIndexMap[parseInt(notCompletedProductId)];
                this.setState({
                    selectedProductIndex: index,
                    selectedProduct: productMap[index]
                })
            }
        } else {
            this.setState({
                isCheckCloseConditions: true
            })
        }

    }

    render() {
        const classes = this.props.classes;
        const { products, selectedProductIndex, productMap, selectedProduct } = this.state;
        const { productRFPMapSuccess } = this.props;
        // console.log(productRFPMapSuccess, 'productRFPMapSuccess')
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            fullScreen
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.topBar}>
                    <Typography variant={"h4"} className={classes.title}>Ask Vendor</Typography>
                </div>

                <Grid container className={classes.contentContainer}>
                    <Grid item xs={9} className={classes.reqContainer}>
                        <div className={classes.sellerUpdateContainer}>
                            <Typography variant={"h6"} className={classes.otherProTitle}>
                                Vendor Details
                            </Typography>
                            <SellerDetailsUpdate
                                product={selectedProduct}
                                vendorNameField={this.props.vendorNameField}
                                vendorEmailField={this.props.vendorEmailField}
                                vendorDeadline={this.props.vendorDeadline}
                                evaluation={this.props.evaluation}
                                selectedProductIndex={selectedProductIndex}
                                onClose={() => {
                                    this.closeDialog()
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3} >
                        <div className={classes.productContainer}>
                            {<div className={classes.body}>
                                <Typography variant={"h6"} className={classes.otherProTitle}>
                                    Current Product
                                </Typography>
                                <List component="nav" className={classes.nav}>
                                    {products.map((product, k) => {
                                        if (k !== selectedProductIndex) {
                                            return null
                                        }
                                        return <ListItem className={classes.otherItmBox} key={k + product.ID + '-evProducts'} >
                                            <Grid container alignItems="center" className={classes.cartItem} key={k + '1'}>
                                                <Grid item className={classes.productImageContainer} onClick={() => {
                                                }}>
                                                    <Image alt="product" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImage} />
                                                </Grid>
                                                <Grid item className={classes.productNameContainer}>
                                                    <Typography variant={"h5"} className={classes.productNameTitle}>{product.Name}</Typography>
                                                    <Divider style={{ margin: '8px 0px' }} />
                                                    <div className={classes.productCompBox}>
                                                        <div>
                                                            {<span className={classes.productCompletion}>{productRFPMapSuccess[product.ID] !== undefined ? 'Completed' : 'Pending'} </span>}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    })}
                                </List>
                            </div>}

                            {products.length > 1 && <div className={classes.body}>
                                <Typography variant={"h6"} className={classes.otherProTitle}>
                                    Other Products
                                </Typography>
                                <List component="nav" className={classes.nav}>
                                    {products.map((product, k) => {
                                        if (k === selectedProductIndex) {
                                            return null
                                        }
                                        return <ListItem className={classes.otherItmBox} key={k + product.ID + '-evProducts'} onClick={() => {
                                            this.setState({
                                                selectedProductIndex: k,
                                                selectedProduct: productMap[k]
                                            })
                                        }}>
                                            <Grid container alignItems="center" className={classes.cartItem} key={k + '1'}>
                                                <Grid item className={classes.productImageContainer}>
                                                    <Image alt="product" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImage} />
                                                </Grid>
                                                <Grid item className={classes.productNameContainer}>
                                                    <Typography variant={"h5"} className={classes.productNameTitle}>{product.Name}</Typography>
                                                    <Divider style={{ margin: '8px 0px' }} />
                                                    <div className={classes.productCompBox}>
                                                        <div>
                                                            {<span className={classes.productCompletion}>{productRFPMapSuccess[product.ID] !== undefined ? 'Completed' : 'Pending'} </span>}
                                                        </div>
                                                        <div className={classes.nextProductBtn}>
                                                            <img className={classes.askSellerImg} src={arrowRight} alt={product.Name} />
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    })}
                                </List>
                            </div>}

                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    }
}

AskSellerDetailsUpdateDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AskSellerDetailsUpdateDialog));