import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { Typography } from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import component from "assets/images/workflow/component.svg";

import { addNewAutomationAction, updateAutomationAction } from 'redux/automations/action';
import ActionItemConfig from "./ActionItemConfig";
import classnames from "classnames";
import CheckIcon from "@material-ui/icons/Check";

const connectedProps = (state) => ({
  fetchMasterTriggerProgress: state.automation.fetchMasterTriggerProgress,
  fetchMasterTriggerError: state.automation.fetchMasterTriggerError,
  masterTriggers: state.automation.masterTriggers,

  fetchMasterActionProgress: state.automation.fetchMasterActionProgress,
  fetchMasterActionError: state.automation.fetchMasterActionError,
  masterActions: state.automation.masterActions,

});

const connectionActions = {
  addNewAutomationAction: addNewAutomationAction,
  updateAutomationAction: updateAutomationAction
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    // marginTop: 10,
    // padding: 15
  },
  sectionTitle: {
    alignItems: 'center',
    color: '#637a87',
    display: 'flex',
    fontSize: 12,
    fontWeight: 700,
    margin: '0 0 20px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    '&:after': {
      background: '#e4e8eb',
      content: "",
      flex: 1,
      height: 1,
      margin: '0px 12px',
      order: 1,
    }
  },
  actionList: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  actionDiv: {
    border: '1px solid #4b89ff',
    borderRadius: '4px',
    padding: 5,
    cursor: 'pointer',
    textAlign: 'center',
    minWidth: 140,
    minHeight: 100,
    marginTop: 5,
    marginRight: 10,
    '&:hover': {
      background: '#f7f7f7',
    }
  },
  actionName: {
    inlineSize: '140px',
    overflowWrap: 'break-word'
  },
  rootAction: {

  },
  rootDisable: {
    marginTop: 10,
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  triggerDiv: {
    border: '1px solid #065f69',
    borderLeft: '3px solid #065f69',
    borderRadius: '4px',
    padding: 10,
    cursor: 'pointer',
    background: '#eef8fa',
    marginBottom: 10,
    '&:hover': {
      background: '#f7f7f7',
    },
    position: 'relative'
  },
  triggerDesc: {
    fontSize: 12
  },
  triggerName: {
    fontWeight: 600
  },
  tag: {
    color: '#fff',
    background: '#2EB77D',
    fontSize: 11,
    marginRight: 10,
    borderRadius: 9,
    padding: '0px 5px'
  },
  topBar: {
    background: '#eef8fa',
    borderBottom: '1px solid #6dc8ce80'
  },
  topTabs: {
    display: 'flex',
    alignItems: 'center',
    background: '#f7f9fa',
    borderBottom: '1px solid #e4e8eb'
  },
  tabButton: {
    fontSize: 12,
    fontWeight: 600,
    background: 'none',
    border: 'none',
    lineHeight: 1,
    padding: '11px 12px 12px',
    position: 'relative',
    color: '#637a87',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '2px solid #e4e8eb'
    }
  },
  selectedButton: {
    color: '#065f69',
    borderBottom: '2px solid #133440'
  },
  actionChoose: {
    position: 'relative',
    height: '85vh',
    padding: 20,
    overflow: 'auto'
  },
  micon: {
    fontSize: 24,
    marginTop: 3,
    background: "#5aa028",
    color: "#fff",
    border: '2px solid #f3f5f6',
    top: '-10px',
    left: '-10px',
    borderRadius: '50%',
    position: 'absolute'
  },
});

class ActionConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      inputJson: '',
      selectedTab: 'Action',
      selectedAction: ''
    }
  }

  componentDidMount() {
    this.initInputJson()
  }

  componentDidUpdate(prevProps) {


  }

  submitResponse() {

  }

  initInputJson() {
    const { inputRef } = this.props;
    const data = this.props.data;
    if (inputRef && inputRef !== '') {
      let selectedTab = 'Action';
      if (inputRef.input_json && inputRef.input_json !== '') {
        selectedTab = 'Setup'
      }
      this.setState({
        inputJson: inputRef.input_json,
        selectedTab: selectedTab
      })
    }
  }

  addAction(action) {
    // console.log(action, 'SelecteddddddddAction')
    const { automationDetails } = this.props;
    // const masterAction = this.getMasterAction(actionId)
    // console.log(masterAction, 'masterAction')
    let params = {
      actionID: action?.id,
      name: action?.name,
      type: 'main',
      parentId: parseInt(this.props.match.params.id),
      inputData: JSON.stringify(action?.inputJson) || '{}'
    }
    this.props.addNewAutomationAction(automationDetails.ID, params);
  }

  updateComponent(component) {
    const { automationDetails, data } = this.props;
    this.props.updateAutomationAction(automationDetails.ID, data.id, {
      refType: 'Master',
      refID: component.id
    })
  }

  updateActionComponent() {

  }

  handleTabClick(type) {
    const data = this.props.data;
    let selectedAction = ''
    if (type === 'Action' && data && data.masterData && data.masterData !== '') {
      selectedAction = data.masterData.id
    }
    this.setState({
      selectedTab: type,
      selectedAction: selectedAction
    })
  }

  render() {
    const { classes, masterActions, masterTriggers, automationDetails, isForSummary } = this.props;
    const { inputJson, selectedTab, selectedAction } = this.state;
    const data = this.props.data;
    const componentData = data.object;

    let chooseAction = data.componentType === 'Action' && (componentData.RefID === null || componentData.RefID === '') && (componentData.RefType === null || componentData.RefType === '');
    if (inputJson && inputJson !== '') {
      chooseAction = false;
    }

    if (selectedTab === 'Action') {
      chooseAction = true;
    } else {
      chooseAction = false;
    }

    return (
      <div className={classes.root}>

        {!data.isNew && data.componentType === 'Action' && <div className={classes.topBar}>
          <div className={classes.topTabs}>
            <button type='button' onClick={() => this.handleTabClick('Action')} className={classnames(classes.tabButton, selectedTab === 'Action' ? classes.selectedButton : '')}>Action</button>
            <button type='button' onClick={() => this.handleTabClick('Setup')} className={classnames(classes.tabButton, selectedTab === 'Setup' ? classes.selectedButton : '')}>Setup</button>
          </div>
        </div>}

        {data.componentType === 'Trigger' && componentData.RefID === null && componentData.RefType === null && <div className={classes.actionChoose}>
          <Typography variant="h3" className={classes.sectionTitle}>Choose Trigger</Typography>
          <div className={classes.triggerList}>
            {masterTriggers && masterTriggers.map((component, index) => {
              if (component.hidden) {
                return null
              }
              return <div className={classes.triggerDiv} key={'action_config_' + index} onClick={() => {
                this.updateComponent(component)
              }}>
                <div>
                  <Typography className={classes.triggerName}>{component.name}</Typography>
                  <Typography className={classes.triggerDesc}>{component.description}</Typography>
                </div>
              </div>
            })}
          </div>
        </div>}

        {data.componentType === 'Trigger' && componentData.RefID && componentData.RefType && inputJson && inputJson.length <= 0 && <div>
          <Typography>{data.label}</Typography>
          <Typography>No Configuration Required</Typography>
        </div>}

        {data.componentType !== 'Trigger' && chooseAction && <div className={classes.actionChoose}>
          <Typography variant="h3" className={classes.sectionTitle}>Choose Action</Typography>
          <div className={classes.triggerList}>
            {masterActions && masterActions.map((mcom, k) => {
              if (mcom.hidden) {
                return null
              }
              let tags = mcom.tags ? mcom.tags : []
              let isSelected = selectedAction === mcom.id ? true : false
              return <div key={k} className={classes.triggerDiv} onClick={() => {
                this.updateComponent(mcom)
              }}>
                <div>
                  {isSelected && <CheckIcon className={classes.micon} />}
                  <Typography className={classes.triggerName}>{mcom.name}</Typography>
                  <Typography className={classes.triggerDesc}>{mcom.description}</Typography>
                  <>
                    {tags.map((tag) => {
                      return <span className={classes.tag}>{tag}</span>
                    })}
                  </>
                </div>
              </div>
            })}
          </div>
        </div>}

        {!chooseAction && <div className={!isForSummary ? classes.rootAction : classes.rootDisable}>
          <ActionItemConfig
            id={automationDetails?.ID}
            actionData={data}
            inputJson={inputJson}
            componentDetails={this.props.componentDetails}
            componentVariables={this.props.componentVariables}
            isForSummary={isForSummary}
          />
        </div>}

      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ActionConfig));
