import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { FormControl,  InputAdornment,
  OutlinedInput,TextField, Select, MenuItem, Typography, Button } from "@material-ui/core";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
// import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import { withAsyncPaginate } from "react-select-async-paginate";
import axios from "axios";
import Image from 'Components/Common/image.jsx';
import CalendarImage from "assets/images/calendar.svg";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { currencyOptions, currencyCodeMap } from "util/Currency";
import CommonCss from "commonCss";

const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

var currencyTableTypeStyles = {
  control: styles => ({ ...styles, height: 46 }),
  menuPortal: base => ({ ...base, zIndex: 10 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  })
};

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  reqTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  actionButtons: {
    textAlign: 'right',
    marginTop: theme.spacing(2)
  },
  appyBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    minHeight: 35,
    width: "calc(100% - 46px)",
    margin: 23,
    color: "#fff",
    fontSize: 14,
    padding: theme.spacing(0.5, 8),
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  head: {
    color: "#282D30",
    fontSize: 18,
  },
  headSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: 23,
  },
  drawerSection: {
    padding: theme.spacing(0, 3.6),
    paddingTop: '0.6rem',
    minHeight: 48,
    width: 400,
  },
  textFields: {
    width: "100%",
    "& [class*='MuiInputBase']": {
      fontSize: 14,
    },
  },
  calendarPopout: {
    zIndex: '100 !important'
  },
  datePicker: {
    "& .react-datepicker-wrapper": {
      width: "100%",
    },
    "& .react-datepicker__input-container > div": {
      display: "flex",
      marginBottom: 0,
      alignItems: "center",
      paddingLeft: "8px !important",
      "& [class*=MuiInputBase-root]": {
        maxHeight: 33,
        minHeight: 33,
        width: "inherit",
      },
      "& input": {
        fontSize: theme.spacing(1.8),
        padding: "14px 5px 14px 2px !important",
        textAlign: "center",
      },
      "& [class*=MuiFormLabel-root]": {
        marginBottom: 0,
        marginRight: 29,
      },
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8),
    },
  },
});

class OrgVendorsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: [20, 37],
      expanded: false,
      appliedFilters: {},
      appliedSubFilters: {},
      filters: [],
      subFilters: [],
      appliedTextField: '',
      hardFilterMessageData: {},
      filterData: {}
    };
  }

  componentDidMount() {
    const { filterData, searchFilterOptions } = this.props
    this.setState({ filterData, filters: searchFilterOptions })
  }

  onSelectAppliedFilter = (value) => {
    if(value && [4,5,6].includes(value.type)){
      let additionalData = value.additional_data;
      try {
        additionalData = JSON.parse(additionalData);
        if(additionalData && additionalData.length > 0){
          let options = [];
          additionalData.forEach(item=> {
            if([4,5].includes(value.type) || [1,7,8].includes(item.type)){
              options.push({
                value: item.value,
                label: item.label,
                type: value.type === 6 ? item.type : null
              })
            }
          })
          this.setState({ filterData: {selectedFilter: value, options: options }});
        }
      } catch(e) {}
    }else {
      this.setState({ filterData: {selectedFilter: value} });
    }
  }

  onSelectAppliedSubFilter = (value) => {
    this.setState({ filterData: {...this.state.filterData, selectedOption: value } });
  }

  handleOnClickApply = () => {
    // let nested_key = '';
    // if([4,5,6].includes(this.state.appliedFilters.type)){
    //   nested_key = 'value'
    // }
    // let data = {
    //   item_slug: this.state.appliedFilters.slug,
    //   nested_key: nested_key,
    //   // type: '',
    //   value: this.state.appliedFilters.type === 1 ? this.state.appliedTextField : this.state.appliedSubFilters.value,
    //   regex: this.state.appliedFilters.type === 1 ? true : false,
    // }
    // this.props.onApplyFilter(data, this.state.hardFilterMessageData);
    this.props.onApplyFilter(this.state.filterData);
  }


  getUserOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      page: 0,
      pageSize: 20,
      query: inputValue
    };
    const url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    // console.log(response)

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      ...m,
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  getTeamOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    const url = `${api_server}/groups?page=0&pageSize=20&query=${inputValue}&is_suppressed=no&t=${timestamp}`;
    const response = await axios.get(url, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      ...m,
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };


  render() {
    var { classes } = this.props;
    const { filters,filterData } = this.state
console.log({ filterData,filters },'orgVendorFilter')
    return (
      <div className={classes.drawerSection}>
        <div className={classes.sortByDiv}>
          <Typography variant={"h6"} className={classes.reqTitle}>
            Filter by:
          </Typography>
          <FormControl variant="outlined" className={classes.formControl} size="small">
            <Select value={filterData.selectedFilter || ""} onChange={(e) => this.onSelectAppliedFilter(e.target.value)}>
              {filters.map((dta, index) => {
                return <MenuItem key={index} value={dta} >{dta.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>


        {[4,5,6].includes(filterData.selectedFilter?.type) && (
          <div className={classes.sortByDiv}>
            <Typography variant={"h6"} className={classes.reqTitle}>
              Select {[4,5].includes(filterData.selectedFilter?.type) ? "option" : "column"}:
            </Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select value={filterData.selectedOption || ""} onChange={(e) => this.onSelectAppliedSubFilter(e.target.value)}>
                {filterData?.options?.map((dta, index) => {
                  return <MenuItem key={index} value={dta}>{dta.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        )}


        {(([1,12,13,14,19].includes(filterData.selectedOption?.type)) || ([1,12,13,14,19].includes(filterData.selectedFilter?.type))) && (
          <div className={classes.sortByDiv}>
            <Typography variant={"h6"} className={classes.reqTitle}>
              Search:
            </Typography>
            <TextField
              value={filterData.selectedValue || ""}
              size="small"
              variant="outlined"
              className={classes.textFields}
              onChange={(e)=> this.setState({ filterData: {...this.state.filterData, selectedValue: e.target.value } })}
            />
          </div>
        )}


        {((filterData.selectedOption?.type === 2) || (filterData.selectedFilter?.type === 2)) && <div className={classes.datePicker}>
          <Typography variant={"h6"} className={classes.reqTitle}>
            Search:
          </Typography>
          <DatePicker
            readOnly={false}
            selected={filterData.selectedValue ? new Date(filterData.selectedValue) : ''}
            onChange={(date) => this.setState({ filterData: {...this.state.filterData, selectedValue: date }})}
            dateFormat="MMM do yyyy"
            showMonthDropdown
            showYearDropdown
            popperClassName={classes.calendarPopout}
            dropdownMode="select"
            customInput={
              <OutlinedInput
                value={filterData.selectedValue}
                readOnlyInput={true}
                placeholder="Select Date"
                labelStyle={"inline"}
                startAdornment={
                  <InputAdornment>
                    <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                  </InputAdornment>
                }
              />
            }
          />
        </div>}

        {((filterData.selectedOption?.type === 20) || (filterData.selectedFilter?.type === 20)) && <div>
          <Typography variant={"h6"} className={classes.reqTitle}>
            Search:
          </Typography>
          <div className={classes.currencyTypeFields} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ width: 100, marginRight: 5, zIndex: 1 }}>
            <ReactSelect
              isMulti={false}
              labelId="singleSelect-select-label"
              id="singleSelect-select"
              value={filterData.selectedValue?.type}
              onChange={(e) => {
                let newValue = {
                  type: e,
                  value: filterData.selectedValue?.value
                }
                this.setState({ filterData: {...this.state.filterData, selectedValue: newValue }})
              }}
              options={currencyOptions}
              placeholder={"Select a Option"}
              styles={currencyTableTypeStyles}
            >
            </ReactSelect>
          </div>
          <CurrencyTextField
            variant="outlined"
            value={filterData.selectedValue?.value}
            currencySymbol={currencyCodeMap[filterData.selectedValue?.type?.value]}
            style={{ textAlign: 'left', flex: 1, height: 46 }}
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            required={false}
            onChange={(e, v) => {
              let newValue = {
                type: filterData.selectedValue?.type,
                value: v
              }
              this.setState({ filterData: {...this.state.filterData, selectedValue: newValue }})
            }}
          />
        </div>
        </div>}

        {((filterData.selectedOption?.type === 7) || (filterData.selectedFilter?.type === 7)) && <div className={classes.searchAndSelect}>
        <Typography variant={"h6"} className={classes.reqTitle}>
            Search:
          </Typography>
          <SelectAsyncPaginate
            isClearable={false}
            isSearchable={true}
            menuPosition='fixed'
            classNamePrefix="select"
            isLoading={false}
            placeholder="Search user"
            value={filterData.selectedValue}
            additional={{
              page: 0,
            }}
            loadOptions={this.getUserOptions}
            onChange={(e) => {
              this.setState({ filterData: {...this.state.filterData, selectedValue: e }})
            }}
            styles={{ control: base => ({ ...base, height: 46 }) }}
          />
        </div>}

        {((filterData.selectedOption?.type === 8) || (filterData.selectedFilter?.type === 8)) && <div className={classes.searchAndSelect}>
          <Typography variant={"h6"} className={classes.reqTitle}>
            Search:
          </Typography>
          <SelectAsyncPaginate
            isClearable={false}
            isSearchable={true}
            menuPosition='fixed'
            classNamePrefix="select"
            isLoading={false}
            placeholder="Search group"
            value={filterData.selectedValue}
            additional={{
              page: 0,
            }}
            loadOptions={this.getTeamOptions}
            onChange={(e) => {
              this.setState({ filterData: {...this.state.filterData, selectedValue: e }})
            }}
            styles={{ control: base => ({ ...base, height: 46 }) }}
          />
        </div>}

        <div className={classes.textCenter}>
          <Button className={classes.appyBtn} onClick={() => this.handleOnClickApply()}>Apply</Button>
        </div>
      </div>
    );
  }
}

OrgVendorsFilter.defaultProps = {
  filterData: {}
}

export default React.memo(withStyles(styles)(OrgVendorsFilter));
