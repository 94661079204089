import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Button, Grid, Chip, InputAdornment, Typography, Table, Divider, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";

import { withRouter } from "react-router-dom";
import Image from 'Components/Common/image.jsx';
import addUsers from "assets/images/add_profile.svg";

import SurveyBar from "../Common/SurveyBar";
import AppBarV2 from "../../../../AppBarV2";
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg"
import SqureButton from 'Components/Common/SqureButton';

import { fetchEvaluationMetaData, updateEvaluationMetaData } from "redux/evaluation/metadata/action";
import classnames from "classnames";
import DefaultImage from "assets/images/evaluation/default.svg"
import ShowCollaboratorDetailsDialog from "../Common/ShowCollaboratorDetailsDialog";
import { fetchColloboratorStatus, showCollaboratorDetailsDialog, showUpdateSurveyDialog } from "redux/survey/action";
import { showEvaluationCollaboratorDialog, hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
import { fetchColloborator, addColloborator } from "redux/evaluation/criteria/action"
import editIcon from "assets/images/edit_icon.svg";
import moment from 'moment';
import { showSnackBar } from "redux/snackbar/action";
import { updateEvaluationTitle } from "redux/evaluation/metadata/action";

const connectedProps = (state) => ({
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
  evaluation: state.evaluationMetaData.evaluation,
  colloborators: state.evaludationCriteria.colloborators,
  fetchColloboratorStatusProgress: state.survey.fetchColloboratorStatusProgress,
  colloboratorStatus: state.survey.colloboratorStatus,
  isShowCollaboratorDetailsDialog: state.survey.isShowCollaboratorDetailsDialog,
  user: state.authUser.user,
  userType: state.authUser.user?.Role,
  fetchColloboratorProgress: state.evaludationCriteria.fetchColloboratorProgress,
  updateProgress: state.evaluationMetaData.updateProgress,
  updateError: state.evaluationMetaData.errorUpdate,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  evaluationId: state.evaluationMetaData.evaluationId,
});

const connectionActions = {
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  fetchColloboratorStatus: fetchColloboratorStatus,
  showCollaboratorDetailsDialog: showCollaboratorDetailsDialog,
  showUpdateSurveyDialog: showUpdateSurveyDialog,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  fetchColloborator: fetchColloborator,
  addColloborator: addColloborator,
  updateDetails: updateEvaluationMetaData,
  showSnackBar: showSnackBar,
  updateEvaluationTitle: updateEvaluationTitle
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  contentContainer: {
    marginBottom: '100px'
  },
  content: {
    padding: theme.spacing(4, 4),
    background: "#F7F7F7",
    marginTop: 0,
    // minHeight: "100vh"
  },
  cartItems: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  cartItem: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: 12,
    border: '1px solid #EEEEEE',
    borderRadius: 10,
    // justifyContent: 'space-between'
  },
  productNameContainer: {
    flex: 1,
    marginLeft: 20
  },
  productName: {
    flex: 0.9,
  },
  productImage: {
    width: 67,
    height: 57,
    padding: 13,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 6px #0000001A',
    borderRadius: 10,
    marginRight: theme.spacing(2)
  },
  productImageContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  productImageContainer2: {
    display: 'flex',
    position: 'relative'
  },
  loader: {
    textAlign: 'center',
    marginTop: 20
  },
  status: {
    cursor: 'pointer'
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actionTxt: {
    color: '#4175DF',
    width: 'max-content',
    fontSize: 15,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  actionImage: {
    width: 13,
    marginRight: 3
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f1f1f1 !important"
    }
  },
  completedText: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: '#94999b'
  },
  tabColumn: {
    width: '20%'
  },
  tabActionColumn: {
    width: '20%'
  },
  viewResponse: {
    backgroundColor: '#1f73b7 !important'
  },
  datePickers: {
    "& [class*='MuiFormLabel-root']": {
      color: '#475867'
    },
    "& [class*=MuiOutlinedInput-root]": {
      minHeight: 40,
      color: '#282D30'
    },
    "& [class*=react-datepicker-wrapper]": {
      display: 'block',
    }
  },
  scheduleTitle: {
    display: "block",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: 500,
    fontSize: theme.spacing(2)
  },
  evalBtn: {
    border: '1px solid #20253A !important',
  },
  surveyBtn: {
    // position:'absolute !important',
    // right: 0
    padding: '0px 20px',
  }
});

class SurveyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colloboratorStatusMap: {},
      colloboratorIds: [],
      totalCollaborator: 0,
      totalCompleted: 0,
      evaluationStart: null,
      evaluationStart_error: "",
      evaluationEnd: null,
      evaluationEnd_error: "",
      isShowLoader: true,
      productDatas: []
    }
    this.handleClose = this.handleClose.bind(this);
  }


  handleClose(user) {
    console.log(user, '55555555555555')
    if (user !== null) {
      this.props.addColloborator(this.props.match.params.survey_id, user.ID)
    } else {
      this.props.hideEvaluationCollaboratorDialog();
    }
  }


  componentDidMount() {
    this.props.fetchColloborator(this.props.match.params.survey_id);
    this.props.fetchEvaluationMetaData(this.props.match.params.survey_id)
    this.props.fetchColloboratorStatus(this.props.match.params.survey_id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchColloboratorStatusProgress === false && prevProps.fetchColloboratorStatusProgress === true) {
      console.log(this.props.colloboratorStatus, 'colloboratorStatus')
      this.createCollaboratorMap();
    }

    if (this.props.collaboratorDialogOpen === false && prevProps.collaboratorDialogOpen === true) {
      this.props.fetchColloboratorStatus(this.props.match.params.survey_id)
    }

    if (this.props.updateProgress === false && prevProps.updateProgress === true) {
      if (this.props.updateError === null) {
        this.props.fetchEvaluationMetaData(this.props.match.params.survey_id);
        this.props.showSnackBar("Survey updated successfully.", "success", 3000)
      } else {
        if (this.props.updateError.message !== undefined) {
          var errorMessage = this.props.updateError.message;
          if (errorMessage.indexOf("NotFound Error") !== -1) {
            this.props.showSnackBar("Something went wrong.", "error", 3000)
          } else {
            this.props.showSnackBar("Something went wrong.", "error", 3000)
          }
        } else {
          this.props.showSnackBar("Something went wrong.", "error", 3000)
        }
      }
    }

    if (this.props.fetchProgress === false && prevProps.fetchProgress === true) {
      const { evaluation } = this.props;
      var state = this.state;
      if (evaluation.EvaluationSd !== null) {
        state.evaluationStart = new Date(evaluation.EvaluationSd)
      } else {
        state.evaluationStart = null
      }

      if (evaluation.EvaluationEd !== null) {
        state.evaluationEnd = new Date(evaluation.EvaluationEd)
      } else {
        state.evaluationEnd = null
      }
      state.isShowLoader = false;
      state.productDatas = evaluation?.Products;
      this.setState(state);
    }
  }

  createCollaboratorMap() {
    const { colloboratorStatus } = this.props;
    console.log(colloboratorStatus, 'colloboratorStatus')
    var colMap = {};
    var colIds = [];
    var totalCollaborator = 0;
    var totalCompleted = 0;
    if (colloboratorStatus !== undefined && colloboratorStatus !== null && colloboratorStatus.response !== undefined && colloboratorStatus.response !== null) {
      colloboratorStatus.response.forEach(function (col) {
        if (colMap[col.user.ID] === undefined) {
          colMap[col.user.ID] = col.status;
          colIds.push(col.user.ID);
          totalCollaborator++;
          if (col.status === 'Completed') {
            totalCompleted++;
          }
        }
      })
    }
    this.setState({
      colloboratorStatusMap: colMap,
      colloboratorIds: colIds,
      totalCollaborator: totalCollaborator,
      totalCompleted: totalCompleted,
    })
  }

  evaluationEndChange = (date) => {
    this.setState({
      evaluationEnd: date
    }, () => {
      this.updateDetails({
        evaluationEd: this.state.evaluationEnd.toISOString(),
      })
    })
  }

  evaluationStartChange = (date) => {
    this.setState({
      evaluationStart: date,
    })
    if (this.state.evaluationEnd) {
      var diff = moment(date).diff(moment(this.state.evaluationEnd), 'days');
      if (diff > 0) {
        this.setState({
          evaluationEnd: null,
        }, () => {
          this.updateDetails({
            evaluationSd: date.toISOString(),
            evaluationEd: date.toISOString(),
          })
        })
      } else {
        this.updateDetails({
          evaluationSd: date.toISOString(),
        })
      }
    } else {
      this.updateDetails({
        evaluationSd: date.toISOString(),
      })
    }
  }

  updateDetails = (options = {}) => {
    this.props.updateDetails(this.props.evaluation.ID, {
      ...options
    })
  }

  render() {
    const classes = this.props.classes;

    const colloborators = this.props.colloborators !== undefined && this.props.colloborators !== null ? this.props.colloborators : [];

    if (this.state.isShowLoader) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }

    console.log(this.state.colloboratorStatusMap,'colloboratorStatusMap')
    console.log(colloborators,'colloborators')

    return (
      <div>
        <AppBarV2
          title={this.props.evaluation !== undefined && this.props.evaluation !== null ? this.props.evaluation?.Name : 'Survey'}
          withBack={true}
          isEditableTitle={true}
          submitTitle={(value) => {
            this.props.updateEvaluationTitle(this.props.match.params.survey_id, {
              name: value,
            })
          }}
          isSubmittingTitle={this.props.evalTitleUpdateProgress}
        />

        {false && <SurveyBar />}

        {<Grid container className={classnames(classes.contentContainer, classes.content)}>
          <Grid item lg={8} md={8} xs={8} sm={8} >
            <div style={{ textAlign: 'right', marginBottom: 10 }}>
              {<SqureButton className={classes.evalBtn} variant="outlined" startIcon={
                !this.props.evaluateView ? <Image src={addUsers} alt="Edit Dark" /> : ''
              } onClick={() => {
                this.props.showEvaluationCollaboratorDialog({
                  evaluationId: this.props.match.params.survey_id,
                  existingUsers: this.props.colloborators !== undefined && this.props.colloborators !== null ? this.props.colloborators : [],
                  selectedUser: this.props.colloborators !== undefined && this.props.colloborators !== null ? this.props.colloborators : [],
                  onClose: this.handleClose,
                  position: 'SecondaryBar',
                })
              }}> Add Collaborator</SqureButton>}
            </div>
          </Grid>
          <Grid item lg={4} md={4} xs={4} sm={4}>

          </Grid>
          <Grid item lg={8} md={8} xs={8} sm={8}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tabColumn} align="left">Name</TableCell>
                    <TableCell className={classes.tabColumn} align="left">Email</TableCell>
                    <TableCell className={classes.tabActionColumn} align="left">Status</TableCell>
                    <TableCell className={classes.tabActionColumn} align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {colloborators.map((col, k) => {
                    var color = 'secondary';
                    if (this.state.colloboratorStatusMap[col.ID] !== undefined && this.state.colloboratorStatusMap[col.ID] === 'Completed') {
                      color = 'primary';
                    }
                    return <TableRow key={k} className={classes.tableRow}>
                      <TableCell align="left">{col.Name}</TableCell>
                      <TableCell align="left">{col.Email}</TableCell>
                      <TableCell align="left">
                        <Chip
                          className={classes.status}
                          variant="outlined"
                          color={color}
                          size="small"
                          label={this.state.colloboratorStatusMap[col.ID] !== undefined ? this.state.colloboratorStatusMap[col.ID] : 'In Progress'}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {color === 'primary' && <Button className={classes.viewResponse} variant="contained" color="secondary" onClick={() => {
                          if (this.state.colloboratorStatusMap[col.ID] !== undefined && this.state.colloboratorStatusMap[col.ID] === 'Completed') {
                            this.props.showCollaboratorDetailsDialog(col.ID, col.Name);
                          }
                        }}>View Response</Button>}

                        {color === 'secondary' && this.props.user.ID === col.ID && this.props.userType === 'OrgAdmin' && this.state.colloboratorIds.indexOf(this.props.user.ID) !== -1 && this.state.colloboratorStatusMap[this.props.user.ID] !== undefined && this.state.colloboratorStatusMap[this.props.user.ID] !== 'Completed' &&
                          <Button
                            className={classes.viewResponse}
                            variant="contained"
                            color="secondary" 
                            onClick={() => {
                              let redirectPath = `/app/survey/${this.props.match.params.survey_id}/take-survey`;
                              this.props.history.push(redirectPath);
                            }}
                          > Take Survey </Button>
                        }

                      </TableCell>
                    </TableRow>
                  })}

                  {colloborators.length <= 0 && <TableRow key={'dd'}>
                    <TableCell align="left" colSpan={3}>No Data</TableCell>
                  </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item lg={4} md={4} xs={4} sm={4} style={{ 'paddingLeft': '20px' }}>
            {false && <div className={classes.topBar}>
              <div className={classes.leftBar}>
              </div>
              <div className={classes.rightBar}>
                <Typography
                  className={classes.actionTxt}
                  onClick={(e) => {
                    this.props.showUpdateSurveyDialog(this.props.evaluation.ID, true)
                  }}
                >
                  <Image className={classes.actionImage} src={editIcon} alt="Edit Dark" /> Edit Survey
                </Typography>
              </div>
            </div>}
            <Grid container alignItems="center" className={classes.cartItem}>

              {this.state.productDatas.map((product, k) => {
                var imageContainerCls = classes.productImageContainer;
                return <>
                  <Grid item className={classes.productNameContainer}>
                    <div item className={imageContainerCls}>
                      <Image alt="product" src={product?.ProductLogo === '' || product?.ProductLogo === null ? DefaultImage : product?.ProductLogo} className={classes.productImage} />
                      <div>
                        <Typography variant={"h5"} className={classes.productNameTitle}>
                          {product?.Name}
                        </Typography>

                      </div>
                    </div>
                    <Divider style={{ margin: '8px 0px' }} />
                    <Typography variant={"h5"} className={classes.completedText}>
                      {this.state.totalCompleted} / {this.state.totalCollaborator} Completed
                    </Typography>
                    {/* {this.props.evaluation && this.props.evaluation.EvaluationEd !== undefined && this.props.evaluation.EvaluationEd !== null && this.props.evaluation.EvaluationEd !== "" && <Divider style={{ margin: '8px 0px' }} />}
                    {this.props.evaluation && this.props.evaluation.EvaluationEd !== undefined && this.props.evaluation.EvaluationEd !== null && this.props.evaluation.EvaluationEd !== "" && <Typography variant={"h5"} className={classes.completedText}>
                      {moment(this.props.evaluation?.EvaluationEd).format("MMM Do YYYY")} - {moment(this.props.evaluation?.EvaluationSd).format("MMM Do YYYY")}
                    </Typography>} */}
                    <Divider style={{ margin: '8px 0px' }} />
                    <Typography variant={"body1"} className={classes.scheduleTitle}>Survey schedule</Typography>
                    <Grid container className={classes.datePickers}>
                      <Grid item xs={12}>
                        <DatePicker
                          selected={this.state.evaluationStart}
                          onChange={(date) => this.evaluationStartChange(date)}
                          dateFormat="MMM do yyyy"
                          portalId="evMetaDialog"
                          minDate={this.state.criteriaSelectionEnd ? this.state.criteriaSelectionEnd : null}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          customInput={
                            <OutlinedInput
                              label="Start Date"
                              readOnlyInput={true}
                              error={this.state.evaluationStart_error.length > 0}
                              helperText={this.state.evaluationStart_error}
                              startAdornment={
                                <InputAdornment>
                                  <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                </InputAdornment>
                              }
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          selected={this.state.evaluationEnd}
                          onChange={(date) => this.evaluationEndChange(date)}
                          dateFormat="MMM do yyyy"
                          portalId="evMetaDialog"
                          minDate={this.state.evaluationStart ? this.state.evaluationStart : null}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          customInput={
                            <OutlinedInput
                              label="End Date"
                              readOnlyInput={true}
                              error={this.state.evaluationEnd_error.length > 0}
                              helperText={this.state.evaluationEnd_error}
                              startAdornment={
                                <InputAdornment>
                                  <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                </InputAdornment>
                              }
                            />
                          }
                        />
                      </Grid>
                    </Grid>

                  </Grid>
                </>
              })}

            </Grid>
          </Grid>
        </Grid>
        }

        {
          this.props.collaboratorDialogOpen &&
          <SearchUserPoper {...this.props.collaboratorDialogData} />
        }


        {this.props.isShowCollaboratorDetailsDialog && <ShowCollaboratorDetailsDialog />}

      </div >
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SurveyView));
