import React from 'react'
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { withRouter } from 'react-router-dom'
import { Typography } from "@material-ui/core";
import LinesEllipsis from "react-lines-ellipsis";


import Image from 'Components/Common/image.jsx'

const styles = (theme) =>
  createStyles({
    container: {
      position: "relative",
      width: '100%'
    },
    root: {
      position: "relative",
      height:"263px",
      border: "solid 3px",
      // marginTop: theme.spacing(1),
      background: theme.palette.common.white,
      borderRadius: theme.spacing(2),
      zIndex: 10,
      padding: theme.spacing(0, 0.5),
      paddingBottom: theme.spacing(2),
      cursor: "pointer",
      display: 'flex',
      flexDirection: 'column',
      placeContent: 'center center'
    },
    layer1: {
      width: "90%",
      position: "absolute",
      border: "solid 2px",
      borderRadius: theme.spacing(2),
      height: "100px",
      left: 0,
      right: 0,
      margin: "0 auto",
      zIndex: "9",
      top: theme.spacing(-1),
    },
    layer2: {
      width: "80%",
      position: "absolute",
      border: "solid 1px",
      borderRadius: theme.spacing(2),
      height: "100px",
      left: 0,
      right: 0,
      margin: "0 auto",
      zIndex: "8",
      top: theme.spacing(-1.8),
    },
    cardGrey: {
      "& $root": {
        borderColor: "#CECECE",
        background: "#FFFFFF",
      },
      "& $layer1": {
        borderColor: "#CECECE",
        opacity: 0.5,
        background: "#FFF",
      },
      "& $layer2": {
        borderColor: "#CECECE",
        opacity: 0.3,
        background: "#FFF",
      },
    },
    cardDefault: {
      "& $root": {
        borderColor: "#EEEEEE",
      },
      "& $layer1": {
        borderColor: "#EEEEEE",
        opacity: 0.5,
        background: "#FFF",
      },
      "& $layer2": {
        borderColor: "#EEEEEE",
        opacity: 0.3,
        background: "#FFF",
      },
      "& $cardTitle": {
        height: theme.spacing(5),
        display:"flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      },
      "& $cardTitle2": {
        height: theme.spacing(8),
        display:"flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      },
    },
    cardTitle: {
      fontSize: theme.spacing(2),
      textAlign: "center",
      padding: '0 20px',
      minHeight: theme.spacing(5),
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block'
    },
    cardTitle2: {
      fontSize: theme.spacing(2),
      textAlign: "center",
      padding: '0 20px',
      minHeight: theme.spacing(5),
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block'
    },
    cardSubTitle: {
      color: "#282D30",
      opacity: 0.6,
      textAlign: "center",
      fontSize: theme.spacing(1.7),
      fontWeight: 400,
      minHeight: 20,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '0 3px',
      marginTop:theme.spacing(2)
    },
    imageContainer: {
      textAlign: "center",
      padding: theme.spacing(3),
      paddingTop: '2em',
      marginTop: 10
    },
    cardImage: {
      // height: theme.spacing(8),
      // maxWidth:"100%"
      width: 'auto',
      height: '55px',
      maxWidth: '90%',
      objectFit: 'contain'
    },
  });


class ClusterFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  gotoFolder =(group)=> {
    this.props.history.push(`/app/products/cluster-products/${group?.orgProduct?.id}`)
  }

  render() {
    const classes = this.props.classes;

    return <>
      <div className={classnames(classes.container, classes.cardDefault)} >
        <div className={classes.layer1}></div>
        <div className={classes.layer2}></div>
        <div className={classes.root} onClick={()=>{this.gotoFolder(this.props.group)}} >
          <div className={classes.imageContainer} >
            <Image
              src={this.props.group?.orgProduct?.productLogo==undefined?"":this.props.group?.orgProduct?.productLogo}
              alt="cardImage"
              className={classes.cardImage}
            />
          </div>
          <Typography
                variant={"h5"}
                className={classes.cardTitle}
              >
                <LinesEllipsis
                          text={this.props.group?.orgProduct?.name}
                          maxLine={2}
                          ellipsis="..."
                          basedOn="letters"
                        />
          </Typography>

          <Typography
                variant={"subtitle1"}
                className={classnames(classes.cardSubTitle)}
              >
                {this.props.group?.productsCount + " "}
                {
                  this.props.group.productsCount > 0 ? 'Products' : 'Product'
                }
          </Typography>
        </div>
      </div>
    </>
  }
}

export default compose(withRouter,withStyles(styles))(ClusterFolder);

