import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Popover } from "@material-ui/core";
import toolTipJson from './toolTipData';
import classnames from "classnames";
import ToolTipImage from "assets/images/tool_tip.svg";
import Image from 'Components/Common/image.jsx'
import CloseIcon from "@material-ui/icons/Close";

const styles = () => createStyles({
    tooltipLabel: {
        margin: 8,
        color: '#74798C',
        fontSize: 13,
        padding: 3
    },
    tooltipSpaceLabel: {
        margin: 8,
        color: '#74798C',
        fontSize: 13,
        padding: 3,
        paddingTop: 20,
    },
    close: {
        position: "absolute",
        right: 10,
        top: 10,
        cursor: "pointer",
        color: "#6F6F6F",
      },
    helpIcon: {
        width: 13,
        borderRadius: '50%',
        padding: 0,
        fill: "#fff",
        // background: '#000',
        height: 'auto'
    },
    toolTipWrapper: {
        display: 'inline',
        cursor: 'pointer',
        position: 'relative',
        top: 1
    },
    toolTipPopover: {
        boxShadow: '0px 3px 32px #00000024',
        border: '1px solid #00000024',
    },
    hoverDiv: {
        marginRight: 2,
        position: 'relative',
        '& > p': {
            position: 'absolute',
            width: 'max-content',
            zIndex: 13,
            top: 0,
            left: 0,
            display: 'none',
            margin: 0,
            fontSize: 10,
            background: '#f7f7f7',
            padding: 3,
            borderRadius: 3
        },
        '&:hover': {
            '& > img': {
                display: 'none'
            },
            '& > p': {
                display: 'block'
            },
        }
    },

    "@global": {
        ' #toolTipPopover': {
            // position: 'fixed',
            // alignItems: 'center',
            // justifyContent: 'center',
            // backgroundColor: 'rgb(255 255 255 / 50%)'
        }
    }
});

class ToolTip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileAnchorEl: null,
            topHover: false
        }
    }
    componentDidMount() {
    }

    componentDidUpdate(preProps) {
        if (this.props.mouseValue && !preProps.mouseValue) {
            if (this.props.mouseValue) {
                this.openPopover(this.props.mouseValue)
            }
        }

        if (!this.props.mouseValue && preProps.mouseValue) {
            this.setState({
                profileAnchorEl: this.props.mouseValue
            })
        }
    }

    componentWillUnmount() {
        this.setState({
            profileAnchorEl: null
        })
    }

    openPopover = (event) => {
        this.setState({ profileAnchorEl: event.target });
        if(this.props.autoHideExplanationToolTip){
            setTimeout(() => {
                this.setState({
                    profileAnchorEl: null
                })
            }, 5000)
        }      
    }

    render() {
        const classes = this.props.classes;
        let tip = ""
        if (this.props.showCustom) {
            tip = { label: this.props.toolTip }
        } else {
            tip = toolTipJson[parseInt(this.props.toolTip)];
        }

        return (
            <div onClick={(event) => { event.stopPropagation() }} className={classnames(classes.toolTipWrapper)} style={tip?.wrapperStyle ? tip.wrapperStyle : {}}>
                {tip && (<>
                    {!this.props.isHover && !this.props.ishoverExplanationToolTip && (
                        <Image
                            src={ToolTipImage}
                            onClick={(event) => this.openPopover(event)}
                            className={classnames(classes.helpIcon)}
                        />
                    )}
                    {!this.props.isHover && this.props.ishoverExplanationToolTip && (
                        <div className={ (this.state.topHover || this.props.ishoverExplanationToolTip) ? classes.hoverDiv : {}}>
                            <Image
                                src={ToolTipImage}
                                onClick={(event) => this.openPopover(event)}
                                className={classnames(classes.helpIcon)}
                            />
                            <p
                                onClick={(event) => {
                                    this.setState({ topHover: true })
                                    this.openPopover(event)
                                }}
                                className={classes.hoverText}
                            >
                                {this.props.onHoverExplanationToolTipText}
                            </p>
                        </div>
                    )}
                    {this.state.profileAnchorEl && (
                        <Popover
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            // transformOrigin={{
                            //     vertical: "bottom",
                            //     horizontal: "left",
                            // }}
                            anchorPosition={{ left: 400, top: 300 }}
                            classes={{ paper: classes.toolTipPopover }}
                            anchorEl={this.state.profileAnchorEl}
                            keepMounted
                            elevation={2}
                            open={Boolean(this.state.profileAnchorEl)}
                            onClose={() => {
                                this.setState({ profileAnchorEl: null, topHover: false });
                            }}
                        >   
                            {this.props.autoHideExplanationToolTip === false && <div className={classes.close} onClick={() => this.setState({ profileAnchorEl: null, topHover: false })}>
                                <CloseIcon style={{fontSize:'18px'}}/>
                            </div>}
                            <p
                                onClick={(event) => { event.stopPropagation() }}
                                className={this.props.autoHideExplanationToolTip === false ? classes.tooltipSpaceLabel: classes.tooltipLabel}
                                dangerouslySetInnerHTML={{ __html: tip?.label }}
                            >
                            </p>
                        </Popover>
                    )}
                </>)}
            </div>
        )
    }
}

ToolTip.defautlProps = {
    showCustom: false,
    ishoverExplanationToolTip: false,
    autoHideExplanationToolTip: true,
    onHoverExplanationToolTipText: ""
}

export default withStyles(styles)(ToolTip)