import {
  START_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH,
  END_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH,
  START_VENDOR_SERVICE_CATALOG_FETCH,
  END_VENDOR_SERVICE_CATALOG_FETCH,
  START_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA,
  START_DELETE_ORG_VENDOR_SERVICE,
  END_DELETE_ORG_VENDOR_SERVICE,
  END_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA,
} from "./action";

const initState = {
  fetchVendorServiceCatalogTabDataProgress: false,
  fetchVendorServiceCatalogTabDataError: null,
  vendorServiceCatalogTabData: {},

  fetchVendorServiceCatalogProgress: false,
  fetchVendorServiceCatalogError: null,
  VendorServiceCatalog: null,

  deleteOrgVendorServiceProgress: false,
  deleteOrgVendorServiceError: null,

  orgVendorServiceTabSectionItemDataUpdateProgress: false,
  orgVendorServiceTabSectionItemDataUpdateError: null,
};

export function vendorServiceCatalogReducer(state = initState, action) {
  switch (action.type) {
    case START_VENDOR_SERVICE_CATALOG_FETCH:
      return {
        ...state,
        fetchVendorServiceCatalogProgress: true,
        fetchVendorServiceCatalogError: null,
      };

    case END_VENDOR_SERVICE_CATALOG_FETCH: {
      let currentData = { ...state.VendorServiceCatalog };
      if (action.payload?.searchQuery?.page > 0) {
        currentData?.items?.push(...action.payload?.success?.items);
      } else {
        currentData = action.payload.success;
      }
      return {
        ...state,
        fetchVendorServiceCatalogProgress: false,
        fetchVendorServiceCatalogError: action.payload.error,
        VendorServiceCatalog: currentData,
      };
    }
    case START_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH:
      return {
        ...state,
        fetchVendorServiceCatalogTabDataProgress: true,
        fetchVendorServiceCatalogTabDataError: null,
      };

    case END_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH: {
      return {
        ...state,
        fetchVendorServiceCatalogTabDataProgress: false,
        fetchVendorServiceCatalogTabDataError: action.payload.error,
        vendorServiceCatalogTabData: action.payload.success,
      };
    }
    case START_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA:
      return {
        ...state,
        orgVendorServiceTabSectionItemDataUpdateProgress: true,
        orgVendorServiceTabSectionItemDataUpdateError: null,
      };
    case START_DELETE_ORG_VENDOR_SERVICE:
      return {
        ...state,
        deleteOrgVendorServiceProgress: true,
        deleteOrgVendorServiceError: null,
      };
    case END_DELETE_ORG_VENDOR_SERVICE:
      return {
        ...state,
        deleteOrgVendorServiceProgress: false,
        deleteOrgVendorServiceError: action.payload.error,
      };
    case END_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA:
      let { item_slug, success } = action.payload;

      let newVendorServiceCatalogTabData = { ...state.vendorServiceCatalogTabData };
      if (success && item_slug) {
        newVendorServiceCatalogTabData[item_slug] = success;
      }
      return {
        ...state,
        vendorServiceCatalogTabData: newVendorServiceCatalogTabData,
        orgVendorServiceTabSectionItemDataUpdateProgress: false,
        orgVendorServiceTabSectionItemDataUpdateError: action.payload.error,
      };
    default:
      return state;
  }
}
