import {
  START_FETCH_REVIEW_WORKFLOWS,END_FETCH_REVIEW_WORKFLOWS,
  START_DELETE_REVIEW,END_DELETE_REVIEW
} from "./action";

const initState = {
  fetchReviewWorkflowsProgress: false,
  fetchReviewWorkflowsError: null,
  reviewWorkflows: [],
  reviewWorkflowsTotal: '',
  reviewStatus: [],

  deleteReviewProgress: false,
  deleteReviewError: null,
}

export function reviewReducer(state = initState, action) {
  switch (action.type) {
    case START_FETCH_REVIEW_WORKFLOWS:
      return {
          ...state,
          fetchReviewWorkflowsProgress: true,
          fetchReviewWorkflowsError: null,
      };

    case END_FETCH_REVIEW_WORKFLOWS:
      let page = action.payload.params?.page;
        var workflowData = {
            Data: state.reviewWorkflows,
            total: 0
        };
        var response_completed = action.payload.success;
        if (response_completed !== null) {
          if(!page || page === 0){
            workflowData.Data = response_completed.Data;
          }else{
            workflowData.Data.push(...response_completed.Data);
          }
          workflowData.total = response_completed.total;
        }

        return {
            ...state,
            fetchReviewWorkflowsProgress: false,
            fetchReviewWorkflowsError: action.payload.error,
            reviewWorkflows: workflowData.Data,
            reviewWorkflowsTotal: workflowData.total,
            reviewStatus: action.payload.status
        };
      case START_DELETE_REVIEW:
        return {
          ...state,
          deleteReviewProgress: true,
          deleteReviewError: null,
        };
      case END_DELETE_REVIEW:
        var error = null;
        let oldData = [...state.reviewWorkflows];
        if (action.payload?.error?.message) {
          error = action.payload?.error?.message;
        }else{     
          var newDataArr = oldData.filter(data=> data.ID !== action.payload?.id)
        }
        return {
          ...state,
          deleteReviewProgress: false,
          deleteReviewError: error,
          reviewWorkflows: newDataArr,
        };
      default:
          return state;
  }
}