import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import ReactSelect from 'react-select'
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { ClickAwayListener, InputAdornment } from '@material-ui/core';
import SqureButton from 'Components/Common/SqureButton';
import CommonCss from 'commonCss';
import DateFilter from "Components/Common/Filters/DateFilter";

import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Grid,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  OutlinedInput as OutlinedInputMIUI,
} from "@material-ui/core";
import classnames from "classnames";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';
// assets
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg";
import AllTeamsDialog from "./AllTeamsDialog";
import { showSnackBar } from "redux/snackbar/action";
// components
import AppBarV2 from "../../AppBarV2";
import ActiveImage from "../../../../assets/images/active_users.svg";
import TotalUser from "../../../../assets/images/total_users.svg";
import OutlinedInput from "../../../Common/Input/OutlinedInput";

import {
  fetchVendor,
  fetchVendorActivity,
  fetchVendorActivityChartdata,
  fetchPossibleApps,
  fetchPossibleSources
} from "redux/metricapplication/action";
import { fetchOrgGroup, updateLicensePurchaged } from "redux/org/group/action";
import { showOrgUserUpdateDialog } from "redux/org/user/action";

import Alert from "@material-ui/lab/Alert";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import moment from "moment";
import * as Env from "../../../../util/Environment";
import LinesEllipsis from "react-lines-ellipsis";
import DefaultVendor from "../../../../assets/images/default-vendor.png";
import ComingSoonDialog from "../../../Common/ComingSoonDialog";
import Image from 'Components/Common/image.jsx'
import { CommonFn } from 'services/commonFn';

const connectedProps = (state) => ({
  fetchVendorProgress: state.metricApp.fetchVendorProgress,
  vendor: state.metricApp.vendor,
  fetchVendorError: state.metricApp.fetchVendorError,
  activityFetchProgress: state.metricApp.activityFetchProgress,
  activities: state.metricApp.activities,
  chartDataFetchProgress: state.metricApp.chartDataFetchProgress,
  dailyChartData: state.metricApp.dailyChartData,
  groups: state.orgGroup.groups,
  fetchGroupProg: state.orgGroup.fetchGroupProg,
  updateLicenseProg: state.orgGroup.updateLicenseProg,
  user: state.authUser.user,
  userType: state.authUser.user?.Role,

  fetchPossibleAppsProgress: state.metricApp.fetchPossibleAppsProgress,
  possibleApps: state.metricApp.possibleApps,
  fetchPossibleAppsError: state.metricApp.fetchPossibleAppsError,
  fetchPossibleSourcesProgress: state.metricApp.fetchPossibleSourcesProgress,
  possibleSources: state.metricApp.possibleSources,
  fetchPossibleSourcesError: state.metricApp.fetchPossibleSourcesError,
});

const connectionActions = {
  fetchVendor: fetchVendor,
  fetchVendorActivity: fetchVendorActivity,
  fetchVendorActivityChartdata: fetchVendorActivityChartdata,
  showOrgUserUpdateDialog: showOrgUserUpdateDialog,
  fetchOrgGroup: fetchOrgGroup,
  updateLicensePurchaged: updateLicensePurchaged,
  fetchPossibleApps: fetchPossibleApps,
  fetchPossibleSources: fetchPossibleSources,
  showSnackBar: showSnackBar,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    section: {
      display: "flex",
    },
    sectionAction: {
      flex: 1,
      textAlign: "right",
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    menuList: {
      padding: '0px',
      "& > hr": {
        "&:first-child": {
          display: 'none'
        },
      }
    },
    threeDot: {
      position: "absolute",
      zIndex: 11,
      right: -15,
      top: -40,
      padding: 5
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "50px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff"
        },
      },
    },
    select: {
      background: "#fff",
      width: "175px",
      textAlign: "left",
    },
    meta: {
      margin: theme.spacing(3, 0),
    },
    card: {
      boxShadow: "none",
      '&.details-border': {
        border: '1px solid #ddd'
      }
      // height: "176px",
    },
    statsContainer: {
      display: "flex",
      alignItems: 'center',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(1),
    },
    vendorInfo: {
      textAlign: "center",
      flex: 0.8,
    },
    stats: {
      flex: 1,
      textAlign: "center",
      flexDirection: 'column',
      boxSizing: 'border-box',
      display: 'flex',
      placeContent: 'center space-around',
      alignItems: 'center',
      maxWidth: '150px',
      paddingTop: 15,
      paddingBottom: 15,
    },
    brdbt: {
      borderBottom: "solid 1px #ccc",
    },
    divider: {
      borderRight: "solid 1px #ccc",
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      height: "auto",
      minHeight: "400px",
    },
    active: {
      display: "inline-block",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#33C162",
    },
    inactive: {
      display: "inline-block",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#9FA0A5",
    },
    vendirImageContainer: {
      // height: "100px",
      // paddingTop:theme.spacing(2),
      // verticalAlign: "middle",
      // textAlign: "center",
      // display: "table-cell",
      height: '80px',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center'
    },
    vendirImage: {
      width: 'auto',
      height: 'auto',
      maxWidth: '80px',
      maxHeight: '80px',
      margin: '0px auto'
    },
    stateTitle: {
      color: "#20253A",
      opacity: 0.7,
      height: 20,
      fontSize: theme.spacing(1.8),
      marginTop: theme.spacing(1),
    },
    stateValue: {
      color: "#20253A",
      fontSize: theme.spacing(3.2),
      fontWeight: 400
    },
    vendorTitle: {
      // fontWeight: 900,
      // fontSize: theme.spacing(2),
      overflow: 'hidden',
      fontSize: theme.spacing(2.4),
      fontWeight: 400
      // marginBottom: '30px'
    },
    tempSection: {
      position: "absolute",
      zIndex: 2,
      width: 200,
      opacity: 0,
      height: 47,
      bottom: 2,
    },
    downloadSection: {
      position: "relative",
      '& [class*=MuiSelect-root]': {
        padding: '11px 20px'
      }
    },
    chartSection: {
      maxWidth: "calc(100vw - 412px)",
      overflowX: "auto",
      overflowY: "hidden",
      padding: "0px 10px 10px 10px",
    },
    chartCard: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      justifyContent: "space-between",
      padding: 0,
      height: "100%",
    },
    chartLabel: {
      color: "#4B4B4B",
      fontSize: 10,
      margin: 0,
      textAlign: "center",
      padding: 10,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      cursor: 'pointer'
    },
    department: {
      cursor: "pointer"
    },
    dropdownTeamCol: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 20,
      '& [class*="-container"]': {
        flex: 1,
        height: 39,
        minWidth: 150,
        '& .select__placeholder': {
          color: '#000',
          fontSize: '16px'
        },
        '& [class*="-ValueContainer"]': {
          color: '#000',
          fontSize: '16px',
          width: 200
        }
      }
    },
    OutlinedInputMIUI: {
      marginRight: 5,
      '& [class*="MuiInputBase-input-"]': {
        padding: 5,
      }
    },
    rootContainer: {
      ...CommonCss.rootContainer,
      padding: 0
    },
    container: {
      padding: theme.spacing(4)
    },
    filterTitle: {
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    teamFilter: {
      // marginLeft: 10,
      marginTop: 10
    },
    filterBtn: {
      height: 39,
      fontSize: 16,
      borderColor: '#cccccc'
    },
    more: {
      color: "#4285F4",
      textDecoration: "underline",
      cursor: "pointer",
    }
  });

class MetricVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      duration: 30,
      department: null,
      apps: [],
      sources: [],
      openDownloadModal: false,
      userState: 20,
      dialogCustomDate: false,
      dialogDateSelected: false,
      startDate: CommonFn.getPreviousDate(30),
      endDate: new Date(),
      licenseProduced: 0,
      edit: false,
      anchorEl: null,
      openShowMoreTeamDialog: false, 
      moreTeams: []
    };
    this.btnRef = React.createRef();
    this.fetchActivity = this.fetchActivity.bind(this);
    this.fetchOrgGroups = this.fetchOrgGroups.bind(this);
    this.fetchVendor = this.fetchVendor.bind(this);
  }
  componentDidMount() {
    const { duration } = this.props
    this.setState({
      duration,
    }, () => {
      this.fetchVendor();
      this.fetchActivity();
      this.fetchOrgGroups();
      this.props.fetchPossibleSources(this.props.match?.params?.vendor_id || this.props?.params?.id);
      this.props.fetchPossibleApps(this.props.match?.params?.vendor_id || this.props?.params?.id);
    })
  }

  fetchOrgGroups() {
    this.props.fetchOrgGroup(0, 15, this.state.departmentquery);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  fetchVendor(dates) {
    var departmentId = (this.state.department == null || this.state.department.value === undefined) ? 0 : this.state.department.value
    var appsId = this.state.apps?.map(O=>O.value)?.join();
    var sourcesId = this.state.sources?.map(O=>O.value)?.join();
    // var duration = {
    //   from: custom ? moment(this.state.startDate).toISOString() : moment().subtract(this.state.duration, "days").toISOString(),
    //   to: custom ? moment(this.state.endDate).toISOString() : moment().toISOString()
    // }
    let startDate= CommonFn.getPreviousDate(dates?.duration || 30);
    let endDate= new Date();

    var duration = {};
    if (dates !== undefined && !dates.isAny && !dates.isCustomDates && startDate && endDate) {
      duration.from = moment(startDate).toISOString()
      duration.to = moment(endDate).toISOString()
    } else if(dates !== undefined && dates.isAny){
      let startDaten= CommonFn.getPreviousDate(90);
      let endDaten= new Date();
      duration.from = moment(startDaten).toISOString()
      duration.to = moment(endDaten).toISOString()
    } else {
      duration.from = moment(this.state.startDate).toISOString()
      duration.to = moment(this.state.endDate).toISOString()
    }

    var vendor_id = -1;
    if (this.props.params !== undefined && this.props.params.id !== undefined) {
      vendor_id = this.props.params.id
    } else if (this.props.match !== undefined && this.props.match.params !== undefined && this.props.match.params.vendor_id !== undefined) {
      vendor_id = this.props.match.params.vendor_id
    }
    this.props.fetchVendor(vendor_id, departmentId, duration, appsId, sourcesId);
  }

  fetchActivity(dates) {
    var departmentId = (this.state.department == null || this.state.department.value === undefined) ? 0 : this.state.department.value
    var appsId = this.state.apps?.map(O=>O.value)?.join();
    var sourcesId = this.state.sources?.map(O=>O.value)?.join();
    // var duration = {
    //   from: custom ? moment(this.state.startDate).toISOString() : moment().subtract(this.state.duration, "days").toISOString(),
    //   to: custom ? moment(this.state.endDate).toISOString() : moment().toISOString()
    // }
    let startDate= CommonFn.getPreviousDate(dates?.duration || 30);
    let endDate= new Date();

    var duration = {};
    if (dates !== undefined && !dates.isAny && !dates.isCustomDates && startDate && endDate) {
      duration.from = moment(startDate).toISOString()
      duration.to = moment(endDate).toISOString()
    } else if(dates !== undefined && dates.isAny){
      let startDaten= CommonFn.getPreviousDate(90);
      let endDaten= new Date();
      duration.from = moment(startDaten).toISOString()
      duration.to = moment(endDaten).toISOString()
    }else {
      duration.from = moment(this.state.startDate).toISOString()
      duration.to = moment(this.state.endDate).toISOString()
    }

    var vendor_id = -1;
    if (this.props.params !== undefined && this.props.params.id !== undefined) {
      vendor_id = this.props.params.id
    } else if (this.props.match !== undefined && this.props.match.params !== undefined && this.props.match.params.vendor_id !== undefined) {
      vendor_id = this.props.match.params.vendor_id
    }

    this.props.fetchVendorActivity(
      vendor_id,
      duration,
      departmentId,
      this.state.userState,
      appsId, 
      sourcesId
    );
    this.props.fetchVendorActivityChartdata(
      vendor_id,
      duration,
      departmentId,
      appsId, 
      sourcesId
    );
  }

  componentDidUpdate(prevProps) {

    if (!this.props.updateLicenseProg && prevProps.updateLicenseProg) {
      this.props.showSnackBar("Total License Count updated successfully", "success", 3000);
      this.setState({ edit: false })
      this.fetchVendor();
    }

    if (!this.props.fetchVendorProgress && prevProps.fetchVendorProgress) {
      this.setState({ licenseProduced: this.props.vendor?.licenseProduced || 0 })
    }
    if (
      this.props.fetchVendorProgress === false &&
      prevProps.fetchVendorProgress === true
    ) {
      if (this.props.fetchVendorError) {
        var error = this.props.fetchVendorError;
        this.setState({ error: error.message });
      }
    }

    if (this.state.dialogDateSelected) {
    }
  }

  selectDownload = (isModalOpen) => {
    this.setState({ openDownloadModal: isModalOpen });
  };

  onuserStateChange = (value) => {
    this.setState({
      userState: value
    }, () => {
      this.fetchActivity()
    })
  }

  handleDateSelection = (value) => {
    if (typeof value !== 'undefined') {
      this.setState({
        dialogCustomDate: value,
        dialogDateSelected: false
      })
    }
  }

  handleDateSelected = () => {
    this.setState({
      dialogCustomDate: false,
      dialogDateSelected: true
    }, () => {
      this.fetchVendor(true);
      this.fetchActivity(true);
    })
  }

  setStartDate = (value) => {
    this.setState({
      startDate: value
    })
  }

  setEndDate = (value) => {
    this.setState({
      endDate: value
    })
  }

  render() {
    const classes = this.props.classes;
    const { isHideDateAction, isHideShare, isHideUserActivity } = this.props;
    var data = [];
    var labels = [];
    var activityDataMap = {};
    if (this.props.dailyChartData?.length > 0) {
      this.props.dailyChartData.forEach((item) => {
        var date = moment(item.activityDate, "YYYY-MM-DD").format("MMM DD");
        activityDataMap[date] = item.userSessions;
      });
    }
    var startDate = moment().subtract(this.state.duration, "days");
    var endData = moment();
    for (
      var i = startDate.toDate().getTime();
      i <= endData.toDate().getTime();
      i = moment(i).add(1, "day").toDate().getTime()
    ) {
      var date = moment(i).format("MMM DD");
      labels.push(date);
      if (activityDataMap[date] !== undefined) {
        data.push(activityDataMap[date]);
      } else {
        data.push(0);
      }
    }
    const setWidth = data.length * 30;

    let possibleApps = [];
    let possibleSources = [];
    this.props.possibleSources?.sources?.forEach((item) => {
      possibleSources.push({
        value: item,
        label: item,
      })
    })
    this.props.possibleApps?.apps?.forEach((item) => {
      possibleApps.push({
        value: item.application_id,
        label: item.name
      })
    })
    
    if (this.state.error.length !== 0) {
      return (
        <div>
          {!this.props.params?.id && <AppBarV2 title={this.props.vendor?.name} withBack={true} />}
          <div className={classes.root}>
            <Alert variant="filled" severity="error">
              {this.state.error}
            </Alert>
          </div>
        </div>
      );
    }

    if (this.props.fetchVendorProgress || this.props.chartDataFetchProgress) {
      return (
        <div>
          {!this.props.params?.id && <AppBarV2 title={this.props.vendor?.name} withBack={true} />}
          <div className={classes.root} style={{textAlign:'center',marginTop:30}}>
            <CircularProgress />
          </div>
        </div>
      );
    }

    if (this.props.vendor == null) {
      return (
        <div>

          {!this.props.params?.id && <AppBarV2 title={this.props.vendor?.name} withBack={true} />}
          <div className={classes.root}>
            <Alert variant="filled" severity="error">
              Something Went Wrong.
            </Alert>
          </div>
        </div>
      );
    }

    const vendor = this.props.vendor;
    const duration = this.state.duration;

    // let _from = this.state.duration === 100 ? moment(this.state.startDate).toISOString() : moment().subtract(this.state.duration, "days").toISOString();
    // let _to = this.state.duration === 100 ? moment(this.state.endDate).toISOString() : moment().toISOString();
    // _from = moment(_from).format('YYYY-MM-DD')
    // _to = moment(_to).format('YYYY-MM-DD')

    const productId = this.props.match?.params?.vendor_id || this.props?.params?.id
    return (
      <>
        <div>
          {!this.props.params?.id && <AppBarV2 title={vendor.name} withBack={true} dropdown={true}></AppBarV2>}
          <ComingSoonDialog
            feature="Metric Vendor"
            comingSoonOpen={this.state.openDownloadModal}
            closeDownload={this.selectDownload}
          />
          <div className={isHideDateAction ? "" : classes.container}>
            {!isHideDateAction && (
              <div className={classes.root} style={{
                display: 'flex',
                marginBottom: 10,
                placeContent: 'center space-between',
              }}>
                <div></div>

                <div></div>
              </div>
            )}
            <div className={classnames(classes.root, classes.rootContainer)}>
              <Grid container spacing={1} alignItems="center">

                {!isHideDateAction && <Grid item xs={12}>
                  <Grid item>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Filters
                    </Typography>
                    <div className={classes.dropdownTeamCol}>
                      <div>
                        <DateFilter
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          duration={this.state.duration}
                          btnClass={classes.filterBtn}
                          onChangeDates={(dates) => {
                            let startDate,endDate;
                            if(dates.isCustomDates){
                              startDate= dates.startDate;
                              endDate= dates.endDate;
                            }else{
                              startDate= CommonFn.getPreviousDate(dates.duration);
                              endDate= new Date();
                            }
                            this.setState({
                              startDate: startDate,
                              endDate: endDate,
                              duration: dates.duration
                            }, () => {
                              this.fetchActivity(dates);
                              this.fetchVendor(dates)
                            })
                          }}
                        />
                      </div>
                      <div className={classes.teamFilter}>
                        <ReactSelect
                          isClearable
                          isSearchable={true}
                          options={this.props.groups.map((item) => {
                            return {
                              value: item.ID,
                              label: item.Name
                            }
                          })}
                          classNamePrefix="select"
                          placeholder="Team"
                          onInputChange={(e) => {
                            this.setState({
                              departmentquery: e
                            }, () => {
                              this.fetchOrgGroups()
                            })

                          }}
                          isLoading={this.props.fetchGroupProg}
                          onChange={(e, action) => {
                            this.setState({
                              department: e
                            }, function () {
                              this.fetchVendor();
                              this.fetchActivity();
                            })

                          }}
                          defaultValue={this.state.department}
                          value={this.state.department}
                        />
                      </div>

                      <div className={classes.teamFilter}>
                        <ReactSelect
                          isMulti
                          isClearable
                          isSearchable={true}
                          options={possibleApps}
                          classNamePrefix="select"
                          placeholder="Apps"
                          onChange={(e, action) => {
                            this.setState({
                              apps: e
                            }, function () {
                              this.fetchVendor();
                              this.fetchActivity();
                            })

                          }}
                          defaultValue={this.state.apps}
                          value={this.state.apps}
                        />
                      </div>

                      <div className={classes.teamFilter}>
                        <ReactSelect
                          isMulti
                          isClearable
                          isSearchable={true}
                          options={possibleSources}
                          classNamePrefix="select"
                          placeholder="Source"
                          onChange={(e, action) => {
                            this.setState({
                              sources: e
                            }, function () {
                              this.fetchVendor();
                              this.fetchActivity();
                            })

                          }}
                          defaultValue={this.state.sources}
                          value={this.state.sources}
                        />
                      </div>

                      {false && !isHideShare && <div className={classes.downloadSection}>
                        <div
                          className={classes.tempSection_rmv}
                        >
                          <FormControl
                            variant="outlined"
                            className={classes.select}
                          >
                            <Select value={"Share"}>
                              <MenuItem onClick={() => this.selectDownload(true)} value="Share">Share</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>}
                    </div>

                  </Grid>
                  {/* <p style={{ padding: 0, margin: 0 }}> Filter: Start Date - <span style={{ fontWeight: 600 }}>{_from}</span>&nbsp;&nbsp;&nbsp; End Date - <span style={{ fontWeight: 600 }}>{_to}</span></p> */}
                </Grid>}
                {false && <Grid item xs={6} >
                  <div className={classes.dropdownTeamCol}>
                    <div className={classes.teamFilter}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={this.props.groups.map((item) => {
                          return {
                            value: item.ID,
                            label: item.Name
                          }
                        })}
                        classNamePrefix="select"
                        placeholder="Team"
                        onInputChange={(e) => {
                          this.setState({
                            departmentquery: e
                          }, () => {
                            this.fetchOrgGroups()
                          })

                        }}
                        isLoading={this.props.fetchGroupProg}
                        onChange={(e, action) => {
                          this.setState({
                            department: e
                          }, function () {
                            this.fetchVendor();
                            this.fetchActivity();
                          })

                        }}
                        defaultValue={this.state.department}
                        value={this.state.department}
                      />
                    </div>
                    {!isHideShare && <div className={classes.downloadSection}>
                      <div
                        className={classes.tempSection_rmv}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.select}
                        >
                          <Select value={"Share"}>
                            <MenuItem onClick={() => this.selectDownload(true)} value="Share">Share</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>}
                  </div>
                </Grid>}

              </Grid>
              <div className={classes.meta}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Card className={classnames(classes.card, this.props.params?.id ? 'details-border' : '')}>
                      <div className={classes.statsContainer}>
                        <div className={classes.vendorInfo}>
                          <div className={classes.vendirImageContainer}>
                            {vendor.logo.length !== 0 && (
                              <Image
                                src={vendor.logo}
                                className={classes.vendirImage} />
                            )}
                            {vendor.logo.length === 0 && (
                              <Image
                                src={DefaultVendor}
                                className={classes.vendirImage}
                              />
                            )}
                          </div>
                          <Typography
                            variant={"h6"}
                            className={classes.vendorTitle}
                          >
                            <LinesEllipsis
                              text={vendor.name}
                              maxLine="2"
                              ellipsis="..."
                              basedOn="letters"
                            />
                          </Typography>
                        </div>
                        <div style={{ flex: 1 }}>
                          <Grid container alignItems="center">
                            <div className={classnames(classes.stats, classes.divider, classes.brdbt)}>
                              <Image src={TotalUser} alt="active" />
                              <Typography className={classes.stateTitle}>
                                Total License
                              </Typography>
                              {!this.state.edit && (
                                <Typography
                                  variant={"h6"}
                                  className={classes.stateValue}
                                >
                                  {(this.props.user?.Role !== "OrgUser" || this.props.isEditable) ? (
                                    <>
                                      <span style={{ marginLeft: 25 }}>{vendor.licenseProduced}</span>
                                      &nbsp;
                                      <span>
                                        <EditIcon
                                          fontSize={"small"}
                                          style={{ fontSize: 16, cursor: 'pointer' }}
                                          className={classes.editIcon}
                                          onClick={() => this.setState({ edit: true })}
                                        />
                                      </span>
                                    </>
                                  ) : (
                                    <span>{vendor.licenseProduced}</span>
                                  )}
                                </Typography>
                              )}
                              {this.state.edit && (
                                <ClickAwayListener onClickAway={() => {
                                  this.props.updateLicensePurchaged(this.state.licenseProduced, productId)
                                }}>
                                  <OutlinedInputMIUI
                                    type="number"
                                    className={classes.OutlinedInputMIUI}
                                    value={this.state.licenseProduced}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      let value = 0
                                      try {
                                        value = parseInt(e.target.value)
                                      } catch (error) {
                                        value = 0
                                      }
                                      this.setState({ licenseProduced: value })
                                    }}
                                  />
                                </ClickAwayListener>
                              )}
                            </div>
                            <div className={classnames(classes.stats, classes.brdbt)}>
                              <Image src={TotalUser} alt="active" />
                              <Typography className={classes.stateTitle}>
                                Total Users
                              </Typography>
                              <Typography variant={"h6"} className={classes.stateValue}>
                                {vendor.totalUser}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid container alignItems="center">
                            <div className={classnames(classes.stats, classes.divider)}>
                              <Image src={ActiveImage} alt="active" />
                              <Typography className={classes.stateTitle}>
                                Active Users
                              </Typography>
                              <Typography variant={"h6"} className={classes.stateValue}>
                                {Math.ceil(vendor.activeUser)}
                              </Typography>
                            </div>
                            <div className={classnames(classes.stats)}>
                              <Image src={ActiveImage} alt="active" />
                              <Typography className={classes.stateTitle}>
                                Inactive Users
                              </Typography>
                              <Typography variant={"h6"} className={classes.stateValue}>
                                {Math.ceil(vendor.inactiveUser)}
                              </Typography>
                            </div>
                          </Grid>
                        </div>
                        {/* <div className={classes.stats}>
                        <Image src={InActiveImage} alt="active" />
                        <Typography className={classes.stateTitle}>
                          Monthly Active
                        </Typography>
                        <Typography variant={"h6"} className={classes.stateValue}>
                          {Math.ceil(vendor.monthlyActive)}
                        </Typography>
                      </div> */}
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className={classnames(classes.card, classes.chartCard, this.props.params?.id ? 'details-border' : '')}>
                      {this.props.chartDataFetchProgress && <CircularProgress />}

                      {!this.props.chartDataFetchProgress && (
                        <>
                          <p className={classes.chartLabel}>User Activity</p>
                          <div className={classnames(classes.chartSection)}>
                            <div style={{ width: setWidth }}>
                              <Bar
                                data={{
                                  labels: labels,
                                  datasets: [
                                    {
                                      backgroundColor: "#49B8FA",
                                      label: "User Activity",
                                      data: data,
                                      maxBarThickness: 20,
                                    },
                                  ],
                                }}
                                options={{
                                  maintainAspectRatio: false,
                                  responsive: true,
                                  scaleShowValues: true,
                                  cornerRadius: 20,
                                  plugins: {
                                    datalabels: {
                                      display: false,
                                      render: () => { }
                                    },
                                    legend: {
                                      display: false,
                                    },
                                  },
                                  scales: {
                                    x: {
                                        barPercentage: 0.45,
                                        ticks: {
                                          callback: function (value,index,values) {
                                            var month_wise_min_date = {};

                                            labels.forEach((date) => {
                                              var dateMonth = moment(date, "MMM DD").format("MMM");
                                              if (month_wise_min_date[dateMonth] === undefined) {
                                                month_wise_min_date[dateMonth] = date;
                                              }
                                            
                                              if (moment(month_wise_min_date[dateMonth],"MMM DD").toDate().getTime() > moment(date, "MMM DD").toDate().getTime()) {
                                                month_wise_min_date[dateMonth] = date;
                                              }
                                            });
                                            var dateMonth = moment(labels[parseInt(value)],"MMM DD").format("MMM");
                                            var date = parseInt(moment(labels[parseInt(value)], "MMM DD").format("DD"));
                                            var endDate = parseInt(moment(labels[parseInt(value)], "MMM DD").endOf("month").format("DD"));
                                            if (month_wise_min_date[dateMonth] === labels[parseInt(value)]) {
                                              return labels[parseInt(value)];
                                            } else {
                                              if (index % Math.ceil(duration / 10) !== 0 ) {
                                                return moment(labels[parseInt(value)],"MMM DD").format("DD");
                                              } else {
                                                if (index !== 0 && (date === endDate || date === 1)) {
                                                  return "";
                                                } else {
                                                  return moment(labels[parseInt(value)],"MMM DD").format("DD");
                                                }
                                              }
                                            }
                                          },
                                          maxRotation: 0,
                                          minRotation: 0,
                                          autoSkip: false,
                                          font: {
                                            size: 9,
                                          },
                                          
                                        },
                                        grid: {
                                          display: false,
                                        }
                                      },
                                    y: {
                                        ticks: {
                                          beginAtZero: true,
                                          font: {
                                            size: 10,
                                            padding: 5,
                                          },
                                          stepSize: 1,
                                        },
                                        grid: {
                                          drawBorder: false,
                                        },
                                      },
                                  },
                                }}
                              />{" "}
                            </div>{" "}
                          </div>{" "}
                        </>
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </div>
              {!isHideUserActivity && (
                <>
                  <div className={classes.section}>
                    <div className={classes.sectionTitle}>
                      <Typography variant={"h6"}>User Activity</Typography>
                    </div>
                    <div className={classes.sectionAction}>
                      <FormControl variant="outlined" className={classes.formControl} size="small">
                        <Select value={this.state.userState} onChange={(e) => { this.onuserStateChange(e.target?.value) }}>
                          {/* <MenuItem value={10} >All Users</MenuItem> */}
                          <MenuItem value={20} >Active Users</MenuItem>
                          <MenuItem value={30} >Inactive Users</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Paper className={classnames(classes.card, classes.paper, this.props.params?.id ? 'details-border' : '')}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell align="center">Teams</TableCell>
                          <TableCell align="center">Last Logged In</TableCell>
                          <TableCell align="center">Unique Sessions</TableCell>
                          {/* <TableCell align="center">&nbsp;</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!this.props.activityFetchProgress && this.props.activities?.map((activity, i) => {
                          var lastLogin = activity.lastLogin === null || activity.lastLogin === '' ? '-' : moment(activity.lastLogin).format(Env.get("DATE_FORMAT.DATETIME"));
                          return (
                            <TableRow key={i}>
                              <TableCell component="th" scope="row">
                                {activity.name}
                              </TableCell>
                              <TableCell>{activity.email}</TableCell>
                              <TableCell align="center" className={classes.department} >
                                {(!activity.userGroups || activity.userGroups?.length === 0) && "-"}
                                {activity.userGroups && activity.userGroups?.length > 0 && (<>
                                  <div>{activity.userGroups[0].Name}</div>
                                  {activity.userGroups?.length > 1 && <div className={classes.more} onClick={()=>{
                                    this.setState({openShowMoreTeamDialog: true, moreTeams: activity.userGroups})
                                  }}>More</div>}
                                  </>
                                )}
                              </TableCell>
                              <TableCell align="center">{lastLogin}</TableCell>
                              <TableCell align="center">
                                {activity.userSessions}
                              </TableCell>
                              {/* {(this.props.user?.Role !== "OrgUser" || this.props.isEditable) && <TableCell align="center" onClick={() => {
                                this.props.showOrgUserUpdateDialog(activity.refUserId);
                              }}>
                                <EditIcon fontSize={"small"} className={classes.editIcon} />
                              </TableCell>} */}
                            </TableRow>
                          );
                        })}

                        {!this.props.activityFetchProgress && this.props.activities !== undefined && this.props.activities !== null && this.props.activities.length <= 0 && <TableRow>
                          <TableCell style={{textAlign:'center'}} component="th" scope="row" colSpan={5}>No Users</TableCell>
                        </TableRow>}
                        {this.props.activityFetchProgress && <TableRow>
                          <TableCell style={{textAlign:'center'}} component="th" scope="row" colSpan={5}><CircularProgress/></TableCell>
                        </TableRow>}
                      </TableBody>
                    </Table>
                  </Paper>
                </>
              )}
            </div>
          </div>
        </div>
        {
          this.state.dialogCustomDate &&
          <CustomDateSelect
            handleDateSelection={this.handleDateSelection}
            handleDateSelected={this.handleDateSelected}
            dialogCustomDate={this.state.dialogCustomDate}
            startDate={this.state.startDate}
            setStartDate={this.setStartDate}
            endDate={this.state.endDate}
            setEndDate={this.setEndDate}
          />
        }
        {this.state.openShowMoreTeamDialog && <AllTeamsDialog 
          data={this.state.moreTeams}
          onClose={()=>{
            this.setState({
              openShowMoreTeamDialog: false,
              moreTeams: []
            })
          }}
        />}
      </>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  addCriteriaDatePicker: {
    marginTop: 15,
    background: '#fff',
    position: 'relative',
    "& .react-datepicker__input-container > div": {
      display: 'flex',
      alignItems: 'center',
      "& [class*=MuiInputBase-input-]": {
        width: 160,
        height: 10,
      },
      "& [class*=MuiInputAdornment-root-]": {
        marginRight: 10,
      }
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8)
    }
  },
  inputlabel: {
    marginTop: -32,
    background: '#fff',
    padding: 5,
    borderRadius: 2
  }
}));

export function CustomDateSelect(props) {
  const classes = useStyles();

  // const [start, setStart] = useState(new Date())
  // const [end, setEnd] = useState(new Date())

  return (
    <div>
      <Dialog open={props.dialogCustomDate} onClose={() => { props.handleDateSelection(false) }} id="dateDialog">
        <DialogTitle>Date Filter</DialogTitle>
        <DialogContent>
          <div className={classes.addCriteriaDatePicker}>
            <DatePicker
              minDate={props.isMinData ? new Date() : null}
              selected={props.startDate}
              onChange={date => {
                props.setStartDate(date)
              }}
              dateFormat="MMM do yyyy"
              portalId="dateDialog"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              // className={classes.datePicker}
              customInput={
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount" className={classes.inputlabel}>Start Date</InputLabel>
                  <OutlinedInput
                    placeholder="YYYY-MM-DD"
                    id="outlined-adornment-amount"
                    value={moment(props.startDate).format('YYYY-MM-DD')}
                    startAdornment={
                      <InputAdornment>
                        <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment>
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              }
            />
          </div>
          <div className={classes.addCriteriaDatePicker}>
            <DatePicker
              minDate={props.isMinData ? new Date() : null}
              selected={props.endDate}
              onChange={date => {
                props.setEndDate(date)
              }}
              dateFormat="MMM do yyyy"
              portalId="dateDialog"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount" className={classes.inputlabel}>End Date</InputLabel>
                  <OutlinedInput
                    placeholder="YYYY-MM-DD"
                    id="outlined-adornment-amount"
                    value={moment(props.endDate).format('YYYY-MM-DD')}
                    startAdornment={
                      <InputAdornment>
                        <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment>
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              }
            />
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '0 25px 25px 25px' }}>
          <SqureButton variant={"outlined"} onClick={() => { props.handleDateSelection(false) }}>Cancel</SqureButton>&nbsp;&nbsp;
          <SqureButton variant={"contained"} onClick={() => { props.handleDateSelected() }}>Select</SqureButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CustomDateSelect.defaultProps = {
  isMinData: false
}
MetricVendor.defaultProps = {
  params: {},
  isHideDateAction: false,
  isHideShare: false,
  isHideUserActivity: false,
  duration: 30,
}
export default connector(compose(withStyles(styles))(MetricVendor));





