import { Types } from './types';


const initState   =   {
    /**
     *  Create Questions Start
     */
    addRequirementOptionInprogress: false,
    addRequirementOptionSuccess: null,
    addRequirementOptionError: null,
    quizOptions:[]
}



export  function criteriaMultiChoice(state=initState,action){
    switch(action.type){
        /**
         * 
         */
        case Types.QUIZ_OPTIONS: {
            let options = action.payload.options;
            return {
                ...state,
                quizOptions: options || []
            }
        }
         
            
        case Types.START_ADD_REQIREMENT_OPTION: 
            return {
                ...state,
                addRequirementOptionInprogress: true,
                addRequirementOptionSuccess: null,
                addRequirementQuestionOptionError: null,
            }
            
        case Types.END_ADD_REQIREMENT_OPTION: {
                    let success = action.payload.success;
                    let error = action.payload.error;
                    let result = {}
                    result.addRequirementOptionInprogress = false;
                    if(error){
                        result.addRequirementOptionSuccess = null;
                        result.addRequirementOptionError = error.message;
                    }else{
                        result.addRequirementOptionSuccess = success;
                        result.addRequirementOptionError = null;
                    }
                return {
                    ...state,
                    ...result
                }
            }
        default:
          return state;
    }
}
