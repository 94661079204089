import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { ChromePicker } from "react-color";

import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);
const styles = (theme) =>
  createStyles({
    formGroup: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    supportLabel: {
      marginTop: 3,
      marginBottom: 3,
      display: "inline-block",
      marginRight: 30,
    },
    color: {
      width: "36px",
      height: "14px",
      borderRadius: "2px",
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  });

class Color extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      displayColorPicker: false,
      timer: null,
    };
  }

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ value: color.rgb });
    clearTimeout(this.state.timer);
    const newTimer = setTimeout(() => {
      this.props.updateValue(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    }, 500);
    this.setState({ timer: newTimer });
  };

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.formGroup}>
        <span className={classes.supportLabel}>{this.props.label}:</span>
        <div className={classes.swatch} onClick={this.handleClick}>
          {typeof this.state.value === "string" ? (
            <div className={classes.color} style={{ background: this.state.value }} />
          ) : (
            <div className={classes.color} style={{ background: `rgba(${this.state.value?.r}, ${this.state.value?.g}, ${this.state.value?.b}, ${this.state.value?.a})` }} />
          )}
        </div>
        {this.state.displayColorPicker ? (
          <div className={classes.popover}>
            <div className={classes.cover} onClick={this.handleClose} />
            <ChromePicker color={this.state.value} onChange={this.handleChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Color));
