import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Button, FormControl, MenuItem, Select, CircularProgress } from "@material-ui/core";
// import { FormControlLabel, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router-dom";
// import Alert from "@material-ui/lab/Alert";
// import CheckIcon from "@material-ui/icons/Check";
// import ClearIcon from "@material-ui/icons/Clear";
import classnames from "classnames";
// import Checkbox from "@material-ui/core/Checkbox";
import { addOrgSettings, fetchAllOrgSettings } from "redux/org/user/action";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import CalendarImage from "assets/images/calendar.svg";
// import OutlinedInput from "Components/Common/Input/OutlinedInput";
// import Image from "Components/Common/image.jsx";
// import moment from "moment";

const connectedProps = (state) => ({
  user: state.orgUser,
  fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
});

const connectionActions = {
  addOrgSettings: addOrgSettings,
  fetchAllOrgSettings: fetchAllOrgSettings,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    notificationRoot: {
      border: "1px solid #F2F2F2",
      margin: 20,
      minHeight: "69vh",
      padding: 10,
    },
    btnSection: {
      textAlign: "right",
      margin: "0 !important",
    },
    mainSection: {
      padding: 10,
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    formControl1: {
      padding: 0,
      display: "flex",
      border: "none",
      "& [class*=MuiInputBase-input-]": {
        transition: "none !important",
        fontSize: "13px",
      },
      "& [class*=MuiInput-formControl-]": {
        width: "100%",
        "&::before": {
          borderBottom: "unset",
        },
        "& [class*=MuiSelect-select-]": {
          "&:focus": {
            backgroundColor: "unset",
          },
        },
      },
    },
    errorMessage: {
      color: 'red',
      fontSize: '12px'
    }
  });

class NotificationConfigurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      EvaluationReminderValue: "",
      EvaluationReminderAfter: "",
      EvaluationReminderFrequency: "",
      ProductRenewalPeriod: "",
      ProductRenewalFrequency: "",
      ProductRenewalPeriodError: "",
      ProductRenewalFrequencyError: "",
      isLoading: true,
    };
    this.updateConfiguration = this.updateConfiguration.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.initConfiguration();
    }, 1000);
    this.setState({ 
      ProductRenewalPeriodError: "",
      ProductRenewalFrequencyError: "",
     })
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
      this.initConfiguration();
    }

    if (this.props.user.addOrgSettingsInprogress === false && prevProps.user.addOrgSettingsInprogress === true) {
      if (this.props.user.addOrgSettingsFailure == null) {
        this.props.fetchAllOrgSettings();
        this.props.showSnackBar("Updated Successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Failed to update fields.", "error", 3000);
      }
    }
  }

  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  }

  initConfiguration() {
    const { user } = this.props;
    const EvaluationReminderValue = this.getConfigValue(user?.fetchAllOrgSettingsSuccess, "SettingName", "EVALUATION_REMINDER") || "NO";
    const EvaluationReminderAfter = this.getConfigValue(user?.fetchAllOrgSettingsSuccess, "SettingName", "EVALUATION_REMINDER_AFTER");
    const EvaluationReminderFrequency = this.getConfigValue(user?.fetchAllOrgSettingsSuccess, "SettingName", "EVALUATION_REMINDER_FREQUENCY");
    const ProductRenewalPeriod = this.getConfigValue(user?.fetchAllOrgSettingsSuccess, "SettingName", "PRODUCT_RENEWAL_PERIOD");
    const ProductRenewalFrequency = this.getConfigValue(user?.fetchAllOrgSettingsSuccess, "SettingName", "PRODUCT_RENEWAL_FREQUENCY");


    this.setState({
      EvaluationReminderValue: EvaluationReminderValue,
      EvaluationReminderAfter: EvaluationReminderAfter,
      EvaluationReminderFrequency: EvaluationReminderFrequency,
      ProductRenewalPeriod: ProductRenewalPeriod,
      ProductRenewalFrequency: ProductRenewalFrequency,
      isLoading: false,
    });
  }
  updateConfiguration() {
    this.setState({ 
      ProductRenewalPeriodError: "",
      ProductRenewalFrequencyError: "",
     })
    const { EvaluationReminderFrequency, ProductRenewalPeriod, ProductRenewalFrequency } = this.state;
    // const { EvaluationReminderValue, EvaluationReminderAfter } = this.state;
    if(Number(ProductRenewalPeriod)<=0){
      this.setState({ ProductRenewalPeriodError: "must be greater than 0"});
      return;
    }
    if(Number(ProductRenewalFrequency)<=0){
      this.setState({ ProductRenewalFrequencyError: "must be greater than 0"});
      return;
    }
    
    var notificationConfig = [
      // {
      //   name: "EVALUATION_REMINDER",
      //   value: EvaluationReminderValue,
      // },
      {
        name: "PRODUCT_RENEWAL_PERIOD",
        value: ProductRenewalPeriod,
      },
      {
        name: "PRODUCT_RENEWAL_FREQUENCY",
        value: ProductRenewalFrequency,
      },
      {
        name: "EVALUATION_REMINDER_FREQUENCY",
        value: EvaluationReminderFrequency.toString(),
      },
    ];

    // if (EvaluationReminderValue === "YES") {
    //   notificationConfig.push(
    //     {
    //       name: "EVALUATION_REMINDER_AFTER",
    //       value: moment(EvaluationReminderAfter).toISOString(),
    //     },
    //     {
    //       name: "EVALUATION_REMINDER_FREQUENCY",
    //       value: EvaluationReminderFrequency.toString(),
    //     }
    //   );
    // }
    this.props.addOrgSettings(notificationConfig);
  }

  render() {
    const { classes } = this.props;
    const { isEdit, EvaluationReminderValue, EvaluationReminderAfter, EvaluationReminderFrequency, ProductRenewalPeriod, ProductRenewalFrequency, ProductRenewalPeriodError, ProductRenewalFrequencyError, isLoading } = this.state;
    console.log(EvaluationReminderValue, "reminderDate");
    if (isLoading) {
      return (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress />
        </div>
      );
    }
    var reminderDate = EvaluationReminderAfter !== undefined && EvaluationReminderAfter !== null && EvaluationReminderAfter !== "" ? new Date(EvaluationReminderAfter) : new Date();
    console.log(reminderDate, "reminderDate");
    return (
      <div className={classes.notificationRoot}>
        <div className={classes.btnSection}>
          {!isEdit && (
            <Button
              className={classes.newUserBtn}
              onClick={() => {
                this.setState({
                  isEdit: true,
                });
              }}
            >
              Edit
            </Button>
          )}
        </div>
        <div className={classes.mainSection}>
          
          {/* <div className={classes.formGroup}>
            <div className={classnames(classes.inputSection, classes.checkboxSection)} style={{ alignItems: "center" }}>
              {isEdit && (
                <div className={classnames(classes.inputSection, "inputSection")}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{ root: classes.root, checked: classes.checked }}
                        defaultChecked={EvaluationReminderValue === "YES" ? true : false}
                        onChange={() => {
                          var newValue = EvaluationReminderValue === "YES" ? "NO" : "YES";
                          this.setState({
                            EvaluationReminderValue: newValue,
                          });
                        }}
                      />
                    }
                    name="EvaluationReminderValue"
                  />
                </div>
              )}
              <div className={classes.checkBoxLabel}>
                <label className={classes.label} style={{ marginBottom: 0 }}>
                  Evaluation Reminder &nbsp;&nbsp;
                  {!isEdit && <>{EvaluationReminderValue === "YES" ? <CheckIcon /> : <ClearIcon />}</>}
                </label>
              </div>
            </div>
          </div> */}
          {/* {EvaluationReminderValue === "YES" && (
            <div className={classes.formGroup}>
              <label className={classes.label}>Reminder After</label>
              {!isEdit && <div className={classnames(classes.inputSection, "inputSection")}>{reminderDate ? moment(reminderDate).format("MMM Do YYYY") : ""}</div>}
              {isEdit && (
                <div className={classnames(classes.textSection, "textSection")}>
                  <DatePicker
                    selected={reminderDate}
                    onChange={(date) => {
                      this.setState({
                        EvaluationReminderAfter: date,
                      });
                    }}
                    portalId="evMetaDialog"
                    dateFormat="MMM do yyyy"
                             showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                    customInput={
                      <OutlinedInput
                        readOnlyInput={true}
                        error={this.state.reminder_date_error.length > 0}
                        helperText={this.state.reminder_date_error}
                        startAdornment={
                          <InputAdornment>
                            <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                          </InputAdornment>
                        }
                      />
                    }
                  />
                </div>
              )}
            </div>
          )} */}

          <div className={classes.formGroup}>
            <label className={classes.label}>Workflow collaborator reminder frequency</label>
            {!isEdit && <div className={classnames(classes.inputSection, "inputSection")}>{EvaluationReminderFrequency} Hours</div>}
            {isEdit && (
              <div className={classnames(classes.textSection, "textSection")}>
                <FormControl variant="outlined" className={classes.formControl1} size="small">
                  <Select
                    value={EvaluationReminderFrequency}
                    onChange={(e) => {
                      this.setState({
                        EvaluationReminderFrequency: e.target.value,
                      });
                    }}
                    size="small"
                  >
                    <MenuItem value={24} style={{ fontSize: 13 }}>
                      24 Hours
                    </MenuItem>
                    <MenuItem value={48} style={{ fontSize: 13 }}>
                      48 Hours
                    </MenuItem>
                    <MenuItem value={72} style={{ fontSize: 13 }}>
                      72 Hours
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>How many days before renewal do you want to be notified?</label>
            {!isEdit && (
              <div className={classnames(classes.inputSection, "inputSection")}>
                {ProductRenewalPeriod} {ProductRenewalPeriod ? "Days" : "NA"}
              </div>
            )}
            {isEdit && (
              <div className={classnames(classes.textSection, "textSection")}>
                <input
                  defaultValue={this.state.ProductRenewalPeriod}
                  className={classes.formControl}
                  placeholder="Enter number of days"
                  name="ProductRenewalPeriod"
                  type="number"
                  min="1"
                  onChange={(e) => {
                    this.setState({ ProductRenewalPeriod: e.target.value });
                  }}
                />
                <div className={classes.errorMessage}>{ProductRenewalPeriodError}</div>
 
              </div>
            )}
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>How often do you want to be notified about upcoming renewals? </label>
            {!isEdit && (
              <div className={classnames(classes.inputSection, "inputSection")}>
                {ProductRenewalFrequency} {ProductRenewalFrequency ? "Days" : "NA"}
              </div>
            )}
            {isEdit && (
              <div className={classnames(classes.textSection, "textSection")}>
                <input
                  defaultValue={this.state.ProductRenewalFrequency}
                  className={classes.formControl}
                  placeholder="Enter number of days"
                  name="ProductRenewalFrequency"
                  type="number"
                  min="1"
                  onChange={(e) => {
                    this.setState({ ProductRenewalFrequency: e.target.value });
                  }}
                />
                <div className={classes.errorMessage}>{ProductRenewalFrequencyError}</div>
 
              </div>
            )}
          </div>
        </div>

        {isEdit && (
          <div className={classes.buttonSection}>
            <>
              <Button
                onClick={() => {
                  this.updateConfiguration();
                }}
                variant="contained"
                color="secondary"
                className={classes.button}
                type="submit"
              >
                Update
              </Button>
              <Button
                variant="contained"
                style={{ background: "#fff" }}
                onClick={() => {
                  this.setState({
                    isEdit: false,
                  });
                }}
                className={classes.button}
                type="button"
              >
                Cancel
              </Button>
            </>
          </div>
        )}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(NotificationConfigurations));
