import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { checkWorkflowFinished } from "util/Common";

// Actions
import { fetchOrgWorkflowComponents, fetchWorkflowComponentDetails, fetchComponentDetails, fetchWorkflowComponentsForms, showWorkflowLoader, setSummaryType } from "redux/workflow/action";

import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action"

// Components
import PreFeedbackDialog from "./Components/PreFeedbackDialog";
import EvaluationDetailForm from "./Components/EvaluationDetailForm";
import RequirementGathering from "../Setup/index";
import Evaluation from "../InProgressV2/index";
import FeedbackForm from "./Components/FeedbackForm/index";
import Summary from "../Summary/index";
import WorkflowSummary from "./Components/Summary/index";
import Approval from "../Approval/index";
import SendNotificationForm from "./Components/SendNotificationForm";
import EmailForm from "./Components/EmailForm";
import FinishForm from "./Components/FinishForm";
import CustomForm from "./Components/CustomForm";
import IntegrationForm from "./Components/IntegrationForm";
import ProductShortlist from "./Components/ProductShortlist";

import { fetchWorkflowUserActivity } from "redux/evaluation/workflow/action";
import { fetchMasterComponents } from "redux/workflow/action";
import { showSnackBar } from "redux/snackbar/action";
import Dummy from "./Components/Dummy";
import { fetchUserWorkflowTimeline } from "redux/workflow/action";
import queryString from "query-string";
import ApprovalCheck from "./Components/ApprovalCheck";
import Instruction from "./Components/Instruction";
import FinishCheck from "./Components/FinishCheck";
import ProjectTimeline from "./Components/ProjectTimeline";
import { getNewPlatform } from "util/Environment";


const connectedProps = (state) => ({
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,
  fetchEvaluationProgress: state.evaluationMetaData.fetchProgress,
  nextComponentDetails: state.workflow.nextComponentDetails,
  componentUserResponse: state.workflow.componentUserResponse,
  fetchComponentUserResponseProgress: state.workflow.fetchComponentUserResponseProgress,
  fetchOrgWorkflowComponentsProgress: state.workflow.fetchOrgWorkflowComponentsProgress,
  orgWorkflowComponentsDatas: state.workflow.orgWorkflowComponentsDatas,
  orgWorkflowComponentsMap: state.workflow.orgWorkflowComponentsMap,
  masterComponentsMap: state.workflow.masterComponentsMap,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  fetchworkflowComponentDetailProgress: state.workflow.fetchworkflowComponentDetailProgress,
  componentDetail: state.workflow.componentDetail,
  fetchComponentDetailProgress: state.workflow.fetchComponentDetailProgress,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  userResponseSaveType: state.workflow.userResponseSaveType,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  isShowWorkflowLoader: state.workflow.isShowWorkflowLoader,
  fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,
  userTimeline: state.workflow.userTimeline,
  evaluationSummaryType: state.workflow.evaluationSummaryType,
  evalInfo: state.evaluationMetaData.evalApprovalInfo,
});

const connectionActions = {
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  fetchOrgWorkflowComponents: fetchOrgWorkflowComponents,
  fetchWorkflowComponentDetails: fetchWorkflowComponentDetails,
  fetchComponentDetails: fetchComponentDetails,
  fetchWorkflowComponentsForms: fetchWorkflowComponentsForms,
  showWorkflowLoader: showWorkflowLoader,
  fetchWorkflowUserActivity: fetchWorkflowUserActivity,
  fetchMasterComponents: fetchMasterComponents,
  showSnackBar: showSnackBar,
  fetchUserWorkflowTimeline: fetchUserWorkflowTimeline,
  setSummaryType: setSummaryType
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
});


class WorkflowComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponentSlug: null,
      selectedComponentID: null,
      isCreatorOrAdmin: false,
      isCallOnce: true,
      isShowNext: null,
      queryParams: queryString.parse(this.props.location.search),
    }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    let link = `${getNewPlatform()}/project/${this.props.match?.params?.evaluation_id}/workflow/${this.props.match?.params?.workflow_id}/component/${this.props.match?.params?.component_id}`;
    window.open(link, "_self");
    // this.props.setSummaryType('WORKFLOW_SUMMARY')
    // this.props.showWorkflowLoader(true)
    // this.props.fetchWorkflowComponentDetails(this.props.match.params.workflow_id, this.props.match.params.component_id, this.props.match.params.evaluation_id);
    // // this.props.fetchWorkflowUserActivity(this.props.match.params.evaluation_id, this.props.match.params.workflow_id);
    // this.props.fetchEvaluationMetaData(this.props.match.params.evaluation_id);


    // let componentIds = null;
    // if (this.state.queryParams !== undefined && this.state.queryParams !== null && this.state.queryParams !== '') {
    //   if (this.state.queryParams.pathComponentIds !== undefined && this.state.queryParams.pathComponentIds !== null && this.state.queryParams.pathComponentIds !== '') {
    //     componentIds = this.state.queryParams.pathComponentIds.split(',');
    //   }
    // }
    // this.props.fetchUserWorkflowTimeline(this.props.match.params.evaluation_id, componentIds, this.props.match.params.component_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchworkflowComponentDetailProgress === false && prevProps.fetchworkflowComponentDetailProgress === true) {
      this.setState({
        selectedComponentID: this.props.workflowComponentDetail?.ComponentID,
      }, () => {
        this.props.fetchComponentDetails(this.props.workflowComponentDetail?.ComponentID);
        this.props.fetchWorkflowComponentsForms(this.props.workflowComponentDetail?.ComponentID);
        this.checkPermissionForComponent();
      })
    }

    if (this.props.fetchComponentDetailProgress === false && prevProps.fetchComponentDetailProgress === true) {
      this.setState({
        selectedComponentSlug: this.props.componentDetail?.Slug
      }, () => {
        this.props.showWorkflowLoader(false)
      })
    }

    if (this.props.addComponentUserResponseProgress === false && prevProps.addComponentUserResponseProgress === true) {
      let adminForward = this.props.workflowComponentDetail?.AdminForward !== undefined && this.props.workflowComponentDetail?.AdminForward !== null && this.props.workflowComponentDetail?.AdminForward === true ? true : false;
      if (adminForward) {
        if (this.props.userResponseSaveType === 'NEXT') {
          this.props.history.push("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/interim");
          this.props.showWorkflowLoader(true)
        } else {
          this.props.showSnackBar("User response added successfully.", "success", 6000)
        }
      } else {
        this.props.history.push("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/interim");
        this.props.showWorkflowLoader(true)
      }
    }

    if (this.props.match.params.component_id !== prevProps.match.params.component_id) {
      window.location.reload();
    }

    if (this.props.fetchEvaluationProgress === false && prevProps.fetchEvaluationProgress === true && this.state.isCallOnce === true) {
      this.setState({
        isCallOnce: false
      }, () => {
        this.checkForUser();
        this.checkPermissionForComponent();
      })
    }
  }

  checkForUser() {
    const { user, evaluation } = this.props;
    var isCreatorOrAdmin = false;

    // console.log(user,'Userrrrrrrrrrrrrrrrrr')
    // console.log(evaluation,'Evaluation')
    if (evaluation?.UserID === user?.ID) {
      isCreatorOrAdmin = true;
    }

    if (user?.Role === 'OrgAdmin') {
      isCreatorOrAdmin = true;
    }

    this.setState({
      isCreatorOrAdmin: isCreatorOrAdmin,
    })
  }

  checkPermissionForComponent() {
    const { user, evaluation, workflowComponentDetail } = this.props;

    if (workflowComponentDetail !== null && evaluation !== null) {
      let isShowNext = false
      // console.log(user, 'user');
      // console.log(evaluation, 'evaluation');
      // console.log(this.props.workflowComponentDetail, 'workflowComponentDetail');
      // console.log(this.props.componentDetail, 'componentDetail------01');
      if (this.props.workflowComponentDetail?.Permission && this.props.workflowComponentDetail?.Permission !== null && this.props.workflowComponentDetail?.Permission === 3) {
        isShowNext = true;
      } else if (evaluation !== undefined && evaluation !== null && (user?.Role === "OrgAdmin" || evaluation?.UserID === user?.ID)) {
        isShowNext = true;
      }
      this.setState({
        isShowNext: isShowNext
      })
    }
    // return isShowNext;
  }

  render() {
    const classes = this.props.classes;
    const { isShowWorkflowLoader, workflowComponentDetail } = this.props;

    if (true) {
      return <div className={classes.loader}> <CircularProgress /></div>
    }


    if (isShowWorkflowLoader || workflowComponentDetail === null || this.state.isShowNext === null) {
      return <div className={classes.loader}> <CircularProgress /></div>
    }

    let adminForward = this.props.workflowComponentDetail?.AdminForward !== undefined && this.props.workflowComponentDetail?.AdminForward !== null && this.props.workflowComponentDetail?.AdminForward === true ? true : false;

    let isApprovalStarted = false;
    if (this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation !== '') {
      isApprovalStarted = this.props.evaluation.ApprovalStarted !== undefined && this.props.evaluation.ApprovalStarted !== null && this.props.evaluation.ApprovalStarted !== '' ? this.props.evaluation.ApprovalStarted === 1 ? true : false : false;
    }

    // console.log(this.props.evaluation,'evaluation88888888888888')

    if (this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation !== '' && (this.props.evaluation?.Status === 4 || this.props.evaluation?.Status === 5)) {
      return <FinishForm
        title={'Finish Page'}
        component={this.props.componentDetail}
        adminForward={adminForward}
        isCreatorOrAdmin={this.state.isCreatorOrAdmin}
        componentId={this.state.selectedComponentID}
        evaluation_id={this.props.match.params.evaluation_id}
        workflow_id={this.props.match.params.workflow_id}
        workflowComponentId={this.props.match.params.component_id}
        isWorkFlow={true}
        isFinish={true}
      />
    }

    let isShowNext = this.state.isShowNext;
    if (!isShowNext) {
      return <div>
        <Dummy
          title={'Custom Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />
      </div>
    }

    let checkTimeline = checkWorkflowFinished(this.props.userTimeline);
    if (this.props.evaluation?.EvaluationUserStatus === 4 && this.state.selectedComponentSlug !== 'WORKFLOW_SUMMARY' && this.state.selectedComponentSlug !== 'FINISH') {
      return <FinishCheck
        title={'Custom Form'}
        component={this.props.componentDetail}
        adminForward={adminForward}
        isCreatorOrAdmin={this.state.isCreatorOrAdmin}
        componentId={this.state.selectedComponentID}
        evaluation_id={this.props.match.params.evaluation_id}
        workflow_id={this.props.match.params.workflow_id}
        workflowComponentId={this.props.match.params.component_id}
        isOpen={true}
        isWorkFlow={true}
        checkTimeline={checkTimeline}
      />
    }

    if (!this.state.isCreatorOrAdmin && this.state.selectedComponentSlug !== 'APPROVAL_WORKFLOW' && this.state.selectedComponentSlug !== 'FINISH' && this.state.selectedComponentSlug !== 'EMAIL' && this.state.selectedComponentSlug !== 'WORKFLOW_SUMMARY' && isApprovalStarted) {
      return <ApprovalCheck
        title={'Custom Form'}
        component={this.props.componentDetail}
        adminForward={adminForward}
        isCreatorOrAdmin={this.state.isCreatorOrAdmin}
        componentId={this.state.selectedComponentID}
        evaluation_id={this.props.match.params.evaluation_id}
        workflow_id={this.props.match.params.workflow_id}
        workflowComponentId={this.props.match.params.component_id}
        isOpen={true}
        isWorkFlow={true}
      />
    }

    // console.log(this.props.evaluationSummaryType, 'evaluationSummaryType')

    return (
      <div>

        {this.state.selectedComponentSlug === 'FORM' && <CustomForm
          title={'Custom Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
          checkTimeline={checkTimeline}
        />}

        {this.state.selectedComponentSlug === 'INTEGRATION_FORM' && <IntegrationForm
          title={'Integration Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'REQUIREMENTS_SELECTION' && <RequirementGathering
          title={'Requirement Gathering'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'QUESTIONNAIRE' && <Evaluation
          title={'Evaluation'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'FEEDBACK' && <FeedbackForm
          open={true}
          title={'Feedback Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'PRODUCT_SHORTLIST' && <ProductShortlist
          title={'Product Shortlist'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'SUMMARY' && <Summary
          title={'Summary Page'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'WORKFLOW_SUMMARY' && <WorkflowSummary
          title={'Summary Page'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'APPROVAL_WORKFLOW' && <Approval
          title={'Approval Workflow'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          // next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'EMAIL' && <EmailForm
          title={'Email Notification'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />}

        {/* Base Component End */}

        {this.state.selectedComponentSlug === 'PRE_EVALUATION_FEEDBACK' && <PreFeedbackDialog
          title={'Pre Evaluation Feedback'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'PRE_EVALUATION_FEEDBACK_FORM' && <PreFeedbackDialog
          title={'Pre Evaluation Feedback'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}

        />}

        {this.state.selectedComponentSlug === 'EVALUATION_DETAILS_FORM' && <EvaluationDetailForm
          title={'Evaluation Detail Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'REQUIREMENT_GATHERING' && <RequirementGathering
          title={'Requirement Gathering'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'EVALUATION' && <Evaluation
          title={'Evaluation'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'COLLABORATOR_FEEDBACK' && <FeedbackForm
          open={true}
          title={'Feedback Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'APPROVAL_PAGE' && <Approval
          title={'Approval Page'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'EMAIL/LINK/DOC' && <SendNotificationForm
          title={'Email Notification'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'RFP_PURPOSE_FORM' && <CustomForm
          title={'RFP Purpose Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
        />}

        {this.state.selectedComponentSlug === 'EVALUATION_SCOPE_FORM' && <CustomForm
          title={'Evaluation Scope Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'SELLER_INSTRUCTION' && <Instruction
          title={'Evaluation Scope Form'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'PROJECT_TIMELINE' && <ProjectTimeline
          title={'Project Timeline'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'FINISH' && <FinishForm
          title={'Finish Page'}
          component={this.props.componentDetail}
          adminForward={adminForward}
          isCreatorOrAdmin={this.state.isCreatorOrAdmin}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
          isFinish={true}
        />}

      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(WorkflowComponent));
