export function getItemDefaultField(fieldUID) {
  let field = 'description'
  if (fieldUID !== undefined && fieldUID !== null && fieldUID !== '') {
    if ("prod_about_item" === fieldUID) field = 'description'
    if ("prod_support_email_item" === fieldUID) field = 'supportEmail'
    if ("prod_support_phone_item" === fieldUID) field = 'supportPhone'
    if ("prod_company_website_item" === fieldUID) field = 'productURL'
    if ("prod_company_name_item" === fieldUID) field = 'companyName'
    if ("prod_business_criticality" === fieldUID) field = 'BuisnessCriticality'
    if ("prod_access_link" === fieldUID) field = 'accessLink'
  }
  return field
}

export const catalogDefaultItemsForUpdate = [
  'prod_about_item',
  'prod_company_name_item',
  'prod_company_website_item',
  'prod_support_phone_item',
  'prod_support_email_item',
]

export const documentCatalogItemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Rich Text', value: 11 },
  { label: 'Number', value: 19 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Email', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  { label: 'Table ', value: 6 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File', value: 9 },
  { label: 'Products', value: 10 },
  { label: 'Currency', value: 20 },
]

export const appCatalogItemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Rich Text', value: 11 },
  { label: 'Number', value: 19 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Email Address', value: 12 },
  { label: 'Send Email', value: 16 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  { label: 'Checklist', value: 15 },
  { label: 'Table ', value: 6 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File', value: 9 },
  { label: 'Products', value: 10 },
  { label: 'Workflow', value: 17 },
  { label: 'Task', value: 18 },
  { label: 'Currency', value: 20 },
  { label: 'Visualization', value: 21 },
  { label: 'Projects', value: 22 },
  { label: 'Project Tracking', value: 23 },
]


export const tableItemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Rich Text', value: 11 },
  { label: 'Number', value: 19 },
  { label: 'Date', value: 2 },
  { label: 'Email Address', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File', value: 9 },
  { label: 'Currency', value: 20 },
]

export const vendorCatalogCatalogItemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Rich Text', value: 11 },
  { label: 'Number', value: 19 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Email Address', value: 12 },
  { label: 'Send Email', value: 16 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  { label: 'Checklist', value: 15 },
  { label: 'Table ', value: 6 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File', value: 9 },
  { label: 'Notes', value: 24 },
  { label: 'Products', value: 10 },
  { label: 'Workflow', value: 17 },
  { label: 'Task', value: 18 },
  { label: 'Currency', value: 20 },
  { label: 'Visualization', value: 21 },
  { label: 'Projects', value: 22 },
  { label: 'Project Tracking', value: 23 },
]
// let types = [1,2,3,6,9,11,12,13,14,19]
export function renewalValidColumnTypes(ss) {
  let types = [1, 2, 3, 4, 5, 6, 7, 8, 12, 13, 14, 19, 20]
  return types
}

export function getApplicationCatalogTypeForForm(ss) {
  let types = [
    1, // Simple Text
    2, // Date
    3, // Binary
    4, // Single Select
    5, // Multi Select
    6, // Table
    7, // Users
    8, // Teams
    9, // File
    10, // Products
    11, // Rich Text
    12, // Email
    13, // Phone
    14, // URL
    19, // Number
    20  // Currency
  ]
  return types
}

export function getAppCatalogTypeUsingRequirementType() {
  let types = [
    1, // Simple Text
    2, // Date
    3, // Binary
    4, // Single Select
    5, // Multi Select
    6, // Table
    11, // Rich Text
    12, // Email
    13, // Phone
    14, // URL
    19, // Number
    20  // Currency
  ]
  return types
}

export const taskTypes = [
  { label: "Task", value: 1 },
  // { label: "Risk", value: 3 },
  { label: "Follow Up", value: 2 },
];

export const vendorTaskTypes = [
  { label: "Task", value: 'task' },
  // { label: "Risk", value: 3 },
  { label: "Follow Up", value: 'follow_up' },
];

export const binaryOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];



export function getAppCatalogTypeUsingFormType_bkp(answerType) {
  let type = 1
  if (answerType !== undefined && answerType !== null && answerType !== '') {
    const AnswerType = parseInt(answerType)
    if (1 === AnswerType) type = [1, 11] // Simple Text in App Catalog
    if (2 === AnswerType) type = [2, 1, 11] // Date in App Catalog
    if (3 === AnswerType) type = [3, 1, 11] // Binary Text in App Catalog
    if (4 === AnswerType) type = [1, 11] // Simple Text in App Catalog
    if (5 === AnswerType) type = [1, 11] // Simple Text in App Catalog
    if (6 === AnswerType) type = [1, 11] // Simple Text in App Catalog
    if (7 === AnswerType) type = [7] // Users Text in App Catalog
    if (8 === AnswerType) type = [8] // Teams Text in App Catalog
    if (9 === AnswerType) type = [9] // File Text in App Catalog
    if (10 === AnswerType) type = [10] // Products Text in App Catalog
    if (11 === AnswerType) type = [11] // Rich Text in App Catalog
    if (12 === AnswerType) type = [12, 1, 11] // Email Text in App Catalog
    if (13 === AnswerType) type = [13, 1, 11] // Phone Text in App Catalog
    if (14 === AnswerType) type = [14, 1, 11] // URL Text in App Catalog
    if (15 === AnswerType) type = [19, 1, 11] // Number Text in App Catalog
    if (16 === AnswerType) type = [19, 1, 11] // Number Text in App Catalog
    if (17 === AnswerType) type = [10] // Products Text in App Catalog
    if (18 === AnswerType) type = [19] // Simple Text in App Catalog
    if (19 === AnswerType) type = [1, 11] // Simple Text in App Catalog
    if (20 === AnswerType) type = [1, 11] // Checklist Text in App Catalog
    if (21 === AnswerType) type = [1, 11] // Simple Text in App Catalog
    if (22 === AnswerType) type = [1, 11] // Simple Text in App Catalog
  }
  return type
}



export function getAppCatalogTypeUsingRequirementTypeBKP(answerType) {
  let type = 1
  if (answerType !== undefined && answerType !== null && answerType !== '') {
    const AnswerType = parseInt(answerType)
    if (1 === AnswerType) type = [11, 1]
    if (2 === AnswerType) type = [1, 11]
    if (3 === AnswerType) type = [1, 11]
    if (4 === AnswerType) type = [3, 1, 11]
    if (5 === AnswerType) type = [19]
    if (6 === AnswerType) type = [9]
    if (7 === AnswerType) type = [1, 11]
    if (8 === AnswerType) type = [12, 11, 1]
    if (9 === AnswerType) type = [13, 1, 11]
    if (10 === AnswerType) type = [14, 1, 11]
    if (11 === AnswerType) type = [2, 1, 11]
    if (12 === AnswerType) type = [19, 1, 11]
    if (13 === AnswerType) type = [19, 1, 11]
    if (14 === AnswerType) type = [1, 11]
    if (15 === AnswerType) type = [1, 11]
    if (16 === AnswerType) type = [7]
    if (17 === AnswerType) type = [8]
    if (18 === AnswerType) type = [10]
    if (19 === AnswerType) type = [10]
  }
  return type
}


// BuisnessCriticality
// accessLink
// companyName
// deployementType
// description
// email
// heading
// name
// phone
// productLogo
// productURL
// supportEmail
// supportPhone
// timeForReReview
// url


export const newAttributes = [
  {
    "label": "Product Name",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "product_name"
  },
  {
    "label": "Invoice ID",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "invoice_id"
  },
  {
    "label": "Purchase Order",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "purchase_order"
  },
  {
    "label": "Invoice Date",
    "value": "",
    "confidence": 0.88708144,
    "type": "date",
    "attribute": "invoice_date"
  },
  {
    "label": "Due Date",
    "value": "",
    "confidence": 0,
    "type": "date",
    "attribute": "due_date"
  },
  {
    "label": "Start Date",
    "value": "",
    "confidence": 0.8,
    "type": "date",
    "attribute": "start_date"
  },
  {
    "label": "End Date",
    "value": "",
    "confidence": 0.8,
    "type": "date",
    "attribute": "end_date"
  },
  {
    "label": "Renewal Date",
    "value": "",
    "confidence": 0.8,
    "type": "date",
    "attribute": "renewal_date"
  },
  {
    "label": "Vendor Name",
    "value": "",
    "confidence": 0.686222,
    "type": "text",
    "attribute": "vendor_name"
  },
  {
    "value": "",
    "confidence": 0,
    "attribute": "supplier_name"
  },
  {
    "label": "Supplier address",
    "value": "",
    "confidence": 0.6855973,
    "type": "text",
    "attribute": "supplier_address"
  },
  {
    "label": "Supplier email",
    "value": "",
    "confidence": 0.8853744,
    "type": "text",
    "attribute": "supplier_email"
  },
  {
    "label": "Supplier Website",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "supplier_website"
  },
  {
    "label": "Supplier tax id",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "supplier_tax_id"
  },
  {
    "label": "supplier iban",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "supplier_iban"
  },
  {
    "label": "Receiver Name",
    "value": "",
    "confidence": 0.8962896,
    "type": "text",
    "attribute": "receiver_name"
  },
  {
    "label": "Receiver Address",
    "value": "",
    "confidence": 0.5237663,
    "type": "text",
    "attribute": "receiver_address"
  },
  {
    "label": "Receiver tax id",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "receiver_tax_id"
  },
  {
    "label": "Payment Terms",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "payment_terms"
  },
  {
    "label": "Total Cost",
    "value": "USD0.00",
    "confidence": 0,
    "type": "price",
    "attribute": "total_cost"
  },
  {
    "label": "Amount Due",
    "value": "USD0.00",
    "confidence": 0,
    "type": "price",
    "attribute": "amount_due"
  },
  {
    "label": "Net Amount",
    "value": "USD0.00",
    "confidence": 0.96340054,
    "type": "price",
    "attribute": "net_amount"
  },
  {
    "label": "Total Tax Amount",
    "value": "USD0.00",
    "confidence": 0.9190586,
    "type": "price",
    "attribute": "total_tax_amount"
  },
  {
    "label": "Vat",
    "value": "USD0.00",
    "confidence": 0,
    "type": "price",
    "attribute": "vat"
  },
  {
    "label": "Freight Amount",
    "value": "USD0.00",
    "confidence": 0.485084,
    "type": "price",
    "attribute": "freight_amount"
  },
  {
    "label": "ship to name",
    "value": "",
    "confidence": 0.71043664,
    "type": "text",
    "attribute": "ship_to_name"
  },
  {
    "label": "Ship to address",
    "value": "",
    "confidence": 0.05898362,
    "type": "text",
    "attribute": "ship_to_address"
  },
  {
    "label": "Remit to name",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "remit_to_name"
  },
  {
    "label": "Remit to address",
    "value": "",
    "confidence": 0,
    "type": "text",
    "attribute": "remit_to_address"
  }
];

export const getFileExtension = (filename) => {
  if (filename) {
    return filename.split('.').pop();
  } else {
    return filename;
  }
}

export const getFileName = (filename) => {
  if (filename) {
    return filename.split('.')[0];
  } else {
    return filename;
  }
}

export const getExtUsingURL = (url) => {
  if (url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  } else {
    return ''
  }
}

export const getFileAndExtension = (fileName, fileURL) => {
  let name = fileName === undefined || fileName === null || fileName === '' ? fileURL : fileName;
  let fileNameWithExt = fileName === undefined || fileName === null || fileName === '' ? fileURL : fileName;
  let isCorrectName = false;
  let ext = getFileExtension(fileNameWithExt);
  if (ext !== fileNameWithExt) {
    isCorrectName = true;
  }
  if (isCorrectName === false) {
    ext = getExtUsingURL(fileURL);
  }
  return { title: name, extension: ext }
}


export const documentGlobalFields = [
  {
    Attribute: "product_name",
    Label: "Product Name",
    Type: 'text',
  },
  {
    Attribute: "end_date",
    Label: "End Date",
    Type: 'date',
  },
  {
    Attribute: "renewal_date",
    Label: "Renewal Date",
    Type: 'date',
  },
  {
    Attribute: "start_date",
    Label: "Start Date",
    Type: 'date',
  },
  {
    Attribute: "total_cost",
    Label: "Total Cost",
    Type: 'price',
  },
  {
    Attribute: "payment_term",
    Label: "Payment Term",
    Type: 'payment_term',
  },
  {
    Attribute: "quantity",
    Label: "Quantity",
    Type: 'number',
  },
  {
    Attribute: "unit_price",
    Label: "Unit Price",
    Type: 'price',
  },
  {
    Attribute: "price",
    Label: "Price",
    Type: 'price',
  },
  {
    Attribute: "vendor_name",
    Label: "Vendor Name",
    Type: 'text',
  },
  {
    Attribute: "amount_due",
    Label: "Amount Due",
    Type: 'price',
  },
  {
    Attribute: "due_date",
    Label: "Due Date",
    Type: 'date',
  },
  {
    Attribute: "freight_amount",
    Label: "Freight Amount",
    Type: 'price',
  },
  {
    Attribute: "net_amount",
    Label: "Net Amount",
    Type: 'price',
  },
  {
    Attribute: "payment_terms",
    Label: "Payment Terms",
    Type: 'text',
  },
  {
    Attribute: "purchase_order",
    Label: "Purchase Order",
    Type: 'text',
  },
  {
    Attribute: "receiver_address",
    Label: "Receiver Address",
    Type: 'text',
  },
  {
    Attribute: "receiver_name",
    Label: "Receiver Name",
    Type: 'text',
  },
  {
    Attribute: "receiver_tax_id",
    Label: "Receiver tax id",
    Type: 'text',
  },
  {
    Attribute: "remit_to_address",
    Label: "Remit to address",
    Type: 'text',
  },
  {
    Attribute: "remit_to_name",
    Label: "Remit to name",
    Type: 'text',
  },
  {
    Attribute: "ship_to_address",
    Label: "Ship to address",
    Type: 'text',
  },
  {
    Attribute: "ship_to_name",
    Label: "ship to name",
    Type: 'text',
  },
  {
    Attribute: "supplier_address",
    Label: "Supplier address",
    Type: 'text',
  },
  {
    Attribute: "supplier_email",
    Label: "Supplier email",
    Type: 'text',
  },
  {
    Attribute: "supplier_iban",
    Label: "supplier iban",
    Type: 'text',
  },
  {
    Attribute: "supplier_tax_id",
    Label: "Supplier tax id",
    Type: 'text',
  },
  {
    Attribute: "supplier_website",
    Label: "supplier website",
    Type: 'text',
  },
  {
    Attribute: "total_tax_amount",
    Label: "totalTaxAmount",
    Type: 'price',
  },
  {
    Attribute: "vat",
    Label: "Vat",
    Type: 'price',
  },
  {
    Attribute: "supplier_name",
    Label: "Supplier Name",
    Type: 'text',
  },
  {
    Attribute: "license",
    Label: "License",
    Type: 'number',
  },
  {
    Attribute: "invoice_id",
    Label: "Invoice Id",
    Type: 'text',
  },
  {
    Attribute: "invoice_date",
    Label: "Invoice Date",
    Type: 'date',
  },
  {
    Attribute: "other_dates",
    Label: "Other Dates",
    Type: 'date',
  },
  {
    Attribute: "currency",
    Label: "Currency",
    Type: 'price',
  },
  {
    Attribute: "document_name",
    Label: "Document Name",
    Type: 'text',
  },
  {
    Attribute: "parties",
    Label: "Parties",
    Type: 'text',
  },
  {
    Attribute: "agreement_date",
    Label: "Agreement Date",
    Type: 'date',
  },
  {
    Attribute: "initial_term",
    Label: "Initial Term",
    Type: 'text',
  },
  {
    Attribute: "renewal_term",
    Label: "Renewal Term",
    Type: 'text',
  },
  {
    Attribute: "notice_to_terminate_renewal",
    Label: "Notice To Terminate Renewal",
    Type: 'text',
  },
  {
    Attribute: "governing_law",
    Label: "Governing Law",
    Type: 'text',
  },
  {
    Attribute: "total_amount",
    Label: "Total Amount",
    Type: 'price',
  },
  {
    Attribute: "carrier",
    Label: "Carrier",
    Type: 'text',
  },
];