import { Button, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import AuthImage from "assets/images/auth_image.svg";
import AuthBackgorundImage from "assets/images/auth_background.svg";
import { withRouter } from "react-router-dom";

// import Logo from "assets/images/colour_logo.svg";
import Image from 'Components/Common/image.jsx'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MediaQuery from 'react-responsive'

const connectedProps = (state) => ({

});

const connectionActions = {

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
    createStyles({
        root: {
            backgroundImage: `url(${AuthBackgorundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "-200px 62px",

            [theme.breakpoints.down('xs')]: {
                backgroundImage: "none",
            },

            [theme.breakpoints.down('md')]: {
                backgroundSize: "cover",
            },
        },
        emptyText: {
            textAlign: 'center',
            height: 50,
            paddingTop: 20
        },
        appBar: {
            background: "#fff",
            padding: theme.spacing(1, 2),
            boxShadow: "none",
            borderBottom: "2px solid #f1f1f1",
        },
        errorPage: {
            maxWidth: theme.appMaxWidth,
            margin: 'auto',
            minHeight: "100vh",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            paddingRight: theme.spacing(12),

            [theme.breakpoints.down('md')]: {
                paddingRight: 0,
            },
        },
        imageContainer: {
            flex: 6,
            [theme.breakpoints.down('xs')]: {
                display: "none"
            },
        },

        content: {
            width: "454px",
            paddingBottom: theme.spacing(8.4),
            margin: "auto"
        },

        authimage: {
            // height: "100vh",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            margin: theme.spacing(0, 3),
            [theme.breakpoints.down('xs')]: {
                height: "inherit",
            },
        },

        image: {
            width: "583px",
            [theme.breakpoints.down('md')]: {
                width: "400px",
            },
        },

        background_image: {
            height: '100%',
            position: "absolute",
            bottom: "0",
            right: 0,
            top: "30px",
        },
        caption: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(10),
        },
        heading: {
            marginBottom: theme.spacing(1),
            fontSize: theme.spacing(4.2),
            color: "#4A4A4A",
        },
        mesasge: {
            marginBottom: theme.spacing(1),
            fontSize: theme.spacing(4.2),
            color: "#7b7b7b",
        },
        Btn: {
            background: "#3C3C3C",
            borderRadius: 5,
            marginTop: theme.spacing(4),
            color: "#fff",
            fontSize: theme.spacing(1.9),
            minHeight: 32,
            minWidth: 175,
            "&:hover": {
                background: "#3C3C3C",
            },
        },
        textWrap: {
            flex: 4
        }
    });

class ErrorFallback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.goToBack = this.goToBack.bind(this);
    }

    componentDidMount() {

    }

    goToBack() {
        console.log('================')
        console.log(this.props.history)
        const { history } = this.props;
        console.log(history.location)
        if (history && history.location) {
            if (history.location.pathname.indexOf('/component/') !== -1) {
                this.props.history.go(-1);
            } else {
                this.props.history.goBack();
            }
        } else {
            this.props.history.goBack();
        }
        setTimeout(() => {
            window.location.reload()
        }, 2000);
    }

    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.root}>
                <div className={classes.errorPage}>
                    <MediaQuery minWidth={992}>
                        <div className={classes.imageContainer}>
                            <div className={classes.authimage}>
                                <Image src={AuthImage} alt="auth_image" className={classes.image} />
                                {/* <Image src={AuthBackgorundImage} alt="auth_backimage" className={classes.background_image} /> */}
                            </div>
                        </div>
                    </MediaQuery>
                    <div className={classes.textWrap}>
                        <Typography variant={"h5"} className={classes.mesasge}>
                            Woops!
                        </Typography>
                        <Typography variant={"h5"} className={classes.mesasge}>
                            Something went wrong :(
                        </Typography>
                        <Button
                            className={classes.Btn}
                            startIcon={<KeyboardBackspaceIcon />}
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => {
                                this.goToBack()
                            }}>
                            Go Back
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

// export default connector(compose(withStyles(styles), withLastLocation)(ErrorFallback));
export default connector(compose(withRouter, withStyles(styles))(ErrorFallback));
