import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

// assets
import deleteIcon from "assets/images/delete.svg";

// components
import Answer from "./Answer";
import Image from "Components/Common/image.jsx";
import { showSnackBar } from "redux/snackbar/action";
// redux
import { addRequirementOptions } from "redux/evaluation/multichoice/action";
import { updateOrgRequirementTemplateOptions } from "redux/templates/evaluation/org/action";

// Component styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  action: {
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    padding: "10px",
    textAlign: "right",
    marginTop: "10px",
  },
  btn: {
    border: "1px solid #EBEBEB",
    borderRadius: "3px",
    outline: 0,
    padding: "6px 14px",
    cursor: "pointer",
  },
  btnCancel: {
    background: "#FAFAFA",
    color: "#707070",
    borderColor: "#EBEBEB",
    marginRight: 16,
  },
  btnSave: {
    background: "#FFFFFF",
    color: "#4175DF",
    borderColor: "#9DBDFA",
  },
  visibilityNone: {
    visibility: "hidden",
    height: 0,
    margin: 0,
    padding: 0,
  },
  label: {
    color: "#707070",
    padding: 0,
    margin: 0,
    marginRight: 5,
    fontSize: "14px",
    fontWeight: 400,
    minWidth: 'max-content'
  }
}));

const connectedProps = (state) => ({});

const connectionActions = {
  addRequirementOptions: addRequirementOptions,
  showSnackBar: showSnackBar,
  updateOrgRequirementTemplateOptions: updateOrgRequirementTemplateOptions,
};

var connector = connect(connectedProps, connectionActions);

function AnswerOptions(props) {
  const classes = useStyles();
  const { requirement, answertype } = props;
  const [answers, setAnswers] = useState([]);
  useEffect(() => {
    if (
      answers.length < props.multiChoice?.length &&
      props.multiChoice.length
    ) {
      setAnswers(props.multiChoice);
    }
    const resizeListener = () => {
      updateViewHeight();
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const initOptionsForRequirement = () => {
    var initOptions = [];
    if (props.requirement && ((!props.isTemplateSection && props.requirement.options) || (props.isTemplateSection && props.requirement.Options))) {
      var options = props.requirement[props.isTemplateSection ? "Options" : "options"];
      options.forEach((item, k) => {
        initOptions.push({
          id: k,
          description: item.Title || item.title,
          title: item.description || item.Description,
          ID: item.ID
        });
      });
    }

    if (initOptions?.length === 0 && answers?.length === 0) {
      let ansType = requirement?.answer_type || requirement?.AnswerType;
      if ([7, 2, 3, 4, 14, 15].includes(ansType)) {
        add();
      }
    } else if (initOptions?.length) {
      setAnswers(initOptions);
    }
    // if (
    //   props?.isNew === true &&
    //   [2, 3, 4, 14, 15].includes(props.answertype) &&
    //   props.requirement.options.length === 0
    // ) {
    //   add();
    // }
  };

  useEffect(() => {
    initOptionsForRequirement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirement, answertype]);

  const remove = (id) => {
    let ans = answers
    if (ans.length === 1) return;
    ans = ans.filter(o => o.id !== id)
    setAnswers(ans);
    const options = answers.map((item) => {
      return {
        title: item.description,
        sequence: item.id,
        description: item.title
      };
    });
    props.onQuizChange(options);
    updateViewHeight();
  };

  const add = (dynamic = false) => {
    let ansType = requirement?.answer_type || requirement?.AnswerType;
    let data = [...answers];
    if (ansType === 4) {
      let field_data = [
        {
          id: answers.length + 1,
          description: "Yes",
          title: 'Yes',
        },
        {
          id: answers.length + 2,
          description: "No",
          title: 'No',
        },
      ];
      data.push(...field_data);
    } else if (ansType === 7) {
      let field_data = {
        id: answers.length + 1,
        description: props.isNew ? 0 : (requirement.CharacterLimit || requirement.character_limit),
        title: "",
      };
      data.push(field_data);
    } else {
      let field_data = {
        id: answers.length + 1,
        description: "",
        title: "",
      };
      data.push(field_data);
    }
    setAnswers(data);
    const options = answers.map((item) => {
      return {
        title: item.description,
        sequence: item.id,
        description: item.title
      };
    });
    props.onQuizChange(options);
    updateViewHeight();
  };

  const handleChange = (e, id) => {
    let newarray = answers.map((answer) => {
      if (answer.id === id) answer["description"] = e.target.value;
      return answer;
    });
    setAnswers(newarray);
    const options = newarray.map((item) => {
      if (props.isNew) {
        return {
          title: item.description,
          sequence: item.id,
          description: item.title
        };
      } else {
        return {
          title: item.description,
          sequence: item.id,
          description: item.title,
          id: item.ID
        };
      }
    });

    props.onQuizChange(options);

    if ((props.isNew === true || props.answertype === 7) && props.fromAddTemplate) {
      props.onChange(options, e.target.value);
    }

  };

  const updateViewHeight = () => {
    setTimeout(() => {
      let reqEle = document.getElementById(
        `requirement_${props.criteria?.id}_${props.requirement?.id}`
      );
      if (reqEle) {
        props.updateCriteriaRequirementHeight(
          true,
          `requirement_${props.criteria?.id}_${props.requirement?.id}`,
          reqEle.offsetHeight
        );
      }
    }, 400);
  };

  // api functions
  const saveQuestion = () => {
    let options = []
    let character_limit = 0
    if (props.answertype === 7) {
      character_limit = parseInt(answers[0].description)
    } else {
      console.log(answers, '3333333333333333333333333333333333Answerssssssssss')
      let isOptionAddIssue = false;
      options = answers.map((item) => {
        if (props.isNew === true) {
          return {
            title: String(item.description),
            sequence: item.id,
            description: item.title
          };
        } else {
          return {
            title: String(item.description),
            sequence: item.id,
            description: item.title,
            id: item.ID
          };
        }
      });

      answers.forEach((item) => {
        if(item.description === '' || item.description === undefined || item.description === 'undefined'){
          isOptionAddIssue = true;
        }
      });

      if (options.length === 0) {
        props.showSnackBar("Please enter atleast 1 option.", "error", 3000);
        return;
      }

      if(props.isNew !== undefined && props?.isNew === false && isOptionAddIssue){
        props.showSnackBar("Please enter valid option value.", "error", 3000);
        return;
      }

    }

    if (props.isNew === true || props.answertype === 7) {
      props.onChange(options, character_limit);
    } else {
      if (props.isTemplateSection) {
        props.updateOrgRequirementTemplateOptions(
          props.evaluation_id,
          props.criteria.ID,
          props.requirement.ID,
          { options }
        );
      } else {
        props.addRequirementOptions(props.evaluation_id, props.requirement.id, { options });
      }
      props.saveQuiz();
    }
  };

  const cancelQuestion = () => {
    props.MultiChoiceQuizOptions([]);
    initOptionsForRequirement();
  };

  let newAnswer = answers;
  if([7].includes(answertype) && answers.length > 1){
    newAnswer = [answers[0]];
  }

  return (
    <>
      <div className={classes.root}>
        {newAnswer.map((item, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "row",
              boxSizing: "border-box",
              placeContent: answertype === 7 ? "baseline" : "center space-between",
              alignItems: "center",
              marginBottom: "14px",
            }}
          >
            {[7].includes(answertype) && <p className={classes.label}>Character limit&nbsp;</p>}

            <Answer
              index={i}
              answertype={answertype}
              isShowCount={![7].includes(answertype)}
              key={item.id}
              placeholder={""}
              answerNumber={item.id}
              type={[7].includes(answertype) ? 'number' : 'text'}
              answer={item.description}
              handleChange={handleChange}
              editable={props.editable}
              isOtherOption={props.isOtherOption}
            />

            {props.editable && [2, 3, 14, 15].includes(props.answertype) && (
              <Image
                style={{ cursor: "pointer", marginLeft: 5 }}
                alt="Delete"
                src={deleteIcon}
                onClick={() => {
                  remove(item.id);
                }}
              />
            )}
          </div>
        ))}

        {[2, 3, 14, 15].includes(props.answertype) && props.isOtherOption && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              boxSizing: "border-box",
              placeContent: "center space-between",
              alignItems: "center",
              marginBottom: "14px",
            }}
          >
            <Answer
              index={answers.length}
              key={1}
              answerNumber={1}
              answer={"Other..."}
              editable={false}
            />
            {props.editable && (
              <Image
                style={{ cursor: "pointer", marginLeft: 5 }}
                alt="Delete"
                src={deleteIcon}
                onClick={(e) => {
                  saveQuestion();
                  props.removeIsOtherOption(e);
                }}
              />
            )}
          </div>
        )}

        {props.editable && (
          <>
            <div>
              {[2, 3, 14, 15].includes(props.answertype) && (
                <>
                  <span
                    onClick={() => {
                      add();
                    }}
                    style={{
                      fontSize: "12px",
                      color: "#4175DF",
                      cursor: "pointer",
                    }}
                  >
                    + Add more
                  </span>
                  {(props.answertype === 14 || props.answertype === 15) && !props.isOtherOption && (
                    <>
                      &nbsp;&nbsp; or &nbsp;&nbsp;
                      <span
                        onClick={(e) => {
                          saveQuestion();
                          props.addOther(e);
                        }}
                        style={{
                          fontSize: "12px",
                          color: "#4175DF",
                          cursor: "pointer",
                        }}
                      >
                        + Add "Other"
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
            <div
              className={classnames(
                classes.action,
                props.isCriteriaRequirement ? classes.visibilityNone : {}
              )}
            >
              <button
                type="button"
                className={classnames(classes.btn, classes.btnCancel)}
                onClick={() => {
                  cancelQuestion();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                id={props.saveBtnId}
                onClick={() => {
                  saveQuestion();
                }}
                className={classnames(classes.btn, classes.btnSave)}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default connector(AnswerOptions);
