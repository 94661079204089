import {
    CircularProgress,
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import Select from 'react-select';
import SqureButton from 'Components/Common/SqureButton';
import classnames from "classnames";
// import Image from "Components/Common/image.jsx";
// import deleteIcon from "assets/images/delete.svg";

import { hideFormDataCopyDialog } from 'redux/workflow/summary/action';
import { fetchOrgProductDetailsTabs } from 'redux/product/orgProduct/action';
import ReactSelect from "react-select";
import { showSnackBar } from "redux/snackbar/action";
import { addNewRow } from "redux/vendor/table/action";
import { addOrgDocument,updateFinancialDetailsData } from "redux/product/orgProduct/action.js";

import {
    getApplicationCatalogTypeForForm,
    getItemDefaultField, catalogDefaultItemsForUpdate
} from "util/AppCatalogTypeOptions";

import { getFormTypeUsingAppCatalogType } from "util/FormTypeOptions"
import { updateOrgProdutDetailsTabSectionItemData, updateOrgProductDetails } from "redux/product/orgProduct/action";

import { fetchOrgCatalogDetailsTabs, updateOrgVendorTabSectionItemData } from "redux/vendor/vendorCatalog/action";
import { updateOrgServiceTabSectionItemData } from "redux/vendor/serviceCatalog/action";

import { addDocTypeFile,updateDocTypeFile,fetchDocTypes } from 'redux/documentTypesV2/action'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.workflowSummary.isShowFormDataCopyDialog,
    orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
    orgProductDetailTabsProgress: state.orgProduct.orgProductDetailTabsProgress,
    isUpdatingOrgProductDetailTabsSectionItemData: state.orgProduct.isUpdatingOrgProductDetailTabsSectionItemData,
    orgProductDetailsUpdateProgress: state.orgProduct.orgProductDetailsUpdateProgress,

    orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
    orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,

    orgVendorTabSectionItemDataUpdateProgress: state.vendorCatalog.orgVendorTabSectionItemDataUpdateProgress,
    orgVendorTabSectionItemDataUpdateError: state.vendorCatalog.orgVendorTabSectionItemDataUpdateError,

    orgServiceTabSectionItemDataUpdateProgress: state.serviceCatalog.orgServiceTabSectionItemDataUpdateProgress,
    orgServiceTabSectionItemDataUpdateError: state.serviceCatalog.orgServiceTabSectionItemDataUpdateError,

    addNewRowProgress: state.catalogTable.addNewRowProgress,
    addNewRowError: state.catalogTable.addNewRowError,

    addOrgDocumentProgress: state.orgProduct.addOrgDocumentProgress,
    addOrgDocumentSuccess: state.orgProduct.addOrgDocumentSuccess,
    addOrgDocumentError: state.orgProduct.addOrgDocumentError,

    addDocTypeFileProgress: state.documentTypeV2.addDocTypeFileProgress,
    addDocTypeFileError: state.documentTypeV2.addDocTypeFileError,
    addDocTypeFileSuccess: state.documentTypeV2.addDocTypeFileSuccess,

    fetchDocTypesProgress: state.documentTypeV2.fetchDocTypesProgress,
    fetchDocTypesError: state.documentTypeV2.fetchDocTypesError,
    vendorDocTypes: state.documentTypeV2.types,
});

const connectionActions = {
    hideDialog: hideFormDataCopyDialog,
    fetchOrgProductDetailsTabs: fetchOrgProductDetailsTabs,
    showSnackBar: showSnackBar,
    updateOrgProdutDetailsTabSectionItemData: updateOrgProdutDetailsTabSectionItemData,
    updateOrgProductDetails: updateOrgProductDetails,
    fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
    updateOrgVendorTabSectionItemData: updateOrgVendorTabSectionItemData,
    updateOrgServiceTabSectionItemData: updateOrgServiceTabSectionItemData,
    addNewRow: addNewRow,
    addOrgDocument: addOrgDocument,
    addDocTypeFile: addDocTypeFile,
    updateFinancialDetailsData:updateFinancialDetailsData,
    updateDocTypeFile:updateDocTypeFile,
    fetchDocTypes:fetchDocTypes
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    heading: {
        fontWeight: 400,
        fontSize: 14,
    },
    body: {
        marginTop: 20,
        border: '1px solid #aeaeae',
        borderRadius: 4,
        padding: 20
    },
    section: {
        marginBottom: 20
    },
    ruleTable: {
        width: "100%",
        '& [class*=MuiSelect-root]': {
            minHeight: 22
        }
    },
    leftOp: {
        width: "45%",
        paddingTop: 10,
    },
    rightOp: {
        width: "45%",
        paddingTop: 10,
    },
    noPaddingTop: {
        paddingTop: 0
    },
    action: {
        width: '10%',
        textAlign: 'center'
    },
    addRuleDiv: {
        textAlign: 'center',
        marginTop: 50
    },
    bottomAction: {
        textAlign: 'right',
        marginTop: 20,
        marginBottom: 20,
    },
    actionBottomBtn: {
        marginLeft: 10
    },
    mappings: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    source: {
        flex: 1,
    },
    target: {
        flex: 1,
    },
    userRes: {
        flex: 1,
        marginLeft: 20,
        marginRight: 20
    },
    rules: {
        flex: 1,
        marginTop: 20
    }
});

class FormDataCopyDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            productOptions: [],
            selectedProduct: '',
            ruleDatas: [],
            optionIdMap: {},
            fieldMaps: [],
            formItemOptions: [],
            selectedCatalogField: '',
            selectedSource: '',
            selectedUser: '',
            userOptions: [],
            isTableType: false,
            isContractType: false,
            isVendorFileType: false,
            tableFields: [],
            productContractUpdateFields:null,
            vendorContractUpdateFields:null,
        }

        this.checkCatalogFieldType = this.checkCatalogFieldType.bind(this);

    }

    componentDidMount() {
        this.props.fetchDocTypes();
        this.initProducts();
    }

    componentDidUpdate(prevProps) {
        if (this.props.orgProductDetailTabsProgress === false && prevProps.orgProductDetailTabsProgress === true) {
            if (this.props.orgProductDetailTabs) {
                let allItems = [];
                this.props.orgProductDetailTabs.forEach(function (tab) {
                    tab.OrgProductTabSections.forEach(function (section) {
                        section.OrgProductTabItems.forEach(function (item) {
                            allItems.push({
                                ...item,
                                TabName: tab.TabName
                            })
                        })
                    })
                })
                let appCatType = getApplicationCatalogTypeForForm();
                this.createOptionMapProduct(appCatType, allItems);
            }

        }

        if (this.props.orgCatalogDetailTabsProgress === false && prevProps.orgCatalogDetailTabsProgress === true) {
            if (this.props.orgCatalogDetailTabs) {
                let allItems = [];
                this.props.orgCatalogDetailTabs.forEach(function (tab) {
                    tab.sections.forEach(function (section) {
                        section.items.forEach(function (item) {
                            allItems.push({
                                ...item,
                                TabName: tab.name
                            })
                        })
                    })
                })
                let appCatType = getApplicationCatalogTypeForForm();
                this.createOptionMapVendor(appCatType, allItems);
            }
        }

        if (this.props.isUpdatingOrgProductDetailTabsSectionItemData === false && prevProps.isUpdatingOrgProductDetailTabsSectionItemData === true) {
            this.props.showSnackBar("Product updated successfully.", "success", 3000)
            this.props.hideDialog()
        }

        if (this.props.orgProductDetailsUpdateProgress === false && prevProps.orgProductDetailsUpdateProgress === true) {
            this.props.showSnackBar("Product updated successfully.", "success", 3000)
            this.props.hideDialog()
        }

        if (!this.props.orgVendorTabSectionItemDataUpdateProgress && prevProps.orgVendorTabSectionItemDataUpdateProgress) {
            if (!this.props.orgVendorTabSectionItemDataUpdateError) {
                this.props.showSnackBar("Vendor updated successfully.", "success", 3000)
                this.props.hideDialog()
            }
        }

        if (!this.props.addNewRowProgress && prevProps.addNewRowProgress) {
            if (this.props.addNewRowError === null) {
                this.props.showSnackBar("Record added successfully.", "success", 3000);
                this.props.hideDialog()
            } else {
                this.props.showSnackBar("Something went wrong.", "error", 3000);
            }
        }

        if (!this.props.orgServiceTabSectionItemDataUpdateProgress && prevProps.orgServiceTabSectionItemDataUpdateProgress) {
            if (!this.props.orgServiceTabSectionItemDataUpdateError) {
                this.props.showSnackBar("Service updated successfully.", "success", 3000)
                this.props.hideDialog()
            } else {
                this.props.showSnackBar("Failed", "error", 3000);
            }
        }

        if (!this.props.addOrgDocumentProgress && prevProps.addOrgDocumentProgress) {
            if (this.props.addOrgDocumentError === null) {
                this.updateProductContractFields()
                this.props.showSnackBar("Contract added successfully.", "success", 3000)
                this.props.hideDialog()
            } else {
                this.props.showSnackBar("Failed", "error", 3000);
            }
        }


        if (!this.props.addDocTypeFileProgress && prevProps.addDocTypeFileProgress) {
            if (!this.props.addDocTypeFileError) {
                this.updateVendorContractFields()
                this.props.showSnackBar("Contract added successfully.", "success", 3000)
                this.props.hideDialog()
            } else {
                this.props.showSnackBar("Something went wrong.", "error", 3000);
            }
        }

    }

    updateProductContractFields(){
        const { productContractUpdateFields,selectedProduct } = this.state;
        const { addOrgDocumentSuccess } = this.props;
        let params = {
            attributes : [
            {
              "attribute": "product_name",
              "label": "Product Name",
              "type": "text",
              "value": ""
            },{
                "attribute": "start_date",
                "label": "Start Date",
                "type": "date",
                "value": productContractUpdateFields['start_date'] ? productContractUpdateFields['start_date'] : ''
              },{
              "attribute": "end_date",
              "label": "End Date",
              "type": "date",
              "value": productContractUpdateFields['end_date'] ? productContractUpdateFields['end_date'] : ''
            },{
              "attribute": "renewal_date",
              "label": "Renewal Date",
              "type": "date",
              "value": productContractUpdateFields['renewal_date'] ? productContractUpdateFields['renewal_date'] : ''
            },{
              "attribute": "total_cost",
              "label": "Total Cost",
              "type": "price",
              "value": productContractUpdateFields['total_cost'] ? productContractUpdateFields['total_cost'] : ''
            },{
              "attribute": "payment_term",
              "label": "Payment Term",
              "type": "payment_term",
              "value": productContractUpdateFields['payment_term'] ? productContractUpdateFields['payment_term'] : ''
            },{
              "attribute": "quantity",
              "label": "Quantity",
              "type": "number",
              "value": "0.00"
            },{
              "attribute": "unit_price",
              "label": "Unit Price",
              "type": "price",
              "value": "USD0.00"
            },{
              "attribute": "price",
              "label": "Price",
              "type": "price",
              "value": "USD0.00"
            },{
              "attribute": "vendor_name",
              "label": "Vendor Name",
              "type": "text",
              "value": ""
            },{
              "attribute": "amount_due",
              "label": "Amount Due",
              "type": "price",
              "value": "USD0.00"
            },{
              "attribute": "due_date",
              "label": "Due Date",
              "type": "date",
              "value": ""
            },{
              "attribute": "freight_amount",
              "label": "Freight Amount",
              "type": "price",
              "value": "USD0.00"
            },{
              "attribute": "invoice_date",
              "label": "Invoice Date",
              "type": "date",
              "value": ""
            },{
              "attribute": "net_amount",
              "label": "Net Amount",
              "type": "price",
              "value": "USD0.00"
            },{
              "attribute": "payment_terms",
              "label": "Payment Terms",
              "type": "text",
              "value": ""
            },{
              "attribute": "purchase_order",
              "label": "Purchase Order",
              "type": "text",
              "value": ""
            },{
              "attribute": "receiver_address",
              "label": "Receiver Address",
              "type": "text",
              "value": ""
            },{
              "attribute": "receiver_name",
              "label": "Receiver Name",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "receiver_tax_id",
              "label": "Receiver tax id",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "remit_to_address",
              "label": "Remit to address",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "remit_to_name",
              "label": "Remit to name",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "ship_to_address",
              "label": "Ship to address",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "ship_to_name",
              "label": "ship to name",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "supplier_address",
              "label": "Supplier address",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "supplier_email",
              "label": "Supplier email",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "supplier_iban",
              "label": "supplier iban",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "supplier_tax_id",
              "label": "Supplier tax id",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "supplier_website",
              "label": "supplier website",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "total_tax_amount",
              "label": "totalTaxAmount",
              "type": "price",
              "value": "USD0.00"
            },
            {
              "attribute": "vat",
              "label": "Vat",
              "type": "price",
              "value": "USD0.00"
            },
            {
              "attribute": "supplier_name",
              "label": "Supplier Name",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "license",
              "label": "License",
              "type": "text",
              "value": ""
            }
          ],lineItemAttributes:[[
            {
              "attribute": "license",
              "label": "License",
              "type": "text",
              "value": ""
            },
            {
              "attribute": "start_date",
              "label": "Start Date",
              "type": "date",
              "value": ""
            },
            {
              "attribute": "end_date",
              "label": "End Date",
              "type": "date",
              "value": ""
            },
            {
              "attribute": "quantity",
              "label": "Quantity",
              "type": "number",
              "value": "0.00"
            },
            {
              "attribute": "unit_price",
              "label": "Unit Price",
              "type": "price",
              "value": "USDNaN"
            },
            {
              "attribute": "price",
              "label": "Price",
              "type": "price",
              "value": "USDNaN"
            }
          ]]
        }
        this.props.updateFinancialDetailsData(selectedProduct?.value, addOrgDocumentSuccess[0].ID, params);
    }

    updateVendorContractFields(){
        const { vendorContractUpdateFields } = this.state;
        const { addDocTypeFileSuccess } = this.props;

      this.props.updateDocTypeFile(addDocTypeFileSuccess.id, vendorContractUpdateFields)
    }

    initProducts() {
        let products = this.props.evaluation.Products.map(o => {
            return {
                label: o.Name,
                value: o.ID,
                entityId: o.EntityID,
                type: o.Type
            }
        })

        let selectedProduct = '';
        if (this.props.selectedProduct === undefined || this.props.selectedProduct === null || this.props.selectedProduct === '') {
            selectedProduct = products[0];
        } else {
            selectedProduct = this.props.selectedProduct;
        }

        this.setState({
            selectedProduct: selectedProduct,
            productOptions: products,
        }, () => {
            this.getProductDetailsTabs();
        })
    }

    initFormSettings(type, isReturn = false) {
        const { formConfigurations, formDatas, showMap } = this.props;
        var sectionName = formConfigurations?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = formConfigurations?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
        let formItemOptions = [];
        const validTypes = getFormTypeUsingAppCatalogType(type);
        formDatas[sectionName].forEach((section, sk) => {
            var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
            if (showMap[section.ID] !== undefined && showMap[section.ID] === false) {
                return;
            }
            sectionItems.forEach(function (item) {
                if (showMap[item.ID] !== undefined && showMap[item.ID] === false) {
                    return;
                }

                if (validTypes.indexOf(item.Type) === -1) {
                    return;
                }
                formItemOptions.push({
                    label: item?.ItemName,
                    value: item?.ID,
                    response: item?.value,
                    type: item.Type,
                    data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                })
            })
        })

        if (isReturn) {
            return formItemOptions;
        } else {
            this.setState({
                formItemOptions: formItemOptions
            })
        }

    }

    createOptionMap(types) {
        var optionsData = [];
        var optionMap = {};
        var validTypes = types;
        let optionIdMap = {};
        const { orgProductDetailTabs } = this.props;
        if (orgProductDetailTabs === undefined || orgProductDetailTabs === null || orgProductDetailTabs.length <= 0) {
            return;
        }
        this.props.orgProductDetailTabs.forEach(function (tab) {
            // if (tab.TabUID !== 'tab-l3wvezqv-1654165478263') {
            //   return;
            // }
            if (tab.OrgProductTabSections) {
                tab.OrgProductTabSections.forEach(function (section) {
                    if (section.OrgProductTabItems) {
                        section.OrgProductTabItems.forEach(function (item) {
                            if (validTypes.indexOf(item.Type) !== -1) {
                                let tabName = tab.TabName;
                                // let sectionName = section.SectionName;
                                let itemName = item.ItemName;
                                let itemId = item.ID;
                                var parentOpName = tabName;
                                // var parentOpName = tabName + ' - ' + sectionName;
                                if (itemName === null || itemName === '') {
                                    return;
                                }
                                let isCustom = item.ItemUID.indexOf('item-') !== -1 ? true : false;

                                if (item.ItemUID.indexOf('prod_') !== -1 && catalogDefaultItemsForUpdate.indexOf(item.ItemUID) === -1) {
                                    return;
                                }

                                if (optionMap[parentOpName] === undefined) {
                                    optionMap[parentOpName] = {
                                        label: parentOpName,
                                        options: [{
                                            label: itemName,
                                            value: itemId,
                                            type: item.Type,
                                            isCustom: isCustom,
                                            itemUID: item.ItemUID,
                                            data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                                        }]
                                    }
                                } else {
                                    optionMap[parentOpName].options.push({
                                        label: itemName,
                                        value: itemId,
                                        type: item.Type,
                                        isCustom: isCustom,
                                        itemUID: item.ItemUID,
                                        data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                                    })
                                }

                                optionIdMap[itemId] = {
                                    label: itemName,
                                    value: itemId,
                                    type: item.Type,
                                    itemUID: item.ItemUID,
                                    isCustom: isCustom,
                                    data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                                }
                            }
                        })
                    }
                })
            }
        })

        Object.keys(optionMap).forEach(function (key) {
            optionsData.push(optionMap[key]);
        });
        this.setState({
            ruleDatas: optionsData,
            optionIdMap: optionIdMap
        })
    }

    createOptionMapProduct(types, allItems) {
        var optionsData = [];
        var optionMap = {};
        var validTypes = types;
        let optionIdMap = {};
        if (allItems === undefined || allItems === null || allItems.length <= 0) {
            return;
        }

        allItems.forEach(function (item) {
            if (validTypes.indexOf(item.Type) !== -1) {
                let tabName = item.TabName;
                // let sectionName = section.SectionName;
                let itemName = item.ItemName;
                let itemId = item.ID;
                var parentOpName = tabName;
                // var parentOpName = tabName + ' - ' + sectionName;
                if (itemName === null || itemName === '') {
                    return;
                }
                let isCustom = item.ItemUID.indexOf('item-') !== -1 ? true : false;

                if (item.ItemUID.indexOf('prod_') !== -1 && catalogDefaultItemsForUpdate.indexOf(item.ItemUID) === -1) {
                    return;
                }

                if (optionMap[parentOpName] === undefined) {
                    optionMap[parentOpName] = {
                        label: parentOpName,
                        options: [{
                            label: itemName,
                            value: itemId,
                            type: item.Type,
                            isCustom: isCustom,
                            itemUID: item.ItemUID,
                            data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                        }]
                    }
                } else {
                    optionMap[parentOpName].options.push({
                        label: itemName,
                        value: itemId,
                        type: item.Type,
                        isCustom: isCustom,
                        itemUID: item.ItemUID,
                        data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                    })
                }

                optionIdMap[itemId] = {
                    label: itemName,
                    value: itemId,
                    type: item.Type,
                    itemUID: item.ItemUID,
                    isCustom: isCustom,
                    data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                }
            }

        })

        optionMap['Contract Details'] = {
            label: 'Contract Details',
            options: [{
                label: 'Contracts, Agreements & POs',
                value: 'contracts',
                type: 100,
                isCustom: true,
                itemUID: 'contracts',
                data: []
            }]
        }

        optionIdMap['contracts'] = {
            label: 'Contracts, Agreements & POs',
            value: 'contracts',
            type: 100,
            itemUID: 'contracts',
            isCustom: true,
            data: [{
                label: 'Contract Name',
                value: 'title',
                type: 1,
                isCustom: true,
                itemUID: 'title',
                isRequired:true,
                data: []
            }, {
                label: 'File',
                value: 'url',
                type: 9,
                isCustom: true,
                itemUID: 'url',
                isRequired:true,
                data: []
            }, {
                label: 'Owner',
                value: 'ownerId',
                type: 7,
                isCustom: true,
                itemUID: 'ownerId',
                isRequired:false,
                data: []
            }, {
                label: 'Start Date',
                value: 'start_date',
                type: 2,
                isCustom: true,
                itemUID: 'start_date',
                isRequired:false,
                data: []
            }, {
                label: 'End Date',
                value: 'end_date',
                type: 2,
                isCustom: true,
                itemUID: 'end_date',
                isRequired:false,
                data: []
            }, {
                label: 'Renewal Date',
                value: 'renewal_date',
                type: 2,
                isCustom: true,
                itemUID: 'renewal_date',
                isRequired:false,
                data: []
            }, {
                label: 'Total Cost',
                value: 'total_cost',
                type: 20,
                isCustom: true,
                itemUID: 'total_cost',
                isRequired:false,
                data: []
            }, {
                label: 'Payment Term',
                value: 'payment_term',
                type: 1,
                isCustom: true,
                itemUID: 'payment_term',
                isRequired:false,
                data: []
            }]
        }


        Object.keys(optionMap).forEach(function (key) {
            optionsData.push(optionMap[key]);
        });
        this.setState({
            ruleDatas: optionsData,
            optionIdMap: optionIdMap
        })
    }

    createOptionMapVendor(types, allItems) {
        var optionsData = [];
        var optionMap = {};
        var validTypes = types;
        let optionIdMap = {};
        if (allItems === undefined || allItems === null || allItems.length <= 0) {
            return;
        }

        allItems.forEach(function (item) {
            if (validTypes.indexOf(item.type) !== -1) {
                let tabName = item.TabName;
                // let sectionName = section.SectionName;
                let itemName = item.name;
                let itemId = item.id;
                var parentOpName = tabName;
                // var parentOpName = tabName + ' - ' + sectionName;
                if (itemName === null || itemName === '') {
                    return;
                }
                let isCustom = item.slug.indexOf('custom-') !== -1 ? true : false;

                if (optionMap[parentOpName] === undefined) {
                    optionMap[parentOpName] = {
                        label: parentOpName,
                        options: [{
                            label: itemName,
                            value: itemId,
                            type: item.type,
                            isCustom: isCustom,
                            itemUID: item.slug,
                            data: item.additional_data !== null && item.additional_data.length > 0 ? JSON.parse(item.additional_data) : []
                        }]
                    }
                } else {
                    optionMap[parentOpName].options.push({
                        label: itemName,
                        value: itemId,
                        type: item.type,
                        isCustom: isCustom,
                        itemUID: item.slug,
                        data: item.additional_data !== null && item.additional_data.length > 0 ? JSON.parse(item.additional_data) : []
                    })
                }

                optionIdMap[itemId] = {
                    label: itemName,
                    value: itemId,
                    type: item.type,
                    itemUID: item.slug,
                    isCustom: isCustom,
                    data: item.additional_data !== null && item.additional_data.length > 0 ? JSON.parse(item.additional_data) : []
                }
            }
        })

        Object.keys(optionMap).forEach(function (key) {
            optionsData.push(optionMap[key]);
        });

        this.setState({
            ruleDatas: optionsData,
            optionIdMap: optionIdMap
        })
    }

    addNewFieldMap() {
        var fields = this.state.fieldMaps;
        var newData = [
            ...fields,
            {
                source: '',
                target: '',
                error: ''
            }]
        this.setState({
            fieldMaps: newData
        })
    }

    checkCatalogFieldType() {
        const { selectedCatalogField, optionIdMap, selectedProduct } = this.state;
        if (selectedCatalogField && selectedCatalogField !== '') {
            var isTableType = false;
            var isContractType = false;
            var isVendorFileType = false;
            var tableFields = [];
            const optionData = optionIdMap[selectedCatalogField.value]
            if (optionData.type === 6) {
                isTableType = true;
                tableFields = optionData.data.map((o) => {
                    return {
                        ...o,
                        selectedSource: '',
                        selectedUser: '',
                        userOptions: [],
                        sourceOptions: this.initFormSettings(o.type, true)
                    }
                });
            } else if (optionData.type === 100) {
                isContractType = true;
                tableFields = optionData.data.map((o) => {
                    return {
                        ...o,
                        selectedSource: '',
                        selectedUser: '',
                        userOptions: [],
                        sourceOptions: this.initFormSettings(o.type, true)
                    }
                });
            } else if (optionData.type === 9 && selectedProduct?.type !== 'PRODUCT') {
                isVendorFileType = true;
                tableFields = [{
                    label: "Document Name",
                    value: 1111,
                    id: 'title',
                    type: 1,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:true,
                    sourceOptions: this.initFormSettings(1, true)
                },{
                    label: "Document Type",
                    value: 'documentType',
                    id: 'documentType',
                    type: 1,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:true,
                    sourceOptions: this.props.vendorDocTypes.map((o) => {
                        return {
                          type: o?.type,
                          name: o?.name,
                          label: o?.name,
                          isEdit: false,
                          ID: o?.id,
                          value: o?.id,
                        }
                      })
                },{
                    label: "File",
                    value: 3333,
                    id: 'url',
                    type: 9,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:true,
                    sourceOptions: this.initFormSettings(9, true)
                },{
                    label: 'Owner',
                    value: 'ownerId',
                    id: 'ownerId',
                    type: 7,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:false,
                    sourceOptions: this.initFormSettings(7, true)
                }, {
                    label: 'Start Date',
                    value: 'start_date',
                    id: 'start_date',
                    type: 2,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:false,
                    sourceOptions: this.initFormSettings(2, true)
                }, {
                    label: 'End Date',
                    value: 'end_date',
                    id: 'end_date',
                    type: 2,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:false,
                    sourceOptions: this.initFormSettings(2, true)
                }, {
                    label: 'Renewal Date',
                    value: 'renewal_date',
                    id: 'renewal_date',
                    type: 2,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:false,
                    sourceOptions: this.initFormSettings(2, true)
                }, {
                    label: 'Total Cost',
                    value: 'total_cost',
                    id: 'total_cost',
                    type: 20,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:false,
                    sourceOptions: this.initFormSettings(20, true)
                }, {
                    label: 'Payment Term',
                    value: 'payment_term',
                    id: 'payment_term',
                    type: 1,
                    selectedSource: "",
                    selectedUser: "",
                    userOptions: [],
                    isRequired:false,
                    sourceOptions: this.initFormSettings(1, true)
                }];
            } else {
                isTableType = false;
                tableFields = []
            }
            this.setState({
                isTableType: isTableType,
                isContractType: isContractType,
                isVendorFileType: isVendorFileType,
                tableFields: tableFields
            })
            this.initFormSettings(optionData.type);
        } else {
            this.setState({
                isTableType: false,
                isContractType: false,
                isVendorFileType: false,
                tableFields: [],
                selectedSource: '',
                selectedUser: '',
            })
        }
    }

    updateTableValues(field, value, index) {
        let tableFields = this.state.tableFields;
        let col = tableFields[index];
        col[field] = value;
        if (field === 'selectedSource') {
            col.selectedUser = '';
            col.userOptions = value?.userOptions;
        }
        this.setState({
            tableFields: tableFields
        })
    }

    getFormResponseForVendor(selectedSource, selectedCatalogField, forTable = false) {
        let response = ''
        if (selectedSource.response) {
            if ([1, 2, 3, 11, 12, 13, 14, 15, 16].includes(selectedSource.type)) {
                response = selectedSource.response || ''
            } else if ([4, 5].includes(selectedSource.type)) {
                let selectedOption = selectedSource?.response;
                let options = selectedSource?.data.filter(o => selectedOption.indexOf(o.value) !== -1).map(o => (o.label).trim())
                if (options && selectedCatalogField && selectedCatalogField.data && selectedCatalogField.data.length > 0) {
                    response = selectedCatalogField.data.filter(o => options.indexOf((o.label).trim()) !== -1)
                }
            } else if ([7, 8].includes(selectedSource.type)) {
                if (selectedSource.response && selectedSource.response.length > 0 && forTable === false) {
                    response = selectedSource.response.map((ut) => {
                        return {
                            "value": ut.ID,
                            "name": ut.Name,
                            "label": ut.Name,
                            "email": ut.Email,
                            "description": ut.Email,
                        }
                    });
                } else {
                    response = selectedSource.response || ''
                }
            } else if ([10].includes(selectedSource.type)) {
                if (selectedSource.response && selectedSource.response.length > 0) {
                    response = selectedSource.response.map((prd) => {
                        return {
                            product_id: prd?.ID,
                            product_logo: prd?.ProductLogo,
                            name: prd?.Name,
                            description: prd?.Description,
                        }
                    });
                }
            } else {
                response = selectedSource.response || ''
            }
        }

        if (forTable && [7, 8].includes(selectedSource.type) && response && response.length > 0) {
            response = response[0]
        } else if (forTable && [9].includes(selectedSource.type) && selectedSource && selectedSource.response && selectedSource?.response.length > 0) {
            response = {
                "name": selectedSource.response[0].name,
                "link": selectedSource.response[0].url,
                "createdAt": new Date()
            }
        }
        return response
    }

    updateDataToProduct() {
        let { selectedSource, selectedCatalogField, selectedProduct, tableFields, isTableType, isContractType, isVendorFileType } = this.state;

        let selectedPrdType = selectedProduct.type;

        if (isTableType || isContractType || isVendorFileType) {
            let isError = false;
            let errorMsg = '';
            if (tableFields) {
                tableFields.forEach(function (data) {
                    if (isError) {
                        return
                    }
                    if(!data.isRequired){
                        return
                    }
                    if (data.selectedSource === undefined || data.selectedSource.length <= 0 || data.selectedSource === '' || data.selectedSource === null) {
                        isError = true
                        errorMsg = "Please select source for "+ data?.label+'.'
                        return;
                    }
                })
            }
            if (isError) {
                this.props.showSnackBar(errorMsg, "error", 3000)
                return;
            }
        } else {
            if (selectedSource.length <= 0 || selectedSource === '' || selectedSource === null) {
                this.props.showSnackBar("Please select source.", "error", 3000)
                return;
            }

            if (selectedCatalogField.length <= 0 || selectedCatalogField === '' || selectedCatalogField === null) {
                this.props.showSnackBar("Please select target.", "error", 3000)
                return;
            }
        }
        // console.log(selectedSource.type, 'selectedSource')
        // console.log(selectedCatalogField.type, 'selectedCatalogField')
        // console.log(isTableType, 'isTableType')
        // console.log(tableFields, 'tableFields')

        if (!isTableType && !isContractType && !isVendorFileType) {
            let response = '';
            let isAppendRes = false;
            if (selectedPrdType === 'PRODUCT') {
                if (selectedSource.response !== undefined && selectedSource.response !== null && selectedSource.response !== '') {
                    if (selectedSource.type === 7 || selectedSource.type === 8) {
                        if (selectedSource.response.length > 0) {
                            response = selectedSource.response.map(o => {
                                return {
                                    value: o.id,
                                    name: o.Name,
                                    label: o.label,
                                    email: o?.Email,
                                }
                            })
                            isAppendRes = true;
                        }
                    } else if (selectedSource.type === 9) {
                        response = [{
                            name: selectedSource?.response?.name,
                            link: selectedSource?.response?.url,
                            createdAt: new Date()
                        }]
                        isAppendRes = true;
                    } else if (selectedSource.type === 16 && selectedCatalogField.type === 11) {
                        let res = 'Value: ' + selectedSource.response?.value;
                        response = res;
                    } else if (selectedSource.type === 16) {
                        response = selectedSource?.response
                    } else if (selectedSource.type === 2) {
                        response = selectedSource?.response;
                    } else if ([5, 20].includes(selectedSource.type) && selectedSource.response && selectedSource.response !== '') {

                        let selectedOption = selectedSource?.response;
                        let options = selectedSource?.data.filter(o => selectedOption.indexOf(o.value) !== -1).map(o => (o.label).trim())
                        if (options && selectedCatalogField && selectedCatalogField.data && selectedCatalogField.data.length > 0) {
                            response = selectedCatalogField.data.filter(o => options.indexOf((o.label).trim()) !== -1)
                        }
                    } else if ([4, 19].includes(selectedSource.type) && selectedSource.response && selectedSource.response !== '') {
                        // console.log(selectedSource, 'selectedSource111')
                        // console.log(selectedCatalogField, 'selectedCatalogField')
                        let selectedOption = parseInt(selectedSource?.response);
                        let index = selectedSource?.data.findIndex(o => selectedOption === o.value);
                        let selectedLabel = selectedSource?.data[index].label.trim();
                        if (selectedLabel && selectedCatalogField && selectedCatalogField.data && selectedCatalogField.data.length > 0) {
                            response = selectedCatalogField.data.filter(o => selectedLabel === o.label.trim())
                        }

                    } else if ((selectedSource.type === 13 || selectedSource.type === 15) && selectedCatalogField.type === 11) {
                        let res = 'Value: ' + selectedSource.response;
                        response = res;
                    } else {
                        response = selectedSource.response;
                        if (selectedSource.type === 10) {
                            isAppendRes = true;
                        }
                    }
                }
            } else if (selectedPrdType === 'CUSTOM_VENDOR' || selectedPrdType === 'MASTER_VENDOR' || selectedPrdType === 'CUSTOM_VENDOR_SERVICE' || selectedPrdType === 'MASTER_VENDOR_SERVICE') {
                // console.log(selectedSource, 'selectedSource')
                response = this.getFormResponseForVendor(selectedSource, selectedCatalogField, false)
            }

            let params = {
                value: response,
            }

            if ([7, 8, 10].includes(selectedSource.type)) {
                params['is_delete'] = false;
            }
            // console.log(response, 'FinalResponse')
            if (selectedPrdType === 'PRODUCT') {
                if (selectedCatalogField.isCustom) {
                    this.props.updateOrgProdutDetailsTabSectionItemData(selectedProduct.value, selectedCatalogField.itemUID, {
                        values: [JSON.stringify({ data: response })],
                        append_response: isAppendRes
                    });
                } else {
                    var data = {};
                    let field = getItemDefaultField(selectedCatalogField.itemUID)
                    data[field] = response;
                    this.props.updateOrgProductDetails(selectedProduct.value, data);
                }
            } else if (selectedPrdType === 'CUSTOM_VENDOR' || selectedPrdType === 'MASTER_VENDOR') {
                this.props.updateOrgVendorTabSectionItemData(selectedProduct.entityId, selectedCatalogField.itemUID, params);
            } else if (selectedPrdType === 'CUSTOM_VENDOR_SERVICE' || selectedPrdType === 'MASTER_VENDOR_SERVICE') {
                this.props.updateOrgServiceTabSectionItemData(selectedProduct.entityId.split('-')[0], selectedCatalogField.itemUID, params);
            }
        } else if (isVendorFileType) {
            let url = '';
            let title = '';
            let ownerId = '';
            let type_id,start_date,end_date,renewal_date,amount_due,payment_term = '';
            tableFields.forEach((td) => {
                let selectedSource = td.selectedSource;
                if(td.id === "documentType"){
                    type_id = selectedSource.value
                }
                if (selectedSource.response !== undefined && selectedSource.response !== null) {
                    if (td.id === "ownerId") {
                        if (selectedSource.response.length > 0) {
                            ownerId = selectedSource.response[0].value
                        }
                    } else if (td.id === "url") {
                        if (selectedSource.response.length > 0) {
                            url = selectedSource?.response[0].url
                        }
                    } else if (td.id === 'title') {
                        title = selectedSource.response;
                    }else if (td.id === "start_date") {
                        start_date = selectedSource.response;
                    }else if (td.id === "end_date") {
                        end_date = selectedSource.response;
                    }else if (td.id === "renewal_date") {
                        renewal_date = selectedSource.response;
                    }else if (td.id === "total_cost") {
                        data = selectedSource.response;
                        let newRes = {
                            "type": {
                                "label": "USD",
                                "value": "USD"
                            },
                            "value": "00"
                        }
                        try{
                            newRes = data;
                        }catch(e){}
                        amount_due = newRes
                    }else if (td.id === "payment_term") {
                        payment_term = selectedSource.response;
                    } 
                }
            })

            let docData = {
                "name": title,
                "url": url,
                "description": "",
                "created_at": new Date(),
            }

            let params = {
                "item_slug": selectedCatalogField.itemUID,
                "entity_id": selectedProduct.entityId,
                "entity_type": selectedPrdType === 'CUSTOM_VENDOR' || selectedPrdType === 'MASTER_VENDOR' ? 'vendor' : 'vendorservice',
                "file": docData,
                "type_id" :type_id
            }
            if(ownerId !== ''){
                params['owner_id'] = ownerId.toString()
            }
            
            let vendorContractUpdateFields = {
                start_date:start_date,
                end_date:end_date,
                renewal_date:renewal_date,
                amount_due:amount_due,
                payment_term:payment_term,
            }
            this.setState({
                vendorContractUpdateFields:vendorContractUpdateFields
            },()=>{
            this.props.addDocTypeFile(params)
            })
        } else if (isContractType) {
            let ownerId = '';
            let url = '';
            let title,start_date,end_date,renewal_date,total_cost,payment_term = '';
            tableFields.forEach((td) => {
                let selectedSource = td.selectedSource;
                if (selectedSource.response !== undefined && selectedSource.response !== null) {
                    if (td.itemUID === "ownerId") {
                        if (selectedSource.response.length > 0) {
                            ownerId = selectedSource.response[0].value
                        }
                    } else if (td.itemUID === "url") {
                        if (selectedSource.response.length > 0) {
                            url = selectedSource?.response[0].url
                        }
                    } else if (td.itemUID === 'title') {
                        title = selectedSource.response;
                    }else if (td.itemUID === "start_date") {
                        start_date = selectedSource.response;
                    }else if (td.itemUID === "end_date") {
                        end_date = selectedSource.response;
                    }else if (td.itemUID === "renewal_date") {
                        renewal_date = selectedSource.response;
                    }else if (td.itemUID === "total_cost") {
                        data = selectedSource.response;
                        let newRes = "USD00"
                        try{
                            newRes = data.type.value+data.value;
                        }catch(e){}
                        total_cost = newRes
                    }else if (td.itemUID === "payment_term") {
                        payment_term = selectedSource.response;
                    }
                }
            })

            let docData = {
                "description": "",
                "title": title,
                "url": url,
            }

            if(ownerId !== ''){
                docData['ownerId'] = ownerId
            }

            let params = {
                "documents":
                    [docData]
            }
            let productContractUpdateFields = {
                start_date:start_date,
                end_date:end_date,
                renewal_date:renewal_date,
                total_cost:total_cost,
                payment_term:payment_term,
            }
            this.setState({
                productContractUpdateFields:productContractUpdateFields
            },()=>{
                this.props.addOrgDocument(
                    selectedProduct.value,
                    params
                );
            })
          
        } else {
            if (selectedPrdType === 'PRODUCT') {
                let responseData = tableFields.map((td) => {
                    let selectedSource = td.selectedSource;
                    let response = '';
                    if (selectedSource.response !== undefined && selectedSource.response !== null) {
                        if (selectedSource.type === 7 || selectedSource.type === 8) {
                            if (selectedSource.response.length > 0) {
                                response = selectedSource.response.map(o => {
                                    return {
                                        value: o.id,
                                        name: o.Name,
                                        label: o.label,
                                        email: o?.Email,
                                    }
                                })
                            }
                        } else if (selectedSource.type === 9) {
                            response = [{
                                name: selectedSource?.response?.name,
                                link: selectedSource?.response?.url,
                                createdAt: new Date()
                            }]
                        } else if (selectedSource.type === 16 && selectedCatalogField.type === 11) {
                            let res = 'Value: ' + selectedSource.response?.value;
                            response = res;
                        } else if (selectedSource.type === 16) {
                            response = selectedSource?.response?.value
                        } else if (selectedSource.type === 2) {
                            response = selectedSource?.response;
                        } else if ([5, 20].includes(selectedSource.type) && selectedSource.response && selectedSource.response !== '') {

                            let selectedOption = selectedSource?.response;
                            let options = selectedSource?.data.filter(o => selectedOption.indexOf(o.value) !== -1).map(o => (o.label).trim())
                            if (options && selectedCatalogField && selectedCatalogField.data && selectedCatalogField.data.length > 0) {
                                response = selectedCatalogField.data.filter(o => options.indexOf((o.label).trim()) !== -1)
                            }
                        } else if ([4, 19].includes(selectedSource.type) && selectedSource.response && selectedSource.response !== '') {
                            let selectedOption = parseInt(selectedSource?.response);
                            let index = selectedSource?.data.findIndex(o => selectedOption === o.value);
                            let selectedLabel = (selectedSource?.data[index].label).trim();

                            if (selectedLabel && selectedCatalogField && selectedCatalogField.data && selectedCatalogField.data.length > 0) {
                                response = selectedCatalogField.data.filter(o => selectedLabel === (o.label).trim())
                            }

                        } else if ((selectedSource.type === 13 || selectedSource.type === 15) && selectedCatalogField.type === 11) {
                            let res = 'Value: ' + selectedSource.response;
                            response = res;
                        } else {
                            response = selectedSource.response;
                            if (selectedSource.type === 10) {
                            }
                        }
                    }

                    return {
                        label: response,
                        value: td.id,
                        id: td.id,
                        type: td.type
                    }
                })
                let paramsD = JSON.stringify({ data: [responseData] })
                this.props.updateOrgProdutDetailsTabSectionItemData(selectedProduct.value, selectedCatalogField.itemUID, {
                    values: [paramsD],
                    append_response: true
                });
            } else {
                let responseData = tableFields.map((td) => {
                    let selectedSource = td.selectedSource;
                    let response = this.getFormResponseForVendor(selectedSource, selectedCatalogField, true)
                    return {
                        label: response,
                        value: td.id,
                        id: td.id,
                        type: td.type
                    }
                })
                let params = {
                    "item_slug": selectedCatalogField.itemUID,
                    "entity_id": selectedProduct.entityId,
                    "entity_type": selectedPrdType === 'CUSTOM_VENDOR' || selectedPrdType === 'MASTER_VENDOR' ? 'vendor' : 'vendorservice',
                    "row_data": [
                    ]
                }
                responseData.forEach(function (dt) {
                    params.row_data.push({
                        label: dt.id,
                        value: dt.label
                    })
                })
                this.props.addNewRow(params)
            }
        }
    }

    setSelectedProduct(product) {
        this.setState({
            selectedProduct: product,
            selectedSource: '',
            selectedCatalogField: ''
        }, () => {
            this.getProductDetailsTabs()
        })
    }

    getProductDetailsTabs() {
        const { selectedProduct } = this.state;
        if (selectedProduct) {
            let selectedPrdType = selectedProduct.type;
            if (selectedPrdType === 'PRODUCT') {
                this.props.fetchOrgProductDetailsTabs()
            } else if (selectedPrdType === 'CUSTOM_VENDOR' || selectedPrdType === 'MASTER_VENDOR') {
                this.props.fetchOrgCatalogDetailsTabs("vendor");
            } else if (selectedPrdType === 'CUSTOM_VENDOR_SERVICE' || selectedPrdType === 'MASTER_VENDOR_SERVICE') {
                this.props.fetchOrgCatalogDetailsTabs("vendorservice");
            }
        }
    }

    render() {
        const classes = this.props.classes;
        const { productOptions, selectedProduct, ruleDatas, formItemOptions, selectedCatalogField, selectedSource, isTableType, isContractType, isVendorFileType, tableFields } = this.state;

        let isUpdateGoingOn = false;
        if (this.props.addNewRowProgress || this.props.orgServiceTabSectionItemDataUpdateProgress || this.props.orgVendorTabSectionItemDataUpdateProgress || this.props.isUpdatingOrgProductDetailTabsSectionItemData) {
            isUpdateGoingOn = true;
        }

        console.log(tableFields,'TableFieldssssssssssss')
        console.log(this.props.vendorDocTypes,'vendorDocTypes')
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Copy Data to Catalog</Typography>
                {<div className={classes.body}>
                    <div className={classes.section}>
                        <Typography variant={"h4"} className={classes.heading}>Select Product</Typography>
                        <Select
                            isMulti={false}
                            labelId="singleSelect-select-label"
                            id="singleSelect-select"
                            value={selectedProduct}
                            disabled={productOptions.length <= 0 ? true : false}
                            onChange={(e) => {
                                this.setSelectedProduct(e)
                            }}
                            options={productOptions}
                            placeholder={"Select a Product"}
                        >
                        </Select>
                    </div>
                    <div className={classes.mappings}>
                        <div className={classes.source}>
                            <ReactSelect
                                isClearable
                                menuPosition='fixed'
                                isSearchable={true}
                                options={ruleDatas}
                                classNamePrefix="select"
                                placeholder="Select Catalog Field"
                                onChange={(e) => {
                                    this.setState({
                                        selectedCatalogField: e,
                                        selectedSource: '',
                                    }, () => {
                                        this.checkCatalogFieldType()
                                    })
                                }}
                                value={selectedCatalogField}
                            />
                        </div>
                        {!isTableType && !isContractType && !isVendorFileType && <div className={classes.userRes}>
                            <ReactSelect
                                isClearable
                                menuPosition='fixed'
                                isSearchable={true}
                                options={formItemOptions}
                                classNamePrefix="select"
                                placeholder="Select Form Field"
                                onChange={(e) => {
                                    this.setState({
                                        selectedSource: e,
                                    })
                                }}
                                value={selectedSource}
                            />
                        </div>}

                    </div>
                    {(isTableType || isContractType || isVendorFileType) && <div className={classes.mappings}>
                        {tableFields.length > 0 && <div className={classes.rules}>
                            <Typography variant={"h4"} className={classes.heading}>{isTableType ? 'Map with table columns' : 'Map with contract columns'}</Typography>
                            <table className={classes.ruleTable}>
                                <tbody>
                                    {tableFields.map((rule, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <tr>
                                                    <td>
                                                        {rule.label} {rule?.isRequired ? '*' : ''}
                                                    </td>
                                                    <td className={classnames(classes.leftOp)}>
                                                        <ReactSelect
                                                            isClearable
                                                            menuPosition='fixed'
                                                            isSearchable={true}
                                                            options={rule.sourceOptions}
                                                            classNamePrefix="select"
                                                            placeholder="Select Form Field"
                                                            onChange={(e) => {
                                                                this.updateTableValues('selectedSource', e, k)
                                                            }}
                                                            value={rule.selectedSource}
                                                        />
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>}
                </div>}

                <div className={classes.bottomAction}>
                    <SqureButton
                        cls={classes.actionBottomBtn}
                        variant={"outlined"}
                        onClick={() => {
                            this.props.hideDialog();
                        }}>
                        Cancel
                    </SqureButton>
                    <SqureButton
                        cls={classes.actionBottomBtn}
                        variant={"contained"}
                        onClick={() => {
                            this.updateDataToProduct();
                        }}>
                        {isUpdateGoingOn ? <CircularProgress style={{ width: 27, height: 27 }} /> : 'Save'}
                    </SqureButton>
                </div>
            </DialogContent>
        </Dialog>
    }
}

FormDataCopyDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(FormDataCopyDialog));