import React, { useState, useEffect } from 'react';
import { generate } from 'shortid';
import classnames from "classnames";
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogContent, DialogActions,
  Slide, DialogTitle
} from '@material-ui/core';
import SqureButton from 'Components/Common/SqureButton';
import CommonCss from 'commonCss';
// import { visibilityType } from './data'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 16,
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
});

const AddTabDialog = ({
  classes,
  isOpen,
  onSubmit,
  onClose,
  data,
  currentVisibility
}) => {
  const [name, setName] = useState("")
  const handleOnClose = () => {
    setName("")
    onClose()
  }

  useEffect(() => {
    setName(data?.TabName || "")
  }, [data])

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleOnClose()
        }
      }}
      aria-labelledby=""
      open={isOpen}
      TransitionComponent={Transition}
      scroll={"body"}
      fullWidth={true}
      className={classnames(classes.dialog)}
      id={`addTabDialog${generate()}`}
      style={{ zIndex: 10001 }}
    >
      <DialogTitle id={`form-dialog-title${generate()}`}>
       {data?.TabName ? "Update Tab" : "Create New Tab"}
      </DialogTitle>
      <DialogContent>
        <div className={classes.formGroup}>
          <label>Name</label>
          <input
            className={classes.formControl}
            value={name}
            placeholder="Enter Tab Name"
            onChange={(e) => setName(e.target?.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: '10px 24px' }}>
          <SqureButton
            variant={"outlined"}
            onClick={() => {
              handleOnClose()
            }}
          >
            Cancel
          </SqureButton>
          &nbsp; &nbsp; &nbsp;
          <SqureButton
            variant={"contained"}
            disabled={name.length === 0}
            onClick={() => {
              onSubmit({
                name,
                visibility: currentVisibility === null || currentVisibility === -1 ? 7 : currentVisibility
              })
              handleOnClose()
            }}
          >
            Save
          </SqureButton>
        </div>
      </DialogActions>
    </Dialog >
  )
}


export default React.memo(withStyles(styles)(AddTabDialog));
