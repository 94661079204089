import React from 'react';
import { generate } from 'shortid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogContent, DialogActions,
  Slide, DialogTitle
} from '@material-ui/core';
import SqureButton from 'Components/Common/SqureButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
});

const AllTeamsDialog = ({
  onClose,
  data,
}) => {
  
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby=""
      open={true}
      TransitionComponent={Transition}
      scroll={"body"}
      fullWidth={true}
      maxWidth={"sm"}
      id={`AllTeamsDialog${generate()}`}
    >
      <DialogTitle id={`form-dialog-title${generate()}`}>
       All Teams
      </DialogTitle>
      <DialogContent>
        <TableContainer style={{maxHeight: 400}} component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>S. No.</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{i+1}</TableCell>
                  <TableCell>
                    {row.Name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: '10px 24px' }}>
          <SqureButton
            variant={"outlined"}
            onClick={() => {
              onClose()
            }}
          >
            Close
          </SqureButton>
        </div>
      </DialogActions>
    </Dialog >
  )
}


export default React.memo(withStyles(styles)(AllTeamsDialog));
