import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_ADD_ORG_WORKFLOW = "START_ADD_ORG_WORKFLOW";
export const END_ADD_ORG_WORKFLOW = "END_ADD_ORG_WORKFLOW";

export const START_UPDATE_ORG_WORKFLOW = "START_UPDATE_ORG_WORKFLOW";
export const END_UPDATE_ORG_WORKFLOW = "END_UPDATE_ORG_WORKFLOW";

export const START_GET_ORG_WORKFLOW = "START_GET_ORG_WORKFLOW";
export const END_GET_ORG_WORKFLOW = "END_GET_ORG_WORKFLOW";

export const START_GET_BY_ID_ORG_WORKFLOW = "START_GET_BY_ID_ORG_WORKFLOW";
export const END_GET_BY_ID_ORG_WORKFLOW = "END_GET_BY_ID_ORG_WORKFLOW";

export const START_DELETE_ORG_WORKFLOW = "START_DELETE_ORG_WORKFLOW";
export const END_DELETE_ORG_WORKFLOW = "END_DELETE_ORG_WORKFLOW";


export const START_ADD_ORG_WORKFLOW_COMPONENT = "START_ADD_ORG_WORKFLOW_COMPONENT";
export const END_ADD_ORG_WORKFLOW_COMPONENT = "END_ADD_ORG_WORKFLOW_COMPONENT";

export const START_UPDATE_ORG_WORKFLOW_COMPONENT = "START_UPDATE_ORG_WORKFLOW_COMPONENT";
export const END_UPDATE_ORG_WORKFLOW_COMPONENT = "END_UPDATE_ORG_WORKFLOW_COMPONENT";

export const START_GET_ORG_WORKFLOW_COMPONENT = "START_GET_ORG_WORKFLOW_COMPONENT";
export const END_GET_ORG_WORKFLOW_COMPONENT = "END_GET_ORG_WORKFLOW_COMPONENT";

export const START_DELETE_ORG_WORKFLOW_COMPONENT = "START_DELETE_ORG_WORKFLOW_COMPONENT";
export const END_DELETE_ORG_WORKFLOW_COMPONENT = "END_DELETE_ORG_WORKFLOW_COMPONENT";


export const START_ADD_ORG_WORKFLOW_ACTION = "START_ADD_ORG_WORKFLOW_ACTION";
export const END_ADD_ORG_WORKFLOW_ACTION = "END_ADD_ORG_WORKFLOW_ACTION";

export const START_UPDATE_ORG_WORKFLOW_ACTION = "START_UPDATE_ORG_WORKFLOW_ACTION";
export const END_UPDATE_ORG_WORKFLOW_ACTION = "END_UPDATE_ORG_WORKFLOW_ACTION";

export const START_FETCH_ORG_WORKFLOW_ACTION = "START_FETCH_ORG_WORKFLOW_ACTION";
export const END_FETCH_ORG_WORKFLOW_ACTION = "END_FETCH_ORG_WORKFLOW_ACTION";

export const START_DELETE_ORG_WORKFLOW_ACTION = "START_DELETE_ORG_WORKFLOW_ACTION";
export const END_DELETE_ORG_WORKFLOW_ACTION = "END_DELETE_ORG_WORKFLOW_ACTION";

export const SHOW_MASTER_COMPONENT_DIALOG = "SHOW_MASTER_COMPONENT_DIALOG";
export const HIDE_MASTER_COMPONENT_DIALOG = "HIDE_MASTER_COMPONENT_DIALOG";

export const START_GET_ORG_WORKFLOW_COMPONENT_DETAILS = "START_GET_ORG_WORKFLOW_COMPONENT_DETAILS";
export const END_GET_ORG_WORKFLOW_COMPONENT_DETAILS = "END_GET_ORG_WORKFLOW_COMPONENT_DETAILS";

export const START_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID = "START_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID";
export const END_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID = "END_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID";

export const START_FETCH_WORKFLOW_CUSTOM_FORM = "START_FETCH_WORKFLOW_CUSTOM_FORM";
export const END_FETCH_WORKFLOW_CUSTOM_FORM = "END_FETCH_WORKFLOW_CUSTOM_FORM";

export const START_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS = "START_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS";
export const END_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS = "END_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS";

export const START_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS = "START_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS";
export const END_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS = "END_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS";

export const START_ADD_WORKFLOW_CUSTOM_FORM_DETAILS = "START_ADD_WORKFLOW_CUSTOM_FORM_DETAILS";
export const END_ADD_WORKFLOW_CUSTOM_FORM_DETAILS = "END_ADD_WORKFLOW_CUSTOM_FORM_DETAILS";

export const START_ADD_WORKFLOW_CUSTOM_FORM_SECTION = "START_ADD_WORKFLOW_CUSTOM_FORM_SECTION";
export const END_ADD_WORKFLOW_CUSTOM_FORM_SECTION = "END_ADD_WORKFLOW_CUSTOM_FORM_SECTION";

export const START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION = "START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION";
export const END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION = "END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION";

export const START_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS = "START_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS";
export const END_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS = "END_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS";

export const START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION = "START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION";
export const END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION = "END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION";

export const START_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "START_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";
export const END_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "END_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";

export const START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";
export const END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";

export const START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";
export const END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";

export const START_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS = "START_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS";
export const END_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS = "END_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS";

export const START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ = "START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ";
export const END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ = "END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ";

export const SHOW_WRAPPER_COMPONENT_DIALOG = "SHOW_WRAPPER_COMPONENT_DIALOG";
export const HIDE_WRAPPER_COMPONENT_DIALOG = "HIDE_WRAPPER_COMPONENT_DIALOG";

export const SHOW_WRAPPER_MASTER_COMPONENT_DIALOG = "SHOW_WRAPPER_MASTER_COMPONENT_DIALOG";
export const HIDE_WRAPPER_MASTER_COMPONENT_DIALOG = "HIDE_WRAPPER_MASTER_COMPONENT_DIALOG";

export const SHOW_USER_WISE_WORKFLOW_PROGRESS_DIALOG = "SHOW_USER_WISE_WORKFLOW_PROGRESS_DIALOG";
export const HIDE_USER_WISE_WORKFLOW_PROGRESS_DIALOG = "HIDE_USER_WISE_WORKFLOW_PROGRESS_DIALOG";

export const SHOW_ALL_USERS_WORKFLOW_PROGRESS_DIALOG = "SHOW_ALL_USERS_WORKFLOW_PROGRESS_DIALOG";
export const HIDE_ALL_USERS_WORKFLOW_PROGRESS_DIALOG = "HIDE_ALL_USERS_WORKFLOW_PROGRESS_DIALOG";

export const SHOW_WRAPPER_WORKFLOW_PROGRESS_DIALOG = "SHOW_WRAPPER_WORKFLOW_PROGRESS_DIALOG";
export const HIDE_WRAPPER_WORKFLOW_PROGRESS_DIALOG = "HIDE_WRAPPER_WORKFLOW_PROGRESS_DIALOG";

export const SHOW_WRAPPER_WORKFLOW_DETAILS_DIALOG = "SHOW_WRAPPER_WORKFLOW_DETAILS_DIALOG";
export const HIDE_WRAPPER_WORKFLOW_DETAILS_DIALOG = "HIDE_WRAPPER_WORKFLOW_DETAILS_DIALOG";

export const START_FETCH_WRAPPER_COMPONENT_DATAS = "START_FETCH_WRAPPER_COMPONENT_DATAS";
export const END_FETCH_WRAPPER_COMPONENT_DATAS = "END_FETCH_WRAPPER_COMPONENT_DATAS";

export const START_FETCH_WORKFLOW_USER_ACTIVITY = "START_FETCH_WORKFLOW_USER_ACTIVITY";
export const END_FETCH_WORKFLOW_USER_ACTIVITY = "END_FETCH_WORKFLOW_USER_ACTIVITY";

export const START_FETCH_EVALUATION_WORKFLOW_ID = "START_FETCH_EVALUATION_WORKFLOW_ID";
export const END_FETCH_EVALUATION_WORKFLOW_ID = "END_FETCH_EVALUATION_WORKFLOW_ID";

export const START_FETCH_WORKFLOW_CONDITION_RULES = "START_FETCH_WORKFLOW_CONDITION_RULES";
export const END_FETCH_WORKFLOW_CONDITION_RULES = "END_FETCH_WORKFLOW_CONDITION_RULES";

export const SHOW_WORKFLOW_DETAILS_UPDATE_DIALOG = "SHOW_WORKFLOW_DETAILS_UPDATE_DIALOG";
export const HIDE_WORKFLOW_DETAILS_UPDATE_DIALOG = "HIDE_WORKFLOW_DETAILS_UPDATE_DIALOG";

export const START_GET_MASTER_WORKFLOW = "START_GET_MASTER_WORKFLOW";
export const END_GET_MASTER_WORKFLOW = "END_GET_MASTER_WORKFLOW";

export const START_FETCH_CHILD_WORKFLOW_USER_ACTIVITY = "START_FETCH_CHILD_WORKFLOW_USER_ACTIVITY";
export const END_FETCH_CHILD_WORKFLOW_USER_ACTIVITY = "END_FETCH_CHILD_WORKFLOW_USER_ACTIVITY";

export const START_ADD_WORKFLOW_COMPONENT_TIMELINE = "START_ADD_WORKFLOW_COMPONENT_TIMELINE";
export const END_ADD_WORKFLOW_COMPONENT_TIMELINE = "END_ADD_WORKFLOW_COMPONENT_TIMELINE";

export const START_UPDATE_WORKFLOW_COMPONENT_TIMELINE = "START_UPDATE_WORKFLOW_COMPONENT_TIMELINE";
export const END_UPDATE_WORKFLOW_COMPONENT_TIMELINE = "END_UPDATE_WORKFLOW_COMPONENT_TIMELINE";

export const START_GET_WORKFLOW_COMPONENT_TIMELINE = "START_GET_WORKFLOW_COMPONENT_TIMELINE";
export const END_GET_WORKFLOW_COMPONENT_TIMELINE = "END_GET_WORKFLOW_COMPONENT_TIMELINE";

export const SHOW_WORKFLOW_COMPONENT_TIMELINE = "SHOW_WORKFLOW_COMPONENT_TIMELINE";
export const HIDE_WORKFLOW_COMPONENT_TIMELINE = "HIDE_WORKFLOW_COMPONENT_TIMELINE";

export const START_SET_COMPONENTS_FLOW_POSITION = "START_SET_COMPONENTS_FLOW_POSITION";
export const END_SET_COMPONENTS_FLOW_POSITION = "END_SET_COMPONENTS_FLOW_POSITION";

export const START_REQUEST_DETAILS = "START_REQUEST_DETAILS";
export const END_REQUEST_DETAILS = "END_REQUEST_DETAILS";

export const SET_WORKFLOW_ACTIVE_TAB = "SET_WORKFLOW_ACTIVE_TAB";

export const SHOW_CRITERIA_APPROVERS_SEARCH_DIALOG = "SHOW_CRITERIA_APPROVERS_SEARCH_DIALOG";
export const HIDE_CRITERIA_APPROVERS_SEARCH_DIALOG = "HIDE_CRITERIA_APPROVERS_SEARCH_DIALOG";

export const SHOW_REQUEST_DETAILS_CART = "SHOW_REQUEST_DETAILS_CART";
export const HIDE_REQUEST_DETAILS_CART = "HIDE_REQUEST_DETAILS_CART";

export const SHOW_REQUEST_SUMMARY_CART = "SHOW_REQUEST_SUMMARY_CART";
export const HIDE_REQUEST_SUMMARY_CART = "HIDE_REQUEST_SUMMARY_CART";

export const START_ADD_COPY_OF_ORG_WORKFLOW = "START_ADD_COPY_OF_ORG_WORKFLOW";
export const END_ADD_COPY_OF_ORG_WORKFLOW = "END_ADD_COPY_OF_ORG_WORKFLOW";

export function showRequestDetailsCart(data) {
    return {
        type: SHOW_REQUEST_DETAILS_CART,
        payload: { data }
    };
}


export function hideRequestDetailsCart() {
    return {
        type: HIDE_REQUEST_DETAILS_CART,
        payload: {}
    };
}

export function showRequestSummaryCart(data) {
    return {
        type: SHOW_REQUEST_SUMMARY_CART,
        payload: { data }
    };
}


export function hideRequestSummaryCart() {
    return {
        type: HIDE_REQUEST_SUMMARY_CART,
        payload: {}
    };
}

function startRequestDetails() {
    return {
        type: START_REQUEST_DETAILS,
        payload: {}
    };
}

function endRequestDetails(success, error) {
    return {
        type: END_REQUEST_DETAILS,
        payload: {
            success, error
        }
    };
}

export function requestDetails(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${id}/meta-details?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startRequestDetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endRequestDetails(response, error));
        } catch (e) {
            dispatch(endRequestDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddOrgWorkflow() {
    return {
        type: START_ADD_ORG_WORKFLOW,
        payload: {}
    };
}

function endAddOrgWorkflow(success, error) {
    return {
        type: END_ADD_ORG_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function addOrgWorkflow(data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endAddOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgWorkflow() {
    return {
        type: START_UPDATE_ORG_WORKFLOW,
        payload: {}
    };
}

function endUpdateOrgWorkflow(success, error) {
    return {
        type: END_UPDATE_ORG_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function updateOrgWorkflow(id, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endUpdateOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetOrgWorkflow() {
    return {
        type: START_GET_ORG_WORKFLOW,
        payload: {}
    };
}

function endGetOrgWorkflow(success, error) {
    return {
        type: END_GET_ORG_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function getOrgWorkflow(data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/all-org-workflows?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
            dispatch(endGetOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endGetOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startGetMasterWorkflow() {
    return {
        type: START_GET_MASTER_WORKFLOW,
        payload: {}
    };
}

function endGetMasterWorkflow(success, error) {
    return {
        type: END_GET_MASTER_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function getMasterWorkflow(data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/workflows?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetMasterWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endGetMasterWorkflow(response, error));
        } catch (e) {
            dispatch(endGetMasterWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetByIdOrgWorkflow() {
    return {
        type: START_GET_BY_ID_ORG_WORKFLOW,
        payload: {}
    };
}

function endGetByIdOrgWorkflow(success, error) {
    return {
        type: END_GET_BY_ID_ORG_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function getByIdOrgWorkflow(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetByIdOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endGetByIdOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endGetByIdOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startDeleteOrgWorkflow() {
    return {
        type: START_DELETE_ORG_WORKFLOW,
        payload: {}
    };
}

function endDeleteOrgWorkflow(success, error) {
    return {
        type: END_DELETE_ORG_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function deleteOrgWorkflow(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endDeleteOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddOrgWorkflowComponent() {
    return {
        type: START_ADD_ORG_WORKFLOW_COMPONENT,
        payload: {}
    };
}

function endAddOrgWorkflowComponent(success, error) {
    return {
        type: END_ADD_ORG_WORKFLOW_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function addOrgWorkflowComponent(id, componentId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgWorkflowComponent());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgWorkflowComponent(response, error));
        } catch (e) {
            dispatch(endAddOrgWorkflowComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgWorkflowComponent() {
    return {
        type: START_UPDATE_ORG_WORKFLOW_COMPONENT,
        payload: {}
    };
}

function endUpdateOrgWorkflowComponent(success, error) {
    return {
        type: END_UPDATE_ORG_WORKFLOW_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function updateOrgWorkflowComponent(id, componentId, data) {
    console.log(data, 'Update ')
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgWorkflowComponent());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgWorkflowComponent(response, error));
        } catch (e) {
            dispatch(endUpdateOrgWorkflowComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetOrgWorkflowComponent() {
    return {
        type: START_GET_ORG_WORKFLOW_COMPONENT,
        payload: {}
    };
}

function endGetOrgWorkflowComponent(success, error) {
    return {
        type: END_GET_ORG_WORKFLOW_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function getOrgWorkflowComponent(id, parent_component_id = 0) {
    console.log('Workflow Builder Function');
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/components?t=${timestamp}&parent_component_id=${parent_component_id}`;
    return async (dispatch) => {
        dispatch(startGetOrgWorkflowComponent());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endGetOrgWorkflowComponent(response, error));
        } catch (e) {
            dispatch(endGetOrgWorkflowComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgWorkflowComponent() {
    return {
        type: START_DELETE_ORG_WORKFLOW_COMPONENT,
        payload: {}
    };
}

function endDeleteOrgWorkflowComponent(success, error) {
    return {
        type: END_DELETE_ORG_WORKFLOW_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function deleteOrgWorkflowComponent(id, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgWorkflowComponent());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgWorkflowComponent(response, error));
        } catch (e) {
            dispatch(endDeleteOrgWorkflowComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddOrgWorkflowAction() {
    return {
        type: START_ADD_ORG_WORKFLOW_ACTION,
        payload: {}
    };
}

function endAddOrgWorkflowAction(success, error) {
    return {
        type: END_ADD_ORG_WORKFLOW_ACTION,
        payload: {
            success, error
        }
    };
}

export function addOrgWorkflowAction(id, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/action?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgWorkflowAction());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgWorkflowAction(response, error));
        } catch (e) {
            dispatch(endAddOrgWorkflowAction(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgWorkflowAction() {
    return {
        type: START_UPDATE_ORG_WORKFLOW_ACTION,
        payload: {}
    };
}

function endUpdateOrgWorkflowAction(success, error) {
    return {
        type: END_UPDATE_ORG_WORKFLOW_ACTION,
        payload: {
            success, error
        }
    };
}

export function updateOrgWorkflowAction(id, actionId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/action/${actionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgWorkflowAction());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgWorkflowAction(response, error));
        } catch (e) {
            dispatch(endUpdateOrgWorkflowAction(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgWorkflowAction() {
    return {
        type: START_FETCH_ORG_WORKFLOW_ACTION,
        payload: {}
    };
}

function endFetchOrgWorkflowAction(success, error) {
    return {
        type: END_FETCH_ORG_WORKFLOW_ACTION,
        payload: {
            success, error
        }
    };
}

export function fetchOrgWorkflowAction(id, parent_component_id = 0) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/actions?t=${timestamp}&parent_component_id=${parent_component_id}`;
    return async (dispatch) => {
        dispatch(startFetchOrgWorkflowAction());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgWorkflowAction(response, error));
        } catch (e) {
            dispatch(endFetchOrgWorkflowAction(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startDeleteOrgWorkflowAction() {
    return {
        type: START_DELETE_ORG_WORKFLOW_ACTION,
        payload: {}
    };
}

function endDeleteOrgWorkflowAction(success, error) {
    return {
        type: END_DELETE_ORG_WORKFLOW_ACTION,
        payload: {
            success, error
        }
    };
}

export function deleteOrgWorkflowAction(id, actionId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/action/${actionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgWorkflowAction());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgWorkflowAction(response, error));
        } catch (e) {
            dispatch(endDeleteOrgWorkflowAction(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showMasterComponentDialog() {
    return {
        type: SHOW_MASTER_COMPONENT_DIALOG,
        payload: {}
    };
}


export function hideMasterComponentDialog() {
    return {
        type: HIDE_MASTER_COMPONENT_DIALOG,
        payload: {}
    };
}


function startFetchOrgWorkflowComponentDetails() {
    return {
        type: START_GET_ORG_WORKFLOW_COMPONENT_DETAILS,
        payload: {}
    };
}

function endFetchOrgWorkflowComponentDetails(success, error) {
    return {
        type: END_GET_ORG_WORKFLOW_COMPONENT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchOrgWorkflowComponentDetails(id, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOrgWorkflowComponentDetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgWorkflowComponentDetails(response, error));
        } catch (e) {
            dispatch(endFetchOrgWorkflowComponentDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}


// Workflow custom form actions start
function startAddWorkFlowCustomFormData() {
    return {
        type: START_ADD_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {}
    };
}

function endAddWorkFlowCustomFormData(success, error) {
    return {
        type: END_ADD_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {
            success, error
        }
    };
}

export function addWorkFlowCustomFormData(id, componentId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddWorkFlowCustomFormData());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddWorkFlowCustomFormData(response, error));
        } catch (e) {
            dispatch(endAddWorkFlowCustomFormData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateWorkFlowCustomFormData() {
    return {
        type: START_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {}
    };
}

function endUpdateWorkFlowCustomFormData(success, error) {
    return {
        type: END_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {
            success, error
        }
    };
}

export function updateWorkFlowCustomFormData(id, componentId, formId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateWorkFlowCustomFormData());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateWorkFlowCustomFormData(response, error));
        } catch (e) {
            dispatch(endUpdateWorkFlowCustomFormData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteWorkFlowCustomFormData() {
    return {
        type: START_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {}
    };
}

function endDeleteWorkFlowCustomFormData(success, error) {
    return {
        type: END_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {
            success, error
        }
    };
}

export function deleteWorkFlowCustomFormData(id, componentId, formId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteWorkFlowCustomFormData());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteWorkFlowCustomFormData(response, error));
        } catch (e) {
            dispatch(endDeleteWorkFlowCustomFormData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkFlowAllCustomForms() {
    return {
        type: START_FETCH_WORKFLOW_CUSTOM_FORM,
        payload: {}
    };
}

function endFetchWorkFlowAllCustomForms(success, error) {
    return {
        type: END_FETCH_WORKFLOW_CUSTOM_FORM,
        payload: {
            success, error
        }
    };
}

export function fetchWorkFlowCustomForm(id, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkFlowAllCustomForms());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkFlowAllCustomForms(response, error));
        } catch (e) {
            dispatch(endFetchWorkFlowAllCustomForms(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchWorkFlowCustomFormUsingId() {
    return {
        type: START_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID,
        payload: {}
    };
}

function endFetchWorkFlowCustomFormUsingId(success, error) {
    return {
        type: END_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID,
        payload: {
            success, error
        }
    };
}

export function fetchWorkFlowCustomFormUsingId(id, componentId, formId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkFlowCustomFormUsingId());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkFlowCustomFormUsingId(response, error));
        } catch (e) {
            dispatch(endFetchWorkFlowCustomFormUsingId(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddWorkFlowCustomFormSection() {
    return {
        type: START_ADD_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {}
    };
}

function endAddWorkFlowCustomFormSection(success, error) {
    return {
        type: END_ADD_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {
            success, error
        }
    };
}

export function addWorkFlowCustomFormSection(id, componentId, formId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddWorkFlowCustomFormSection());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddWorkFlowCustomFormSection(response, error));
        } catch (e) {
            dispatch(endAddWorkFlowCustomFormSection(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateWorkFlowCustomFormSection() {
    return {
        type: START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {}
    };
}

function endUpdateWorkFlowCustomFormSection(success, error) {
    return {
        type: END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {
            success, error
        }
    };
}

export function updateWorkFlowCustomFormSection(id, componentId, formId, sectionId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateWorkFlowCustomFormSection());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateWorkFlowCustomFormSection(response, error));
        } catch (e) {
            dispatch(endUpdateWorkFlowCustomFormSection(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkFlowCustomFormSections() {
    return {
        type: START_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS,
        payload: {}
    };
}

function endFetchWorkFlowCustomFormSections(success, error) {
    return {
        type: END_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS,
        payload: {
            success, error
        }
    };
}

export function fetchWorkFlowCustomFormSections(id, componentId, formId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/sections?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkFlowCustomFormSections());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkFlowCustomFormSections(response, error));
        } catch (e) {
            dispatch(endFetchWorkFlowCustomFormSections(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteWorkFlowCustomFormSection() {
    return {
        type: START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {}
    };
}

function endDeleteWorkFlowCustomFormSection(success, error) {
    return {
        type: END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {
            success, error
        }
    };
}

export function deleteWorkFlowCustomFormSection(id, componentId, formId, sectionId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteWorkFlowCustomFormSection());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteWorkFlowCustomFormSection(response, error));
        } catch (e) {
            dispatch(endDeleteWorkFlowCustomFormSection(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddWorkFlowCustomFormSectionItem() {
    return {
        type: START_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {}
    };
}

function endAddWorkFlowCustomFormSectionItem(success, error) {
    return {
        type: END_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {
            success, error
        }
    };
}

export function addWorkFlowCustomFormSectionItem(id, componentId, formId, sectionId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/item?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddWorkFlowCustomFormSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
            dispatch(endAddWorkFlowCustomFormSectionItem(response, error));
        } catch (e) {
            dispatch(endAddWorkFlowCustomFormSectionItem(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateWorkFlowCustomFormSectionItem() {
    return {
        type: START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {}
    };
}

function endUpdateWorkFlowCustomFormSectionItem(success, error) {
    return {
        type: END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {
            success, error
        }
    };
}

export function updateWorkFlowCustomFormSectionItem(id, componentId, formId, sectionId, itemId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateWorkFlowCustomFormSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateWorkFlowCustomFormSectionItem(response, error));
        } catch (e) {
            dispatch(endUpdateWorkFlowCustomFormSectionItem(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchWorkFlowCustomFormSectionItems() {
    return {
        type: START_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS,
        payload: {}
    };
}

function endFetchWorkFlowCustomFormSectionItems(success, error) {
    return {
        type: END_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS,
        payload: {
            success, error
        }
    };
}

export function fetchWorkFlowCustomFormSectionItems(id, componentId, formId, sectionId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/items?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkFlowCustomFormSectionItems());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkFlowCustomFormSectionItems(response, error));
        } catch (e) {
            dispatch(endFetchWorkFlowCustomFormSectionItems(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteWorkFlowCustomFormSectionItem() {
    return {
        type: START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {}
    };
}

function endDeleteWorkFlowCustomFormSectionItem(success, error) {
    return {
        type: END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {
            success, error
        }
    };
}

export function deleteWorkFlowCustomFormSectionItem(id, componentId, formId, sectionId, itemId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteWorkFlowCustomFormSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteWorkFlowCustomFormSectionItem(response, error));
        } catch (e) {
            dispatch(endDeleteWorkFlowCustomFormSectionItem(null, {
                message: e.message
            }));
            return;
        }
    };
}

// function startUpdateWorkFlowCustomFormSectionSequence(data) {
//     return {
//         type: START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ,
//         payload: {data}
//     };
// }

// function endUpdateWorkFlowCustomFormSectionSequence(success, error) {
//     return {
//         type: END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ,
//         payload: {
//             success, error
//         }
//     };
// }

export function updateWorkFlowCustomFormSectionSequence(id, componentId, formId, sectionId, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/seq?t=${timestamp}`;
    // return async (dispatch) => {
    //     dispatch(startUpdateWorkFlowCustomFormSectionSequence({id, componentId, formId, sectionId,data}));
    //     try {
    //         let [response, error] = await HttpFactory.instance().putMethod(url, data);
    //         dispatch(endUpdateWorkFlowCustomFormSectionSequence(response, error));
    //     } catch (e) {
    //         dispatch(endUpdateWorkFlowCustomFormSectionSequence(null, {
    //             message: e.message
    //         }));
    //         return;
    //     }
    // };
    return async (dispatch) => {
        try {
            await HttpFactory.instance().putMethod(url, data);
            dispatch(fetchWorkFlowCustomFormSections(id, componentId, formId));
        } catch (e) {
            return;
        }
    };
}

export function showWrapperComponentDialog() {
    return {
        type: SHOW_WRAPPER_COMPONENT_DIALOG,
        payload: {}
    };
}


export function hideWrapperComponentDialog() {
    return {
        type: HIDE_WRAPPER_COMPONENT_DIALOG,
        payload: {}
    };
}

export function showWrapperMasterComponentDialog() {
    return {
        type: SHOW_WRAPPER_MASTER_COMPONENT_DIALOG,
        payload: {}
    };
}


export function hideWrapperMasterComponentDialog() {
    return {
        type: HIDE_WRAPPER_MASTER_COMPONENT_DIALOG,
        payload: {}
    };
}
// Workflow custom form actions end


// Workflow Project Dialogs
export function showUserWiseWorkflowProgressDialog() {
    return {
        type: SHOW_USER_WISE_WORKFLOW_PROGRESS_DIALOG,
        payload: {}
    };
}


export function hideUserWiseWorkflowProgressDialog() {
    return {
        type: HIDE_USER_WISE_WORKFLOW_PROGRESS_DIALOG,
        payload: {}
    };
}

export function showAllUsersWorkflowProgressDialog() {
    return {
        type: SHOW_ALL_USERS_WORKFLOW_PROGRESS_DIALOG,
        payload: {}
    };
}


export function hideAllUsersWorkflowProgressDialog() {
    return {
        type: HIDE_ALL_USERS_WORKFLOW_PROGRESS_DIALOG,
        payload: {}
    };
}

export function showWrapperWorkflowProgressDialog(component_id = 0) {
    return {
        type: SHOW_WRAPPER_WORKFLOW_PROGRESS_DIALOG,
        payload: { component_id }
    };
}


export function hideWrapperWorkflowProgressDialog() {
    return {
        type: HIDE_WRAPPER_WORKFLOW_PROGRESS_DIALOG,
        payload: {}
    };
}

export function showWrapperWorkflowDetailsDialog(componentId) {
    return {
        type: SHOW_WRAPPER_WORKFLOW_DETAILS_DIALOG,
        payload: { componentId }
    };
}


export function hideWrapperWorkflowDetailsDialog() {
    return {
        type: HIDE_WRAPPER_WORKFLOW_DETAILS_DIALOG,
        payload: {}
    };
}


function startFetchWrapperComponentDatas() {
    return {
        type: START_FETCH_WRAPPER_COMPONENT_DATAS,
        payload: {}
    };
}

function endFetchWrapperComponentDatas(success, error) {
    return {
        type: END_FETCH_WRAPPER_COMPONENT_DATAS,
        payload: {
            success, error
        }
    };
}

export function fetchWrapperComponentDatas(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/wrapper/component/${id}/components?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWrapperComponentDatas());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWrapperComponentDatas(response, error));
        } catch (e) {
            dispatch(endFetchWrapperComponentDatas(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startWorkflowUserActivity() {
    return {
        type: START_FETCH_WORKFLOW_USER_ACTIVITY,
        payload: {}
    };
}

function endWorkflowUserActivity(success, error) {
    return {
        type: END_FETCH_WORKFLOW_USER_ACTIVITY,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowUserActivity(id, workflow_id, component_id = 0) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${id}/org-workflow/${workflow_id}/user_activity/${component_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startWorkflowUserActivity());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endWorkflowUserActivity(response, error));
        } catch (e) {
            dispatch(endWorkflowUserActivity(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchEvaluationWorkflowId() {
    return {
        type: START_FETCH_EVALUATION_WORKFLOW_ID,
        payload: {}
    };
}

function endFetchEvaluationWorkflowId(success, error) {
    return {
        type: END_FETCH_EVALUATION_WORKFLOW_ID,
        payload: {
            success, error
        }
    };
}

export function fetchEvaluationworkflowId(workflow_id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${workflow_id}/parent?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchEvaluationWorkflowId());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchEvaluationWorkflowId(response, error));
        } catch (e) {
            dispatch(endFetchEvaluationWorkflowId(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkflowConditionRules() {
    return {
        type: START_FETCH_WORKFLOW_CONDITION_RULES,
        payload: {}
    };
}

function endFetchWorkflowConditionRules(success, error) {
    return {
        type: END_FETCH_WORKFLOW_CONDITION_RULES,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowConditionRules(workflow_id, component_id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${workflow_id}/rules-conditions/${component_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowConditionRules());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowConditionRules(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowConditionRules(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function showWorkflowDetailUpdateDialog() {
    return {
        type: SHOW_WORKFLOW_DETAILS_UPDATE_DIALOG,
        payload: {}
    };
}


export function hideWorkflowDetailUpdateDialog() {
    return {
        type: HIDE_WORKFLOW_DETAILS_UPDATE_DIALOG,
        payload: {}
    };
}



function startChildWorkflowUserActivity() {
    return {
        type: START_FETCH_CHILD_WORKFLOW_USER_ACTIVITY,
        payload: {}
    };
}

function endChildWorkflowUserActivity(success, error) {
    return {
        type: END_FETCH_CHILD_WORKFLOW_USER_ACTIVITY,
        payload: {
            success, error
        }
    };
}

export function fetchChildWorkflowUserActivity(id, workflow_id, component_id = 0) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${id}/org-workflow/${workflow_id}/user_activity/${component_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startChildWorkflowUserActivity());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endChildWorkflowUserActivity(response, error));
        } catch (e) {
            dispatch(endChildWorkflowUserActivity(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startAddWorkflowComponentTimeline() {
    return {
        type: START_ADD_WORKFLOW_COMPONENT_TIMELINE,
        payload: {}
    };
}

function endAddWorkflowComponentTimeline(success, error) {
    return {
        type: END_ADD_WORKFLOW_COMPONENT_TIMELINE,
        payload: {
            success, error
        }
    };
}

export function addWorkflowComponentTimeline(evaluation_id, component_id, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${evaluation_id}/component/${component_id}/timeline?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddWorkflowComponentTimeline());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddWorkflowComponentTimeline(response, error));
        } catch (e) {
            dispatch(endAddWorkflowComponentTimeline(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateWorkflowComponentTimeline() {
    return {
        type: START_UPDATE_WORKFLOW_COMPONENT_TIMELINE,
        payload: {}
    };
}

function endUpdateWorkflowComponentTimeline(success, error) {
    return {
        type: END_UPDATE_WORKFLOW_COMPONENT_TIMELINE,
        payload: {
            success, error
        }
    };
}

export function updateWorkflowComponentTimeline(evaluation_id, component_id, timeline_id, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${evaluation_id}/component/${component_id}/timeline/${timeline_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateWorkflowComponentTimeline());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateWorkflowComponentTimeline(response, error));
        } catch (e) {
            dispatch(endUpdateWorkflowComponentTimeline(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkflowComponentTimeline() {
    return {
        type: START_GET_WORKFLOW_COMPONENT_TIMELINE,
        payload: {}
    };
}

function endFetchWorkflowComponentTimeline(success, error) {
    return {
        type: END_GET_WORKFLOW_COMPONENT_TIMELINE,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowComponentTimeline(evaluation_id, component_id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${evaluation_id}/component/${component_id}/timeline?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowComponentTimeline());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowComponentTimeline(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowComponentTimeline(null, {
                message: e.message
            }));
            return;
        }
    };
}

// Project Progress Dialogs
export function showWorkflowComponentTimelineDialog() {
    return {
        type: SHOW_WORKFLOW_COMPONENT_TIMELINE,
        payload: {}
    };
}


export function hideWorkflowComponentTimelineDialog() {
    return {
        type: HIDE_WORKFLOW_COMPONENT_TIMELINE,
        payload: {}
    };
}

function startSetComponentsFlowPosition() {
    return {
        type: START_SET_COMPONENTS_FLOW_POSITION,
        payload: {}
    };
}

function endSetComponentsFlowPosition(success, error) {
    return {
        type: END_SET_COMPONENTS_FLOW_POSITION,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the setComponentsFlowPosition
export function setComponentsFlowPosition(workflowId, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${workflowId}/components-flow-data?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSetComponentsFlowPosition());
        try {
            let [response, error] = await await HttpFactory.instance().putMethod(url, { data });
            dispatch(endSetComponentsFlowPosition(response, error));
        } catch (e) {
            dispatch(endSetComponentsFlowPosition(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function setWorkflowActiveTab(id) {
    return {
        type: SET_WORKFLOW_ACTIVE_TAB,
        payload: {
            id
        }
    };
}

// Project Progress Dialogs
export function showApproverSearchDialog() {
    return {
        type: SHOW_CRITERIA_APPROVERS_SEARCH_DIALOG,
        payload: {}
    };
}


export function hideApproverSearchDialog() {
    return {
        type: HIDE_CRITERIA_APPROVERS_SEARCH_DIALOG,
        payload: {}
    };
}


function startAddCopyOfOrgWorkflow() {
    return {
        type: START_ADD_COPY_OF_ORG_WORKFLOW,
        payload: {}
    };
}

function endAddCopyOfOrgWorkflow(success, error) {
    return {
        type: END_ADD_COPY_OF_ORG_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function addCopyOfOrgWorkflow(data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/copy-workflow?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddCopyOfOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddCopyOfOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endAddCopyOfOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}