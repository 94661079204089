import React from "react";

export const ProductOption = (props) => {
  const product = props;
  return <div >
    <span style={{ 'color': '#000', fontSize: '16px' }}>{product.label}</span>&nbsp;
    {product.isProduct !== undefined && product.isProduct && <span style={{ 'color': '#6C6C6C', fontSize: '12px' }}>By {product.company_name}</span>}
  </div>
}

export const ProductOptionLabel = (props) => {
  const product = props;
  // console.log(product)
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', cursor: props.isDisabled ? 'not-allowed' : 'pointer' }}>
      <div >
        <span style={{ 'color': '#000', fontSize: '16px' }}>{product.label}</span>&nbsp;
        {product?.CompanyName && <span style={{ 'color': '#6C6C6C', fontSize: '12px' }}>By {product.CompanyName}</span>}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {product.IsExisting && (
          <div
            style={{
              color: '#2EB77D',
              padding: '3px 10px',
              fontSize: 11,
              background: '#E6FFF3',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Existing
          </div>
        )}
      </div>
    </div>
  )

}


export const IsExistingProduct = (props) => {
  const product = props;

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', cursor: props.isDisabled ? 'not-allowed' : 'pointer' }}>
      <div >
        <span style={{ 'color': '#000', fontSize: '16px' }}>{product.label}</span>&nbsp;
        {product.isProduct !== undefined && product.isProduct && <span style={{ 'color': '#6C6C6C', fontSize: '12px' }}>By {product.company_name}</span>}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {props.productStates?.evaluated && (
          <div
            style={{
              color: '#1976d2',
              padding: '3px 10px',
              fontSize: 11,
              background: '#dfecfa',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Evaluated
          </div>
        )}
        {props.productStates?.blocked && (
          <div
            style={{
              color: '#fb766b',
              padding: '3px 10px',
              fontSize: 11,
              background: '#f9dedc',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Blocked
          </div>
        )}
        {props.productStates?.in_review && (
          <div
            style={{
              color: '#fff',
              padding: '3px 10px',
              fontSize: 11,
              background: '#bdbdbd',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            In Review
          </div>
        )}
        {props.productStates?.limited && (
          <div
            style={{
              color: '#fff',
              padding: '3px 10px',
              fontSize: 11,
              background: '#2e81da',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Limited Use
          </div>
        )}
        {props.productStates?.featured && (
          <div
            style={{
              color: '#D1B000',
              padding: '3px 10px',
              fontSize: 11,
              background: '#fdf4c6',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Featured
          </div>
        )}
          {props.productStates?.unverified && (
          <div
            style={{
              color: '#fff',
              padding: '3px 10px',
              fontSize: 11,
              background: '#1f73b79e',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Unverified
          </div>
        )}
        {(props.isExisting || props.productStates?.existing) && (
          <div
            style={{
              color: '#2EB77D',
              padding: '3px 10px',
              fontSize: 11,
              background: '#E6FFF3',
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Existing
          </div>
        )}
      </div>
    </div>
  )

}

export const ProductSearchFunction = (option, rawInput) => {
  return option.data.__isNew__ || option;
}