import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons"
// import CommonCss from "commonCss";
// components
import UpcomingReviews from './UpcomingReviews'
import CompletedReviews from './CompletedReviews'
import AppBarV2 from "../../../AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
// assets
import CommonCss from "../../../../../commonCss";

// Redux
import { fetchCompletedEvaluation, fetchInProgressEvaluation } from "redux/evaluate/action";
import { fetchVendors } from "redux/metric/action";
import { fetchOrgProducts } from "redux/product/orgProduct/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "redux/evaluation/cart/action";
import { showSnackBar } from "redux/snackbar/action";
import ReviewsEmptyState from "assets/images/reviews_empty_state.svg";

// components
import Image from 'Components/Common/image';


const connectedProps = (state) => ({
  vendors: state.metric.vendors,
  vendorsTotal: state.metric.vendorsTotal,
  evaluationCart: state.evaluationCart.evaluation,
  productFecthProgress: state.orgProduct.orgProductFetchProg,
  products: state.orgProduct.orgProducts,
  fetchProductReviewsProgress: state.orgProduct.fetchProductReviewsProgress,
  productReviews: state.orgProduct.productReviews,
  totalOrgProducts: state.orgProduct.totalOrgProducts,
  fetchUpcomingReviewProductsUnderProgress: state.orgProduct.fetchUpcomingReviewProductsUnderProgress,
  upcomingReviewProducts: state.orgProduct.upcomingReviewProducts,
  fetchCompletedReviewProductsUnderProgress: state.orgProduct.fetchCompletedReviewProductsUnderProgress,
  completedReviewProducts: state.orgProduct.completedReviewProducts,
});

const connectionActions = {
  fetchCompletedEvaluation: fetchCompletedEvaluation,
  fetchInProgressEvaluation: fetchInProgressEvaluation,
  fetchVendors: fetchVendors,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  showSnackBar: showSnackBar,
  fetchOrgProducts: fetchOrgProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    bottomContainer: {
      marginTop: theme.spacing(8),
    },
    viewAll: CommonCss.viewAll,
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
    topHead: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 40,
      marginBottom: 8,
      "& > .appBar": {
        flex: 1
      }
    },
    DashboardNoDataLayout: {
      margin: '22px 0px 0px 0px',
      minHeight: 450,
      textAlign: 'center',
      // background: '#ffffff',
      padding: '23px',
      borderRadius: '6px',
      '& button': {
        margin: '20px 0',
        color: '#fff',
        background: '#3C3C3C',
        '&:hover': {
          color: '#FFF',
          background: '#3C3C3C',
        }
      }
    },
    loader: {
      position: 'fixed',
      right: '45%',
      top: '40%'
    },
    DashboardNoDataLayout: {
      width: 'calc(100% - 10px)',
      margin: '22px 0px 0px 0px',
      minHeight: 450,
      textAlign: 'center',
      background: '#ffffff',
      padding: '23px',
      borderRadius: '6px',
      '& button': {
        margin: '20px 0'
      }
    },
    ...CommonCss.Home,
    rootContainer: CommonCss.rootContainer
  });

class MyProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 6,
    };
    this.fetchData = this.fetchData.bind(this);
    this.addToCart = this.addToCart.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.fetchOrgProducts(this.state.page, this.state.pageSize, this.state.query);
  }

  addToCart(item) {
    item.ID = item.ID || parseInt(item.application_id)
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.ID);
      this.props.showSnackBar("Product added to evaluation", "success", 3000);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(item.ID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
      } else {
        this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
        this.props.showSnackBar("Product added to evaluation", "success", 3000);
      }
    }
  }

  reviewProduct = (e, id) => {
    e.stopPropagation();
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  }

  producDetail = (e, product) => {
    this.props.history.push(`/app/products/org-product-details/${product.ID}`)
  }

  render() {
    const classes = this.props.classes;
    if (this.props.fetchUpcomingReviewProductsUnderProgress === false &&
      this.props.fetchCompletedReviewProductsUnderProgress === false &&
      this.props.completedReviewProducts?.Data === null && this.props.upcomingReviewProducts?.Data === null) {
      return <div className={classes.DashboardNoDataLayout}>
        <Image src={ReviewsEmptyState} />
        <h2>No Reviews found</h2>
        <p>Get started on your first review by tapping New Review below</p>
        <Button variant="outlined" color="secondary" className={classes.button} startIcon={<AddIcon />}
          onClick={() => { this.props.history.push('/app/products/org-products') }}
        >New Review</Button>
      </div>
    }
    var addedToCart =
      this.props.evaluationCart == null
        ? []
        : this.props.evaluationCart.Products.map((p) => p.ID);
    return (
      <div>
        <div className={classes.topHead} style={{ marginTop: 'unset' }}>
          <AppBarV2 title="Review" withBack={false} dropdown={true} />
          {/* <AddProductButton /> */}
        </div>

        <Grid container className={classes.rootContainer}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={9} id="topContainer" className={classes.topContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.newEvaluation}>
                <LayeredCard
                  title="+ New Review"
                  color="CYAN"
                  numLayer={1}
                  // subTitle="0 Evaluations"
                  image={AddEvaluationImage}
                  subTitle={'Tap to start a new review'}
                  onClick={() => {
                    this.props.history.push("/app/products/org-products")
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.bottomContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <CompletedReviews />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <UpcomingReviews />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(MyProducts));
