import {
  Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";

import RatingPoper from "./RatingPoper";

import { updateFromOrgEvaluationTemplate } from "redux/templates/evaluation/org/action";
import { hideScoringScaleDialog } from "redux/evaluation/criteria/action";
import { updateEvaluationScoringScale } from "redux/evaluation/metadata/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.evaludationCriteria.isShowScoringScaleDialog,
  showScoringfor: state.evaludationCriteria.showScoringfor,
});

const connectionActions = {
  hideDialog: hideScoringScaleDialog,
  updateFromOrgEvaluationTemplate:updateFromOrgEvaluationTemplate,
  updateEvaluationScoringScale:updateEvaluationScoringScale
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    margin: 'auto',
    marginBottom: 20
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
  },
  body: {
    marginTop: 20
  }
});

class ScoreScaleDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
    }
  }

  componentDidUpdate(prevProps) {

  }

  updateScoringScale(newScore) {
    const { templateDetails,showScoringfor } = this.props;
    if(showScoringfor === 'template'){
      this.props.updateFromOrgEvaluationTemplate(templateDetails.ID, {
        maxScore: newScore
      })
    }

    if(showScoringfor === 'evaluation'){
      this.props.updateEvaluationScoringScale(this.props.evaluationId, {
        maxScore: newScore
      })
    }
  }

  render() {
    const classes = this.props.classes;
    const { templateDetails, minValue, maxValue } = this.props;
    var currentScore = templateDetails?.MaxScore !== null && templateDetails?.MaxScore !== 0 ? templateDetails?.MaxScore : 5
    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>Scoring Scale</Typography>
        <div className={classes.body}>
          <RatingPoper
            value={currentScore}
            minValue={minValue}
            maxValue={maxValue}
            anchorEl={true}
            onClose={() => {

            }}
            onSave={(value) => {
              this.updateScoringScale(value);
              console.log(value, 'SelectedValue')
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  }
}

ScoreScaleDialog.defaultProps = {
  isWorkFlow: false,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ScoreScaleDialog));