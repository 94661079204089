import * as Validator from "util/Validation";
import {
  ACTIVE_CONTRACT_DETAILS,
  END_ADD_CONTRACT_DETAILS,
  END_ADD_NEW_ORG_CATEGORIES,
  END_ADD_ORG_CATEGORIES,
  END_ADD_ORG_CONTACT_DETAILS,
  END_ADD_ORG_CONTACT_DETAILS_LINK,
  END_ADD_ORG_DOCUMENT,
  END_ADD_ORG_FEATURES,
  END_ADD_ORG_PII,
  END_ADD_ORG_PLATFORM,
  END_ADD_ORG_PRODUCT,
  END_ADD_ORG_PRODUCT_ALTERNATIVES,
  END_ADD_ORG_PRODUCT_CATEGORIES,
  END_ADD_ORG_PRODUCT_CHAMPIONS,
  END_ADD_ORG_PRODUCT_DETAILS_TAB,
  END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION,
  END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
  END_ADD_ORG_RELATED_PRODUCTS,
  END_ADD_ORG_SECURITY_CERT,
  END_ADD_ORG_SECURITY_CERT_DOC,
  END_ADD_ORG_SUBCATEGORIES,
  END_ADD_PRODUCT_REVIEW,
  END_DELETE_ORG_CATEGORIES,
  END_DELETE_ORG_CONTACT_DETAILS,
  END_DELETE_ORG_CONTACT_DETAIL_LINK,
  END_DELETE_ORG_DOCUMENT,
  END_DELETE_ORG_FEATURES,
  END_DELETE_ORG_PII,
  END_DELETE_ORG_PLATFORM,
  END_DELETE_ORG_PRODUCT_DETAILS_TAB,
  END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION,
  END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
  END_DELETE_ORG_RELATED_PRODUCTS,
  END_DELETE_ORG_SECURITY_CERT,
  END_DELETE_ORG_SUBCATEGORIES,
  END_DELETE_PRODUCT,
  END_DELETE_PRODUCT_REVIEW,
  END_FETCH_ALTERNATIVE_PRODUCTS,
  END_FETCH_COMPANY_PRODUCTS,
  END_FETCH_COMPLEMENTARY_PRODUCTS,
  END_FETCH_COMPLETED_REVIEWS_PRODUCTS,
  END_FETCH_EXISTING_SIMILAR_PRODUCTS,
  END_FETCH_FEATURED_PRODUCT,
  END_FETCH_ORG_CONTACT_DETAILS,
  END_FETCH_ORG_DOCUMENTS,
  END_FETCH_ORG_FEATURES,
  END_FETCH_ORG_FINANCE_DETAILS,
  END_FETCH_ORG_PII,
  END_FETCH_ORG_PLATFORM,
  END_FETCH_ORG_PRODUCT,
  END_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING,
  RESET_SIMILAR_EXISTING_PRODUCTS,
  END_FETCH_ORG_PRODUCT_DEPARTMENT,
  END_FETCH_ORG_PRODUCT_DETAILS,
  END_FETCH_CONTRACT_FOLDERS,
  END_UPDATE_CONTRACT_FOLDERS,
  END_FETCH_ORG_PRODUCT_DETAILS_TABS,
  END_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA,
  END_FETCH_ORG_RELATED_PRODUCTS,
  END_FETCH_ORG_RISK_POSTURE,
  END_FETCH_ORG_SECURITY_CERT,
  END_FETCH_ORG_SECURITY_CERT_DOC,
  END_FETCH_ORG_SUBCATEGORIES,
  END_FETCH_ORG_VENDOR_POC_DETAILS,
  END_FETCH_PARSE_CONTRACT_DETAILS,
  END_FETCH_PRODUCT_PRICE,
  END_FETCH_SIMILAR_EXISTING_PRODUCT,
  END_FETCH_UPCOMING_REVIEWS_PRODUCTS,
  END_ORG_ADD_VENDOR_POC,
  END_ORG_DELETE_VENDOR_POC,
  END_ORG_PRODUCTS_COUNT,
  END_ORG_PRODUCT_COMPLETED_REVIEWS,
  END_ORG_PRODUCT_GROUP_ADD,
  END_ORG_PRODUCT_GROUP_DELETE,
  END_ORG_PRODUCT_INPROGRESS_REVIEWS,
  END_PRODUCT_REVIEWS,
  END_SEARCH_ORG_PRODUCTS,
  END_UPDATE_CONTRACT_DETAILS,
  END_UPDATE_ORG_FINANCE_DETAILS,
  END_UPDATE_ORG_PRODUCT_DETAILS,
  END_UPDATE_ORG_PRODUCT_DETAILS_TAB,
  END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION,
  END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
  END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA,
  END_UPDATE_ORG_SECURITY_CERT,
  HIDE_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG,
  HIDE_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG,
  RESET_ORG_PRODUCT_DATA,
  SHOW_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG,
  SHOW_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG,
  START_ADD_CONTRACT_DETAILS,
  START_ADD_NEW_ORG_CATEGORIES,
  START_ADD_ORG_CATEGORIES,
  START_ADD_ORG_CONTACT_DETAILS,
  START_ADD_ORG_CONTACT_DETAILS_LINK,
  START_ADD_ORG_DOCUMENT,
  START_ADD_ORG_FEATURES,
  START_ADD_ORG_PII,
  START_ADD_ORG_PLATFORM,
  START_ADD_ORG_PRODUCT,
  START_ADD_ORG_PRODUCT_ALTERNATIVES,
  START_ADD_ORG_PRODUCT_CATEGORIES,
  START_ADD_ORG_PRODUCT_CHAMPIONS,
  START_ADD_ORG_PRODUCT_DETAILS_TAB,
  START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION,
  START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
  START_ADD_ORG_RELATED_PRODUCTS,
  START_ADD_ORG_SECURITY_CERT,
  START_ADD_ORG_SECURITY_CERT_DOC,
  START_ADD_ORG_SUBCATEGORIES,
  START_ADD_PRODUCT_REVIEW,
  START_DELETE_ORG_CATEGORIES,
  START_DELETE_ORG_CONTACT_DETAILS,
  START_DELETE_ORG_CONTACT_DETAIL_LINK,
  START_DELETE_ORG_DOCUMENT,
  START_DELETE_ORG_FEATURES,
  START_DELETE_ORG_PII,
  START_DELETE_ORG_PLATFORM,
  START_DELETE_ORG_PRODUCT_DETAILS_TAB,
  START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION,
  START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
  START_DELETE_ORG_RELATED_PRODUCTS,
  START_DELETE_ORG_SECURITY_CERT,
  START_DELETE_ORG_SUBCATEGORIES,
  START_DELETE_PRODUCT,
  START_DELETE_PRODUCT_REVIEW,
  START_FETCH_ALTERNATIVE_PRODUCTS,
  START_FETCH_COMPANY_PRODUCTS,
  START_FETCH_COMPLEMENTARY_PRODUCTS,
  START_FETCH_COMPLETED_REVIEWS_PRODUCTS,
  START_FETCH_EXISTING_SIMILAR_PRODUCTS,
  START_FETCH_FEATURED_PRODUCT,
  START_FETCH_ORG_CONTACT_DETAILS,
  START_FETCH_ORG_DOCUMENTS,
  START_FETCH_ORG_FEATURES,
  START_FETCH_ORG_FINANCE_DETAILS,
  START_FETCH_ORG_PII,
  START_FETCH_ORG_PLATFORM,
  START_FETCH_ORG_PRODUCT,
  START_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING,
  START_FETCH_ORG_PRODUCT_DEPARTMENT,
  START_FETCH_ORG_PRODUCT_DETAILS,
  START_FETCH_ORG_PRODUCT_DETAILS_TABS,
  START_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA,
  START_FETCH_ORG_RELATED_PRODUCTS,
  START_FETCH_ORG_RISK_POSTURE,
  START_FETCH_CONTRACT_FOLDERS,
  START_UPDATE_CONTRACT_FOLDERS,
  START_FETCH_ORG_SECURITY_CERT,
  START_FETCH_ORG_SECURITY_CERT_DOC,
  START_FETCH_ORG_SUBCATEGORIES,
  START_FETCH_ORG_VENDOR_POC_DETAILS,
  START_FETCH_PARSE_CONTRACT_DETAILS,
  START_FETCH_PRODUCT_PRICE,
  START_FETCH_SIMILAR_EXISTING_PRODUCT,
  START_FETCH_UPCOMING_REVIEWS_PRODUCTS,
  START_ORG_ADD_VENDOR_POC,
  START_ORG_DELETE_VENDOR_POC,
  START_ORG_PRODUCTS_COUNT,
  START_ORG_PRODUCT_COMPLETED_REVIEWS,
  START_ORG_PRODUCT_GROUP_ADD,
  START_ORG_PRODUCT_GROUP_DELETE,
  START_ORG_PRODUCT_INPROGRESS_REVIEWS,
  START_PRODUCT_REVIEWS,
  START_SEARCH_ORG_PRODUCTS,
  START_UPDATE_CONTRACT_DETAILS,
  START_UPDATE_ORG_FINANCE_DETAILS,
  START_UPDATE_ORG_PRODUCT_DETAILS,
  START_UPDATE_ORG_PRODUCT_DETAILS_TAB,
  START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION,
  START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM,
  START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA,
  START_UPDATE_ORG_SECURITY_CERT,
  SHOW_ADD_PRODUCT_TEAMS_DIALOG,
  HIDE_ADD_PRODUCT_TEAMS_DIALOG,
  START_FETCH_PRODUCT_TAB_TEAMS,
  END_FETCH_PRODUCT_TAB_TEAMS,
  START_FETCH_PRODUCT_SECTION_TEAMS,
  END_FETCH_PRODUCT_SECTION_TEAMS,
  START_ADD_PRODUCT_TAB_TEAMS,
  END_ADD_PRODUCT_TAB_TEAMS,
  START_ADD_PRODUCT_SECTION_TEAMS,
  END_ADD_PRODUCT_SECTION_TEAMS,
  START_DELETE_PRODUCT_TAB_TEAMS,
  END_DELETE_PRODUCT_TAB_TEAMS,
  START_DELETE_PRODUCT_SECTION_TEAMS,
  END_DELETE_PRODUCT_SECTION_TEAMS,
  START_FETCH_ORG_PRODUCT_TABS,
  END_FETCH_ORG_PRODUCT_TABS,
  START_ALL_PRODUCT_SEARCH,
  END_ALL_PRODUCT_SEARCH,
  START_FETCH_DWNLD_PRODUCT_CSV_FILE, END_FETCH_DWNLD_PRODUCT_CSV_FILE,
  START_IMPORT_PRODUCT_DATA_FILE, END_IMPORT_PRODUCT_DATA_FILE,
  START_FETCH_ORG_PRODUCT_TAB_ITEMS, END_FETCH_ORG_PRODUCT_TAB_ITEMS,
  START_ADD_NEW_CONTRACT_DETAILS, END_ADD_NEW_CONTRACT_DETAILS,
  START_FETCH_OVERALL_ANALYSYS_DATA, END_FETCH_OVERALL_ANALYSYS_DATA,
  START_FETCH_TEAM_WISE_ANALYSYS_DATA, END_FETCH_TEAM_WISE_ANALYSYS_DATA,
  START_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA, END_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA,
  START_FETCH_ANALYSYS_CRITERIAS, END_FETCH_ANALYSYS_CRITERIAS,
  START_FETCH_ANALYSYS_REQUIREMENTS, END_FETCH_ANALYSYS_REQUIREMENTS,
  RESET_ANALYSIS_DATA, START_UPDATE_ORG_DOCUMENT, END_UPDATE_ORG_DOCUMENT,
  START_GET_ORG_DOCUMENT_DETAILS, END_GET_ORG_DOCUMENT_DETAILS,
  START_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA, END_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA,
  START_UPDATE_DOCUMENT_FILE, END_UPDATE_DOCUMENT_FILE,
  SHOW_ADD_ORG_PRODUCT_WORKFLOW_DIALOG, HIDE_ADD_ORG_PRODUCT_WORKFLOW_DIALOG
} from "./action";

import { END_ADD_PRODUCT_STATE, END_DELETE_PRODUCT_STATE } from "../action";
import { END_PRODUCT_EXT_COLLABORATOR_ADD, END_PRODUCT_EXT_COLLABORATOR_REMOVE } from "../../externalCollaborator/action";

const initState = {
  orgProductCountStatsInProgress: false,
  orgProductCountStats: null,

  addOrgProductProgress: false,
  addorgProductStatus: null,
  addorgProductStatusError: null,

  addOrgProductCatsProgress: false,
  addorgProductCatsStatus: null,

  addOrgProductChampProgress: false,
  addorgProductChampStatus: null,

  // For Product alternatives
  fetchProductAlternativesUnderProgress: false,
  productAlternatives: null,

  // For complementary products
  fetchComplementaryProductsUnderProgress: false,
  complementaryProducts: null,

  // For company products
  fetchCompanyProductsUnderProgress: false,
  companyProducts: null,

  // completed review products
  fetchCompletedReviewProductsUnderProgress: false,
  completedReviewProducts: null,

  // upcoming review products
  fetchUpcomingReviewProductsUnderProgress: false,
  upcomingReviewProducts: null,

  // view products list
  fetchProductReviewsProgress: false,
  productReviews: [],

  //delete product review
  deleteProductReviewProgress: false,

  orgProducts: [],
  orgProductFetchProg: false,
  totalOrgProducts: 0,

  dialogOrgProductChampionsState: false,

  //org product details
  orgProductDetails: {},
  orgDepartment: [],
  orgCategories: [],
  contactDetailLinks: [],
  orgProductDetailsFetchProgress: false,
  orgProductDetailsFetchError: null,
  orgAddContactDetailLinkProgress: false,
  orgDeleteContactDetailLinkProgress: false,

  //org product department list;
  orgProductDepartment: [],
  orgProductDepartmentFetchProgress: false,

  //org product contact details
  orgContactDetails: [],
  orgContactDetailsFetchProgress: false,

  //org product vendor poc
  orgVendorPocDetails: [],
  orgVendorPocDetailsFetchProgress: false,

  //update org product details
  orgProductDetailsUpdateProgress: false,
  orgProductDetailsUpdateSuccess: null,

  //add product group
  orgProductGroupAddProgress: false,

  // dialog org product
  dialogOrgProductChampions: {},

  // org add vendor poc
  orgAddVendorPocProgress: false,

  // org delete vendor poc
  orgDeleteVendorPocProgress: false,

  //org add contact detail
  orgAddContactDetailsProgress: false,

  // org delete contact detail
  orgDeleteContactDetailsProgress: false,

  //org finance detail
  orgFinanceDetails: [],
  orgFinanceDetailsProgress: false,
  orgUpdateFinanceDetailsProgress: false,

  orgFetchDocuments: [],
  orgFetchDocumentsProgress: false,
  addOrgDocumentProgress: false,
  addOrgDocumentError: null,
  addOrgDocumentSuccess: null,
  deleteOrgDocumentProgress: false,

  // org security cert
  orgFetchSecurityCert: [],
  orgFetchSecurityCertProgress: false,

  orgFetchSecurityCertDocs: [],
  orgFetchSecurityCertDocProgress: false,

  //org add security cert
  orgAddSecurityCertProgress: false,

  //org update security cert
  orgUpdateSecurityCertProgress: false,

  //org delete security cert
  orgDeleteSecurityCertProgress: false,

  // org pii
  orgFetchPii: [],
  orgFetchPiiProgress: false,

  //org add pii
  orgAddPiiProgress: false,

  //org delete pii
  orgDeletePiiProgress: false,

  // org risk posture
  orgRiskPosture: [],
  orgRiskPostureProgress: false,

  orgProductInprogressReviews: null,
  orgProductInprogressReviewsInprogress: false,

  orgProductCompletedReviews: null,
  orgProductCompletedReviewsInprogress: false,

  // org feature
  orgFeatures: [],
  orgFeaturesProgress: false,

  addOrgFeaturesProgress: false,
  deleteOrgFeaturesProgress: false,

  // org feature
  orgSubCategories: [],
  orgSubCategoriesProgress: false,

  addOrgSubCategoriesProgress: false,
  addOrgCategoriesProgress: false,
  addNewOrgCategoriesProgress: false,
  addNewOrgCategoriesError: null,
  deleteOrgSubCategoriesProgress: false,
  deleteOrgCategoriesProgress: false,

  orgPlatform: [],
  orgPlatformProgress: false,
  addOrgPlatformProgress: false,
  deleteOrgPlatformProgress: false,

  orgIntegration: [],
  rejectedIntegrations: [],
  orgSimilarProduct: [],
  orgSameProduct: [],
  orgComplementaryProduct: [],
  orgIntegrationTotal: 0,
  orgSimilarProductTotal: 0,
  orgSameProductTotal: 0,
  orgComplementaryProductTotal: 0,
  orgIntegrationProgress: false,
  orgSimilarProductProgress: false,
  orgSameProductProgress: false,
  orgComplementaryProductProgress: false,
  addOrgIntegrationProgress: false,
  addOrgSimilarProductProgress: false,
  addOrgSameProductProgress: false,
  addOrgComplementaryProductProgress: false,
  deleteOrgIntegrationProgress: false,
  deleteOrgSimilarProductProgress: false,
  deleteOrgSameProductProgress: false,
  deleteOrgComplementaryProductProgress: false,

  similarExistingProducts: {},
  similarExistingProductProgress: false,

  isOpenOrgProductContractDetailsDialog: false,
  orgProductContractDetailsDialog: {},
  fetchParseContractDetailsProg: false,
  parseContractDetails: null,
  parseContractDetailsError: null,

  fetchExistingSimilarProductsProgress: false,
  orgExisingSimilarProducts: [],

  fetchAlternativeProductsProgress: false,
  orgAlternativeProducts: [],
  fetchOrgProductsSimilarExistingProgress: false,
  orgProductsSimilarExisting: [],

  searchOrgProductsProgress: true,
  searchOrgProductsData: [],

  orgProductDetailTabs: [],
  orgProductDetailTabsProgress: false,
  addOrgProductDetailTabsProgress: false,
  updateOrgProductDetailTabsProgress: false,
  deleteOrgProductDetailTabsProgress: false,
  addOrgProductDetailTabsSectionProgress: false,
  updateOrgProductDetailTabsSectionProgress: false,
  deleteOrgProductDetailTabsSectionProgress: false,
  addOrgProductDetailTabsSectionError: null,
  updateOrgProductDetailTabsSectionError: null,
  addOrgProductDetailTabsSectionItemProgress: false,
  updateOrgProductDetailTabsSectionItemProgress: false,
  addOrgProductDetailTabsSectionItemError: null,
  updateOrgProductDetailTabsSectionItemError: null,
  orgProductDetailTabsSectionItemData: {},
  orgProductDetailTabsSectionItemDataProgress: false,
  deleteOrgProductDetailTabsSectionItemProgress: false,
  addContractDetailsProg: false,
  addContractDetailsError: null,
  updateContractDetailsProg: false,
  updateContractDetailsError: null,
  activeContractDocument: null,
  productPriceProg: false,
  productPriceError: null,
  productPrice: {},
  featuredProductsFetchProg: true,
  featuredProducts: [],
  orgProductStates: [],

  addProductTeamsDialogOpen: false,
  selectAddProductTeamId: null,
  selectAddProductTeamType: null,
  selectAddProductTeamData: null,

  startFetchProductTabTeams: false,
  productTabTeams: [],

  startFetchProductSectionTeams: false,
  productSectionTeams: [],

  addProductTabTeamsProgress: false,
  addProductSectionTeamsProgress: false,

  deleteProductTabTeamProgress: false,
  deleteProductSectionTeamProgress: false,
  deleteProductSectionTeamError: null,
  deleteProductTabTeamError: null,
  addProductSectionTeamsError: null,
  addProductTabTeamsError: null,

  allProductSearchData: {},
  allProductSearchDataProg: false,

  importProductDataFileData: null,
  importProductDataFileProg: false,

  fetchdownloadProductCSVFileLinkData: null,
  fetchdownloadProductCSVFileLinkDataProg: false,

  fetchOrgProductTabItems: false,
  productTabItems: [],

  addContractProgress: false,
  addContractError: null,
  addContractSuccess: null,
  teamWiseAnalysisData: [],
  fetchTeamWiseAnalysisError: null,
  fetchTeamWiseAnalysisProgress: false,

  requirementWiseAnalysisData: [],
  fetchRequirementWiseAnalysisError: null,
  fetchRequirementWiseAnalysisProgress: false,

  teamRequirementWiseAnalysisData: [],
  fetchTeamRequirementWiseAnalysisError: null,
  fetchTeamRequirementWiseAnalysisProgress: false,

  overallAnalysisData: [],
  fetchOverallAnalysisError: null,
  fetchOverallAnalysisProgress: false,

  analysisRequirementsData: [],
  fetchAnalysisRequirementsError: null,
  fetchAnalysisRequirementsProgress: false,

  analysisCriteriasData: [],
  fetchAnalysisCriteriasError: null,
  fetchAnalysisCriteriasProgress: false,

  updateOrgDocumentProgress: false,
  updateOrgDocumentError: null,
  updateOrgDocumentSuccess: null,

  getOrgDocumentDetailsProgress: false,
  getOrgDocumentDetailsError: null,
  getOrgDocumentDetailsSuccess: null,

  attachDocumentProgress: false,
  attachDocumentError: null,
  attachDocumentSuccess: null,

  contractFoldersData: {},
  fetchContractFoldersError: null,
  fetchContractFoldersProgress: false,

  updateContractFoldersError: null,
  updateContractFoldersProgress: false,

  dialogOrgProductWorkflowState: false,
  dialogOrgProductWorkflow: {},
};

export function orgProductReducer(state = initState, action) {
  switch (action.type) {
    case RESET_ANALYSIS_DATA: {
      return {
        ...state,
        analysisCriteriasData: [],
        analysisRequirementsData: [],
        teamWiseAnalysisData: [],
        overallAnalysisData: [],
        requirementWiseAnalysisData: [],
        teamRequirementWiseAnalysisData: [],
      };
    }
    case START_FETCH_ANALYSYS_CRITERIAS: {
      return {
        ...state,
        analysisCriteriasData: [],
        fetchAnalysisCriteriasError: null,
        fetchAnalysisCriteriasProgress: true,
      };
    }
    case END_FETCH_ANALYSYS_CRITERIAS: {
      return {
        ...state,
        analysisCriteriasData: action.payload.success || [],
        fetchAnalysisCriteriasProgress: false,
        fetchAnalysisCriteriasError: action.payload.error,
      };
    }
    case START_UPDATE_CONTRACT_FOLDERS: {
      return {
        ...state,
        contractFoldersData: {},
        updateContractFoldersError: null,
        updateContractFoldersProgress: true,
      };
    }
    case END_UPDATE_CONTRACT_FOLDERS: {
      return {
        ...state,
        contractFoldersData: action.payload.success || {},
        updateContractFoldersProgress: false,
        updateContractFoldersError: action.payload.error,
      };
    }
    case START_FETCH_CONTRACT_FOLDERS: {
      return {
        ...state,
        contractFoldersData: {},
        fetchContractFoldersError: null,
        fetchContractFoldersProgress: true,
      };
    }
    case END_FETCH_CONTRACT_FOLDERS: {
      return {
        ...state,
        contractFoldersData: action.payload.success || {},
        fetchContractFoldersProgress: false,
        fetchContractFoldersError: action.payload.error,
      };
    }
    case START_FETCH_ANALYSYS_REQUIREMENTS: {
      return {
        ...state,
        analysisRequirementsData: [],
        fetchAnalysisRequirementsError: null,
        fetchAnalysisRequirementsProgress: true,
      };
    }
    case END_FETCH_ANALYSYS_REQUIREMENTS: {
      return {
        ...state,
        analysisRequirementsData: action.payload.success || [],
        fetchAnalysisRequirementsProgress: false,
        fetchAnalysisRequirementsError: action.payload.error,
      };
    }
    case START_FETCH_OVERALL_ANALYSYS_DATA: {
      return {
        ...state,
        overallAnalysisData: [],
        fetchOverallAnalysisError: null,
        fetchOverallAnalysisProgress: true,
      };
    }
    case END_FETCH_OVERALL_ANALYSYS_DATA: {
      return {
        ...state,
        overallAnalysisData: action.payload.success || [],
        fetchOverallAnalysisProgress: false,
        fetchOverallAnalysisError: action.payload.error,
      };
    }
    case START_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA: {
      return {
        ...state,
        requirementWiseAnalysisData: [],
        fetchRequirementWiseAnalysisError: null,
        fetchRequirementWiseAnalysisProgress: true,
      };
    }
    case END_FETCH_REQUIREMENT_WISE_ANALYSYS_DATA: {
      return {
        ...state,
        requirementWiseAnalysisData: action.payload.success || [],
        fetchRequirementWiseAnalysisProgress: false,
        fetchRequirementWiseAnalysisError: action.payload.error,
      };
    }

    case START_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA: {
      return {
        ...state,
        teamRequirementWiseAnalysisData: [],
        fetchTeamRequirementWiseAnalysisError: null,
        fetchTeamRequirementWiseAnalysisProgress: true,
      };
    }
    case END_FETCH_TEAM_REQUIREMENT_WISE_ANALYSYS_DATA: {
      return {
        ...state,
        teamRequirementWiseAnalysisData: action.payload.success || [],
        fetchTeamRequirementWiseAnalysisProgress: false,
        fetchTeamRequirementWiseAnalysisError: action.payload.error,
      };
    }
    case START_FETCH_TEAM_WISE_ANALYSYS_DATA: {
      return {
        ...state,
        teamWiseAnalysisData: [],
        fetchTeamWiseAnalysisError: null,
        fetchTeamWiseAnalysisProgress: true,
      };
    }
    case END_FETCH_TEAM_WISE_ANALYSYS_DATA: {
      return {
        ...state,
        teamWiseAnalysisData: action.payload.success || [],
        fetchTeamWiseAnalysisProgress: false,
        fetchTeamWiseAnalysisError: action.payload.error,
      };
    }
    case START_FETCH_DWNLD_PRODUCT_CSV_FILE: {
      return {
        ...state,
        fetchdownloadProductCSVFileLinkData: null,
        fetchdownloadProductCSVFileLinkDataProg: true,
      };
    }
    case END_FETCH_DWNLD_PRODUCT_CSV_FILE: {
      let fetchdownloadProductCSVFileLinkData = state.fetchdownloadProductCSVFileLinkData
      if (action.payload.error === null) {
        fetchdownloadProductCSVFileLinkData = action.payload.success?.path || null
      }
      return {
        ...state,
        fetchdownloadProductCSVFileLinkData,
        fetchdownloadProductCSVFileLinkDataProg: false,
      };
    }
    case START_IMPORT_PRODUCT_DATA_FILE: {
      return {
        ...state,
        importProductDataFileData: null,
        importProductDataFileProg: true,
      };
    }
    case END_IMPORT_PRODUCT_DATA_FILE: {
      let importProductDataFileData = state.importProductDataFileData
      if (action.payload.error === null) {
        importProductDataFileData = action.payload.success
      }
      return {
        ...state,
        importProductDataFileData,
        importProductDataFileProg: false,
      };
    }
    case START_ALL_PRODUCT_SEARCH: {
      return {
        ...state,
        allProductSearchData: {},
        allProductSearchDataProg: true,
      };
    }
    case END_ALL_PRODUCT_SEARCH: {
      let allProductSearchData = state.allProductSearchData;
      if (action.payload.error === null) {
        allProductSearchData = action.payload.success.data;
      }
      return {
        ...state,
        allProductSearchData,
        allProductSearchDataProg: false,
      };
    }

    case END_ADD_PRODUCT_STATE: {
      let orgProducts = state.orgProducts;
      let orgProductsSimilarExisting = state.orgProductsSimilarExisting;
      const { productId, success } = action.payload;
      if (orgProducts) {
        const index = orgProducts.findIndex(
          (obj) => parseInt(obj.ID) === parseInt(productId)
        );
        if (index > -1) {
          const currentProduct = orgProducts[index];
          const data = { StateName: success.State, StateID: success.ID };
          if (currentProduct.ProductStates) {
            currentProduct.ProductStates.push(data);
          } else {
            currentProduct["ProductStates"] = [data];
          }
          orgProducts[index] = currentProduct;
        }
      }

      if (orgProductsSimilarExisting) {
        const index = orgProductsSimilarExisting.findIndex(
          (obj) => parseInt(obj.ID) === parseInt(productId)
        );
        if (index > -1) {
          const currentProduct = orgProductsSimilarExisting[index];
          const data = { StateName: success.State, StateID: success.ID };
          if (currentProduct.ProductStates) {
            currentProduct.ProductStates.push(data);
          } else {
            currentProduct["ProductStates"] = [data];
          }
          orgProductsSimilarExisting[index] = currentProduct;
        }
      }

      return {
        ...state,
        orgProducts,
        orgProductsSimilarExisting,
      };
    }

    case END_DELETE_PRODUCT_STATE: {
      const { productId, stateId } = action.payload;
      let orgProducts = state.orgProducts;
      let orgProductsSimilarExisting = state.orgProductsSimilarExisting;
      if (orgProducts) {
        const index = orgProducts.findIndex(
          (obj) => parseInt(obj.ID) === parseInt(productId)
        );
        if (index > -1) {
          let ProductStates = orgProducts[index].ProductStates;
          ProductStates = ProductStates.filter(
            (item) => item.StateID !== stateId
          );
          orgProducts[index].ProductStates = ProductStates;
        }
      }

      if (orgProductsSimilarExisting) {
        const index = orgProductsSimilarExisting.findIndex(
          (obj) => obj.ID === productId
        );
        if (index > -1) {
          let ProductStates = orgProductsSimilarExisting[index].ProductStates;
          ProductStates = ProductStates.filter(
            (item) => item.StateID !== stateId
          );
          orgProductsSimilarExisting[index].ProductStates = ProductStates;
        }
      }

      return {
        ...state,
        orgProducts,
        orgProductsSimilarExisting,
      };
    }
    case RESET_ORG_PRODUCT_DATA:
      return {
        ...state,
        addorgProductStatus: null,
        addorgProductCatsStatus: null,
        addorgProductChampStatus: null,
      };
    case START_ORG_PRODUCTS_COUNT:
      return {
        ...state,
        orgProductCountStatsInProgress: true,
      };

    case END_ORG_PRODUCTS_COUNT:
      let productCount = null;
      var response = action.payload.success;

      if (response !== null) {
        productCount = response;
      }

      return {
        ...state,
        orgProductCountStatsInProgress: false,
        orgProductCountStats: productCount,
      };

    case START_ADD_ORG_PRODUCT:
      return {
        ...state,
        addOrgProductProgress: true,
        addorgProductStatus: null,
        addorgProductStatusError: null,
      };

    case END_ADD_ORG_PRODUCT: {
      let productStatus = null;
      const response = action.payload.success;
      if (response !== null) {
        productStatus = response;
      }

      return {
        ...state,
        addOrgProductProgress: false,
        addorgProductStatus: productStatus,
        addorgProductStatusError: action.payload.error,
      };
    }

    case START_ADD_ORG_PRODUCT_CATEGORIES:
      return {
        ...state,
        addOrgProductCatsProgress: true,
        addorgProductCatsStatus: null,
      };

    case END_ADD_ORG_PRODUCT_CATEGORIES:
      let productCatsStatus = null;
      var response1 = action.payload.success;
      if (response1 !== null) {
        productCatsStatus = response1;
      }
      return {
        ...state,
        addOrgProductCatsProgress: false,
        addorgProductCatsStatus: productCatsStatus,
      };

    case START_ADD_ORG_PRODUCT_CHAMPIONS:
      return {
        ...state,
        addOrgProductChampProgress: true,
        addorgProductChampStatus: null,
      };

    case END_ADD_ORG_PRODUCT_CHAMPIONS:
      let productChampStatus = null;
      var response_ao = action.payload.success;
      if (response_ao !== null) {
        productChampStatus = response_ao;
      }
      return {
        ...state,
        addOrgProductChampProgress: false,
        addorgProductChampStatus: productChampStatus,
      };

    case START_ADD_ORG_PRODUCT_ALTERNATIVES:
      return {
        ...state,
        fetchProductAlternativesUnderProgress: true,
      };

    case END_ADD_ORG_PRODUCT_ALTERNATIVES:
      let productAlternatives = {};
      var response_aol = action.payload.success;
      if (
        response_aol !== null &&
        response_aol.similar_products !== undefined
      ) {
        productAlternatives = response_aol.similar_products;
      }
      return {
        ...state,
        fetchProductAlternativesUnderProgress: false,
        productAlternatives: productAlternatives,
      };

    case START_FETCH_COMPLEMENTARY_PRODUCTS:
      return {
        ...state,
        fetchComplementaryProductsUnderProgress: true,
      };

    case END_FETCH_COMPLEMENTARY_PRODUCTS:
      let complementaryProducts = {};
      var fcp_response = action.payload.success;
      if (
        fcp_response !== null &&
        fcp_response.complementary_products !== undefined
      ) {
        complementaryProducts = fcp_response.complementary_products;
      }
      return {
        ...state,
        fetchComplementaryProductsUnderProgress: false,
        complementaryProducts: complementaryProducts,
      };

    case START_FETCH_COMPANY_PRODUCTS:
      return {
        ...state,
        fetchCompanyProductsUnderProgress: true,
      };

    case END_FETCH_COMPANY_PRODUCTS:
      let company_products = {};
      var fp_response = action.payload.success;
      if (fp_response !== null && fp_response.company_products !== undefined) {
        company_products = fp_response.company_products;
      }
      return {
        ...state,
        fetchCompanyProductsUnderProgress: false,
        companyProducts: company_products,
      };

    // completed reviews
    case START_FETCH_COMPLETED_REVIEWS_PRODUCTS:
      var params__ = action.payload.params;
      let completedReviewProducts_ = {};
      if (params__.page === 0) {
        completedReviewProducts_ = null;
      } else {
        completedReviewProducts_ = state.completedReviewProducts;
      }
      return {
        ...state,
        fetchCompletedReviewProductsUnderProgress: true,
        completedReviewProducts: completedReviewProducts_,
      };

    case END_FETCH_COMPLETED_REVIEWS_PRODUCTS:
      var completedReviewProducts = {};
      var params = action.payload.params;
      var response_ = action.payload.success;
      if (response_ && response_?.Data) {
        if (!params.page || params.page === 0) {
          completedReviewProducts = response_;
        } else {
          let oldData_ = state.completedReviewProducts;
          completedReviewProducts = response_;
          let mergeData = [...oldData_.Data, ...completedReviewProducts.Data];
          completedReviewProducts.Data = mergeData;
        }
      }
      return {
        ...state,
        fetchCompletedReviewProductsUnderProgress: false,
        completedReviewProducts: completedReviewProducts,
      };

    // Upcoming products
    case START_FETCH_UPCOMING_REVIEWS_PRODUCTS:
      var params_ = action.payload.params;
      let upcomingReviewProducts_ = {};
      if (params_.page === 0) {
        upcomingReviewProducts_ = null;
      } else {
        upcomingReviewProducts_ = state.upcomingReviewProducts;
      }
      return {
        ...state,
        fetchUpcomingReviewProductsUnderProgress: true,
        upcomingReviewProducts: upcomingReviewProducts_,
      };

    case END_FETCH_UPCOMING_REVIEWS_PRODUCTS:
      var upcomingReviewProducts = {};
      var params1 = action.payload.params;
      var response_ur = action.payload.success;
      if (response_ur !== null && response_ur !== undefined) {
        if (!params1.page || params1.page === 0) {
          upcomingReviewProducts = response_ur;
        } else {
          let oldData = state.upcomingReviewProducts;
          upcomingReviewProducts = response_ur;
          let mergeData = [...oldData.Data, ...upcomingReviewProducts.Data];
          upcomingReviewProducts.Data = mergeData;
        }
      }
      return {
        ...state,
        fetchUpcomingReviewProductsUnderProgress: false,
        upcomingReviewProducts: upcomingReviewProducts,
      };

    case START_ADD_PRODUCT_REVIEW:
      return {
        ...state,
        fetchAddProductToReview: true,
        evaludationDetails: {},
      };
    case END_ADD_PRODUCT_REVIEW:
      var response_apr = action.payload.success;
      return {
        ...state,
        fetchAddProductToReview: false,
        evaludationDetails: response_apr,
      };
    case START_PRODUCT_REVIEWS:
      return {
        ...state,
        fetchProductReviewsProgress: true,
        productReviews: [],
      };
    case END_PRODUCT_REVIEWS:
      var response_pr = action.payload.success;
      return {
        ...state,
        fetchProductReviewsProgress: false,
        productReviews: response_pr,
      };

    case START_DELETE_PRODUCT_REVIEW:
      return {
        ...state,
        deleteProductReviewProgress: true,
        deleteProductReviewError: null,
      };
    case END_DELETE_PRODUCT_REVIEW:
      var error = null;
      let oldData = { ...state.completedReviewProducts };
      if (action.payload?.error?.message) {
        error = action.payload?.error?.message;
      } else {
        var newDataArr = oldData?.Data?.filter(data => data.EvaluationID !== action.payload?.id)
        oldData.Data = newDataArr;
      }
      return {
        ...state,
        deleteProductReviewProgress: false,
        deleteProductReviewError: error,
        completedReviewProducts: oldData,
      };
    case START_FETCH_ORG_PRODUCT:
      var orgProducts = state.orgProducts;
      return {
        ...state,
        orgProductFetchProg: true,
        orgProducts: orgProducts,
      };

    case END_FETCH_ORG_PRODUCT:
      var success = action.payload.success;
      var _orgProducts = state.orgProducts;
      var _totalOrgProducts = state.totalOrgProducts;
      if (success !== null) {
        var Page = success.page;
        var Data = success.Data;
        _totalOrgProducts = success.total;
        if (Page === 0) {
          _orgProducts = Data;
        } else {
          _orgProducts = _orgProducts.concat(Data);
        }
      }
      return {
        ...state,
        orgProductFetchProg: false,
        orgProducts: _orgProducts,
        totalOrgProducts: _totalOrgProducts,
      };
    case START_FETCH_FEATURED_PRODUCT:
      return {
        ...state,
        featuredProductsFetchProg: true,
        featuredProducts: [],
      };

    case END_FETCH_FEATURED_PRODUCT:
      var success_fp = action.payload.success;
      return {
        ...state,
        featuredProductsFetchProg: false,
        featuredProducts: success_fp?.Data,
      };
    case SHOW_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG:
      return {
        ...state,
        dialogOrgProductChampionsState: true,
        dialogOrgProductChampions: action.payload,
      };
    case HIDE_ADD_ORG_PRODUCT_CHAMPIONS_DIALOG:
      return {
        ...state,
        dialogOrgProductChampionsState: false,
        dialogOrgProductChampions: {},
      };
    case START_FETCH_ORG_PRODUCT_DETAILS:
      return {
        ...state,
        orgProductDetails: state.orgProductDetails || null,
        orgDepartment: state.orgDepartment || [],
        orgCategories: state.orgCategories || [],
        contactDetailLinks: state.ContactDetailLinks || [],
        orgProductStates: state.orgProductStates || [],
        orgProductDetailsFetchProgress: true,
      };
    case END_FETCH_ORG_PRODUCT_DETAILS:
      return {
        ...state,
        orgProductDetails: action.payload?.success?.orgProduct || null,
        orgDepartment: action.payload?.success?.Departments || [],
        orgCategories: action.payload?.success?.Categories || [],
        orgProductStates: action.payload?.success?.States,
        contactDetailLinks: action.payload?.success?.ContactDetailLinks || [],
        orgProductDetailsFetchProgress: false,
        orgProductDetailsFetchError: action.payload?.error
      };
    case START_FETCH_ORG_CONTACT_DETAILS:
      return {
        ...state,
        orgContactDetails: [],
        orgContactDetailsFetchProgress: true,
      };
    case END_FETCH_ORG_CONTACT_DETAILS:
      return {
        ...state,
        orgContactDetails:
          action?.payload?.success == null ? [] : action?.payload?.success,
        orgContactDetailsFetchProgress: false,
      };
    case START_ADD_ORG_CONTACT_DETAILS:
      return {
        ...state,
        orgContactDetails: [],
        orgAddContactDetailsProgress: true,
      };
    case END_ADD_ORG_CONTACT_DETAILS:
      return {
        ...state,
        orgAddContactDetailsProgress: false,
      };
    case START_FETCH_ORG_VENDOR_POC_DETAILS:
      return {
        ...state,
        orgVendorPocDetails: [],
        orgVendorPocDetailsFetchProgress: true,
      };
    case END_FETCH_ORG_VENDOR_POC_DETAILS:
      return {
        ...state,
        orgVendorPocDetails: action?.payload?.success,
        orgVendorPocDetailsFetchProgress: false,
      };
    case START_UPDATE_ORG_PRODUCT_DETAILS:
      return {
        ...state,
        orgProductDetailsUpdateProgress: true,
        orgProductDetailsUpdateSuccess: null,
      };
    case END_UPDATE_ORG_PRODUCT_DETAILS:
      return {
        ...state,
        orgProductDetailsUpdateProgress: false,
        orgProductDetailsUpdateSuccess: action?.payload?.success,
      };
    case START_ORG_PRODUCT_GROUP_ADD:
      return {
        ...state,
        orgProductGroupAddProgress: true,
      };
    case END_ORG_PRODUCT_GROUP_ADD:
      return {
        ...state,
        orgProductGroupAddProgress: false,
      };
    case START_ORG_PRODUCT_GROUP_DELETE:
      return {
        ...state,
        orgProductGroupDeleteProgress: true,
      };
    case END_ORG_PRODUCT_GROUP_DELETE:
      return {
        ...state,
        orgProductGroupDeleteProgress: false,
      };
    case START_ORG_ADD_VENDOR_POC:
      return {
        ...state,
        orgAddVendorPocProgress: true,
      };
    case END_ORG_ADD_VENDOR_POC:
      return {
        ...state,
        orgAddVendorPocProgress: false,
      };
    case START_ORG_DELETE_VENDOR_POC:
      return {
        ...state,
        orgDeleteVendorPocProgress: true,
      };
    case END_ORG_DELETE_VENDOR_POC:
      return {
        ...state,
        orgDeleteVendorPocProgress: false,
      };
    case START_DELETE_ORG_CONTACT_DETAILS:
      return {
        ...state,
        orgDeleteContactDetailsProgress: true,
      };
    case END_DELETE_ORG_CONTACT_DETAILS:
      return {
        ...state,
        orgDeleteContactDetailsProgress: false,
      };
    case START_FETCH_ORG_PRODUCT_DEPARTMENT:
      return {
        ...state,
        orgProductDepartment: [],
        orgProductDepartmentFetchProgress: true,
      };
    case END_FETCH_ORG_PRODUCT_DEPARTMENT:
      return {
        ...state,
        orgProductDepartment: action.payload?.success?.groups || [],
        orgProductDepartmentFetchProgress: false,
      };
    case START_FETCH_ORG_FINANCE_DETAILS:
      return {
        ...state,
        orgFinanceDetails: state.orgFinanceDetails,
        orgFinanceDetailsProgress: true,
      };
    case END_FETCH_ORG_FINANCE_DETAILS:
      return {
        ...state,
        orgFinanceDetails:
          action.payload.success === null ? [] : action.payload.success,
        orgFinanceDetailsProgress: false,
      };
    case START_UPDATE_ORG_FINANCE_DETAILS:
      return {
        ...state,
        orgUpdateFinanceDetailsProgress: true,
      };
    case END_UPDATE_ORG_FINANCE_DETAILS:
      return {
        ...state,
        orgUpdateFinanceDetailsProgress: false,
      };
    case START_FETCH_ORG_DOCUMENTS:
      return {
        ...state,
        orgFetchDocuments: [],
        orgFetchDocumentsProgress: true,
      };
    case END_FETCH_ORG_DOCUMENTS:
      return {
        ...state,
        orgFetchDocuments: action.payload.success || [],
        orgFetchDocumentsProgress: false,
      };
    case START_ADD_ORG_DOCUMENT:
      return {
        ...state,
        addOrgDocumentProgress: true,
        addOrgDocumentError: null,
        addOrgDocumentSuccess: null,
      };
    case END_ADD_ORG_DOCUMENT:
      return {
        ...state,
        addOrgDocumentProgress: false,
        addOrgDocumentError: action.payload.error,
        addOrgDocumentSuccess: action.payload.success,
      };
    case START_DELETE_ORG_DOCUMENT:
      return {
        ...state,
        deleteOrgDocumentProgress: true,
      };
    case END_DELETE_ORG_DOCUMENT:
      return {
        ...state,
        deleteOrgDocumentProgress: false,
      };
    case START_ADD_ORG_SECURITY_CERT:
      return {
        ...state,
        orgAddSecurityCertProgress: true,
      };
    case END_ADD_ORG_SECURITY_CERT:
      return {
        ...state,
        orgAddSecurityCertProgress: false,
      };
    case START_UPDATE_ORG_SECURITY_CERT:
      return {
        ...state,
        orgUpdateSecurityCertProgress: true,
      };
    case END_UPDATE_ORG_SECURITY_CERT:
      return {
        ...state,
        orgUpdateSecurityCertProgress: false,
      };
    case START_ADD_ORG_SECURITY_CERT_DOC:
      return {
        ...state,
        orgUpdateSecurityCertProgress: true,
      };
    case END_ADD_ORG_SECURITY_CERT_DOC:
      return {
        ...state,
        orgUpdateSecurityCertProgress: false,
      };
    case START_FETCH_ORG_SECURITY_CERT:
      return {
        ...state,
        orgFetchSecurityCertProgress: true,
      };
    case END_FETCH_ORG_SECURITY_CERT:
      return {
        ...state,
        orgFetchSecurityCert: action?.payload?.success || [],
        orgFetchSecurityCertProgress: false,
      };
    case START_FETCH_ORG_SECURITY_CERT_DOC:
      return {
        ...state,
        orgFetchSecurityCertDocProgress: true,
      };
    case END_FETCH_ORG_SECURITY_CERT_DOC:
      return {
        ...state,
        orgFetchSecurityCertDocs: action?.payload?.success || [],
        orgFetchSecurityCertDocProgress: false,
      };
    case START_DELETE_ORG_SECURITY_CERT:
      return {
        ...state,
        orgDeleteSecurityCertProgress: true,
      };
    case END_DELETE_ORG_SECURITY_CERT:
      return {
        ...state,
        orgDeleteSecurityCertProgress: false,
      };
    case START_ADD_ORG_PII:
      return {
        ...state,
        orgAddPiiProgress: true,
      };
    case END_ADD_ORG_PII:
      return {
        ...state,
        orgAddPiiProgress: false,
      };
    case START_DELETE_ORG_PII:
      return {
        ...state,
        orgDeletePiiProgress: true,
      };
    case END_DELETE_ORG_PII:
      return {
        ...state,
        orgDeletePiiProgress: false,
      };
    case START_FETCH_ORG_PII:
      return {
        ...state,
        orgFetchPiiProgress: true,
      };
    case END_FETCH_ORG_PII:
      return {
        ...state,
        orgFetchPii: action?.payload?.success || [],
        orgFetchPiiProgress: false,
      };
    case START_FETCH_ORG_RISK_POSTURE:
      return {
        ...state,
        orgRiskPosture: [],
        orgRiskPostureProgress: true,
      };
    case END_FETCH_ORG_RISK_POSTURE:
      return {
        ...state,
        orgRiskPosture: action?.payload?.success?.Data || [],
        orgRiskPostureProgress: false,
      };

    case START_ORG_PRODUCT_COMPLETED_REVIEWS:
      let params_opcr = action.payload.params;
      let orgProductCompletedReviews_ = {};
      if (params_opcr.page === 0) {
        orgProductCompletedReviews_ = null;
      } else {
        orgProductCompletedReviews_ = state.orgProductCompletedReviews;
      }
      return {
        ...state,
        orgProductCompletedReviewsInprogress: true,
        orgProductCompletedReviews: orgProductCompletedReviews_,
      };
    case END_ORG_PRODUCT_COMPLETED_REVIEWS:
      let orgProductCompletedReviews = {};
      let params__params_opcr = action.payload.params;
      let response_opcr = action.payload.success;
      if (response_opcr && response_opcr?.Data) {
        if (!params__params_opcr.page || params__params_opcr.page === 0) {
          orgProductCompletedReviews = response_opcr;
        } else {
          let oldData_ = state.orgProductCompletedReviews;
          orgProductCompletedReviews = response_opcr;
          let mergeData = [
            ...oldData_.Data,
            ...orgProductCompletedReviews.Data,
          ];
          orgProductCompletedReviews.Data = mergeData;
        }
      }
      return {
        ...state,
        orgProductCompletedReviewsInprogress: false,
        orgProductCompletedReviews: orgProductCompletedReviews,
      };

    case START_ORG_PRODUCT_INPROGRESS_REVIEWS:
      let params_opir = action.payload.params;
      let orgProductInprogressReviews_ = {};
      if (params_opir.page === 0) {
        orgProductInprogressReviews_ = null;
      } else {
        orgProductInprogressReviews_ = state.orgProductInprogressReviews;
      }
      return {
        ...state,
        orgProductInprogressReviewsInprogress: true,
        orgProductInprogressReviews: orgProductInprogressReviews_,
      };
    case END_ORG_PRODUCT_INPROGRESS_REVIEWS:
      let orgProductInprogressReviews = {};
      let params__opir = action.payload.params;
      let response_opir = action.payload.success;
      if (response_opir && response_opir?.Data) {
        if (!params__opir.page || params__opir.page === 0) {
          orgProductInprogressReviews = response_opir;
        } else {
          let oldData_ = state.orgProductInprogressReviews;
          orgProductInprogressReviews = response_opir;
          let mergeData = [
            ...oldData_.Data,
            ...orgProductInprogressReviews.Data,
          ];
          orgProductInprogressReviews.Data = mergeData;
        }
      }
      return {
        ...state,
        orgProductInprogressReviewsInprogress: false,
        orgProductInprogressReviews: orgProductInprogressReviews,
      };

    case START_FETCH_ORG_FEATURES:
      return {
        ...state,
        orgFeaturesProgress: true,
      };
    case END_FETCH_ORG_FEATURES:
      return {
        ...state,
        orgFeatures: action?.payload?.success || [],
        orgFeaturesProgress: false,
      };

    case START_ADD_ORG_FEATURES:
      return {
        ...state,
        addOrgFeaturesProgress: true,
      };
    case END_ADD_ORG_FEATURES:
      return {
        ...state,
        addOrgFeaturesProgress: false,
      };
    case START_DELETE_ORG_FEATURES:
      return {
        ...state,
        deleteOrgFeaturesProgress: true,
      };
    case END_DELETE_ORG_FEATURES:
      return {
        ...state,
        deleteOrgFeaturesProgress: false,
      };
    case START_FETCH_ORG_SUBCATEGORIES:
      return {
        ...state,
        orgSubCategoriesProgress: true,
      };
    case END_FETCH_ORG_SUBCATEGORIES:
      return {
        ...state,
        orgSubCategories: action?.payload?.success || [],
        orgSubCategoriesProgress: false,
      };
    case START_ADD_ORG_SUBCATEGORIES:
      return {
        ...state,
        addOrgSubCategoriesProgress: true,
      };
    case END_ADD_ORG_SUBCATEGORIES:
      return {
        ...state,
        addOrgSubCategoriesProgress: false,
      };

    case START_ADD_ORG_CATEGORIES:
      return {
        ...state,
        addOrgCategoriesProgress: true,
      };
    case END_ADD_ORG_CATEGORIES:
      let orgCategories = state.orgCategories;
      if (action.payload.success !== null) {
        orgCategories = action.payload.success;
      }
      return {
        ...state,
        addOrgCategoriesProgress: false,
        orgCategories,
      };

    case START_ADD_NEW_ORG_CATEGORIES: {
      return {
        ...state,
        addNewOrgCategoriesProgress: true,
        addNewOrgCategoriesError: null,
      };
    }
    case END_ADD_NEW_ORG_CATEGORIES: {
      let orgCategories = state.orgCategories;
      if (action.payload.error === null) {
        orgCategories = action.payload.success;
      }
      return {
        ...state,
        addNewOrgCategoriesProgress: false,
        addNewOrgCategoriesError: action.payload.error,
        orgCategories,
      };
    }
    case START_DELETE_ORG_CATEGORIES:
      return {
        ...state,
        deleteOrgCategoriesProgress: true,
      };
    case END_DELETE_ORG_CATEGORIES: {
      let orgCategories = state.orgCategories;
      if (action.payload.error === null) {
        orgCategories = orgCategories.filter(
          (item) => item.ID !== action.payload.category_id
        );
      }
      return {
        ...state,
        deleteOrgCategoriesProgress: false,
        orgCategories,
      };
    }
    case START_DELETE_ORG_SUBCATEGORIES:
      return {
        ...state,
        deleteOrgSubCategoriesProgress: true,
      };
    case END_DELETE_ORG_SUBCATEGORIES:
      return {
        ...state,
        deleteOrgSubCategoriesProgress: false,
      };
    case START_FETCH_ORG_PLATFORM:
      return {
        ...state,
        orgPlatformProgress: true,
      };
    case END_FETCH_ORG_PLATFORM:
      return {
        ...state,
        orgPlatform: action?.payload?.success || [],
        orgPlatformProgress: false,
      };
    case START_ADD_ORG_PLATFORM:
      return {
        ...state,
        addOrgPlatformProgress: true,
      };
    case END_ADD_ORG_PLATFORM:
      return {
        ...state,
        orgPlatform: action?.payload?.success || [],
        addOrgPlatformProgress: false,
      };
    case START_DELETE_ORG_PLATFORM:
      return {
        ...state,
        deleteOrgPlatformProgress: true,
      };
    case END_DELETE_ORG_PLATFORM:
      var currentPlatforms = [...state.orgPlatform];
      if (action.payload.error == null) {
        currentPlatforms = currentPlatforms.filter((m) => {
          if (m.ID !== action.payload.platform_id) {
            return true;
          }
          return false;
        });
      }
      return {
        ...state,
        deleteOrgPlatformProgress: true,
        orgPlatform: currentPlatforms,
      };
    case START_FETCH_ORG_RELATED_PRODUCTS:
      let orgIntegration = state.orgIntegration;
      let rejectedIntegrations = state.rejectedIntegrations;
      let orgSimilarProduct = state.orgSimilarProduct;
      let orgSameProduct = state.orgSameProduct;
      let orgComplementaryProduct = state.orgComplementaryProduct;
      let orgIntegrationTotal = state.orgIntegrationTotal;
      let orgSimilarProductTotal = state.orgSimilarProductTotal;
      let orgSameProductTotal = state.orgSameProductTotal;
      let orgComplementaryProductTotal = state.orgComplementaryProductTotal;
      let orgIntegrationProgress = state.orgIntegrationProgress;
      let orgSimilarProductProgress = state.orgSimilarProductProgress;
      let orgSameProductProgress = state.orgSameProductProgress;
      let orgComplementaryProductProgress =
        state.orgComplementaryProductProgress;

      if (action.payload?.type === 1) {
        if (!action.payload?.page) {
          orgIntegration = [];
          rejectedIntegrations = [];
        }
        orgIntegrationTotal = 0;
        orgIntegrationProgress = true;
      } else if (action.payload?.type === 2) {
        if (!action.payload?.page) {
          orgSimilarProduct = [];
        }
        orgSimilarProductTotal = 0;
        orgSimilarProductProgress = true;
      } else if (action.payload?.type === 3) {
        if (!action.payload?.page) {
          orgSameProduct = [];
        }
        orgSameProductTotal = 0;
        orgSameProductProgress = true;
      } else if (action.payload?.type === 4) {
        if (!action.payload?.page) {
          orgComplementaryProduct = [];
        }
        orgComplementaryProductTotal = 0;
        orgComplementaryProductProgress = true;
      }

      return {
        ...state,
        orgIntegration: orgIntegration,
        rejectedIntegrations: rejectedIntegrations,
        orgSimilarProduct: orgSimilarProduct,
        orgSameProduct: orgSameProduct,
        orgComplementaryProduct: orgComplementaryProduct,
        orgIntegrationTotal: orgIntegrationTotal,
        orgSimilarProductTotal: orgSimilarProductTotal,
        orgSameProductTotal: orgSameProductTotal,
        orgComplementaryProductTotal: orgComplementaryProductTotal,
        orgIntegrationProgress: orgIntegrationProgress,
        orgSimilarProductProgress: orgSimilarProductProgress,
        orgSameProductProgress: orgSameProductProgress,
        orgComplementaryProductProgress: orgComplementaryProductProgress,
      };
    case END_FETCH_ORG_RELATED_PRODUCTS:
      let orgIntegrationEnd = state.orgIntegration;
      let rejectedIntegrationsEnd = state.rejectedIntegrations;
      let orgSimilarProductEnd = state.orgSimilarProduct;
      let orgSameProductEnd = state.orgSameProduct;
      let orgComplementaryProductEnd = state.orgComplementaryProduct;
      let orgIntegrationTotalEnd = state.orgIntegrationTotal;
      let orgSimilarProductTotalEnd = state.orgSimilarProductTotal;
      let orgSameProductTotalEnd = state.orgSameProductTotal;
      let orgComplementaryProductTotalEnd = state.orgComplementaryProductTotal;
      let orgIntegrationProgressEnd = state.orgIntegrationProgress;
      let orgSimilarProductProgressEnd = state.orgSimilarProductProgress;
      let orgSameProductProgressEnd = state.orgSameProductProgress;
      let orgComplementaryProductProgressEnd =
        state.orgComplementaryProductProgress;
      let successProduct = action.payload?.success || {};
      let total = action.payload?.success?.total || 0;
      if (action.payload?.type === 1) {
        if (successProduct.page === 0) {
          orgIntegrationEnd = successProduct.Data.filter(
            (product) => product.IsApproved === true
          );
          rejectedIntegrationsEnd = successProduct.Data.filter(
            (product) => product.IsApproved === false
          );
        } else if (successProduct.Data) {
          let approvedInt = successProduct.Data?.filter(
            (product) => product.IsApproved === true
          );
          let rejInt = successProduct.Data?.filter(
            (product) => product.IsApproved === false
          );
          orgIntegrationEnd = [...state.orgIntegration, ...approvedInt];
          rejectedIntegrationsEnd = [...state.orgIntegration, ...rejInt];
        }
        orgIntegrationTotalEnd = total;
        orgIntegrationProgressEnd = false;
      } else if (action.payload?.type === 2) {
        if (successProduct.page === 0) {
          orgSimilarProductEnd = successProduct.Data || [];
        } else if (successProduct.Data) {
          orgSimilarProductEnd = [
            ...state.orgSimilarProduct,
            ...successProduct.Data,
          ];
        }
        orgSimilarProductTotalEnd = total;
        orgSimilarProductProgressEnd = false;
      } else if (action.payload?.type === 3) {
        if (successProduct.page === 0) {
          orgSameProductEnd = successProduct.Data || [];
        } else if (successProduct.Data) {
          orgSameProductEnd = [...state.orgSameProduct, ...successProduct.Data];
        }
        orgSameProductTotalEnd = total;
        orgSameProductProgressEnd = false;
      } else if (action.payload?.type === 4) {
        if (successProduct.page === 0) {
          orgComplementaryProductEnd = successProduct.Data || [];
        } else if (successProduct.Data) {
          orgComplementaryProductEnd = [
            ...state.orgComplementaryProduct,
            ...successProduct.Data,
          ];
        }
        orgComplementaryProductTotalEnd = total;
        orgComplementaryProductProgressEnd = false;
      }

      return {
        ...state,
        orgIntegration: orgIntegrationEnd,
        rejectedIntegrations: rejectedIntegrationsEnd,
        orgSimilarProduct: orgSimilarProductEnd,
        orgSameProduct: orgSameProductEnd,
        orgComplementaryProduct: orgComplementaryProductEnd,
        orgIntegrationTotal: orgIntegrationTotalEnd,
        orgSimilarProductTotal: orgSimilarProductTotalEnd,
        orgSameProductTotal: orgSameProductTotalEnd,
        orgComplementaryProductTotal: orgComplementaryProductTotalEnd,
        orgIntegrationProgress: orgIntegrationProgressEnd,
        orgSimilarProductProgress: orgSimilarProductProgressEnd,
        orgSameProductProgress: orgSameProductProgressEnd,
        orgComplementaryProductProgress: orgComplementaryProductProgressEnd,
      };
    case START_ADD_ORG_RELATED_PRODUCTS:
      let addOrgIntegrationProgress = state.addOrgIntegrationProgress;
      let addOrgSimilarProductProgress = state.addOrgSimilarProductProgress;
      let addOrgSameProductProgress = state.addOrgSameProductProgress;
      let addOrgComplementaryProductProgress =
        state.addOrgComplementaryProductProgress;
      if (action.payload?.type === 1) {
        addOrgIntegrationProgress = true;
      } else if (action.payload?.type === 2) {
        addOrgSimilarProductProgress = true;
      } else if (action.payload?.type === 3) {
        addOrgSameProductProgress = true;
      } else if (action.payload?.type === 4) {
        addOrgComplementaryProductProgress = true;
      }

      return {
        ...state,
        addOrgIntegrationProgress: addOrgIntegrationProgress,
        addOrgSimilarProductProgress: addOrgSimilarProductProgress,
        addOrgSameProductProgress: addOrgSameProductProgress,
        addOrgComplementaryProductProgress: addOrgComplementaryProductProgress,
      };
    case END_ADD_ORG_RELATED_PRODUCTS:
      let addOrgIntegrationProgressEnd = state.addOrgIntegrationProgress;
      let addOrgSimilarProductProgressEnd = state.addOrgSimilarProductProgress;
      let addOrgSameProductProgressEnd = state.addOrgSameProductProgress;
      let addOrgComplementaryProductProgressEnd =
        state.addOrgComplementaryProductProgress;

      if (action.payload?.type === 1) {
        addOrgIntegrationProgressEnd = false;
      } else if (action.payload?.type === 2) {
        addOrgSimilarProductProgressEnd = false;
      } else if (action.payload?.type === 3) {
        addOrgSameProductProgressEnd = false;
      } else if (action.payload?.type === 4) {
        addOrgComplementaryProductProgressEnd = false;
      }

      return {
        ...state,
        addOrgIntegrationProgress: addOrgIntegrationProgressEnd,
        addOrgSimilarProductProgress: addOrgSimilarProductProgressEnd,
        addOrgSameProductProgress: addOrgSameProductProgressEnd,
        addOrgComplementaryProductProgress:
          addOrgComplementaryProductProgressEnd,
      };

    case START_DELETE_ORG_RELATED_PRODUCTS:
      let deleteOrgIntegrationProgress = state.deleteOrgIntegrationProgress;
      let deleteOrgSimilarProductProgress =
        state.deleteOrgSimilarProductProgress;
      let deleteOrgSameProductProgress = state.deleteOrgSameProductProgress;
      let deleteOrgComplementaryProductProgress =
        state.deleteOrgComplementaryProductProgress;

      if (action.payload?.type === 1) {
        deleteOrgIntegrationProgress = true;
      } else if (action.payload?.type === 2) {
        deleteOrgSimilarProductProgress = true;
      } else if (action.payload?.type === 3) {
        deleteOrgSameProductProgress = true;
      } else if (action.payload?.type === 4) {
        deleteOrgComplementaryProductProgress = true;
      }

      return {
        ...state,
        deleteOrgIntegrationProgress: deleteOrgIntegrationProgress,
        deleteOrgSimilarProductProgress: deleteOrgSimilarProductProgress,
        deleteOrgSameProductProgress: deleteOrgSameProductProgress,
        deleteOrgComplementaryProductProgress:
          deleteOrgComplementaryProductProgress,
      };
    case END_DELETE_ORG_RELATED_PRODUCTS:
      let deleteOrgIntegrationProgressEnd = state.deleteOrgIntegrationProgress;
      let deleteOrgSimilarProductProgressEnd =
        state.deleteOrgSimilarProductProgress;
      let deleteOrgSameProductProgressEnd = state.deleteOrgSameProductProgress;
      let deleteOrgComplementaryProductProgressEnd =
        state.deleteOrgComplementaryProductProgress;

      if (action.payload?.type === 1) {
        deleteOrgIntegrationProgressEnd = false;
      } else if (action.payload?.type === 2) {
        deleteOrgSimilarProductProgressEnd = false;
      } else if (action.payload?.type === 3) {
        deleteOrgSameProductProgressEnd = false;
      } else if (action.payload?.type === 4) {
        deleteOrgComplementaryProductProgressEnd = false;
      }

      return {
        ...state,
        deleteOrgIntegrationProgress: deleteOrgIntegrationProgressEnd,
        deleteOrgSimilarProductProgress: deleteOrgSimilarProductProgressEnd,
        deleteOrgSameProductProgress: deleteOrgSameProductProgressEnd,
        deleteOrgComplementaryProductProgress:
          deleteOrgComplementaryProductProgressEnd,
      };
    case START_FETCH_SIMILAR_EXISTING_PRODUCT:
      return {
        ...state,
        similarExistingProductProgress: true,
        similarExistingProducts: {},
      };
    case END_FETCH_SIMILAR_EXISTING_PRODUCT:
      var server_response = [];
      if (
        action.payload.success !== null &&
        action.payload.success.similar_existing !== undefined
      ) {
        server_response = action.payload.success.similar_existing;
      }
      var similarExistingProducts = {};
      server_response.forEach((item) => {
        similarExistingProducts[parseInt(item[0])] = item[1];
      });
      return {
        ...state,
        similarExistingProductProgress: false,
        similarExistingProducts: similarExistingProducts,
      };
    case START_DELETE_PRODUCT:
      return {
        ...state,
        deleteProductProgress: true,
      };
    case END_DELETE_PRODUCT:
      return {
        ...state,
        deleteProductProgress: false,
      };
    case SHOW_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG:
      return {
        ...state,
        isOpenOrgProductContractDetailsDialog: true,
        orgProductContractDetailsDialog: action.payload,
      };
    case HIDE_ADD_ORG_PRODUCT_CONTRACT_DETAILS_DIALOG:
      return {
        ...state,
        isOpenOrgProductContractDetailsDialog: false,
        orgProductContractDetailsDialog: {},
      };
    case START_FETCH_PARSE_CONTRACT_DETAILS:
      return {
        ...state,
        fetchParseContractDetailsProg: true,
        parseContractDetails: {},
        parseContractDetailsError: null,
      };
    case END_FETCH_PARSE_CONTRACT_DETAILS: {
      const { success, error } = action.payload;
      let parseContractDetails = null;
      let parseContractDetailsError = null;
      if (error === null) {
        if (success.error_message) {
          parseContractDetailsError = {
            message: success.error_message,
          };
        } else {
          parseContractDetails = success;
        }
      } else {
        parseContractDetailsError = error;
      }
      return {
        ...state,
        fetchParseContractDetailsProg: false,
        parseContractDetails,
        parseContractDetailsError,
      };
    }

    case START_ADD_CONTRACT_DETAILS:
      return {
        ...state,
        addContractDetailsProg: true,
        addContractDetailsError: null,
      };
    case END_ADD_CONTRACT_DETAILS: {
      const { success, error } = action.payload;
      let addContractDetailsError = null;
      const orgFinanceDetails = state.orgFinanceDetails;
      if (error) {
        addContractDetailsError = error;
      } else {
        orgFinanceDetails.push(success);
      }
      return {
        ...state,
        orgFinanceDetails,
        addContractDetailsProg: false,
        addContractDetailsError,
      };
    }
    case ACTIVE_CONTRACT_DETAILS:
      return {
        ...state,
        activeContractDocument: action.payload,
      };
    case START_UPDATE_CONTRACT_DETAILS:
      return {
        ...state,
        updateContractDetailsProg: true,
        updateContractDetailsError: null,
      };
    case END_UPDATE_CONTRACT_DETAILS: {
      const { error } = action.payload;
      let updateContractDetailsError = null;
      if (error) {
        updateContractDetailsError = error;
      }
      return {
        ...state,
        updateContractDetailsProg: false,
        updateContractDetailsError,
      };
    }

    case START_FETCH_EXISTING_SIMILAR_PRODUCTS:
      return {
        ...state,
        fetchExistingSimilarProductsProgress: true,
      };

    case END_FETCH_EXISTING_SIMILAR_PRODUCTS:
      return {
        ...state,
        fetchExistingSimilarProductsProgress: false,
        orgExisingSimilarProducts: action.payload.success,
      };
    case START_FETCH_ALTERNATIVE_PRODUCTS:
      return {
        ...state,
        fetchAlternativeProductsProgress: true,
      };

    case END_FETCH_ALTERNATIVE_PRODUCTS: {
      const { success } = action.payload;
      let orgAlternativeProducts = [];
      if (success) {
        if (success && success.status === 200) {
          orgAlternativeProducts = success?.Data || null;
        }
      }
      return {
        ...state,
        fetchAlternativeProductsProgress: false,
        orgAlternativeProducts,
      };
    }

    case RESET_SIMILAR_EXISTING_PRODUCTS:
      return {
        ...state,
        orgProductsSimilarExisting: [],
      };

    case START_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING:
      return {
        ...state,
        fetchOrgProductsSimilarExistingProgress: true,
        orgProductsSimilarExisting: [],
      };

    case END_FETCH_ORG_PRODUCTS_SIMILAR_EXISTING: {
      const { success } = action.payload;
      let orgProductsSimilarExisting = [];
      if (success) {
        if (success && success?.status === 200) {
          orgProductsSimilarExisting = success?.similar_products || [];
        }
      }
      return {
        ...state,
        fetchOrgProductsSimilarExistingProgress: false,
        orgProductsSimilarExisting,
      };
    }

    case START_SEARCH_ORG_PRODUCTS:
      return {
        ...state,
        searchOrgProductsProgress: true,
        searchOrgProductsData: [],
      };

    case END_SEARCH_ORG_PRODUCTS: {
      const { success } = action.payload;
      let searchOrgProductsData = [];
      if (success) {
        if (success && success.status === 200) {
          searchOrgProductsData = success?.Data || null;
        }
      }
      return {
        ...state,
        searchOrgProductsProgress: false,
        searchOrgProductsData,
      };
    }

    case START_FETCH_ORG_PRODUCT_DETAILS_TABS:
      return {
        ...state,
        orgProductDetailTabsProgress: true,
        orgProductDetailTabs: state.orgProductDetailTabs,
      };

    case END_FETCH_ORG_PRODUCT_DETAILS_TABS: {
      const { success } = action.payload;
      let orgProductDetailTabs = [];
      if (success !== null) {
        orgProductDetailTabs = success || orgProductDetailTabs;
      }
      return {
        ...state,
        orgProductDetailTabs,
        orgProductDetailTabsProgress: false,
      };
    }

    case START_FETCH_ORG_PRODUCT_TABS:
      return {
        ...state,
        orgProductDetailTabsProgress: true,
        orgProductDetailTabs: state.orgProductDetailTabs,
      };

    case END_FETCH_ORG_PRODUCT_TABS: {
      const { success } = action.payload;
      let orgProductDetailTabs = [];
      if (success !== null) {
        orgProductDetailTabs = success || orgProductDetailTabs;
      }
      return {
        ...state,
        orgProductDetailTabs,
        orgProductDetailTabsProgress: false,
      };
    }

    case START_ADD_ORG_PRODUCT_DETAILS_TAB:
      return {
        ...state,
        addOrgProductDetailTabsProgress: true,
      };

    case END_ADD_ORG_PRODUCT_DETAILS_TAB: {
      const { success } = action.payload;
      let orgProductDetailTabs = state.orgProductDetailTabs;
      if (success !== null) {
        orgProductDetailTabs.push(success);
      }
      return {
        ...state,
        orgProductDetailTabs,
        addOrgProductDetailTabsProgress: false,
      };
    }

    case START_UPDATE_ORG_PRODUCT_DETAILS_TAB:
      return {
        ...state,
        updateOrgProductDetailTabsProgress: true,
      };

    case END_UPDATE_ORG_PRODUCT_DETAILS_TAB: {
      const { success, tabUid } = action.payload;
      let orgProductDetailTabs = state.orgProductDetailTabs;
      if (success !== null) {
        const tabIndex = orgProductDetailTabs.findIndex(
          (obj) => obj.TabUID === tabUid
        );
        orgProductDetailTabs[tabIndex] = success || orgProductDetailTabs;
      }
      return {
        ...state,
        orgProductDetailTabs,
        updateOrgProductDetailTabsProgress: false,
      };
    }

    case START_DELETE_ORG_PRODUCT_DETAILS_TAB:
      return {
        ...state,
        deleteOrgProductDetailTabsProgress: true,
      };

    case END_DELETE_ORG_PRODUCT_DETAILS_TAB: {
      const { success, tabUid } = action.payload;
      let orgProductDetailTabs = state.orgProductDetailTabs;
      if (success !== null) {
        const tabIndex = orgProductDetailTabs.findIndex(
          (obj) => obj.TabUID === tabUid
        );
        orgProductDetailTabs.splice(tabIndex, 1);
      }
      return {
        ...state,
        orgProductDetailTabs,
        deleteOrgProductDetailTabsProgress: false,
      };
    }

    case START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION:
      return {
        ...state,
        addOrgProductDetailTabsSectionProgress: true,
      };

    case END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION: {
      const { success, tabUid, error } = action.payload;
      let orgProductDetailTabs = state.orgProductDetailTabs;
      if (success !== null && tabUid !== null) {
        const tabIndex = orgProductDetailTabs.findIndex(
          (obj) => obj.TabUID === tabUid
        );
        if (
          Array.isArray(orgProductDetailTabs[tabIndex].OrgProductTabSections)
        ) {
          orgProductDetailTabs[tabIndex].OrgProductTabSections.push(success);
        } else {
          orgProductDetailTabs[tabIndex].OrgProductTabSections = [success];
        }
      }
      return {
        ...state,
        orgProductDetailTabs,
        addOrgProductDetailTabsSectionProgress: false,
        addOrgProductDetailTabsSectionError: error,
      };
    }

    case START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION:
      return {
        ...state,
        updateOrgProductDetailTabsSectionProgress: true,
        updateOrgProductDetailTabsSectionError: null,
      };

    case END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION: {
      const { success, tabUid, sectionUid, error } = action.payload;
      let orgProductDetailTabs = state.orgProductDetailTabs;
      if (success !== null) {
        const tabIndex = orgProductDetailTabs.findIndex(
          (obj) => obj.TabUID === tabUid
        );
        const OrgProductTabSections =
          orgProductDetailTabs[tabIndex].OrgProductTabSections;
        const sectionIndex = OrgProductTabSections.findIndex(
          (obj) => obj.SectionUID === sectionUid
        );
        OrgProductTabSections[sectionIndex] = success;
        orgProductDetailTabs[tabIndex].OrgProductTabSections =
          OrgProductTabSections;
      }
      return {
        ...state,
        orgProductDetailTabs,
        updateOrgProductDetailTabsSectionProgress: false,
        updateOrgProductDetailTabsSectionError: error,
      };
    }

    case START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION:
      return {
        ...state,
        deleteOrgProductDetailTabsSectionProgress: true,
      };

    case END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION: {
      const { success, tabUid, sectionUid } = action.payload;
      let orgProductDetailTabs = state.orgProductDetailTabs;
      if (success !== null) {
        const tabIndex = orgProductDetailTabs.findIndex(
          (obj) => obj.TabUID === tabUid
        );
        const OrgProductTabSections =
          orgProductDetailTabs[tabIndex].OrgProductTabSections;
        const sectionIndex = OrgProductTabSections.findIndex(
          (obj) => obj.SectionUID === sectionUid
        );
        OrgProductTabSections.splice(sectionIndex, 1);
        orgProductDetailTabs[tabIndex].OrgProductTabSections =
          OrgProductTabSections;
      }
      return {
        ...state,
        orgProductDetailTabs,
        deleteOrgProductDetailTabsSectionProgress: false,
      };
    }

    case START_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM:
      return {
        ...state,
        addOrgProductDetailTabsSectionItemProgress: true,
        addOrgProductDetailTabsSectionItemError: null,
      };

    case END_ADD_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM: {
      const { success, tabUid, sectionUid, error } = action.payload;
      let orgProductDetailTabs = state.orgProductDetailTabs;
      if (success !== null && tabUid !== null) {
        const tabIndex = orgProductDetailTabs.findIndex(
          (obj) => obj.TabUID === tabUid
        );
        const OrgProductTabSections =
          orgProductDetailTabs[tabIndex].OrgProductTabSections;
        if (OrgProductTabSections) {
          const sectionIndex = OrgProductTabSections.findIndex(
            (obj) => obj.SectionUID === sectionUid
          );
          if (
            Array.isArray(
              OrgProductTabSections[sectionIndex].OrgProductTabItems
            )
          ) {
            OrgProductTabSections[sectionIndex].OrgProductTabItems.push(
              success
            );
          } else {
            OrgProductTabSections[sectionIndex].OrgProductTabItems = [success];
          }
          orgProductDetailTabs[tabIndex].OrgProductTabSections =
            OrgProductTabSections;
        }
      }
      return {
        ...state,
        orgProductDetailTabs,
        addOrgProductDetailTabsSectionItemProgress: false,
        addOrgProductDetailTabsSectionItemError: error,
      };
    }

    case START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM:
      return {
        ...state,
        updateOrgProductDetailTabsSectionProgress: true,
        updateOrgProductDetailTabsSectionError: null,
      };

    case END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM: {
      const { success, tabUid, sectionUid, itemUid, error } = action.payload;
      let orgProductDetailTabs = state.orgProductDetailTabs;
      if (success !== null) {
        const tabIndex = orgProductDetailTabs.findIndex(
          (obj) => obj.TabUID === tabUid
        );
        const OrgProductTabSections =
          orgProductDetailTabs[tabIndex].OrgProductTabSections;
        const sectionIndex = OrgProductTabSections.findIndex(
          (obj) => obj.SectionUID === sectionUid
        );
        const OrgProductTabItems =
          OrgProductTabSections[sectionIndex].OrgProductTabItems;
        const itemIndex = OrgProductTabItems.findIndex(
          (obj) => obj.ItemUID === itemUid
        );
        OrgProductTabItems[itemIndex] = success;
        OrgProductTabSections[sectionIndex].OrgProductTabItems =
          OrgProductTabItems;
        orgProductDetailTabs[tabIndex].OrgProductTabSections =
          OrgProductTabSections;
      }
      return {
        ...state,
        orgProductDetailTabs,
        updateOrgProductDetailTabsSectionProgress: false,
        updateOrgProductDetailTabsSectionError: error,
      };
    }

    case START_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA:
      let orgProductDetailTabsSectionItemData =
        state.orgProductDetailTabsSectionItemData;
      return {
        ...state,
        orgProductDetailTabsSectionItemDataProgress: true,
        orgProductDetailTabsSectionItemData,
      };

    case END_FETCH_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA: {
      const { success, error, userType, tabUid, itemsUIDs } = action.payload;
      let orgProductDetailTabsSectionItemData =
        state.orgProductDetailTabsSectionItemData;
      let orgProductDetailTabs = [...state.orgProductDetailTabs];

      try {
        if (itemsUIDs && itemsUIDs.length > 0) {
          itemsUIDs.forEach((itemUID) => {
            orgProductDetailTabsSectionItemData[itemUID] = {
              isLoading: false,
              error: null,
              data: null,
            };
            if (error === null && success[itemUID]) {
              orgProductDetailTabsSectionItemData[itemUID] = {
                isLoading: false,
                error: null,
                data: success[itemUID][0]
              };
            }
          });
        }
        const tabIndex = orgProductDetailTabs.findIndex(obj => obj.TabUID === tabUid);
        let OrgProductTabSections = orgProductDetailTabs[tabIndex].OrgProductTabSections;

        if (userType === "OrgUser") {
          OrgProductTabSections = OrgProductTabSections?.filter(obj => {
            let isShow = true;
            if (obj.IsDeletable) {
              isShow = false;
              obj.OrgProductTabItems?.forEach(item => {
                if (success[item.ItemUID] && success[item.ItemUID][0]) {
                  let itemObj = success[item.ItemUID][0];
                  let parsedObj = Validator.isJson(itemObj.Value) ? JSON.parse(itemObj.Value) : {};
                  if (parsedObj && parsedObj.data && parsedObj.data.length > 0) {
                    isShow = true;
                  }
                }
              })
            }
            if (obj.Permission === 'write') {
              isShow = true;
            }
            return isShow;
          });
          orgProductDetailTabs[tabIndex].OrgProductTabSections = OrgProductTabSections;
        }
      } catch (e) { }



      return {
        ...state,
        orgProductDetailTabsSectionItemDataProgress: false,
        orgProductDetailTabsSectionItemData,
        orgProductDetailTabs
      };
    }

    case START_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA: {
      const { itemUID } = action.payload;
      let orgProductDetailTabsSectionItemData =
        state.orgProductDetailTabsSectionItemData;
      orgProductDetailTabsSectionItemData = {
        ...orgProductDetailTabsSectionItemData,
        [itemUID]: {
          ...orgProductDetailTabsSectionItemData[itemUID],
          error: null,
        },
      };
      return {
        ...state,
        isUpdatingOrgProductDetailTabsSectionItemData: true,
        orgProductDetailTabsSectionItemData,
      };
    }

    case END_UPDATE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM_DATA: {
      const { itemUID, success, error } = action.payload;
      let orgProductDetailTabsSectionItemData =
        state.orgProductDetailTabsSectionItemData;
      orgProductDetailTabsSectionItemData = {
        ...orgProductDetailTabsSectionItemData,
        [itemUID]: {
          ...orgProductDetailTabsSectionItemData[itemUID],
          error: error,
        },
      };
      if (success !== null) {
        orgProductDetailTabsSectionItemData[itemUID].data = success[0];
      }
      return {
        ...state,
        isUpdatingOrgProductDetailTabsSectionItemData: false,
        orgProductDetailTabsSectionItemData,
      };
    }
    case START_ADD_ORG_CONTACT_DETAILS_LINK:
      return {
        ...state,
        orgAddContactDetailLinkProgress: true,
      };

    case END_ADD_ORG_CONTACT_DETAILS_LINK:
      var links = [...state.contactDetailLinks];
      links.push(action.payload.success);
      return {
        ...state,
        orgAddContactDetailLinkProgress: false,
        contactDetailLinks: links,
      };

    case START_DELETE_ORG_CONTACT_DETAIL_LINK:
      return {
        ...state,
        orgDeleteContactDetailLinkProgress: true,
      };

    case END_DELETE_ORG_CONTACT_DETAIL_LINK:
      var clinks = [...state.contactDetailLinks];
      var link_id = action.payload.link_id;
      let index = clinks.findIndex((x) => x.ID === link_id);
      clinks.splice(index, 1);
      return {
        ...state,
        orgDeleteContactDetailLinkProgress: false,
        contactDetailLinks: clinks,
      };

    case START_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM:
      return {
        ...state,
        deleteOrgProductDetailTabsSectionItemProgress: true,
      };

    case END_DELETE_ORG_PRODUCT_DETAILS_TAB_SECTION_ITEM: {
      const { success, tabUid, sectionUid, itemUid } = action.payload;
      let orgProductDetailTabs_Dta = state.orgProductDetailTabs;
      if (success !== null) {
        const tabIndex = orgProductDetailTabs_Dta.findIndex(
          (obj) => obj.TabUID === tabUid
        );
        const OrgProductTabSections = orgProductDetailTabs_Dta[tabIndex].OrgProductTabSections;
        const sectionIndex = OrgProductTabSections.findIndex(
          (obj) => obj.SectionUID === sectionUid
        );
        const OrgProductTabItems = OrgProductTabSections[sectionIndex].OrgProductTabItems;
        const itemIndex = OrgProductTabItems.findIndex(
          (obj) => obj.ItemUID === itemUid
        );
        OrgProductTabItems.splice(itemIndex, 1);
        OrgProductTabSections[sectionIndex].OrgProductTabItems = OrgProductTabItems;
        orgProductDetailTabs_Dta[tabIndex].OrgProductTabSections = OrgProductTabSections;
      }

      return {
        ...state,
        orgProductDetailTabs: orgProductDetailTabs_Dta,
        deleteOrgProductDetailTabsSectionItemProgress: false,
      };
    }
    case START_FETCH_PRODUCT_PRICE:
      return {
        ...state,
        productPriceProg: true,
        productPriceError: null,
      };

    case END_FETCH_PRODUCT_PRICE:
      let productPrice = state.productPrice;
      if (action.payload.error === null) {
        productPrice = action.payload.success.data;
      }
      return {
        ...state,
        productPrice,
        productPriceProg: false,
        productPriceError: null,
      };

    case SHOW_ADD_PRODUCT_TEAMS_DIALOG:
      return {
        ...state,
        addProductTeamsDialogOpen: true,
        selectAddProductTeamId: action.payload.id,
        selectAddProductTeamType: action.payload.type,
        selectAddProductTeamData: action.payload.data,
      };

    case HIDE_ADD_PRODUCT_TEAMS_DIALOG:
      return {
        ...state,
        addProductTeamsDialogOpen: false,
        selectAddProductTeamId: null,
        selectAddProductTeamType: null,
        selectAddProductTeamData: null
      };

    case START_FETCH_PRODUCT_TAB_TEAMS:
      return {
        ...state,
        startFetchProductTabTeams: true,
        productTabTeams: [],
      };

    case END_FETCH_PRODUCT_TAB_TEAMS:
      return {
        ...state,
        startFetchProductTabTeams: false,
        productTabTeams: action.payload.success,
      };

    case START_FETCH_PRODUCT_SECTION_TEAMS:
      return {
        ...state,
        startFetchProductSectionTeams: true,
        productSectionTeams: [],
      };

    case END_FETCH_PRODUCT_SECTION_TEAMS:
      return {
        ...state,
        startFetchProductSectionTeams: false,
        productSectionTeams: action.payload.success,
      };

    case START_ADD_PRODUCT_TAB_TEAMS:
      return {
        ...state,
        addProductTabTeamsProgress: true,
        addProductTabTeamsError: null,
      };

    case END_ADD_PRODUCT_TAB_TEAMS:
      return {
        ...state,
        addProductTabTeamsProgress: false,
        addProductTabTeamsError: action.payload.error,
      };

    case START_ADD_PRODUCT_SECTION_TEAMS:
      return {
        ...state,
        addProductSectionTeamsProgress: true,
        addProductSectionTeamsError: null,
      };

    case END_ADD_PRODUCT_SECTION_TEAMS:
      let sectionDataAdd = state.selectAddProductTeamData;
      let orgProductDetailTabsA = state.orgProductDetailTabs;
      if (success !== null) {
        const tabIndex = orgProductDetailTabsA.findIndex(
          (obj) => obj.ID === sectionDataAdd.TabID
        );
        let OrgProductTabSections = orgProductDetailTabsA[tabIndex].OrgProductTabSections;
        const sectionIndex = OrgProductTabSections.findIndex(
          (obj) => obj.SectionUID === sectionDataAdd.SectionUID
        );
        let section = OrgProductTabSections[sectionIndex];
        if (section.Teams === null) {
          section.Teams = [];
          section.Teams.push(action.payload.success);
        } else {
          section.Teams.push(action.payload.success);
        }
        OrgProductTabSections[sectionIndex] = section;
        orgProductDetailTabsA[tabIndex].OrgProductTabSections = OrgProductTabSections;
      }
      return {
        ...state,
        orgProductDetailTabs: orgProductDetailTabsA,
        addProductSectionTeamsProgress: false,
        addProductSectionTeamsError: action.payload.error,
      };

    case START_DELETE_PRODUCT_TAB_TEAMS:
      return {
        ...state,
        deleteProductTabTeamProgress: true,
        deleteProductTabTeamError: null,
      };

    case END_DELETE_PRODUCT_TAB_TEAMS:
      return {
        ...state,
        deleteProductTabTeamProgress: false,
        deleteProductTabTeamError: action.payload.error,
      };

    case START_DELETE_PRODUCT_SECTION_TEAMS:
      return {
        ...state,
        deleteProductSectionTeamProgress: true,
        deleteProductSectionTeamError: null,
      };

    case END_DELETE_PRODUCT_SECTION_TEAMS:
      // console.log(state.selectAddProductTeamData, 'selectAddProductTeamData')
      let sectionData = state.selectAddProductTeamData;
      let deleteTeamId = action.payload.teamid;
      let orgProductDetailTabsU = state.orgProductDetailTabs;
      if (success !== null) {
        // console.log(orgProductDetailTabsU, 'orgProductDetailTabsU')
        const tabIndex = orgProductDetailTabsU.findIndex(
          (obj) => obj.ID === sectionData.TabID
        );
        let OrgProductTabSections = orgProductDetailTabsU[tabIndex].OrgProductTabSections;
        const sectionIndex = OrgProductTabSections.findIndex(
          (obj) => obj.SectionUID === sectionData.SectionUID
        );
        let section = OrgProductTabSections[sectionIndex];
        const teamIndx = section.Teams.findIndex((obj) => obj.ID === deleteTeamId);
        section.Teams.splice(teamIndx, 1);
        // console.log(section, 'FromSection')
        OrgProductTabSections[sectionIndex] = section;
        orgProductDetailTabsU[tabIndex].OrgProductTabSections = OrgProductTabSections;
      }
      return {
        ...state,
        orgProductDetailTabs: orgProductDetailTabsU,
        deleteProductSectionTeamProgress: false,
        deleteProductSectionTeamError: action.payload.error,
      };

    case START_FETCH_ORG_PRODUCT_TAB_ITEMS:
      return {
        ...state,
        fetchOrgProductTabItems: true,
        productTabItems: []
      }

    case END_FETCH_ORG_PRODUCT_TAB_ITEMS:
      return {
        ...state,
        fetchOrgProductTabItems: false,
        productTabItems: action.payload.success
      }
    case END_PRODUCT_EXT_COLLABORATOR_ADD:

      let orgProductDetailTabsUser = [...state.orgProductDetailTabs];
      if (action.payload.success !== null) {
        var tabId = action.payload.additionalData.tabId;
        var sectionId = action.payload.additionalData.sectionId;
        const tabIndex = orgProductDetailTabsUser.findIndex(
          (obj) => obj.ID === tabId
        );
        let OrgProductTabSections = orgProductDetailTabsUser[tabIndex].OrgProductTabSections;
        const sectionIndex = OrgProductTabSections.findIndex(
          (obj) => obj.ID === sectionId
        );
        let section = OrgProductTabSections[sectionIndex];
        if (section.ExternalUsers === null) {
          section.ExternalUsers = [];
          section.ExternalUsers.push(...action.payload.success.Data);
        } else {
          section.ExternalUsers.splice(0, section.ExternalUsers.length, ...action.payload.success.Data);
        }
        OrgProductTabSections[sectionIndex] = section;
        orgProductDetailTabsUser[tabIndex].OrgProductTabSections = OrgProductTabSections;
      }
      return {
        ...state,
        orgProductDetailTabs: orgProductDetailTabsUser,
      };

    case END_PRODUCT_EXT_COLLABORATOR_REMOVE:
      let userId = action.payload.userId;
      let orgProductDetailTabsUserUpdated = state.orgProductDetailTabs;
      if (action.payload.success !== null) {
        tabId = action.payload.additionalData.tabId;
        sectionId = action.payload.additionalData.sectionId;
        const tabIndex = orgProductDetailTabsUserUpdated.findIndex(
          (obj) => obj.ID === tabId
        );
        let OrgProductTabSections = orgProductDetailTabsUserUpdated[tabIndex].OrgProductTabSections;
        const sectionIndex = OrgProductTabSections.findIndex(
          (obj) => obj.ID === sectionId
        );
        let section = OrgProductTabSections[sectionIndex];
        const userIndex = section.ExternalUsers.findIndex((obj) => obj.ID === userId);
        if (userIndex > -1) {
          section.ExternalUsers.splice(userIndex, 1);
        }
        OrgProductTabSections[sectionIndex] = section;
        orgProductDetailTabsUserUpdated[tabIndex].OrgProductTabSections = OrgProductTabSections;
      }
      return {
        ...state,
        orgProductDetailTabs: orgProductDetailTabsUserUpdated,
      }

    case START_ADD_NEW_CONTRACT_DETAILS:
      return {
        ...state,
        addContractProgress: true,
        addContractError: null,
        addContractSuccess: null,
      }

    case END_ADD_NEW_CONTRACT_DETAILS:
      const errData = action.payload.error;
      let addError = null;
      const orgFinanceDetails = state.orgFinanceDetails;
      if (errData) {
        addError = error;
      } else {
        if (action.payload.success !== null) {
          orgFinanceDetails.push(action.payload.success);
        }
      }
      return {
        ...state,
        addContractProgress: false,
        orgFinanceDetails: orgFinanceDetails,
        addContractError: addError,
        addContractSuccess: action.payload.success,
      }


    case START_UPDATE_ORG_DOCUMENT: {
      return {
        ...state,
        updateOrgDocumentProgress: true,
        updateOrgDocumentError: null,
        updateOrgDocumentSuccess: null,
      }
    }

    case END_UPDATE_ORG_DOCUMENT: {
      return {
        ...state,
        updateOrgDocumentProgress: false,
        updateOrgDocumentError: action.payload.error,
        updateOrgDocumentSuccess: action.payload.success,
      }
    }

    case START_GET_ORG_DOCUMENT_DETAILS: {
      return {
        ...state,
        getOrgDocumentDetailsProgress: true,
        getOrgDocumentDetailsError: null,
        getOrgDocumentDetailsSuccess: null,
      }
    }

    case END_GET_ORG_DOCUMENT_DETAILS: {
      return {
        ...state,
        getOrgDocumentDetailsProgress: false,
        getOrgDocumentDetailsError: action.payload.error,
        getOrgDocumentDetailsSuccess: action.payload.success,
      }
    }

    case START_UPDATE_DOCUMENT_FILE: {
      return {
        ...state,
        attachDocumentProgress: true,
        attachDocumentError: null,
        attachDocumentSuccess: null,
      }
    }

    case END_UPDATE_DOCUMENT_FILE: {
      return {
        ...state,
        attachDocumentProgress: false,
        attachDocumentError: action.payload.error,
        attachDocumentSuccess: action.payload.success,
      }
    }
    case SHOW_ADD_ORG_PRODUCT_WORKFLOW_DIALOG:
      return {
        ...state,
        dialogOrgProductWorkflowState: true,
        dialogOrgProductWorkflow: action.payload,
      };
    case HIDE_ADD_ORG_PRODUCT_WORKFLOW_DIALOG:
      return {
        ...state,
        dialogOrgProductWorkflowState: false,
        dialogOrgProductWorkflow: {},
      };
    default:
      return state;
  }
}
