import { Types } from "./types";

const initialState = {
  _createClusterGroupDialog: false,
  _createClusterGroupDialogIsnew: false,
  _createClusterGroupDialogDetails: {},
  _clusterProductsDialog: false,
  _clusterProductsDialogIsNew: false,

  // create cluster group
  createClusterGroupInprogress: false,
  createClusterGroupSuccess: null,
  createClusterGroupError: null,

  // update cluster group
  updateClusterGroupInprogress: false,
  updateClusterGroupSuccess: null,
  updateClusterGroupError: null,

  // fetch cluster group
  fetchClusterGroupInprogress: false,
  fetchClusterGroupSuccess: null,
  fetchClusterGroupError: null,

  // add product to cluster group
  addProductInClusterGroupInprogress: false,
  addProductInClusterGroupSuccess: null,
  addProductInClusterGroupError: null,

  // fetch cluster group products
  fetchClusterGroupProductsInprogress: false,
  fetchClusterGroupProductsSuccess: null,
  fetchClusterGroupProductsError: null,

  // delete products in cluster group
  deleteClusterGroupProductsInprogress: false,
  deleteClusterGroupProductsSuccess: null,
  deleteClusterGroupProductsError: null,

  // delete cluster group
  deleteClusterGroupInprogress: false,
  deleteClusterGroupSuccess: null,
  deleteClusterGroupError: null,

  storeClusterGroupProducts: [],
};

export function clusterProductReducer(state = initialState, action) {
  switch (action.type) {
    case Types.TEMP_STORE_CLUSTER_GROUPING_PRODUCTS: {
      let products = action.payload.products;
      let result = {};
      result.storeClusterGroupProducts = products;
      return {
        ...state,
        ...result,
      };
    }

    case Types.SHOW_CREATE_CLUSTER_DIALOG: {
      let is_new = action.payload.is_new;
      let group_details = action.payload.group_details;
      let result = {};
      result._createClusterGroupDialog = true;
      result._createClusterGroupDialogIsnew = is_new;
      result._createClusterGroupDialogDetails = group_details;
      return {
        ...state,
        ...result,
      };
    }

    case Types.HIDE_CREATE_CLUSTER_DIALOG: {
      let result = {};
      result._createClusterGroupDialog = false;
      result._createClusterGroupDialogIsnew = false;
      result._createClusterGroupDialogDetails = {};
      return {
        ...state,
        ...result,
      };
    }

    case Types.SHOW_CLUSTER_PRODUCTS_DIALOG: {
      let is_new = action.payload.is_new;
      let existing_products = action.payload.existing_products;
      let result = {};
      result._clusterProductsDialog = true;
      result._clusterProductsDialogIsNew = is_new;
      result.addProductInClusterGroupSuccess = null;
      result._clusterProductsDialogExistingProducts = existing_products;
      return {
        ...state,
        ...result,
      };
    }

    case Types.HIDE_CLUSTER_PRODUCTS_DIALOG: {
      let result = {};
      result._clusterProductsDialog = false;
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_CREATE_PRODUCT_CLUSTER_GROUP:
      return {
        ...state,
        createClusterGroupInprogress: true,
        createClusterGroupSuccess: null,
        createClusterGroupError: null,
      };

    case Types.END_CREATE_PRODUCT_CLUSTER_GROUP: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};
      result.createClusterGroupInprogress = false;
      if (error) {
        result.createClusterGroupSuccess = null;
        result.createClusterGroupError = error.message;
      } else {
        result.createClusterGroupSuccess = success;
        result.createClusterGroupError = null;
      }
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_UPDATE_PRODUCT_CLUSTER_GROUP:
      return {
        ...state,
        updateClusterGroupInprogress: false,
        updateClusterGroupSuccess: null,
        updateClusterGroupError: null,
      };

    case Types.END_UPDATE_PRODUCT_CLUSTER_GROUP: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};
      result.updateClusterGroupInprogress = false;
      if (error) {
        result.updateClusterGroupSuccess = null;
        result.updateClusterGroupError = error.message;
      } else {
        result.updateClusterGroupSuccess = success;
        result.updateClusterGroupError = null;
      }
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_FETCH_PRODUCT_CLUSTER_GROUPS:
      return {
        ...state,
        fetchClusterGroupInprogress: false,
        fetchClusterGroupSuccess: null,
        fetchClusterGroupError: null,
      };

    case Types.END_FETCH_PRODUCT_CLUSTER_GROUPS: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};
      result.fetchClusterGroupInprogress = false;
      if (error) {
        result.fetchClusterGroupSuccess = null;
        result.fetchClusterGroupError = error.message;
      } else {
        result.fetchClusterGroupSuccess = success;
        result.fetchClusterGroupError = null;
      }
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_ADD_PRODUCTS_TO_CLUSTER_GROUP:
      return {
        ...state,
        addProductInClusterGroupInprogress: true,
        addProductInClusterGroupSuccess: null,
        addProductInClusterGroupError: null,
      };

    case Types.END_ADD_PRODUCTS_TO_CLUSTER_GROUP: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};
      result.addProductInClusterGroupInprogress = false;
      if (error) {
        result.addProductInClusterGroupSuccess = null;
        result.addProductInClusterGroupError = error.message;
      } else {
        result.addProductInClusterGroupSuccess = success;
        result.addProductInClusterGroupError = null;
      }
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_FETCH_CLUSTER_GROUP_PRODUCTS:
      return {
        ...state,
        fetchClusterGroupProductsInprogress: true,
        fetchClusterGroupProductsSuccess: null,
        fetchClusterGroupProductsError: null,
      };

    case Types.END_FETCH_CLUSTER_GROUP_PRODUCTS: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};
      result.fetchClusterGroupProductsInprogress = false;
      if (error) {
        result.fetchClusterGroupProductsSuccess = null;
        result._clusterProductsDialogExistingProducts = [];
        result.fetchClusterGroupProductsError = error.message;
      } else {
        result.fetchClusterGroupProductsSuccess = success;
        result._clusterProductsDialogExistingProducts = success.products || [];
        result.fetchClusterGroupProductsError = null;
      }
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_DELETE_PRODUCT_IN_CLUSTER_GROUP:
      return {
        ...state,
        deleteClusterGroupProductsInprogress: false,
        deleteClusterGroupProductsSuccess: null,
        deleteClusterGroupProductsError: null,
      };

    case Types.END_DELETE_PRODUCT_IN_CLUSTER_GROUP: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};
      result.deleteClusterGroupProductsInprogress = false;
      if (error) {
        result.deleteClusterGroupProductsSuccess = null;
        result.deleteClusterGroupProductsError = error.message;
      } else {
        result.deleteClusterGroupProductsSuccess = success;
        result.deleteClusterGroupProductsError = null;
      }
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_DELETE_PRODUCT_CLUSTER_GROUP:
      return {
        ...state,
        deleteClusterGroupInprogress: true,
        deleteClusterGroupSuccess: null,
        deleteClusterGroupError: null,
      };

    case Types.END_DELETE_PRODUCT_CLUSTER_GROUP: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};
      result.deleteClusterGroupInprogress = false;
      if (error) {
        result.deleteClusterGroupSuccess = null;
        result.deleteClusterGroupError = error.message;
      } else {
        result.deleteClusterGroupSuccess = success;
        result.deleteClusterGroupError = null;
      }
      return {
        ...state,
        ...result,
      };
    }

    default:
      return state;
  }
}
