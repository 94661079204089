import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import CommonCss from 'commonCss';
import { withRouter } from "react-router-dom";
import {
  Dialog, DialogContent, DialogActions, Slide, DialogTitle,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import SqureButton from 'Components/Common/SqureButton';

import FoldersTreeSelect from "./FoldersTreeSelect";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
  contractFoldersData: state.orgProduct.contractFoldersData,
});

const connectionActions = {
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
  ...CommonCss.ProductDetails,
  formControlDialog: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  formControl: {
    ...CommonCss.formControl,
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 80
  },
  formGroupDialog: {
    marginTop: 5,
    marginBottom: 16,
    '& input': {
      color: '#6C6C6C !important'
    },
    "& .p-treeselect": {
      fontFamily: 'Poppins',
      width: '100%',
    }
  },
  filterBtn: {
    background: '#fff',
    border: '1px solid #888',
    borderRadius: 4,
    minWidth: 200,
    color: '#222',
    boxShadow: 'none',
    padding: '4px 20px',
    fontSize: 16,
    '&:hover': {
      background: '#fff',
      color: '#222'
    }
  },
});

const AddFileToFolderDialog = (props) => {
  const [parentfolder, setParentFolder] = useState(null)
  const { classes, isOpen, onSubmit, onClose } = props;

  useEffect(() =>{
    if(props.data?.parentNode?.id){
      setParentFolder({id: props.data.parentNode?.id, label: props.data.parentNode?.label});
    }
  },[])

  const handleSubmit = () => {
    onSubmit(props.data,parentfolder?.id || "")
    setParentFolder(null)
    onClose()
  }

  return (
    <Dialog
      onClose={()=>{
        onClose();
      }}
      aria-labelledby="AddUserDialog"
      open={isOpen}
      TransitionComponent={Transition}
      scroll={"body"}
      fullWidth={true}
      className={classes.dialog}
      id={`add-file-to-folder`}
    >
      <DialogTitle id={`form-dialog-title-add-file`}>
        Move {props.data.Title ? props.data.Title : "Document" } to folder
      </DialogTitle>
      <DialogContent>
        <div className={classes.formContainer}>
          {props.contractFoldersData?.meta?.length > 0 ? <div className={classes.formGroupDialog}>
            <FoldersTreeSelect handleChange={(value)=>setParentFolder(value)} value={parentfolder}/>
          </div> : <div>No Folder Available</div>}
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: '10px 24px' }}>
          <SqureButton
            variant={"outlined"}
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </SqureButton>
          &nbsp; &nbsp; &nbsp;
          {props.contractFoldersData?.meta?.length > 0 && <SqureButton
            variant={"contained"}
            onClick={() => {
              handleSubmit()
            }}
          >
            Save
          </SqureButton>}
        </div>
      </DialogActions>
    </Dialog >
  )
}

export default connector(
  compose(withRouter, withStyles(styles))(AddFileToFolderDialog)
);
