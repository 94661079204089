import React, { Component } from 'react'
import { connect } from "react-redux";
import { compose } from "recompose";
import { generate } from 'shortid';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withStyles, createStyles } from "@material-ui/core/styles"
import {
  Grid, Tabs, Tab, Popover, List, ListItem, ListItemIcon,
  Typography, Divider, IconButton, CircularProgress
} from "@material-ui/core";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import deleteIcon from "assets/images/delete.svg";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AddIcon from '@material-ui/icons/Add';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CommonCss from 'commonCss';
import MessageDialog from "Components/Common/MessageDialog";
import CloneCatalogSectionDialog from "Components/Common/CloneCatalogSectionDialog";
import Image from "Components/Common/image.jsx";
import SqureButton from 'Components/Common/SqureButton';
import AppBarV2 from "Components/Application/AppBarV2";
import SearchTeamsPoper from "./dialogs/SearchTeamsPoper";
import classnames from "classnames";
import {
  hideMessageDialog, showMessageDialog
} from "redux/applications/action";

import {
  addOrgVendorDetailsTab, updateOrgVendorDetailsTab,
  deleteOrgVendorDetailsTab, fetchOrgCatalogAllTabs,
  addOrgVendorDetailsTabSection, deleteOrgVendorDetailsTabSection,
  updateOrgVendorDetailsTabSection, addOrgVendorDetailsTabSectionItem,
  updateOrgVendorDetailsTabSectionItem, updateOrgVendorDetailsTabSequence,
  updateOrgVendorDetailsTabSectionSequence, deleteOrgVendorDetailsTabSectionItem,
} from "redux/vendor/vendorCatalog/action";
import {
  showAddProductTeamsDialog, hideAddProductTeamsDialog
} from 'redux/product/orgProduct/action'
import { updateDrawerStyle } from "redux/drawer/action";
import AddTabDialog from './AddTabDialog.jsx'
import FormBuilder from './FormBuilder.jsx'
import { showSnackBar } from "redux/snackbar/action";
import { fetchVisualization } from "redux/visualization/action";
import { showCloneCatalogSectionDialog, hideCloneCatalogSectionDialog } from "redux/vendor/action";
import { getOrgWorkflow } from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
  orgCatalogAllTabs: state.vendorCatalog.orgCatalogAllTabs,
  isLoading: state.vendorCatalog.orgCatalogAllTabsProgress,
  addOrgVendorDetailTabsProgress: state.vendorCatalog.addOrgVendorDetailTabsProgress,
  addOrgVendorDetailTabsError: state.vendorCatalog.addOrgVendorDetailTabsError,
  updateOrgVendorDetailTabsProgress: state.vendorCatalog.updateOrgVendorDetailTabsProgress,
  updateOrgVendorDetailTabsError: state.vendorCatalog.updateOrgVendorDetailTabsError,
  deleteOrgVendorDetailTabsProgress: state.vendorCatalog.deleteOrgVendorDetailTabsProgress,
  deleteOrgVendorDetailTabsError: state.vendorCatalog.deleteOrgVendorDetailTabsError,
  addOrgVendorDetailTabsSectionProgress: state.vendorCatalog.addOrgVendorDetailTabsSectionProgress,
  addOrgVendorDetailTabsSectionSuccess: state.vendorCatalog.addOrgVendorDetailTabsSectionSuccess,
  addOrgVendorDetailTabsSectionError: state.vendorCatalog.addOrgVendorDetailTabsSectionError,
  updateOrgVendorDetailTabsSectionError: state.vendorCatalog.updateOrgVendorDetailTabsSectionError,
  deleteOrgVendorDetailTabsSectionProgress: state.vendorCatalog.deleteOrgVendorDetailTabsSectionProgress,
  updateOrgVendorDetailTabsSectionProgress: state.vendorCatalog.updateOrgVendorDetailTabsSectionProgress,
  addOrgVendorDetailTabsSectionItemProgress: state.vendorCatalog.addOrgVendorDetailTabsSectionItemProgress,
  addOrgVendorDetailTabsSectionItemError: state.vendorCatalog.addOrgVendorDetailTabsSectionItemError,
  updateOrgVendorDetailTabsSectionItemProgress: state.vendorCatalog.updateOrgVendorDetailTabsSectionItemProgress,
  updateOrgVendorDetailTabsSectionItemError: state.vendorCatalog.updateOrgVendorDetailTabsSectionItemError,
  deleteOrgVendorDetailTabsSectionItemProgress: state.vendorCatalog.deleteOrgVendorDetailTabsSectionItemProgress,
  deleteOrgVendorDetailTabsSectionItemError: state.vendorCatalog.deleteOrgVendorDetailTabsSectionItemError,
  addProductTeamsDialogOpen: state.orgProduct.addProductTeamsDialogOpen,
  selectAddProductTeamId: state.orgProduct.selectAddProductTeamId,
  selectAddProductTeamType: state.orgProduct.selectAddProductTeamType,
  deleteProductSectionTeamProgress: state.orgProduct.deleteProductSectionTeamProgress,
  deleteProductSectionTeamError: state.orgProduct.deleteProductSectionTeamError,
  addProductSectionTeamsProgress: state.orgProduct.addProductSectionTeamsProgress,
  addProductSectionTeamsError: state.orgProduct.addProductSectionTeamsError,

  visualizations: state.visualization.visualizations,
  visualizationFetchProgress: state.visualization.visualizationFetchProgress,
  visualizationFetchError: state.visualization.visualizationFetchError,

  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
  openCloneCatalogSectionDialog: state.vendors.openCloneCatalogSectionDialog,
});

const connectionActions = {
  fetchOrgCatalogAllTabs: fetchOrgCatalogAllTabs,
  addOrgVendorDetailsTab: addOrgVendorDetailsTab,
  updateOrgVendorDetailsTab: updateOrgVendorDetailsTab,
  deleteOrgVendorDetailsTab: deleteOrgVendorDetailsTab,
  addOrgVendorDetailsTabSection: addOrgVendorDetailsTabSection,
  deleteOrgVendorDetailsTabSection: deleteOrgVendorDetailsTabSection,
  updateOrgVendorDetailsTabSection: updateOrgVendorDetailsTabSection,
  addOrgVendorDetailsTabSectionItem: addOrgVendorDetailsTabSectionItem,
  updateOrgVendorDetailsTabSectionItem: updateOrgVendorDetailsTabSectionItem,
  updateOrgVendorDetailsTabSequence: updateOrgVendorDetailsTabSequence,
  updateOrgVendorDetailsTabSectionSequence: updateOrgVendorDetailsTabSectionSequence,
  deleteOrgVendorDetailsTabSectionItem: deleteOrgVendorDetailsTabSectionItem,
  updateDrawerStyle: updateDrawerStyle,
  showAddProductTeamsDialog: showAddProductTeamsDialog,
  hideAddProductTeamsDialog: hideAddProductTeamsDialog,
  showSnackBar: showSnackBar,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  fetchVisualization: fetchVisualization,
  getOrgWorkflow: getOrgWorkflow,
  showCloneCatalogSectionDialog: showCloneCatalogSectionDialog,
  hideCloneCatalogSectionDialog: hideCloneCatalogSectionDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    backgroundColor: '#FFF',
    minHeight: '100vh',
  },
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  mb16: {
    marginBottom: 16,
  },
  formGroup: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  ...CommonCss.ProductDetails,
  leftSection: {
    width: 200,
    minWidth: 200,
  },
  rightSection: {
    flex: 1,
    paddingLeft: 40
  },
  tabs: {
    "& [class*='MuiTab-root']": {
      padding: '0 6px',
      maxWidth: 'max-content',
      width: 'max-content'
    },
    "border-bottom": '1px solid #d8d8d8',
  },
  tabContainer: {
    paddingTop: theme.spacing(3)
  },
  container: {
    marginTop: 81
  },
  "popoverText": {
    color: "#74798C"
  },
  listItem: {
    paddingTop: theme.spacing(2),
  },
  listIcon: {
    minWidth: theme.spacing(5),
  },
  diabled: {
    opacity: 0.4
  },
  editIcon: {
    color: "#4A87F8",
    verticalAlign: "middle",
    cursor: 'pointer'
  },
  deleteImage: {
    cursor: "pointer",
  },
  rootContainer: CommonCss.rootContainer
})

class VendorAndServiceCatalog extends Component {
  constructor(props) {
    super()
    this.state = {
      tab: 0,
      openAddTabDialog: false,
      activeSection: null,
      editTab: null,
      open: null,
      anchorEl: null,
      activeNewSection: null,
      typeOfVisibility: null,
      newTabSection: null,
      dragFromSeq: null,
      dragTabId: null,
      isDrapSection: false,
      deletingTab: 0,
      deleteTabId: null,
      isUpdateHappen: false,
      isUpdateItemHappen: false,
      isAddItemHappen: false,
      addTeamsDialogOpen: false,
      selectedTeams: [],
      addTeamsDialogType: null,
      addTeamsDialogTypeData: null,
    }
    this.onSectionDragEnd = this.onSectionDragEnd.bind(this)
    this.drop = this.drop.bind(this)
    this.drag = this.drag.bind(this)
  }
  componentDidMount() {
    if (this.props.type) {
      this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 });
      this.props.fetchOrgCatalogAllTabs(this.props.type)
      this.props.fetchVisualization({
        query: '',
        status: "",
        forView: true,
      });
      setTimeout(() => {
        this.props.updateDrawerStyle(false, true);
      }, 100);
    } else {
      this.props.showSnackBar("something went wrong, please try again.", "error", 3000);
      this.props.history.push('/app/settings/configurations')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      addOrgVendorDetailTabsProgress, updateOrgVendorDetailTabsProgress,
      addOrgVendorDetailTabsError, updateOrgVendorDetailTabsError,
      deleteOrgVendorDetailTabsProgress, deleteOrgVendorDetailTabsError, orgCatalogAllTabs,
      addOrgVendorDetailTabsSectionProgress,
      addOrgVendorDetailTabsSectionSuccess,
      updateOrgVendorDetailTabsSectionProgress, isLoading, addOrgVendorDetailTabsSectionItemProgress,
      deleteOrgVendorDetailTabsSectionItemProgress,
      deleteOrgVendorDetailTabsSectionProgress,
      deleteOrgVendorDetailTabsSectionError
    } = this.props;

    if (!this.state.openAddTabDialog && prevState.openAddTabDialog) {
      this.setState({ anchorEl: null, open: null })
    }

    if (this.props.deleteProductSectionTeamProgress === false && prevProps.deleteProductSectionTeamProgress === true) {
      if (this.props.deleteProductSectionTeamError === null) {
        this.setState({
          isUpdateHappen: this.state.isUpdateHappen ? false : true
        })
      }
    }

    if (this.props.addProductSectionTeamsProgress === false && prevProps.addProductSectionTeamsProgress === true) {
      if (this.props.addProductSectionTeamsError === null) {
        this.setState({
          isUpdateHappen: this.state.isUpdateHappen ? false : true
        })
      }
    }

    if (!isLoading && prevProps.isLoading) {
      this.setState({
        dragFromSeq: null,
        dragTabId: null,
        isDrapSection: false,
      })
    }
    if (!addOrgVendorDetailTabsProgress && prevProps.addOrgVendorDetailTabsProgress) {
      if (addOrgVendorDetailTabsError) {
        this.props.showSnackBar("Failed to add tab", "error", 3000);
      } else {
        this.setState({
          tab: orgCatalogAllTabs.length - 1,
          activeNewSection: null,
        });
        this.props.showSnackBar("Tab added successfully.", "success", 3000);
      }
    }

    if (!updateOrgVendorDetailTabsProgress && prevProps.updateOrgVendorDetailTabsProgress) {
      if (updateOrgVendorDetailTabsError) {
        this.props.showSnackBar("Failed to update", "error", 3000);
      } else {
        this.setState({
          typeOfVisibility: null
        });
        this.props.showSnackBar("Tab updated successfully.", "success", 3000);
      }
    }

    if (!deleteOrgVendorDetailTabsProgress && prevProps.deleteOrgVendorDetailTabsProgress) {
      if (deleteOrgVendorDetailTabsError) {
        this.props.showSnackBar("Failed to delete", "error", 3000);
      } else {
        this.setState({
          tab: this.state.deletingTab > this.state.tab
            ? this.state.tab
            : this.state.tab - 1,
          editTab: null,
          open: false,
          anchorEl: null
        });
        this.props.showSnackBar("Tab deleted successfully.", "success", 3000);
      }
    }
    if (!addOrgVendorDetailTabsSectionItemProgress && prevProps.addOrgVendorDetailTabsSectionItemProgress) {
    }

    if (!addOrgVendorDetailTabsSectionProgress && prevProps.addOrgVendorDetailTabsSectionProgress && addOrgVendorDetailTabsSectionSuccess) {
      this.props.showSnackBar("Section added successfully.", "success", 3000);
      this.props.hideCloneCatalogSectionDialog();
      // const data = this.state.newTabSection;
      // const items = data?.items || []
      // items.forEach((item, k) => {
      //   this.props.addOrgVendorDetailsTabSectionItem(
      //     data.tabId, addOrgVendorDetailTabsSectionSuccess.id,
      //     {
      //       name: item.name,
      //       description: item.description,
      //       // visibility: item.visibility,
      //       type: item.type,
      //       additional_data: JSON.stringify(item.additional_data),
      //       sequence: k + 1,
      //     }
      //   )
      // })

      this.setState({
        newTabSection: null,
        activeSection: null,
        activeNewSection: null
      }, () => {
        fetchOrgCatalogAllTabs({ page: 1, page_size: 10 })
        setTimeout(() => {
          this.setState({
            isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
          })
        }, 3000);
      })
    }

    if (!deleteOrgVendorDetailTabsSectionProgress && prevProps.deleteOrgVendorDetailTabsSectionProgress === true) {
      if (deleteOrgVendorDetailTabsSectionError) {
        this.props.showSnackBar("Failed to delete.", "error", 3000);
      } else {
        this.props.showSnackBar("Section deleted successfully.", "success", 3000);
      }
    }

    if (!deleteOrgVendorDetailTabsSectionItemProgress && prevProps.deleteOrgVendorDetailTabsSectionItemProgress === true) {
      this.setState({
        activeSection: null,
        isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
      });
    }

    if (!updateOrgVendorDetailTabsSectionProgress && prevProps.updateOrgVendorDetailTabsSectionProgress) {
      if (this.state.newTabSection === null) {
        this.props.showSnackBar("Section updated successfully.", "success", 3000)
      }
      if (!this.state.isDrapSection) {
        const data = this.state.newTabSection;
        const items = data?.items || []
        console.log(items, data, 'tabSectionapi');
        let addNewItems = []
        items.forEach((item, k) => {
          const newData = {
            name: item.name,
            description: item.description,
            visibility: item.visibility,
            type: item.type,
            additional_data: JSON.stringify(item.additional_data),
            sequence: k + 1
          }
          if (item?.isNew) {
             addNewItems.push(newData);
          } else {
            this.props.updateOrgVendorDetailsTabSectionItem(
              data.tabId, data.id, item.itemId, newData
            )
          }
        });
        if(addNewItems.length>0){
          this.props.addOrgVendorDetailsTabSectionItem(data.tabId, data.id, addNewItems)
        }
      }
      this.setState({
        newTabSection: null,
        activeSection: null,
        activeNewSection: null
      }, () => {
        setTimeout(() => {
          this.setState({
            isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
          })
        }, 3000);
        // fetchOrgCatalogAllTabs({page:1,page_size:10})
      })
    }
  }

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  handleAddTab = (data) => {
    const { editTab } = this.state
    if (editTab === null) {
      if (data?.name) {
        let seq = this.props.orgCatalogAllTabs?.length + 1 || 1
        const newTab = { name: data.name, visibility: data.visibility, sequence: seq, type: this.props.type }
        this.props.addOrgVendorDetailsTab(newTab)
      }
    } else {
      const updatedData = {
        name: data?.name,
        visibility: data?.visibility
      }
      this.props.updateOrgVendorDetailsTab(editTab.id, updatedData)
    }
    this.setState({
      editTab: null,
    })
  }

  handleDeleteTab = (deleteTabId, deletingTab) => {
    this.setState({ deletingTab, deleteTabId }, () => {
      this.setState({ anchorEl: null, open: null })
      this.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteTabId) {
      this.props.hideMessageDialog();
      this.props.deleteOrgVendorDetailsTab(this.state.deleteTabId)
    }
  };


  handleTabVisibility = (type, tab) => {
    let visibility = tab.visibility
    if (visibility.includes(type)) {
      let index = visibility.indexOf(type);
      visibility.splice(index, 1);
    } else {
      visibility.push(type)
    }
    const data = {
      visibility: visibility,
    }
    this.setState({
      typeOfVisibility: type,
    }, () => {
      this.props.updateOrgVendorDetailsTab(tab.id, data)
    })
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab, activeSection: null, activeNewSection: null, dragSection: null, isDrapSection: false })
  };

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(dragFromSeq, dragTabId) {
    this.setState({ dragFromSeq, dragTabId })
  }

  drop(ev) {
    const destinationId = parseInt(ev.target.id)
    const { dragFromSeq, dragTabId } = this.state;

    let firstIndex = this.props.orgCatalogAllTabs[destinationId - 1]?.sequence;
    let lastIndex = this.props.orgCatalogAllTabs[destinationId]?.sequence;
    
    if (destinationId === 0) {
      firstIndex = 0;
    } else if (destinationId === this.props.orgCatalogAllTabs.length) {
      lastIndex = this.props.orgCatalogAllTabs[destinationId]?.sequence + 1;
    }
    const newSeq = (firstIndex + lastIndex) / 2

    if (dragTabId && ![null, NaN, undefined].includes(destinationId) && destinationId !== dragFromSeq) {
      this.props.updateOrgVendorDetailsTabSequence(dragTabId, { sequence: newSeq }, this.props.type)
    }
  }

  handleCreateNewSection = (data, tabId) => {
    // const { newTabSection } = this.state
    // if (newTabSection === null) {
      const currentTab = this.props.orgCatalogAllTabs[this.state.tab];
      let seq = currentTab?.sections?.length + 1 || 1
      // this.setState({
      //   newTabSection: { ...data, tabId }
      // }, () => {
          this.props.addOrgVendorDetailsTabSection(
            tabId,
            [{
              name: data.name,
              description: "",
              visibility: ['admin','user'],
              sequence: seq,
              is_editable: true,
              items: data?.items?.map((item, k) => ({
                name: item.name,
                description: item.description,
                // visibility: item.visibility,
                type: item.type,
                additional_data: JSON.stringify(item.additional_data),
                sequence: k + 1,
              }))
            }]
          )
        
      // })
    // }
  }

  handleUpdateSection = (data, isCustom, tabId, id) => {
    const { newTabSection } = this.state
    console.log(newTabSection, 'tabSectionapi new')
    if (newTabSection === null) {
      this.setState({
        newTabSection: isCustom ? { ...data, tabId, id } : null
      }, () => {
        this.props.updateOrgVendorDetailsTabSection(
          tabId,
          id,
          {
            name: data.name,
            description: "",
            visibility: data.visibility
          }
        )
      })
    }
  }

  handleUpdateSectionVisibility = (data, tabId, id) => {
    const { newTabSection } = this.state
    if (newTabSection === null) {
      this.props.updateOrgVendorDetailsTabSection(
        tabId,
        id,
        {
          visibility: data.visibility
        }
      )
    }
  }

  handleDeleteSection = (tabId, id) => {
    this.setState({ activeSection: null }, () => {
      this.props.deleteOrgVendorDetailsTabSection(tabId, id)
    })
  }

  handleDeleteItem = (tabId, id, itemId) => {
    this.setState({
      activeSection: null,
    }, () => {
      this.props.deleteOrgVendorDetailsTabSectionItem(tabId, id, itemId)
    })
  }

  handleSaveSectionData = (index, data) => {
    const { tabs, tab } = this.state
    const tabView = tabs[tab]
    tabView.sections[index] = { ...tabView.sections[index], ...data }
    tabs[tab] = tabView
    this.setState({ tabs, activeSection: null })
  }

  onSectionDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const itemIndex = parseInt(draggableId)
      let destinationIndex = parseInt(destination.index);
      const sourceIndex = parseInt(source.index);
      const currentTab = this.props.orgCatalogAllTabs[this.state.tab]
      const dragedSection = currentTab.sections[itemIndex]

      if(destinationIndex>sourceIndex){
        destinationIndex += 1;
      }
      let firstIndex = currentTab.sections[destinationIndex - 1]?.sequence;
      let lastIndex = currentTab.sections[destinationIndex]?.sequence;
      
      if (destinationIndex === 0) {
        firstIndex = 0;
      } else if (destinationIndex === currentTab.sections.length) {
        lastIndex = firstIndex + 1;
      }

      const newSeq = (firstIndex + lastIndex) / 2
      this.setState({
        isDrapSection: true
      }, () => {
        this.props.updateOrgVendorDetailsTabSectionSequence(
          currentTab.id,
          dragedSection.id,
          {
            sequence: newSeq
          },
          this.props.type
        )
      })
    }
  }

  render() {

    const {
      classes, orgCatalogAllTabs, isLoading,
      addOrgVendorDetailTabsProgress, updateOrgVendorDetailTabsProgress,
      deleteOrgVendorDetailTabsProgress, addOrgVendorDetailTabsSectionProgress,
      deleteOrgVendorDetailTabsSectionProgress, updateOrgVendorDetailTabsSectionProgress,
      addOrgVendorDetailTabsSectionItemProgress, updateOrgVendorDetailTabsSectionItemProgress,
      deleteOrgVendorDetailTabsSectionItemProgress,
    } = this.props;

    const {
      tab, dragFromSeq, dragTabId,
      openAddTabDialog, activeSection,
      open, activeNewSection, typeOfVisibility, isDrapSection, isUpdateHappen, isUpdateItemHappen
    } = this.state;

    if (
      (isLoading && orgCatalogAllTabs.length === 0) ||
      (isLoading && orgCatalogAllTabs.length > 0 && dragFromSeq == null && dragTabId == null && !isDrapSection)
    ) {
      return (<Grid container alignItems="center" justify="center" style={{ height: "50vh" }}><CircularProgress /></Grid>)
    }

    if (orgCatalogAllTabs.length === 0) {
      return null
    }

    let tabs = (orgCatalogAllTabs && orgCatalogAllTabs.length > 0 && orgCatalogAllTabs) || []
    let tabView = []
    if (tabs.length > 0 && tabs[tab]?.sections && tabs[tab].sections.length) {
      tabView = tabs[tab].sections
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };
    let catalogType = "Vendor";
    if (this.props.type === "service") {
      catalogType = "Service";
    } else if (this.props.type === "vendorservice") {
      catalogType = "Vendor Service";
    }

    return (
      <div className={classes.root}>
        <AppBarV2
          title={catalogType + " Catalog"}
          withBack={true}
          dropdown={true}
        />
        <Grid container className={classnames(classes.container, classes.rootContainer)}>
          <div className={classes.leftSection}>
            <Image
              src="https://assets.expent.ai/Stark.png"
              style={{ maxWidth: "90%" }}
            />
            <DragDropContext onDragEnd={this.onSectionDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <ul className={classes.list}>
                      {![null, undefined, 'NaN'].includes(tabView) &&
                        tabView.filter(o => !(o.name && o.name?.length === 0))
                          .map((obj, index) => {
                            if (obj.name === null || obj.name === '' || obj.name.length <= 0) {
                              return null
                            }
                            return <Draggable key={String(index)} draggableId={String(index)} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <a href={`#${obj.name.toLowerCase().split(" ").join("-")}`}>
                                    <li className={classes.item} key={`section-list-${index}`}>
                                      <Grid container alignItems="center">
                                        <DragIndicatorOutlinedIcon id={index} style={{ color: '#D9D9D9' }} />
                                        <p style={{ flex: 1, padding: 0, margin: 0, wordBreak: 'break-word' }}>{obj.name}</p>
                                      </Grid>
                                    </li>
                                  </a>
                                </div>
                              )}
                            </Draggable>
                          })}
                    </ul>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className={classes.rightSection}>
            <Grid container alignItems="center" className={classes.mb16}>
              <Grid item xs="11">
                {(isLoading && dragFromSeq !== null && dragTabId !== null) ? (
                  <Grid container alignItems="center"><CircularProgress size={20} /></Grid>
                ) : (
                  <>
                    {tabs && tabs.length > 0 && (
                      <Tabs
                        value={tab}
                        onChange={this.handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        className={classes.tabs}
                      >
                        {tabs.map((obj, index) => (
                          <Tab
                            draggable="true"
                            onDrop={this.drop}
                            onDragOver={this.allowDrop}
                            onDragStart={() => this.drag(index, obj.id)}
                            key={index}
                            id={index}
                            label={
                              <Grid container alignItems="center" id={index} >
                                <DragIndicatorOutlinedIcon id={index} style={{ color: '#D9D9D9', marginRight: 10 }} />
                                <Grid item id={index}>
                                  {obj.name}
                                </Grid>
                                &nbsp;
                                <Grid item>
                                  <IconButton
                                    className={classes.threeDot}
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      this.setState({ open: index, anchorEl: event.currentTarget || event.current })
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </Grid>
                                <Popover
                                  id={index}
                                  open={open === index}
                                  anchorEl={this.state.anchorEl}
                                  onClose={() => this.setState({ anchorEl: null, open: null })}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}>
                                  <List>
                                    <ListItem
                                      classes={{
                                        button: classes.listItem,
                                      }}
                                      button
                                      onClick={e => {
                                        e.stopPropagation()
                                        this.handleTabVisibility('admin', obj)
                                      }}
                                    >
                                      {typeOfVisibility === "admin" && open && updateOrgVendorDetailTabsProgress && this.state.anchorEl ? (
                                        <Grid container justify="center">
                                          <CircularProgress size={20} />
                                        </Grid>
                                      ) : (
                                        <>
                                          <ListItemIcon classes={{ root: classes.listIcon }}>
                                            {obj.visibility?.includes('admin') ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                          </ListItemIcon>
                                          <Typography variant="inherit">
                                            Admin
                                          </Typography>
                                        </>
                                      )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                      classes={{
                                        button: classes.listItem,
                                      }}
                                      button
                                      onClick={e => {
                                        e.stopPropagation()
                                        this.handleTabVisibility('user', obj)
                                      }}
                                    >
                                      {typeOfVisibility === "user" && open && updateOrgVendorDetailTabsProgress && this.state.anchorEl ? (
                                        <Grid container justify="center">
                                          <CircularProgress size={20} />
                                        </Grid>
                                      ) : (
                                        <>
                                          <ListItemIcon classes={{ root: classes.listIcon }}>
                                            {obj.visibility?.includes('user') ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                          </ListItemIcon>
                                          <Typography variant="inherit">
                                            End User
                                          </Typography>
                                        </>
                                      )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                      classes={{
                                        button: classes.listItem,
                                      }}
                                      button
                                    >
                                      <ListItemIcon classes={{ root: classes.listIcon }} onClick={e => {
                                        e.stopPropagation()
                                        this.handleTabVisibility('team', obj)
                                      }}>
                                        {obj.visibility?.includes('team') ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                      </ListItemIcon>
                                      <Typography variant="inherit" onClick={e => {
                                        e.stopPropagation()
                                        this.setState({
                                          addTeamsDialogOpen: true,
                                          addTeamsDialogType: 'Tab',
                                          addTeamsDialogTypeData: obj,
                                        })
                                      }}>
                                        Teams
                                      </Typography>
                                    </ListItem>
                                    {true && (
                                      <>
                                        <Divider />
                                        <ListItem
                                          classes={{
                                            button: classes.listItem,
                                          }}
                                          button
                                          onClick={e => {
                                            e.stopPropagation()
                                            this.setState({
                                              editTab: obj,
                                              openAddTabDialog: true,
                                            })
                                          }}
                                        >
                                          {typeOfVisibility == null && updateOrgVendorDetailTabsProgress ? (
                                            <Grid container justify="center">
                                              <CircularProgress size={20} />
                                            </Grid>
                                          ) : (
                                            <>
                                              <ListItemIcon classes={{ root: classes.listIcon }}>
                                                <EditIcon
                                                  fontSize={"small"}
                                                  className={classes.editIcon}
                                                />
                                              </ListItemIcon>
                                              <Typography variant="inherit">
                                                Edit Tab Title
                                              </Typography>
                                            </>
                                          )}
                                        </ListItem>
                                      </>
                                    )}
                                    {!obj.is_default && (
                                      <>
                                        <Divider />
                                        <ListItem
                                          classes={{
                                            button: classes.listItem,
                                          }}
                                          button
                                          onClick={e => {
                                            e.stopPropagation()
                                            this.handleDeleteTab(obj.id, index)
                                          }}
                                        >
                                          {open && this.state.anchorEl !== null && deleteOrgVendorDetailTabsProgress ? (
                                            <Grid container justify="center">
                                              <CircularProgress size={20} />
                                            </Grid>
                                          ) : (
                                            <>

                                              <ListItemIcon classes={{ root: classes.listIcon }}>
                                                <Image
                                                  src={deleteIcon}
                                                  className={classes.deleteImage}
                                                />
                                              </ListItemIcon>
                                              <Typography variant="inherit">
                                                Delete Tab
                                              </Typography>
                                            </>
                                          )}
                                        </ListItem>
                                      </>
                                    )}

                                  </List>
                                </Popover>
                              </Grid>
                            }
                            {...this.a11yProps(0)}
                          />
                        ))}
                        {addOrgVendorDetailTabsProgress && <Grid container alignItems="center"><CircularProgress size={20} /></Grid>}
                      </Tabs>
                    )}
                  </>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  variant={"contained"}
                  onClick={() => this.setState({ openAddTabDialog: true, editTab: null, label: "" })}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <div className={classes.tabContainer}>
              {(updateOrgVendorDetailTabsSectionProgress || isLoading || deleteOrgVendorDetailTabsSectionItemProgress) && isDrapSection ? (
                <Grid container alignItems="center" justify="center" style={{ height: "20vh" }}><CircularProgress /></Grid>
              ) : (
                <>
                  {tabView && (
                    <>
                      <DragDropContext onDragEnd={this.onSectionDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {tabView?.map((obj, index) => (
                                <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <FormBuilder
                                        config={obj}
                                        id={`${obj.name.toLowerCase().split(" ").join("-")}`}
                                        key={obj.id}
                                        isNew={false}
                                        isLoader={deleteOrgVendorDetailTabsSectionProgress ||
                                          updateOrgVendorDetailTabsSectionProgress ||
                                          updateOrgVendorDetailTabsSectionItemProgress ||
                                          deleteOrgVendorDetailTabsSectionItemProgress
                                        }
                                        isCustom={!obj.is_default}
                                        onDelete={() => this.handleDeleteSection(tabs[tab]?.id, obj.id)}
                                        deleteItem={itemId => this.handleDeleteItem(tabs[tab]?.id, obj.id, itemId)}
                                        onCancel={() => this.setState({ activeSection: null })}
                                        isActive={activeSection !== null && activeSection === index}
                                        activeSection={() => this.setState({ activeSection: index })}
                                        onUpdate={data => this.handleUpdateSection(data, true, tabs[tab]?.id, obj.id)}
                                        onUpdateSectionVisibility={data => this.handleUpdateSectionVisibility(data, tabs[tab]?.id, obj.id)}
                                        addTeams={() => this.setState({
                                          addTeamsDialogOpen: true,
                                          addTeamsDialogType: 'Section',
                                          addTeamsDialogTypeData: { ...obj, tabId: tabs[tab]?.id },
                                        })}
                                        isUpdateHappen={isUpdateHappen}
                                        isUpdateItemHappen={isUpdateItemHappen}
                                        visualizations={this.props.visualizations}
                                        orgWorkFlowList={this.props.orgWorkFlowList}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))
                              }
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  )}
                  {activeNewSection === null ? (
                    <Grid container justify="center">
                      <SqureButton
                        variant={"contained"}
                        onClick={() => {
                          this.setState({ activeNewSection: tabs[tab] })
                          setTimeout(() => {
                            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                          }, 100)
                        }}
                      >
                        + New Section
                      </SqureButton>
                      <SqureButton
                        style={{marginLeft: 20}}
                        variant={"contained"}
                        onClick={() => { this.props.showCloneCatalogSectionDialog() }}
                      >
                        Clone Sections
                      </SqureButton>
                    </Grid>
                  ) : (
                    <FormBuilder
                      isNew
                      isLoader={
                        addOrgVendorDetailTabsSectionProgress ||
                        addOrgVendorDetailTabsSectionItemProgress
                      }
                      isCustom={true}
                      isActive={true}
                      key={generate()}
                      config={{ visibility: [] }}
                      onSave={data => this.handleCreateNewSection(data, tabs[tab]?.id)}
                      onCancel={() => this.setState({ activeNewSection: null })}
                      visualizations={this.props.visualizations}
                      orgWorkFlowList={this.props.orgWorkFlowList}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Grid>
        {openAddTabDialog &&
          <AddTabDialog
            isOpen={openAddTabDialog}
            currentVisibility={tabs[tab]?.visibility}
            data={this.state.editTab}
            onClose={() => this.setState({ openAddTabDialog: false })}
            onSubmit={this.handleAddTab}
          />
        }

        <MessageDialog messageData={messageData} callBack={this.callBack} />

        {this.state.addTeamsDialogOpen && <SearchTeamsPoper
          onSubmit={(teams) => {
            if (this.state.addTeamsDialogType === 'Section') {
              const { id, tabId } = this.state.addTeamsDialogTypeData;
              this.props.updateOrgVendorDetailsTabSection(tabId, id, { teams });
            } else {
              const { id } = this.state.addTeamsDialogTypeData;
              this.props.updateOrgVendorDetailsTab(id, { teams });
            }
          }}
          onCancel={() => {
            this.setState({
              addTeamsDialogOpen: false,
              addTeamsDialogType: null,
              addTeamsDialogTypeData: null,
            })
          }}
          selectedTeams={this.state.addTeamsDialogTypeData?.teams || []}
        />}

        {this.props.openCloneCatalogSectionDialog && <CloneCatalogSectionDialog 
          currentTab={this.props.orgCatalogAllTabs[this.state.tab]}
          cloneSection={(data)=> {
            this.props.addOrgVendorDetailsTabSection(tabs[tab]?.id, data)
          }}
        />}
      </div>
    )
  }
}

export default connector(compose(withStyles(styles))(VendorAndServiceCatalog));