import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import AppBarV2 from "Components/Application/AppBarV2";
import DefaultImage from "assets/images/evaluation/default.svg";
import AddVendorButton from "Components/Common/AddVendorButton";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import SearchInput from "Components/Common/Input/SearchInput";

import { matchPath } from "react-router-dom";
import { storeSearchString } from "redux/applications/action";
// redux
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import { fetchVendorCatalog } from "redux/vendor/vendorCatalog/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { fetchVendors } from "redux/vendor/action";
import { showSnackBar } from "redux/snackbar/action";
import viewMoreImage from "assets/images/icons/viewMore.svg";
// Dilogs
import Breadcrumb from "Components/Common/Breadcrumb";
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
import AddVendorOptionDialog from "Components/Common/Dialogs/AddVendorOptionDialog";
import { createEvaluationCart, removeProductFromCart, fetchEvaluationCart } from "redux/evaluation/cart/action";

const connectedProps = (state) => ({
  userRole: state.authUser.user?.Role,
  searchString: state.applications.searchString,
  searchType: state.applications.searchType,
  fetchVendorProgress: state.vendors.fetchVendorProgress,
  updateCatalogStateProgress: state.vendors.updateCatalogStateProgress,
  updateCatalogStateError: state.vendors.updateCatalogStateError,
  updateOrgCatalogStateProgress: state.vendorCatalog.updateOrgCatalogStateProgress,
  updateOrgCatalogStateError: state.vendorCatalog.updateOrgCatalogStateError,
  vendors: state.vendors.vendorsData,
  VendorCatalog: state.vendorCatalog.VendorCatalog,
  fetchVendorError: state.vendors.fetchVendorError,
  addVendorDialogOpen: state.vendorCatalog.addVendorDialogOpen,
  evaluationCart: state.evaluationCart.evaluation,
});

const connectionActions = {
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  updateDrawerStyle: updateDrawerStyle,
  showSnackBar: showSnackBar,
  storeSearchString: storeSearchString,
  fetchVendors: fetchVendors,
  fetchVendorCatalog: fetchVendorCatalog,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      paddingTop: 0,
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
    },
    productContainer: {
      maxHeight: "calc(100vh - 230px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 230px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
  });

class AllVendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObj: {
        text: "",
        page: 0,
        page_size: 60,
      },
      productType: "VENDOR",
      lastLocation: this.props.lastLocation,
      searchRestored: false,
      headerSectionWidth: null,
      selectedState: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { lastLocation } = this.state;
    var pathName = lastLocation?.pathname;
    var isMatched = false;
    if (pathName !== undefined) {
      if (
        matchPath(pathName, {
          path: "/app/vendor/vendor-details/:id",
          exact: true,
          strict: false,
        })
      ) {
        isMatched = true;
      }
    }

    if (isMatched && pathName && this.props.searchString !== "" && this.state.searchRestored === false) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, text: prevProps.searchString },
          searchRestored: true,
        },
        () => {
          this.fetchData();
        }
      );
    }

    if (!this.props.updateCatalogStateProgress && prevProps.updateCatalogStateProgress) {
      if (this.props.updateCatalogStateError) {
        this.props.showSnackBar("Failed to update", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.setState(
          {
            searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
          },
          () => {
            this.fetchData();
          }
        );
      }
    }

    if (!this.props.updateOrgCatalogStateProgress && prevProps.updateOrgCatalogStateProgress) {
      if (this.props.updateOrgCatalogStateError) {
        this.props.showSnackBar("Failed to update", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.setState(
          {
            searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
          },
          () => {
            this.fetchData();
          }
        );
      }
    }

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const headerSectionWidth = this.divElement.clientWidth;
    if (this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  };

  handleRedirect = (type) => {
    if (type === "VENDOR") {
      this.props.history.push(`/app/vendors/all-vendors`);
    } else if (type === "SOFTWARE") {
      this.props.history.push(`/app/products/all-products`);
    } else if (type === "HARDWARE") {
      this.props.history.push(`/app/vendors/hardwares`);
    } else if (type === "SERVICE") {
      this.props.history.push(`/app/vendors/services`);
    }
  };

  fetchData() {
    const { searchObj } = this.state;
    let queryParams = {
      query: searchObj.text,
      page_size: searchObj.page_size,
      page: searchObj.page,
      filters: {},
    };
    queryParams.filters.states = this.state.selectedState;
    
    this.props.fetchVendorCatalog({...queryParams,filters: {...queryParams.filters, is_org: '1'}})
    this.props.fetchVendors({...queryParams,filters: {...queryParams.filters, is_master: '1'}});
  }

  productStates = (arr) => {
    if (arr) {
      const data = arr.map((state) => ({
        StateName: state.name,
        StateID: state.name,
      }));
      return data;
    } else {
      return [];
    }
  };

  addToCart(id,type='MASTER_VENDOR') {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(id, null, type);
    } else {
      var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.filter((p) => p.EntityID === id);
      if (addedToCart && addedToCart.length > 0) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, parseInt(addedToCart[0].EntityObjectID));
      } else {
        this.props.createEvaluationCart(id, this.props.evaluationCart.ID, type);
      }
    }
  }

  handleOnClickState = (newState) => {
    let selectedState = [...this.state.selectedState];
    if (selectedState.includes(newState)) {
      selectedState = selectedState.filter((state) => state !== newState);
    } else {
      selectedState.push(newState);
    }
    this.setState(
      {
        selectedState,
        searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    const { classes, vendors, VendorCatalog, fetchVendorProgress } = this.props;
    const { searchObj } = this.state;
    const totalCount = this.props.vendors?.total || 0;

    var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => p.EntityID);

    return (
      <>
        <AppBarV2 title={"Expent Vendor Directory"} withBack={true} dropdown={true} />
        <Breadcrumb routeList={[{ name: "Vendors" }]} />
        <div className={classes.root}>
          <div className={classes.stickyBar}>
            <Grid
              container
              justifyContent="space-between"
              ref={(divElement) => {
                this.divElement = divElement;
              }}
              className={classes.productSectionNew}
            >
              <Grid item>
                <p className={classes.productCount}>
                  {totalCount.toLocaleString("en-US")}
                  {totalCount > 1 ? " Vendors" : " Vendor"} found
                </p>
              </Grid>
              <Grid item>
                <ProductTypeFilter
                  type={this.state.productType}
                  hideHardware
                  onChange={(type) => {
                    this.handleRedirect(type);
                  }}
                />
                <SearchInput
                  roundedCorner
                  showTypes={false}
                  isFilter={false}
                  searchObj={this.state.searchObj}
                  selectedState={this.state.selectedState}
                  placeholder="Search Vendors"
                  onChange={(searchObj) => {
                    this.setState(
                      {
                        searchObj: { page_size: 60, text: searchObj.text, page: 0 },
                      },
                      () => {
                        this.props.storeSearchString(searchObj.text);
                        this.fetchData();
                      }
                    );
                  }}
                  onChangeState={(newState) => {
                    this.handleOnClickState(newState);
                  }}
                  showState
                  type="vendor"
                />
              </Grid>
              <Grid item>
                <AddVendorButton title={"Add Vendor"} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.productContainer} id="productContainer">
            {vendors?.data?.length === 0 && !fetchVendorProgress && (
              <Typography align="center">
                <span>Not Found</span>
              </Typography>
            )}
            <InfiniteScroll
              scrollableTarget="productContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                marginRight: 20,
              }}
              dataLength={vendors?.items?.length || 0}
              next={() => {
                this.setState(
                  {
                    searchObj: { ...searchObj, page: searchObj.page + 1 },
                    isScroll: true,
                  },
                  () => {
                    this.fetchData();
                  }
                );
              }}
              hasMore={fetchVendorProgress ? false : totalCount > vendors?.items?.length}
              endMessage={""}
            >
              {VendorCatalog?.items && VendorCatalog?.items.length > 0 && (
                <>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Typography className={classes.label}>Existing Vendors</Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ marginBottom: 10 }}>
                    {VendorCatalog?.items.slice(0, 5).map((vendor, index) => (
                      <Grid item lg={2} md={3} sm={4} xs={6} key={vendor.id}>
                        <LayeredCard
                          title={vendor.name}
                          numLayer={0}
                          subTitle={vendor.name}
                          cardType={"vendor"}
                          isActionButtons
                          isExisting={true}
                          productStates={this.productStates(vendor.states)}
                          onClickState={(state) => this.handleOnClickState(state)}
                          image={vendor.logo == null || vendor.logo.length === 0 ? DefaultImage : vendor.logo}
                          onClick={() => {
                            if (!vendor.master_vendor_id) return;
                            // this.props.history.push(`/app/vendors/org-vendor-details/${vendor.id}`);
                            this.props.history.push(`/app/vendors/all-vendors/${vendor.master_vendor_id}`);
                          }}
                          org_vendor_id={vendor.org_vendor_id}
                          master_vendor_id={vendor.master_vendor_id}
                          showCheckbox
                          id={parseInt(vendor.id)}
                          customRoute={`/app/vendors/all-vendors/${vendor.id}`}
                          checked={addedToCart?.indexOf(vendor.org_vendor_id) !== -1}
                          toogleCheck={(e) => {
                            this.addToCart(vendor.org_vendor_id,'CUSTOM_VENDOR');
                            console.log(vendor, "VendorDetailsss");
                          }}
                        />
                      </Grid>
                    ))}
                    {VendorCatalog?.items && VendorCatalog?.items.length > 5 && (
                      <Grid item lg={2} md={3} sm={4} xs={6} key={"org_product"}>
                        <LayeredCard
                          title={"View More"}
                          image={viewMoreImage}
                          subTitle=""
                          color="BLUE"
                          onClick={() => {
                            localStorage.setItem("activeTab", 2);
                            this.props.history.push("/app/vendors/org-vendors");
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}

              {vendors?.items && vendors?.items.length > 0 && (
                <Grid container alignItems="center" justifyContent="space-between">
                  <Typography className={classes.label}>Other Vendors </Typography>
                </Grid>
              )}
              <Grid container spacing={3}>
                {vendors?.items &&
                  vendors?.items.map((vendor) => (
                    <Grid item lg={2} md={3} sm={4} xs={6} key={vendor.id}>
                      <LayeredCard
                        title={vendor.name}
                        numLayer={0}
                        subTitle={vendor.name}
                        cardType={"vendor"}
                        isActionButtons
                        productStates={this.productStates(vendor.states)}
                        onClickState={(state) => this.handleOnClickState(state)}
                        image={vendor.logo == null || vendor.logo.length === 0 ? DefaultImage : vendor.logo}
                        onClick={() => {
                          if (!vendor.master_vendor_id) return;
                          this.props.history.push(`/app/vendors/all-vendors/${vendor.master_vendor_id}`);
                        }}
                        showCheckbox
                        isExisting={false}
                        id={parseInt(vendor.id)}
                        org_vendor_id={vendor?.org_vendor_id}
                        master_vendor_id={vendor.master_vendor_id}
                        customRoute={`/app/vendors/all-vendors/${vendor.id}`}
                        checked={addedToCart?.indexOf(vendor.master_vendor_id) !== -1}
                        toogleCheck={(e) => {
                          this.addToCart(parseInt(vendor.master_vendor_id));
                          console.log(vendor, "VendorDetailsss");
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
              {fetchVendorProgress && (
                <Grid container justify="center" style={{ marginTop: 30 }}>
                  <CircularProgress />
                </Grid>
              )}
            </InfiniteScroll>
          </div>
        </div>
        {this.props.addVendorDialogOpen && <AddVendorOptionDialog type={"vendor"} />}
      </>
    );
  }
}

AllVendors.defaultProps = {
  isSeller: false,
};

export default connector(compose(withStyles(styles), withLastLocation)(AllVendors));
