import React, { useState } from 'react'
import {
    Transforms,
    Editor,
    Range,
    Element as SlateElement,
} from 'slate'
import { useSlate } from 'slate-react';

import { IconButton, Dialog, DialogContent, Button, Typography } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import makeStyles from '@material-ui/styles/makeStyles';
import Schema from 'Components/Common/ProjectSchema/Schema.jsx';

export const InsertAddVariableButton = ({ icon, workflowId }) => {
    const editor = useSlate();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [placeholder, setPlaceholder] = useState('');
    // const [placeholderError, setVariableError] = useState('');
    const [code, setCodeValue] = useState('');
    const [selection, setSelection] = useState(null);

    const insertValue = (tagData, additional_data) => {
        // console.log(additional_data, 'additional_data')
        // // event.stopPropagation();
        // // setVariableError("")
        // const add_variable = {
        //     type: 'variable',
        //     placeholder: additional_data?.name !== '' ? additional_data?.name : tagData?.label,
        //     code: '{{ ' + tagData.value + ' }}',
        //     children: [{ text: '' }],
        // }
        // Transforms.insertNodes(editor, add_variable)
        // Transforms.move(editor)
        // ReactEditor.focus(editor)
        // clear();
        editor.selection = selection
        insertVariable(editor, tagData, additional_data);
        clear();
    }

    const clear = (e) => {
        setIsOpen(false);
        setPlaceholder('');
        setCodeValue('');
    }

    const addVariableIcon = (e) => {
        // console.log(editor, 'editor')
        const [add_variable] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'variable',
        })
        if (add_variable && add_variable[0]) {
            setPlaceholder(add_variable[0].placeholder);
            setCodeValue(add_variable[0].code);
        } else {
        }
        e.preventDefault()
        setIsOpen(true)
        setSelection(editor.selection)
    }

    return (
        <span>
            <ToggleButton
                className={classes.insertVariable}
                selected={(isAddVariableActive(editor))}
                onMouseDown={event => addVariableIcon(event)}>
                <IconButton >{icon}
                </IconButton>
            </ToggleButton>
            <Dialog
                aria-labelledby="app-addVariableDialog"
                open={isOpen}
                disableBackdropClick={true}
                maxWidth={"md"}
                scroll="body"
                className={classes.dialog}
                id={"addVariableMetaDialog"}
                fullWidth={true}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Typography variant={'h5'}>Insert Variable</Typography>
                    <Schema
                        workflowId={workflowId}
                        isFromSlate={true}
                        handleClick={(tagData, additional_data) => {
                            // console.log(tagData, 'TagData')
                            // setPlaceholder(tagData?.label)
                            // setCodeValue(tagData?.value)
                            insertValue(tagData, additional_data)
                            // this.props.addTagValue(tagData?.value)
                        }}
                    />
                    {false && <div>
                        <div className={classes.items}>
                            <Typography className={classes.itemTitle} variant={'h1'}>Placeholder</Typography>
                            <input
                                placeholder={'Enter Placeholder'}
                                value={placeholder}
                                onChange={(e) => { setPlaceholder(e.target.value) }}
                                className={classes.inputBox} />
                        </div>
                        <div className={classes.items}>
                            <Typography className={classes.itemTitle} variant={'h1'}>Code</Typography>
                            <textarea
                                placeholder={'Enter Code'}
                                rows={4}
                                value={code}
                                onChange={(e) => { setCodeValue(e.target.value) }}
                                className={classes.inputBox} />
                        </div>
                        <div className={classes.btnSection}>
                            <Button variant="outlined" color="secondary" className={classes.button} onClick={(event) => {
                                clear()
                            }} >Cancel</Button>&nbsp;&nbsp;
                            <Button variant="contained" color="secondary" disabled={placeholder.length < 1} className={classes.button} onClick={(event) => insertValue(event)}>{'Submit'}</Button>
                        </div>
                    </div>}

                    <div className={classes.btnSection}>
                        <Button variant="outlined" color="secondary" className={classes.button} onClick={(event) => {
                            clear()
                        }} >Cancel</Button>
                    </div>

                </DialogContent>
            </Dialog>
        </span>
    )
}

const unwrapVariable = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'variable',
    })
}

const wrapVariable = (editor, tagData, additional_data) => {
    if (isVariableActive(editor)) {
        unwrapVariable(editor)
    }

    // console.log(additional_data, 'additional_data')
    // // event.stopPropagation();
    // // setVariableError("")
    // const add_variable = {
    //     type: 'variable',
    //     placeholder: additional_data?.name !== '' ? additional_data?.name : tagData?.label,
    //     code: '{{ ' + tagData.value + ' }}',
    //     children: [{ text: '' }],
    // }
    // Transforms.insertNodes(editor, add_variable)
    // Transforms.move(editor)
    // ReactEditor.focus(editor)
    // clear();

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'variable',
        placeholder: additional_data?.name !== '' ? additional_data?.name : tagData?.label,
        code: '{{ ' + tagData.value + ' }}',
        children: isCollapsed ? [{ text: '' }] : [],
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

const isVariableActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
}

const insertVariable = (editor, tagData, additionalData) => {
    if (editor.selection) {
        wrapVariable(editor, tagData, additionalData)
    }
}

export const withVariables = editor => {
    const { insertData, insertText, isInline } = editor

    editor.isInline = element => {
        return element.type === 'variable' ? true : isInline(element)
    }

    editor.insertText = text => {
        if (text) {
            wrapVariable(editor, text)
        } else {
            insertText(text)
        }
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')

        if (text) {
            wrapVariable(editor, text)
        } else {
            insertData(data)
        }
    }
    return editor
}

const isAddVariableActive = editor => {
    const [add_variable] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'variable',
    })
    return !!add_variable
}

const useStyles = makeStyles((theme) => ({
    insertVariable: {
        padding: 0,
        minWidth: 41,
        margin: '0.25em',
        border: 'none'
    },
    radioBox: {
        width: 13,
        height: 13,
        maxWidth: 13,
        minWidth: 13
    },
    inputBox: {
        width: '100%',
        borderRadius: 3,
        minHeight: 40,
        border: '1px solid #ddd',
        padding: 5
    },
    btnSection: {
        float: 'right',
        marginBottom: 10
    },
    label: {
        position: 'relative',
        top: -1
    },
    alert: {
        marginBottom: theme.spacing(1)
    },
    items: {
        marginBottom: 20
    },
    itemTitle: {
        fontSize: 14,
        marginBottom: 5
    }
}));
