import { Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Box, Grid, Popover } from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/new_delete.svg";
import addIcon from "assets/images/new_tick.svg";
import closeIcon from "assets/images/new_close.svg";
import Select from "react-select";
import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";

import { withAsyncPaginate } from "react-select-async-paginate";
const SelectAsyncPaginate = withAsyncPaginate(Select);
const connectedProps = () => ({});

const connectionActions = {};

const connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      paddingTop: "24px",
      paddingBottom: "24px",
    },
    heading: {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 600,
      color: "#003b4d99",
      textTransform: "uppercase",
    },
    imageContainer: {
      textAlign: "center",
      height: "40px",
      width: "40px",
    },
    card: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      border: "1px solid #c1c1c1",
      borderRadius: "4px",
      height: "100px",
    },
    evaluateCard: {
      width: "calc(100% - 10px)",
      background: "rgba(0, 59, 77, 0.05)",
      padding: "16px",
      boxShadow: "0px 3px 25px #55555500",
      borderRadius: 5,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      marginBottom: "16px",
    },
    evaluateCardTitle: {
      color: "#003B4D",
      fontSize: 14,
      margin: 0,
      fontWeight: 600,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontFamily: "Manrope",
    },
    evaluateCardSubTitle: {
      fontSize: 14,
      fontFamily: "Manrope",
      fontWeight: 500,
      color: "rgba(0, 59, 77, 0.60)",
      marginTop: "5px",
      marginBottom: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    evaluateText: {
      flex: 0.8,
    },
    evaluateLogo: {
      flex: 0.2,
      display: "flex",
      justifyContent: "flex-end",
    },
    productLogoContainer: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
    },
    productLogo: {
      float: "left",
      width: "40px",
      height: "40px",
      border: "1px solid #EEEEEE",
      borderRadius: "4px",
      marginRight: "10px",
      textAlign: "center",
      lineHeight: "33px",
      fontWeight: 600,
      fontSize: 11,
      color: "#A0A5BA",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "scale-down",
      },
    },
    addBtn: {
      cursor: "pointer",
      color: "#17BEBB",
      marginTop: "8px",
    },
    popDialog: {
      padding: "10px",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "0px 4px 8px 0px rgba(0, 59, 77, 0.16)",
      minWidth: "400px",
      maxWidth: "400px",
    },
    cardDatas: {
      maxHeight: "200px",
      overflow: "auto",
    },
    vendorCards: {
      width: "calc(100% - 10px)",
      boxShadow: "0px 3px 25px #55555500",
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      marginBottom: "16px",
    },
    vendorTxt: {
      flex: 0.9,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      padding: "12px",
      borderRadius: "10px",
      border: "rgba(0, 59, 77, 0.15)",
      background: "rgba(0, 59, 77, 0.05)",
    },
    vendorDel: {
      marginLeft: 3,
      border: "1px solid rgba(0, 59, 77, 0.05)",
      cursor: "pointer",
      padding: "16px",
      borderRadius: 5,
      flex: 0.1,
      textAlign: "center",
    },
    vendorLogoContainer: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
    },
    actionButtons: {
      marginTop: "24px",
      textAlign: "right",
      paddingRight: "20px",
    },
  });

const ProfileItemAddVendorType = (props) => {
  const { classes, data, isEdit, value, onSubmit } = props;

  const [vendors, setVendors] = useState([]);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isAddNew, setIsAddNew] = useState(false); // New state variable to track user interaction

  const handleUpdate = (e) => {
    onSubmit?.(vendors);
    handleClose();
  };

  const handleClick = (event) => {
    setProfileAnchorEl(event.currentTarget || event.current);
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  const handleDeleteVendor = (indx) => {
    let newData = [...vendors];
    newData.splice(indx, 1);
    setVendors(newData);
  };

  const getOptionVendors = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      query: inputValue,
      page_size: 15,
      page: page,
      filters: {},
    };

    let url = `${api_server}/entity/vendor/search?t=${timestamp}`;

    const response = await axios.post(url, formData, {
      headers: {
        Authorization:
          CommonFn.getStorage("authType") +
          " " +
          CommonFn.getStorage("authToken"),
        "Content-Type": "application/json",
      },
    });

    let products = response?.data?.items || [];
    let total = response?.data?.total || 0;

    products = products.map((m) => {
      m.value = m.is_master ? m.master_vendor_id : m.org_vendor_id;
      m.label = m.name;
      m.isMaster = m.is_master ? true : false;
      return m;
    });

    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Box style={{ width: "100%" }}>
      <Grid container>
        {value &&
          value.map((itm, index) => {
            return (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={itm.id}>
                <div
                  className={classes.evaluateCard}
                  key={index}
                  onClick={() => { }}
                >
                  <div className={classes.evaluateText}>
                    <p className={classes.evaluateCardTitle}>{itm?.label}</p>
                    <p className={classes.evaluateCardSubTitle}>
                      {itm?.description}
                    </p>
                  </div>
                  <div className={classes.evaluateLogo}>
                    <div className={classes.productLogoContainer}>
                      <div className={classes.productLogo} title={itm?.label}>
                        <Image
                          src={itm.logo}
                          alt={itm?.label}
                          title={itm?.label}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
      {isEdit && (
        <Typography
          onClick={(e) => {
            setVendors(value);
            handleClick(e);
          }}
          variant="body1"
          className={classes.addBtn}
        >
          {value && value.length > 0
            ? "+ Edit " + data?.label
            : "+ Add " + data?.label}
        </Typography>
      )}
      {!isEdit && (
        <Box style={{ width: "100%" }}>
          {value && value.length <= 0 && (
            <Typography className={classes.addBtn} variant="body1">
              -
            </Typography>
          )}
        </Box>
      )}

      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="profileMenu"
        classes={{ paper: classes.profileMenu }}
        anchorEl={profileAnchorEl}
        keepMounted
        elevation={2}
        open={Boolean(profileAnchorEl)}
        onClose={() => {
          handleClose();
        }}
      >
        <Box className={classes.popDialog}>
          <div className={classes.cardDatas}>
            {vendors.map((itm, index) => {
              return (
                <div
                  className={classes.vendorCards}
                  key={"itm_v_" + index}
                  onClick={() => { }}
                >
                  <div className={classes.vendorTxt}>
                    <div className={classes.vendorLogoContainer}>
                      <div className={classes.productLogo} title={itm?.label}>
                        <Image
                          src={itm.logo}
                          alt={itm?.label}
                          title={itm?.label}
                        />
                      </div>
                    </div>
                    <p className={classes.evaluateCardTitle}>{itm?.label}</p>
                  </div>
                  <div className={classes.vendorDel}>
                    <Image
                      src={deleteIcon}
                      onClick={() => {
                        handleDeleteVendor(index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          {isAddNew && (
            <SelectAsyncPaginate
              isClearable
              isMulti={true}
              isSearchable={true}
              classNamePrefix="select"
              debounceTimeout={200}
              placeholder={"Select Vendor"}
              onChange={(data) => {
                setVendors(data);
              }}
              value={vendors}
              defaultValue={vendors}
              loadOptions={getOptionVendors}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          )}

          {!isAddNew && (
            <Typography
              onClick={(e) => {
                setIsAddNew(true);
              }}
              className={classes.addBtn}
              variant="body1"
            >
              {"+ Add New " + data?.label}
            </Typography>
          )}

          <div className={classes.actionButtons}>
            <Image
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
              }}
              src={closeIcon}
            />{" "}
            &nbsp;&nbsp;
            <Image
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleUpdate();
              }}
              src={addIcon}
            />
          </div>
        </Box>
      </Popover>
    </Box>
  );
};

export default connector(
  compose(withRouter, withStyles(styles))(ProfileItemAddVendorType)
);
