import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel,Checkbox,Divider,Button,CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {createChildEvaluation} from "redux/evaluation/link/action"
import Alert from '@material-ui/lab/Alert';

const connectedProps = (state) => ({
    currentEvaluation:state.evaluationMetaData.evaluation,
    updateProgress:state.evaluationLink.childCreateProgress,
    updateError:state.evaluationLink.errorChildCreate,
});

const connectionActions = {
    createChildEvaluation:createChildEvaluation
}



var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions:{
        marginTop:theme.spacing(2),
        textAlign:"right"
    },
    product:{
        marginTop:theme.spacing(2,0),
    },
    alerts:{
        marginBottom:theme.spacing(2),
        marginTop:theme.spacing(2)
    },

});

class NewEvaluation extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            actibeTab:0,
            selectedProducts:[],
            error:"",
            success:"",
        }
        this.toggleProduct     =   this.toggleProduct.bind(this);
        this.submitRequest  =   this.submitRequest.bind(this);
    }

    componentDidUpdate(prevProps){
        if(this.props.updateProgress === false && prevProps.updateProgress === true){
          if(this.props.updateError == null){
            this.setState({
              success:"Child Evaluation Created. Please navigate to Existing Tab to see the details.",
              selectedProducts:[]
            })
          } else {
            this.setState({
                error:"Failed to create Evaluation.",
              })
          }
        }
      }

    toggleProduct(event,productId){
        var checked = event.target.checked;
        var currentProducts  = this.state.selectedProducts;
        var productIndex  = currentProducts.indexOf(productId);
        if(productIndex == -1){
            currentProducts.push(productId)
        } else {
            currentProducts.splice(currentProducts.indexOf(productId),1)
        }
        this.setState({
            selectedProducts:currentProducts
        })
    }

    submitRequest(){
        this.setState({"error":"","success":""});
        if(this.state.selectedProducts.length === 0){
          this.setState({"error":"Please select products."});
          return
        }
  
        this.props.createChildEvaluation(this.props.currentEvaluation.ID,{
            "product_ids":this.state.selectedProducts,
            "type":1,
            "name":"Follow up Evaluation-"+this.props.currentEvaluation.Name
        })
    }


    render() {
        const classes   =   this.props.classes;
        if(this.props.currentEvaluation == null){
            return null
        }
        var products = this.props.currentEvaluation.Products;
        return <div >
                    <div className={classes.alerts}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>
                    {products.map((product)=>{
                        return <div key={product.ID} className={classes.product}>
                                    <FormControlLabel
                                        control={<Checkbox 
                                            checked={this.state.selectedProducts.indexOf(product.ID) !== -1} 
                                            onChange={(event)=>{
                                                this.toggleProduct(event,product.ID)
                                            }} name={`product${product.ID}`} />}
                                        label={product.Name}
                                    />
                                </div>
                    })}
                    <Divider/>
                    <div className={classes.actions}>
                        {!this.props.updateProgress && <Button variant="contained" color="secondary"  onClick={()=>{
                            this.submitRequest();
                        }}>Create Next Evaluation</Button>}
                        {this.props.updateProgress && <CircularProgress/>}
                    </div>
                </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(NewEvaluation));
