import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const START_FETCH_USER_PROFILE_META = "START_FETCH_USER_PROFILE_META";
export const END_FETCH_USER_PROFILE_META = "END_FETCH_USER_PROFILE_META";

export const START_FETCH_DEPARTMENTS = "START_FETCH_DEPARTMENTS";
export const END_FETCH_DEPARTMENTS = "END_FETCH_DEPARTMENTS";

export const START_GET_DEPARTMENT = "START_GET_DEPARTMENT";
export const END_GET_DEPARTMENT = "END_GET_DEPARTMENT";

export const START_GET_DEPARTMENT_APPROVERS = "START_GET_DEPARTMENT_APPROVERS"
export const END_GET_DEPARTMENT_APPROVERS = "END_GET_DEPARTMENT_APPROVERS"

export const START_ADD_DEPARTMENT_APPROVER = "START_ADD_DEPARTMENT_APPROVER";
export const END_ADD_DEPARTMENT_APPROVER = "END_ADD_DEPARTMENT_APPROVER";

export const START_DELETE_DEPARTMENT_APPROVER = "START_DELETE_DEPARTMENT_APPROVER";
export const END_DELETE_DEPARTMENT_APPROVER = "END_DELETE_DEPARTMENT_APPROVER";

export const START_UPDATE_DEPARTMENT_APPROVER = "START_UPDATE_DEPARTMENT_APPROVER";
export const END_UPDATE_DEPARTMENT_APPROVER = "END_UPDATE_DEPARTMENT_APPROVER";

export const START_FETCH_FIELD_OPTIONS = "START_FETCH_FIELD_OPTIONS";
export const END_FETCH_FIELD_OPTIONS = "END_FETCH_FIELD_OPTIONS";

function startFetchUserProfileMeta() {
    return {
        type: START_FETCH_USER_PROFILE_META,
        payload: {}
    };
}

function endFetchUserProfileMeta(success, error) {
    return {
        type: END_FETCH_USER_PROFILE_META,
        payload: {
            success, error
        }
    };
}

export function fetchUserProfileMeta() {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/profile-meta?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchUserProfileMeta());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchUserProfileMeta(response, error));
        } catch (e) {
            dispatch(endFetchUserProfileMeta(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchFieldOptions() {
    return {
        type: START_FETCH_FIELD_OPTIONS,
        payload: {}
    };
}

function endFetchFieldOptions(success, error) {
    return {
        type: END_FETCH_FIELD_OPTIONS,
        payload: {
            success, error
        }
    };
}

export function fetchFieldOptions(key) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/profile-meta-data/${key}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchFieldOptions());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchFieldOptions(response, error));
        } catch (e) {
            dispatch(endFetchFieldOptions(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchDepartments() {
    return {
        type: START_FETCH_DEPARTMENTS,
        payload: {}
    };
}

function endFetchDepartments(success, error) {
    return {
        type: END_FETCH_DEPARTMENTS,
        payload: {
            success, error
        }
    };
}

export function fetchDepartments() {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/departments?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchDepartments());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchDepartments(response, error));
        } catch (e) {
            dispatch(endFetchDepartments(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetDepartment() {
    return {
        type: START_GET_DEPARTMENT,
        payload: {}
    };
}

function endGetDepartment(success, error) {
    return {
        type: END_GET_DEPARTMENT,
        payload: {
            success, error
        }
    };
}

export function getDepartment(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/department/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetDepartment());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endGetDepartment(response, error));
        } catch (e) {
            dispatch(endGetDepartment(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetDepartmentApprovers() {
    return {
        type: START_GET_DEPARTMENT_APPROVERS,
        payload: {}
    };
}

function endGetDepartmentApprovers(success, error) {
    return {
        type: END_GET_DEPARTMENT_APPROVERS,
        payload: {
            success, error
        }
    };
}

export function getDepartmentApprovers(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/department/${id}/approvers?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetDepartmentApprovers());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endGetDepartmentApprovers(response, error));
        } catch (e) {
            dispatch(endGetDepartmentApprovers(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddDepartmentApprover() {
    return {
        type: START_ADD_DEPARTMENT_APPROVER,
        payload: {}
    };
}

function endAddDepartmentApprover(success, error) {
    return {
        type: END_ADD_DEPARTMENT_APPROVER,
        payload: {
            success, error
        }
    };
}

export function addDepartmentApprover(id, params) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/department/${id}/approver?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddDepartmentApprover());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddDepartmentApprover(response, error));
        } catch (e) {
            dispatch(endAddDepartmentApprover(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateDepartmentApprover() {
    return {
        type: START_UPDATE_DEPARTMENT_APPROVER,
        payload: {}
    };
}

function endUpdateDepartmentApprover(success, error) {
    return {
        type: END_UPDATE_DEPARTMENT_APPROVER,
        payload: {
            success, error
        }
    };
}

export function updateDepartmentApprover(id, approverId, params) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/department/${id}/approver/${approverId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateDepartmentApprover());
        try {
            let [response, error] = await await HttpFactory.instance().putMethod(url, params);
            dispatch(endUpdateDepartmentApprover(response, error));
        } catch (e) {
            dispatch(endUpdateDepartmentApprover(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteDepartmentApprover() {
    return {
        type: START_DELETE_DEPARTMENT_APPROVER,
        payload: {}
    };
}

function endDeleteDepartmentApprover(success, error) {
    return {
        type: END_DELETE_DEPARTMENT_APPROVER,
        payload: {
            success, error
        }
    };
}

export function deleteDepartmentApprover(id, approverId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/department/${id}/approver/${approverId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteDepartmentApprover());
        try {
            let [response, error] = await await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteDepartmentApprover(response, error));
        } catch (e) {
            dispatch(endDeleteDepartmentApprover(null, {
                message: e.message
            }));
            return;
        }
    };
}