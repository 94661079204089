import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, InputAdornment, Slide, Typography, CircularProgress, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import { hideViaIntegrationDialog, showViaIntegrationDialog, fetchOrgGroup, updateOrgGroup } from "redux/org/group/action";
import { Search as SearchIcon, Cancel as CancelIcon } from "@material-ui/icons";
import { fetchUser, addTeamMembers } from "../../../../../redux/okta/action";
import { fetchUsers } from "../../../../../redux/usersSettings/action";
import editIcon from "assets/images/edit_icon.svg"
import deleteIcon from "assets/images/delete.svg"
import Image from 'Components/Common/image.jsx'
import SqureButton from "Components/Common/SqureButton";

import VisibilityIcon from '@material-ui/icons/Visibility';
import Chip from '@material-ui/core/Chip';
import { showSnackBar } from "redux/snackbar/action";


import {
    TableContainer, Table, TableBody, TableCell, TableHead, TableRow,
    TablePagination, Paper
} from '@material-ui/core';
import classnames from "classnames";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    viaIntegrationDialogOpen: state.orgGroup.viaIntegrationDialogOpen,
    okta: state.okta,
    groups: state.orgGroup.groups,
    fetchGroupProg: state.orgGroup.fetchGroupProg,
    fetchGroupTotal: state.orgGroup.fetchGroupTotal,
    settings: state.orgUser.fetchAllOrgSettingsSuccess,
    updateGroupProg: state.orgGroup.updateGroupProg,
    updateGroupError: state.orgGroup.updateGroupError,
});

const connectionActions = {
    showViaIntegrationDialog: showViaIntegrationDialog,
    hideViaIntegrationDialog: hideViaIntegrationDialog,
    addTeamMembers: addTeamMembers,
    fetchUser: fetchUser,
    fetchUsers: fetchUsers,
    fetchOrgGroup: fetchOrgGroup,
    updateOrgGroup: updateOrgGroup,
    showSnackBar: showSnackBar
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(0),
    },
    progress: {
        textAlign: 'center',
        paddingTop: 15
    },
    tbodyCell: {
        minHeight: 40
    },
    selectAll: {
        border: 0,
        color: '#0091FF',
        background: '#fff',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 15,

        "&:focus": {
            outline: 'none'
        }
    },
    headSection: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    userCount: {
        color: '#282D30',
        fontSize: 17,
    },
    buttonSection: {
        textAlign: 'center',
        marginTop: 40
    },
    searchSection: {
        marginTop: 20,
        '& [class*="MuiOutlinedInput-root"]': {
            maxHeight: 40,
            minHeight: 40,
            maxWidth: 343,
            margin: '0 auto',
        },
        "& [class*='MuiInputBase-input']": {
            fontSize: 13,
            // width: 300,
        },
        "& [class*='MuiSvgIcon-root']": {
            fill: "#707070",
        },
        "& [class*=MuiGrid-container]": {
            alignItems: "center",
            marginBottom: 20,
        },
        "&>div ": {
            marginBottom: 0,
        },
    },
    checked: {
        opacity: 0,
        pointerEvents: 'none',
        width: 50
    },
    alert: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    closeIcon: {
        cursor: "pointer",
    },
    tableSection: {
        marginTop: theme.spacing(6.4),
        minHeight: 150,
        height: '500px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    close: {
        position: 'absolute',
        right: 30,
        top: 30,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    action: {
        width: "20%",
        textAlign: 'center'
    },
});

class TeamsViaIntegrationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            searchQuery: '',
            oktauser: [],
            error: '',
            page: 0,
            pageSize: 20,
            isDataLoading: true,
            Suppressed: true
        }

        this.getAppSpecificFlag = this.getAppSpecificFlag.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {

        if (this.props.fetchGroupProg === false && prevProps.fetchGroupProg === true) {
            this.setState({
                isDataLoading: false
            })
        }

        if (!this.props.updateGroupProg && prevProps.updateGroupProg && !this.props.updateGroupError) {
            this.props.showSnackBar("Team successfully updated", "success", 3000);
            this.setState({ page: 0, isDataLoading: true }, () => {
                this.fetchData();
            });
        }

        // if (this.props.viaIntegrationDialogOpen === true && prevProps.viaIntegrationDialogOpen === false) {
        //     this.props.fetchOrgGroup(this.state.searchQuery);
        //     this.setState({ 'error': '' })
        // }

        if (!this.props.okta?.addTeamMemberProgress && prevProps.okta?.addTeamMemberProgress) {
            if (this.props.okta.addTeamMemberError !== null) {
                this.setState({ 'error': this.props.okta?.addTeamMemberError?.message })
            } else if (this.props.viaIntegrationDialogOpen) {
                this.props.hideViaIntegrationDialog();
                this.props.fetchUsers(0, 20, '');
            }
        }
    }

    fetchData = () => {
        this.props.fetchOrgGroup(this.state.page, this.state.pageSize, this.state.query, 'yes');
    }


    handleChangePage(e, newPage) {
        this.setState({
            page: newPage,
            isDataLoading: true
        }, () => {
            this.fetchData()
        })
    }

    handleChangeRowsPerPage(e) {
        this.setState({
            page: 0,
            pageSize: e.target.value,
            isDataLoading: true
        }, () => {
            this.fetchData()
        })
    }

    getAppSpecificFlag(appName, key) {
        var result = false;
        if (this.props.settings != null) {
            var setting = this.props.settings.filter((item) => {
                if (item.SettingName === appName + key) {
                    return true
                }
                return false
            });

            if (setting.length > 0) {
                result = setting[0]['Value'] === "YES"
            }
        }
        return result;
    }

    updateOrgGroup = (data) => {
        let obj = {}
        obj.id = data?.ID;
        obj.name = data?.Name;
        obj.email = data?.Email;
        obj.is_suppressed = false;
        obj.user_ids = data?.Users;
        obj.description = data?.Description;
        this.props.updateOrgGroup(obj);
    }

    render() {
        const classes = this.props.classes;
        const tableData = {
            class: classes.table,
            isProgress: this.props.fetchGroupProg,
            head: [
                {
                    label: "Name",
                    key: "Name",
                    type: "text",
                    headClass: classes.thead,
                    cellClass: classes.tbodyCell,
                },
                {
                    label: "Description",
                    key: "Description",
                    type: "text",
                    headClass: classes.thead,
                    cellClass: classes.tbodyCell,
                },
                {
                    label: "Products",
                    key: "ProductsCount",
                    type: "view",
                    headClass: classes.thead,
                    cellClass: classes.tbodyCell,
                    actionItem:
                    {
                        action: "viewProducts",
                    },
                },
            ],
        };


        tableData.head.push({
            label: "Action",
            key: "ID",
            type: "action",
            headClass: classes.action,
            actionItems: [
                {
                    label: "",
                    type: "muiicon",
                    html: <Image alt="Edit" src={editIcon} className={classes.edit} />,
                    action: "edit",
                },
                {
                    label: "",
                    type: "muiicon",
                    html: (
                        <Image alt="Edit" className={classnames(classes.edit, classes.delete)} src={deleteIcon} />
                    ),
                    action: "delete",
                },
            ],
        })


        let tasks = this.props.groups || [];
        tasks = tasks.map((item) => {
            item.SyncUserFlag = item.SyncUser === true ? "YES" : "NO";
            return item;
        })

        let totalGroups = this.props.fetchGroupTotal;

        let rowData = tasks.map((list) => {
            return tableData.head.map((obj) => {
                return {
                    ...obj,
                    ...list,
                    html: list[obj.key],
                };
            });
        });
        const { page, pageSize, isDataLoading } = this.state;

        return <Dialog
            onClose={this.props.hideViaIntegrationDialog}
            aria-labelledby="via-integration-dialog"
            open={this.props.viaIntegrationDialogOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"lg"}
            scroll={"body"}
        >
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.close} onClick={this.props.hideViaIntegrationDialog}><CloseIcon /></div>
                <Typography variant={"h6"} className={classes.title}> Via Integration</Typography>
                <div className={classes.searchSection}>
                    <OutlinedInput
                        id={"searchUsers "}
                        placeholder="Search Teams"
                        value={this.state.query}
                        onChange={(event) => {
                            if (event) {
                                this.setState({ query: event.target.value, page: 0 }, () => {
                                    this.fetchData()
                                });
                            }
                        }}
                        fullWidth={true}
                        style={{ width: "100%" }}
                        startAdornment={
                            <InputAdornment>
                                <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                        }
                        endAdornment={
                            this.state.query.length !== 0 ? (
                                <InputAdornment>
                                    <CancelIcon
                                        className={classes.closeIcon}
                                        onClick={() => {
                                            this.setState({
                                                query: "",
                                                page: 0,
                                            }, () => this.props.fetchUser(this.state.query, this.state.page, this.state.pageSize));
                                        }}
                                    />
                                </InputAdornment>
                            ) : null
                        }
                        roundedCorner={true}
                    />
                </div>
                <div className={classes.alert}>
                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                </div>

                <Paper style={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer style={{ maxHeight: '65vh' }}>
                        <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                            <TableHead>
                                <TableRow>
                                    {tableData?.head?.map((e, index) => {
                                        if (e.type === 'action') {
                                            return <TableCell align="center" className={classes.action}>{e.label}</TableCell>
                                        }
                                        return <TableCell style={{ minWidth: e.key === 'Email' ? '200px' : '' }} className={classes.nameCol}>{e.label}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isDataLoading && rowData.slice(page * pageSize, page * pageSize + pageSize).map((team, parentIndex) => {
                                    return <TableRow key={'task_' + team.ID}>
                                        {team.map((item, childIndex) => {
                                            console.log(item, 'item')
                                            return <TableCell key={parentIndex + childIndex} className={classnames(classes.cell, item.cellClass)}>
                                                {item?.type === 'text' && <span>
                                                    {item?.html}
                                                </span>}
                                                {item?.type === 'view' && <span>
                                                    <span onClick={(event) => {
                                                        this.props.showUserUploadDialog(item)
                                                    }}>
                                                        <Chip
                                                            icon={<VisibilityIcon style={{ marginLeft: '5px' }} />}
                                                            label={<div className={classes.mtop1}>{item?.html}</div>}
                                                            size="small"
                                                            clickable
                                                            color="primary"
                                                            variant="outlined"
                                                        />
                                                    </span>
                                                </span>}

                                                {item?.type === 'action' && this.state.Suppressed && <SqureButton
                                                    style={{ display: 'block', margin: '0 auto' }}
                                                    variant={"contained"}
                                                    disabled={this.props.updateGroupProg}
                                                    onClick={() => {
                                                        this.updateOrgGroup(item)
                                                    }}
                                                >
                                                    Move to main view
                                                </SqureButton>}
                                            </TableCell>
                                        })}
                                    </TableRow>
                                })}

                                {(!isDataLoading && (tasks === undefined || tasks === null || tasks.length <= 0)) && <TableRow>
                                    <TableCell colSpan={7} style={{ textAlign: 'center' }} className={classes.nameCol}>
                                        No Teams
                                    </TableCell>
                                </TableRow>}

                                {isDataLoading && <TableRow>
                                    <TableCell colSpan={7} style={{ textAlign: 'center' }} className={classes.nameCol}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}

                            </TableBody>
                        </Table>
                    </TableContainer >
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        component="div"
                        count={totalGroups || 0}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Paper >
                {(this.props.okta?.fetchOktaUserProg === false && this.props.okta?.addTeamMemberProgress === false) && <div className={classes.buttonSection}>
                    <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideViaIntegrationDialog}>Cancel</Button>
                </div>}
            </DialogContent>
        </Dialog>
    }
}
TeamsViaIntegrationDialog.defaultProps = {

};

export default connector(compose(
    withStyles(styles)
)(TeamsViaIntegrationDialog));