import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress } from "@material-ui/core";
import AddProductButton from "../../../../Common/AddProductButton";
import SearchInput from "../../../../Common/Input/SearchInput";
import LayeredCard from "../../Evaluate/Components/Common/LayeredCard";
import DefaultImage from "../../../../../assets/images/evaluation/default.svg";
import AppBarV2 from "../../../AppBarV2";
import {
  fetchOrgWishlistedProducts,
  fetchWishListedProductList,
  fetchWishListedProducts,
} from "../../../../../redux/product/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "../../../../../redux/evaluation/cart/action";

import { updateDrawerStyle } from "../../../../../redux/drawer/action";
import CommonCss from 'commonCss';
import { isEnabledEvaluate } from "util/Common";

const connectedProps = (state) => ({
  productsRec: state.products,
  fetchwishlistedProductListProgress: state.products.fetchwishlistedProductListProgress,
  evaluationCart: state.evaluationCart.evaluation,
  addwishlistedProductsProgress: state.products.addwishlistedProductsProgress,
  fetchProductReactionByTypeProgress: state.products.fetchProductReactionByTypeProgress,
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,
  fetchOrgWishlistedProductListProgress: state.products.fetchOrgWishlistedProductListProgress,
  myOrgWishlistedProducts: state.products.myOrgWishlistedProducts,
});

const connectionActions = {
  fetchWishListedProductList: fetchWishListedProductList,
  fetchOrgWishlistedProducts: fetchOrgWishlistedProducts,
  fetchWishListedProducts: fetchWishListedProducts,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    container: {
      marginTop: theme.spacing(6.4),
      maxHeight: "calc(100vh - 200px)",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: 250,
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
    },
    emptyText: {
      textAlign: "center",
      height: 100,
      paddingTop: 20,
    },
  });

class OrgWishlisted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObj: {
        search_name: "applications",
        type: "all",
        page: 0,
        size: 500,
        text: "",
        filter_fields_value: {},
        sort_field: null,
        ascending: false,
      },
      searchProduct: '',
      productDatas: [],
      isLoadingData:true
    };
  }
  componentDidMount() {
    const urlPrams = new URLSearchParams(this.props.location.search);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);

    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    this.props.fetchWishListedProducts();
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (!this.props.fetchProductReactionByTypeProgress && prevProps.fetchProductReactionByTypeProgress) {
      var reactionProducts = this.props.productsRec?.wishlistProduct.Data || [];
      if (reactionProducts.length > 0) {
        const productIDs = reactionProducts.map((o) => o.ID);
        this.getProductReactions(productIDs);
      }
    }

    if (!this.props.addwishlistedProductsProgress && prevProps.addwishlistedProductsProgress) {
      this.fetchData();
    }

    if (!this.props.fetchOrgWishlistedProductListProgress && prevProps.fetchOrgWishlistedProductListProgress) {
      this.setState({
        productDatas: this.props.myOrgWishlistedProducts?.Data || [],
        isLoadingData:false
      })
    }

  }

  fetchData() {
    this.props.fetchOrgWishlistedProducts("org_wishlisted");
  }

  addToCart(id) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(id);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(id) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, id);
      } else {
        this.props.createEvaluationCart(id, this.props.evaluationCart.ID);
      }
    }
  }

  getProductReactions(productIDs) {
    this.props.fetchProductReaction("featured", {
      productIDs,
      source: "featured",
      sourceData: "featured",
    });

    setTimeout(() => {
      this.props.fetchProductReaction("all_product_blocking", {
        productIDs,
        source: "all_product_blocking",
        sourceData: "all_product_blocking",
      });
    }, 2000);
  }

  render() {
    const { classes } = this.props;
    if(this.state.isLoadingData){
      return <div style={{textAlign:'center',marginTop:20}}>
        <CircularProgress/>
      </div>
    }
    const { searchProduct } = this.state;
    var addedToCart =
      this.props.evaluationCart == null
        ? []
        : this.props.evaluationCart.Products.map((p) => p.ID);

    let title = "";
    let placeholder = "";

    title = "Org Wishlisted";
    placeholder = "Search Org Wishlisted Products";

    var reactionProducts = this.state.productDatas || [];

    if (searchProduct.length) {
      reactionProducts = reactionProducts.filter(item => (
        item.Name.toLocaleLowerCase().includes(searchProduct.toLocaleLowerCase())
      ))
    }
    var total = reactionProducts.length || 0;

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForUsersCheck')
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForAdminsCheck')
    let isShowCheckBox = true;
    if (((!EnableEvaluateForAdminsCheck && this.props.userRole !== 'OrgUser') || (!EnableEvaluateForUsersCheck && this.props.userRole === 'OrgUser'))) {
      isShowCheckBox = false;
    }

    return (
      <>
        <AppBarV2 title={title} withBack={true} dropdown={true} />
        <div className={classes.rootContainer}>
          <div className={classes.stickyBar}>
            <Grid
              container
              justifyContent="space-between"
              ref={(divElement) => {
                this.divElement = divElement;
              }}
            >
              <Grid item>
                <p className={classes.productCount}>
                  {total}
                  {total > 1 ? " Products" : " Product"} found
                </p>
              </Grid>
              {/* {match?.params && match?.params[0] === "my-wishlisted" && ( */}
              <Grid item>
                <SearchInput
                  roundedCorner
                  placeholder={placeholder}
                  showTypes={false}
                  isFilter={false}
                  page="metrics"
                  searchIcon={true}
                  searchObj={this.state.searchObj}
                  onChange={(searchObj) => {
                    this.setState({ searchProduct: searchObj?.text })
                    // this.setState({ searchObj }, () => {
                    //   this.fetchData();
                    // });
                  }}
                />
              </Grid>
              {/* )} */}
              <Grid item>
                <AddProductButton title={'Add Product'}/>
              </Grid>
            </Grid>
          </div>
          <div className={classes.container} id="allProducts">
            <InfiniteScroll
              scrollableTarget="allProducts"
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                marginRight: 20,
              }} //To put endMessage and loader to the top.
              dataLength={total} //This is important field to render the next data
              next={() => {
                var me = this;
                this.setState(prevState => ({
                  searchObj: {
                    ...prevState.searchObj,
                    page: prevState.searchObj.page + 1
                  }
                }), () => {
                  me.fetchData();
                });
              }}
              hasMore={false && total > (this.state.searchObj.page + 1) * this.state.searchObj.size}
              endMessage={
                <>
                  {reactionProducts?.length === 0 &&
                    !this.props.fetchwishlistedProductListProgress && (
                      <p className={classes.emptyText}>
                        <span>
                          <span>
                            Not finding what you are looking for? Try searching
                            based on{" "}
                          </span>
                          <span
                            className={classes.addProductLink}
                            onClick={() => this.setSearchType("features")}
                          >
                            features{" "}
                          </span>
                          <span>or </span>
                          <span
                            className={classes.addProductLink}
                            onClick={() => this.setSearchType("subcategories")}
                          >
                            sub-categories.
                          </span>
                        </span>
                      </p>
                    )}
                </>
              }
            >
              <Grid container spacing={3}>
                {reactionProducts !== null &&
                  reactionProducts.map((product, index) => (
                    <Grid item lg={2} md={3} sm={4} xs={6} key={product.ID}>
                      <LayeredCard
                        title={product.Name}
                        subTitle={product.CompanyName}
                        description={product.Description}
                        image={
                          product.ProductLogo == null ||
                            product.ProductLogo.length === 0
                            ? DefaultImage
                            : product.ProductLogo
                        }
                        toogleCheck={() => {
                          this.addToCart(product.ID);
                        }}
                        onClick={() => { }}
                        numLayer={0}
                        checked={addedToCart.indexOf(product.ID) !== -1}
                        showCheckbox={isShowCheckBox}
                        id={product.ID}
                        uniqueId={product.ID + "_m_" + index}
                        type={"product"}
                        IsExisting={product.IsExisting}
                        isActionButtons
                        showReactionResponses
                        productStates={product?.ProductStates}
                        onClickState={(state) => this.handleOnClickState(state)}
                        typeOfBlock="all_product_blocking"
                        typeOfFeatured="featured"
                      />
                    </Grid>
                  ))}
              </Grid>
              {this.props.fetchwishlistedProductListProgress && (
                <Grid container alignItems="center" justify="center" style={{ marginTop: '2em' }}>
                  <CircularProgress />
                </Grid>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(OrgWishlisted));
