import {
  START_FETCH_ORG_FORM_BY_ID, END_FETCH_ORG_FORM_BY_ID,
  START_FETCH_MASTER_FORM_BY_ID, END_FETCH_MASTER_FORM_BY_ID,
  START_ADD_CUSTOM_COMPONENT_RESPONSE, END_ADD_CUSTOM_COMPONENT_RESPONSE,
  START_FETCH_WORKFLOW_FORM_RESPONSE, END_FETCH_WORKFLOW_FORM_RESPONSE,
} from "./action";


// Initial State
const initState = {
  fetchOrgFormsByIdProgress: false,
  orgFormData: null,

  fetchMasterFormsByIdProgress: false,
  masterFormData: null,

  componentUserResponse: [],
  componentUserResponseError: null,
  addCustomComponentUserResponseProgress: false,

  fetchWokflowFormResponseProgress: false,
  workflowFormResponse: null,
}


export function publicFormReducers(state = initState, action) {
  switch (action.type) {
    case START_FETCH_ORG_FORM_BY_ID:
      return {
        ...state,
        fetchOrgFormsByIdProgress: true,
        orgFormData: null
      }
    case END_FETCH_ORG_FORM_BY_ID:
      return {
        ...state,
        fetchOrgFormsByIdProgress: false,
        orgFormData: action.payload.success
      }

    case START_FETCH_MASTER_FORM_BY_ID:
      return {
        ...state,
        fetchMasterFormsByIdProgress: true,
        masterFormData: null
      }
    case END_FETCH_MASTER_FORM_BY_ID:
      return {
        ...state,
        fetchMasterFormsByIdProgress: false,
        masterFormData: action.payload.success
      }


    case START_ADD_CUSTOM_COMPONENT_RESPONSE:
      return {
        ...state,
        addCustomComponentUserResponseProgress: true,
        componentUserResponseError: null
      }

    case END_ADD_CUSTOM_COMPONENT_RESPONSE:
      return {
        ...state,
        componentUserResponse: action.payload.success,
        componentUserResponseError: action.payload.error,
        addCustomComponentUserResponseProgress: false,
      };

    case START_FETCH_WORKFLOW_FORM_RESPONSE:
      return {
        ...state,
        fetchWokflowFormResponseProgress: true,
        workflowFormResponse: null
      }

    case END_FETCH_WORKFLOW_FORM_RESPONSE:
      return {
        ...state,
        fetchWokflowFormResponseProgress: false,
        workflowFormResponse: action.payload.success
      }

    default:
      return state;
  }

}