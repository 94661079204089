import React from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import classnames from "classnames";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CommonFn } from "services/commonFn";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { Avatar } from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    requirement: {
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      // height: 220,
      cursor: "pointer",
      // padding: '0 .5em',
      // marginTop: 18,
      borderTop: "solid 1px #EEEEEE",
      display: "flex",
      overflow: "hidden",
      // alignItems: 'center',
      flexDirection: "column",
      // justifyContent: 'space-between',
      // maxHeight: 195,
      position: "relative",
      transition: "all linear .5s",
      "&.rootRequirementHight": {
        height: 300,
      },

      "&.options-hide": {
        pointerEvents: "none",
      },
    },
    yourResponse: {
      textAlign: "left",
      letterSpacing: "0px",
      color: "#707070",
      opacity: 1,
      fontSize: "13px",
      transition: "height 0.3s ease-in",
    },
    response: {
      textAlign: "left",
      letterSpacing: "0px",
      color: "#04363D",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      placeContent: "center space-between",
      alignItems: "center",
      width: "100%",
      transition: "all linear 1s",
      position: "relative",
      // marginTop: 16,
      // marginBottom: 6,
      "& [class*='MuiInputBase-inputMultiline']": {
        fontSize: 13,
        height: 70,
        color: "#707070",
      },
    },
    "@global": {
      ".rootRequirementHight": {
        "& [class*='response-addText']": {
          height: "150px",
        },
        "& [class*='editor-formControl']": {
          maxHeight: "150px",
        },
      },
    },
    expand: {
      transform: "rotate(0deg)",
      padding: 0,
      borderRadius: 3,
      border: "1px solid #EDEDED",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    tabPanel: {
      backgroundColor: "#fff",
      minHeight: theme.spacing(40),
    },
    tabPanelRes: {
      backgroundColor: "#fff",
      "& [class*=MuiBox-root]": {
        padding: "10px",
      },
      "& [class*=makeStyles-label]": {
        display: "none",
      },
    },
    responseItems: {
      "& [class*=MuiTabs-root]": {
        minWidth: "100px !important",
      },
      "& [class*=MuiTab-root]": {
        minWidth: "100px !important",
      },
    },
    userResponder: {
      border: "1px solid #f2f2f2",
      boxShadow: "0px 1px 10px -5px rgb(157 133 133 / 77%)",
      padding: 10,
      marginBottom: 10,
      borderRadius: 8,
    },
    userInfoItem: {
      display: "flex",
      alignItems: "center",
      "& span": {
        marginLeft: 10,
      },
    },
    requirementExpanded01: {
      border: "1px solid #8d7d7d",
      backgroundColor: "white",
      height: "100%",
    },
    answerBox: {
      backgroundColor: "#fff",
      borderBottom: "1px solid #f1f1f1",
    },
    disableForm: {
      marginTop: 12,
      marginLeft: 5,
    },
  });

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingPoperEl: null,
      editNote: false,
      editSellerNote: false,
      sellerNoteUpdated: false,
      fetchSellerThread: "",
      note: "",
      response: "",
      value: 0,
      openDownloadModal: false,
      isDotHidden: false,
      selectedTab: null,
      addComment: false,
    };
    this.avatarName = this.avatarName.bind(this);
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {}

  render() {
    const classes = this.props.classes;

    var response = this.props.requirement?.answers || {};

    let answerType = response?.answer_type;
    let options = response?.options_answer;
    let printableAnswer = null;
    try {
      if (![2, 3, 4, 14, 15].includes(answerType)) {
        let answer = response?.answer;
        if (answer && answerType === 11) {
          printableAnswer = CommonFn.formateDate(answer, true);
        } else if (answer && answerType === 13) {
          let data = JSON.parse(answer);
          printableAnswer = data.value + data.type.label;
        } else if (answer && answer !== null && answer !== "" && answerType === 6) {
          printableAnswer = answer?.map((dt) => {
            return (
              <p>
                <a style={{ color: "#4b86ff" }} without rel="noreferrer" target={"_blank"} href={dt?.url}>
                  {dt.name}
                </a>
              </p>
            );
          });
        } else if (answerType === 1 || answerType === 7) {
          printableAnswer = <SlateReadonlyField initValue={answer} />;
        } else {
          printableAnswer = answer;
        }
      } else {
        printableAnswer = options?.map((u) => u.title).join(", ") || "-";
      }
    } catch (e) {}

    return (
      <div className={classes.requirementExpanded01}>
        <div className={classnames(classes.requirement, this.props.respondable ? "options-show" : "options-hide")}>
          <div className={classes.responseItems}>
            <AppBar position="static" color="default" className={classes.answerBox}>
              <Tabs value={1} indicatorColor="primary" textColor="primary">
                <Tab value={1} label="Response" {...a11yProps(0)} />
              </Tabs>
            </AppBar>

            <TabPanel className={classes.tabPanelRes} value={1} index={1}>
              <div className={classes.yourResponse}>
                {response?.answer === "" && response?.options_answer.length <= 0 ? (
                  <div style={{ textAlign: "center" }}>No Response</div>
                ) : (
                  <div className={classes.userResponder}>
                    <div>
                      <Typography className={classes.userInfoItem}>
                        <Tooltip
                          placement="top"
                          title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{response?.created_by}</span>}
                          arrow
                        >
                          <Avatar className={classes.avatarImg}> {this.avatarName(response?.created_by || "")}</Avatar>
                        </Tooltip>
                        <span>{response?.created_by}</span>
                      </Typography>
                    </div>

                    <div className={classes.disableForm}>
                      <div className={classes.textWrap}>{printableAnswer}</div>
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Response));
