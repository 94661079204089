import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { Button, CircularProgress } from "@material-ui/core";
import EvaluationBar from "../../Common/EvaluationBar";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import { updateEvaluationMetaDataStatus } from "redux/evaluation/metadata/action";
import FinishUserWiseProgress from "../../Common/WorkflowProgress/FinishUserWiseProgress";
import ReviewsEmptyState from "assets/images/reviews_empty_state.svg";
import Image from 'Components/Common/image';
import { fetchUserWorkflowTimeline } from "redux/workflow/action";

import {
  showConfirmCallbackDialog,
  hideConfirmCallbackDialog
} from "redux/applications/action";
import { getPreviousComponent, getFinishComponent } from "util/Common";

import ConfirmCallbackDialog from "Components/Common/ConfirmCallbackDialog";
import { showSnackBar } from "redux/snackbar/action";
import EvaluationAddToExistingDialog from 'Components/Dialogs/EvaluationAddToExistingDialog';
import { showAddToExistingDialog } from "redux/vendor/action";
import { getNewPlatform } from "util/Environment";

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  updateStatusProgress: state.evaluationMetaData.updateStatusProgress,
  userTimeline: state.workflow.userTimeline,
  userRole: state.authUser.user?.Role,
  user: state.authUser.user,
  openAddToExistingDialog: state.vendors.showAddToExistingDialog,
});

const connectionActions = {
  updateDetails: updateEvaluationMetaDataStatus,
  showConfirmCallbackDialog: showConfirmCallbackDialog,
  hideConfirmCallbackDialog: hideConfirmCallbackDialog,
  showSnackBar: showSnackBar,
  fetchUserWorkflowTimeline: fetchUserWorkflowTimeline,
  showAddToExistingDialog: showAddToExistingDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  DashboardNoDataLayout: {
    margin: '22px 0px 0px 0px',
    minHeight: 450,
    textAlign: 'center',
    // background: '#ffffff',
    padding: '23px',
    borderRadius: '6px',
    // '& button': {
    //   margin: '20px 0',
    //   color: '#fff',
    //   background: '#3C3C3C',
    //   '&:hover': {
    //     color: '#FFF',
    //     background: '#3C3C3C',
    //   }
    // }
  },
  link: {
    cursor: 'pointer',
    color: 'blue'
  },
  bottomActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  finishBottom: {
    marginRight: 20
  },
  loader: {
    textAlign: 'center',
    marginTop: 30
  }
});

class FinishForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finishTitle: '',
      finishDescription: '',
      isRedirectRequire: false,
      isShowRedirectMessage: false,
      redirectLink: '',
      isAddedTitleFromConfiguration: false,
      previousComponentData: null,
      isLoadFinish: false,
      isShowSummary: true,
      isAutoFinishRequire: false,
      isConfigCheckDone: false,
      isOnlyFinish: false,
      isLoading: true,
      openCounter: 0,
      isredirectToVendorHomeRequire: false
    }
    this.callBack = this.callBack.bind(this);
    this.onCancelWithdraw = this.onCancelWithdraw.bind(this);
    this.redirectCheck = this.redirectCheck.bind(this);
  }

  componentDidMount() {
    this.checkPreviousComponent();
    this.checkWorkflowConfiguration();
    // this.props.fetchUserWorkflowTimeline(this.props.match.params.evaluation_id, [], 0);
  }

  checkForExisting = () => {
    // if (this.props.evaluation?.Products?.length) {
    //   if (this.props.evaluation?.Products?.some(item => (!item.IsExisting && (item.Type !== "MASTER_VENDOR_SERVICE")))) {
    //     this.props.showAddToExistingDialog();
    //   }
    // }
    this.setState({
      isLoading: false
    })
  };

  componentDidUpdate(prevProps) {
    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.props.isWorkFlow) {
        this.checkWorkflowConfiguration();
      }
    }

    if (this.props.updateStatusProgress === false && prevProps.updateStatusProgress === true) {
      this.props.showSnackBar("Request completed successfully.", "success", 3000)
      // this.setState({
      //   isLoadFinish: true,
      //   isShowSummary: true
      // })
      this.redirectCheck()

    }
  }

  finishEvaluation() {
    this.props.updateDetails(this.props.match.params.evaluation_id, {
      status: 4
    })
  }

  checkFinishConditions() {
    if (this.props.evaluation?.Status && this.props.evaluation?.Status !== 4 && this.props.evaluation?.Status !== 5) {
      if (this.props.userRole === "OrgAdmin" || this.props.userRole === "OrgManager" || this.props.evaluation?.UserID === this.props.user.ID) {
        if (this.state.isAutoFinishRequire) {
          this.finishEvaluation();
        } else {
          this.props.showConfirmCallbackDialog();
        }
      } else {
        this.setState({
          isLoadFinish: true,
          isShowSummary: false
        }, () => {
          this.redirectCheck()
        })
      }
    } else {
      this.setState({
        isLoadFinish: true,
        isShowSummary: true
      }, () => {
        this.redirectCheck()
      });
    }
  }

  checkWorkflowConfiguration() {
    if (this.state.isConfigCheckDone) {
      return;
    }
    const { workflowComponentsForms, workflowComponentDetail } = this.props;
    if (workflowComponentsForms === null || workflowComponentDetail === null || this.state.isConfigCheckDone === true) {
      return;
    }
    var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      this.setState({
        isConfigCheckDone: true
      }, () => {
        this.checkFinishConditions()
      })
    } else {


      var redirectFormId = workflowComponentsForms.filter(o => o.Name === "finish_redirect").map(o => o.ID)[0];
      var redirectUrlValue = configurations.filter(o => o.FormID === redirectFormId).map(o => o.Value)[0];
      redirectUrlValue = redirectUrlValue === 'false' ? false : true;

      if (redirectUrlValue === true) {
        var redirectUrlFormId = workflowComponentsForms.filter(o => o.Name === "finish_redirect_url").map(o => o.ID)[0];
        var redirectUrl = configurations.filter(o => o.FormID === redirectUrlFormId).map(o => o.Value)[0];
        if (redirectUrl !== null && redirectUrl !== undefined) {
          this.setState({
            isRedirectRequire: true,
            redirectLink: redirectUrl
          }, () => {
            // setTimeout(() => {
            //   this.openInNewTab(this.state.redirectLink)
            // }, 30000);
          })
        }
      }

      var finishMsgTitleFormId = workflowComponentsForms.filter(o => o.Name === "finish_message_title").map(o => o.ID)[0];
      var finishTitleValue = configurations.filter(o => o.FormID === finishMsgTitleFormId).map(o => o.Value)[0];
      if (finishTitleValue !== null && finishTitleValue !== undefined) {
        this.setState({
          finishTitle: finishTitleValue,
          isAddedTitleFromConfiguration: true
        })
      }

      var finishDescriptionFormId = workflowComponentsForms.filter(o => o.Name === "finish_message_description").map(o => o.ID)[0];
      var finishDescriptionValue = configurations.filter(o => o.FormID === finishDescriptionFormId).map(o => o.Value)[0];
      if (finishDescriptionValue !== null && finishDescriptionValue !== undefined) {
        this.setState({
          finishDescription: finishDescriptionValue
        })
      }

      var redirectToVendorHomeId = workflowComponentsForms.filter(o => o.Name === 'finish_redirect_to_vendor_home').map(o => o.ID)[0];
      var redirectToVendorHomeValue = configurations.filter(o => o.FormID === redirectToVendorHomeId).map(o => o.Value)[0];
      redirectToVendorHomeValue = redirectToVendorHomeValue === 'true' ? true : false;
      this.setState({
        isredirectToVendorHomeRequire: redirectToVendorHomeValue,
      })

      // console.log(workflowComponentsForms,'workflowComponentsForms----')
      // console.log(workflowComponentDetail,'workflowComponentDetail')
      var autoFinishId = workflowComponentsForms.filter(o => o.Name === 'auto_finish').map(o => o.ID)[0];
      var autoFinishValue = configurations.filter(o => o.FormID === autoFinishId).map(o => o.Value)[0];
      autoFinishValue = autoFinishValue === 'true' ? true : false;
      this.setState({
        isAutoFinishRequire: autoFinishValue,
        isConfigCheckDone: true
      }, () => {
        this.checkFinishConditions()
      })

    }

  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  checkPreviousComponent() {
    const { userTimeline } = this.props;
    if (userTimeline) {
      if (userTimeline.length === 1 && userTimeline[0].ComponentType === 'FINISH') {
        this.setState({
          isOnlyFinish: true
        })
      } else {
        let preComponent = getPreviousComponent(userTimeline, this.props.match.params.component_id);
        if (preComponent !== undefined && preComponent !== null && preComponent !== '') {
          this.setState({
            previousComponentData: preComponent
          })
        }
      }
    }
  }

  callBack() {
    this.finishEvaluation();
    this.props.hideConfirmCallbackDialog();
  }

  onCancelWithdraw() {
    this.props.hideConfirmCallbackDialog();
    if (this.state.previousComponentData !== null) {
      this.props.history.push("/app/evaluate/" + this.state.previousComponentData?.EvaluationId + "/workflow/" + this.state.previousComponentData?.WorkflowId + "/component/" + this.state.previousComponentData?.ComponentId);
    } else {
      window.location.reload()
    }
  }

  routeToProjectSummary() {
    let finishComponent = getFinishComponent(this.props.userTimeline);
    if (finishComponent) {
      this.props.history.push(`/app/evaluate/${finishComponent.EvaluationId}/workflow/${finishComponent.WorkflowId}/component/${finishComponent.ComponentId}/workflow_summary`)
    } else {
      this.props.history.push(`/app/evaluate/${this.props.match.params.evaluation_id}/workflow/${this.props.match.params.workflow_id}/component/${this.props.match.params.component_id}/workflow_summary`)
    }
  }

  checkURLOrigin(url) {
    let isSame = false;
    var host = window.location.host;
    var sameOrigin = url.indexOf(host);
    if (sameOrigin >= 0) {
      isSame = true;
    }
    return isSame;
  }

  redirectCheck() {
    const { isRedirectRequire, redirectLink, isOnlyFinish, isredirectToVendorHomeRequire } = this.state;
    const { evaluation } = this.props;
    // console.log(evaluation,'Evaluationn1111')
    // console.log(isredirectToVendorHomeRequire,'isredirectToVendorHomeRequire')
    if (isredirectToVendorHomeRequire && evaluation && evaluation?.Products.length <= 1) {
      let product = evaluation?.Products[0]
      let url = ''
      if (product.Type === "CUSTOM_VENDOR") {
        url = '/app/vendors/org-vendor-details/' + product?.EntityID
      } else if (product.Type === "MASTER_VENDOR" && product.IsExisting) {
        url = '/app/vendors/org-vendor-details/' + product?.EntityID
      } else if (product.Type === "MASTER_VENDOR") {
        url = '/app/vendors/all-vendors/' + product?.EntityID
      } else if (product.Type === "CUSTOM_VENDOR_SERVICE") {
        url = ''
      } else if (product.Type === "MASTER_VENDOR_SERVICE") {
        url = ''
      } else if (product.Type === "PRODUCT" && product.IsExisting) {
        url = '/app/products/org-product-details/' + product?.EntityID
      } else if (product.Type === "PRODUCT") {
        url = '/app/products/product-details/' + product?.EntityID
      } else if (product.Type === "CUSTOM_VENDOR_V2") {
        url = `${getNewPlatform()}/entity-catalog/${product.EntityType}/${product?.EntityID}/-`
        window.open(url, "_self")
        return
      }
      // console.log(url,'RedirectURl')
      if (url !== '') {
        this.props.history.push(url)
      }
    } else if (isRedirectRequire) {
      if (isOnlyFinish) {
        let isSameOrigin = this.checkURLOrigin(redirectLink)
        if (isSameOrigin) {
          window.location.href = redirectLink
        } else {
          this.checkForExisting()
          this.openInNewTab(redirectLink)
        }
      } else {
        this.checkForExisting()
        this.setState({
          isShowRedirectMessage: true,
          openCounter: this.state.openCounter + 1
        }, () => {
          if (this.state.openCounter === 1) {
            setTimeout(() => {
              this.openInNewTab(this.state.redirectLink)
            }, 30000);
          }
        })
      }
    } else {
      this.checkForExisting()
    }
  }

  render() {
    const classes = this.props.classes;
    let finishTitle = this.state.finishTitle;
    const { isShowSummary, isLoading } = this.state;
    if (!this.state.isAddedTitleFromConfiguration && this.props.evaluation !== null) {
      finishTitle = this.props.evaluation?.Type === 3 ? 'Survey Completed' : ''
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "You are about to complete the request. You will not be able to edit your request.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    if (!this.state.isLoadFinish) {
      return <ConfirmCallbackDialog
        messageData={messageData}
        callBack={this.callBack}
        closeCallBack={this.onCancelWithdraw}
        closeDialog={this.onCancelWithdraw}
      />
    }

    if (isLoading) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }

    return (<div>

      {this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation.Type !== 3 && <EvaluationBar
        isShowCollabrations={false}
        isShowEvalDetails={false}
        isWorkFlow={this.props.isWorkFlow}
        isShowFinishSummaryBtn={isShowSummary ? true : false}
        isShowProjectProgress={true}
      />}

      {false && isShowSummary && <div style={{ margin: '0 auto', width: '80%' }}>
        <FinishUserWiseProgress />
      </div>}

      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <div className={classes.DashboardNoDataLayout}>
          {isShowSummary && <Image src={ReviewsEmptyState} />}
          <h2>{isShowSummary ? finishTitle.length > 0 ? finishTitle : 'Project Completed' : 'Project Completed'}</h2>
          {this.state.finishDescription && <div style={{ width: '50%', margin: '0 auto' }}>
            <SlateInputFieldV2
              readOnly={true}
              isToolBar={false}
              placeholder=""
              style={{
                margin: 0,
              }}
              as={SlateInputFieldV2}
              initValue={this.state.finishDescription}
              formControlStyle={{ border: 'none', overflow: 'hidden' }}
              textContainerStyle={{ textAlign: 'center', minHeight: '100px !important', margin: "0px", background: "#f7f7f7", color: "#707070", fontSize: 16 }} />
          </div>}

          {this.state.isShowRedirectMessage && this.state.isRedirectRequire && <p>You are now being redirected to <span className={classes.link} onClick={() => {
            this.openInNewTab(this.state.redirectLink);
          }}>{this.state.redirectLink} </span> in 30 seconds.</p>}





          {false && <div className={classes.bottomActions}>

            {this.props.evaluation.EvaluationUserStatus !== 4 && <Button
              variant="contained"
              color="secondary"
              className={classes.finishBottom}
              onClick={() => {
                this.finishEvaluation();
              }}
            >
              Finish
            </Button>}

            <Button variant="outlined" color="secondary" className={classes.finishBottom}
              onClick={() => { this.props.history.push('/app/home') }}
            >Home</Button>

            <Button variant="contained" color="secondary" className={classes.button}
              onClick={() => {
                this.routeToProjectSummary()
              }}
            >Project Summary</Button>
          </div>}

        </div>
      </div>
      {this.props.openAddToExistingDialog && <EvaluationAddToExistingDialog />}
    </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(FinishForm));
