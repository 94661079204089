import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {fetchEvaluationMetaData,updateEvaluationMetaData, emptyProductsList, fetchProductsList} from "../../../../../../../redux/evaluation/metadata/action"
import {showEvaluationCriteriaAddDialog,
    fetchUserRequirementResponse,fetchScores } from "../../../../../../../redux/evaluation/criteria/action"
import {showFeedbackTakeDialog} from "../../../../../../../redux/evaluation/feedback/action"
import {showSnackBar} from "../../../../../../../redux/snackbar/action"
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import EvaluationBar from "../Common/EvaluationBar";

import BottomAction from '../Common/BottomAction'; 
import SqureButton from 'Components/Common/SqureButton';
import ArrowForward from "../../../../../../../assets/images/arrow_forward_white.svg"
import CriteriaRequirement from '../Common/CriteriaRequirement';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Product from "./products";
import FeedbackDialog from "./FeedbackDialog";

import { fetchProductRequirementDetails } from "redux/product/action";


// Drawers
import CollectiveResponseDialog from 'Components/Dialogs/CollectiveResponseDialog'

// Shared
import Image from 'Components/Common/image.jsx'
import ProductSlider from 'Components/Common/ProductSlider'
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
// redux
import {showEvaluationMetaDialog} from "redux/evaluation/metadata/action";
import { toggleEvaluateView, toggleEvaluateNeverReviewed } from "redux/applications/action";
import {hideFeedbackTakeDialog} from "redux/evaluation/feedback/action"

import classnames from "classnames";
import {addEvaluationFeedback} from "redux/evaluation/feedback/action"
import moment from 'moment';
import {showEvaluationLinkDialog} from "redux/evaluation/link/action";
import { Link as LinkIcon } from '@material-ui/icons';

const connectedProps = (state) => ({
    evaluation:state.evaluationMetaData.evaluation,
    collectiveResponseDialogOpen: state.evaludationCriteria.collectiveResponseDialogOpen,
    fetchProgress:state.evaluationMetaData.fetchProgress,
    updateProgress:state.evaluationMetaData.updateProgress,
    updateError:state.evaluationMetaData.errorUpdate,
    addResponseProgress:state.evaludationCriteria.addResponseProgress,
    addFeedbackProgress:state.evaluationFeedback.addFeedbackProgress,
    addFeedbackError:state.evaluationFeedback.addFeedbackError,
    user: state.authUser.user,
    productRequirement:state.products.productRequirement,
    evaluateView: state.applications.evaluateView,
    evaluateViewId: state.applications.evaluateViewId,
    evaluateNeverReview: state.applications.evaluateNeverReview,
    evaluateNeverReviewId: state.applications.evaluateNeverReviewId,
    criterias:state.evaludationCriteria.criterias,
    userReponses:state.evaludationCriteria.userReponses,
    hideFeedbackTakeDialog: hideFeedbackTakeDialog,
    collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
});

const connectionActions = {
    fetchEvaluationMetaData:fetchEvaluationMetaData,
    updateEvaluationMetaData:updateEvaluationMetaData,
    showSnackBar:showSnackBar,
    showEvaluationCriteriaAddDialog:showEvaluationCriteriaAddDialog,
    fetchUserRequirementResponse:fetchUserRequirementResponse,
    showFeedbackTakeDialog:showFeedbackTakeDialog,
    fetchScores:fetchScores,
    showEvaluationMetaDialog:showEvaluationMetaDialog,
    emptyProductsList: emptyProductsList,
    fetchProductsList: fetchProductsList,
    fetchProductRequirementDetails:fetchProductRequirementDetails,
    toggleEvaluateView: toggleEvaluateView,
    toggleEvaluateNeverReviewed: toggleEvaluateNeverReviewed,
    addEvaluationFeedback: addEvaluationFeedback,
    showEvaluationLinkDialog:showEvaluationLinkDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    'criteriaTitle':{
        fontSize:theme.spacing(2.5),
        margin:theme.spacing(1.6,0),
        color: '#282D30'
    },
    bottomNav:{
        padding:theme.spacing(1.6,0)
    },
    prodPad:{
        // height:"100px"
    },
    productBox:{
        minWidth: 450,
        marginRight: 30,
        borderRadius: 5
    },
    productContainer:{
        display: 'flex',
        overflow: 'auto',
        marginBottom: 10,
    },
    contentContainer:{
        marginBottom:'100px'
    },
    finalizeBtn: {
        textAlign: 'right',
    },
    saveBtn: {
        display:'inline-block',
        marginRight: 30
    },
    subText: {
        paddingBottom: theme.spacing(2)
    },
    content: {
        padding: theme.spacing(4, 10),
        background: "#F7F7F7",
        minHeight: "100vh"
    },
});

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
};

class InProgress extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedProducts: [],
            isWorkflow:false
        }
        this.updateStatus   =   this.updateStatus.bind(this);
    }

    componentDidMount(){
        this.props.fetchEvaluationMetaData(this.props.match.params.evaluation_id)
        this.props.fetchUserRequirementResponse(this.props.match.params.evaluation_id)
        this.props.fetchScores(this.props.match.params.evaluation_id,[this.props.user.ID]);
    }

    componentDidUpdate(prevProps){
        if(this.props.addResponseProgress == false && prevProps.addResponseProgress == true){
            this.props.fetchScores(this.props.match.params.evaluation_id);
            this.props.fetchUserRequirementResponse(this.props.match.params.evaluation_id)
        }

        if(this.props.fetchProgress === false && prevProps.fetchProgress === true){
            if(!this.props.errorFetch) {
                this.setReview()
            }
        }

        if(this.props.updateProgress == false && prevProps.updateProgress == true){
            if(this.props.updateError == null){
                this.setReview()
                //Migrate to different page
                if(this.props.evaluation.Status == 4){
                    this.props.history.push("/app/evaluate/"+this.props.evaluation.ID+"/summary");
                }
                this.props.showSnackBar("Updated Successfully.","success",3000)
            } else {
                this.props.showSnackBar("Failed to update evaluation status.","error",3000)
            }
        }

        if(this.props.addFeedbackProgress == false && prevProps.addFeedbackProgress == true){
            if(this.props.addFeedbackError == null){
                //Migrate to different page
                if(this.props.user.ID == this.props.evaluation.UserID){
                    this.props.updateEvaluationMetaData(this.props.match.params.evaluation_id,{
                        Status:4
                    });
                } else if(this.props.evaluation?.ID) {
                    this.props.hideFeedbackTakeDialog();
                    this.props.history.push("/app/evaluate/"+this.props.evaluation.ID+"/summary");
                }
                
            } 
        }
        if(this.props.evaluation?.Products?.length !== prevProps.evaluation?.Products?.length) {
            this.props.emptyProductsList();
            let products = this.props.evaluation?.Products;
            if(products) {
               for(let i = 0; i < products?.length; i++) {
                   this.props.fetchProductsList(products[i].ID);
               }
            }
        }

        if(this.props.fetchProgress == false && prevProps.fetchProgress == true){
            let products = this.props.evaluation?.Products;
            if(products !== undefined){
                this.props.fetchProductRequirementDetails(products.map((i)=>{
                    return i.ID
                }), this.props.evaluation.ID, window.location.pathname)
            }
            
        }
    }

    componentWillUnmount() {
        try {
            this.props.toggleEvaluateView(false, null);
            this.props.toggleEvaluateNeverReviewed(false, null);
            document.body.classList.remove('evaluate-view');
        } catch(e) {}
    }

    setReview = () => {
        try {
            if(this.props.evaluation?.Status === 4 && this.props.evaluation?.Type === 2 && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), 'days') > 30) {
                document.body.classList.add('evaluate-view');
                this.props.toggleEvaluateView(true, this.props.evaluation?.Products[0]?.ID);
            } else if(((this.props.evaluation?.Status <= 3 || this.props.evaluation?.Status === 4 && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), 'days') <= 30) && this.props.evaluation?.Type === 2)) {
                this.props.toggleEvaluateNeverReviewed(true,  this.props.evaluation?.Products[0]?.ID);
            }
        } catch(e) {}
    }

    isMandatoryResponseFilled(){
        var requirementIdCriteriaMap  = {};
        var criteriaProductMap  = {};
        var criteriQuetionCount  = {};
        var mandatoryCriteria = [];
        let products = this.props.evaluation?.Products;
        if(this.props.criterias !== null){
            this.props.criterias.forEach((criteria)=>{
                if(!criteria.is_mandatory){
                    return;
                }
                mandatoryCriteria.push(criteria.id)
                criteriQuetionCount[criteria.id] = criteria.requirements.length;
                criteria.requirements.forEach((requirement)=>{
                    requirementIdCriteriaMap[requirement.id] = criteria.id;
                });
                if(criteriaProductMap[criteria.id] === undefined){
                    criteriaProductMap[criteria.id] = {}
                }
                products.forEach(product=>{
                    if(criteriaProductMap[criteria.id][product.ID] === undefined){
                        criteriaProductMap[criteria.id][product.ID] = 0
                    }
                })

            })
        }

        if(this.props.userReponses !== null){
            for (const [productId, product_responses] of Object.entries(this.props.userReponses)) {
                for (const [requirementId, pr_responses] of Object.entries(product_responses)) {
                        var criteriaId  =   requirementIdCriteriaMap[requirementId];
                        if(criteriaId !== undefined ){
                            if(pr_responses.Response !== null && String(pr_responses.Response).trim().length !== 0 &&  pr_responses.Response !== 0){
                                criteriaProductMap[criteriaId][productId] = criteriaProductMap[criteriaId][productId] + 1;
                            }
                        }
                }  
            }
        }

        var errorCriteriaIds = [];
        for (const [criteriaId, productResponses] of Object.entries(criteriaProductMap)) {
            for (const [productId, pc_responses] of Object.entries(productResponses)) {
                    if(criteriQuetionCount[criteriaId] > pc_responses){
                        errorCriteriaIds.push(parseInt(criteriaId));
                    }
            }  
        }
        console.log(errorCriteriaIds)
        var errorCriterias = this.props.criterias.filter((c)=>{
            return errorCriteriaIds.indexOf(c.id) !== -1
        }).map((c)=>{
            return c.name;
        });
        if(errorCriterias.length > 0){
            this.props.showSnackBar("Please respond to all requirenents for "+errorCriterias.join(", ")+".","error",3000)
            return false
        }
        return true;
    }

    updateStatus(){
        if(!this.isMandatoryResponseFilled()){
            return
        }
        this.props.showFeedbackTakeDialog();
    }

    nextPage = () => {
        let redirectPath = `/app/evaluate/${this.props.match.params.evaluation_id}/summary`;
        this.props.history.push(redirectPath);
    }
    render() {
        const classes   =   this.props.classes;

        let products = this.props.evaluation?.Products;
        
        return <div>
                <EvaluationBar/>

                <Grid container className={classnames(classes.contentContainer, classes.content)}>
                    <Grid item xs={6}>
                        <div className={classes.prodPad}>
                            <Typography variant={"h6"} className={classes.criteriaTitle}><span>{this.props.evaluateView || this.props.evaluateNeverReview ? 'Review': 'Evaluation'} Section  &nbsp;</span>
                            </Typography>
                            <p className={classes.subText}>{this.props.evaluateView || this.props.evaluateNeverReview ? 'Review the' : 'Evaluate'} products and once done, select Finalize to complete your {this.props.evaluateView || this.props.evaluateNeverReview ? 'review' : 'evaluation'}.</p>
                        </div>
                        <div style={{ paddingTop: 95 }}>
                            <CriteriaRequirement isRespond isEditable={false} evaluationId={this.props.match.params.evaluation_id}/>
                        </div>
                    </Grid>
                    <Grid item xs={6} style={{position:'relative',paddingLeft:'20px'}}>
                        <div className={classes.productContainer}>
                                
                                    {
                                        this.props.evaluation !== null && products.length > 0 &&
                                        <ProductSlider products={products}>
                                            {
                                                products.map((product,k)=>{
                                                    return    <div className={classes.productBox}>
                                                        <Product isRespond product={product}/>
                                                    </div>
                                                })
                                            }
                                        </ProductSlider>
                                    }
                                
                        </div>
                        {/* <div className={classes.productContainer}>
                            <Slider {...settings}>
                                {
                                    this.props.evaluation !== null && this.props.evaluation.Products.map((product,k)=>{
                                        return    <div className={classes.productBox}>
                                            <Product product={product} />
                                        </div>
                                    })
                                }
                            </Slider>
                        </div> */}
                    </Grid>
                </Grid>
                <BottomAction>
                    <Grid container className={classes.bottomNav}>
                        <Grid item xs={3}>
                            {false && <div className={classnames(classes.saveButton, 'evaluate-save-template')}>
                                <SqureButton variant={"contained"} startIcon={
                                    <LinkIcon/>
                                } onClick={()=>{
                                    this.props.showEvaluationLinkDialog()
                                }}>Link Evaluation</SqureButton>
                            </div>}
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classnames('evaluate-add-new-criteria')}>
                               <SqureButton variant={"outlined"} onClick={()=>{
                                this.props.showEvaluationCriteriaAddDialog(this.props.evaluation.ID)
                            }}>+ Add New Section</SqureButton>  </div>
                        </Grid>
                        <Grid item xs={5}>  
                           <div className={classes.finalizeBtn}>
                           <div className={classnames(classes.saveBtn, 'evaluate-save-continue')}>
                               <SqureButton  variant={"outlined"} onClick={()=>{
                                    this.props.history.push("/app/evaluate")
                                }}>Save and continue later</SqureButton></div> <span className={'evaluate-proceed-btn'}>&nbsp;&nbsp;
                            <SqureButton  variant={"contained"} endIcon={
                                <Image src={ArrowForward} alt="arrow forward" />
                            } onClick={this.updateStatus}>Finalize</SqureButton></span>
                             <span className={'evaluate-next'}>
                                    &nbsp;&nbsp;&nbsp;
                                    <SqureButton  variant={"contained"} endIcon={
                                        <Image src={ArrowForward}  alt="arrow forward"/>
                                    } onClick={this.nextPage}>Next</SqureButton>
                                </span>
                           </div>
                          
                        </Grid>
                    </Grid>
                </BottomAction>
                <FeedbackDialog/>
                {
                    this.props.collectiveResponseDialogOpen == true &&
                    <CollectiveResponseDialog />
                }
                {
                    this.props.collaboratorDialogOpen && 
                    <SearchUserPoper {...this.props.collaboratorDialogData}/>
                }
            </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(InProgress));