import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";

// components
import AddProductButton from 'Components/Common/AddProductButton'
import UpcomingReviews from './UpcomingReviews'
import CompletedReviews from './CompletedReviews'
import AppBarV2 from "../../../AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import CartDrawer from "Components/Application/CartDrawer";
import Image from 'Components/Common/image'

// assets
import CommonCss from "../../../../../commonCss";
import DefaultImage from "assets/images/evaluation/default.svg";
import EvaluationEmptyState from "assets/images/evaluation_empty_state.svg";

// Redux
import { fetchCompletedEvaluation, fetchInProgressEvaluation } from "redux/evaluate/action";
import {fetchVendors} from "redux/metric/action";
import {fetchOrgProducts} from "redux/product/orgProduct/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "redux/evaluation/cart/action";
import {showSnackBar} from "redux/snackbar/action";

const connectedProps = (state) => ({
  vendors:state.metric.vendors,
  vendorsTotal: state.metric.vendorsTotal,
  evaluationCart: state.evaluationCart.evaluation,
  productFecthProgress:state.orgProduct.orgProductFetchProg,
  products:state.orgProduct.orgProducts,
  totalOrgProducts:state.orgProduct.totalOrgProducts,
});

const connectionActions = {
  fetchCompletedEvaluation: fetchCompletedEvaluation,
  fetchInProgressEvaluation: fetchInProgressEvaluation,
  fetchVendors: fetchVendors,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  showSnackBar:showSnackBar,
  fetchOrgProducts:fetchOrgProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    bottomContainer: {
      marginTop: theme.spacing(8),
    },
    viewAll: CommonCss.viewAll,
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
    topHead: {
        display: 'flex',
        justifyContent:'space-between',
        marginTop: 40,
        marginBottom: 8,
        "& > .appBar": {
            flex:1
        }
    },
    DashboardNoDataLayout:{
      margin: '22px 0px 0px 0px',
      minHeight: 450,
      textAlign: 'center',
      // background: '#ffffff',
      padding: '23px',
      borderRadius: '6px',
      '& button':{
          margin: '20px 0',
          color: '#fff',
          background: '#3C3C3C',
          '&:hover':{
              color: '#FFF',
              background: '#3C3C3C',
          }
      }
    },
    loader:{
      position: 'fixed',
      right: '45%',
      top: '40%'
    }
  });

class MyProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 6,
      isCartShow: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.addToCart = this.addToCart.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
   this.props.fetchOrgProducts(this.state.page, this.state.pageSize ,this.state.query);
  }

  cartOpen = () => {
    this.setState({ isCartShow: true });
  };

  cartClose = () => {
   this.setState({ isCartShow: false });
  };

  addToCart(item) {
    item.ID = item.ID || parseInt(item.application_id)
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.ID);
      this.props.showSnackBar("Product added to evaluation","success",3000);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(item.ID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
      } else {
        this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
        this.props.showSnackBar("Product added to evaluation","success",3000);
      }
    }
  }

  reviewProduct = (e,id) => {
    e.stopPropagation();
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  }

  producDetail = (e,product) => {
    this.props.history.push(`/app/products/org-product-details/${product.ID}`)
  }

  render() {
    const classes = this.props.classes;

    if(this.props.productFecthProgress){
      return(<span className={classes.loader}><CircularProgress /></span>)
    }

    if(!this.props.productFecthProgress && this.props.totalOrgProducts === 0){
      return(<>
      <div className={classes.DashboardNoDataLayout}>
          <Image src={EvaluationEmptyState}/> 
          <h2>No Products found</h2>
          <p>Get started on your first review or evaluation by tapping on Add Vendor below</p>
          <AddProductButton title={'Add Product'}/>
      </div>
      </>)
    }
    const isCart = this.props.evaluationCart !== null && this.props.evaluationCart !== '' && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length > 0;

    return (
      <div>
          <div className={classes.topHead} style={{marginTop:'unset'}}>
            <AppBarV2 title="My Products"   withBack={true} dropdown={false}/>
            <AddProductButton title={'Add Product'}/>
          </div>
           
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="topContainer" className={classes.topContainer}>
                <div className={classes.topHead}>
                  <span>
                      <>
                       {this.props.totalOrgProducts} {this.props.totalOrgProducts > 1 ? " Products" : " Product"} found
                      </>
                  </span>
                  {this.props.totalOrgProducts > 6 && 
                    <Link to="/app/products/org-products" 
                    className={classes.viewAll}> View all ({this.props.totalOrgProducts}) </Link>
                  }
                </div>
              <Grid container spacing={3}>
                {this.props.products.slice(0, 6).map((product, index) => (
                  <Grid item lg={2} md={3} key={index}>
                    <div onClick={(e) =>  this.producDetail(e, product)}>
                      <LayeredCard
                        title={product.Name} 
                        subTitle={product.CompanyName}
                        description={product.Description}
                        image={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage:product.ProductLogo}
                        toogleCheck={(e) => {
                          this.reviewProduct(e, product.ID)
                        }}
                        onClick={()=>{}}
                        numLayer={0}
                        //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                        showCheckbox={true}
                        id={product.ID}
                        uniqueId={product.ID+'_e_'+index}
                        //  type={'product'}
                        IsExisting={true}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.bottomContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                  <CompletedReviews />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                  <UpcomingReviews />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <div id="homeCart"
              onMouseEnter={() => this.cartOpen()}
              onMouseLeave={() => this.cartClose()}
              className={classnames(
                  classes.cartSection,
                  this.state.isCartShow ? "showCart" : ""
              )}
              >
              {isCart && (
                  <div>
                  <CartDrawer/>
                  </div>
              )}
          </div>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(MyProducts));
