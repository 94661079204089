import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import AppBarV2 from "../../../AppBarV2";
import LayeredCard from "../../Evaluate/Components/Common/LayeredCard";
import ToolTip from "Components/Common/ToolTip.jsx";
import { isEnabledEvaluate } from "util/Common";

// redux
import {
  productSearch,
  fetchProductReaction,
  fetchWishListedProducts,
  fetchRecommendationProducts,
} from "../../../../../redux/product/action";
import { showAddProductManuallyDialog } from "../../../../../redux/productsSettings/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "../../../../../redux/evaluation/cart/action";

import { updateDrawerStyle } from "../../../../../redux/drawer/action";
// Dilogs

const connectedProps = (state) => ({
  evaluationCart: state.evaluationCart.evaluation,
  productFilters: state.products.productFilters,
  productsRec: state.products,
  fetchProductReactionByTypeProgress:
    state.products.fetchProductReactionByTypeProgress,
  deleteProductStateProg: state.products.deleteProductStateProg,
  addProductStateProg: state.products.addProductStateProg,
  recommendedProducts: state.products.recommendedProducts,
  experimentDetails: state.products.experimentDetails,
  recommendedSubcategory: state.products.recommendedSubcategory,
  fetchRecommendedProductsProgress:
    state.products.fetchRecommendedProductsProgress,
  fetchRecommendationProductsUnderProgress:
    state.products.fetchRecommendationProductsUnderProgress,
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  productSearch: productSearch,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  fetchProductReaction: fetchProductReaction,
  fetchEvaluationCart: fetchEvaluationCart,
  updateDrawerStyle: updateDrawerStyle,
  fetchWishListedProducts: fetchWishListedProducts,
  fetchRecommendationProducts: fetchRecommendationProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
    },
    productContainer: {
      maxHeight: "calc(100vh - 230px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 230px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
  });

class RecommendedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSectionWidth: null,
      queryParams: queryString.parse(this.props.location.search),
      checkBoxFilterStatus: {
        featured: false,
        wishlisted: false,
        org_wishlisted: false,
        all_product_blocking: false,
      },
      isCheckBoxFilter: false,
      selectedCheckBoxFilter: "",
      filters: {},
      selectedState: [],
    };
  }

  componentDidMount() {
    this.props.fetchWishListedProducts();
    const urlPrams = new URLSearchParams(this.props.location.search);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
    const subCategory = urlPrams.get("subcategory") || '1';
    const num_users = urlPrams.get("user") || "";
    let feature_ids = urlPrams.get("featureId") || "";
    feature_ids = feature_ids.split(",").map((value) => parseInt(value));
    this.props.fetchRecommendationProducts(subCategory, {
      feature_ids,
      num_users,
      page: 0,
      pageSize: 100,
      query: "",
      type: 2,
    });
    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isSeller &&
      !this.props.fetchRecommendedProductsProgress &&
      prevProps.fetchRecommendedProductsProgress &&
      this.props.recommendedProducts
    ) {
      const productIDs = this.props.recommendedProducts.map((o) => o.ID);
      if (this.props.history?.location.search && productIDs) {
        this.props.fetchProductReaction("recommended", {
          productIDs,
          source: "recommended",
          sourceData: this.props.history.location.search.substring(1),
        });
        this.getProductReactions(productIDs);
      }
    }

    if (
      !this.props.isSeller &&
      !this.props.fetchProductReactionByTypeProgress &&
      prevProps.fetchProductReactionByTypeProgress
    ) {
      var reactionProducts = this.props.productsRec?.wishlistProduct.Data || [];
      if (reactionProducts.length > 0) {
        const productIDs = reactionProducts.map((o) => o.ID);
        this.getProductReactions(productIDs);
      }
    }
  }

  getProductReactions(productIDs) {
    this.props.fetchProductReaction("all_product_blocking", {
      productIDs,
      source: "all_product_blocking",
      sourceData: "all_product_blocking",
    });
  }

  componentWillUnmount() { }

  addToCart(product_id) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(parseInt(product_id));
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(parseInt(product_id)) !== -1) {
        this.props.removeProductFromCart(
          this.props.evaluationCart.ID,
          parseInt(product_id)
        );
      } else {
        this.props.createEvaluationCart(
          parseInt(product_id),
          this.props.evaluationCart.ID
        );
      }
    }
  }

  productStates = (obj) => {
    if (obj) {
      const data = Object.keys(obj).map((key) => ({
        StateName: key,
        StateID: obj[key],
      }));
      return data;
    } else {
      return [];
    }
  };

  render() {
    const { classes, deleteProductStateProg, addProductStateProg } = this.props;

    if(this.props.fetchRecommendedProductsProgress){
      return <div style={{textAlign:'center',marginTop:20}}>
          <CircularProgress/>
      </div>
    }
    var addedToCart =
      this.props.evaluationCart == null
        ? []
        : this.props.evaluationCart.Products.map((p) => p.ID);
    let subcategoryId = "";
    let teamSize = "";
    let featureIds = [];

    try {
      const urlParams = new URLSearchParams(this.props.location.search);
      subcategoryId = urlParams.get("subcategory") || "";
      teamSize = urlParams.get("user") || "";
      if (urlParams.get("featureId") !== null) {
        featureIds = urlParams
          .get("featureId")
          .split(",")
          .map((id) => parseInt(id));
      }
    } catch (e) { }

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForUsersCheck')
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForAdminsCheck')
    let isShowCheckBox = true;
    if (((!EnableEvaluateForAdminsCheck && this.props.userRole !== 'OrgUser') || (!EnableEvaluateForUsersCheck && this.props.userRole === 'OrgUser'))) {
      isShowCheckBox = false;
    }

    return (
      <>
        <AppBarV2
          title={
            this.state.queryParams?.subcategory &&
              this.props.recommendedSubcategory
              ? this.props.recommendedSubcategory
              : "Recommended products"
          }
          withBack={true}
          dropdown={true}
        />
        <div className={classes.root}>
          {this.props.recommendedProducts &&
            this.props.recommendedProducts.length > 0 && (
              <>
                <Grid container alignItems="center">
                  <Typography className={classes.label}>
                    Recommended products <ToolTip toolTip="12" />
                  </Typography>
                </Grid>
                <Grid container alignItems="center">
                  <Typography
                    className={classes.subTitle}
                    style={{ marginBottom: 20 }}
                  >
                    [BETA] Here is a list of products that best fit your needs.
                    Tap on them for more details and select products you want to
                    evaluate
                  </Typography>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 10 }}>
                  {this.props.recommendedProducts
                    .slice(0, 10)
                    .map((product, index) => (
                      <Grid
                        item
                        lg={2}
                        md={3}
                        sm={4}
                        xs={6}
                        key={"recommendedProducts"}
                      >
                        <LayeredCard
                          title={product.Name}
                          showLike
                          showExplanation={product.Explanation?.length>0 ? true : false}
                          explanation={product.Explanation}
                          ishoverExplanationToolTip={true}
                          autoHideExplanationToolTip={false}
                          onHoverExplanationToolTipText="Why am i seeing this?"
                          typeOfLike="recommended"
                          subTitle={product.CompanyName}
                          description={product.Description}
                          image={product.ProductLogo}
                          toogleCheck={() => {
                            this.addToCart(product.ID);
                          }}
                          onClick={() => { }}
                          numLayer={0}
                          checked={addedToCart.indexOf(product.ID) !== -1}
                          showCheckbox={isShowCheckBox}
                          id={product.ID}
                          uniqueId={product.ID + "_j_" + index}
                          type={"product"}
                          IsExisting={product.IsExisting}
                          productStates={product?.ProductStates}
                          source={"BROWSE"}
                          showReactionResponses
                          typeOfBlock="all_product_blocking"
                          typeOfFeatured="featured"
                          fireEvent
                          eventPage={"productRecommendation"}
                          eventData={{
                            type: "event.click.product.on-product-recommendation",
                            data: {
                              url:
                                this.props.location.pathname +
                                this.props?.location?.search,
                              product: {
                                id: product.ID,
                                name: product.Name,
                                position: index + 1,
                              },
                              subcategoryId,
                              teamSize,
                              featureIds,
                              experimentDetails: this.props.experimentDetails,
                            },
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
                {this.props.recommendedProducts &&
                  this.props.recommendedProducts.length > 10 && (
                    <>
                      <Grid container alignItems="center">
                        <Typography className={classes.label}>
                          Other Products <ToolTip toolTip="13" />
                        </Typography>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography
                          className={classes.subTitle}
                          style={{ marginBottom: 20 }}
                        >
                          Here are other products in your problem space. Tap on
                          them for more details and select products you want to
                          evaluate.
                        </Typography>
                      </Grid>
                      <Grid container spacing={3}>
                        {this.props.recommendedProducts
                          .slice(10, 35)
                          .map((product, index) => (
                            <Grid
                              item
                              lg={2}
                              md={3}
                              sm={4}
                              xs={6}
                              key={"product_" + parseInt(product.ID)}
                            >
                              <LayeredCard
                                title={product.Name}
                                showLike
                                showExplanation={product.Explanation?.length>0 ? true : false}
                                explanation={product.Explanation}
                                ishoverExplanationToolTip={true}
                                autoHideExplanationToolTip={false}
                                onHoverExplanationToolTipText="Why am i seeing this?"
                                typeOfLike="recommended"
                                subTitle={product.CompanyName}
                                description={product.Description}
                                image={product.ProductLogo}
                                toogleCheck={() => {
                                  this.addToCart(product.ID);
                                }}
                                onClick={() => { }}
                                numLayer={0}
                                checked={addedToCart.indexOf(product.ID) !== -1}
                                showCheckbox={isShowCheckBox}
                                id={product.ID}
                                uniqueId={product.ID + "_j_" + index}
                                type={"product"}
                                IsExisting={product.IsExisting}
                                productStates={product?.ProductStates}
                                source={"BROWSE"}
                                showReactionResponses
                                typeOfBlock="all_product_blocking"
                                typeOfFeatured="featured"
                                fireEvent={true}
                                eventPage={"productRecommendation"}
                                eventData={{
                                  type: "event.click.product.on-product-recommendation",
                                  data: {
                                    url:
                                      this.props.location.pathname +
                                      this.props?.location?.search,
                                    product: {
                                      id: product.ID,
                                      name: product.Name,
                                      position: index + 1,
                                    },
                                    subcategoryId,
                                    teamSize,
                                    featureIds,
                                    experimentDetails:
                                      this.props.experimentDetails,
                                  },
                                }}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </>
                  )}
              </>
            )}
          {(deleteProductStateProg || addProductStateProg) && (
            <Grid container justify="center" spacing={3}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
        </div>
      </>
    );
  }
}

export default connector(
  compose(withStyles(styles), withLastLocation)(RecommendedProducts)
);
