import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { hideUserCountDetailsDialog } from "redux/evaluation/feedback/action"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaluationFeedback.showUserCountDetailsDialog,
    showUserCountDetails: state.evaluationFeedback.showUserCountDetails,
    user: state.authUser.user,
});

const connectionActions = {
    hideDialog: hideUserCountDetailsDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions: {
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        minWidth: '200px'
    },
    actionBtnDisabled: {
        minWidth: '200px',
    },
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    userDetailsDialog: {
    },
    title: {
        textAlign: "center",
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px 0px'
    },
    userInfo: {
        marginTop: 10,
        border: '1px solid #c1c1c1',
        borderRadius : '4px'
    }
});

class OverallRequiremntViewDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            feedback: "",
            feedback_error: "",
            products: []
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const classes = this.props.classes;
        var showUserCountDetails = this.props.showUserCountDetails !== undefined && this.props.showUserCountDetails !== null ? this.props.showUserCountDetails : [];
        return <Dialog
            className={classes.userDetailsDialog}
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <div>
                    <Typography variant={"h4"} className={classes.title}>Users</Typography>

                    <div className={classes.userInfo}>
                        <Table className={classes.mandatoryTable} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.criteriaName}>Name</TableCell>
                                    <TableCell className={classes.criteriaName}>Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showUserCountDetails.map((user) => {
                                    return <TableRow>
                                        <TableCell className={classes.criteriaName}>{user.Name}</TableCell>
                                        <TableCell className={classes.criteriaName}>{user.Email}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>

                    </div>
                </div>
            </DialogContent>
        </Dialog >
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(OverallRequiremntViewDialog));