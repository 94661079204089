import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, CircularProgress
} from '@material-ui/core'

import { withRouter } from 'react-router-dom';
import * as Validator from "util/Validation";
import { addWorkflowComponentUserResponse, addCustomFormResponse, fetchWorkflowFormUserWiseResponse } from "redux/workflow/action";
import { searchUsers } from "redux/usersSettings/action";
import { fetchOrgGroup } from "redux/org/group/action";
import { fetchWorkFlowCustomForm } from "redux/evaluation/workflow/action";
import CustomReply from 'Components/Common/FormReply/CustomReply';


import { fetchOrgFormUsingId } from "redux/forms/org/action";
import { fetchMasterFormUsingId } from "redux/forms/master/action";
import { fetchSimilarExistingProducts } from "redux/product/orgProduct/action";
import Alert from '@material-ui/lab/Alert';

import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";
import { showSearchTeamsPoperDialog, hideSearchTeamsPoperDialog, showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import SearchTeamsPoper from "Components/Common/TeamsAndUser/SearchTeamsPoper";
import SearchUserPoper from "Components/Common/TeamsAndUser/SearchUserPoper";

const connectedProps = (state) => ({
  OrgID: state.authUser.user.OrgID,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  componentUserResponseError: state.workflow.componentUserResponseError,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  fetchCustomFormProgress: state.evaluationWorkflow.fetchCustomFormProgress,
  workflowCustomForm: state.evaluationWorkflow.workflowCustomForm,
  addCustomComponentUserResponseProgress: state.workflow.addCustomComponentUserResponseProgress,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  orgFormData: state.orgForms.orgFormData,
  fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
  fetchMasterFormsByIdProgress: state.masterForms.fetchMasterFormsByIdProgress,
  masterFormData: state.masterForms.masterFormData,
  fetchWokflowFormResponseProgress: state.workflow.fetchWokflowFormResponseProgress,
  workflowFormResponse: state.workflow.workflowFormResponse,
  similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
  similarExistingProducts: state.orgProduct.similarExistingProducts,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  isOpenTeamDialog: state.dialogs.isShowSearchTeamPoperDialog,
  isOpenUserDialog: state.dialogs.isShowSearchUserPoperDialog,
});

const connectionActions = {
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchWorkFlowCustomForm: fetchWorkFlowCustomForm,
  addCustomFormResponse: addCustomFormResponse,
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  fetchOrgFormUsingId: fetchOrgFormUsingId,
  fetchMasterFormUsingId: fetchMasterFormUsingId,
  fetchWorkflowFormUserWiseResponse: fetchWorkflowFormUserWiseResponse,
  fetchSimilarExistingProducts: fetchSimilarExistingProducts,
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  showSearchTeamsPoperDialog: showSearchTeamsPoperDialog,
  hideSearchTeamsPoperDialog: hideSearchTeamsPoperDialog,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    // background: '#fff',
    // height: '80vh',
    // overflow: 'scroll',
    pointerEvents: 'none',
  },
  actions: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  actionBtn: {
    minWidth: '200px'
  },
  actionBtnDisabled: {
    minWidth: '200px',
  },
  dialogContent: {
    margin: 'auto',
    marginBottom: 20,
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  stepHead: {
    color: '#282D30',
    fontSize: 18,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  stepSubHead: {
    color: '#4B4B4B',
    fontSize: 12,
    margin: 0,
    paddingBottom: 20
  },
  customForm: {
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
    padding: 20,
  },
  topHeadColor: {
    background: '#1f73b7',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    height: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%'
  },
  footer: {
    textAlign: 'right'
  },
  section: {
  },
  sectionItem: {
    padding: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
  },
  sectionTitle: {
    color: '#282D30',
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
  alert: {
    padding: '10px 20px 0px'
  }
});

class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customFormTitle: '',
      customFormDescription: '',
      customFormData: [],
      isShowLoader: true,
      formConfigDetails: {},
      formError: '',
      formResponseMap: {},
      showSectionMap: {},
      showSectionResponseMatch: {},
      itemIdsForSection: {},
      currentSelected: null
    }
    this.avatarName = this.avatarName.bind(this);
    this.setSelectedTeams = this.setSelectedTeams.bind(this);
    this.setSelectedUsers = this.setSelectedUsers.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this)
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    if (this.props.isWorkFlow) {
      this.checkWorkflowConfiguration();
    }
    this.props.fetchUsers(0, 30, "");
    this.props.fetchOrgGroup(0, 30, "");
  }

  componentDidUpdate(prevProps) {
    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.props.isWorkFlow) {
        this.checkWorkflowConfiguration();
      }
    }

    if (this.props.fetchWokflowFormResponseProgress === false && prevProps.fetchWokflowFormResponseProgress === true) {
      this.checkResponses();
    }

  }

  cleanErrors() {
    let { customFormData, formConfigDetails } = this.state;
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    this.state.customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        item['error'] = ''
      })
    })
    this.setState({
      customFormData: customFormData
    })
  }

  checkResponses() {
    const { workflowFormResponse } = this.props;
    var customFormDataNew = [];
    var responseMap = [];
    if (workflowFormResponse !== undefined && workflowFormResponse !== null) {
      workflowFormResponse.forEach(function (form) {
        if (form.master_form !== null) {
          customFormDataNew.push({
            type: 'MASTER',
            formData: form.master_form
          });
        } else {
          customFormDataNew.push({
            type: 'ORG',
            formData: form.org_form
          });
        }
        if (form.form_response !== undefined && form.form_response !== null) {
          form.form_response.forEach(function (response) {
            if(Validator.isJson(response.Data)){
              responseMap[response.ItemId] = JSON.parse(response.Data);
            }
          })
        }
      })
    }
    this.setState({
      customFormData: customFormDataNew,
      formResponseMap: responseMap,
    }, () => {
      this.checkConditions();
    })
  }

  checkConditions() {
    const orgFormData = this.state.customFormData[0].formData;
    let showSectionMap = {};
    let showSectionResponseMatch = {};
    let itemIdsForSection = {};
    var formType = "ORG";
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    if(orgFormData){
      orgFormData[sectionName].forEach(function (section, k) {
        var item = section[sectionItemName][0];
        if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
          var options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
          showSectionResponseMatch[item.ID] = {};
          itemIdsForSection[section.ID] = item.ID;
          options.forEach(function (op) {
            if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
              showSectionMap[op.condition.value] = false;
              showSectionResponseMatch[item.ID][op.value] = op.condition;
            }
          })
        }

        if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
          var num_options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
          showSectionResponseMatch[item.ID] = {};
          itemIdsForSection[section.ID] = item.ID;
          num_options.forEach(function (op) {
            if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
              showSectionMap[op.condition.value] = false;
              showSectionResponseMatch[item.ID][op.value] = op.condition;
            }
          })
        }


      })
    }
    this.setState({
      showSectionMap: showSectionMap,
      showSectionResponseMatch: showSectionResponseMatch,
      itemIdsForSection: itemIdsForSection,
    }, () => {
      this.checkResponded()
    })
  }

  checkResponded() {
    const { formResponseMap } = this.state;
    var me = this;
    var formType = 'ORG';
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    var customFormDataNew = this.state.customFormData[0].formData;
    customFormDataNew[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        if (formResponseMap[item.ID] === undefined) {
          return;
        }
        var value = '';
        try {
          value = formResponseMap[item.ID]
        } catch (error) {
        }
        if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
          me.updateShowSection(item, value);
        }

        if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
          me.updateShowSectionForNumbers(item,value)
        }
      })
    });
    this.setState({
      isShowLoader: false
    })
  }

  updateShowSection(item, response) {
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      var datas = showSectionResponseMatch[item.ID];
      Object.keys(datas).forEach(function (okey) {
        var data = datas[okey];
        if (item.Type === 4) {
          okey = parseInt(okey)
        }
        if (okey === response) {
          showSectionMap[data.value] = true;
        } else {
          showSectionMap[data.value] = false;
        }
      })
    }
    this.setState({
      showSectionMap: showSectionMap
    })
  }

  updateShowSectionForNumbers(item, response) {
    if (item.Type === 16) {
      if (response !== undefined && response !== null && response !== '') {
        let newValue;
        try {
          newValue = JSON.parse(response);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '' && newValue.value !== undefined && newValue.value !== null && newValue.value !== '') {
          response = newValue.value;
        } else if (response.type !== undefined && response.type !== null && response.type !== '') {
          response = response.value;
        }
      }
    }
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      var num_options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
      var datas = showSectionResponseMatch[item.ID];
      var checkMap = {};
      num_options.forEach(function (itm) {
        checkMap[itm.value] = {
          ...itm,
          value: parseFloat(itm.label)
        }
      })
      let resValue = parseFloat(response)
      Object.keys(datas).forEach(function (okey) {
        var data = datas[okey];
        if (checkMap[okey] !== undefined && checkMap[okey].operation === 1 && checkMap[okey].value === resValue) {
          showSectionMap[data.value] = true;
          // console.log('Operation 1 value:' + checkMap[okey].value + ' === response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 2 && resValue > checkMap[okey].value) {
          showSectionMap[data.value] = true;
          // console.log('Operation 2 value:' + checkMap[okey].value + ' > response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 3 && resValue >= checkMap[okey].value) {
          showSectionMap[data.value] = true;
          // console.log('Operation 3 value:' + checkMap[okey].value + ' >= response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 4 && resValue < checkMap[okey].value) {
          showSectionMap[data.value] = true;
          // console.log('Operation 4 value:' + checkMap[okey].value + ' < response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 5 && resValue <= checkMap[okey].value) {
          showSectionMap[data.value] = true;
          // console.log('Operation 5 value:' + checkMap[okey].value + ' <= response:' + resValue)
        }
      })
    }

    this.setState({
      showSectionMap: showSectionMap
    })
  }

  checkWorkflowConfiguration() {
    this.props.fetchWorkflowFormUserWiseResponse(this.props.evaluation_id, this.props.userId)
  }

  setSelectedTeams(team, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;
    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [team];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === team.ID)
        if (index === -1) {
          datas.push(team);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      })
    }
  }

  setSelectedUsers(user, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;

    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [user];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === user.ID)
        if (index === -1) {
          datas.push(user);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      })
    }
  }


  render() {
    const classes = this.props.classes;
    let { customFormData, isShowLoader, formResponseMap, showSectionMap } = this.state;


    if (isShowLoader) {
      return <div className={classes.loader}><CircularProgress /></div>
    }

    return <>

      <div className={classes.root}>

        {customFormData !== null && customFormData.map((customForm, sk) => {
          var sectionName = customForm?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
          var sectionItemName = customForm?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
          var sectionDatas = customForm.formData[sectionName] !== undefined ? customForm.formData[sectionName] : [];
          return <div classes={classes.dialogContent} >

            <div className={classes.customForm}>
              <Typography variant={"h4"} className={classes.stepHead}>{customForm.formData.FormTitle}</Typography>
              <span className={classes.stepSubHead}>{customForm.formData.FormDescription}</span>
              <div className={classes.topHeadColor}></div>
            </div>

            <div className={classes.alert}>
              {this.state.formError.length > 0 && <Alert variant="filled" severity="error">{this.state.formError}</Alert>}
            </div>
            {sectionDatas.map((section, sk) => {
              var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
              sectionItems = sectionItems.sort(function (a, b) {
                if (a.Seq > b.Seq) {
                  return 1;
                } else if (a.Seq < b.Seq) {
                  return -1
                } else {
                  return 0;
                }
              })
              return <>
                <div className={classes.section}>
                  {/* <Typography variant={"h4"} className={classes.sectionTitle}>{section?.SectionName}</Typography> */}
                  {sectionItems.map((item, key) => {
                    var response = formResponseMap[item.ID] !== undefined ? formResponseMap[item.ID] : '';

                    if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                      return '';
                    }

                    if (item.Type === 1) {
                    }
                    var options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
                    return <><div className={classes.sectionItem}>
                      <CustomReply
                        additional_data={options}
                        type={item.Type}
                        title={item.ItemName}
                        description={item.Description}
                        isRequired={item?.IsRequired}
                        key={item.ID}
                        index={item.ID}
                        value={response}
                        selectedOption={[]}
                        evaluationId={this.props.evaluation_id}
                        orgId={this.props.OrgID}
                        workflowId={this.props.workflow_id}
                        onChange={(response) => {
                          item['value'] = response;
                          if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                            this.updateShowSection(item, response);
                          }
                          this.cleanErrors();
                        }}
                        showUserAddDialog={(item) => {
                          this.setState({
                            currentSelected: item
                          }, () => {

                          })
                          this.props.showSearchUsersPoperDialog()
                        }}
                        showTeamAddDialog={(data) => {
                          this.setState({
                            currentSelected: item
                          }, () => {
                            this.props.showSearchTeamsPoperDialog()
                          })
                        }}
                      // similarExistingProducts={this.props.similarExistingProducts}
                      // similarExistingProductProgress={this.props.similarExistingProductProgress}
                      />
                    </div>
                    </>
                  })}

                </div>
              </>
            })}

          </div>
        })}


        {this.props.isOpenTeamDialog && <SearchTeamsPoper
          selectedTeams={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
          onAdd={(team) => {
            this.setSelectedTeams(team, true)
          }}
          onRemove={(team) => {
            this.setSelectedTeams(team, true)
          }}
          onClose={() => {
            this.props.hideSearchTeamsPoperDialog();
          }}
        />}

        {this.props.isOpenUserDialog && <SearchUserPoper
          selectedUsers={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
          onAdd={(user) => {
            this.setSelectedUsers(user, true)
          }}
          onRemove={(user) => {
            this.setSelectedUsers(user, true)
          }}
          onClose={() => {
            this.props.hideSearchUsersPoperDialog();
          }}
        />}
      </div>
    </>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomForm));