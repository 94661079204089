import { isArray } from "underscore";
import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_EVALUATION_REQUIREMENT_RESPONSE_ADD = "START_EVALUATION_REQUIREMENT_RESPONSE_ADD";
export const END_EVALUATION_REQUIREMENT_RESPONSE_ADD = "END_EVALUATION_REQUIREMENT_RESPONSE_ADD";

export const START_EVALUATION_RESPONSE_FETCH = "START_EVALUATION_RESPONSE_FETCH";
export const END_EVALUATION_RESPONSE_FETCH = "END_EVALUATION_RESPONSE_FETCH";

export const SHOW_EVALUATION_CRITERIA_ADD_DIALOG = "SHOW_EVALUATION_CRITERIA_ADD_DIALOG";
export const HIDE_EVALUATION_CRITERIA_ADD_DIALOG = "HIDE_EVALUATION_CRITERIA_ADD_DIALOG";

export const SHOW_EVALUATION_CRITERIA_UPDATE_DIALOG = "SHOW_EVALUATION_CRITERIA_UPDATE_DIALOG";
export const HIDE_EVALUATION_CRITERIA_UPDATE_DIALOG = "HIDE_EVALUATION_CRITERIA_UPDATE_DIALOG";

export const SHOW_EVALUATION_REQUIREMENT_ADD_DIALOG = "SHOW_EVALUATION_REQUIREMENT_ADD_DIALOG";
export const HIDE_EVALUATION_REQUIREMENT_ADD_DIALOG = "HIDE_EVALUATION_REQUIREMENT_ADD_DIALOG";

export const START_EVALUATION_CRITERIA_FETCH = "START_EVALUATION_CRITERIA_FETCH";
export const END_EVALUATION_CRITERIA_FETCH = "END_EVALUATION_CRITERIA_FETCH";

export const START_EVALUATION_CRITERIA_ADD = "START_EVALUATION_CRITERIA_ADD";
export const END_EVALUATION_CRITERIA_ADD = "END_EVALUATION_CRITERIA_ADD";

export const START_EVALUATION_REQUIREMENT_ADD = "START_EVALUATION_REQUIREMENT_ADD";
export const END_EVALUATION_REQUIREMENT_ADD = "END_EVALUATION_REQUIREMENT_ADD";

export const SHOW_EVALUATION_CRITERIA_EDIT_DIALOG = "SHOW_EVALUATION_CRITERIA_EDIT_DIALOG";
export const HIDE_EVALUATION_CRITERIA_EDIT_DIALOG = "HIDE_EVALUATION_CRITERIA_EDIT_DIALOG";

export const SHOW_EVALUATION_REQUIREMENT_EDIT_DIALOG = "SHOW_EVALUATION_REQUIREMENT_EDIT_DIALOG";
export const HIDE_EVALUATION_REQUIREMENT_EDIT_DIALOG = "HIDE_EVALUATION_REQUIREMENT_EDIT_DIALOG";

export const START_EVALUATION_REQUIREMENT_EDIT = "START_EVALUATION_REQUIREMENT_EDIT";
export const END_EVALUATION_REQUIREMENT_EDIT = "END_EVALUATION_REQUIREMENT_EDIT";

export const START_EVALUATION_CRITERIA_EDIT = "START_EVALUATION_CRITERIA_EDIT";
export const END_EVALUATION_CRITERIA_EDIT = "END_EVALUATION_CRITERIA_EDIT";

export const START_EVALUATION_CRITERIA_DELETE = "START_EVALUATION_CRITERIA_DELETE";
export const END_EVALUATION_CRITERIA_DELETE = "END_EVALUATION_CRITERIA_DELETE";

export const START_EVALUATION_REQUIREMENT_DELETE = "START_EVALUATION_REQUIREMENT_DELETE";
export const END_EVALUATION_REQUIREMENT_DELETE = "END_EVALUATION_REQUIREMENT_DELETE";

export const START_EVALUATION_SCORE_FETCH = "START_EVALUATION_SCORE_FETCH";
export const END_EVALUATION_SCORE_FETCH = "END_EVALUATION_SCORE_FETCH";

export const START_EVALUATION_COLLOBORATOR_FETCH = "START_EVALUATION_COLLOBORATOR_FETCH";
export const END_EVALUATION_COLLOBORATOR_FETCH = "END_EVALUATION_COLLOBORATOR_FETCH";

export const START_EVALUATION_COLLOBORATOR_ADD = "START_EVALUATION_COLLOBORATOR_ADD";
export const END_EVALUATION_COLLOBORATOR_ADD = "END_EVALUATION_COLLOBORATOR_ADD";

// add member to criteria
export const START_CRITERIA_COLLOBORATOR_ADD = "START_CRITERIA_COLLOBORATOR_ADD";
export const END_CRITERIA_COLLOBORATOR_ADD = "END_CRITERIA_COLLOBORATOR_ADD";

// update member or approver
export const START_UPDATE_CRITERIA_USER_ROLE = "START_UPDATE_CRITERIA_USER_ROLE";
export const END_UPDATE_CRITERIA_USER_ROLE = "END_UPDATE_CRITERIA_USER_ROLE";

// remove roles(approver or member)
export const START_REMOVE_CRITERIA_MEMBER = "START_REMOVE_CRITERIA_MEMBER";
export const END_REMOVE_CRITERIA_MEMBER = "END_REMOVE_CRITERIA_MEMBER";

export const START_REQUIREMENT_COLLOBORATOR_ADD = "START_REQUIREMENT_COLLOBORATOR_ADD";
export const END_REQUIREMENT_COLLOBORATOR_ADD = "END_REQUIREMENT_COLLOBORATOR_ADD";

export const EVALUATION_CRITERIA_EXPAND = "EVALUATION_CRITERIA_EXPAND";
export const EVALUATION_REQUIREMENT_EXPAND = "EVALUATION_REQUIREMENT_EXPAND";

export const HIDE_READMORE_DIALOG = "HIDE_READMORE_DIALOG";
export const SHOW_READMORE_DIALOG = "SHOW_READMORE_DIALOG";

export const START_REQUIREMENT_CRITERIA_IMPORT = "START_REQUIREMENT_CRITERIA_IMPORT";
export const END_REQUIREMENT_CRITERIA_IMPORT = "END_REQUIREMENT_CRITERIA_IMPORT";

// Collective response dialog
export const SHOW_COLLECTIVE_RESPONSE_DIALOG = "SHOW_COLLECTIVE_RESPONSE_DIALOG";
export const UPDATE_SELLER_COLLECTIVE_RESPONSE_DIALOG = "UPDATE_SELLER_COLLECTIVE_RESPONSE_DIALOG";
export const HIDE_COLLECTIVE_RESPONSE_DIALOG = "HIDE_COLLECTIVE_RESPONSE_DIALOG";
export const START_COLLECTIVE_RESPONSES_CHAT = "START_COLLECTIVE_RESPONSES_CHAT";
export const END_COLLECTIVE_RESPONSES_CHAT = "END_COLLECTIVE_RESPONSES_CHAT";

export const SHOW_ASK_SELLER_DIALOG = "SHOW_ASK_SELLER_DIALOG";
export const HIDE_ASK_SELLER_DIALOG = "HIDE_ASK_SELLER_DIALOG";

export const START_ASK_SELLER_REQUEST = "START_ASK_SELLER_REQUEST";
export const END_ASK_SELLER_REQUEST = "END_ASK_SELLER_REQUEST";

export const START_GET_RESPONESE_FOR_THREAD = "START_GET_RESPONESE_FOR_THREAD";
export const END_GET_RESPONESE_FOR_THREAD = "END_GET_RESPONESE_FOR_THREAD";

export const START_REPLY_RESPONESE_FOR_THREAD = "START_REPLY_RESPONESE_FOR_THREAD";
export const END_REPLY_RESPONESE_FOR_THREAD = "END_REPLY_RESPONESE_FOR_THREAD";

export const START_UPDATE_RESPONESE_FOR_THREAD = "START_UPDATE_RESPONESE_FOR_THREAD";
export const END_UPDATE_RESPONESE_FOR_THREAD = "END_UPDATE_RESPONESE_FOR_THREAD";

export const START_DELETE_RESPONESE_THREAD = "START_DELETE_RESPONESE_THREAD";
export const END_DELETE_RESPONESE_THREAD = "END_DELETE_RESPONESE_THREAD";

export const START_FETCH_ASK_SELLER_THREAD = "START_FETCH_ASK_SELLER_THREAD";
export const END_FETCH_ASK_SELLER_THREAD = "END_FETCH_ASK_SELLER_THREAD";

export const START_LAST_EVALUATION_USER_RESPONSE_FETCH = "START_LAST_EVALUATION_USER_RESPONSE_FETCH";
export const END_LAST_EVALUATION_USER_RESPONSE_FETCH = "END_LAST_EVALUATION_USER_RESPONSE_FETCH";

// fetch Requirement Tree
export const START_REQUIREMENT_TREE_FETCH = "START_REQUIREMENT_TREE_FETCH";
export const END_REQUIREMENT_TREE_FETCH = "END_REQUIREMENT_TREE_FETCH";

export const START_REQUIREMENT_RESPONSE_FETCH = "START_REQUIREMENT_RESPONSE_FETCH";
export const END_REQUIREMENT_RESPONSE_FETCH = "END_REQUIREMENT_RESPONSE_FETCH";

export const START_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH = "START_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH";
export const END_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH = "END_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH";

export const START_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS = "START_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS";
export const END_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS = "END_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS";

export const SET_SELECTED_REQUIREMENT_FOR_EVALUATION = "SET_SELECTED_REQUIREMENT_FOR_EVALUATION";
export const SET_SELECTED_PRODUCT_FOR_EVALUATION = "SET_SELECTED_PRODUCT_FOR_EVALUATION";

export const START_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH = "START_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH";
export const END_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH = "END_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH";

export const SET_REQUIREMENT_TREE_COLLAPSE_EXPAND = "SET_REQUIREMENT_TREE_COLLAPSE_EXPAND";

export const START_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS = "START_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS";
export const END_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS = "END_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS";

export const START_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH = "START_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH";
export const END_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH = "END_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH";

export const SET_CRITERIA_REQUIREMENT_SHOW_MAP = "SET_CRITERIA_REQUIREMENT_SHOW_MAP";

export const SET_SELECTED_SUMMARY_VIEW_FOR_EVALUATION = "SET_SELECTED_SUMMARY_VIEW_FOR_EVALUATION";
export const SET_SELECTED_PRODUCTS_FOR_SUMMARY_VIEW = "SET_SELECTED_PRODUCTS_FOR_SUMMARY_VIEW";

export const SET_REQUIREMENT_FOR_ADMIN_FLAG = "SET_REQUIREMENT_FOR_ADMIN_FLAG";

export const START_FETCH_PREVIOUS_INTERACTION = "START_FETCH_PREVIOUS_INTERACTION";
export const END_FETCH_PREVIOUS_INTERACTION = "END_FETCH_PREVIOUS_INTERACTION";

export const TOOGLE_PREVIOUS_INTERACTION_DIALOG = "TOOGLE_PREVIOUS_INTERACTION_DIALOG";
export const SET_RESPONSE_TAB = "SET_RESPONSE_TAB";

export const START_CRITERIA_COLLOBORATOR_UPDATE = "START_CRITERIA_COLLOBORATOR_UPDATE";
export const END_CRITERIA_COLLOBORATOR_UPDATE = "END_CRITERIA_COLLOBORATOR_UPDATE";

export const SET_SELECTED_CRITERIAS_FOR_SUMMARY_VIEW = "SET_SELECTED_CRITERIAS_FOR_SUMMARY_VIEW";

export const SHOW_SCORING_SCALE_DIALOG = "SHOW_SCORING_SCALE_DIALOG";
export const HIDE_SCORING_SCALE_DIALOG = "HIDE_SCORING_SCALE_DIALOG";

export const SET_MOVE_TO_NEXT_REQUIREMENT_FLAG = "SET_MOVE_TO_NEXT_REQUIREMENT_FLAG";

export const SET_REQUIREMENT_lOCAL_RESPONSE = "SET_REQUIREMENT_lOCAL_RESPONSE";

export const SET_GO_TO_REQUIREMENT = "SET_GO_TO_REQUIREMENT";

export const START_FETCH_QUESTIONAIRE_REQUIRED_STATUS = "START_FETCH_QUESTIONAIRE_REQUIRED_STATUS";
export const END_FETCH_QUESTIONAIRE_REQUIRED_STATUS = "END_FETCH_QUESTIONAIRE_REQUIRED_STATUS";

export const SET_SELECTED_REQUIREMENT_TREE_TYPE_FOR_EVALUATION = "SET_SELECTED_REQUIREMENT_TREE_TYPE_FOR_EVALUATION";

export const CLEAR_REQUIREMENT_RESPONSES = "CLEAR_REQUIREMENT_RESPONSES";

export function setResponseTab(tab) {
  return {
    type: SET_RESPONSE_TAB,
    payload: { tab },
  };
}
export function tooglePreviousInteractionDialog() {
  return {
    type: TOOGLE_PREVIOUS_INTERACTION_DIALOG,
    payload: {},
  };
}

//-------------------------------------
function startFetchPreviousInteraction() {
  return {
    type: START_FETCH_PREVIOUS_INTERACTION,
    payload: {},
  };
}

function endFetchPreviousInteraction(success, error) {
  return {
    type: END_FETCH_PREVIOUS_INTERACTION,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the fetchPreviousInteraction
export function fetchPreviousInteraction(evaluation_id, product_id) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/questions/compare_with_prev_evaluation?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchPreviousInteraction());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(url, { evaluation_id, product_id });
      dispatch(endFetchPreviousInteraction(response, error));
    } catch (e) {
      dispatch(
        endFetchPreviousInteraction(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export function clearState(type) {
  switch (type) {
    case "clear_add_response":
      return {
        type: "CLEAR_ADD_MAIN_RESPONSE",
        payload: {},
      };

    case "delete_thread":
      return {
        type: "CLEAR_DELETE_RESPONESE_THREAD",
        payload: {},
      };

    case "reply_response":
      return {
        type: "CLEAR_REPLY_RESPONESE_THREAD",
        payload: {},
      };

    case "update_response":
      return {
        type: "CLEAR_UPDATE_RESPONESE_THREAD",
        payload: {},
      };
    default:
      return {};
  }
}

export function hideReadMoreDialog() {
  return {
    type: HIDE_READMORE_DIALOG,
    payload: {},
  };
}

export function showReadMoreDialog(readmore) {
  return {
    type: SHOW_READMORE_DIALOG,
    payload: readmore,
  };
}

export function expandEvaluationCriteria(criteriaId) {
  return {
    type: EVALUATION_CRITERIA_EXPAND,
    payload: criteriaId,
  };
}

export function expandEvaluationRequirement(requirementID) {
  return {
    type: EVALUATION_REQUIREMENT_EXPAND,
    payload: requirementID,
  };
}

export function showEvaluationCriteriaUpdateDialog(criteria, evaluationId, componentId, productId) {
  return {
    type: SHOW_EVALUATION_CRITERIA_UPDATE_DIALOG,
    payload: { evaluationId, criteria, componentId, productId },
  };
}

export function hideEvaluationCriteriaUpdateDialog() {
  return {
    type: HIDE_EVALUATION_CRITERIA_UPDATE_DIALOG,
    payload: {},
  };
}

export function showEvaluationCriteriaAddDialog(evaluationId, forApproval, componentId, productId) {
  if (forApproval === undefined) {
    forApproval = false;
  }
  return {
    type: SHOW_EVALUATION_CRITERIA_ADD_DIALOG,
    payload: { evaluationId, forApproval, componentId, productId },
  };
}

export function hideEvaluationCriteriaAddDialog() {
  return {
    type: HIDE_EVALUATION_CRITERIA_ADD_DIALOG,
    payload: {},
  };
}

export function showEvaluationRequirementAddDialog(evaluationId, criteriaId) {
  return {
    type: SHOW_EVALUATION_REQUIREMENT_ADD_DIALOG,
    payload: { evaluationId, criteriaId },
  };
}

export function hideEvaluationRequirementAddDialog() {
  return {
    type: HIDE_EVALUATION_REQUIREMENT_ADD_DIALOG,
    payload: {},
  };
}

//-------------------------------------
function startEvaluationCriteriaAdd() {
  return {
    type: START_EVALUATION_CRITERIA_ADD,
    payload: {},
  };
}

function endEvaluationCriteriaAdd(success, error) {
  return {
    type: END_EVALUATION_CRITERIA_ADD,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function addCriteria(evId, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationCriteriaAdd());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(url, data);
      dispatch(endEvaluationCriteriaAdd(response, error));
    } catch (e) {
      dispatch(
        endEvaluationCriteriaAdd(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

//------------------------------------------

//-------------------------------------
function startEvaluationCriteriaFetch() {
  return {
    type: START_EVALUATION_CRITERIA_FETCH,
    payload: {},
  };
}

function endEvaluationCriteriaFetch(success, error) {
  return {
    type: END_EVALUATION_CRITERIA_FETCH,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function fetchCriteria(evId, forAdmin = false, componentId = 0) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria?forAdmin=${forAdmin}&componentId=${componentId}&t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationCriteriaFetch());
    try {
      let [response, error] = await await HttpFactory.instance().getMethod(url);
      dispatch(endEvaluationCriteriaFetch(response, error));
    } catch (e) {
      dispatch(
        endEvaluationCriteriaFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

//------------------------------------------
export function showEvaluationCriteriaEditDialog(evaluationId, criteria) {
  return {
    type: SHOW_EVALUATION_CRITERIA_EDIT_DIALOG,
    payload: { evaluationId, criteria },
  };
}

export function hideEvaluationCriteriaEditDialog() {
  return {
    type: HIDE_EVALUATION_CRITERIA_EDIT_DIALOG,
    payload: {},
  };
}

//-------------------------------------
function startEvaluationRequirementAdd() {
  return {
    type: START_EVALUATION_REQUIREMENT_ADD,
    payload: {},
  };
}

function endEvaluationRequirementAdd(success, error) {
  return {
    type: END_EVALUATION_REQUIREMENT_ADD,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function addRequirement(evId, criteriaId, data, duplicateCallNotAllow = true) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}/requirement?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationRequirementAdd());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(url, data, duplicateCallNotAllow);
      dispatch(endEvaluationRequirementAdd({ response, criteriaId }, error));
    } catch (e) {
      dispatch(
        endEvaluationRequirementAdd(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationRequirementResponseAdd() {
  return {
    type: START_EVALUATION_REQUIREMENT_RESPONSE_ADD,
    payload: {},
  };
}

function endEvaluationRequirementResponseAdd(success, error) {
  return {
    type: END_EVALUATION_REQUIREMENT_RESPONSE_ADD,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function addRequirementResponse(evId, product_id, requirement_id, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/product/${product_id}/requirement/${requirement_id}/response?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationRequirementResponseAdd());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(url, data);
      dispatch(endEvaluationRequirementResponseAdd(response, error));
    } catch (e) {
      dispatch(
        endEvaluationRequirementResponseAdd(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationResponseUserFetch() {
  return {
    type: START_EVALUATION_RESPONSE_FETCH,
    payload: {},
  };
}

function endEvaluationResponseUserFetch(success, error) {
  return {
    type: END_EVALUATION_RESPONSE_FETCH,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function fetchUserRequirementResponse(evId, product_id, requirement_id, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/responses?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationResponseUserFetch());
    try {
      let [response, error] = await await HttpFactory.instance().getMethod(url, data);
      dispatch(endEvaluationResponseUserFetch(response, error));
    } catch (e) {
      dispatch(
        endEvaluationResponseUserFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

export function showEvaluationRequirementEditDialog(evaluationId, criteriaId, requirement) {
  return {
    type: SHOW_EVALUATION_REQUIREMENT_EDIT_DIALOG,
    payload: { evaluationId, criteriaId, requirement },
  };
}

export function hideEvaluationRequirementEditDialog() {
  return {
    type: HIDE_EVALUATION_REQUIREMENT_EDIT_DIALOG,
    payload: {},
  };
}

//Thunk to perform the updateRequirement
export function updateRequirement(evId, requirementId, data, compId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/requirement/${requirementId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationRequirementEdit());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endEvaluationRequirementEdit(response, error));
      dispatch(fetchCriteria(evId, false, compId));
    } catch (e) {
      dispatch(
        endEvaluationRequirementEdit(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationRequirementEdit() {
  return {
    type: START_EVALUATION_REQUIREMENT_EDIT,
    payload: {},
  };
}

function endEvaluationRequirementEdit(success, error) {
  return {
    type: END_EVALUATION_REQUIREMENT_EDIT,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function editRequirement(evId, criteriaId, requirementId, data, compId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}/requirement/${requirementId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationRequirementEdit());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endEvaluationRequirementEdit(response, error));
      dispatch(fetchCriteria(evId, false, compId));
    } catch (e) {
      dispatch(
        endEvaluationRequirementEdit(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationCriteriaEdit() {
  return {
    type: START_EVALUATION_CRITERIA_EDIT,
    payload: {},
  };
}

function endEvaluationCriteriaEdit(success, error) {
  return {
    type: END_EVALUATION_CRITERIA_EDIT,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function editCriteria(evId, criteriaId, data, compId = 0) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationCriteriaEdit());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endEvaluationCriteriaEdit(response, error));
      dispatch(fetchCriteria(evId, false, compId));
    } catch (e) {
      dispatch(
        endEvaluationCriteriaEdit(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationCriteriaDelete() {
  return {
    type: START_EVALUATION_CRITERIA_DELETE,
    payload: {},
  };
}

function endEvaluationCriteriaDelete(success, error) {
  return {
    type: END_EVALUATION_CRITERIA_DELETE,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function deleteCriteria(evId, criteriaId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationCriteriaDelete());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endEvaluationCriteriaDelete(criteriaId, error));
    } catch (e) {
      dispatch(
        endEvaluationCriteriaDelete(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationRequirementDelete() {
  return {
    type: START_EVALUATION_REQUIREMENT_DELETE,
    payload: {},
  };
}

function endEvaluationRequirementDelete(success, error) {
  return {
    type: END_EVALUATION_REQUIREMENT_DELETE,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function deleteRequirement(evId, criteriaId, requirementId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}/requirement/${requirementId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationRequirementDelete());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endEvaluationRequirementDelete(requirementId, error));
    } catch (e) {
      dispatch(
        endEvaluationRequirementDelete(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationScoreFetch() {
  return {
    type: START_EVALUATION_SCORE_FETCH,
    payload: {},
  };
}

function endEvaluationScoreFetch(success, error) {
  return {
    type: END_EVALUATION_SCORE_FETCH,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function fetchScores(evId, userIds) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  var userQuery = "";
  if (userIds !== undefined && userIds !== null && isArray(userIds) && userIds.length > 0) {
    userQuery = "&userIds=" + userIds.join(",");
  }
  const url = `${api_server}/${evId}/score?t=${timestamp}${userQuery}`;
  return async (dispatch) => {
    dispatch(startEvaluationScoreFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endEvaluationScoreFetch(response, error));
    } catch (e) {
      dispatch(
        endEvaluationScoreFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationColloboratorFetch() {
  return {
    type: START_EVALUATION_COLLOBORATOR_FETCH,
    payload: {},
  };
}

function endEvaluationColloboratorFetch(success, error) {
  return {
    type: END_EVALUATION_COLLOBORATOR_FETCH,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function fetchColloborator(evId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/colloborator?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationColloboratorFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endEvaluationColloboratorFetch(response, error));
    } catch (e) {
      dispatch(
        endEvaluationColloboratorFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startEvaluationColloboratorAdd() {
  return {
    type: START_EVALUATION_COLLOBORATOR_ADD,
    payload: {},
  };
}

function endEvaluationColloboratorAdd(isWatcher, success, error) {
  return {
    type: END_EVALUATION_COLLOBORATOR_ADD,
    payload: {
      success,
      error,
      isWatcher,
    },
  };
}

//Thunk to perform the authorizedUser
export function addColloborator(evId, userId, isWatcher = false) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/colloborator?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startEvaluationColloboratorAdd());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, {
        userId: userId,
        isWatcher: isWatcher,
      });
      dispatch(endEvaluationColloboratorAdd(isWatcher, response, error));
    } catch (e) {
      dispatch(
        endEvaluationColloboratorAdd(isWatcher, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

//------------------------------------------

//-------------------------------------
function startCriteriaColloboratorAdd() {
  return {
    type: START_CRITERIA_COLLOBORATOR_ADD,
    payload: {},
  };
}

function endCriteriaColloboratorAdd(criteriaId, isApprover, success, error) {
  return {
    type: END_CRITERIA_COLLOBORATOR_ADD,
    payload: {
      criteriaId,
      isApprover,
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function addCriteriaColloborator(evId, criteriaId, userId, isApprover = false) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}/colloborator?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCriteriaColloboratorAdd());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, {
        userId: userId,
        isApprover: isApprover,
      });
      dispatch(endCriteriaColloboratorAdd(criteriaId, isApprover, response, error));
    } catch (e) {
      dispatch(
        endCriteriaColloboratorAdd(null, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startCriteriaColloboratorUpdate() {
  return {
    type: START_CRITERIA_COLLOBORATOR_UPDATE,
    payload: {},
  };
}

function endCriteriaColloboratorUpdate(criteriaId, userId, priority, success, error) {
  return {
    type: END_CRITERIA_COLLOBORATOR_UPDATE,
    payload: {
      criteriaId,
      userId,
      priority,
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function updateCriteriaColloborator(evId, criteriaId, userId, priority = 1) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}/colloborator?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCriteriaColloboratorUpdate());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, {
        userId: userId,
        priority: priority,
      });
      dispatch(endCriteriaColloboratorUpdate(criteriaId, userId, priority, response, error));
    } catch (e) {
      dispatch(
        endCriteriaColloboratorUpdate(null, null, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

//Thunk to perform the authorizedUser
export function addApproverCriteriaColloborator(evId, productId, criteriaId, userId, isApprover = false) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/product/${productId}/criteria/${criteriaId}/colloborator?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCriteriaColloboratorAdd());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, {
        userId: userId,
        isApprover: isApprover,
      });
      dispatch(endCriteriaColloboratorAdd(criteriaId, isApprover, response, error));
    } catch (e) {
      dispatch(
        endCriteriaColloboratorAdd(null, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startCriteriaColloboratorRoleUpdate() {
  return {
    type: START_UPDATE_CRITERIA_USER_ROLE,
    payload: {},
  };
}

function endCriteriaColloboratorRoleUpdate(criteriaId, isApprover, success, error) {
  return {
    type: END_UPDATE_CRITERIA_USER_ROLE,
    payload: {
      criteriaId,
      isApprover,
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function criteriaColloboratorRoleUpdate(evId, criteriaId, userId, isApprover) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}/colloborator?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCriteriaColloboratorRoleUpdate());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, {
        userId: userId,
        isApprover: isApprover,
      });
      dispatch(endCriteriaColloboratorRoleUpdate(criteriaId, isApprover, response, error));
    } catch (e) {
      dispatch(
        endCriteriaColloboratorRoleUpdate(null, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

//-------------------------------------
function startRemoveCriteriaApprover() {
  return {
    type: START_REMOVE_CRITERIA_MEMBER,
    payload: {},
  };
}

function endRemoveCriteriaApprover(criteriaId, userId, success, error) {
  return {
    type: END_REMOVE_CRITERIA_MEMBER,
    payload: {
      criteriaId,
      userId,
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function removeCriteriaApprover(evId, criteriaId, approverId) {
  const api_server = Environment.api_host("EVALUATION");
  const url = `${api_server}/${evId}/criteria/${criteriaId}/colloborator/${approverId}`;
  return async (dispatch) => {
    dispatch(startRemoveCriteriaApprover());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endRemoveCriteriaApprover(criteriaId, approverId, response, error));
    } catch (e) {
      dispatch(
        endRemoveCriteriaApprover(null, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startRequirementColloboratorAdd() {
  return {
    type: START_REQUIREMENT_COLLOBORATOR_ADD,
    payload: {},
  };
}

function endRequirementColloboratorAdd(requirementId, success, error) {
  return {
    type: END_REQUIREMENT_COLLOBORATOR_ADD,
    payload: {
      requirementId,
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function addRequirementColloborator(evId, criteriaId, requirementId, userId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/criteria/${criteriaId}/requirement/${requirementId}/colloborator?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startRequirementColloboratorAdd());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(
        url,
        {
          userId: userId,
        },
        false
      );
      dispatch(endRequirementColloboratorAdd(requirementId, response, error));
    } catch (e) {
      dispatch(
        endRequirementColloboratorAdd(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

//-------------------------------------
function startRequirementCriteriaImport() {
  return {
    type: START_REQUIREMENT_CRITERIA_IMPORT,
    payload: {},
  };
}

function endRequirementCriteriaImport(success, error) {
  return {
    type: END_REQUIREMENT_CRITERIA_IMPORT,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function importRequirement(evId, criteriaIds, templateType, productFeature = false) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/importrequirement?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startRequirementCriteriaImport());
    try {
      var data = {
        type: templateType,
        criteriaIds: criteriaIds,
      };

      if (productFeature) {
        data["product_features"] = true;
      }

      let [response, error] = await HttpFactory.instance().postMethod(url, data);
      dispatch(endRequirementCriteriaImport(response, error));
    } catch (e) {
      dispatch(
        endRequirementCriteriaImport(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
//------------------------------------------

/**
 * Collective response dialog
 */
export function showCollectiveResponseDialog(criteria, collectiveRequirement, collectiveProduct, evaluation_id, targetTab = 0) {
  return {
    type: SHOW_COLLECTIVE_RESPONSE_DIALOG,
    payload: {
      criteria: criteria,
      collectiveRequirement: collectiveRequirement,
      collectiveProduct: collectiveProduct,
      evaluation_id: evaluation_id,
      targetTab: targetTab,
    },
  };
}

export function updateSellerCollectiveResDatas(criteria, collectiveRequirement, collectiveProduct, evaluation_id, targetTab = 0) {
  return {
    type: UPDATE_SELLER_COLLECTIVE_RESPONSE_DIALOG,
    payload: {
      criteria: criteria,
      collectiveRequirement: collectiveRequirement,
      collectiveProduct: collectiveProduct,
      evaluation_id: evaluation_id,
      targetTab: targetTab,
    },
  };
}

export function hideCollectiveResponseDialog() {
  return {
    type: HIDE_COLLECTIVE_RESPONSE_DIALOG,
    payload: null,
  };
}

/**
 * Collective responses start
 */
function startCollectiveResponsesFetch() {
  return {
    type: START_COLLECTIVE_RESPONSES_CHAT,
    payload: {},
  };
}

function endCollectiveResponsesFetch(success, error) {
  return {
    type: END_COLLECTIVE_RESPONSES_CHAT,
    payload: {
      success,
      error,
    },
  };
}

export function fetchCollectiveResponses(queryparams) {
  const { evaluation_id, product_id, requirement_id } = queryparams;
  const api_server = Environment.api_host("EVALUATION");
  const url = `${api_server}/${evaluation_id}/product/${product_id}/requirement/${requirement_id}/responses`;
  return async (dispatch) => {
    dispatch(startCollectiveResponsesFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endCollectiveResponsesFetch(response, error));
    } catch (e) {
      dispatch(
        endCollectiveResponsesFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
/**
 * Collective responses end
 */

export function showAskSellerDialog(requirement, product, evaluation_id) {
  return {
    type: SHOW_ASK_SELLER_DIALOG,
    payload: {
      requirement: requirement,
      product: product,
      evaluation_id: evaluation_id,
    },
  };
}

export function hideAskSellerDialog() {
  return {
    type: HIDE_ASK_SELLER_DIALOG,
    payload: null,
  };
}

/**
 * Ask Vendor start
 */
function startAskSellerRequest() {
  return {
    type: START_ASK_SELLER_REQUEST,
    payload: {},
  };
}

function endAskSellerRequest(success, error) {
  return {
    type: END_ASK_SELLER_REQUEST,
    payload: {
      success,
      error,
    },
  };
}

export function addAskSellerRequest(uuid, requirement_id, responseString) {
  const api_server = Environment.api_host("EVALUATION");
  const url = `${api_server}/${uuid}/requirement/${requirement_id}/askseller`;
  return async (dispatch) => {
    dispatch(startAskSellerRequest());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, {
        response: responseString,
      });
      dispatch(endAskSellerRequest(response, error));
    } catch (e) {
      dispatch(
        endAskSellerRequest(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

/**
 * Get response threads for particular response_id
 */
function startFetchResponseForThread() {
  return {
    type: START_GET_RESPONESE_FOR_THREAD,
    payload: {},
  };
}

function endFetchResponseForThread(evaluation_id, response_id, success, error) {
  return {
    type: END_GET_RESPONESE_FOR_THREAD,
    payload: {
      evaluation_id,
      response_id,
      success,
      error,
    },
  };
}

export function fetchResponseForThread(evaluation_id, response_id) {
  const api_server = Environment.api_host("EVALUATION");
  const url = `${api_server}/${evaluation_id}/response/${response_id}/thread`;
  return async (dispatch) => {
    dispatch(startFetchResponseForThread());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchResponseForThread(evaluation_id, response_id, response, error));
    } catch (e) {
      dispatch(
        endFetchResponseForThread(null, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

/**
 * Fetch Ask Vendor thread
 */
function startFetchAskSellerThread() {
  return {
    type: START_FETCH_ASK_SELLER_THREAD,
    payload: {},
  };
}

function endFetchAskSellerThread(uuid, requirement_id, success, error) {
  console.log(success, "After fetch ask thread");
  return {
    type: END_FETCH_ASK_SELLER_THREAD,
    payload: {
      uuid,
      requirement_id,
      success,
      error,
    },
  };
}

export function fetchAskSellerThread(uuid, requirement_id) {
  const api_server = Environment.api_host("EVALUATION");
  const url = `${api_server}/${uuid}/requirement/${requirement_id}/org/askseller-thread`;
  return async (dispatch) => {
    dispatch(startFetchAskSellerThread(uuid, requirement_id));
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchAskSellerThread(uuid, requirement_id, response, error));
    } catch (e) {
      dispatch(
        endFetchAskSellerThread(null, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

/**
 * Start reply for seller thread
 */
function startReplyForResponseThread() {
  return {
    type: START_REPLY_RESPONESE_FOR_THREAD,
    payload: {},
  };
}

function endReplyForResponseThread(success, error) {
  return {
    type: END_REPLY_RESPONESE_FOR_THREAD,
    payload: {
      success,
      error,
    },
  };
}

export function addReplyForResponseThread(evaluation_id, response_id, reply) {
  const api_server = Environment.api_host("EVALUATION");
  const url = `${api_server}/${evaluation_id}/response/${response_id}/thread`;
  return async (dispatch) => {
    dispatch(startReplyForResponseThread());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, {
        reply: reply,
      });
      dispatch(endReplyForResponseThread(response, error));
    } catch (e) {
      dispatch(
        endReplyForResponseThread(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

/**
 * Update response thread
 */
function startUpdateResopneThread() {
  return {
    type: START_UPDATE_RESPONESE_FOR_THREAD,
    payload: {},
  };
}

function endUpdateResopneThread(success, error) {
  return {
    type: END_UPDATE_RESPONESE_FOR_THREAD,
    payload: {
      success,
      error,
    },
  };
}

export function updateResponseThread(evaluation_id, response_id, thread_id, responseString) {
  const api_server = Environment.api_host("EVALUATION");
  const url = `${api_server}/${evaluation_id}/response/${response_id}/thread/${thread_id}`;
  return async (dispatch) => {
    dispatch(startUpdateResopneThread());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, {
        reply: responseString,
      });
      dispatch(endUpdateResopneThread(response, error));
    } catch (e) {
      dispatch(
        endUpdateResopneThread(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

/**
 * Update response thread
 */
function startDeleteResopneThread() {
  return {
    type: START_DELETE_RESPONESE_THREAD,
    payload: {},
  };
}

function endDeleteResopneThread(success, error) {
  return {
    type: END_DELETE_RESPONESE_THREAD,
    payload: {
      success,
      error,
    },
  };
}

export function deletResponseThread(evaluation_id, response_id, response_thread_id) {
  // DELETE: evaluation/:id/response/:response_id/thread/:response_thread_id
  const api_server = Environment.api_host("EVALUATION");
  const url = `${api_server}/${evaluation_id}/response/${response_id}/thread/${response_thread_id}`;
  return async (dispatch) => {
    dispatch(startDeleteResopneThread());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteResopneThread(response, error));
    } catch (e) {
      dispatch(
        endDeleteResopneThread(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startLastEvaluationUserResponseFetch() {
  return {
    type: START_LAST_EVALUATION_USER_RESPONSE_FETCH,
    payload: {},
  };
}

function endLastEvaluationUserResponseFetch(success, error, product_id) {
  return {
    type: END_LAST_EVALUATION_USER_RESPONSE_FETCH,
    payload: {
      success,
      error,
      product_id,
    },
  };
}

//Thunk to perform the authorizedUser
export function fetchLastEvaluationUserResponse(evId, product_id) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/user-last-response/${product_id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startLastEvaluationUserResponseFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endLastEvaluationUserResponseFetch(response, error, product_id));
    } catch (e) {
      dispatch(
        endLastEvaluationUserResponseFetch(
          null,
          {
            message: e.message,
          },
          product_id
        )
      );
      return;
    }
  };
}

function startRequirementTreeFetch() {
  return {
    type: START_REQUIREMENT_TREE_FETCH,
    payload: {},
  };
}

function endRequirementTreeFetch(success, error) {
  return {
    type: END_REQUIREMENT_TREE_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchRequirementTree(evaluation_id, product_id, forAdmin = false) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evaluation_id}/requirement-tree/${product_id}?forAdmin=${forAdmin}&t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startRequirementTreeFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endRequirementTreeFetch(response, error));
    } catch (e) {
      dispatch(
        endRequirementTreeFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startRequirementResponseFetch() {
  return {
    type: START_REQUIREMENT_RESPONSE_FETCH,
    payload: {},
  };
}

function endRequirementResponseFetch(success, error) {
  return {
    type: END_REQUIREMENT_RESPONSE_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchRequirementResponse(evaluation_id, requirement_id) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evaluation_id}/criteria/0/requirement/${requirement_id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startRequirementResponseFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endRequirementResponseFetch(response, error));
    } catch (e) {
      dispatch(
        endRequirementResponseFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startRequirementUserResponseFetch() {
  return {
    type: START_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH,
    payload: {},
  };
}

function endRequirementUserResponseFetch(success, error) {
  return {
    type: END_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchRequirementUserResponse(evaluation_id, product_id, requirement_id) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evaluation_id}/product/${product_id}/requirement/${requirement_id}/user_response?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startRequirementUserResponseFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false, false);
      dispatch(endRequirementUserResponseFetch(response, error));
    } catch (e) {
      dispatch(
        endRequirementUserResponseFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startfetchEvaProductsScoreAndProgress() {
  return {
    type: START_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS,
    payload: {},
  };
}

function endfetchEvaProductsScoreAndProgress(success, error) {
  return {
    type: END_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchEvaluationProductsScore(evId, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/products_scores?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startfetchEvaProductsScoreAndProgress());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, true);
      dispatch(endfetchEvaProductsScoreAndProgress(response, error));
    } catch (e) {
      dispatch(
        endfetchEvaProductsScoreAndProgress(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchEvaluationOverallProductsScore() {
  return {
    type: START_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS,
    payload: {},
  };
}

function endFetchEvaluationOverallProductsScore(success, error) {
  return {
    type: END_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchEvaluationOverallProductsScore(evId, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/products_scores?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchEvaluationOverallProductsScore());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, true);
      dispatch(endFetchEvaluationOverallProductsScore(response, error));
    } catch (e) {
      dispatch(
        endFetchEvaluationOverallProductsScore(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export function setSelectedRequirement(req_id) {
  return {
    type: SET_SELECTED_REQUIREMENT_FOR_EVALUATION,
    payload: {
      req_id,
    },
  };
}

export function setSelectedRequirementTreeType(type) {
  return {
    type: SET_SELECTED_REQUIREMENT_TREE_TYPE_FOR_EVALUATION,
    payload: {
      type,
    },
  };
}

export function setSelectedProduct(product_id) {
  return {
    type: SET_SELECTED_PRODUCT_FOR_EVALUATION,
    payload: {
      product_id,
    },
  };
}

export function clearRequirementResponse() {
  return {
    type: CLEAR_REQUIREMENT_RESPONSES,
    payload: {},
  };
}

export function setIsNewChangesFound(data) {
  return {
    type: SET_REQUIREMENT_lOCAL_RESPONSE,
    payload: {
      data,
    },
  };
}

export function setGoToNextRequirement(data) {
  return {
    type: SET_GO_TO_REQUIREMENT,
    payload: {
      data,
    },
  };
}

function startRequirementUserResponseStatusFetch() {
  return {
    type: START_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH,
    payload: {},
  };
}

function endRequirementUserResponseStatusFetch(success, error) {
  return {
    type: END_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchRequirementUserResponseStatus(evaluation_id) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evaluation_id}/user-responses-status?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startRequirementUserResponseStatusFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endRequirementUserResponseStatusFetch(response, error));
    } catch (e) {
      dispatch(
        endRequirementUserResponseStatusFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export function setRequirementTreeColExpand(req_id, type) {
  return {
    type: SET_REQUIREMENT_TREE_COLLAPSE_EXPAND,
    payload: {
      req_id,
      type,
    },
  };
}

function startFetchRequirementOrgProgressStatus() {
  return {
    type: START_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH,
    payload: {},
  };
}

function endFetchRequirementOrgProgressStatus(success, error) {
  return {
    type: END_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchRequirementOrgProgressStatus(evaluation_id) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evaluation_id}/org-progress-status?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchRequirementOrgProgressStatus());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchRequirementOrgProgressStatus(response, error));
    } catch (e) {
      dispatch(
        endFetchRequirementOrgProgressStatus(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export function updatecriteriaReqShowMap(isCheckTreeConditions, criteriaReqShowMap, showRequirementIds) {
  return {
    type: SET_CRITERIA_REQUIREMENT_SHOW_MAP,
    payload: {
      isCheckTreeConditions,
      criteriaReqShowMap,
      showRequirementIds,
    },
  };
}

export function setSelectedSummaryView(viewId) {
  return {
    type: SET_SELECTED_SUMMARY_VIEW_FOR_EVALUATION,
    payload: {
      viewId,
    },
  };
}

export function setSelectedProductsForSummaryView(products) {
  return {
    type: SET_SELECTED_PRODUCTS_FOR_SUMMARY_VIEW,
    payload: {
      products,
    },
  };
}

export function setSelectedCriteriasForSummaryView(criterias) {
  return {
    type: SET_SELECTED_CRITERIAS_FOR_SUMMARY_VIEW,
    payload: {
      criterias,
    },
  };
}

export function setForAdminFlag(state) {
  return {
    type: SET_REQUIREMENT_FOR_ADMIN_FLAG,
    payload: {
      state,
    },
  };
}

export function setMoveToNextFlag(flag) {
  return {
    type: SET_MOVE_TO_NEXT_REQUIREMENT_FLAG,
    payload: {
      // flag
      flag: false,
    },
  };
}

export function showScoringScaleDialog(from) {
  return {
    type: SHOW_SCORING_SCALE_DIALOG,
    payload: { from },
  };
}

export function hideScoringScaleDialog() {
  return {
    type: HIDE_SCORING_SCALE_DIALOG,
    payload: {},
  };
}

function startFetchQuestionaireRequiredStatus() {
  return {
    type: START_FETCH_QUESTIONAIRE_REQUIRED_STATUS,
    payload: {},
  };
}

function endFetchQuestionaireRequiredStatus(success, error) {
  return {
    type: END_FETCH_QUESTIONAIRE_REQUIRED_STATUS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchQuestionaireRequiredStatus(evId, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${evId}/products_scores?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchQuestionaireRequiredStatus());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, true);
      dispatch(endFetchQuestionaireRequiredStatus(response, error));
    } catch (e) {
      dispatch(
        endFetchQuestionaireRequiredStatus(null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
