import { Button, CircularProgress, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import deleteIcon from "assets/images/delete.svg";
// components
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import Image from "Components/Common/image.jsx";
import DialogOrgProductChampions from "Components/Dialogs/DialogOrgProductChampions";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { authorizedUser } from "redux/authuser/action";
import {
  createEvaluationCart, fetchEvaluationCart, removeProductFromCart
} from "redux/evaluation/cart/action";
// redux
import { fetchVendors } from "redux/metric/action";
import {
  addOrgRelatedProducts,
  deleteOrgRelatedProducts, fetchOrgProductDetails, fetchOrgProducts, fetchOrgRelatedProducts, hideAddOrgProductChampionsDialog, showAddOrgProductChampionsDialog
} from "redux/product/orgProduct/action";
import CommonCss from "../../commonCss";
import AppBarV2 from "../Application/AppBarV2";


const connectedProps = (state) => ({
  vendors: state.metric.vendors,
  productDetails: state.orgProduct.orgProductDetails,
  productFecthProgress: state.orgProduct.orgProductFetchProg,
  products: state.orgProduct.orgProducts,
  totalOrgProducts: state.orgProduct.totalOrgProducts,
  evaluationCart: state.evaluationCart.evaluation,

  orgIntegration: state.orgProduct.orgIntegration,
  orgSimilarProduct: state.orgProduct.orgSimilarProduct,
  orgSameProduct: state.orgProduct.orgSameProduct,
  orgComplementaryProduct: state.orgProduct.orgComplementaryProduct,
  orgIntegrationTotal: state.orgProduct.orgIntegrationTotal,
  orgSimilarProductTotal: state.orgProduct.orgSimilarProductTotal,
  orgSameProductTotal: state.orgProduct.orgSameProductTotal,
  orgComplementaryProductTotal: state.orgProduct.orgComplementaryProductTotal,
  orgIntegrationProgress: state.orgProduct.orgIntegrationProgress,
  orgSimilarProductProgress: state.orgProduct.orgSimilarProductProgress,
  orgSameProductProgress: state.orgProduct.orgSameProductProgress,
  orgComplementaryProductProgress:
    state.orgProduct.orgComplementaryProductProgress,
  addOrgIntegrationProgress: state.orgProduct.addOrgIntegrationProgress,
  addOrgSimilarProductProgress: state.orgProduct.addOrgSimilarProductProgress,
  addOrgSameProductProgress: state.orgProduct.addOrgSameProductProgress,
  addOrgComplementaryProductProgress:
    state.orgProduct.addOrgComplementaryProductProgress,
  deleteOrgIntegrationProgress: state.orgProduct.deleteOrgIntegrationProgress,
  deleteOrgSimilarProductProgress:
    state.orgProduct.deleteOrgSimilarProductProgress,
  deleteOrgSameProductProgress: state.orgProduct.deleteOrgSameProductProgress,
  deleteOrgComplementaryProductProgress:
    state.orgProduct.deleteOrgComplementaryProductProgress,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  fetchVendors: fetchVendors,
  fetchOrgProducts: fetchOrgProducts,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchOrgRelatedProducts: fetchOrgRelatedProducts,
  addOrgRelatedProducts: addOrgRelatedProducts,
  deleteOrgRelatedProducts: deleteOrgRelatedProducts,
  showAddOrgProductChampionsDialog: showAddOrgProductChampionsDialog,
  hideAddOrgProductChampionsDialog: hideAddOrgProductChampionsDialog,
  fetchOrgProductDetails: fetchOrgProductDetails,
  authorizedUser: authorizedUser,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    filterSection: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      margin: 0,
    },
    productSection: {
      marginTop: theme.spacing(6.2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    searchSection: {
      minWidth: 400,
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
    productList: {
      position: "relative",
    },
    deleteImage: {
      position: "absolute",
      top: 10,
      zIndex: 16,
      right: 16,
      padding: 4,
      cursor: "pointer",
    },
    cardAction: {
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      position: "relative",
      flex: 1,

      "& [class*='CardAction-iconButton']": {
        color: "#74798C",
      },
    },

    primary: {
      ...CommonCss.primary,
      fontWeight: 600,
      lineHeight: 1.334,
      fontSize: "1em",
    },

    secondary: {
      textAlign: "center",
    },
    content: {
      ...CommonCss.content,
      flex: 2,
      display: "flex",
      alignItems: "center",
    },
    gridSection: {
      ...CommonCss.cardSection,
      marginTop: 10,
      "& [class*='MuiGrid-item']": {
        padding: 10,
        // display: 'flex'
      },
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    recommendedList: {
      maxHeight: "calc(100vh - 186px)",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: 250,
    },
    emptyText: {
      textAlign: "center",
      height: 50,
      paddingTop: 20,
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
    rootContainer:CommonCss.rootContainer
  });

class OrgProductRelationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: "",
      page: 0,
      pageSize: 10,
      query: "",
    };
  }

  searchData = (e) => {
    let me = this;
    this.setState({ query: e.target.value, page: 0 }, () => {
      me.fetchOrgRelatedProducts();
    });
  };
  componentDidMount() {
    this.fetchOrgRelatedProducts();
    this.fetchOrgProductDetails();
  }

  componentDidUpdate(prevProps) {
    if (
      (!this.props.addOrgIntegrationProgress &&
        prevProps.addOrgIntegrationProgress) ||
      (!this.props.deleteOrgIntegrationProgress &&
        prevProps.deleteOrgIntegrationProgress)
    ) {
      this.props.hideAddOrgProductChampionsDialog();
      this.fetchOrgRelatedProducts(1, 0);
    }

    if (
      (!this.props.addOrgSimilarProductProgress &&
        prevProps.addOrgSimilarProductProgress) ||
      (!this.props.deleteOrgSimilarProductProgress &&
        prevProps.deleteOrgSimilarProductProgress)
    ) {
      this.fetchOrgRelatedProducts(2, 0);
      this.props.hideAddOrgProductChampionsDialog();
    }

    if (
      (!this.props.addOrgSameProductProgress &&
        prevProps.addOrgSameProductProgress) ||
      (!this.props.deleteOrgSameProductProgress &&
        prevProps.deleteOrgSameProductProgress)
    ) {
      this.fetchOrgRelatedProducts(3, 0);
      this.props.hideAddOrgProductChampionsDialog();
    }

    if (
      (!this.props.addOrgComplementaryProductProgress &&
        prevProps.addOrgComplementaryProductProgress) ||
      (!this.props.deleteOrgComplementaryProductProgress &&
        prevProps.deleteOrgComplementaryProductProgress)
    ) {
      this.fetchOrgRelatedProducts(4, 0);
      this.props.hideAddOrgProductChampionsDialog();
    }
  }

  fetchOrgRelatedProducts() {
    this.props.fetchOrgRelatedProducts(
      parseInt(this.props.match?.params?.relationType),
      this.props.match?.params?.id,
      {
        relationTypes: [parseInt(this.props.match?.params?.relationType)],
        page: this.state.page,
        pageSize: this.state.pageSize,
      }
    );
  }

  saveProduct = (value) => {
    if (value) {
      this.props.addOrgRelatedProducts(
        parseInt(this.props.match?.params?.relationType),
        this.props.match?.params?.id,
        {
          productID: value,
          relationType: parseInt(this.props.match?.params?.relationType),
        }
      );
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  saveData = (data) => {
    if (data?.selectedProduct?.value) {
      this.saveProduct(data?.selectedProduct?.value);
    }
  };

  deleteProduct = (item) => {
    this.props.deleteOrgRelatedProducts(
      parseInt(this.props.match?.params?.relationType),
      this.props.match?.params?.id,
      {
        productIDs: [item.ID],
        relationType: parseInt(this.props.match?.params?.relationType),
      }
    );
  };

  fetchOrgProductDetails = () => {
    this.props.fetchOrgProductDetails(this.props.match?.params?.id);
  };

  // reviewProduct = (e,id) => {
  //   e.stopPropagation();
  //   let redirectPath = `/app/products/product-review/${id}/`;
  //   this.props.history.push(redirectPath);
  // }

  // producDetail = (e,product) => {
  //   this.props.history.push(`/app/products/org-product-details/${product.ID}`)
  // }

  render() {
    const classes = this.props.classes;

    let productList = [];
    let productFecthProgress = false;
    let title = "";
    let total = 0;

    if (this.props.match?.params?.relationType === "1") {
      productList = this.props.orgIntegration;
      productFecthProgress = this.props.orgIntegrationProgress;
      title = "Integrations";
      total = this.props.orgIntegrationTotal;
    } else if (this.props.match?.params?.relationType === "2") {
      productList = this.props.orgSimilarProduct;
      productFecthProgress = this.props.orgSimilarProductProgress;
      title = "Similar Products";
      total = this.props.orgSimilarProductTotal;
    } else if (this.props.match?.params?.relationType === "3") {
      productList = this.props.orgSameProduct;
      productFecthProgress = this.props.orgSameProductProgress;
      title = "Other products by";
      total = this.props.orgSameProductTotal;
    } else if (this.props.match?.params?.relationType === "4") {
      productList = this.props.orgComplementaryProduct;
      productFecthProgress = this.props.orgComplementaryProductProgress;
      title = "Complementary Products";
      total = this.props.orgComplementaryProductTotal;
    }

    const chooseProduct = {
      head: "Add Vendor",
      isProductList: true,
      isProductRequired: true,
      productPlacehoder: "Choose product",
      type: "addProduct",
      existingProductList: productList || [],
    };

    const content = {
      ...this.props,
      parentClass: classes,
      title: title,
      countLabel: "product",
      pluralCountLabel: "products",
      searchPlaceholder: "Search product",
      isFilterRequired: true,
    };

    return (
      <div>
        <div>
          <AppBarV2
            title={`${content.title} ${
              this.props.match?.params?.relationType === "3"
                ? this.props.productDetails?.companyName
                : ""
            }`}
            withBack={true}
          />
          <div className={classes.rootContainer}>
          <div className={classes.productSection}>
            <p className={classes.productCount}>
              {total}{" "}
              {total > 1 ? content.pluralCountLabel : content.countLabel} found
            </p>
            {/* <SearchInput
            placeholder={content.searchPlaceholder }
            searchClass={classes.searchSection}
            searchData={this.searchData}
          /> */}
           {this.props.userRole === "OrgAdmin" && (
            <div className={classes.filter}>
              <Button
                onClick={() =>
                  this.props.showAddOrgProductChampionsDialog(chooseProduct)
                }
                className={classes.filterSection}
              >
                +Add vendor
              </Button>
            </div>
           )}
          </div>
          <div className={classes.gridSection}>
            <div className={classes.recommendedList} id="productRelationList">
              <InfiniteScroll
                scrollableTarget="productRelationList"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  marginRight: 10,
                }} //To put endMessage and loader to the top.
                dataLength={productList?.length} //This is important field to render the next data
                next={() => {
                  var me = this;
                  this.setState({ page: this.state.page + 1 }, () => {
                    me.fetchOrgRelatedProducts();
                  });
                }}
                hasMore={
                  productList.length ===
                  (this.state.page + 1) * this.state.pageSize
                }
                endMessage={
                  productList?.length === 0 &&
                  productFecthProgress === false && (
                    <p className={classes.emptyText}>
                      <span>
                        <span>Product is not available</span>{" "}
                      </span>
                    </p>
                  )
                }
              >
                <Grid container spacing={3}>
                  {productList.map((product, i) => {
                    return (
                      <Grid item lg={2} md={3} key={i}>
                        <div className={classes.productList}>
                          {this.props.userRole === "OrgAdmin" && (
                            <Image
                              src={deleteIcon}
                              alt={product.Name}
                              className={classes.deleteImage}
                              onClick={() => this.deleteProduct(product)}
                            />
                          )}
                          <LayeredCard
                            title={product.Name}
                            subTitle={product.CompanyName}
                            description={product.Description}
                            image={product.ProductLogo}
                            onClick={() => {}}
                            numLayer={0}
                            showCheckbox={false}
                            id={product.ID}
                            uniqueId={product.ID + "_t_" + i}
                            type={"product"}
                            IsExisting={product.IsExisting}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
              {productFecthProgress === true && productList?.length === 0 && (
                <div className={classes.backdrop}>
                  {" "}
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
          <DialogOrgProductChampions saveData={this.saveData} />
          </div>
        </div>
      </div>
    );
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(OrgProductRelationList)
);
