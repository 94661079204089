import {
    Dialog, DialogContent, Slide, Typography, InputAdornment, CircularProgress
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SqureButton from 'Components/Common/SqureButton';
import moment from 'moment';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import CalendarImage from "assets/images/calendar.svg";
import Image from 'Components/Common/image.jsx';
import { hideUserLogginActivityDialog, downloadLoggingActivity } from "redux/usersSettings/action";
import Alert from '@material-ui/lab/Alert';
import { showSnackBar } from "redux/snackbar/action";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.usersSettings.isShowLogginActivityDialog,
    downloadLogginActivityProgress: state.usersSettings.downloadLogginActivityProgress,
    downloadLogginActivityError: state.usersSettings.downloadLogginActivityError,
    logginActivity: state.usersSettings.logginActivity,
});

const connectionActions = {
    hideDialog: hideUserLogginActivityDialog,
    downloadLoggingActivity: downloadLoggingActivity,
    showSnackBar: showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 30
    },
    body: {
        marginTop: 30
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    dateFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    itemTitle: {
        fontWeight: 400,
        color: '#282D30',
        fontSize: 14,
    },
    startDate: {
        marginRight: 10
    },
    actionBtns: {
        textAlign: 'right',
    },
    alert: {
        marginBottom: theme.spacing(1)
    }
});

class LogginActivityDownloadDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            startDate: null,
            endDate: null
        }
    this.divRef = React.createRef();

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        const { downloadLogginActivityProgress, downloadLogginActivityError, logginActivity } = this.props;
        if (!downloadLogginActivityProgress && prevProps.downloadLogginActivityProgress) {
            if (downloadLogginActivityError === null) {
                console.log(logginActivity, 'logginActivity') 
                const link = document.createElement('a');
                link.href = logginActivity.path;
                link.setAttribute('download', "login_activity.xlsx");
                this.divRef.current.appendChild(link)
                link.click();
                link.parentNode.removeChild(link);
                this.props.showSnackBar('Login Activity Downloaded Successfully', 'success', 3000)
                this.props.hideDialog()
            } else {
                this.props.showSnackBar('Something Went Wrong', 'error', 3000)
            }
        }
    }

    clearError() {
        this.setState({
            error: '',
            success: ''
        })
    }
    downloadActivity = () => {
        const { startDate, endDate } = this.state;
        this.clearError()

        if (startDate === '' || startDate === null) {
            this.setState({
                error: 'Please select start date'
            })
            return;
        }

        if (endDate === '' || endDate === null) {
            this.setState({
                error: 'Please select end date'
            })
            return;
        }

        console.log(startDate, 'StartDate')
        console.log(endDate, 'ENdDate')

        this.props.downloadLoggingActivity({
            start_date: moment(startDate).toISOString(),
            end_date: moment(endDate).toISOString(),
        })
    }

    render() {
        const classes = this.props.classes;

        const { startDate, endDate } = this.state
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Download Login Activity</Typography>
                <div className={classes.body}>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>
                    <div className={classes.dateFilters}>
                        <div className={classes.startDate}>
                            <Typography variant={"h5"} className={classes.itemTitle} >{'Start Date'}</Typography>
                            <DatePicker
                                className={classes.datePicker}
                                selected={startDate}
                                popperClassName={classes.calendarPopout}
                                disabled={false}
                                onChange={date => {
                                    this.setState({
                                        startDate: date
                                    }, () => {
                                        this.clearError()
                                    })
                                }}
                                dateFormat="MMM do yyyy"
                                portalId="evMetaDialog"
                                minDate={null}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                    <OutlinedInput
                                        readOnlyInput={true}
                                        startAdornment={
                                            <InputAdornment>
                                                <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                            </InputAdornment>
                                        }
                                    />
                                }
                            />
                        </div>
                        <div className={classes.endDate}>
                            <Typography variant={"h5"} className={classes.itemTitle} >{'End Date'}</Typography>
                            <DatePicker
                                className={classes.datePicker}
                                selected={endDate}
                                popperClassName={classes.calendarPopout}
                                disabled={false}
                                onChange={date => {
                                    this.setState({
                                        endDate: date
                                    }, () => {
                                        this.clearError()
                                    })
                                }}
                                dateFormat="MMM do yyyy"
                                portalId="evMetaDialog"
                                minDate={this.state.startDate}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                    <OutlinedInput
                                        readOnlyInput={true}
                                        startAdornment={
                                            <InputAdornment>
                                                <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                            </InputAdornment>
                                        }
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div ref={this.divRef} id="downloadlink"></div>

                    <div className={classes.actionBtns}>
                        <SqureButton cls={classes.actBtn} variant={"outlined"}
                            onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actBtn} variant={"contained"}
                            onClick={this.downloadActivity}>
                                {this.props.downloadLogginActivityProgress ? <CircularProgress style={{width:25,height:25}}/>: 'Download'}</SqureButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}

LogginActivityDownloadDialog.defaultProps = {
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(LogginActivityDownloadDialog));