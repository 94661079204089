import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"


export const START_FETCH_REVIEW_WORKFLOWS = "START_FETCH_REVIEW_WORKFLOWS";
export const END_FETCH_REVIEW_WORKFLOWS = "END_FETCH_REVIEW_WORKFLOWS";

export const START_DELETE_REVIEW = "START_DELETE_REVIEW";
export const END_DELETE_REVIEW = "END_DELETE_REVIEW";


function startFetchReviewWorkflows(onInit = false){
  let payload = {}
  payload.onInit = onInit
  return {
      type:START_FETCH_REVIEW_WORKFLOWS,
      payload:payload
  };
}

function endFetchReviewWorkflows(success,error,params){
  return {
      type:END_FETCH_REVIEW_WORKFLOWS,
      payload:{
          success,error,params
      }
  };
}

export  function fetchReviewWorkflows(queryParam){
  const api_server = Environment.api_host("EVALUATION");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/fetch?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchReviewWorkflows());
      try{
          let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
          dispatch(endFetchReviewWorkflows(response,error,queryParam));
      } catch(e){
          dispatch(endFetchReviewWorkflows(null,{
              message:e.message
          }));
          return;
      }
  };
} 

function startDeleteReview() {
  return {
      type: START_DELETE_REVIEW,
      payload: null
  };
}

function endDeleteReview(success, error, id) {
    return {
        type: END_DELETE_REVIEW,
        payload: {
            success, error, id
        }
    };
}

export function deleteReview(id) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  let url = `${api_server}/review/${id}?t=${timestamp}`
  return async (dispatch) => {
      dispatch(startDeleteReview());
      try {
          let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
          dispatch(endDeleteReview(response, error,id));
      } catch (e) {
          dispatch(endDeleteReview(null, null, {
              message: e.message
          }));
          return;
      }
  };
}