import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { InputLabel,FormControl,Grid,Button,Divider, CircularProgress,Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactSelect from 'react-select'
import {fetchVendorEvaluation} from "redux/evaluate/action"
import {updateEvaluationMetaData} from "redux/evaluation/metadata/action"
import {fetchParentEvaluation,saveChildEvaluation,fetchChildEvaluation} from "redux/evaluation/link/action"


import EvaluationCard from "./EvaluationCard"
import Alert from '@material-ui/lab/Alert';
const connectedProps = (state) => ({
  isOpen:state.evaluationLink.showDialog,
  evaluations:state.evaluate.vendorEvaluations.Data==undefined?[]:state.evaluate.vendorEvaluations.Data,
  currentEvaluation:state.evaluationMetaData.evaluation,
  childEvaluationFetchProg:state.evaluationLink.childEvaluationFetchProg,
  childEvaluation:state.evaluationLink.childEvaluation,
  updateProgress:state.evaluationLink.childSaveProgress,
  updateError:state.evaluationLink.errorChildSave,
});

const connectionActions = {
  fetchEvaluation:fetchVendorEvaluation,
  saveChildEvaluation:saveChildEvaluation,
  fetchParentEvaluation:fetchParentEvaluation,
  fetchChildEvaluation:fetchChildEvaluation
}



var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  parentForm:{
    padding:theme.spacing(3)
  },
  inputLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight:600
  },
  formControl: {
    marginBottom: theme.spacing(1)
  },
  alerts:{
    marginBottom:theme.spacing(2)
},
});

class SelectExisting extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          searchQuery:"",
          error:"",
          success:"",
          evaluation:[]
        }
        this.updateAsChild   =   this.updateAsChild.bind(this);
        this.fetchChildEvaluation = this.fetchChildEvaluation.bind(this);
    }

    componentDidUpdate(prevProps){
      if(this.props.isOpen && prevProps.isOpen == false){
        this.fetchChildEvaluation();
      }
      if(this.props.updateProgress === false && prevProps.updateProgress === true){
        if(this.props.updateError == null){
          this.fetchChildEvaluation();
          this.setState({
            error:"",
            success:"",
            evaluation:[]
          })
        }
      }
    }

    componentDidMount(){
      this.fetchEvaluation();
      this.fetchChildEvaluation();
    }

    fetchChildEvaluation(){
      // if(this.props.currentEvaluation.ParentEvaluationID !== null && this.props.currentEvaluation.ParentEvaluationID !== 0){
      if(this.props.currentEvaluation !== null){
        this.props.fetchChildEvaluation(this.props.currentEvaluation.ID)
      }
        
      // }
    }

    fetchEvaluation(){
      this.props.fetchEvaluation({
        query:this.state.searchQuery
      })
    }

    updateAsChild(){
      this.setState({"error":"","success":""});
      if(this.state.evaluation.length === 0){
        this.setState({"error":"Please select evaluation."});
        return
      }

      this.props.saveChildEvaluation(this.props.currentEvaluation.ID,this.state.evaluation.map((e)=>{
          return e.value;
      }))
    }

    render() {
        const classes   =   this.props.classes;
        
        return <div className={classes.parentForm}>
                    <div className={classes.alerts}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>
                    
                    
                    {!this.props.childEvaluationFetchProg && this.props.childEvaluation !== null && <>
                        <Typography >Child Evaluation</Typography>
                        {
                            this.props.childEvaluation.map((evaluation)=>{
                                return <EvaluationCard key={`ev_${evaluation.ID}`} evaluation={evaluation} />
                            })
                        }
                        
                      </>}
                    {!this.props.childEvaluationFetchProg && this.props.childEvaluation === null && <Typography >No Child Evaluation Found</Typography>}
                    {this.props.childEvaluationFetchProg && <CircularProgress/>}
                    <Divider/>
                    <Grid container spacing={2}>
                      <Grid item lg={8}>
                          <InputLabel id="teamlabel" className={classes.inputLabel}>Update Child Evaluation</InputLabel>
                          <FormControl fullWidth variant="outlined" className={classes.formControl}>
                              <ReactSelect
                                  isClearable
                                  isSearchable={true}
                                  options={this.props.evaluations.map((item)=>{
                                      return {
                                          value: item.ID,
                                          label: item.Name +" ["+item.Products.map((p)=>{
                                            return p.Name
                                          }).join(" | ")+"]",
                                          data:item
                                      }
                                  })}
                                  isMulti={true}
                                  classNamePrefix="select"
                                  placeholder="Search Evaluation" 
                                  onInputChange={(e) => {
                                      this.setState({
                                          searchQuery:e
                                      },()=>{
                                          this.fetchEvaluation()
                                      })
                                      
                                  }}
                                  isLoading={this.props.fetchGroupProg }
                                  onChange={(e,action)=>{
                                      console.log('e',e)
                                      this.setState({
                                        evaluation:e
                                      })
                                  }}
                                  menuPortalTarget={document.getElementById("evLinkDialog")} 
                                  defaultValue={this.state.evaluation}
                                  value={this.state.evaluation}
                              />
                          </FormControl>
                      </Grid>
                      <Grid item lg={4}>
                        <InputLabel id="teamlabel" className={classes.inputLabel} style={{"visibility":"hidden"}}>&nbsp;</InputLabel>
                        {!this.props.updateProgress && <Button variant="contained" color="secondary"  fullWidth onClick={this.updateAsChild}>Update as Child</Button>}
                        {this.props.updateProgress && <CircularProgress/>}
                      </Grid>
                    </Grid>
                </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(SelectExisting));