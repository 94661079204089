import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import * as Validator from "util/Validation";

import {
    Select,
    MenuItem,
    FormControl,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import classnames from "classnames";

// components
import Image from 'Components/Common/image';

// assets
import CommonCss from "../../../../../commonCss";

// Redux
import { fetchUpcomingReviewProducts, addProductToReview } from "redux/product/orgProduct/action";

import { fetchMasterTemplate } from "redux/templates/evaluation/master/action";
import { fetchOrgTemplate } from "redux/templates/evaluation/org/action";
import { importRequirement } from "redux/evaluation/criteria/action"
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
    fetchUpcomingReviewProductsUnderProgress: state.orgProduct.fetchUpcomingReviewProductsUnderProgress,
    upcomingReviewProducts: state.orgProduct.upcomingReviewProducts,
    fetchAddProductToReview: state.orgProduct.fetchAddProductToReview,
    evaludationDetails: state.orgProduct.evaludationDetails,
    configurations: state.orgUser.fetchAllOrgSettingsSuccess,
    configurationsFetchProg: state.orgUser.fetchAllOrgSettingsInprogress,
    template: state.masterTemplateEvaluation.template,
    fetchTemplateProg: state.masterTemplateEvaluation.fetchTemplateProg,
    orgTemplate: state.orgTemplateEvaluation.template,
    fetchOrgTemplateProg: state.orgTemplateEvaluation.fetchTemplateProg,
});



const connectionActions = {
    fetchUpcomingReviewProducts: fetchUpcomingReviewProducts,
    addProductToReview: addProductToReview,
    fetchMasterTemplate: fetchMasterTemplate,
    fetchOrgTemplate: fetchOrgTemplate,
    importRequirement: importRequirement,
    showSnackBar:showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
    createStyles({
        viewAll: CommonCss.viewAll,
        productHead: {
            ...CommonCss.productHead,
            fontSize: 19,
            margin: "0px !important",
            lineHeight: '38px'
        },
        evaluateCard: {
            width: 'calc(100% - 0px)',
            background: '#fff',
            padding: "16px 24px",
            margin: '20px 0px 0px 0px',
            boxShadow: '0px 3px 25px #55555500',
            borderRadius: 5,
            display: 'flex',
            cursor: 'pointer'
        },
        evaluateCardTitle: {
            color: '#20253A',
            fontSize: 14,
            margin: 0,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        evaluateCardSubTitle: {
            fontSize: 12,
            color: '#A0A5BA',
            marginTop: "5px",
            marginBottom: 0
        },
        evaluateLogo: {
            flex: .8,
            display: 'flex',
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center'
        },
        evaluateText: {
            flex: 1.5,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        loader: {
            marginTop: 20,
            marginLeft: 20,
            display: 'block',
            position: 'absolute',
            top: '38%',
            left: '46%'
        },
        noMoreData: {
            marginTop: 30,
            display: 'block'
        },

        productLogo: {
            float: 'left',
            width: '50px',
            height: '50px',
            borderRadius: '4px',
            marginRight: '10px',
            textAlign: 'center',
            lineHeight: '33px',
            fontWeight: 600,
            fontSize: 11,
            color: '#A0A5BA',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'scale-down'
            }
        },
        rightArrow: {
            width: 17
        },
        reviewCompleted: {
            color: '#2EB77D',
            fontSize: 12,
            marginTop: 3,
            marginBottom: 0
        },
        select: {
            margin: 0,
            padding: 0,
        },
        upcomingList: {
            // marginTop: theme.spacing(6.4),
            maxHeight: "50vh",
            minHeight: 200,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
        },
        btnReview: {
            width: '66px',
            height: '32px',
            border: '1px solid #91949E',
            borderRadius: '5px',
            background: ' #ffffff',
            cursor: 'pointer',
            textAlign: 'center',
            fontSize: '13px',
            color: '#626675',
        },
        showMore: {
            display: 'inline-block',
            border: '1px solid #FFF',
            cursor: 'pointer',
            padding: '10px 14px',
            fontSize: '12px',
            background: '#4A87F8',
            borderRadius: '20px',
            color: 'white'
        }
    });

class UpcomingReviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            page: 0,
            pageSize: 5,
            upcomingDuration: 1,
            isCartShow: false,
            dropDownSwitched: false,
            defaultTemplateType: "MASTER",
            defaultCriteriaIds: [],
        };
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
        if (this.props.configurations !== null) {
            this.fetchDefaultEvaluationTemplate();
        }
    }

    fetchData() {
        this.fetchUpcomingProducts()
    }

    fetchDefaultEvaluationTemplate() {
        var templateType = "MASTER";
        var templateId = 1
        if (this.props.configurations !== null) {
            var default_review_setting = this.props.configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_REVIEW_TEMPLATE";
            })

            if (default_review_setting.length > 0) {
                var settingValue = default_review_setting[0].Value
                if (settingValue !== "NULL") {
                    try {
                        var parsed = JSON.parse(settingValue)
                        if (parsed.type === "ORG") {
                            templateType = "ORG"
                            templateId = parseInt(parsed.tid)
                        } else if (parsed.type === "MASTER") {
                            templateType = "MASTER"
                            templateId = parseInt(parsed.tid)
                        }
                    } catch (e) { }
                }
            }
        }
        this.setState({
            defaultTemplateType: templateType
        })
        if (templateType === "MASTER") {
            this.props.fetchMasterTemplate(templateId, this.props.evaluationId)
        } else {
            this.props.fetchOrgTemplate(templateId, this.props.evaluationId)
        }
    }

    redirectPage() {
        if (this.props.configurations !== null) {
            var default_review_workflow = this.props.configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_REVIEW_WORKFLOW";
            })
            console.log(default_review_workflow, 'default_review_workflow')
            if (default_review_workflow.length > 0 && default_review_workflow[0].Value !== undefined && default_review_workflow[0].Value !== null && default_review_workflow[0].Value !== '' && default_review_workflow[0].Value !== "NULL" && default_review_workflow[0].Value.length > 0) {
                this.props.history.push(`/app/evaluate/${this.props.evaludationDetails?.ID}/workflow/interim`);
            } else {
                this.props.importRequirement(this.props.evaludationDetails?.ID, this.state.defaultCriteriaIds, this.state.defaultTemplateType)
                let redirectPath = `/app/evaluate/${this.props.evaludationDetails?.ID}/setup`;
                this.props.history.push(redirectPath);
            }
        } else {
            this.props.importRequirement(this.props.evaludationDetails?.ID, this.state.defaultCriteriaIds, this.state.defaultTemplateType)
            let redirectPath = `/app/evaluate/${this.props.evaludationDetails?.ID}/setup`;
            this.props.history.push(redirectPath);
        }
    }

    componentDidUpdate(preProps) {
        if (this.props.configurationsFetchProg === false && preProps.configurationsFetchProg === true) {
            if (this.props.configurations !== null) {
                this.fetchDefaultEvaluationTemplate();
            }
        }

        if (this.state.defaultTemplateType === "MASTER") {
            if (this.props.fetchTemplateProg === false && preProps.fetchTemplateProg === true) {
                if (this.props.template !== null) {
                    this.setState({
                        defaultCriteriaIds: this.props.template.Criterias.map((i) => {
                            return i.ID
                        }),
                        reviable: true
                    })
                }
            }
        } else {
            if (this.props.fetchOrgTemplateProg === false && preProps.fetchOrgTemplateProg === true) {
                if (this.props.orgTemplate !== null) {
                    this.setState({
                        defaultCriteriaIds: this.props.orgTemplate.Criterias.map((i) => {
                            return i.ID
                        }),
                        reviable: true
                    })
                }
            }
        }

        if (!this.props.fetchUpcomingReviewProductsUnderProgress && !this.props.upcomingReviewProducts.Data && !this.state.dropDownSwitched || !this.props.fetchUpcomingReviewProductsUnderProgress && this.props.upcomingReviewProducts?.Data?.length === 0 && !this.state.dropDownSwitched) {
            this.setState({
                upcomingDuration: 2,
                dropDownSwitched: true
            }, () => {
                this.fetchUpcomingProducts()
            })
        }

        if (!this.props.fetchAddProductToReview && preProps.fetchAddProductToReview) {
            if (this.props.evaludationDetails) {
                this.redirectPage()
            }
        }
    }


    fetchUpcomingProducts = () => {
        if (this.state.upcomingDuration === 1) {
            let data = {};
            data.page = this.state.page
            data.pageSize = this.state.pageSize
            data.inOrgAndEvaluated = true;
            data.range = {
                "greaterThan": -10
            }
            this.props.fetchUpcomingReviewProducts(data)
        } else {
            let data = {};
            data.page = this.state.page
            data.pageSize = this.state.pageSize
            data.inOrgButNotEvaluated = true;
            // data.range = {
            //     "greaterThan": -365
            // }
            this.props.fetchUpcomingReviewProducts(data)
        }
    }

    routeChange = (id, item) => {
        if (this.state.upcomingDuration === 2) {
            // this.props.addProductToReview(id, {
            //     status: 2
            // })
            if (this.props.configurations !== undefined && this.props.configurations !== null) {
                const defaultObjReview = this.props.configurations.find((item) => item.SettingName === "ORG_DEFAULT_REVIEW_WORKFLOW");
                var defaultWorkflowId = null;

                if ((defaultObjReview && Validator.isJson(defaultObjReview?.Value) && defaultObjReview?.Value && defaultObjReview?.Value !== 'NULL')) {
                    defaultWorkflowId = JSON.parse(defaultObjReview?.Value).wid
                }

                if (defaultWorkflowId === null) {
                    this.props.showSnackBar("Set default review workflow before proceeding.", "error", 3000)
                    return;
                }

                this.props.addProductToReview(id, {
                    status: 2
                })

            } else {
                this.props.showSnackBar("Set default review workflow before proceeding.", "error", 3000)
            }
        } else {
            let redirectPath = `/app/products/product-review/${id}`;
            this.props.history.push(redirectPath);
        }
    }


    render() {
        const classes = this.props.classes;

        const { upcomingReviewProducts } = this.props;

        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                        <p className={classnames(classes.productHead)}>Upcoming Reviews</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: 'right' }} >
                        <FormControl
                            variant="outlined"
                            className={classes.select}
                            margin="dense"
                        >
                            <Select
                                value={this.state.upcomingDuration}
                                onChange={(event) => {
                                    var me = this;
                                    this.setState({ upcomingDuration: event.target.value, page: 0 }, () => {
                                        me.fetchUpcomingProducts();
                                    });
                                }}
                            >
                                <MenuItem value={1}>Overdue</MenuItem>
                                <MenuItem value={2}>Never Reviewed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>


                <div style={{ position: 'relative' }}>
                    {upcomingReviewProducts?.Data?.length > 0 &&
                        <>
                            {upcomingReviewProducts?.Data?.map((item, index) =>
                                <div className={classes.evaluateCard} key={index} onClick={() => { this.routeChange(item.ID, item) }}>
                                    <div className={classes.productLogo} title={item.Name}>
                                        <Image src={item.ProductLogo} alt={item.Name} title={item.Name} />
                                    </div>
                                    <div className={classes.evaluateText}>
                                        <p className={classes.evaluateCardTitle}>{item.Name}</p>
                                        {/* <p className={classnames(classes.reviewCompleted, classes.upcomingReview)}>Last Reviewed: {CommonFn.formateDate(item.UpdatedAt)}</p> */}
                                    </div>
                                    <div className={classes.evaluateLogo}>
                                        <button type="button" className={classes.btnReview}>Review</button>
                                    </div>
                                </div>
                            )}
                            {!(upcomingReviewProducts?.Data?.length === upcomingReviewProducts?.total) &&
                                <div style={{ padding: '16px 0px', textAlign: 'center' }}>
                                    <span
                                        onClick={() => {
                                            var me = this;
                                            this.setState({ page: this.state.page + 1 }, () => {
                                                me.fetchUpcomingProducts();
                                            });
                                        }}
                                        className={classes.showMore}
                                    >Load more</span>
                                </div>
                            }
                        </>
                    }
                    {this.props.fetchUpcomingReviewProductsUnderProgress &&
                        <span className={classes.loader}><CircularProgress /></span>
                    }
                    {!this.props.fetchUpcomingReviewProductsUnderProgress && (upcomingReviewProducts?.Data?.length === 0 || upcomingReviewProducts?.Data === null) &&
                        <span className={classes.noMoreData}>No more data </span>
                    }
                </div>
            </>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(UpcomingReviews));
