import React, { Component, useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import classnames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid, Button, TextField, CircularProgress
} from '@material-ui/core'
import * as Validator from "util/Validation";
import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import CommonCss from 'commonCss';
import CustomReply, { itemTypes } from "./CustomReply"
const styles = (theme) => createStyles({
  root: {
    position: 'relative',
    border: '1px solid #d8d8d8',
    padding: 17,
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17,
    '& [class*=MuiInputBase-input]': {
      fontSize: theme.spacing(1.8),
      color: '#282D30',
      '&::-webkit-input-placeholder': {
        color: "#707070",
        fontSize: theme.spacing(1.8)
      }
    },

  },
  checkbox: {
    padding: '0px !important'
    // '& [class*=MuiCheckbox-root]': {
    // }
  },
  productLink: {
    fontSize: 13,
    color: 'black',
    fontWeight: 400,
  },
  productLinkNew: {
    display: 'inline-block',
    color: '#0093E0',
    fontSize: 18,
    textDecoration: 'underline',
    marginTop: 0,
  },
  pItem: {
    margin: 0
  },
  itemContainer: {
    position: 'relative',
    border: '1px solid #d8d8d8',
    padding: '17px 17px 0px 17px ',
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17
  },
  ...CommonCss.ProductDetails,
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  mb16: {
    marginBottom: 17,
  },
  formGroup: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
  inputLabel: {
    color: '#6C6C6C',
    fontSize: 15,
    opacity: 0.9,
    marginBottom: 11
  },
  type: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1
  },
  head: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1,
    margin: 0
  },
  itemHead: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1
  },
  fieldContainer: {
    marginBottom: 17
  },
  addNewButton: {
    fontWeight: 600,
    fontSize: theme.spacing(2)
  },
  actionIcon: {
    width: 30,
    height: 30,
    borderRadius: 5,
    textAlign: 'center',
    marginLeft: 5,
    border: '1px solid #4b86f8'
  },
  btn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 35,
    padding: 0,
    marginLeft: 15,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },

  dragIndicator: {
    color: '#D9D9D9',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
  },
  deleteIcon: {
    position: "absolute",
    right: 16,
    top: 13,
    cursor: "pointer",
  },
  formControlDialog: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  formGroupDialog: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  deleteImage: {
    cursor: "pointer",
  },
});

class FormBuilder extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      items: [{
        name: "",
        input_type: 1,
        desciption: "",
        answer: "",
        additional_data: JSON.stringify([]),
      }]
    }
  }

  handleSave = () => {
    const data = { ...this.state }
    data.items = data.items.map(obj => {
      if ([4, 5, 7, 8, 9].includes(obj.input_type)) {
        obj.answer = JSON.stringify(obj.answer)
      } else {
        obj.answer = String(obj.answer)
      }
      return obj
    })
    this.props.onSave(data)
  }

  handleItemType = (input_type, index) => {
    const items = this.state.items
    items[index] = {
      ...items[index],
      input_type,
      desciption: "",
      answer: [4, 5, 9, 7, 8].includes(input_type) ? [] : 2 === input_type ? new Date() : input_type === 3 ? 'yes' : "",
      additional_data:
        input_type === 3
          ? JSON.stringify([{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }])
          : JSON.stringify([]),
    }

    this.setState({ items })
  }

  handleUpdateItemValue = (name, key, index, additional_data) => {
    const items = this.state.items
    const item = items[index]
    item[key] = name
    item.additional_data = additional_data
    items[index] = item
    this.setState({ items })
  }

  handleRemoveItem = (index) => {
    const items = this.state.items
    items.splice(index, 1)
    this.setState({ items })
  }

  handleAddItem = () => {
    const items = this.state.items
    items.push({
      name: "",
      input_type: 1,
      desciption: "",
      answer: "",
      additional_data: JSON.stringify([]),
    })
    this.setState({ items })
  }

  handleOnSelectOption = (options, index) => {
    const items = this.state.items
    const item = items[index]
    item.answer = options
    items[index] = item
    this.setState({ items })
  }

  handleAddOption = (options, index) => {
    const items = this.state.items
    const item = items[index]
    item['additional_data'] = JSON.stringify(options)
    items[index] = item
    this.setState({ items })
  }

  handleOptionChange = (options, index) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data = JSON.stringify(options)
    items[index] = item
    this.setState({ items })
  }

  handleDeleteOption(options, itemIndex) {
    const items = this.state.items
    const item = items[itemIndex]
    item.additional_data = JSON.stringify(options)
    if (options.length === 0) {
      item.answer = []
    }
    items[itemIndex] = item
    this.setState({ items })
  }

  render() {
    const { classes } = this.props
    const {
      name, items
    } = this.state

    return (
      <div className={classes.root} id={this.props.id} key={this.props.key}>
        <div className={classnames(classes.formGroup, classes.mb16)} >
          <p className={classes.inputLabel}>Section Title</p>
          <TextField
            fullWidth
            className={classes.formControl}
            value={name}
            InputProps={{ disableUnderline: true }}
            placeholder="Enter Section Title"
            onChange={e => this.setState({ name: e.target.value })}
          />
        </div>
        {
          items && items.length > 0 && items.map((item, index) => (
            <div className={classes.itemContainer} key={index}>
              <Grid container justifyContent="space-between" alignItems="center">
                <p className={classes.inputLabel}>Sub Section Title</p>
                {items.length > 1 && !this.props.isLoader && (
                  <div>
                    <Image
                      src={deleteIcon}
                      className={classes.deleteImage}
                      style={{ top: 15 }}
                      onClick={(event) => {
                        event.stopPropagation()
                        this.handleRemoveItem(index)
                      }}
                    />
                  </div>
                )}
              </Grid>
              <div className={classnames(classes.formGroup, classes.mb16)}>
                <InputField
                  classes={classes}
                  val={item.name}
                  placeholder="Enter Sub Section Title"
                  onChange={value => {
                    this.handleUpdateItemValue(value, 'name', index, item.additional_data)
                  }}
                />
              </div>
              <p className={classes.inputLabel} style={{ marginTop: 0 }}>Sub Section Type</p>
              <div className={classes.fieldContainer}>
                <Select
                  options={itemTypes}
                  defaultValue={itemTypes.filter(type => type.value === item.input_type)[0]}
                  classNamePrefix="select"
                  placeholder={"Select Type"}
                  onChange={(e) => this.handleItemType(e.value, index)}
                />
              </div>
              <CustomReply
                key={index}
                type={item.input_type}
                value={item?.answer}
                additional_data={Validator.isJson(item.additional_data) ? JSON.parse(item.additional_data) : []}
                handleOnSelectOption={(options) => this.handleOnSelectOption(options, index)}
                handleOptionChange={(options) => this.handleOptionChange(options, index)}
                handleAddOption={(options) => this.handleAddOption(options, index)}
                handleDeleteOption={(options) => this.handleDeleteOption(options, index)}
                onChange={(response, additional_data) => this.handleUpdateItemValue(response, 'answer', index, additional_data)}
              />
            </div>
          ))
        }
        <Grid container justifyContent="space-between" direction="row-reverse" alignItems="center">
          {this.props.isLoader ? (
            <CircularProgress />
          ) : (
            <>
              <div>
                <SqureButton
                  variant={"outlined"}
                  onClick={() => {
                    this.props.onCancel()
                  }}
                >
                  Cancel
                </SqureButton>
                &nbsp; &nbsp; &nbsp;
                <SqureButton
                  variant={"contained"}
                  onClick={this.handleSave}
                >
                  Save
                </SqureButton>
              </div>
              <div>
                <Button
                  className={classes.addNewButton}
                  onClick={() => {
                    setTimeout(() => {
                      this.handleAddItem()
                    }, 100)
                  }}
                >
                  + Add Sub Section
                </Button>
              </div>
            </>
          )}
        </Grid>
      </div >
    )
  }
}

export default React.memo(withStyles(styles)(FormBuilder));

const InputField = ({ classes, onChange, val, placeholder, disabled = false, border = true }) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    setValue(val)
  }, [val])
  return (
    <TextField
      fullWidth
      className={classnames(classes.formControl, border ? {} : classes.borderNone)}
      value={value}
      disabled={disabled}
      InputProps={{ disableUnderline: true }}
      placeholder={placeholder}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value)
      }}
    />
  )
}