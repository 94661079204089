import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";
/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {MetricFactory} from "../../services/metric";

export const START_METRIC_APP_FETCH     =   "START_METRIC_APP_FETCH";
export const END_METRIC_APP_FETCH       =   "END_METRIC_APP_FETCH";

export const START_METRIC_APP_ACTIVITY_FETCH     =   "START_METRIC_APP_ACTIVITY_FETCH";
export const END_METRIC_APP_ACTIVITY_FETCH       =   "END_METRIC_APP_ACTIVITY_FETCH";

export const START_METRIC_APP_CHARTDATA_FETCH     =   "START_METRIC_APP_CHARTDATA_FETCH";
export const END_METRIC_APP_CHARTDATA_FETCH       =   "END_METRIC_APP_CHARTDATA_FETCH";

export const START_POSSIBLE_APP_FETCH     =   "START_POSSIBLE_APP_FETCH";
export const END_POSSIBLE_APP_FETCH       =   "END_POSSIBLE_APP_FETCH";

export const START_POSSIBLE_SOURCE_FETCH     =   "START_POSSIBLE_SOURCE_FETCH";
export const END_POSSIBLE_SOURCE_FETCH       =   "END_POSSIBLE_SOURCE_FETCH";

function startFetchPossibleSources(){
    return {
        type:START_POSSIBLE_SOURCE_FETCH,
        payload:{}
    };
}

function endFetchPossibleSources(success,error){
    return {
        type:END_POSSIBLE_SOURCE_FETCH,
        payload:{
            success,error
        }
    };
}

export function fetchPossibleSources(id){
    const api_server = Environment.api_host("METRIC");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/vendor/${id}/possible_sources?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchPossibleSources());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchPossibleSources(response,error));
        } catch(e){
            dispatch(endFetchPossibleSources(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startFetchPossibleApps(){
    return {
        type:START_POSSIBLE_APP_FETCH,
        payload:{}
    };
}

function endFetchPossibleApps(success,error){
    return {
        type:END_POSSIBLE_APP_FETCH,
        payload:{
            success,error
        }
    };
}

export function fetchPossibleApps(id){
    const api_server = Environment.api_host("METRIC");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/vendor/${id}/possible_apps?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchPossibleApps());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchPossibleApps(response,error));
        } catch(e){
            dispatch(endFetchPossibleApps(null,{
                message:e.message
            }));
            return;
        }
    };
}


//Action to start the registration
function startMetricAppFetch(){
    return {
        type:START_METRIC_APP_FETCH,
        payload:null
    };
}

function endMetricAppFetch(success,error){
    return {
        type:END_METRIC_APP_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchVendor(applicationId,departmentId,duration, appsId, sourcesId){
    return async (dispatch) => {
        dispatch(startMetricAppFetch());
        try{
            let [response,error]    =   await MetricFactory.instance().getVendor(applicationId,departmentId,duration,appsId, sourcesId);
            dispatch(endMetricAppFetch(response,error));
        } catch(e){
            dispatch(endMetricAppFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}   


function startMetricAppActivityFetch(){
    return {
        type:START_METRIC_APP_ACTIVITY_FETCH,
        payload:null
    };
}

function endMetricAppActivityFetch(success,error){
    return {
        type:END_METRIC_APP_ACTIVITY_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchVendorActivity(applicationId,duration,departmentId,userState, appsId, sourcesId){
    return async (dispatch) => {
        dispatch(startMetricAppActivityFetch());
        try{
            let [response,error]    =   await MetricFactory.instance().getVendorActivity(applicationId,duration,departmentId,userState,appsId, sourcesId);
            dispatch(endMetricAppActivityFetch(response,error));
        } catch(e){
            dispatch(endMetricAppActivityFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}   


function startMetricAppActivityChartdataFetch(){
    return {
        type:START_METRIC_APP_CHARTDATA_FETCH,
        payload:null
    };
}

function endMetricAppActivityChartdataFetch(success,error){
    return {
        type:END_METRIC_APP_CHARTDATA_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchVendorActivityChartdata(applicationId,duration,departmentId,appsId, sourcesId){
    return async (dispatch) => {
        dispatch(startMetricAppActivityChartdataFetch());
        try{
            let [response,error]    =   await MetricFactory.instance().getVendorActivityChartData(applicationId,duration,departmentId,appsId, sourcesId);
            dispatch(endMetricAppActivityChartdataFetch(response,error));
        } catch(e){
            dispatch(endMetricAppActivityChartdataFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}   
