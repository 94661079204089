import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const START_FETCH_ORG_FORM_BY_ID = "START_FETCH_ORG_FORM_BY_ID";
export const END_FETCH_ORG_FORM_BY_ID = "END_FETCH_ORG_FORM_BY_ID";

export const START_FETCH_MASTER_FORM_BY_ID = "START_FETCH_MASTER_FORM_BY_ID";
export const END_FETCH_MASTER_FORM_BY_ID = "END_FETCH_MASTER_FORM_BY_ID";

export const START_ADD_CUSTOM_COMPONENT_RESPONSE = "START_ADD_CUSTOM_COMPONENT_RESPONSE";
export const END_ADD_CUSTOM_COMPONENT_RESPONSE = "END_ADD_CUSTOM_COMPONENT_RESPONSE";

export const START_FETCH_WORKFLOW_FORM_RESPONSE = "START_FETCH_WORKFLOW_FORM_RESPONSE";
export const END_FETCH_WORKFLOW_FORM_RESPONSE = "END_FETCH_WORKFLOW_FORM_RESPONSE";

function startFetchOrgFormUsingId() {
    return {
        type: START_FETCH_ORG_FORM_BY_ID,
        payload: {}
    };
}

function endFetchOrgFormUsingId(success, error) {
    return {
        type: END_FETCH_ORG_FORM_BY_ID,
        payload: {
            success, error
        }
    };
}

export function fetchOrgFormUsingId(formId, project_id = 0, org_id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/public/org-workflow/template/form/${formId}?t=${timestamp}&project_id=${project_id}&org_id=${org_id}`;
    return async (dispatch) => {
        dispatch(startFetchOrgFormUsingId());
        try {
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endFetchOrgFormUsingId(response, error));
        } catch (e) {
            dispatch(endFetchOrgFormUsingId(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchMasterFormUsingId() {
    return {
        type: START_FETCH_MASTER_FORM_BY_ID,
        payload: {}
    };
}

function endFetchMasterFormUsingId(success, error) {
    return {
        type: END_FETCH_MASTER_FORM_BY_ID,
        payload: {
            success, error
        }
    };
}

export function fetchMasterFormUsingId(formId, project_id = 0, org_id) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/public/org-workflow/template/form/${formId}?t=${timestamp}&project_id=${project_id}&org_id=${org_id}`;
    return async (dispatch) => {
        dispatch(startFetchMasterFormUsingId());
        try {
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endFetchMasterFormUsingId(response, error));
        } catch (e) {
            dispatch(endFetchMasterFormUsingId(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddCustomFormResponse() {
    return {
        type: START_ADD_CUSTOM_COMPONENT_RESPONSE,
        payload: {}
    };
}

function endAddCustomFormResponse(success, error) {
    return {
        type: END_ADD_CUSTOM_COMPONENT_RESPONSE,
        payload: {
            success, error
        }
    };
}

export function addCustomFormResponse(evaId, form_id, param) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/public/${evaId}/workflow-form/${form_id}/capture-form-response?t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startAddCustomFormResponse());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, param, true, false);
            dispatch(endAddCustomFormResponse(response, error));
        } catch (e) {
            dispatch(endAddCustomFormResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchWorkflowFormResponse() {
    return {
        type: START_FETCH_WORKFLOW_FORM_RESPONSE,
        payload: {}
    };
}

function endFetchWorkflowFormResponse(success, error) {
    return {
        type: END_FETCH_WORKFLOW_FORM_RESPONSE,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowFormResponse(evaluationId, formId, formType, componentId, org_id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/public/${evaluationId}/workflow-form/${formId}/get-form-response/${formType}/for_component/${componentId}?org_id=${org_id}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowFormResponse());
        try {
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endFetchWorkflowFormResponse(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowFormResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}