import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { generate } from 'shortid';
import * as Validator from "util/Validation";
import SqureButton from 'Components/Common/SqureButton';
// import Alert from '@material-ui/lab/Alert';

import {
  Dialog, DialogContent, DialogActions, Slide, DialogTitle, TextField
} from "@material-ui/core";
import classnames from "classnames";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  error: {
    color: '#f44336'
  },
  alerts:{
    marginBottom:theme.spacing(2)
  },
  errorMessage: {
    color: "red",
    fontWeight: "500",
    fontSize: "12px",
    marginLeft: "8px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddContackLinkDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameError: false,
      link: "",
      linkError: false,
      linkValidError: "",
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  handleOnClose = () => {
    this.setState({
      name: "",
      nameError: false,
      link: "",
      linkError: false,
      linkValidError: ''
    })
    this.props.onClose()
  }

  handleSubmit = (data) => {
    this.setState({
      nameError: false,
      linkError: false,
    })

    if (data.name === '' || data.name.length <= 0) {
      this.setState({
        nameError: true
      })
      return;
    }

    if (data.link === '' || data.link.length <= 0) {
      this.setState({
        linkError: true,
        linkValidError: 'Link can not be empty',
      })
      return;
    }

    if(this.state.linkValidError.length > 0){
      return;
    }

    this.props.onSubmit(data)
    this.setState({
      name: "",
      nameError: false,
      link: "",
      linkError: false,
    })
  }

  render() {
    var classes = this.props.classes;
    return (
      <Dialog
        onClose={this.handleOnClose}
        aria-labelledby=""
        open={this.props.isOpen}
        TransitionComponent={Transition}
        scroll={"body"}
        fullWidth={true}
        className={classnames(classes.dialog)}
        id={`productBLockDialog${generate()}`}
      >
        <DialogTitle id={`form-dialog-title${generate()}`}>
          Contact Details
        </DialogTitle>
        <DialogContent>
          <div className={classes.formContainerDialog}>
            {/* <div className={classes.alerts}>
              {this.state.linkValidError.length > 0 && <Alert variant="filled" severity="error">{this.state.linkValidError}</Alert>}
            </div> */}
            <div className={classes.formGroupDialog}>
              <TextField
                fullWidth
                InputProps={{ disableUnderline: true }}
                className={this.state.nameError === false ? classes.formControlDialog : classes.errformControlDialog}
                value={this.state.name}
                placeholder="Enter Resource Name"
                onChange={(e) => {
                  this.setState({
                    name: e.target?.value
                  })
                }}
              />
              {this.state.nameError && <div className={classes.errorMessage}>Resource name can not be empty</div>}
            </div>
            <div className={classes.formGroupDialog}>
              <TextField
                fullWidth
                InputProps={{ disableUnderline: true }}
                className={!this.state.linkValidError.length ? classes.formControlDialog : classes.errformControlDialog}
                value={this.state.link}
                placeholder="Enter Link"
                onChange={(e) => {
                  let error = "";
                  if (e.target?.value && !Validator.validateURL(e.target?.value)) {
                    error = "Please enter valid url."
                  }
                  this.setState({
                    link: e.target?.value,
                    linkValidError: error,
                  })
                }
                }
              />
              {this.state.linkValidError.length > 0 && <div className={classes.errorMessage}>{this.state.linkValidError}</div>}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '10px 24px' }}>
            <SqureButton
              variant={"outlined"}
              onClick={() => {
                this.setState({
                  linkValidError : ''
                })
                this.handleOnClose()
              }}
            >
              Cancel
            </SqureButton>
            &nbsp; &nbsp; &nbsp;
            <SqureButton
              variant={"contained"}
              onClick={() => {
                this.handleSubmit({
                  name: this.state.name,
                  link: this.state.link
                });
              }}
            >
              Save
            </SqureButton>
          </div>
        </DialogActions>
      </Dialog >

    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(AddContackLinkDialog));








