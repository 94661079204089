import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';

import {
    Select,
    MenuItem,
    FormControl,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { CommonFn } from 'services/commonFn';

// assets
import CommonCss from "../../../../../commonCss";
import RightArrow from "assets/images/right-arrow.svg";

// icons
import FilterListIcon from '@material-ui/icons/FilterList';

// components
import Image from 'Components/Common/image';
import ProductsReviewFilterDialog from 'Components/Dialogs/ProductsReviewFilterDialog';

// Redux
import { fetchCompletedReviewProducts } from "redux/product/orgProduct/action";
import { showProductsReviewFilterDialog } from "redux/evaluate/action";


const connectedProps = (state) => ({
    fetchCompletedReviewProductsUnderProgress: state.orgProduct.fetchCompletedReviewProductsUnderProgress,
    completedReviewProducts: state.orgProduct.completedReviewProducts,
    filterData: state.evaluate.productsReviewFilterData, 
});

const connectionActions = {
    fetchCompletedReviewProducts: fetchCompletedReviewProducts,
    showProductsReviewFilterDialog: showProductsReviewFilterDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
    createStyles({
        viewAll: CommonCss.viewAll,
        viewAllbutton:{
            ...CommonCss.viewAllbutton,
            // marginRight: 10,
            lineHeight: 0,
            cursor: 'pointer'
        },
        productHead: {
            ...CommonCss.productHead,
            fontSize: 19,
            margin: "0px !important",
            lineHeight: '38px',
            '& span':{
                fontSize: 13,
                fontWeight: 400
            }
        },
        evaluateCard: {
            width: 'calc(100% - 0px)',
            background: '#fff',
            padding: "16px 24px",
            margin: '20px 0px 0px 0px',
            boxShadow: '0px 3px 25px #55555500',
            borderRadius: 5,
            display: 'flex',
            cursor: 'pointer'
        },
        evaluateCardTitle: {
            color: '#20253A',
            fontSize: 14,
            margin: 0,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        evaluateCardSubTitle: {
            fontSize: 12,
            color: '#A0A5BA',
            marginTop: "5px",
            marginBottom: 0
        },
        evaluateLogo: {
            flex: .8,
            display: 'flex',
            justifyContent: 'flex-end'
        },
        evaluateText: {
            flex: 1.5,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        loader: {
            marginTop: 20,
            marginLeft: 20,
            display: 'block',
            position: 'absolute',
            top: '38%',
            left: '46%'
        },
        noMoreData: {
            marginTop: 30,
            display: 'block'
        },

        productLogo: {
            float: 'left',
            width: '50px',
            height: '50px',
            borderRadius: '4px',
            marginRight: '10px',
            textAlign: 'center',
            lineHeight: '33px',
            fontWeight: 600,
            fontSize: 11,
            color: '#A0A5BA',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'scale-down'
            }
        },
        rightArrow: {
            width: 17
        },
        reviewCompleted: {
            color: '#2EB77D',
            fontSize: 12,
            marginTop: 3,
            marginBottom: 0
        },
        select: {
            margin: 0,
            padding: 0,
        },
        showMore:{
            display: 'inline-block',
            border: '1px solid #FFF',
            cursor: 'pointer',
            padding: '10px 14px',
            fontSize: '12px',
            background: '#4A87F8',
            borderRadius: '20px',
            color: 'white'
        }
    });

class CompletedReviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            page: 0,
            pageSize: 5,
            isCartShow: false,
            selectedReview: 10
        };
    }

    componentDidMount() {
        this.fetchReviewProducts();
    }

    fetchReviewProducts = () => {
        // In-Progress
        if(this.state.selectedReview == 10){
            let data = {};
            data.page = this.state.page
            data.pagesize = this.state.pageSize
            data.inOrgAndEvaluationInProgress = true
            data.range = {
                greaterThan : -(this.props.filterData?.daterange || 30)
            }
            this.props.fetchCompletedReviewProducts(data)
        }else{
            // Completed
            let data = {};
            data.page = this.state.page
            data.pagesize = this.state.pageSize
            data.inOrgAndEvaluated = true
            data.range = {
                greaterThan : -(this.props.filterData?.daterange || 30)
            }
            this.props.fetchCompletedReviewProducts(data)
        }
    }

    routeChange = (id) => {
        let redirectPath = `/app/evaluate/${id}`;
        this.props.history.push(redirectPath);
    }


    render() {
        const classes = this.props.classes;

        const { completedReviewProducts } = this.props;

        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                        <p className={classnames(classes.productHead)}>Reviews <span>in last {this.props.filterData?.daterange || 30} days</span></p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <div style={{    display: 'flex', alignItems: 'center',float: 'right'}}>
                            <div style={{marginRight: 16}}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.select}
                                    margin="dense"
                                    onClick={e => e.stopPropagation()}
                                >
                                    <Select
                                        value={this.state.selectedReview}
                                        onChange={(event) => {
                                            var me = this;
                                            this.setState({ selectedReview: event.target.value, page: 0 }, () => {
                                                me.fetchReviewProducts();
                                            });
                                        }}
                                        ref={ref => {
                                            if (!ref || !ref.select) {
                                            return;
                                            }
                                
                                            const orig = ref.select.onMenuMouseDown;
                                
                                            ref.select.onMenuMouseDown = function(e) {
                                            e.nativeEvent.stopImmediatePropagation();
                                            orig.call(this, e);
                                            };
                                        }}
                                    >
                                        <MenuItem value={10}>In-Progress</MenuItem>
                                        <MenuItem value={20}>Completed</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <span className={classes.viewAllbutton} onClick={()=>{this.props.showProductsReviewFilterDialog(this.state.selectedReview)}} >
                                <FilterListIcon />
                            </span>
                        </div>
                    </Grid>
                </Grid>
                <div style={{position:'relative'}}>
                    { completedReviewProducts?.Data?.length > 0 &&
                        <>
                            {   completedReviewProducts?.Data.map((item, index) =>
                                <div className={classes.evaluateCard} key={index} onClick={() => { this.routeChange(item.EvaluationID) }}>
                                    <div className={classes.productLogo} title={item.Name}>
                                        <Image src={item.ProductLogo} alt={item.Name} title={item.Name} />
                                    </div>
                                    <div className={classes.evaluateText}>
                                        <p className={classes.evaluateCardTitle}>{item.Name}</p>
                                        {this.state.selectedReview === 20 &&
                                            <p className={classes.reviewCompleted}>Last Reviewed: {CommonFn.formateDate(item.EvaluationUpdatedAt)}</p>
                                        }
                                        {this.state.selectedReview === 10 &&
                                            <p className={classes.reviewCompleted}>Last Updated: {CommonFn.formateDate(item.EvaluationUpdatedAt)}</p>
                                        }
                                    </div>
                                    <div className={classes.evaluateLogo}>
                                        <Image src={RightArrow} className={classes.rightArrow} />
                                    </div>
                                </div>
                            )}
                            {!(completedReviewProducts?.Data?.length === completedReviewProducts?.total) &&
                                <div style={{padding: '16px 0px', textAlign: 'center'}}>
                                    <span 
                                    onClick={()=>{
                                         var me = this;
                                         this.setState({ page: this.state.page + 1 }, () => {
                                             me.fetchReviewProducts();
                                         });
                                    }}
                                    className={classes.showMore}
                                    >Load more</span>
                                </div>
                            }
                        </>
                    }

                    {this.props.fetchCompletedReviewProductsUnderProgress &&
                        <span className={classes.loader}><CircularProgress /></span>
                    }
                    {!this.props.fetchCompletedReviewProductsUnderProgress && completedReviewProducts?.Data == null &&
                        <span className={classes.noMoreData}>No more data </span>
                    }
                </div>
                <ProductsReviewFilterDialog />
            </>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CompletedReviews));
