import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";
// import {
//   Grid, Button, Popover, List, Checkbox, FormControlLabel,
//   ListItem, Typography, TextField, InputAdornment
// } from "@material-ui/core"
import { withRouter } from "react-router-dom";
// import SearchIcon from "@material-ui/icons/Search";
import { updateDrawerStyle } from "redux/drawer/action";
import { hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
import { updateEntityRenewal } from "redux/renewal/action";
import axios from "axios";
// import * as Validator from "util/Validation";
import { fetchVisualizationContractData } from "redux/vendor/vendorCatalog/action";
import { CommonFn } from "services/commonFn";
import { showSnackBar } from "redux/snackbar/action";
// import classNames from "classnames";

import { fetchUsers } from "redux/usersSettings/action";
// import DateFilter from "Components/Common/Filters/DateFilter";
import * as Environment from "util/Environment";
import moment from "moment";
// import ReactSelect from "react-select";
// import { withAsyncPaginate } from "react-select-async-paginate";
// import StartRenewalDialog from "Components/Application/Components/Renewal/StartRenewalDialog";
// import AttachRequestDialog from "Components/Application/Components/Renewal/AttachRequestDialog";
import { showEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
// import { isEnabledEvaluate } from "util/Common";

import { showConfirmCallbackDialog, hideConfirmCallbackDialog } from "redux/applications/action";

// import ConfirmCallbackDialog from "Components/Common/ConfirmCallbackDialog";
import ContractList from "./Common/ContractList";
import ContractFilter from "./Common/ContractFilter";

// const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

const connectedProps = (state) => ({
  tableVisualizationContracts: state.vendorCatalog.tableVisualizationContracts,
  fetchVisualizationContractProg: state.vendorCatalog.fetchVisualizationContractProg,
  updateEntityRenewalProg: state.renewal.updateEntityRenewalProg,
  updateRenewalColloboratorProg: state.renewal.updateRenewalColloboratorProg,
  deleteRenewalColloboratorProg: state.renewal.deleteRenewalColloboratorProg,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
  startNewRenewalProgress: state.renewal.startNewRenewalProgress,
  productRenewalInfo: state.renewal.productRenewalInfo,
  productRenewalInfoError: state.renewal.productRenewalInfoError,
  attachProductToRequestProg: state.renewal.attachProductToRequestProg,
  attachProductToRequestErr: state.renewal.attachProductToRequestErr,
  users: state.usersSettings.users,
  orgUser: state.orgUser,
  isOpen: state.evaluationMetaData.showEvaluationProductSelectionDialog,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchVisualizationContractData: fetchVisualizationContractData,
  fetchUsers: fetchUsers,
  showSnackBar: showSnackBar,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  showEvaluationProductSelectionDialog: showEvaluationProductSelectionDialog,
  showConfirmCallbackDialog: showConfirmCallbackDialog,
  hideConfirmCallbackDialog: hideConfirmCallbackDialog,
  updateEntityRenewal: updateEntityRenewal,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: "0em 2em",
      marginTop: "0px !important",
      paddingBottom: "2rem",
    },
    head: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 40,
      marginBottom: 8,
      "& > .appBar": {
        flex: 1,
      },
    },
    select: {
      background: "#fff",
      width: 200,
      textAlign: "left",
      marginTop: 0,
      marginBottom: 0,
      "& [class*=MuiSelect-root]": {
        padding: 9,
      },
    },
    evaluateCard: {
      width: "calc(100% - 0px)",
      background: "#fff",
      padding: "16px 24px",
      boxShadow: "0px 3px 25px #55555500",
      borderRadius: 5,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "space-between",
    },
    evaluateCardTitle: {
      color: "#20253A",
      fontSize: 14,
      margin: 0,
      fontWeight: 500,
      width: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    evaluateDetailsBox: {
      display: "flex",
      flex: ".6",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    evaluateText: {
      display: "flex",
      flex: ".4",
      justifyContent: "center",
      flexDirection: "column",
      cursor: "pointer",
    },
    productLogo: {
      float: "left",
      width: "50px",
      height: "50px",
      borderRadius: "4px",
      marginRight: "10px",
      textAlign: "center",
      lineHeight: "33px",
      fontWeight: 600,
      fontSize: 11,
      color: "#A0A5BA",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "scale-down",
      },
      cursor: "pointer",
    },
    reviewCompleted: {
      color: "#2EB77D",
      fontSize: 12,
      marginTop: 3,
      marginBottom: 0,
    },
    ownerNames: {
      color: "#6d6d6d",
      fontSize: 12,
      marginTop: 3,
      marginBottom: 0,
    },
    btnReview: {
      width: "max-content",
      height: "32px",
      border: "1px solid #91949E",
      borderRadius: "5px",
      background: " #ffffff",
      cursor: "pointer",
      textAlign: "center",
      fontSize: "13px",
      color: "#626675",
    },
    filterTitle: {
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    topFilters: {
      marginBottom: 25,
    },
    selectComp: {
      width: 250,
      marginTop: 10,
    },
    stateLabel: {
      cursor: "pointer",
      backgroundColor: "#fff",
      border: "1px #dadce0 solid",
      borderRadius: 8,
      height: 32,
      padding: "0 10px",
      margin: "0px 3px",
      display: "flex",
      alignItems: "center",
    },
    selected: {
      backgroundColor: "#a3dcfc33",
    },
    stateLabelText: {
      color: "#5f6368",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "1px",
    },
    labelInit: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 16,
      height: 16,
      marginRight: 8,
      color: "#fff",
      borderRadius: 8,
      fontSize: 10,
      background: "#4a87f8",
    },
    isFeatured: {
      background: "#ff9800",
      color: "#fff",
    },
    isBlocked: {
      background: "#f44336",
      color: "#fff",
    },
    isExisting: {
      background: "#E6FFF3",
      color: "#2EB77D",
    },
    isInReview: {
      background: "#bdbdbd",
      color: "#fff",
    },
    isLimited: {
      background: "#2e81da",
      color: "#fff",
    },
    filterOptions: {
      display: "flex",
      alignItems: "center",
      gap: 20,
      padding: theme.spacing(3, 3),
      paddingLeft: 0,
    },
    autoSelectInput: {
      width: "100%",
      background: "white",
      marginTop: 10,
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
      // width: '30%'
    },
    searchBox: {
      maxWidth: "500px",
      minWidth: "250px",
    },
    statusfilterBtn: {
      background: "#fff",
      border: "1px solid #888",
      borderRadius: 4,
      width: "100%",
      color: "#222",
      boxShadow: "none",
      padding: "4px 20px",
      fontSize: 16,
      "&:hover": {
        background: "#fff",
        color: "#222",
      },
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  });

let startDate = new Date();
startDate = moment(startDate).toISOString();

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      isOpen: false,
      selectedRenewalProduct: null,
      selectedRenewalContract: null,
      selectedRenewalDate: "",
      selectedEntityType: null,
      anchorEl: null,
      currentRenewal: null,
      showAttachRequestDialog: false,
      availableFilters: [
        { label: "Renewal Status", slug: "renewal_status", isShow: true },
        { label: "Date", slug: "date", isShow: true },
        { label: "Project Status", slug: "project_status", isShow: true },
        { label: "Project Owner", slug: "project_owner", isShow: true },
        { label: "Contract Owner", slug: "contract_owner", isShow: true },
      ],
      showFilters: [],
      renewalStatusfilterPopover: null,
      customColumns: [],
      filterData: {
        query: "",
        duration: 0,
        startDate: new Date(),
        endDate: new Date(),
        projectStatus: "",
        projectOwnerIDs: [],
        contractOwnerIDs: [],
        dates: null,
        statusFilter: [1, 2],
      },
    };
    this.callBack = this.callBack.bind(this);
    this.onCancelUpdateContract = this.onCancelUpdateContract.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.props.fetchUsers(0, 10, "");
  }

  componentDidUpdate(prevProps) {
    if (!this.props.updateEntityRenewalProg && prevProps.updateEntityRenewalProg) {
      this.fetchData();
      this.props.showSnackBar("Status updated successfully", "success", 3000);
    }

    if (!this.props.attachProductToRequestProg && prevProps.attachProductToRequestProg) {
      if (this.props.attachProductToRequestErr) {
        this.props.showSnackBar("Failed to attach", "error", 3000);
      } else {
        this.fetchData();
        this.props.showSnackBar("Attached successfully", "success", 3000);
      }
    }

    if (!this.props.startNewRenewalProgress && prevProps.startNewRenewalProgress) {
      if (this.props.productRenewalInfoError === null) {
        this.props.history.push(`/app/evaluate/${this.props.productRenewalInfo?.EvaluationId}/workflow/interim`);
      } else {
        this.props.showSnackBar("Something went wrong", "error", 3000);
      }
    }
  }

  fetchData = () => {
    const data = {
      for_me: 1,
    };
    let dates = this.state.filterData?.dates;
    data.projectStatus = this.state.filterData?.projectStatus?.value || "";
    data.projectOwnerIDs = this.state.filterData?.projectOwnerIDs?.map((user) => user.value) || [];
    data.contractOwnerIDs = this.state.filterData?.contractOwnerIDs?.map((user) => user.value) || [];
    data.start = startDate;
    data.query = this.state.filterData?.query;
    if (dates && !dates.isAny && dates?.startDate && dates?.endDate) {
      if (dates.duration === -1) {
        data.end = dates.endDate;
        delete data.start;
      } else {
        data.start = dates.startDate;
        data.end = dates.endDate;
      }
    }
    data.status = this.state.filterData?.statusFilter;

    if (this.state.customColumns && this.state.customColumns.length > 0) {
      console.log(this.state.customColumns, "DefaultColumnssssssssssssssssssssssss");
      const item_ids = this.state.customColumns.map((o) => o.id);
      data.itemIds = item_ids;
    }
    this.props.fetchVisualizationContractData(data, this.props.Vid);
  };

  viewRenewal(evaluationId) {
    if (evaluationId !== undefined && evaluationId !== null && evaluationId !== 0) {
      this.props.history.push(`/app/evaluate/${evaluationId}/workflow/interim`);
    }
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      let appList = [];
      this.props.users?.forEach((item, index) => {
        appList.push({
          label: item.Name,
          value: item.ID,
        });
      });
      return {
        options: appList,
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      page: 0,
      pageSize: 10,
      query: inputValue,
    };
    let url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  }

  callBack() {
    this.props.history.push(
      `/app/vendors/org-vendor-details/${this.state.selectedRenewalProduct?.id}?document_id=${this.state.selectedRenewalContract?.id}#${this.state.selectedRenewalContract?.meta?.tab_slug}`
    );
    this.onCancelUpdateContract();
  }

  onCancelUpdateContract() {
    this.props.hideConfirmCallbackDialog();
  }

  updateStatusFilter(data) {
    let statusSelected;
    if (data === null || data === "") {
      statusSelected = [
        {
          label: "All",
          value: 0,
        },
      ];
    } else {
      let isOtherSelection = data.filter((o) => o.value > 0);
      if (isOtherSelection && isOtherSelection.length > 0) {
        statusSelected = isOtherSelection;
      } else {
        statusSelected = [
          {
            label: "All",
            value: 0,
          },
        ];
      }
    }
    console.log(data);
    this.setState({ statusFilter: statusSelected }, () => {
      // this.fetchData();
    });
  }

  handleOnClickState(newState) {
    let { statusFilter } = this.state;
    if (statusFilter.indexOf(newState) !== -1) {
      statusFilter.splice(statusFilter.indexOf(newState), 1);
    } else {
      statusFilter.push(newState);
    }
    this.setState(
      {
        statusFilter: statusFilter,
      },
      () => {
        this.fetchData();
      }
    );
  }

  handleOnClickFilter(value, index) {
    let showFilters = this.state.showFilters;
    if (showFilters.indexOf(value) !== -1) {
      showFilters.splice(showFilters.indexOf(value), 1);
    } else {
      showFilters.push(value);
    }
    this.setState({
      showFilters: showFilters,
    });
  }

  searchQuery = (text) => {
    this.setState(
      {
        query: text,
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <ContractFilter
          filterData={this.state.filterData}
          applyFilter={(filters) => {
            this.setState({ filterData: filters }, () => {
              this.fetchData();
            });
          }}
        />
        <ContractList
          fetchProgress={this.props.fetchVisualizationContractProg[this.props.Vid]}
          contractData={this.props.tableVisualizationContracts[this.props.Vid]}
          fetchRenewals={(columns) => {
            this.setState(
              {
                customColumns: columns,
              },
              () => {
                this.fetchData();
              }
            );
          }}
          viewRenewal={(id) => {
            this.viewRenewal(id);
          }}
          showConfirmCallbackDialog={(product, contract) => {
            this.setState(
              {
                selectedRenewalProduct: product,
                selectedRenewalContract: contract,
              },
              () => {
                this.props.showConfirmCallbackDialog();
              }
            );
          }}
          updateEntityRenewal={(id, params) => {
            this.props.updateEntityRenewal(id, params);
          }}
          showEvaluationProductSelectionDialog={(contract, product, date) => {
            this.setState({ selectedRenewalContract: contract, selectedRenewalProduct: product, selectedRenewalDate: date }, () => {
              this.props.showEvaluationProductSelectionDialog();
            });
          }}
          showAttachRequestDialog={(product, contract, date, entity) => {
            this.setState({
              showAttachRequestDialog: true,
              selectedRenewalProduct: product,
              selectedRenewalContract: contract,
              selectedRenewalDate: date,
              selectedEntityType: entity,
            });
          }}
        />
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Contracts));
