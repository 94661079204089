import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Button } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { CommonFn } from "services/commonFn";
import CommonCss from "commonCss";

import { withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { IsExistingProduct } from "Components/Common/SelectProductOption";

// Redux
import { showClaimProductDialog, hideClaimProductDialog } from "redux/seller/product/action";
import { fetchAddOrgProduct } from "redux/product/orgProduct/action";
import { fetchProductSubCategory } from "redux/product/subcategory/action";
import { searchUser } from "redux/usersearch/action";
import { fetchRecommendedProductsToAdd } from "redux/product/action";
import { showSnackBar } from "redux/snackbar/action";
import { resetAddOrgProductData } from "redux/product/orgProduct/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";

import ReactSelect from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";

// env
import * as Environment from "util/Environment";
import { claimProduct } from "redux/seller/product/action";

const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  user: state.authUser.user,
  claimProductDialogOpen: state.sellerProduct.claimProductDialogOpen,
  claimProductInitQuery: state.sellerProduct.claimProductInitQuery,

  claimProductProg: state.sellerProduct.claimProductProg,
  claimProductError: state.sellerProduct.claimProductError,

  subCategories: state.productSubCategory.subCategories,
  subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryProgress,

  ProductChampUserProgress: state.userSearch.searchProgress,
  ProductChampUsers: state.userSearch.users,

  products: state.products.recommendedProductsToAdd,
  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
  addorgProductStatus: state.orgProduct.addorgProductStatus,
  addorgProductCatsStatus: state.orgProduct.addorgProductCatsStatus,
  addorgProductChampStatus: state.orgProduct.addorgProductChampStatus,
  addorgProductStatusError: state.orgProduct.addorgProductStatusError,
  fetchRecommendedProductsToAddProgress: state.products.fetchRecommendedProductsToAddProgress,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  showClaimProductDialog: showClaimProductDialog,
  hideClaimProductDialog: hideClaimProductDialog,
  fetchAddOrgProduct: fetchAddOrgProduct,
  fetchProductSubCategory: fetchProductSubCategory,
  searchProductChampUser: searchUser,
  fetchRecommendedProducts: fetchRecommendedProductsToAdd,
  resetAddOrgProductData: resetAddOrgProductData,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  claimProduct:claimProduct
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    header: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: " center space-between",
      alignItems: "center",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      marginLeft: 20,
    },
    addProductSection: {
      border: "1px solid #F2F2F2",
      padding: 30,
      margin: 20,
      width:'100%'
    },
    productImage: {
      width: 200,
      height: 200,
      border: "1px solid #AAAAAA",
      borderRadius: 11,
    },
    subTitle: {
      color: "#282D30",
      fontSize: 16,
    },
    formControl: CommonCss.formControl,
    label: CommonCss.label,
    formGroup: CommonCss.formGroup,
    rightSection: {
      flex: 1,
      marginLeft: 60,
      marginRight: 30,
    },
    formDivide: {
      display: "flex",
    },
    groupFlexLeft: {
      marginRight: 10,
      flex: 1,
    },
    groupFlexRight: {
      marginLeft: 10,
      flex: 1,
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    button: {},
    arrow: {
      paddingLeft: 5,
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 11,
    },
    deleteSection: {
      position: "absolute",
      background: "#fff",
      borderRadius: 11,
      padding: "2px 2px 0px 2px",
      top: 0,
      right: 0,
      cursor: "pointer",
    },
    delete: {
      fill: "#ff1200",
    },
    mandatory: CommonCss.mandatory,
    searchAndSelect: {
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
      },

      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    otherDetails: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 10px #398AF51A",
      border: "1px solid #398AF5",
      borderRadius: "5px",
    },
    close: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "24px",
      },
    },
    divider: {
      color: "#4b86ff",
    },
  });

class ClaimProductDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      selectedProduct: {},
      companyName: "",
      productURL: "",
      internalTool: false,
      existingTool: false,
      existingToolDisabled: false,
      expanded: false,
      cat: [],
      Setchamp: [],
      cNameError: false,
      urlError: false,
      loading: false,
      addingNewProduct: false
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props.claimProductDialogOpen && !prevProps.claimProductDialogOpen) {
      this.setState({ loading: false });
    }
    
    if(this.props.claimProductProg && !prevProps.claimProductProg){
      if(this.props.claimProductError === null){
        this.props.showSnackBar("Product successfully claimed.", "success", 3000);
        this.props.hideClaimProductDialog();
      }else{
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  clearError = () => {
    this.setState({
      cNameError: false,
      urlError: false,
    });
  };

  onSubmit = () => {
    this.clearError();
    if (this.state.selectedProduct === '' || this.state.selectedProduct?.label === undefined) {
      this.props.showSnackBar("Please Select Vendor.", "error", 3000);
      return;
    }

    this.setState({ 
      loading: true 
    },()=>{
      this.props.claimProduct([parseInt(this.state.selectedProduct.value)])
    });
   
  };

  getOptions = async (inputValue, loadedOptions, additional) => {
    // console.log(inputValue,loadedOptions,additional);
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      field_setname: "all",
      text: inputValue,
      page,
      page_size: 150,
      sort_fields: [],
      filter_fields: {},
      merge_existing_others: true,
    };
    // setPage(page+1)
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;
    products = products.map((m) => {
      m.value = parseInt(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = m.states?.blocked !== undefined;
      return m;
    });
    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    const classes = this.props.classes;
    const { loading} = this.state;
    return (
      <Dialog
        onClose={this.props.hideClaimProductDialog}
        aria-labelledby="add-upload-dialog"
        open={this.props.claimProductDialogOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll={"body"}
        id="addProductDialog"
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: "visible" }}>
          <Typography variant={"h6"} className={classes.title}>
            Claim new vendor
          </Typography>
          <div>
            <span className={classes.close} onClick={() => this.props.hideClaimProductDialog()}>
              <CloseIcon className={classes.close} />
            </span>
            <div className={classes.addProductSection}>
                <div className={classes.formGroup}>
                  <label className={classes.label}>
                    Name of the vendor<span className={classes.mandatory}>*</span>
                  </label>
                  <SelectAsyncPaginate
                    isClearable
                    noOptionsMessage={() => "Start typing to select vendor"}
                    isSearchable={true}
                    classNamePrefix="select"
                    placeholder="Name of the vendor"
                    additional={{
                      page: 0,
                    }}
                    components={{ IndicatorSeparator: () => null }}
                    formatOptionLabel={IsExistingProduct}
                    isValidNewOption={(input) => input.length > 0}
                    defaultValue={this.props.claimProductInitQuery?.name ? { label: this.props.claimProductInitQuery?.name, value: "" } : {}}
                    loadOptions={this.getOptions}
                    value={this.state.selectedProduct}
                    // defaultValue={this.state.selectedProduct}
                    onChange={(data) => {
                      this.setState({
                        selectedProduct: data,
                      });
                     
                     
                    }}
                  />
                </div>
            </div>

            <div className={classes.buttonSection}>
              <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideClaimProductDialog}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              {this.props.claimProductProg || loading ? (
                <Button variant="contained" color="secondary" type="submit" className={classes.button}>
                  Loading...
                </Button>
              ) : (
                <Button variant="contained" color="secondary" onClick={this.onSubmit} className={classes.button}>
                  Claim Product <ArrowForwardIcon className={classes.arrow} />
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

ClaimProductDialog.defaultValue = {
  isSeller: false,
};

export default connector(compose(withRouter, withStyles(styles))(ClaimProductDialog));
