import React from 'react';
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis'
import LinkImg from "../../../../../assets/images/link.svg"
// import Image from 'Components/Common/image.jsx'

const styles = (theme) => createStyles({
    root:{
        background:theme.palette.common.white,
        borderRadius:theme.spacing(2),
        padding:theme.spacing(3)
    },
    imageContainer:{
        textAlign:"center",
        paddingBottom:theme.spacing(2)
    },
    img:{
        height:theme.spacing(18)
    },
    title:{
        fontSize:theme.spacing(2.5),
        paddingBottom:theme.spacing(2),
        textAlign: 'center',
    },
    body:{
        fontSize:theme.spacing(2),
        paddingBottom:theme.spacing(2),
        color:"#666666",
        textAlign:"justify"
    },
    action:{
        textAlign:"center"
    },
    link:{
        color:"#3C9CF9",
        textTransform:"none",
        fontSize:theme.spacing(1.8),
        cursor:"pointer"
    },
    linkImage:{
        width:theme.spacing(1)
    }
});

class CommingSoon extends React.Component {

    render() {
        const classes   =   this.props.classes;
        return <div className={classes.root}>
           <Typography variant={"h6"} className={classes.title}>{this.props.title}</Typography>

            {this.props.image && (
           <div className={classes.imageContainer}>

               <img src={this.props.image} className={classes.img} alt="link"/>

           </div>
           )}
           <Typography variant={"body1"} className={classes.body}>
               <LinesEllipsis 
                text={this.props.description}
                maxLine='5'
                ellipsis='...'
                basedOn='letters'
               />
           </Typography>
           {this.props.link && (
            <div className={classes.action}>

                    <a href={this.props.link}><Typography className={classes.link}>Learn more <img alt="link" src={LinkImg} className={classes.linkImage}/></Typography></a>

            </div>
           )}
        </div>
    }
}

CommingSoon.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default compose(
    withStyles(styles)
)(CommingSoon);