import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import * as Validator from "util/Validation";
import {
  Dialog, DialogContent, Slide, Typography, Grid
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
} from 'react-flow-renderer';


import { hideWrapperWorkflowProgressDialog } from "redux/evaluation/workflow/action";
import { fetchWorkflowUserActivity } from "redux/evaluation/workflow/action";
import { nodeLabel } from "../../../../../Settings/WorkflowBuilder/WorkFlowV2Components/data";

const connectedProps = (state) => ({
  isOpen: state.evaluationWorkflow.isWrapperWorflowProgressDialogOpen,
  wrapperWorkflowComponentId: state.evaluationWorkflow.wrapperWorkflowComponentId,
  fetchWorkflowUserActivityProgress: state.evaluationWorkflow.fetchWorkflowUserActivityProgress,
  workflowUserActivity: state.evaluationWorkflow.workflowUserActivity,
  user: state.authUser.user,
  workflowComponent: state.master.workflowComponent,
});

const connectionActions = {
  hideDialog: hideWrapperWorkflowProgressDialog,
  fetchWorkflowUserActivity: fetchWorkflowUserActivity,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
    margin: '5px 0px'
  },
  dialogContent: {
    margin: 'auto',
    marginBottom: 10
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  flowSection: {
    height: '87vh',
    maxHeight: "calc( 100vh - 200px)",
    "& .draggable": {
      display: 'none',
    },
    "& .decision-rectangle": {
      width: 66,
      height: 66,
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: 11,
      left: 41,
      border: '1px solid #EEEEEE',
      background: '#fff',
      zIndex: -1
    },
    "& .decision-component": {
      position: 'relative',
      zIndex: 1,
      top: 34
    }
  },
  "@global": {
    '.applicationContent': {
      background: '#fff !important',
      padding: "0 !important"
    },
    ".react-flow__node": {
      padding: '0px !important',
      boxShadow: 'none'
    },
    ".react-flow__node-default, .react-flow__node-input, .react-flow__node-output": {
      padding: '5px 10px',
      fontSize: '7.5px',
      border: 'none'
    },
    '.react-flow__edge-path': {
      strokeWidth: 1.5
    },
  },

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ChildWorkflowProgress extends React.Component {
  constructor(props) {
    super(props);
    this.reactFlowWrapper = React.createRef(null);
    this.state = {
      reactFlowInstance: null,
      elements: [],
      isChild:true
    }
    this.avatarName = this.avatarName.bind(this);

  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  getMasterObjectFromId = (id) => {
    return this.props.workflowComponent && this.props.workflowComponent.find((item) => item?.ID === parseInt(id));
  }

  componentDidMount() {
    this.props.fetchWorkflowUserActivity(this.props.match.params.evaluation_id, 'child',this.props.wrapperWorkflowComponentId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchWorkflowUserActivityProgress === false && prevProps.fetchWorkflowUserActivityProgress === true && this.state.isChild) {
      this.setElementToFlow();
    }
  }

  setElementToFlow() {
    const { workflowUserActivity } = this.props;
    const orgWorkFlowCompList = workflowUserActivity.components;
    const orgWorkFlowActionList = workflowUserActivity.actions;
    const userActivities = workflowUserActivity.user_activity;

    if (orgWorkFlowCompList === null || userActivities === null || userActivities.length <= 0) {
      return;
    }

    let userActivityMap = {};
    var currentActiveComponent = 0;
    var isStartCompleted = false;
    var startComId = '';

    let workflowComponentMap = {};
    orgWorkFlowCompList.forEach(function (component) {
      workflowComponentMap[component.ID] = component;
    });

    userActivities.forEach(function (userActivity) {
      if (workflowComponentMap[userActivity.component_id] === undefined) {
        return;
      }
      userActivityMap[userActivity.component_id] = userActivity.status;
      isStartCompleted = true;
      if (userActivity.status === 2) {
        currentActiveComponent = userActivity.component_id;
      }
    });

    const decisionComponentIds = [];
    const elementList = orgWorkFlowCompList ? orgWorkFlowCompList.map((item, index) => {
      let flowData = {};
      if (Validator.isJson(item.FlowData)) {
        flowData = JSON.parse(item.FlowData);
      }
      
      const masterObject = this.getMasterObjectFromId(item.ComponentID);
      if (masterObject?.Slug === "START") {
        startComId = item.ID;
      }

      let label = item.Name;

      const dataObjects = {
        id: item.ID.toString(),
        data: { label, componentId: item.ComponentID.toString(), flowData: item.FlowData, configurations: item.OrgWorkflowComponentConfigurations, object: item },
        position: flowData?.position ? flowData?.position : { x: 40, y: (index + 1) * 40 },
        style: {
          background: "transparent",
          border: "none",
          borderRadius: "0",
          width: "max-content",
          "&:hove": {
            boxShadow: "none",
          },
        },
        currentActiveUsers: []
      }

      if (currentActiveComponent === item.ID) {
        dataObjects.currentActiveUsers.push(this.props.user);
      }

      if (masterObject && masterObject?.Slug === 'DECISION_COMPONENT') {
        decisionComponentIds.push(item.ID)
      }

      if (masterObject?.Slug === "START") {
        dataObjects.data['type'] = 'input';
      } else if (masterObject?.Slug === 'FINISH' || masterObject?.Slug === 'STOP') {
        dataObjects.data['type'] = 'output';
      }

      var isEdit = false;
      dataObjects.data.label = nodeLabel(
        false,
        masterObject?.Slug,
        dataObjects.id,
        item.Name,
        dataObjects.position.x,
        dataObjects.position.y,
        this.onSelectWorkflowComponent,
        this.onClickDecisionComponent,
        this.onClickWorkFlowComponent,
        isEdit,
        dataObjects.currentActiveUsers,
        this.removeUsers
      )

      return dataObjects
    }) : [];

    const componentEdges = orgWorkFlowActionList ? orgWorkFlowActionList.map((item, index) => {
      let animated = userActivityMap[item.SourceComponentID] !== undefined && userActivityMap[item.SourceComponentID] === 1 ? true : false;
      if (startComId === item.SourceComponentID && isStartCompleted === true) {
        animated = true;
      }
      return {
        id: item.ID.toString(),
        source: item.SourceComponentID.toString(),
        target: item.TargetComponentID.toString(),
        type: decisionComponentIds.includes(item.SourceComponentID) ? "smoothstep" : "buttonedge",
        label: decisionComponentIds.includes(item.SourceComponentID) ? item?.Name : "",
        style: { stroke: '#d8d8d8', strokeWidth: '1px' },
        animated: animated
      }
    }) : [];
    this.setState({
      elements: [...elementList, ...componentEdges]
    })
  }


  onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView()
    this.setState({
      reactFlowInstance: reactFlowInstance
    })
  }

  render() {
    const classes = this.props.classes;

    const { elements } = this.state;

    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"lg"}
      scroll="body"
      id="evMetaDialog"
    >
     <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>Child Project Progress</Typography>
        <div className={classes.formBuilderMain}>
          <Grid container>
            <Grid item lg={12}>
              <div className={classes.flowSection} id="dropSection">
                <ReactFlowProvider>
                  <div style={{ width: '100%', height: '100%' }} ref={this.reactFlowWrapper}>
                    <ReactFlow
                      elements={elements}
                      onLoad={this.onLoad}
                      snapToGrid={true}
                      snapGrid={[15, 15]}
                      style={{ background: '#F8F7F7' }}
                      key="edge-with-button"
                      defaultZoom={1.5}
                    onElementClick={this.onElementClick}
                    >
                      <Controls
                        onInteractiveChange={e => console.log(e)}
                      />
                      <Background color="#000" gap={30} />
                    </ReactFlow>
                  </div>
                </ReactFlowProvider>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ChildWorkflowProgress));