import {
    START_INDUSTRIES_FETCH, END_INDUSTRIES_FETCH,
    START_NUMOFEMPLOYEES_FETCH, END_NUMOFEMPLOYEES_FETCH,
    START_LOCATIONS_FETCH, END_LOCATIONS_FETCH,
    START_ORG_TYPE_FETCH, END_ORG_TYPE_FETCH,
    START_COMPLIANCE_CERT_FETCH, END_COMPLIANCE_CERT_FETCH,
    START_PII_FETCH, END_PII_FETCH,
    START_ADD_COMPLIANCE_CERT, END_ADD_COMPLIANCE_CERT,
    START_ADD_PII, END_ADD_PII,
    START_FETCH_PLATFORM, END_FETCH_PLATFORM,
    START_FETCH_WORKFLOW_COMP, END_FETCH_WORKFLOW_COMP,
    START_FORM_BY_COMP_ID, END_FORM_BY_COMP_ID,
    START_FORM_COMP, END_FORM_COMP,
    START_FRAMEWORKS_FETCH, END_FRAMEWORKS_FETCH
} from "./action";

const initState = {
    fetchIndustriesProgress: false,
    industries: [],
    fetchLocationsProgress: false,
    locations: [],
    fetchOrgTypeProgress: false,
    orgType: [],
    fetchNumOfEmployeesProgess: false,
    numOfEmployees: [],
    fetchComplienceCertProgress: false,
    complienceCert: [],
    pii: [],
    fetchPiiProgress: false,
    platform: [],
    fetchPlatformProgress: false,
    fetchWorkflowComponentProgress: false,
    workflowComponent: [],

    workflowFormByCompIdProgress: false,
    workflowFormByCompIdList: [],

    workflowFormCompProgress: false,
    workflowFormCompList: [],

    fetchFrameworksProgress: false,
    frameworksList: []
}

export function masterReducer(state = initState, action) {
    switch (action.type) {
        case START_INDUSTRIES_FETCH:
            return {
                ...state,
                fetchIndustriesProgress: true,
                industries: [],
            };
        case END_INDUSTRIES_FETCH:
            return {
                ...state,
                fetchIndustriesProgress: false,
                industries: action.payload.success || [],
            }
        case START_NUMOFEMPLOYEES_FETCH:
            return {
                ...state,
                fetchNumOfEmployeesProgess: true,
                numOfEmployees: [],
            };
        case END_NUMOFEMPLOYEES_FETCH:
            console.log(action.payload.success)
            return {
                ...state,
                fetchNumOfEmployeesProgess: false,
                numOfEmployees: action.payload.success || [],
            }
        case START_LOCATIONS_FETCH:
            return {
                ...state,
                fetchLocationsProgress: true,
                locations: [],
            };
        case END_LOCATIONS_FETCH:
            return {
                ...state,
                fetchLocationsProgress: false,
                locations: action.payload.success || [],
            }
        case START_ORG_TYPE_FETCH:
            return {
                ...state,
                fetchOrgTypeProgress: true,
                orgType: [],
            };
        case END_ORG_TYPE_FETCH:
            return {
                ...state,
                fetchOrgTypeProgress: false,
                orgType: action.payload.success || [],
            }
        case START_COMPLIANCE_CERT_FETCH:
            return {
                ...state,
                fetchComplienceCertProgress: true,
                complienceCert: [],
            };
        case END_COMPLIANCE_CERT_FETCH:
            return {
                ...state,
                fetchComplienceCertProgress: false,
                complienceCert: action.payload?.success?.Data || [],
            };
        case START_PII_FETCH:
            return {
                ...state,
                fetchPiiProgress: true,
                pii: [],
            };
        case END_PII_FETCH:
            return {
                ...state,
                fetchPiiProgress: false,
                pii: action.payload?.success?.Data || [],
            };
        case START_ADD_COMPLIANCE_CERT:
            return {
                ...state,
                addComplienceCertProgress: true,
            };
        case END_ADD_COMPLIANCE_CERT:
            return {
                ...state,
                addComplienceCertProgress: false,
            };
        case START_ADD_PII:
            return {
                ...state,
                addPiiProgress: true,
            };
        case END_ADD_PII:
            return {
                ...state,
                addPiiProgress: false,
            };
        case START_FETCH_PLATFORM:
            return {
                ...state,
                platform: [],
                fetchPlatformProgress: true,
            };
        case END_FETCH_PLATFORM:
            return {
                ...state,
                platform: action?.payload?.success || [],
                fetchPlatformProgress: false,
            };
        case START_FETCH_WORKFLOW_COMP:
            return {
                ...state,
                fetchWorkflowComponentProgress: true,
                workflowComponent: []
            };
        case END_FETCH_WORKFLOW_COMP:
            return {
                ...state,
                fetchWorkflowComponentProgress: false,
                workflowComponent: action?.payload?.success || [],
            };
        case START_FORM_BY_COMP_ID: {
            return {
                ...state,
                workflowFormByCompIdProgress: true,
                workflowFormByCompIdList: []
            };
        }
        case END_FORM_BY_COMP_ID: {
            return {
                ...state,
                workflowFormByCompIdProgress: false,
                workflowFormByCompIdList: action?.payload?.success || [],
            };
        }

        case START_FORM_COMP: {
            return {
                ...state,
                workflowFormCompProgress: true,
                workflowFormCompList: []
            };
        }
        case END_FORM_COMP: {
            // console.log(action?.payload?.success, 'action?.payload?.success');
            return {
                ...state,
                workflowFormCompProgress: false,
                workflowFormCompList: action?.payload?.success || [],
            };
        }

        case START_FRAMEWORKS_FETCH: {
            return {
                ...state,
                fetchFrameworksProgress: true,
                frameworksList: []
            };
        }
        case END_FRAMEWORKS_FETCH: {
            return {
                ...state,
                fetchFrameworksProgress: false,
                frameworksList: action?.payload?.success || [],
            };
        }

        default:
            return state;
    }
}