import { Dialog, DialogContent, Slide, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import SqureButton from "../../../Common/SqureButton";
import Alert from "@material-ui/lab/Alert";
import { showSnackBar } from "redux/snackbar/action";

import {
  hideNudgeNotificationDialog,
  addNotification,
} from "redux/notification/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.notifications.showNudgeDialog,
  forUser: state.notifications.forUser,
  nudgeData: state.notifications.nudgeData,
  addNotificationProgress: state.notifications.addNotificationProgress,
  addNotificationError: state.notifications.addNotificationError,
  OrgID: state.authUser.user.OrgID,
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
  hideDialog: hideNudgeNotificationDialog,
  addNotification: addNotification,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    actions: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: "right",
    },
    body: {
      marginTop: 20,
    },
    alerts: {
      marginTop: 20,
    },
    subTitle: {
      fontSize: 14,
      marginTop: 10,
      marginBottom: 10,
    },
    common: {
      width: "100%",
      padding: "10px",
      borderRadius: "6px",
      fontFamily: "POPPINS",
    },
  });

class NudgetNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      message_error: "",
      message: "",
      actor: "",
      criteria: null,
      requirement: null,
      product: null,
    };
    this.submitData = this.submitData.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.user, "LoginUser");
    // console.log(this.props.nudgeData, "Data");
    // console.log(this.props.forUser, "Data");
    this.initMessage();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addNotificationProgress === false &&
      prevProps.addNotificationProgress === true
    ) {
      if (this.props.addNotificationError === null) {
        this.props.showSnackBar(
          "Notification sent successfully",
          "success",
          3000
        );
        this.props.hideDialog();
      } else {
        if (this.props.addNotificationError.message !== undefined) {
          this.props.showSnackBar("Failed to sent notification", "error", 3000);
        } else {
          // this.props.showSnackBar("Something went wrong", "error", 3000);
        }
      }
    }
  }

  initMessage() {
    const { nudgeData, forUser, evaluation } = this.props;
    let message = "";
    if (nudgeData.type === "notification.evaluation.colloborator.nudge") {
      message =
        "Hello " +
        forUser?.Name +
        " requested to take action on " +
        evaluation?.Name +
        ".";
    } else if (
      nudgeData.type === "notification.evaluation.criteria.colloborator.nudge"
    ) {
      message =
        "Hello " +
        forUser?.Name +
        " requested to take action on section " +
        nudgeData.criteria?.name +
        " of " +
        evaluation?.Name +
        ".";
      this.setState({
        criteria: nudgeData.criteria,
      });
    } else if (
      nudgeData.type ===
      "notification.evaluation.requirement.colloborator.nudge"
    ) {
      message =
        "Hello " +
        forUser?.Name +
        " requested to take action on question " +
        nudgeData.requirement?.name +
        " of " +
        evaluation?.Name +
        ".";
      this.setState({
        criteria: nudgeData.criteria,
        requirement: nudgeData.requirement,
      });
    } else if (
      nudgeData.type === "notification.evaluation.product.colloborator.nudge"
    ) {
      message =
        "Hello " +
        forUser?.Name +
        " requested to take action on product " +
        nudgeData.product?.Name +
        " of " +
        evaluation?.Name +
        ".";
      this.setState({
        product: nudgeData.product,
      });
    } else if (
      nudgeData.type === "notification.evaluation.criteria.approver.nudge"
    ) {
      message =
        "Hello " +
        forUser?.Name +
        " requested to approve on section " +
        nudgeData.criteria?.name +
        " of " +
        evaluation?.Name +
        ".";
      this.setState({
        criteria: nudgeData.criteria,
      });
    } else if (
      nudgeData.type === "notification.evaluation.criteria.approver.response.nudge"
    ) {
      message =
        "Hello " +
        forUser?.Name +
        " You have pending questions in " +
         evaluation?.Name +
        " which requires your response.";
      this.setState({
        criteria: nudgeData.criteria,
      });
    }

    this.setState({
      message: message,
    });
  }

  clearError() {
    this.setState({
      error: "",
      success: "",
    });
  }

  submitData() {
    this.clearError();

    const { message } = this.state;
    const { nudgeData, OrgID, user, forUser, evaluation } = this.props;
    if (message.length <= 0 || message === "") {
      this.setState({
        error: "Please enter message",
      });
      return;
    }

    // console.log(this.props.nudgeData, "nudgeData");
    // console.log(this.props.forUser, "forUser");

    var notificationBody = {
      type: nudgeData.type,
      orgId: OrgID,
      user: {
        id: forUser.ID,
        name: forUser.Name,
        email: forUser.Email,
      },
      data: {
        evaluation: {
          id: evaluation?.ID,
          name: evaluation?.Name,
        },
        actor: {
          id: user.ID,
          name: user.Name,
          email: user.Email,
        },
        message: message,
      },
    };

    if (
      nudgeData.type === "notification.evaluation.criteria.colloborator.nudge"
    ) {
      // console.log(nudgeData.criteria, "criteria");
      notificationBody.data["criteria"] = {
        id: nudgeData?.criteria.id,
        name: nudgeData?.criteria.name,
      };
    } else if (
      nudgeData.type ===
      "notification.evaluation.requirement.colloborator.nudge"
    ) {
      // console.log(nudgeData.requirement, "requirement");
      notificationBody.data["criteria"] = {
        id: nudgeData?.criteria.id,
        name: nudgeData?.criteria.name,
      };

      notificationBody.data["requirement"] = {
        id: nudgeData?.requirement.id,
        name: nudgeData?.requirement.name,
      };
    } else if (
      nudgeData.type === "notification.evaluation.product.colloborator.nudge"
    ) {
      // console.log(nudgeData.product, "product");

      notificationBody.data["product"] = {
        id: nudgeData?.product.ID,
        name: nudgeData?.product.Name,
      };
    } else if (
      nudgeData.type === "notification.evaluation.criteria.approver.nudge"
    ) {
      // console.log(nudgeData.criteria, "criteria");
      notificationBody.data["criteria"] = {
        id: nudgeData?.criteria.id,
        name: nudgeData?.criteria.name,
      };
    }

    console.log(notificationBody, "Final Data");

    this.props.addNotification(notificationBody);
  }

  render() {
    const classes = this.props.classes;
    const { criteria, requirement, product } = this.state;
    console.log(criteria, "criteria");
    console.log(requirement, "requirement");
    console.log(product, "product");
    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="app-integrationDialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="evMetaDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div
            className={classes.close}
            onClick={() => this.props.hideDialog()}
          >
            <CloseIcon />
          </div>
          <Typography variant={"h4"} className={classes.title}>
            Send Notification to {this.props.forUser.Name}
          </Typography>
          {criteria !== null && (
            <Typography variant={"h4"} className={classes.subTitle}>
              <b>Criteria: </b> {criteria?.name}
            </Typography>
          )}
          {requirement !== null && (
            <Typography variant={"h4"} className={classes.subTitle}>
              <b>Requirement: </b> {requirement?.name}
            </Typography>
          )}
          {product !== null && (
            <Typography variant={"h4"} className={classes.subTitle}>
              <b>Product: </b> {product?.Name}
            </Typography>
          )}

          <div className={classes.alerts}>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
            {this.state.success.length > 0 && (
              <Alert variant="filled" severity="success">
                {this.state.success}
              </Alert>
            )}
          </div>
          <div className={classes.body}>
            {/* <OutlinedInput
            label="Message"
            error={this.state.message_error.length > 0}
            helperText={this.state.message_error}
            value={this.state.message}
            onChange={(event) => {
              this.setState({ message: event.target.value });
            }}
          /> */}
            <Typography variant={"h4"} className={classes.subTitle}>
              Message
            </Typography>
            <textarea
              onChange={(e) => {
                this.setState({ message: e.target.value });
              }}
              value={this.state.message}
              className={classes.common}
              placeholder="Add your comments here"
            ></textarea>
          </div>
          <div className={classes.actions}>
            <SqureButton
              cls={classes.actionBtn}
              variant={"outlined"}
              onClick={this.props.hideDialog}
            >
              Cancel
            </SqureButton>
            &nbsp;&nbsp;&nbsp;
            <SqureButton
              cls={classes.actionBtn}
              variant={"contained"}
              onClick={(e) => {
                this.submitData();
              }}
            >
              Submit
            </SqureButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

NudgetNotification.defaultProps = {
  isWorkFlow: false,
};

export default connector(
  compose(withRouter, withStyles(styles))(NudgetNotification)
);
