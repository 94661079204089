import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const SHOW_ADD_UPDATE_TASK_DIALOG     =   "SHOW_ADD_UPDATE_TASK_DIALOG";
export const HIDE_ADD_UPDATE_TASK_DIALOG       =   "HIDE_ADD_UPDATE_TASK_DIALOG"

export const START_ADD_NEW_CATALOG_TASK     =   "START_ADD_NEW_CATALOG_TASK";
export const END_ADD_NEW_CATALOG_TASK       =   "END_ADD_NEW_CATALOG_TASK";

export const START_UPDATE_CATALOG_TASK     =   "START_UPDATE_CATALOG_TASK";
export const END_UPDATE_CATALOG_TASK       =   "END_UPDATE_CATALOG_TASK";

export const START_FETCH_CATALOG_TASKS     =   "START_FETCH_CATALOG_TASKS";
export const END_FETCH_CATALOG_TASKS       =   "END_FETCH_CATALOG_TASKS";

export const START_DELETE_CATALOG_TASK     =   "START_DELETE_CATALOG_TASK";
export const END_DELETE_CATALOG_TASK       =   "END_DELETE_CATALOG_TASK";

export const START_FETCH_CATALOG_TASKS_ONLY = "START_FETCH_CATALOG_TASKS_ONLY"
export const END_FETCH_CATALOG_TASKS_ONLY = "END_FETCH_CATALOG_TASKS_ONLY"

export const START_FETCH_CATALOG_RISK_TASKS = "START_FETCH_CATALOG_RISK_TASKS"
export const END_FETCH_CATALOG_RISK_TASKS = "END_FETCH_CATALOG_RISK_TASKS"

export const START_FETCH_CATALOG_FOLLOW_UP_TASKS = "START_FETCH_CATALOG_FOLLOW_UP_TASKS"
export const END_FETCH_CATALOG_FOLLOW_UP_TASKS ="END_FETCH_CATALOG_FOLLOW_UP_TASKS"

export  function showAddUpdateTaskDialog(data={},isNew=true){
    return     {
        type:SHOW_ADD_UPDATE_TASK_DIALOG,
        payload:{data,isNew}
    };
}

export  function hideAddUpdateTaskDialog(){
    return     {
        type:HIDE_ADD_UPDATE_TASK_DIALOG,
        payload:{}
    };
}

function startAddNewCatalogTask() {
    return {
        type: START_ADD_NEW_CATALOG_TASK,
        payload: {}
    };
}

function endAddNewCatalogTask(success, error) {
    return {
        type: END_ADD_NEW_CATALOG_TASK,
        payload: {
            success, error
        }
    };
}

export function addNewCatalogTask(data) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewCatalogTask());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewCatalogTask(response, error));
        } catch (e) {
            dispatch(endAddNewCatalogTask(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateCatalogTask() {
    return {
        type: START_UPDATE_CATALOG_TASK,
        payload: {}
    };
}

function endUpdateCatalogTask(from,success, error) {
    return {
        type: END_UPDATE_CATALOG_TASK,
        payload: {
            success, error, from
        }
    };
}

export function updateCatalogTask(taskId,data,from) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/task/${taskId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateCatalogTask());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateCatalogTask(from,response, error));
        } catch (e) {
            dispatch(endUpdateCatalogTask(from,null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchCatalogTasks(){
    return {
        type:START_FETCH_CATALOG_TASKS,
        payload:{}
    };
}

function endFetchCatalogTasks(success,error){
    return {
        type:END_FETCH_CATALOG_TASKS,
        payload:{
            success,error
        }
    };
}

export  function fetchCatalogTasks(queryParam){
    const api_server = Environment.api_host("CATALOG");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchCatalogTasks());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url,queryParam,false);
            dispatch(endFetchCatalogTasks(response,error));
        } catch(e){
            dispatch(endFetchCatalogTasks(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startDeleteCatalogTask() {
    return {
        type: START_DELETE_CATALOG_TASK,
        payload: null
    };
}

function endDeleteCatalogTask(taskId, type,from,success, error) {
    return {
        type: END_DELETE_CATALOG_TASK,
        payload: {
            success, error, type, from, taskId
        }
    };
}

export function deleteCatalogTask(taskId, type='task', from='EVALUATION') {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/task/${taskId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteCatalogTask());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteCatalogTask(taskId, type,from,response, error));
        } catch (e) {
            dispatch(endDeleteCatalogTask(taskId, type,from,null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchCatalogTaskOnly(){
    return {
        type:START_FETCH_CATALOG_TASKS_ONLY,
        payload:{}
    };
}

function endFetchCatalogTaskOnly(success,error){
    return {
        type:END_FETCH_CATALOG_TASKS_ONLY,
        payload:{
            success,error
        }
    };
}

export  function fetchCatalogTaskOnly(queryParam){
    const api_server = Environment.api_host("CATALOG");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchCatalogTaskOnly());
        try{
            let [response,error]    =  await HttpFactory.instance().getMethod(url,queryParam,false);
            dispatch(endFetchCatalogTaskOnly(response,error));
        } catch(e){
            dispatch(endFetchCatalogTaskOnly(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startFetchCatalogRiskTasks(){
    return {
        type:START_FETCH_CATALOG_RISK_TASKS,
        payload:{}
    };
}

function endFetchCatalogRiskTasks(success,error){
    return {
        type:END_FETCH_CATALOG_RISK_TASKS,
        payload:{
            success,error
        }
    };
}

export  function fetchCatalogRiskTasks(queryParam){
    const api_server = Environment.api_host("CATALOG");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchCatalogRiskTasks());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url,queryParam,false);
            dispatch(endFetchCatalogRiskTasks(response,error));
        } catch(e){
            dispatch(endFetchCatalogRiskTasks(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startFetchCatalogFollowUpTasks(){
    return {
        type:START_FETCH_CATALOG_FOLLOW_UP_TASKS,
        payload:{}
    };
}

function endFetchCatalogFollowUpTasks(success,error){
    return {
        type:END_FETCH_CATALOG_FOLLOW_UP_TASKS,
        payload:{
            success,error
        }
    };
}

export  function fetchCatalogFollowUpTasks(queryParam){
    const api_server = Environment.api_host("CATALOG");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchCatalogFollowUpTasks());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url,queryParam,false);
            dispatch(endFetchCatalogFollowUpTasks(response,error));
        } catch(e){
            dispatch(endFetchCatalogFollowUpTasks(null,{
                message:e.message
            }));
            return;
        }
    };
} 
