import { 
    START_PRODUCT_FETCH, END_PRODUCT_FETCH,
    START_PRODUCT_TAB_SECTION_FETCH, END_PRODUCT_TAB_SECTION_FETCH,
    START_PRODUCT_EXT_COLLABORATOR_ADD, END_PRODUCT_EXT_COLLABORATOR_ADD,
    START_PRODUCT_EXT_COLLABORATOR_REMOVE,END_PRODUCT_EXT_COLLABORATOR_REMOVE,
    START_PRODUCT_EXT_COLLABORATOR_FETCH,END_PRODUCT_EXT_COLLABORATOR_FETCH,
} from "./action";

const initState  =  {
    externalProducts: [],
    externalProductsFetchProgress: false,
    externalProductsFetchError: null,

    externalProductTabs: [],
    externalProductTabsFetchProgress: false,
    externalProductTabsFetchError: null,

    externalCollaboratorAddProgress: false,
    externalCollaboratorAddError: null,
    externalCollaboratorAddSuccess: null,

    externalCollaboratorRemoveProgress: false,
    externalCollaboratorRemoveError: null,
    externalCollaboratorRemoveSuccess: null,

    externalProductCollaborators: [],
    externalProductCollaboratorFetchProgress: false,
    externalProductCollaboratorFetchError: null,
}

export  function externalCollaboratorReducer(state=initState, action){
    switch(action.type){
        case START_PRODUCT_FETCH:
            return {
                ...state,
                externalProducts: [],
                externalProductsFetchProgress: true,
                externalProductsFetchError: null,
            };
        case END_PRODUCT_FETCH:
            var data = [];
            if(action.payload.success && action.payload.success.Data){
                data = action.payload.success.Data;
            }
            return {
                ...state,
                externalProducts: data,
                externalProductsFetchProgress: false,
                externalProductsFetchError: action.payload.error,
            };

        case START_PRODUCT_TAB_SECTION_FETCH:
            return {
                ...state,
                externalProductTabs: [],
                externalProductTabsFetchProgress: true,
                externalProductTabsFetchError: null,
            };
        case END_PRODUCT_TAB_SECTION_FETCH:
            return {
                ...state,
                externalProductTabs: action.payload.success || [],
                externalProductTabsFetchProgress: false,
                externalProductTabsFetchError: action.payload.error,
            };
        case START_PRODUCT_EXT_COLLABORATOR_ADD:
            return {
                ...state,
                externalCollaboratorAddProgress: true,
                externalCollaboratorAddError: null,
                externalCollaboratorAddSuccess: null,
            };
        case END_PRODUCT_EXT_COLLABORATOR_ADD:
            return {
                ...state,
                externalCollaboratorAddProgress: false,
                externalCollaboratorAddError: action.payload.error,
                externalCollaboratorAddSuccess: action.payload.success,
            };

        case START_PRODUCT_EXT_COLLABORATOR_REMOVE:
            return {
                ...state,
                externalCollaboratorRemoveProgress: true,
                externalCollaboratorRemoveError: null,
                externalCollaboratorRemoveSuccess: null,
            };
        case END_PRODUCT_EXT_COLLABORATOR_REMOVE:
            return {
                ...state,
                externalCollaboratorRemoveProgress: false,
                externalCollaboratorRemoveError: action.payload.error,
                externalCollaboratorRemoveSuccess: action.payload.success,
            };

        case START_PRODUCT_EXT_COLLABORATOR_FETCH:
            
            return {
                ...state,
                externalProductCollaborators: [],
                externalProductCollaboratorFetchProgress: true,
                externalProductCollaboratorFetchError: null,
            };
        case END_PRODUCT_EXT_COLLABORATOR_FETCH:
            data = [];
            if(action.payload.success && action.payload.success.Data){
                data = action.payload.success.Data;
            }
            return {
                ...state,
                externalProductCollaborators: data,
                externalProductCollaboratorFetchProgress: false,
                externalProductCollaboratorFetchError: action.payload.error,
            };
        
        default:
            return state;
    }
}  
